import React, { KeyboardEvent } from 'react';
import './SelectWithSearch.scss';
import RadioButtonGroup from '../RadioButtonGroup/RadioButtonGroup';
import FormGroup from '../FormGroup/FormGroup';
import { ReactComponent as RemoveIcon } from '../BootstrapIcons/trash.svg';
import SelectOption from '../../../infrastructure/types/SelectOption';
import SelectWithSearchItem from '../../../types/report/import/SelectWithSearchItem';

interface SelectWithSearchProps<T> {
    items: SelectWithSearchItem<T>[];
    label: string;
    searchBy: SelectOption[];
    value: string;
    className?: string;
    disabled?: boolean;
    searchByLabel?: string;
    placeholder?: string;
    selectedItems: SelectWithSearchItem<T>[];
    onSelectedItemClick?: (item: SelectWithSearchItem<T>) => void;
    onChangeSearchValue: (value: string, selectedSearchBy: string) => void;
    onSearchChange: (selectedSearchBy: string) => void;
    onSelectItem: (item: T) => void;
    onRemoveSelectedItemClick: (item: T) => void;
}

interface SelectWithSearchState {
    inputClicked: boolean;
    showResults: boolean;
    searchByValue: string;
}
class SelectWithSearch<T> extends React.Component<SelectWithSearchProps<T>, SelectWithSearchState> {
    searchResultsRef: React.RefObject<HTMLDivElement>;
    constructor(props: SelectWithSearchProps<T>) {
        super(props);

        this.searchResultsRef = React.createRef();
        this.state = {
            inputClicked: false,
            showResults: false,
            searchByValue: this.props.searchBy[0].value
        }
        this.onChangeSearchText = this.onChangeSearchText.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onInputClick = this.onInputClick.bind(this);
        this.onSearchByChange = this.onSearchByChange.bind(this);
        this.onMouseLeaveResults = this.onMouseLeaveResults.bind(this);
        this.handleOutsideSearchResultClick = this.handleOutsideSearchResultClick.bind(this);
        this.onRemoveSelectedItemClick = this.onRemoveSelectedItemClick.bind(this);
    }

    componentDidMount() {
        window.addEventListener("mousedown", this.handleOutsideSearchResultClick, false);
    }

    componentWillUnmount() {
        window.removeEventListener("mousedown", this.handleOutsideSearchResultClick, false);
    }

    handleOutsideSearchResultClick(e: any): void {
        if (this.searchResultsRef.current && !this.searchResultsRef.current.contains(e.target)) {
            this.setState({ showResults: false });
        }
    }

    onChangeSearchText(e: React.ChangeEvent<HTMLInputElement>): void {
        this.props.onChangeSearchValue(e.target.value, this.state.searchByValue)
    }

    onSelect(option: SelectWithSearchItem<T>): void {
        let selectedItemsCopy = this.props.selectedItems.slice();
        let item = selectedItemsCopy.find(x => x.name === option.name);
        if (!item) {
            selectedItemsCopy.push(option);
            this.setState({
                showResults: false
            });
            this.props.onSelectItem(option.item);
        }
        else {
            this.setState({ showResults: false });
        }
    }

    onKeyUp(e: KeyboardEvent): void {
        this.props.onSearchChange(this.state.searchByValue);
    }

    onInputClick(e: React.MouseEvent<HTMLInputElement>): void {
        if (this.props.items.length) {
            this.setState({ showResults: true });
        }
    }

    onSearchByChange(value: string): void {
        this.setState({ searchByValue: value });
    }

    onMouseLeaveResults(): void {
        this.setState({ showResults: false });
    }

    onRemoveSelectedItemClick(actionItem: SelectWithSearchItem<T>) {
        this.props.onRemoveSelectedItemClick(actionItem.item);
    }

    render(): JSX.Element {
        return (
            <div className={`${this.props.disabled ? 'disabled' : ''}`}>
                <FormGroup label={this.props.label} isVertical={true}>
                    <div className='display-flex'>
                        <label >{this.props.searchByLabel || 'Search by'}</label>
                        <RadioButtonGroup
                            isDisabled={() => { return this.props.disabled || false }}
                            options={this.props.searchBy}
                            value={this.state.searchByValue}
                            name={this.props.searchByLabel || 'Search by'}
                            onChange={this.onSearchByChange}
                            labelClassName='display-inline-flex'
                            className='margin-left-30px'
                        />
                    </div>
                    <input
                        onClick={this.onInputClick}
                        onKeyUp={this.onKeyUp}
                        value={this.props.value}
                        onChange={this.onChangeSearchText}
                        className={`select-search select-with-search ${this.props.className || ''}`}
                        placeholder={this.props.placeholder || 'Search ...'}
                        disabled={this.props.disabled}>
                    </input>
                    {this.state.showResults &&
                        this.props.items.length ?
                        <div className='select-with-search-select' ref={this.searchResultsRef}>
                            <ul className='select-search-options' >
                                {this.props.items.map((option, index) => {
                                    return <li className='select-search-option' key={index} onClick={(e) => { this.onSelect(option); }}>{option.name}</li>
                                })
                                }
                            </ul>
                        </div>
                        : null
                    }
                    <ul className={`selected-items width-fit-content ${this.props.disabled ? 'disabled' : ''}`}>
                        {this.props.selectedItems.map((item) => {
                            return <li key={item.id} className="row no-gutters cursor-pointer">
                                <div className="col-auto">
                                    <button disabled={item.preventDelete} className="transparent-button" type="button" onClick={() => this.onRemoveSelectedItemClick(item)}>
                                        <RemoveIcon />
                                    </button>
                                </div>
                                <div className="col" onClick={() => { this.props.onSelectedItemClick && this.props.onSelectedItemClick(item) }}>{item.name}</div>
                            </li>
                        })
                        }
                    </ul>
                </FormGroup>
            </div>
        );
    }
}

export default SelectWithSearch;
