import stringHelper from '../../common/stringHelper';
import typeHelper from '../../common/typeHelper';

const validate = (logic: string, name: string): string | null => {

    if (!typeHelper.isString(logic) || stringHelper.isEmpty(logic)) {

        return `Bespoke ${name} is required`;
    }

    return null;
};

const validateCondition = (condition: string): string | null => validate(condition, 'condition');
const validateAssignment = (assignment: string): string | null => validate(assignment, 'assignment');

const bespokeValidator = {
    validateCondition,
    validateAssignment
};

export default bespokeValidator;
