import DeclarationHelper from '../../../types/Functions/DeclarationHelper';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import UserDefinedFunctionParameter from '../../../../types/report/UserDefinedFunctionParameter';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import assignmentHelper from '../assignment/assignmentHelper';
import dataType from '../../../constants/dataType';
import ActionType from '../../../../types/report/ActionType';
import operandHelper from '../operand/operandHelper';
import Operand from '../../../../types/functions/Operand/Operand';
import operandType from '../../../constants/functions/operandType';

class UserDefinedFunctionParameterHelper implements DeclarationHelper<UserDefinedFunctionParameter> {

    private _functionNumber: number;

    constructor(functionNumber: number) {

        this._functionNumber = functionNumber;
    }

    add(declarations: UserDefinedFunctionParameter[], declaration: UserDefinedFunctionParameter): void {

        declaration.number = declarations.length + 1;

        declarations.push(declaration);
    }

    remove(declarations: UserDefinedFunctionParameter[], location: ReportLogicLocation): UserDefinedFunctionParameter[] {

        return declarations.filter(x => x.number !== location.fieldId);
    }

    getDeclarations(config: ReportConfig, location: ReportLogicLocation): UserDefinedFunctionParameter[] {

        let dictionary = this.getFunction(config, location);

        return dictionary.parameters;
    }

    getDeclaration(declarations: UserDefinedFunctionParameter[], location: ReportLogicLocation): UserDefinedFunctionParameter {

        let declaration = declarations.find(x => x.number === location.fieldId) as UserDefinedFunctionParameter;

        return declaration;
    }

    getDataSourceId(config: ReportConfig, location: ReportLogicLocation): number {

        return 0;
    }

    getEmpty(rawDataSourceId: number | null): UserDefinedFunctionParameter {

        let assignment = assignmentHelper.getEmpty(false);
        assignment.number = 1;

        return {
            id: null,
            name: '',
            businessDescription: '',
            number: 0,
            assignment: assignment,
            dataType: dataType.string,
            errors: []
        };
    }
    
    changeReferences(actionType: ActionType, userDefinedFunction: UserDefinedFunction, reportConfig: ReportConfig, parameterIndex?: number, parameterNumber?: number): void {

        operandHelper.traverseReportConfigSectionsForOperands(reportConfig, (operand: Operand) => {

            if (operand.arguments && operand?.functionNumber === userDefinedFunction.number) {

                let args = operand.arguments || [] as UserDefinedFunctionParameter[];

                switch (actionType) {
                    case ActionType.Add: args.push(operandHelper.getEmpty()); break;
                    case ActionType.Remove: args.splice(parameterIndex as number, 1); break;
                    case ActionType.Clear: args[parameterIndex as number] = operandHelper.getEmpty(); break;

                    default: break;
                }
            }
        });

        if ((actionType === ActionType.Clear || actionType === ActionType.Remove) && userDefinedFunction.assignment) {
            operandHelper.proceedWhitAssignment(userDefinedFunction.assignment, ((operand: Operand) => {

                if (operand.operandType === operandType.parameter && operand.fieldId === parameterNumber) {
                    operand.dataType = '';
                    operand.fieldId = null;
                    operand.operandType = '';
                    operand.value = '';
                }
            }));
        }

        if (actionType === ActionType.Change && userDefinedFunction.assignment) {
            operandHelper.proceedWhitAssignment(userDefinedFunction.assignment, ((operand:Operand) => {

                if (operand.operandType === operandType.parameter && operand.fieldId === parameterNumber) {
                    operand.value = userDefinedFunction.parameters[parameterIndex as number].name;
                }
            }));
        }
    }

    private getFunction(config: ReportConfig, location: ReportLogicLocation): UserDefinedFunction {

        return config.userDefinedFunctions.find(x => x.number === this._functionNumber) as UserDefinedFunction;
    }
}


const userDefinedFunctionParameterHelper = (userDefinedFunctionNumber: number): UserDefinedFunctionParameterHelper => new UserDefinedFunctionParameterHelper(userDefinedFunctionNumber);

export default userDefinedFunctionParameterHelper;
