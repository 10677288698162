import React from 'react';
import { Unsubscribe } from 'redux';
import copyObject from '../../../infrastructure/helpers/common/copyObject';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import assignmentHelper from '../../../infrastructure/helpers/functions/assignment/assignmentHelper';
import outsideContextHelper from '../../../infrastructure/helpers/functions/common/outsideContextHelper';
import pseudocodeHelper from '../../../infrastructure/helpers/functions/common/pseudocodeHelper';
import autoCompleteHelper from '../../../infrastructure/helpers/functions/condition/autoCompleteHelper';
import conditionHelper from '../../../infrastructure/helpers/functions/condition/conditionHelper';
import conditionModifyHelper from '../../../infrastructure/helpers/functions/condition/conditionModifyHelper';
import customDataSourceHelper from '../../../infrastructure/helpers/functions/customDataSource/customDataSourceHelper';
import lookupHelper from '../../../infrastructure/helpers/functions/lookup/lookupHelper';
import operandHelper from '../../../infrastructure/helpers/functions/operand/operandHelper';
import operandRuleHelper from '../../../infrastructure/helpers/functions/operand/operandRuleHelper';
import overrideOperandHelper from '../../../infrastructure/helpers/functions/operand/overrideOperandHelper/overrideOperandHelper';
import Declaration from '../../../infrastructure/types/Functions/Declaration';
import OutsideContextDetails from '../../../infrastructure/types/Functions/OutsideContextDetails';
import actions from '../../../store/actions';
import store from '../../../store/store';
import Assignment from '../../../types/functions/Assignment/Assignment';
import Condition from '../../../types/functions/Condition/Condition';
import locationType from '../../../types/functions/Location/LocationType';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import Operand from '../../../types/functions/Operand/Operand';
import OperandRules from '../../../types/functions/Operand/OperandRules';
import OperandModalState from '../Operand/Modal/OperandModalState';
import validateOperand from '../Operand/OperandBuilder.Validation';
import ReportConfigComponentLock from '../../../types/report/ReportConfigComponentLock';
import ArrayItemLegitimacy from '../../../infrastructure/types/Functions/ArrayItemLegitimacy';
import arrayItemHelper from '../../../infrastructure/helpers/functions/common/arrayItemHelper';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';
import DeclarationBlockBuilderProps from './DeclarationBlockBuilderProps';
import DeclarationBuilderProps from './DeclarationBuilderProps';
import userDefinedFunctionHelper from '../../../infrastructure/helpers/functions/userDefinedFunctions/userDefinedFunctionHelper';
import UserDefinedFunctionParameter from '../../../types/report/UserDefinedFunctionParameter';
import operandType from '../../../infrastructure/constants/functions/operandType';
import ActionType from '../../../types/report/ActionType';

interface State<T> {
    showWarningModal: boolean,
    location?: ReportLogicLocation,
    setupRemove?: (x: T[], y?: T) => void,
    setupAdd?: (x: T, y: T[]) => void,
    reportConfigComponentLock?: ReportConfigComponentLock,
    error?: string,
    message?: string,
    title?: string,
    actionType: ActionType
}
class DeclarationBlockBuilder<T extends Declaration> extends React.Component<DeclarationBlockBuilderProps<T>, State<T>> {
    private unsubscribeOperandSave: Unsubscribe | undefined;

    constructor(props: DeclarationBlockBuilderProps<T>) {
        super(props);

        this.state = {
            showWarningModal: false,
            location: {} as ReportLogicLocation,
            setupAdd: () => { },
            setupRemove: () => { },
            reportConfigComponentLock: {} as ReportConfigComponentLock,
            error: '',
            message: '',
            title: '',
            actionType: ActionType.Add
        }
        this.onAddDeclarationClick = this.onAddDeclarationClick.bind(this);
        this.onRemoveDeclarationClick = this.onRemoveDeclarationClick.bind(this);

        this.onAddAssignmentClick = this.onAddAssignmentClick.bind(this);
        this.onRemoveAssignmentClick = this.onRemoveAssignmentClick.bind(this);

        this.onLogicalOperatorChange = this.onLogicalOperatorChange.bind(this);
        this.onParenthesesChange = this.onParenthesesChange.bind(this);
        this.onAddConditionClick = this.onAddConditionClick.bind(this);
        this.onRemoveConditionClick = this.onRemoveConditionClick.bind(this);
        this.onComparisonTypeChange = this.onComparisonTypeChange.bind(this);
        this.onValidateDeclarationClick = this.onValidateDeclarationClick.bind(this);

        this.onPastePieceOfCodeClick = this.onPastePieceOfCodeClick.bind(this);
        this.allowPaste = this.allowPaste.bind(this);

        this.onOperandClick = this.onOperandClick.bind(this);
        this.onOperandSave = this.onOperandSave.bind(this);
        this.onDeclarationChange = this.onDeclarationChange.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.resetAssignment = this.resetAssignment.bind(this);
        this.updateAssignment = this.updateAssignment.bind(this);
        this.updateCondition = this.updateCondition.bind(this);
        this.updateConditionOperand = this.updateConditionOperand.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.getDeclaration = this.getDeclaration.bind(this);
        this.getCondition = this.getCondition.bind(this);
        this.onWarningModalOkClick = this.onWarningModalOkClick.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribeOperandSave = store.subscribe(this.onOperandSave);
    }

    componentWillUnmount(): void {
        (this.unsubscribeOperandSave as Unsubscribe)();
    }

    onAddDeclarationClick(setupAdd: (x: T, y: T[]) => void, title?: string, message?: string): void {

        if (title && message) {
            this.setState({ showWarningModal: true, setupAdd, title, message, actionType: ActionType.Add });
        }
        else {
            this.setState({ setupAdd, actionType: ActionType.Add }, () => {

                let declarations = this.props.declarationHelper.getDeclarations(this.props.reportConfig, null as any, this.props.componentType);

                this.onAddDeclarationApproved(declarations);
            });
        }
    }

    async onRemoveDeclarationClick(location: ReportLogicLocation, setupRemove?: (x: T[], y?: T) => void, reportConfigComponentLock?: ReportConfigComponentLock, error?: string, title?: string, message?: string): Promise<void> {

        title = title || 'Remove item';

        message = message || 'Are you sure you want to delete this item? This action cannot be undone.';

        this.setState({ showWarningModal: true, location, setupRemove, reportConfigComponentLock, error, title, message, actionType: ActionType.Remove });
    }

    async onWarningModalOkClick() {

        let declarations = this.props.declarationHelper.getDeclarations(this.props.reportConfig, null as any, this.props.componentType);
        let declaration = this.props.declarationHelper.getDeclaration(declarations, this.state.location as ReportLogicLocation);

        if (this.state.error) {
            declaration.errors = [this.state.error];

            this.props.onDeclarationChange(declarations);
            return;
        }

        let isLocked = await this.tryLock(this.state.reportConfigComponentLock);

        if (!isLocked) {
            if (this.state.actionType === ActionType.Remove) {
                this.onRemoveDeclarationApproved(declaration, declarations)
            }
            else if (this.state.actionType === ActionType.Add) {
                this.onAddDeclarationApproved(declarations);
            }
        }

        this.setState({ showWarningModal: false });
    }

    onAddDeclarationApproved(declarations: T[]) {

        let declaration = this.props.declarationHelper.getEmpty(this.props.reportConfig.rawDataSourceId, '');

        this.props.declarationHelper.add(declarations, declaration);

        if (this.state.setupAdd) {
            this.state.setupAdd(declaration, declarations);
        }

        this.props.onDeclarationChange(declarations);
    }

    onRemoveDeclarationApproved(declaration: T, declarations: T[]) {

        let error = this.props.validateRemoveDeclaration(this.state.location as ReportLogicLocation);

        if (error) {

            declaration.errors = [error];

            this.props.onDeclarationChange(declarations);

            return;
        }

        let reduced = this.props.declarationHelper.remove(declarations, this.state.location as ReportLogicLocation);

        if (this.state.setupRemove) {
            this.state.setupRemove(reduced);
        }

        this.props.onDeclarationChange(reduced);
    }

    async onAddAssignmentClick(location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {
            const update = (assignment: Assignment): void => assignmentHelper.add(assignment, location.assignmentNumber, this.props.initialDoNotPopulate);

            this.updateAssignment(location, update);
        }
    }

    async onRemoveAssignmentClick(location: ReportLogicLocation, isSingle?: boolean, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            const update = (assignment: Assignment): void => assignmentHelper.remove(assignment, location.assignmentNumber);

            this.updateAssignment(location, update);
        }
    }

    async onLogicalOperatorChange(location: ReportLogicLocation, operator: string, event?: React.ChangeEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            const update = (condition: Condition): Condition => {

                let target = conditionHelper.getByNumber(condition, location.conditionNumber);

                conditionModifyHelper.changeLogicalOperator(target, operator);

                return condition;
            };

            this.updateCondition(location, update);
        }
    }

    async onParenthesesChange(location: ReportLogicLocation, count: number, type: string, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            const update = (condition: Condition): Condition => {

                let target = conditionHelper.getByNumber(condition, location.conditionNumber);

                conditionModifyHelper.changeParenthesesCount(target, count, type);

                return condition;
            };

            this.updateCondition(location, update);
        }
    }

    async onAddConditionClick(location: ReportLogicLocation, operator: string | null, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            const update = (condition: Condition): Condition => conditionHelper.add(condition, operator);

            this.updateCondition(location, update);
        }
    }

    async onRemoveConditionClick(location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            const update = (condition: Condition): Condition => conditionHelper.remove(condition, location.conditionNumber);

            this.updateCondition(location, update);
        }
    }

    async onComparisonTypeChange(location: ReportLogicLocation, value: string, event?: React.ChangeEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {
        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            const update = (condition: Condition): Condition => {

                let target = conditionHelper.getByNumber(condition, location.conditionNumber);

                Object.assign(target, { comparisonType: value });

                return condition;
            };

            this.updateCondition(location, update);
        }
    }

    async onValidateDeclarationClick(location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            let declarations = this.props.declarationHelper.getDeclarations(this.props.reportConfig, location, this.props.componentType);

            let declaration = this.props.declarationHelper.getDeclaration(declarations, location);

            this.props.validate(declaration);

            this.props.onDeclarationChange(declarations);
        }
    }

    async onPastePieceOfCodeClick(location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {

            if (!typeHelper.isObject(this.props.clipboard)) {
                return;
            }

            let clipboard = this.props.clipboard as ReportLogicLocation;

            if (clipboard.pieceOfCode === locationType.pieceOfCode.condition) {

                let update = (condition: Condition): Condition => {

                    let source = this.getCondition(clipboard);

                    return copyObject(source);
                };

                this.updateCondition(location, update);

                return;
            }

            if (clipboard.pieceOfCode === locationType.pieceOfCode.assignmentValue) {

                let declaration = this.getDeclaration(clipboard);

                let source = assignmentHelper.getByNumber(declaration.assignment, clipboard.assignmentNumber);

                let bundle = this.props.getOperandRuleBundle(location, source.value, source.value, false);

                let error = validateOperand(source.value, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields, bundle[0]);
                if (error) {

                    this.onDeclarationChange(location, { errors: [error] } as T);
                    return;
                }

                let update = (assignment: Assignment): void => {

                    let target = assignmentHelper.getByNumber(assignment, location.assignmentNumber) as Assignment;

                    target.doNotPopulate = false;
                    target.value = copyObject(source.value);
                };

                this.updateAssignment(location, update);
            }
        }
    }

    allowPaste(location: ReportLogicLocation): boolean {

        if (!typeHelper.isObject(this.props.clipboard)) {

            return false;
        }

        let proceed =
            (this.props.clipboard as ReportLogicLocation).statement === location.statement &&
            (this.props.clipboard as ReportLogicLocation).pieceOfCode === location.pieceOfCode;

        if (!proceed) {
            return false;
        }

        let isSameItem =
            (this.props.clipboard as ReportLogicLocation).statementNumber === location.statementNumber &&
            (this.props.clipboard as ReportLogicLocation).fieldId === location.fieldId &&
            (this.props.clipboard as ReportLogicLocation).assignmentNumber === location.assignmentNumber;

        if (isSameItem) {
            return false;
        }

        return (
            this.props.declarationHelper.getDataSourceId(this.props.reportConfig, this.props.clipboard as ReportLogicLocation, this.props.componentType) ===
            this.props.declarationHelper.getDataSourceId(this.props.reportConfig, location, this.props.componentType)
        );
    }

    async onOperandClick(location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {
            let operand: Operand;
            let outsideContextDetails: OutsideContextDetails;
            let operandRules: OperandRules;
            let title: string;
            let doNotPopulate: boolean;
            let dataSource1Id: number | null;
            let dataSource2Id: number | null;
            let arrayItemLegitimacy: ArrayItemLegitimacy;
            let parameters: UserDefinedFunctionParameter[] | null;

            let customDataSourceLegitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, this.props.declarationHelper.getDataSourceId(this.props.reportConfig, location, this.props.componentType));

            if (location.pieceOfCode === locationType.pieceOfCode.conditionOperand) {

                let condition = conditionHelper.getExactConditionByNumber(this.getCondition(location), location.conditionNumber);

                operand = conditionHelper.getOperand(condition, location);

                let previousLocationFildId = location.fieldId;

                operandRules = operandRuleHelper.getOperandRules(
                    location,
                    condition,
                    this.props.getOperandRuleBundle,
                    operand,
                    this.props.reportConfig,
                    customDataSourceLegitimacy,
                    this.props.collections
                );

                location.fieldId = previousLocationFildId;
                outsideContextDetails = outsideContextHelper.get(condition, null, location, this.props.reportConfig.lookups, this.props.type);

                doNotPopulate = false;

                let conditionOperand = conditionHelper.getExactConditionOperand(this.getCondition(location), location);
                let callStack = operandHelper.getOperandCallStack(conditionOperand, location.argumentIndexes);
                arrayItemLegitimacy = arrayItemHelper.getLegitimacy(callStack, operand);

                title = `Operand ${location.operandNumber}`;
            }
            else {
                let declaration = this.getDeclaration(location);

                operand = assignmentHelper.getValue(declaration.assignment, location, true);
                let root = assignmentHelper.getValue(declaration.assignment, location, false);

                let isRoot = (operand === root);

                outsideContextDetails = outsideContextHelper.get(null, root, location, this.props.reportConfig.lookups, this.props.type);

                let bundle = this.props.getOperandRuleBundle(location, operand, root, true);

                operandRules = bundle[0];

                doNotPopulate = isRoot && (assignmentHelper.getByNumber(declaration.assignment, location.assignmentNumber)).doNotPopulate;

                title = pseudocodeHelper.wrapInBrackets(bundle[1]);

                let target = assignmentHelper.getByNumber(declaration.assignment, location.assignmentNumber);
                let callStack = operandHelper.getOperandCallStack(target.value, location.argumentIndexes);
                arrayItemLegitimacy = arrayItemHelper.getLegitimacy(callStack, operand);
            }

            if (location.statement === locationType.statement.lookup) {

                let lookup = lookupHelper.getByNumber(this.props.reportConfig.lookups, location.statementNumber);

                dataSource1Id = lookup.dataSource1Id;
                dataSource2Id = lookup.dataSource2Id;
            }
            else {

                dataSource1Id = outsideContextDetails.isOutsideContext ? outsideContextDetails.dataSourceId : this.props.declarationHelper.getDataSourceId(this.props.reportConfig, location, this.props.componentType);
                dataSource2Id = null;
            }

            let operandForModal = operandHelper.prepareForEdit(operand, operandRules, outsideContextDetails);

            if (location.statement === locationType.statement.userDefinedFunction) {

                let func = userDefinedFunctionHelper.getByNumber(this.props.reportConfig.userDefinedFunctions, location.statementNumber);

                parameters = func.parameters;

                if (!typeHelper.isString(operandForModal.operandType)) {

                    operandForModal.operandType = operandType.parameter;
                }
            }
            else {

                parameters = null;
            }

            let state: OperandModalState = {
                title: title,
                isOpen: true,
                operand: operandForModal,
                location: location,
                dataSource1Id: dataSource1Id,
                dataSource2Id: dataSource2Id,
                operandRules: operandRules,
                customDataSourceLegitimacy: customDataSourceLegitimacy,
                specialScenario: { doNotPopulate: doNotPopulate, pseudoFunction: null },
                customDataSourceContext: outsideContextDetails.customDataSource,
                error: null,
                arrayItemLegitimacy: arrayItemLegitimacy,
                parameters: parameters,
            };

            this.dispatchStateChange(state);
        }
    }

    onOperandSave(): void {

        let appState = store.getState();
        if (appState.action !== actions.operandModal.save) {
            return;
        }

        let state = appState.operandModalSave;
        if (state.location.statement !== this.props.type) {
            return;
        }

        let error = state.specialScenario.doNotPopulate ? null : validateOperand(state.operand, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields, state.operandRules);

        let changes = { error: error } as OperandModalState;

        if (error) {
            this.dispatchStateChange(changes);
            return;
        }

        if (state.location.pieceOfCode === locationType.pieceOfCode.conditionOperand) {

            this.updateCondition(state.location, condition => this.updateConditionOperand(state, condition));
        }
        else if (state.specialScenario.doNotPopulate) {

            this.resetAssignment(state.location, true);
        }
        else {
            this.updateAssignment(state.location, assignment => this.updateValue(state.location, state.operand, assignment));
        }

        changes.isOpen = false;

        this.dispatchStateChange(changes);
    }

    onDeclarationChange(location: ReportLogicLocation, changes: T): void {

        let declarations = this.props.declarationHelper.getDeclarations(this.props.reportConfig, location, this.props.componentType);

        let declaration = this.props.declarationHelper.getDeclaration(declarations, location);

        Object.assign(declaration, changes);

        this.props.onDeclarationChange(declarations);
    }

    async onCopyClick(clipboard: ReportLogicLocation, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        let isLocked = await this.tryLock(reportConfigComponentLock);
        if (!isLocked) {
            this.props.onClipboardChange(clipboard);
        }
    }

    async tryLock(reportConfigComponentLock?: ReportConfigComponentLock): Promise<boolean> {

        if (reportConfigComponentLock && this.props.onComponentContainerClick) {
            return await this.props.onComponentContainerClick(reportConfigComponentLock);
        }
        return false;
    }

    resetAssignment(location: ReportLogicLocation, doNotPopulate: boolean): void {

        const update = (assignment: Assignment): void => {

            let item = assignmentHelper.getByNumber(assignment, location.assignmentNumber);

            item.doNotPopulate = doNotPopulate;
            item.value = operandHelper.getEmpty();
        };

        this.updateAssignment(location, update);
    }

    updateAssignment(location: ReportLogicLocation, update: (assignment: Assignment) => void): void {

        let declarations = this.props.declarationHelper.getDeclarations(this.props.reportConfig, location, this.props.componentType);

        let declaration = this.props.declarationHelper.getDeclaration(declarations, location);

        update(declaration.assignment);

        this.props.onDeclarationChange(declarations);
    }

    updateCondition(location: ReportLogicLocation, update: (condition: Condition) => Condition): void {

        const updateAssignment = (assignment: Assignment): void => {

            let target = assignmentHelper.getByNumber(assignment, location.assignmentNumber);

            target.condition = update(target.condition as Condition);
        };

        this.updateAssignment(location, updateAssignment);
    }

    updateConditionOperand(state: OperandModalState, condition: Condition): Condition {

        let operand = conditionHelper.getOperand(condition, state.location);

        overrideOperandHelper.override(operand, state.operand, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields, this.props.reportConfig.variables);

        autoCompleteHelper.execute(condition);

        return condition;
    }

    updateValue(location: ReportLogicLocation, operand: Operand, assignment: Assignment): void {

        let item = assignmentHelper.getByNumber(assignment, location.assignmentNumber);

        item.doNotPopulate = false;

        let target = assignmentHelper.getValue(assignment, location, true);

        overrideOperandHelper.override(target, operand, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields, this.props.reportConfig.variables);
    }

    getDeclaration(location: ReportLogicLocation): T {

        let declarations = this.props.declarationHelper.getDeclarations(this.props.reportConfig, location, this.props.componentType);

        return this.props.declarationHelper.getDeclaration(declarations, location);
    }

    getCondition(location: ReportLogicLocation): Condition {

        let declaration = this.getDeclaration(location);

        let item = assignmentHelper.getByNumber(declaration.assignment, location.assignmentNumber);

        return item.condition as Condition;
    }

    dispatchStateChange(changes: OperandModalState): void {

        store.dispatch({ type: actions.operandModal.change, payload: changes });
    }

    render(): JSX.Element {

        let props: DeclarationBuilderProps<T> = {
            isWaiting: this.props.isWaiting,
            isReadOnly: this.props.isReadOnly,
            declarationBatchNumber: this.props.declarationBatchNumber,
            reportConfig: this.props.reportConfig,
            counts: this.props.counts,
            collections: this.props.collections,
            onAddDeclarationClick: this.onAddDeclarationClick,
            onRemoveDeclarationClick: this.onRemoveDeclarationClick,
            onAddAssignmentClick: this.onAddAssignmentClick,
            onRemoveAssignmentClick: this.onRemoveAssignmentClick,
            onResetAssignmentClick: this.resetAssignment,
            onLogicalOperatorChange: this.onLogicalOperatorChange,
            onParenthesesChange: this.onParenthesesChange,
            onAddConditionClick: this.onAddConditionClick,
            onRemoveConditionClick: this.onRemoveConditionClick,
            onComparisonTypeChange: this.onComparisonTypeChange,
            onOperandClick: this.onOperandClick,
            onDeclarationChange: this.onDeclarationChange,
            onCopyClick: this.onCopyClick,
            onPasteClick: this.onPastePieceOfCodeClick,
            onValidateDeclarationClick: this.onValidateDeclarationClick,
            validateRemoveDeclaration: this.props.validateRemoveDeclaration,
            allowPaste: this.allowPaste,
            onDragAndDrop: this.props.onDragAndDrop,
            onComponentContainerClick: this.props.onComponentContainerClick,
            componentType: this.props.componentType
        };

        return (
            <>
                {React.createElement(this.props.childComponentType, props)}
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onWarningModalOkClick}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title={this.state.title}
                        message={this.state.message} />
                }
            </>
        );
    }
}

export default DeclarationBlockBuilder;
