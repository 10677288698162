import { mapToBasicOption } from '../../../infrastructure/helpers/html/selectOptionHelper';
import dataSourceKind from '../../../infrastructure/constants/dataSourceKind';
import fileExtension from '../../../infrastructure/constants/fileExtension';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import SelectOption from '../../../infrastructure/types/SelectOption';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';

const ingestionDataTypeOptions: SelectOption[] = [dataSourceKind.raw, dataSourceKind.ref, dataSourceKind.rawAndRef].map(mapToBasicOption);
const sourceOptions: SelectOption[] = [fileExtension.csv, fileExtension.xml, fileExtension.json].map(mapToBasicOption);

const resolveSource = (config: IngestionConfig): string => {

    if (stringHelper.isNonEmpty(config.xmlSectionName)) {

        return fileExtension.xml;
    }

    if (stringHelper.isNonEmpty(config.jsonSectionName)) {

        return fileExtension.json;
    }

    return fileExtension.csv;
};

const resolveMasterFileLocationValue = (source: string, config: IngestionConfig): string => {

    switch (source) {

        case fileExtension.xml:
            return config.xmlSectionName;

        case fileExtension.json:
            return config.jsonSectionName;

        default:
            return '';
    }
};

const isSourcedFromMasterFile = (config: IngestionConfig): boolean => {

    return (
        stringHelper.isNonEmpty(config.xmlSectionName) ||
        stringHelper.isNonEmpty(config.jsonSectionName)
    );
};

export {
    ingestionDataTypeOptions,
    sourceOptions,
    resolveSource,
    resolveMasterFileLocationValue,
    isSourcedFromMasterFile
};
