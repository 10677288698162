import actions from "../../../../store/actions";
import store from "../../../../store/store";
import locationType from "../../../../types/functions/Location/LocationType";
import ComponentType from "../../../../types/report/ComponentType";
import reportConfigNavigation from "../navigation/reportConfigNavigation";

const onReferenceSelected = (value: string): void => {

    let parts = value.split('-');

    let componentType = getComponentType(parts[0]);

    if (parts.length === 2) {

        let simpleComponentTypes = [ComponentType.Variable, ComponentType.Lookup, ComponentType.Filter, ComponentType.UnderReportFilter, ComponentType.OverReportFilter, ComponentType.EndpointFilter];

        if (simpleComponentTypes.includes(componentType )) {
            reportConfigNavigation.navigateToItem(value);
        }

        store.dispatch({ type: actions.component.selected, payload: { number: parseInt(parts[parts.length - 1]), callback: () => reportConfigNavigation.navigateToItem(value), componentType } });
    }
    else{

        if (parts[0] === locationType.statement.reportField && parts[2] === locationType.statement.case) {

            value = `${parts[0]}-${parts[1]}`;
        }
        else if (parts[0] === locationType.statement.aggregatedRecordField && parts[2] === locationType.statement.aggregation) {

            value = `${parts[0]}-${parts[1]}`;
        }
        else if (parts[0] === locationType.statement.dictionaryField && parts[2] === locationType.statement.dictionary) {

            value = `${parts[0]}-${parts[1]}`;
        }
        else {
            value = `${parts[2]}-${parts[3]}`;
        }

        store.dispatch({ type: actions.component.selected, payload: { number: parseInt(parts[parts.length - 1]), callback: () => reportConfigNavigation.navigateToItem(value), componentType } });
    }
}

const toggleSection = (sectionId: string) => {
    document.getElementById(`btn-expand-${sectionId}`)?.click();
}

const getComponentType = (value: string): ComponentType => {

    switch (value) {
        case locationType.statement.aggregatedRecordField: return ComponentType.AggregationField;
        case locationType.statement.aggregation: return ComponentType.Aggregation;
        case locationType.statement.case: return ComponentType.Case;
        case locationType.statement.reportField: return ComponentType.CaseField;
        case locationType.statement.filter: return ComponentType.Filter;
        case locationType.statement.endpointFilter: return ComponentType.EndpointFilter;
        case locationType.statement.underReportFilter: return ComponentType.UnderReportFilter;
        case locationType.statement.overReportFilter: return ComponentType.OverReportFilter;
        case locationType.statement.validationCore: return ComponentType.AccuracyValidationCore;
        case locationType.statement.validationStandard: return ComponentType.AccuracyValidationStandard;
        case locationType.statement.validation: return ComponentType.AccuracyValidation;
        case locationType.statement.variable: return ComponentType.Variable;
        case locationType.statement.lookup: return ComponentType.Lookup;
        case locationType.statement.isinSelector: return ComponentType.IsinSelector;
        case locationType.statement.leiSelector: return ComponentType.LeiSelector;
        case locationType.statement.exchangeRatesSelector: return ComponentType.ExchangeRatesSelector;
        case locationType.statement.lseSelector: return ComponentType.LseSelector;
        case locationType.statement.annaDsbUpiEnrichmentSelector: return ComponentType.AnnaDsbUpiEnrichmentSelector;
        case locationType.statement.annaDsbUpiEnrichmentSelectorKey: return ComponentType.AnnaDsbUpiEnrichmentSelector;
        case locationType.statement.annaDsbUpiSelector: return ComponentType.AnnaDsbUpiSelector;
        case locationType.statement.fcaRegulatedEntitiesSelector: return ComponentType.FCARegulatedEntitiesSelector;
        case locationType.statement.firdsEsmaInstrumentSelector: return ComponentType.FirdsEsmaInstrumentSelectors;
        case locationType.statement.firdsEsmaInstrumentSelectorKey: return ComponentType.FirdsEsmaInstrumentSelectors;
        case locationType.statement.firdsFcaInstrumentSelector: return ComponentType.FirdsFcaInstrumentSelectors;
        case locationType.statement.firdsFcaInstrumentSelectorKey: return ComponentType.FirdsFcaInstrumentSelectors;
        case locationType.statement.dictionary: return ComponentType.Dictionary;
        case locationType.statement.dictionaryField: return ComponentType.DictionaryField;

        default: break;
    }

    return ComponentType.None;
}

export { onReferenceSelected, toggleSection, getComponentType }