import { firdsResultSetTypes } from '../../../infrastructure/constants/functions/specialOperandTypes';
import aggregationFunctionLegitimacy from '../../../infrastructure/constants/functions/aggregationFunctionLegitimacy';
import aggregationFunctions from '../../../infrastructure/constants/functions/definition/aggregationFunctions';
import CustomDataSourceFields from '../../../types/report/CustomDataSourceFields';
import dataType from '../../../infrastructure/constants/dataType';
import functionHelper from '../../../infrastructure/helpers/functions/function/functionHelper';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import Lookup from '../../../types/functions/Lookup';
import Operand from '../../../types/functions/Operand/Operand';
import operandDisplayHelper from '../../../infrastructure/helpers/functions/operand/operandDisplayHelper';
import operandHelper from '../../../infrastructure/helpers/functions/operand/operandHelper';
import operandRuleHelper from '../../../infrastructure/helpers/functions/operand/operandRuleHelper';
import OperandRules from '../../../types/functions/Operand/OperandRules';
import operandType from '../../../infrastructure/constants/functions/operandType';
import regexPattern from './OperandBuilder.Validation.Regex';
import ReportFieldDefinition from '../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../types/report/ReportVariable';
import specialReturnType from '../../../infrastructure/constants/functions/definition/specialReturnType';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';
import typeConversionHelper from '../../../infrastructure/helpers/functions/operand/typeConversionHelper';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import Dictionary from '../../../types/report/Dictionary';
import OperandDetails from '../../../types/functions/Operand/OperandDetails';
import UserDefinedFunction from '../../../types/report/UserDefinedFunction';

const getGenericMessage = (dataType: string): string => `Valid ${dataType} value is required`;

const getGenericResult = (isValid: boolean, dataType: string): string | null => {

    if (isValid) {
        return null;
    }

    return getGenericMessage(dataType);
};

const validateDataType = (operandRules: OperandRules, dataType: string, operand: Operand): string | null => {

    if (typeHelper.isArray(operandRules.specialOperandTypes)) {

        let types = (operandRules.specialOperandTypes as string[]).join(' or ');

        return `${types} operand is expected`;
    }

    if (!typeHelper.isArray(operandRules.allowedTypes)) {
        return null;
    }

    if (operandRules.injectTypeConversion && typeConversionHelper.inject(operandRules.allowedTypes as string[], dataType, operand)) {
        return null;
    }

    if (!(operandRules.allowedTypes as string[]).includes(dataType)) {
        return `This ${operand.operandType} is not allowed in the current context because of type mismatch. Operand type: ${dataType}. Expected types: ${(operandRules.allowedTypes as string[]).join(', ')}.`;
    }

    return null;
};

const validateAllowedValue = (allowedValues: string[] | null, value: string): string | null => {

    if (!typeHelper.isArray(allowedValues)) {
        return null;
    }

    if (!(allowedValues as string[]).includes(value)) {

        return `Only the following values are allowed: ${(allowedValues as string[]).join(', ')}`;
    }

    return null;
};

const validateStringLength = (operand: Operand, operandRules: OperandRules): string | null => {

    if (operand.dataType !== dataType.string) {
        return null;
    }

    if (!typeHelper.isNumber(operandRules.stringLength)) {
        return null;
    }

    if (operand.value.length > (operandRules.stringLength as number)) {

        return `Max character count is ${operandRules.stringLength}`;
    }

    return null;
};

const validateConstant = (operand: Operand, operandRules: OperandRules): string | null => {

    if (!typeHelper.isString(operand.value)) {

        return getGenericMessage(operand.dataType);
    }

    let dataTypeError = validateDataType(operandRules, operand.dataType, operand);
    if (dataTypeError) {
        return dataTypeError;
    }

    let allowedValueError = validateAllowedValue(operandRules.allowedValues, operand.value);
    if (allowedValueError) {
        return allowedValueError;
    }

    let stringLengthError = validateStringLength(operand, operandRules);
    if (stringLengthError) {
        return stringLengthError;
    }

    switch (operand.dataType) {

        case dataType.integer:
            return getGenericResult(regexPattern.integer.test(operand.value), operand.dataType);

        case dataType.decimal:
            return getGenericResult(regexPattern.decimal.test(operand.value), operand.dataType);

        case dataType.date:
            return getGenericResult(regexPattern.isoDate.test(operand.value), operand.dataType);

        case dataType.time:
            return getGenericResult(regexPattern.time.test(operand.value), operand.dataType);

        case dataType.dateTime:
            return getGenericResult(regexPattern.isoDateTime.test(operand.value), operand.dataType);

        case dataType.unixTime:
            return getGenericResult(regexPattern.unixTime.test(operand.value), operand.dataType);
    }

    return null;
};

const validateField = (operand: Operand, operandRules: OperandRules, details: OperandDetails): string | null => {

    if (!typeHelper.isNumber(operand.fieldId) &&
        !typeHelper.isNumber(operand.reportFieldId) &&
        !typeHelper.isNumber(operand.customDataSourceFieldId)) {

        return 'A field must be selected';
    }

    return validateDataType(operandRules, details.dataType, operand);
};

const validateVariable = (operand: Operand, variables: ReportVariable[], operandRules: OperandRules): string | null => {

    if (!typeHelper.isNumber(operand.variableNumber)) {
        return 'A variable must be selected';
    }

    let variable = variables.find(x => x.number === operand.variableNumber) as ReportVariable;

    return validateDataType(operandRules, variable.dataType, operand);
};

const validateFunction = (operand: Operand, operandRules: OperandRules): string | null => {

    if (stringHelper.isEmpty(operand.function)) {
        return 'A function must be selected';
    }

    let definition = functionHelper.getFunctionDefinition(operand.function);

    if (definition.returnType !== specialReturnType.generic) {

        return validateDataType(operandRules, definition.returnType, operand);
    }

    return null;
};

const validateDictionary = (operand: Operand, dictionaries: Dictionary[]): string | null => {

    if (!typeHelper.isNumber(operand.dictionaryNumber)) {

        return 'A dictionary must be selected';
    }

    if (!typeHelper.isArray(operand.arguments)) {

        return 'Parameters must be provided';
    }

    let parameters = operand.arguments as Operand[];

    let dictionary = dictionaries.find(x => x.number === operand.dictionaryNumber) as Dictionary;

    if (dictionary.fields.length !== parameters.length) {

        return `Provided arguments count does not match the dictionary definition, expected ${dictionary.fields.length}`;
    }

    return null;
};

const validateSpecialOperandTypeLegitimacy = (operandRules: OperandRules, specialOperandType: string): string | null => {

    if (!operandRuleHelper.isSpecialOperandTypeAllowed(operandRules, specialOperandType)) {

        return `${specialOperandType} operand is not expected`;
    }

    return null;
};

const validateLookupResultSet = (operand: Operand, operandRules: OperandRules): string | null => {

    let legitimacyError = validateSpecialOperandTypeLegitimacy(operandRules, operandType.lookupResultSet);
    if (legitimacyError) {

        return legitimacyError;
    }

    if (!typeHelper.isNumber(operand.lookupNumber)) {

        return 'Multi Result Lookup is required';
    }

    return null;
};

const validateUserDefinedFunction = (operand: Operand, operandRules: OperandRules, functions: UserDefinedFunction[]): string | null => {

    if (!typeHelper.isNumber(operand.functionNumber)) {

        return 'A dictionary must be selected';
    }

    if (!typeHelper.isArray(operand.arguments)) {

        return 'Parameters must be provided';
    }

    let parameters = operand.arguments as Operand[];

    let func = functions.find(x => x.number === operand.functionNumber) as UserDefinedFunction;

    if (func.parameters.length !== parameters.length) {

        return `Provided arguments count does not match the dictionary definition, expected ${func.parameters.length}`;
    }

    return validateDataType(operandRules, func.returnType, operand);
};

const validateOperand = (operand: Operand, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, operandRules: OperandRules): string | null => {

    let getOperandDetails = (op: Operand): OperandDetails => {

        let details = operandDisplayHelper.getOperandDetails(op, functions, lookups, dictionaries, [], dataSources, reportFields, customDataSourceFields, null);

        return details;
    }

    let error = validateAggregationFunction(operand, operandRules);
    if (error) {
        return error;
    }

    if (operand.operandType === operandType.constant) {
        return validateConstant(operand, operandRules);
    }

    if (operand.operandType === operandType.variable) {
        return validateVariable(operand, variables, operandRules);
    }

    if (operand.operandType === operandType.function) {
        return validateFunction(operand, operandRules);
    }

    if (operand.operandType === operandType.dictionary) {

        let details = getOperandDetails(operand);

        var fieldError = validateField(operand, operandRules, details);
        if (fieldError) {
            return fieldError;
        }

        return validateDictionary(operand, dictionaries);
    }

    if (operand.operandType === operandType.group ||
        operand.operandType === operandType.leiResultSetLevel1 ||
        operand.operandType === operandType.exchangeRatesResultSet ||
        operand.operandType === operandType.annaDsbUpiEnrichmentResultSet ||
        firdsResultSetTypes.includes(operand.operandType)) {

        return validateSpecialOperandTypeLegitimacy(operandRules, operand.operandType);
    }

    if (operand.operandType === operandType.lookupResultSet) {
        return validateLookupResultSet(operand, operandRules);
    }

    if (operand.operandType === operandType.arrayItem) {
        return validateDataType(operandRules, operand.dataType, operand);
    }

    if (operand.operandType === operandType.parameter) {
        return validateDataType(operandRules, operand.dataType, operand);
    }

    if (operand.operandType === operandType.userDefinedFunction) {
        return validateUserDefinedFunction(operand, operandRules, functions);
    }

    let details = getOperandDetails(operand);

    return validateField(operand, operandRules, details);
};

const validateAggregationFunction = (operand: Operand, operandRules: OperandRules): string | null => {

    if (!operandHelper.isSetUp(operand)) {

        return null;
    }

    let isAggregationFunction = operandHelper.isValidFunction(operand) && aggregationFunctions.includes(operand.function);

    let isConstant = operandHelper.isValidConstant(operand);

    if (operandRules.aggregationFunctionLegitimacy === aggregationFunctionLegitimacy.required && !isAggregationFunction && !isConstant) {

        return 'Aggregation function expected';
    }

    //if (operandRules.aggregationFunctionLegitimacy === aggregationFunctionLegitimacy.forbidden && isAggregationFunction) {

    //    return 'Aggregation function is not allowed in this context';
    //}

    return null;
};

export default validateOperand;
