import ModalSize from './ModalSize';

const getModalDialogClasses = (size?: ModalSize): string => {
    let classes = ['modal-dialog'];

    if (size === 'small') {
        classes.push('modal-sm');
    }
    else if (size === 'medium') {
        classes.push('modal-l');
    }
    else if (size === 'large') {
        classes.push('modal-lg');
    }
    else if (size === 'extra-large') {
        classes.push('modal-xl');
    }

    return classes.join(' ');
};

export { getModalDialogClasses };