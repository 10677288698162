const guidance = {
    fileType: 'A file extension is the suffix at the end of a filename that indicates what type of file it is. For example, in the filename "control_now.csv," the . csv is the file extension. It indicates the file is a comma delimeted file.',
    name: 'Name of the ingestion config.',
    filenames: 'A list of file names that will be used for report generation',
    sourcedFrom: 'Ingestion config might represent a particular section from a parent XML or JSON file',
    masterFileLocation: 'Name of the section inside a parent file',
    description: 'Free text to explain business description of file.',
    dataSourceKind: 'This is the value that will be used by system to identify the file type: Raw/Ref/Both.',
    emptyValueToken: 'A list of values that can signify an empty value.\nExample: If populated with \'NULL\' then all string values \'NULL\' in the file will be read as an empty value',
    transposedData: 'Tick this box where JSON formatted data appears as a single object (i.e. dictionary - containing a sequence of key-value pairs) rather than a list of objects with matching keys.'
};

export default guidance;
