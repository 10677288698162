import { comparisonTypeDisplayMap } from '../../../../infrastructure/constants/functions/comparisonType';
import logicalOperator from '../../../../infrastructure/constants/functions/logicalOperator';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import Condition from '../../../../types/functions/Condition/Condition';


const comparisonTypeOptions: SelectOption[] = Object.keys(comparisonTypeDisplayMap).map(x => {
    return {
        value: x,
        text: comparisonTypeDisplayMap[x]
    };
});

const logicalOperatorOptions: SelectOption[] = Object.values(logicalOperator).map(x => {
    return {
        value: x,
        text: x.toUpperCase()
    };
});


const reorder = (originalCondition: Condition, dragNumber: number, dropNumber: number): void => {
    let draggedCondition = {} as Condition;
    let dropCondition = {} as Condition;
    extractClausesAndConditions(originalCondition, dragNumber, dropNumber, (extractedCondition: Condition, isDragged: boolean) => {
        if (isDragged) {
            draggedCondition = Object.assign({}, extractedCondition);
        }
        else {
            dropCondition = Object.assign({}, extractedCondition);
        }

    });
    if (draggedCondition?.operand1 && dropCondition?.operand1) {
        swapConditions(originalCondition, dragNumber, dropNumber, draggedCondition, dropCondition, 0);
    }
}

const swapConditions = (condition: Condition, dragNumber: number, dropNumber: number, draggedCondition: Condition, dropCondition: Condition, setConditionsCount: number): void => {
    if (dragNumber === condition.number) {
        assignValuesToCondition(condition, dropCondition);
        setConditionsCount++;
    }
    else if (dropNumber === condition.number) {
        assignValuesToCondition(condition, draggedCondition);
        setConditionsCount++;
    }
    if (setConditionsCount < 2) {
        if (condition.and != null) {
            swapConditions(condition.and, dragNumber, dropNumber, draggedCondition, dropCondition, setConditionsCount);
        }

        if (condition.or != null) {
            swapConditions(condition.or, dragNumber, dropNumber, draggedCondition, dropCondition, setConditionsCount);
        }
    }
}

const assignValuesToCondition = (condition: Condition, newCondition: Condition) => {
    condition.operand1 = newCondition.operand1;
    condition.operand2 = newCondition.operand2;
    condition.openParentheses = newCondition.openParentheses;
    condition.closeParentheses = newCondition.closeParentheses;
    condition.comparisonType = newCondition.comparisonType;
}

const extractClausesAndConditions = (condition: Condition, dragNumber: number, dropNumber: number, callback: (condition: Condition, isDragged: boolean) => void) => {

    if (condition.number === dragNumber) {
        callback(condition, true);
    }
    else if (condition.number === dropNumber) {
        callback(condition, false);
    }
    if (condition.and != null) {
        extractClausesAndConditions(condition.and, dragNumber, dropNumber, callback);
    }

    if (condition.or != null) {
        extractClausesAndConditions(condition.or, dragNumber, dropNumber, callback);
    }
}

export {
    comparisonTypeOptions,
    logicalOperatorOptions,
    reorder
};