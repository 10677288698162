import './CustomIcon.scss';
import React from 'react';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';

interface UrlIconProps {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    count?: number;
    title?: string;
}

class CustomIcon extends React.Component<UrlIconProps> {
    render(): JSX.Element {
        return (
            <span className="custom-i" title={this.props.title}>
                <span className="i">
                    {React.createElement(this.props.icon)}
                </span>
                {
                    typeHelper.isNumber(this.props.count) &&
                    <span className="count">{this.props.count}</span>
                }
            </span>
        );
    }
}

export default CustomIcon;
