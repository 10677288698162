import SelectOption from '../../types/SelectOption';
import ObjectIndexer from '../../types/ObjectIndexer';
import { SelectSearchOption } from 'react-select-search';

const mapToBasicOption = (x: string): SelectOption => {
    return { value: x, text: x };
};

const mapToSelectSearchOption = (value: string): SelectSearchOption => {
    let valueName = value;
    if (valueName === ' ') {
        //handling space option with Non-breaking space
        valueName = '\u00A0';
    }

    return { name: valueName, value: value };
};


const mapToSelectOption = (obj: ObjectIndexer<string>, key: string): SelectOption => {
    return { value: obj[key], text: key };
};

const mapToSelectOptions = (obj: ObjectIndexer<string>): SelectOption[] => Object.keys(obj).map(key => mapToSelectOption(obj, key));

export { mapToBasicOption, mapToSelectOptions, mapToSelectSearchOption };