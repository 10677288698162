import './ComplianceCommentModal.scss';
import { Unsubscribe } from 'redux';
import { validate } from './ComplianceCommentModal.Validation';
import actions from '../../../../store/actions';
import ComplianceComment from '../../../../types/common/ComplianceComment';
import ComplianceCommentModalState from './ComplianceCommentModalState';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import Modal from '../../Modal/Modal';
import PromiseButton from '../../PromiseButton';
import React from 'react';
import store from '../../../../store/store';
import CustomIcon from '../../BootstrapIcons/Custom/CustomIcon';
import { ReactComponent as RemoveIcon } from '../../../Common/BootstrapIcons/close.svg';
import iconTooltip from '../../../Common/BootstrapIcons/Tooltip/IconTooltip';
import identityStorage from '../../../../infrastructure/authorization/identityStorage';
import userRole from '../../../../infrastructure/constants/identity/userRole';

class ComplianceCommentModal extends React.Component<any, ComplianceCommentModalState> {
    private unsubscribe: Unsubscribe | undefined;

    private get invokedFromActionItems() {
        return this.state.url.indexOf('action-item-comment') > -1;
    }

    constructor(props: any) {
        super(props);

        this.state = {
            title: 'Compliance Comments',
            isOpen: false,
            comments: [],
            comment: '',
            url: '',
            error: null
        };

        this.openListener = this.openListener.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.close = this.close.bind(this);
        this.getItem = this.getItem.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.openListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }
    openListener(): void {
        let state = store.getState();

        if (!(state.action === actions.complianceCommentModal.open || 
            state.action === actions.complianceCommentModal.deleteCommentSuccess)) {
            return;
        }

        this.setState(state.complianceCommentModalState);
    }

    onCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {

        this.setState({ comment: e.target.value });
    }

    onSaveClick(): Promise<any> {

        let error = validate(this.state.comment);

        this.setState({ error: error });

        if (error) {
            return Promise.reject(error);
        }

        return httpClient.post(this.state.url, { comment: this.state.comment }).then(this.onSaveSuccess);
    }

    onSaveSuccess(): void {

        this.setState({ isOpen: false });

        store.dispatch({ type: actions.complianceCommentModal.saveSuccess, payload: null as any });
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    onRemoveClick(comment: ComplianceComment): Promise<any> {
        return httpClient.delete(`${this.state.url}/${comment.commentId}`).then(()=> this.onRemoveSuccess(comment.commentId));
    }

    onRemoveSuccess(commentId: number): void {
        let temp = { ...this.state };
        temp.comments = temp.comments.filter(c=> c.commentId !== commentId);

        store.dispatch({ type: actions.complianceCommentModal.deleteCommentSuccess, payload: temp });
    }

    getItem(comment: ComplianceComment, i: number): JSX.Element {
        let identity = identityStorage.get();

        return (
            <li key={i}>
                <div className="container">
                    <div className="left">
                        <div>{comment.content}</div>
                        <span>{comment.createdTimestamp} - {comment.createdBy}</span>
                    </div>
                    {   !this.invokedFromActionItems && identity.userRole === userRole.admin &&
                        <div className="right">
                            <button
                                className="custom-icon-button"
                                onClick={() => this.onRemoveClick(comment)}>
                                <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                            </button>
                        </div>
                    }
                </div>
            </li>
        );
    }

    render(): JSX.Element {
        return (
            <Modal state={this.state} close={this.close} size="extra-large">

                <div className="compliance-comment-modal">
                    <ul>
                        {this.state.comments.map(this.getItem)}
                    </ul>

                    <textarea placeholder="Add Comment" onChange={this.onCommentChange} value={this.state.comment}></textarea>

                    <PromiseButton text="Save" className="btn cb-btn" enableOnErrorOnly={false} task={this.onSaveClick} />
                </div>
            </Modal>
        );
    }
}

export default ComplianceCommentModal;
