import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';
import CopyAggregationFieldModalState from './CopyAggregationFieldModalState';
import Aggregation from '../../../../types/report/Aggregation';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';

const sameDataSource = (state: CopyAggregationFieldModalState, aggregations: Aggregation[]): boolean => {

    let sourceNumber = parseInt(state.sourceNumber);
    state.targetNumbers.forEach((number) => {
        if ((aggregations.find(x => x.number === sourceNumber) as Aggregation).dataSourceId !==
            (aggregations.find(x => x.number === parseInt(number)) as Aggregation).dataSourceId) {
            return false;
        }
    });
    return true;
}

const validate = (state: CopyAggregationFieldModalState, aggregations: Aggregation[], fields: ReportFieldDefinition[]): string | null => {

    if (!sameDataSource(state, aggregations)) {

        return 'Unable to copy field(s) because \'From\' and \'To\' cases have different data sources';
    }

    if (state.copyAllFields) {
        return null;
    }

    if (stringHelper.isEmpty(state.sourceFieldId)) {
        return 'From field is required';
    }

    if (stringHelper.isEmpty(state.targetFieldId)) {
        return 'To field is required';
    }

    let sameAggregation = state.targetNumbers.has(state.sourceNumber);
    let sameField = state.sourceFieldId === state.targetFieldId;

    if (sameAggregation && sameField) {
        return 'Unable to copy from a field to the same field (same aggregation)';
    }

    return null;
};

export { validate };
