import pixelize from './../../../infrastructure/helpers/html/pixelize';
import React from 'react';

const onDragOverClass = 'over';

const handle = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
};

const addClass = (e: React.DragEvent<HTMLDivElement>, className: string): void => (e.target as HTMLDivElement).classList.add(className);

const removeClass = (e: React.DragEvent<HTMLDivElement>, className: string): void => (e.target as HTMLDivElement).classList.remove(className);

const onEnter = (e: React.DragEvent<HTMLDivElement>): void => {
    addClass(e, onDragOverClass);
    handle(e);
};

const onOver = (e: React.DragEvent<HTMLDivElement>): void => {
    addClass(e, onDragOverClass);
    handle(e);
};

const onLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    removeClass(e, onDragOverClass);
    handle(e);
};

const onDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    removeClass(e, onDragOverClass);
    handle(e);
};

const getDropAreaStyles = (height: number): React.CSSProperties => {
    return { height: pixelize(height) };
};

const getDropAreaContentStyles = (height: number): React.CSSProperties => {
    let paddingTop = Math.round(height / 3);

    let margin = `${pixelize(paddingTop)} 0 0 0`;

    return { margin };
};

const getInputId = (uniqueName: string): string => `${uniqueName}-picker`;

const getAcceptAttribute = (allowedExtensions: string[]): string => allowedExtensions.map(x => '.' + x).join(', ');

export {
    onEnter,
    onOver,
    onLeave,
    onDrop,
    getDropAreaStyles,
    getDropAreaContentStyles,
    getInputId,
    getAcceptAttribute
};
