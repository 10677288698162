import './ParenthesesEditor.scss';
import React from 'react';

interface Props {
    count: number;
    character: string;
    onChange: (value: number, event: React.MouseEvent<HTMLElement>) => void;
    isReadOnly?: boolean
}

class ParenthesesEditor extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onIncrementClick = this.onIncrementClick.bind(this);
        this.onDecrementClick = this.onDecrementClick.bind(this);
    }

    onIncrementClick(event: React.MouseEvent<HTMLElement>): void {
        this.props.onChange(this.props.count + 1, event);
    }

    onDecrementClick(event: React.MouseEvent<HTMLElement>): void {
        this.props.onChange(this.props.count - 1, event);
    }

    render(): JSX.Element {
        return (
            <span className='parentheses-editor'>
                <button className='increment helper-btn' title={`add '${this.props.character}'`} onClick={this.onIncrementClick} disabled={this.props.isReadOnly}>&#43;</button>
                <button className='decrement helper-btn' title={`remove '${this.props.character}'`} onClick={this.onDecrementClick} disabled={this.props.count === 0 || this.props.isReadOnly}>&#8722;</button>
            </span>
        );
    }
}

export default ParenthesesEditor;
