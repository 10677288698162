import { getColumns } from './ComplianceUrlEditModal.Grid';
import { getRemoveMessage } from './ComplianceUrlEditModal.Helpers';
import { Unsubscribe } from 'redux';
import { validate } from './ComplianceUrlEditModal.Validation';
import actions from '../../../../store/actions';
import ComplianceUrl from '../../../../types/common/ComplianceUrl';
import ComplianceUrlEditModalState from './ComplianceUrlEditModalState';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import Modal from '../../Modal/Modal';
import PromiseButton from '../../PromiseButton';
import React from 'react';
import store from '../../../../store/store';
import UiDataGrid from '../../DataGrid/UiDataGrid/UiDataGrid';
import WarningModal from '../WarningModal/WarningModal';
import copyArray from '../../../../infrastructure/helpers/common/copyArray';

class ComplianceUrlEditModal extends React.Component<any, ComplianceUrlEditModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: 'Compliance Guidance',
            isOpen: false,
            saveUrl: '',
            urls: [],
            error: null,
            showWarningModal: false,
            number: 0
        };

        this.openListener = this.openListener.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.close = this.close.bind(this);
        this.onRemoveConfirmed = this.onRemoveConfirmed.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.openListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    openListener(): void {
        let state = store.getState();

        if (state.action !== actions.complianceUrlEditModal.open) {
            return;
        }

        this.setState(state.complianceUrlEditModalState);
    }

    onAddClick(): void {

        let empty: ComplianceUrl = { name: '', url: '' };

        this.setState({ urls: this.state.urls.concat([empty]) });
    }

    onChange(i: number, changes: ComplianceUrl): void {

        Object.assign(this.state.urls[i], changes);

        this.setState({ urls: this.state.urls });
    }

    onRemoveClick(i: number) {
        this.setState({ number: i, showWarningModal: true });
    }

    onRemoveConfirmed(): void {

        let urls = copyArray(this.state.urls);
        urls.splice(this.state.number, 1);

        this.setState({ urls, showWarningModal: false });
    }

    onSaveClick(): Promise<any> {

        let error = validate(this.state.urls);

        this.setState({ error: error });

        if (error) {
            return Promise.reject(error);
        }

        return httpClient.put(this.state.saveUrl, this.state.urls).then(this.onSaveSuccess);
    }

    onSaveSuccess(): void {

        this.setState({ isOpen: false });

        store.dispatch({ type: actions.complianceUrlEditModal.saveSuccess, payload: null as any });
    }

    close(): void {

        this.setState({ isOpen: false });
    }

    render(): JSX.Element {

        return (
            <>
                <Modal state={this.state} close={this.close} size="extra-large">
                    <UiDataGrid
                        columns={getColumns(this.onChange, this.onRemoveClick)}
                        data={this.state.urls}
                        getKey={(x, i) => `${i}-${x.name}`} />

                    <div className="mt-3">

                        <button onClick={this.onAddClick} className="btn btn-light">+ URL</button>

                        <PromiseButton text="Save" className="btn cb-btn" enableOnErrorOnly={false} task={this.onSaveClick} />

                    </div>
                </Modal>
                {this.state.showWarningModal && this.state.urls.length &&
                    <WarningModal
                        onOkClick={this.onRemoveConfirmed}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Remove url'
                        message={getRemoveMessage(this.state.urls[this.state.number].name)} />
                }
            </>
        );
    }
}

export default ComplianceUrlEditModal;
