import './ReportFieldInstructionEditModal.scss';
import { Unsubscribe } from 'redux';
import { validate } from './ReportFieldInstructionEditModal.Validation';
import actions from '../../../../store/actions';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import Modal from '../../Modal/Modal';
import PromiseButton from '../../PromiseButton';
import React from 'react';
import ReportFieldInstructionEditModalState from './ReportFieldInstructionEditModalState';
import ReportFieldInstructions from '../../../../types/report/ReportFieldInstructions';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import store from '../../../../store/store';
import reportRegime from '../../../../infrastructure/constants/reportRegime';
import { createValidationEsmaTable, getDetailsToBeReportedString, getFormatToBeReportedString, getValidationsString, getFieldNumberString, getClassOfSpecifiedDerivativesContractsString, getExplanatoryNotesString, shouldShowRegimeSpecificColumn } from './ReportFieldInstructionHelper';

interface Props {
    updateState: () => void;
    regime: string;
}

class ReportFieldInstructionEditModal extends React.Component<Props, ReportFieldInstructionEditModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            isOpen: false,
            saveUrl: '',
            instructions: {
                detailsToBeReported: '',
                detailsToBeReportedUrl: '',
                formatToBeReported: '',
                formatToBeReportedUrl: '',
                validations: '',
                validationEsmaTableRows: [[]],
                validationsUrl: '',
                cnPopulationGuidelines: '',
                cnPopulationGuidelinesUrl: '',
                classOfSpecifiedDerivativesContracts: '',
                classOfSpecifiedDerivativesContractsUrl: '',
                explanatoryNotes: '',
                explanatoryNotesUrl: '',
                rtsFieldNumber: '',
                t: '',
                p: ''
            },
            error: null
        };

        this.openListener = this.openListener.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.close = this.close.bind(this);
        this.onChangeEsmaTableCell = this.onChangeEsmaTableCell.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.openListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    openListener(): void {
        let state = store.getState();

        if (state.action !== actions.reportFieldInstructionEditModal.open) {
            return;
        }

        this.setState(state.reportFieldInstructionEditModalState);
    }

    onChange(changes: ReportFieldInstructions): void {

        this.setState({ instructions: Object.assign({}, this.state.instructions, changes) });
    }

    onSaveClick(): Promise<any> {

        let sanitizedRtsFieldNumber = this.state.instructions.rtsFieldNumber.replace(/&nbsp;/g, ' ').trim();
        let updatedInstructions = Object.assign({}, this.state.instructions, { rtsFieldNumber: sanitizedRtsFieldNumber });

        return new Promise((resolve, reject) => {
            this.setState({ instructions: updatedInstructions }, () => {

                let error = validate(this.state.instructions);

                this.setState({ error: error });

                if (error) {
                    return reject(error);
                }

                httpClient.put(this.state.saveUrl, this.state.instructions)
                    .then(this.onSaveSuccess)
                    .then(resolve)
                    .catch(reject);
            });
        });
    }

    onSaveSuccess(): void {

        this.props.updateState();
        this.setState({ isOpen: false });

        store.dispatch({ type: actions.reportFieldInstructionEditModal.saveSuccess, payload: null as any });
    }

    close(): void {

        this.setState({ isOpen: false });
    }

    getSection(title: string, html: string, url: string, onHtmlChange: (value: string) => void, onUrlChange: (value: string) => void, additionalContent?: JSX.Element | null): JSX.Element {

        return (
            <>
                <div className="title-container">
                    <h5>{title}</h5>
                    <div>
                        <input type="text" placeholder="URL" value={url || ''} onChange={e => onUrlChange(e.currentTarget.value)} />
                    </div>
                </div>

                <div className="section">
                    <RichTextEditor html={html} update={onHtmlChange} />
                </div>
                {additionalContent}
            </>
        );
    }

    getOnlyValueSection(title: string, html: string, onHtmlChange: (value: string) => void): JSX.Element {
        return (
            <>
                <div className="title-container">
                    <h5>{title}</h5>
                </div>

                <div className="section">
                    <RichTextEditor html={html} update={onHtmlChange} />
                </div>
            </>
        );
    }

    onChangeEsmaTableCell(e: any, rowIndex: number, cellIndex: number): void {
        const value = e.currentTarget.value;
        let instructions = Object.assign({}, this.state.instructions);

        if (instructions.validationEsmaTableRows) {
            instructions.validationEsmaTableRows[rowIndex][cellIndex] = value;
        }

        this.setState({ instructions });
    }

    render(): JSX.Element {

        const validationEsmaTable = this.props.regime === reportRegime.REFIT
            && this.state.instructions.validationEsmaTableRows ?
            createValidationEsmaTable(this.state.instructions.validationEsmaTableRows, this.onChangeEsmaTableCell)
            : null;

        return (
            <Modal state={this.state} close={this.close} size="extra-large">

                <div className="report-field-instruction-edit-modal">

                    {this.getSection(
                        getDetailsToBeReportedString(this.props.regime),
                        this.state.instructions.detailsToBeReported,
                        this.state.instructions.detailsToBeReportedUrl,
                        html => this.onChange({ detailsToBeReported: html } as ReportFieldInstructions),
                        url => this.onChange({ detailsToBeReportedUrl: url } as ReportFieldInstructions))}

                    {this.getSection(
                        getFormatToBeReportedString(this.props.regime),
                        this.state.instructions.formatToBeReported,
                        this.state.instructions.formatToBeReportedUrl,
                        html => this.onChange({ formatToBeReported: html } as ReportFieldInstructions),
                        url => this.onChange({ formatToBeReportedUrl: url } as ReportFieldInstructions))}

                    {shouldShowRegimeSpecificColumn(this.props.regime) &&
                        this.getSection(
                        getClassOfSpecifiedDerivativesContractsString(this.props.regime),
                        this.state.instructions.classOfSpecifiedDerivativesContracts,
                        this.state.instructions.classOfSpecifiedDerivativesContractsUrl,
                        html => this.onChange({ classOfSpecifiedDerivativesContracts: html } as ReportFieldInstructions),
                        url => this.onChange({ classOfSpecifiedDerivativesContractsUrl: url } as ReportFieldInstructions))}

                    {shouldShowRegimeSpecificColumn(this.props.regime) &&
                        this.getSection(
                        getExplanatoryNotesString(this.props.regime),
                        this.state.instructions.explanatoryNotes,
                        this.state.instructions.explanatoryNotesUrl,
                        html => this.onChange({ explanatoryNotes: html } as ReportFieldInstructions),
                        url => this.onChange({ explanatoryNotesUrl: url } as ReportFieldInstructions))}

                    {this.getSection(
                        getValidationsString(this.props.regime),
                        this.state.instructions.validations,
                        this.state.instructions.validationsUrl,
                        html => this.onChange({ validations: html } as ReportFieldInstructions),
                        url => this.onChange({ validationsUrl: url } as ReportFieldInstructions),
                        validationEsmaTable)}

                    {this.getSection(
                        'CN Population Guidelines',
                        this.state.instructions.cnPopulationGuidelines,
                        this.state.instructions.cnPopulationGuidelinesUrl,
                        html => this.onChange({ cnPopulationGuidelines: html } as ReportFieldInstructions),
                        url => this.onChange({ cnPopulationGuidelinesUrl: url } as ReportFieldInstructions))}

                    {this.getOnlyValueSection(
                        getFieldNumberString(this.props.regime),
                        this.state.instructions.rtsFieldNumber,
                        html => this.onChange({ rtsFieldNumber: html } as ReportFieldInstructions))}

                </div>

                <div className="mt-3">

                    <PromiseButton text="Save" className="btn cb-btn" enableOnErrorOnly={false} task={this.onSaveClick} />

                </div>

            </Modal>
        );
    }

}

export default ReportFieldInstructionEditModal;
