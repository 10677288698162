import { ReactComponent as CaretDown } from '../../BootstrapIcons/caret-down-fill.svg';
import { ReactComponent as CaretUp } from '../../BootstrapIcons/caret-up-fill.svg';
import React from 'react';
import ServerDataGridProps from './ServerDataGridProps';
import ServerDataGridState from './ServerDataGridState';
import sortSelectionStorage from '../sortSelectionStorage';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';

function getGridClasses(isWaiting: boolean): string {

    let classes = ['server-data-grid', 'grid'];

    if (isWaiting) {
        classes.push('waiting');
    }

    return classes.join(' ');
};

function getDefaultState<T>(props: ServerDataGridProps<T>): ServerDataGridState<T> {

    let sortSelection = sortSelectionStorage.get(props.uniqueName);

    let column = (
        props.columns.find(x => typeHelper.isString(x.sortKey) && x.sortKey === sortSelection.columnName) ||
        props.columns.find(x => typeHelper.isString(x.sortKey))
    );

    let ascending = (column?.sortKey === sortSelection.columnName) ? sortSelection.ascending : false;

    return {
        records: [],
        currentPage: 1,
        lastPage: 0,
        total: 0,
        sortBy: column?.sortKey as string,
        ascending: ascending,
        previousButtonEnabled: false,
        nextButtonEnabled: false,
        isWaiting: false
    };
};

function getSortingIcon(show: boolean, ascending: boolean): JSX.Element {
    if (!show) {
        return (<></>);
    }

    if (ascending) {
        return (<span className="asc"><CaretUp /></span>);
    }

    return (<span className="desc"><CaretDown /></span>);
}

export {
    getGridClasses,
    getDefaultState,
    getSortingIcon
};