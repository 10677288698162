import React from 'react';

class Forbidden extends React.Component {
    render(): JSX.Element {
        return (
            <>
                <h5>Forbidden</h5>
                Unfortunately you don't have sufficient rights to perform this action or access the requested resource
            </>
        );
    }
};

export default Forbidden;
