import CopyValidationModalState from './CopyValidationModalState';
import AccuracyValidation from '../../../../types/report/AccuracyValidation';
import { SelectSearchOption } from 'react-select-search';

const getDefaultState = (): CopyValidationModalState => {
    return {
        title: '',
        isOpen: false,
        error: null,
        sourceValidationNumber: '',
        targetValidationNumber: '',
        showWarningModal: false,
        message: ''
    };
};

const createFieldOption = (field: AccuracyValidation): SelectSearchOption => {
    return {
        name: field.identifier,
        value: (field.number || 0).toString()
    };
};

const createEmptyFieldOption = (): SelectSearchOption => {
    return {
        name: '',
        value: ''
    };
};

export { getDefaultState, createFieldOption, createEmptyFieldOption };
