import React from 'react';
import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import Operand from '../../../../types/functions/Operand/Operand';
import UserDefinedFunctionParameter from '../../../../types/report/UserDefinedFunctionParameter';
import SelectList from '../../../Common/SelectList/SelectList';
import BaseOperandProps from '../Props/BaseOperandProps';
import { createOption } from './ParameterBuilder.Helpers';

type ParameterBuilderProps = BaseOperandProps & {
    parameters: UserDefinedFunctionParameter[]
}

class ParameterBuilder extends React.Component<ParameterBuilderProps> {

    constructor(props: ParameterBuilderProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.getValue = this.getValue.bind(this);
    }

    onChange(value: string): void {

        if (stringHelper.isEmpty(value)) {
            return;
        }

        let parameter = this.props.parameters.find(x => x.number === parseInt(value)) as UserDefinedFunctionParameter;

        let operand =
            {
                fieldId: parameter.number,
                value: parameter.name,
                dataType: parameter.dataType
            } as Operand;

        this.props.onOperandChange(operand);
    }

    getOptions(): SelectOption[] {

        return this.props.parameters.map(x => createOption(x));
    }

    getValue(): string {

        return this.props.operand.fieldId?.toString() || '';
    }

    render(): JSX.Element {
        return (
            <SelectList
                options={this.getOptions()}
                value={this.getValue()}
                onChange={this.onChange}
                includeEmptyOption={true}
                className="form-control" />
        );
    }
}
export default ParameterBuilder;
