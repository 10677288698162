import CustomDataSourceFieldDefinition from '../../../../types/report/CustomDataSourceFieldDefinition';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import CustomDataSourceLegitimacy from '../../../types/Functions/CustomDataSourceLegitimacy';
import locationType from '../../../../types/functions/Location/LocationType';
import ReportConfig from '../../../../types/report/ReportConfig';
import typeHelper from '../../common/typeHelper';

const getFieldById = (fields: CustomDataSourceFields, customDataSourceFieldId: number): CustomDataSourceFieldDefinition => {

    let firdsField = fields.firds.find(x => x.id === customDataSourceFieldId) as CustomDataSourceFieldDefinition;
    if (typeHelper.isObject(firdsField)) {

        return firdsField;
    }

    let leiLevel1Field = fields.leiLevel1.find(x => x.id === customDataSourceFieldId) as CustomDataSourceFieldDefinition;
    if (typeHelper.isObject(leiLevel1Field)) {

        return leiLevel1Field;
    }

    let exchangeRatesField = fields.exchangeRates.find(x => x.id === customDataSourceFieldId) as CustomDataSourceFieldDefinition;
    if (typeHelper.isObject(exchangeRatesField)) {

        return exchangeRatesField;
    }

    let annaDsbUpiEnrichmentField = fields.annaDsbUpiEnrichment.find(x => x.id === customDataSourceFieldId) as CustomDataSourceFieldDefinition;
    if (typeHelper.isObject(annaDsbUpiEnrichmentField)) {

        return annaDsbUpiEnrichmentField;
    }
    throw new Error(`Custom data source field was not found by id ${customDataSourceFieldId}`);
};

const isFirdsField = (fields: CustomDataSourceFields, customDataSourceFieldId: number): boolean => {

    return fields.firds.some(x => x.id === customDataSourceFieldId);
};

const isLeiLevel1Field = (fields: CustomDataSourceFields, customDataSourceFieldId: number): boolean => {

    return fields.leiLevel1.some(x => x.id === customDataSourceFieldId);
};

const isExchangeRatesField = (fields: CustomDataSourceFields, customDataSourceFieldId: number): boolean => {

    return fields.exchangeRates.some(x => x.id === customDataSourceFieldId);
};

const isAnnaDsbUpiEnrichmentField = (fields: CustomDataSourceFields, customDataSourceFieldId: number): boolean => {

    return fields.annaDsbUpiEnrichment.some(x => x.id === customDataSourceFieldId);
};

const isAnnaDsbUpiField = (fields: CustomDataSourceFields, customDataSourceFieldId: number): boolean => {

    return fields.annaDsbUpi.some(x => x.id === customDataSourceFieldId);
};

const getLegitimacy = (config: ReportConfig, statement: string, dataSourceId: number | null): CustomDataSourceLegitimacy => {

    if (statement !== locationType.statement.lookup &&
        statement !== locationType.statement.variable &&
        statement !== locationType.statement.filter &&
        statement !== locationType.statement.case &&
        statement !== locationType.statement.reportField &&
        statement !== locationType.statement.validation &&
        statement !== locationType.statement.userDefinedFunction) {

        return { isin: false, lei: false, exchangeRates: false, annaDsbUpiEnrichment: false, annaDsbUpi: false, lse: false, firdsFcaInstrument: false, firdsEsmaInstrument: false, fcaRegulatedEntities: false };
    }

    return {
        isin: config.isinSelectors.some(x => x.dataSourceId === dataSourceId),
        lei: config.leiSelectors.some(x => x.dataSourceId === dataSourceId),
        exchangeRates: config.exchangeRatesSelectors.some(x => x.dataSourceId === dataSourceId),
        annaDsbUpiEnrichment: config.annaDsbUpiEnrichmentSelectors.some(x => x.dataSourceId === dataSourceId),
        annaDsbUpi: config.annaDsbUpiSelectors.some(x => x.dataSourceId === dataSourceId),
        lse: config.lseSelectors.some(x => x.dataSourceId === dataSourceId),
        firdsFcaInstrument: config.firdsFcaInstrumentSelectors.some(x => x.dataSourceId === dataSourceId),
        firdsEsmaInstrument: config.firdsEsmaInstrumentSelectors.some(x => x.dataSourceId === dataSourceId),
        fcaRegulatedEntities: config.fcaRegulatedEntitiesSelectors.some(x => x.dataSourceId === dataSourceId)
    };
};

const customDataSourceHelper = {
    getFieldById,
    isFirdsField,
    isLeiLevel1Field,
    isExchangeRatesField,
    isAnnaDsbUpiEnrichmentField,
    isAnnaDsbUpiField,
    getLegitimacy
};

export default customDataSourceHelper;