import { AxiosResponse } from 'axios';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import FieldDefinitions from '../FieldDefinitions/FieldDefinitions';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import React from 'react';
import Spinner from '../../Common/Spinner/Spinner';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';

interface State {
    isWaiting: boolean;
    dataSource: IngestionConfig;
}

class GlobalRefDataSourcePreviewPage extends React.Component<any, State> {
    id: string;
    isValidQueryParam: boolean;

    constructor(props: any) {
        super(props);

        let query = urlHelper.deserializeQuery(history.location.search);

        this.id = query['id'];

        this.isValidQueryParam = typeHelper.isInteger(parseInt(this.id));

        if (!this.isValidQueryParam) {
            history.push(appRoutes.home);
        }

        this.state = {
            isWaiting: true,
            dataSource: {} as IngestionConfig
        };

        this.onGetSuccess = this.onGetSuccess.bind(this);
    }

    componentDidMount(): void {
        if (!this.isValidQueryParam) {
            return;
        }

        let url = urlHelper.buildRoute(['global-ref-data-source', this.id]);

        httpClient.get<IngestionConfig>(url).then(this.onGetSuccess);
    }

    onGetSuccess(response: AxiosResponse<IngestionConfig>): void {

        this.setState({ isWaiting: false, dataSource: response.data });
    }

    render(): JSX.Element {

        if (this.state.isWaiting) {
            return (
                <Spinner />
            );
        }

        return (
            <>
                <h4>{this.state.dataSource.name}</h4>

                <div className="mt-4">
                    <FieldDefinitions definitions={this.state.dataSource.fieldDefinitions} />
                </div>
            </>
        );
    }
}

export default GlobalRefDataSourcePreviewPage;