import { getArgumentDefinitionByIndex, getFunctionDefinition } from './functionHelper.Helpers';
import ArgumentDefinition from '../../../../types/functions/ArgumentDefinition';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../operand/operandHelper';

const areCompatible = (previous: ArgumentDefinition, current: ArgumentDefinition): boolean => {

    return (
        previous.isArray === current.isArray &&
        previous.allowedTypes.every(type => current.allowedTypes.includes(type)) &&
        previous.specialValueType === current.specialValueType &&
        previous.defaultValue === current.defaultValue
    );
};

const assignCompatibleArguments = (previous: Operand, current: Operand): void => {

    if (!operandHelper.isValidFunction(previous) || !operandHelper.isValidFunction(current)) {
        return;
    }

    let definitionPrevious = getFunctionDefinition(previous.function);
    let definitionCurrent = getFunctionDefinition(current.function);

    let argumentDefinitionPrevious: ArgumentDefinition;
    let argumentDefinitionCurrent: ArgumentDefinition;

    let length = (current.arguments as Operand[]).length;

    for (let i = 0; i < length; i++) {

        if ((previous.arguments as Operand[]).length <= i) {
            break;
        }

        argumentDefinitionPrevious = getArgumentDefinitionByIndex(definitionPrevious, i);
        argumentDefinitionCurrent = getArgumentDefinitionByIndex(definitionCurrent, i);

        if (!areCompatible(argumentDefinitionPrevious, argumentDefinitionCurrent)) {
            break;
        }

        (current.arguments as Operand[])[i] = (previous.arguments as Operand[])[i];
    }
};

export default assignCompatibleArguments;