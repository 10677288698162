import './SeparatedValueBuilder.scss';
import { ReactComponent as RemoveIcon } from '../../Common/BootstrapIcons/trash.svg';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import React from 'react';
import separatedValueHelper from '../../../infrastructure/helpers/ingestion/separatedValueHelper';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

interface Props {
    isReadOnly: boolean;
    items: string;
    suffix: string | null;
    validate: (items: string) => string | null;
    onChange: (items: string) => void;
}

interface State {
    isEditMode: boolean;
    error: string | null;
}

class SeparatedValueBuilder extends React.Component<Props, State> {
    private firstInput: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.firstInput = React.createRef<HTMLInputElement>();

        this.state = { isEditMode: false, error: null };

        this.onAddClick = this.onAddClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.getItem = this.getItem.bind(this);
    }

    onAddClick(): void {

        this.props.onChange(separatedValueHelper.add(this.props.items));
    }

    onChange(i: number, value: string): void {

        this.props.onChange(separatedValueHelper.edit(this.props.items, i, value));
    }

    onRemoveClick(i: number): void {

        this.props.onChange(separatedValueHelper.remove(this.props.items, i));
    }

    onSaveClick(): void {

        let error = this.props.validate(this.props.items);
        if (error) {
            this.setState({ error: error });
            return;
        }

        this.setState({ isEditMode: false, error: null });
    }

    getItem(item: string, i: number, all: string[]): JSX.Element {

        return (
            <tr key={i}>
                <td>
                    {
                        all.length > 1 &&
                        <button className="transparent-button" onClick={() => this.onRemoveClick(i)} title={iconTooltip.remove}>
                            <RemoveIcon />
                        </button>
                    }
                </td>
                <td>
                    <input
                        ref={i === 0 ? this.firstInput : null}
                        type="text"
                        value={item}
                        onChange={e => this.onChange(i, e.target.value)}
                        className="form-control" />
                </td>
                {
                    typeHelper.isString(this.props.suffix) &&
                    <td>{this.props.suffix}</td>
                }
            </tr>
        )
    }

    render(): JSX.Element {

        if (this.state.isEditMode) {
            return (
                <div className="separated-values">
                    <table>
                        <tbody>
                            {separatedValueHelper.split(this.props.items).map(this.getItem)}
                        </tbody>
                    </table>
                    <div className="mt-2">
                        <button onClick={this.onAddClick} className="btn btn-light">+ Add</button>
                        <button onClick={this.onSaveClick} className="btn cb-btn">Save</button>
                    </div>
                    <ErrorMessage error={this.state.error} className="mt-2" />
                </div>
            );
        }

        return (
            <input
                type="text"
                className="form-control"
                disabled={this.props.isReadOnly}
                onFocus={() => { this.setState({ isEditMode: true }, () => this.firstInput.current?.focus()); }}
                defaultValue={this.props.items} />
        );
    }
}

export default SeparatedValueBuilder;
