import functionName from './functionName';

const arrayItemFunctions = [
    functionName.all,
    functionName.any,
    functionName.allInteger,
    functionName.anyInteger,
    functionName.allDecimal,
    functionName.anyDecimal,
    functionName.allBoolean,
    functionName.anyBoolean,
    functionName.allDate,
    functionName.anyDate,
    functionName.allTime,
    functionName.anyTime,
    functionName.allDateTime,
    functionName.anyDateTime,
    functionName.allUnixTime,
    functionName.anyUnixTime,
];

export default arrayItemFunctions;
