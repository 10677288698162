import ObjectIndexer from "../../types/ObjectIndexer";

const timeZones: ObjectIndexer<{ offset: string, commonName: string, daylightSaving: string }> = {
    'Dateline Standard Time': {
        offset: '-12:00',
        commonName: 'International Date Line West',
        daylightSaving: 'N'
    },
    'UTC-11': {
        offset: '-11:00',
        commonName: 'Coordinated Universal Time-11',
        daylightSaving: 'N'
    },
    'Aleutian Standard Time': {
        offset: '-10:00',
        commonName: 'Aleutian Islands',
        daylightSaving: 'Y'
    },
    'Hawaiian Standard Time': {
        offset: '-10:00',
        commonName: 'Hawaii',
        daylightSaving: 'N'
    },
    'Marquesas Standard Time': {
        offset: '-09:30',
        commonName: 'Marquesas Islands',
        daylightSaving: 'N'
    },
    'Alaskan Standard Time': {
        offset: '-09:00',
        commonName: 'Alaska',
        daylightSaving: 'Y'
    },
    'UTC-09': {
        offset: '-09:00',
        commonName: 'Coordinated Universal Time-9',
        daylightSaving: 'N'
    },
    'Pacific Standard Time (Mexico)': {
        offset: '-08:00',
        commonName: 'Baja California',
        daylightSaving: 'Y'
    },
    'UTC-08': {
        offset: '-08:00',
        commonName: 'Coordinated Universal Time-8',
        daylightSaving: 'N'
    },
    'Pacific Standard Time': {
        offset: '-08:00',
        commonName: 'Pacific Time (US & Canada)',
        daylightSaving: 'Y'
    },
    'US Mountain Standard Time': {
        offset: '-07:00',
        commonName: 'Arizona',
        daylightSaving: 'N'
    },
    'Mountain Standard Time (Mexico)': {
        offset: '-07:00',
        commonName: 'La Paz, Mazatlan',
        daylightSaving: 'Y'
    },
    'Mountain Standard Time': {
        offset: '-07:00',
        commonName: 'Mountain Time (US & Canada)',
        daylightSaving: 'Y'
    },
    'Yukon Standard Time': {
        offset: '-07:00',
        commonName: 'Yukon',
        daylightSaving: 'Y'
    },
    'Central America Standard Time': {
        offset: '-06:00',
        commonName: 'Central America',
        daylightSaving: 'N'
    },
    'Central Standard Time': {
        offset: '-06:00',
        commonName: 'Central Time (US & Canada)',
        daylightSaving: 'Y'
    },
    'Easter Island Standard Time': {
        offset: '-06:00',
        commonName: 'Easter Island',
        daylightSaving: 'Y'
    },
    'Central Standard Time (Mexico)': {
        offset: '-06:00',
        commonName: 'Guadalajara, Mexico City, Monterrey',
        daylightSaving: 'Y'
    },
    'Canada Central Standard Time': {
        offset: '-06:00',
        commonName: 'Saskatchewan',
        daylightSaving: 'N'
    },
    'SA Pacific Standard Time': {
        offset: '-05:00',
        commonName: 'Bogota, Lima, Quito Rio Branco',
        daylightSaving: 'N'
    },
    'Eastern Standard Time (Mexico)': {
        offset: '-05:00',
        commonName: 'Chetumal',
        daylightSaving: 'Y'
    },
    'Eastern Standard Time': {
        offset: '-05:00',
        commonName: 'Eastern Time (US & Canada)',
        daylightSaving: 'Y'
    },
    'Haiti Standard Time': {
        offset: '-05:00',
        commonName: 'Haiti',
        daylightSaving: 'Y'
    },
    'Cuba Standard Time': {
        offset: '-05:00',
        commonName: 'Havana',
        daylightSaving: 'Y'
    },
    'US Eastern Standard Time': {
        offset: '-05:00',
        commonName: 'Indiana (East)',
        daylightSaving: 'Y'
    },
    'Turks And Caicos Standard Time': {
        offset: '-05:00',
        commonName: 'Turks and Caicos',
        daylightSaving: 'Y'
    },
    'Paraguay Standard Time': {
        offset: '-04:00',
        commonName: 'Asuncion',
        daylightSaving: 'Y'
    },
    'Atlantic Standard Time': {
        offset: '-04:00',
        commonName: 'Atlantic Time (Canada)',
        daylightSaving: 'Y'
    },
    'Venezuela Standard Time': {
        offset: '-04:00',
        commonName: 'Caracas',
        daylightSaving: 'Y'
    },
    'Central Brazilian Standard Time': {
        offset: '-04:00',
        commonName: 'Cuiaba',
        daylightSaving: 'Y'
    },
    'SA Western Standard Time': {
        offset: '-04:00',
        commonName: 'Georgetown, La Paz, Manaus, San Juan',
        daylightSaving: 'N'
    },
    'Pacific SA Standard Time': {
        offset: '-04:00',
        commonName: 'Santiago',
        daylightSaving: 'Y'
    },
    'Newfoundland Standard Time': {
        offset: '-03:30',
        commonName: 'Newfoundland',
        daylightSaving: 'Y'
    },
    'Tocantins Standard Time': {
        offset: '-03:00',
        commonName: 'Araguaina',
        daylightSaving: 'Y'
    },
    'E. South America Standard Time': {
        offset: '-03:00',
        commonName: 'Brasilia',
        daylightSaving: 'Y'
    },
    'SA Eastern Standard Time': {
        offset: '-03:00',
        commonName: 'Cayenne, Fortaleza',
        daylightSaving: 'N'
    },
    'Argentina Standard Time': {
        offset: '-03:00',
        commonName: 'City of Buenos Aires',
        daylightSaving: 'Y'
    },
    'Montevideo Standard Time': {
        offset: '-03:00',
        commonName: 'Montevideo',
        daylightSaving: 'Y'
    },
    'Magallanes Standard Time': {
        offset: '-03:00',
        commonName: 'Punta Arenas',
        daylightSaving: 'Y'
    },
    'Saint Pierre Standard Time': {
        offset: '-03:00',
        commonName: 'Saint Pierre and Miquelon',
        daylightSaving: 'Y'
    },
    'Bahia Standard Time': {
        offset: '-03:00',
        commonName: 'Salvador',
        daylightSaving: 'Y'
    },
    'UTC-02': {
        offset: '-02:00',
        commonName: 'Coordinated Universal Time-02',
        daylightSaving: 'N'
    },
    'Greenland Standard Time': {
        offset: '-02:00',
        commonName: 'Greenland',
        daylightSaving: 'Y'
    },
    'Mid-Atlantic Standard Time': {
        offset: '-02:00',
        commonName: 'Mid-Atlantic - Old',
        daylightSaving: 'Y'
    },
    'Azores Standard Time': {
        offset: '-01:00',
        commonName: 'Azores',
        daylightSaving: 'Y'
    },
    'Cape Verde Standard Time': {
        offset: '-01:00',
        commonName: 'Cabo Verde Is.',
        daylightSaving: 'N'
    },
    'UTC': {
        offset: '+00:00',
        commonName: 'Coordinated Universal Time',
        daylightSaving: 'N'
    },
    'GMT Standard Time': {
        offset: '+00:00',
        commonName: 'Dublin, Edinburgh, Lisbon, London',
        daylightSaving: 'Y'
    },
    'Greenwich Standard Time': {
        offset: '+00:00',
        commonName: 'Monrovia, Reykjavik',
        daylightSaving: 'N'
    },
    'Sao Tome Standard Time': {
        offset: '+00:00',
        commonName: 'Sao Tome',
        daylightSaving: 'Y'
    },
    'Morocco Standard Time': {
        offset: '+01:00',
        commonName: 'Casablanca',
        daylightSaving: 'Y'
    },
    'W. Europe Standard Time': {
        offset: '+01:00',
        commonName: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        daylightSaving: 'Y'
    },
    'Central Europe Standard Time': {
        offset: '+01:00',
        commonName: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        daylightSaving: 'Y'
    },
    'Romance Standard Time': {
        offset: '+01:00',
        commonName: 'Brussels, Copenhagen, Madrid, Paris',
        daylightSaving: 'Y'
    },
    'Central European Standard Time': {
        offset: '+01:00',
        commonName: 'Sarajevo, Skopje, Warsaw, Zagreb',
        daylightSaving: 'Y'
    },
    'W. Central Africa Standard Time': {
        offset: '+01:00',
        commonName: 'West Central Africa',
        daylightSaving: 'N'
    },
    'GTB Standard Time': {
        offset: '+02:00',
        commonName: 'Athens, Bucharest',
        daylightSaving: 'Y'
    },
    'Middle East Standard Time': {
        offset: '+02:00',
        commonName: 'Beirut',
        daylightSaving: 'Y'
    },
    'Egypt Standard Time': {
        offset: '+02:00',
        commonName: 'Cairo',
        daylightSaving: 'Y'
    },
    'E. Europe Standard Time': {
        offset: '+02:00',
        commonName: 'Chisinau',
        daylightSaving: 'Y'
    },
    'West Bank Standard Time': {
        offset: '+02:00',
        commonName: 'Gaza, Hebron',
        daylightSaving: 'Y'
    },
    'South Africa Standard Time': {
        offset: '+02:00',
        commonName: 'Harare, Pretoria',
        daylightSaving: 'N'
    },
    'FLE Standard Time': {
        offset: '+02:00',
        commonName: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        daylightSaving: 'Y'
    },
    'Israel Standard Time': {
        offset: '+02:00',
        commonName: 'Jerusalem',
        daylightSaving: 'Y'
    },
    'South Sudan Standard Time': {
        offset: '+02:00',
        commonName: 'Juba',
        daylightSaving: 'Y'
    },
    'Kaliningrad Standard Time': {
        offset: '+02:00',
        commonName: 'Kaliningrad',
        daylightSaving: 'Y'
    },
    'Sudan Standard Time': {
        offset: '+02:00',
        commonName: 'Khartoum',
        daylightSaving: 'Y'
    },
    'Libya Standard Time': {
        offset: '+02:00',
        commonName: 'Tripoli',
        daylightSaving: 'Y'
    },
    'Namibia Standard Time': {
        offset: '+02:00',
        commonName: 'Windheok',
        daylightSaving: 'Y'
    },
    'Jordan Standard Time': {
        offset: '+03:00',
        commonName: 'Amman',
        daylightSaving: 'Y'
    },
    'Arabic Standard Time': {
        offset: '+03:00',
        commonName: 'Baghdad',
        daylightSaving: 'Y'
    },
    'Syria Standard Time': {
        offset: '+03:00',
        commonName: 'Damascus',
        daylightSaving: 'Y'
    },
    'Turkey Standard Time': {
        offset: '+03:00',
        commonName: 'Istanbul',
        daylightSaving: 'Y'
    },
    'Arab Standard Time': {
        offset: '+03:00',
        commonName: 'Kuwait, Riyadh',
        daylightSaving: 'N'
    },
    'Belarus Standard Time': {
        offset: '+03:00',
        commonName: 'Minsk',
        daylightSaving: 'Y'
    },
    'Russian Standard Time': {
        offset: '+03:00',
        commonName: 'Moscow, St. Petersburg',
        daylightSaving: 'Y'
    },
    'E. Africa Standard Time': {
        offset: '+03:00',
        commonName: 'Nairobi',
        daylightSaving: 'N'
    },
    'Volgograd Standard Time': {
        offset: '+03:00',
        commonName: 'Volgograd',
        daylightSaving: 'Y'
    },
    'Iran Standard Time': {
        offset: '+03:30',
        commonName: 'Tehran',
        daylightSaving: 'Y'
    },
    'Arabian Standard Time': {
        offset: '+04:00',
        commonName: 'Abu Dhabi, Muscat',
        daylightSaving: 'N'
    },
    'Astrakhan Standard Time': {
        offset: '+04:00',
        commonName: 'Astrakhan, Ulyanovsk',
        daylightSaving: 'Y'
    },
    'Azerbaijan Standard Time': {
        offset: '+04:00',
        commonName: 'Baku',
        daylightSaving: 'Y'
    },
    'Russia Time Zone 3': {
        offset: '+04:00',
        commonName: 'Izhevsk, Samara',
        daylightSaving: 'Y'
    },
    'Mauritius Standard Time': {
        offset: '+04:00',
        commonName: 'Port Louis',
        daylightSaving: 'Y'
    },
    'Saratov Standard Time': {
        offset: '+04:00',
        commonName: 'Saratov',
        daylightSaving: 'Y'
    },
    'Georgian Standard Time': {
        offset: '+04:00',
        commonName: 'Tbilisi',
        daylightSaving: 'N'
    },
    'Caucasus Standard Time': {
        offset: '+04:00',
        commonName: 'Yerevan',
        daylightSaving: 'Y'
    },
    'Afghanistan Standard Time': {
        offset: '+04:30',
        commonName: 'Kabul',
        daylightSaving: 'N'
    },
    'West Asia Standard Time': {
        offset: '+05:00',
        commonName: 'Ashgabat, Tashkent',
        daylightSaving: 'N'
    },
    'Qyzylorda Standard Time': {
        offset: '+05:00',
        commonName: 'Astana',
        daylightSaving: 'Y'
    },
    'Ekaterinburg Standard Time': {
        offset: '+05:00',
        commonName: 'Ekaterinburg',
        daylightSaving: 'Y'
    },
    'Pakistan Standard Time': {
        offset: '+05:00',
        commonName: 'Islamabad, Karachi',
        daylightSaving: 'Y'
    },
    'India Standard Time': {
        offset: '+05:30',
        commonName: 'Chennai, Kolkata, Mumbai, New Delhi',
        daylightSaving: 'N'
    },
    'Sri Lanka Standard Time': {
        offset: '+05:30',
        commonName: 'Sri Jayawardenepura',
        daylightSaving: 'N'
    },
    'Nepal Standard Time': {
        offset: '+05:45',
        commonName: 'Kathmandu',
        daylightSaving: 'N'
    },
    'Central Asia Standard Time': {
        offset: '+06:00',
        commonName: 'Bishek',
        daylightSaving: 'N'
    },
    'Bangladesh Standard Time': {
        offset: '+06:00',
        commonName: 'Dhaka',
        daylightSaving: 'Y'
    },
    'Omsk Standard Time': {
        offset: '+06:00',
        commonName: 'Omsk',
        daylightSaving: 'Y'
    },
    'Myanmar Standard Time': {
        offset: '+06:30',
        commonName: 'Yangon (Rangoon)',
        daylightSaving: 'N'
    },
    'SE Asia Standard Time': {
        offset: '+07:00',
        commonName: 'Bangkok, Hanoi, Jakarta',
        daylightSaving: 'N'
    },
    'Altai Standard Time': {
        offset: '+07:00',
        commonName: 'Barnaul, Gorno-Altaysk',
        daylightSaving: 'Y'
    },
    'W. Mongolia Standard Time': {
        offset: '+07:00',
        commonName: 'Havd',
        daylightSaving: 'Y'
    },
    'N. Central Asia Standard Time': {
        offset: '+07:00',
        commonName: 'Novosibirsk',
        daylightSaving: 'Y'
    },
    'Tomsk Standard Time': {
        offset: '+07:00',
        commonName: 'Tomsk',
        daylightSaving: 'Y'
    },
    'China Standard Time': {
        offset: '+08:00',
        commonName: 'Beijing, Chongqing, Hong Kong, Urumqi',
        daylightSaving: 'N'
    },
    'North Asia East Standard Time': {
        offset: '+08:00',
        commonName: 'Irkutsk',
        daylightSaving: 'Y'
    },
    'Singapore Standard Time': {
        offset: '+08:00',
        commonName: 'Kuala Lumpur, Singapore',
        daylightSaving: 'N'
    },
    'W. Australia Standard Time': {
        offset: '+08:00',
        commonName: 'Perth',
        daylightSaving: 'Y'
    },
    'Taipei Standard Time': {
        offset: '+08:00',
        commonName: 'Taipei',
        daylightSaving: 'N'
    },
    'Ulaanbaatar Standard Time': {
        offset: '+08:00',
        commonName: 'Ulaanbaatar',
        daylightSaving: 'Y'
    },
    'Aus Central W. Standard Time': {
        offset: '+08:45',
        commonName: 'Eucla',
        daylightSaving: 'N'
    },
    'Transbaikal Standard Time': {
        offset: '+09:00',
        commonName: 'Chita',
        daylightSaving: 'Y'
    },
    'Tokyo Standard Time': {
        offset: '+09:00',
        commonName: 'Osaka, Sapporo, Tokyo',
        daylightSaving: 'N'
    },
    'Nord Korea Standard Time': {
        offset: '+09:00',
        commonName: 'Pyongyang',
        daylightSaving: 'Y'
    },
    'Korea Standard Time': {
        offset: '+09:00',
        commonName: 'Seoul',
        daylightSaving: 'N'
    },
    'Yakutsk Standard Time': {
        offset: '+09:00',
        commonName: 'Yakutsk',
        daylightSaving: 'Y'
    },
    'Cen. Australia Standard Time': {
        offset: '+09:30',
        commonName: 'Adelaide',
        daylightSaving: 'Y'
    },
    'AUS Central Standard Time': {
        offset: '+09:30',
        commonName: 'Darwin',
        daylightSaving: 'N'
    },
    'E. Australia Standard Time': {
        offset: '+10:00',
        commonName: 'Brisbane',
        daylightSaving: 'N'
    },
    'AUS Eastern Standard Time': {
        offset: '+10:00',
        commonName: 'Canberra, Melbourne, Sydney',
        daylightSaving: 'Y'
    },
    'West Pacific Standard Time': {
        offset: '+10:00',
        commonName: 'Guam, Port Moresby',
        daylightSaving: 'N'
    },
    'Tasmania Standard Time': {
        offset: '+10:00',
        commonName: 'Hobart',
        daylightSaving: 'Y'
    },
    'Vladivostok Standard Time': {
        offset: '+10:00',
        commonName: 'Vladivostok',
        daylightSaving: 'Y'
    },
    'Lord Howe Standard Time': {
        offset: '+10:30',
        commonName: 'Lord Howe Island',
        daylightSaving: 'Y'
    },
    'Bougainville Standard Time': {
        offset: '+11:00',
        commonName: 'Bougainville Island',
        daylightSaving: 'Y'
    },
    'Russia Time Zone 10': {
        offset: '+11:00',
        commonName: 'Chokurdakh',
        daylightSaving: 'Y'
    },
    'Magadan Standard Time': {
        offset: '+11:00',
        commonName: 'Magadan',
        daylightSaving: 'Y'
    },
    'Norfolk Standard Time': {
        offset: '+11:00',
        commonName: 'Norfolk Island',
        daylightSaving: 'Y'
    },
    'Sakhalin Standard Time': {
        offset: '+11:00',
        commonName: 'Sakhalin',
        daylightSaving: 'Y'
    },
    'Central Pacific Standard Time': {
        offset: '+11:00',
        commonName: 'Solomon Is., New Caledonia',
        daylightSaving: 'N'
    },
    'Russia Time Zone 11': {
        offset: '+12:00',
        commonName: 'Anadyr, Petropavlovsk-Kamchatsky',
        daylightSaving: 'Y'
    },
    'New Zealand Standard Time': {
        offset: '+12:00',
        commonName: 'Auckland, Wellington',
        daylightSaving: 'Y'
    },
    'UTC+12': {
        offset: '+12:00',
        commonName: 'Coordinated Universal Time+12',
        daylightSaving: 'Y'
    },
    'Fiji Standard Time': {
        offset: '+12:00',
        commonName: 'Fiji',
        daylightSaving: 'Y'
    },
    'Kamchatka Standard Time': {
        offset: '+12:00',
        commonName: 'Petropavlovsk-Kamchatsky - Old',
        daylightSaving: 'Y'
    },
    'Chatham Islands Standard Time': {
        offset: '+12:45',
        commonName: 'Chatham Islands',
        daylightSaving: 'Y'
    },
    'UTC+13': {
        offset: '+13:00',
        commonName: 'Coordinated Universal Time+13',
        daylightSaving: 'Y'
    },
    'Tonga Standard Time': {
        offset: '+13:00',
        commonName: 'Nuku\'alofa',
        daylightSaving: 'Y'
    },
    'Samoa Standard Time': {
        offset: '+13:00',
        commonName: 'Samoa',
        daylightSaving: 'Y'
    },
    'Line Islands Standard Time': {
        offset: '+14:00',
        commonName: 'Kiritimati Island',
        daylightSaving: 'N'
    }
};

export default timeZones;
