/* eslint-disable react/jsx-no-undef */
import Dictionary from '../../../types/report/Dictionary';
import { getColumns } from './DictionaryBuilder.Grid';
import React from 'react';
import dataSourceHelper from '../../../infrastructure/helpers/functions/common/dataSourceHelper';
import dictionaryHelper from '../../../infrastructure/helpers/functions/dictionaries/dictionaryHelper';
import referenceValidator from '../../../infrastructure/helpers/functions/validator/referenceValidator';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import locationType from '../../../types/functions/Location/LocationType';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import DictionaryBuilderProps from './DictionaryBuilderProps';
import ErrorList from '../../Common/ErrorList/ErrorList';

class DictionaryBuilder extends React.Component<DictionaryBuilderProps> {

    constructor(props: DictionaryBuilderProps) {
        super(props);

        this.onNameChange = this.onNameChange.bind(this);
        this.onDataSourceChange = this.onDataSourceChange.bind(this);
        this.onBusinessDescriptionChange = this.onBusinessDescriptionChange.bind(this);
        this.onRemoveDictionaryClick = this.onRemoveDictionaryClick.bind(this);
    }

    onNameChange(name: string, number: number): void {

        let location = this.getLocation(number, []);

        this.props.onChange(location, { name: name } as Dictionary);
    }

    onDataSourceChange(dataSourceId: string, number: number): void {

        let changes = {} as Dictionary;

        let location = this.getLocation(number, []);

        let error = referenceValidator.validateDictionaryReferences(number, this.props.reportConfig);
        if (error) {

            changes.errors = [error];

            this.props.onChange(location, changes);

            return;
        }

        let dataSource = dataSourceHelper.getById(this.props.collections.dataSources, parseInt(dataSourceId));

        changes.fields = [];

        dictionaryHelper.reset(changes, dataSource);

        this.props.onChange(location, changes);
    }

    onBusinessDescriptionChange(businessDescription: string, number: number): void {

        let location = this.getLocation(number, []);

        this.props.onChange(location, { businessDescription: businessDescription } as Dictionary);
    }

    onRemoveDictionaryClick(number: number): void {

        let location = this.getLocation(number, []);

        this.props.onRemoveClick(location);
    }

    getLocation(number: number, argumentIndexes: number[]): ReportLogicLocation {

        let location = {} as ReportLogicLocation;

        location.statement = locationType.statement.dictionary;
        location.statementNumber = number;
        location.argumentIndexes = argumentIndexes;

        return location;
    }

    getContent(dict: Dictionary): JSX.Element {

        return (
            <>
                <ErrorList errors={dict.errors} className="mt-2" />
            </>)
    }

    render(): JSX.Element {
        return (
            <ReportLogicList
                isWaiting={this.props.isWaiting}
                items={this.props.reportConfig.dictionaries.filter(x => x.number === this.props.dictionaryNumber)}
                columns={getColumns(this.onNameChange, this.onDataSourceChange, this.onBusinessDescriptionChange, this.onRemoveDictionaryClick, this.props.collections.dataSources, this.props.isReadOnly)}
                getContent={this.getContent}
                getKey={a => a.number}
                createHtmlId={v => reportConfigNavigation.buildDictionaryId(v.number)} />
        );
    }
}

export default DictionaryBuilder;
