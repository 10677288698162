import stringHelper from '../stringHelper';
import typeHelper from '../typeHelper';

const start = 'https://';

const validate = (url: string, name: string): string | null => {

    if (!typeHelper.isString(url) || stringHelper.isEmpty(url)) {

        return `${name} URL is required`;
    }

    if (!url.startsWith(start) || stringHelper.isEmpty(url.substr(start.length))) {

        return `Invalid ${name} URL`;
    }

    return null;
};

const urlValidator = {
    validate
};

export default urlValidator;
