import { getIngestionConfig } from './IngestionPage/IngestionPage.Helpers';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import FieldDefinition from '../../../types/common/FieldDefinition';
import FieldDefinitions from '../FieldDefinitions/FieldDefinitions';
import history from '../../../infrastructure/helpers/react/history';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import IngestionPageState from './IngestionPage/IngestionPageState';
import IngestionStepIndicator from '../IngestionStepIndicator';
import React from 'react';
import store from '../../../store/store';
import SystemFieldDefinitions from '../FieldDefinitions/SystemFieldDefinitions/SystemFieldDefinitions';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import validate from '../FieldDefinitions/FieldDefinitions.Validation';

class IngestionFieldDefinitionPage extends React.Component<any, IngestionPageState> {

    constructor(props: any) {
        super(props);

        if (!typeHelper.isObject(history.location.state)) {
            history.push(appRoutes.ingestion.home);
        }

        this.state = { config: getIngestionConfig(history), error: null };

        this.onChange = this.onChange.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
    }

    onChange(definitions: FieldDefinition[]): void {

        let config = Object.assign({}, this.state.config, { fieldDefinitions: definitions } as IngestionConfig);

        this.setState({ config: config });
    }

    onNextClick(): void {

        let error = validate(this.state.config.fieldDefinitions, this.state.config.isTransposed);

        this.setState({ error });

        if (error) {
            return;
        }

        store.dispatch({ type: actions.navigation.execute, payload: () => history.push(appRoutes.ingestion.signoff, this.state.config) });
    }

    onBackClick(): void {

        store.dispatch({ type: actions.navigation.execute, payload: () => history.push(appRoutes.ingestion.details, this.state.config) });
    }

    render(): JSX.Element {

        return (
            <>
                <IngestionStepIndicator
                    currentStepNumber={2}
                    config={this.state.config} />

                <FieldDefinitions
                    definitions={this.state.config.fieldDefinitions}
                    onChange={this.onChange}
                    isReadOnly={false} />

                <h3 className="mt-5 mb-5">System Fields</h3>

                <SystemFieldDefinitions
                    definitions={this.state.config.fieldDefinitions}
                    onChange={this.onChange}
                    isReadOnly={false} />

                <ErrorMessage className="mt-3" error={this.state.error} />

                <div className="mt-3 mb-3">
                    <button onClick={this.onNextClick} className="btn cb-btn">Next</button>
                    <button onClick={this.onBackClick} className="btn btn-light">Back</button>
                </div>
            </>
        );
    }
}

export default IngestionFieldDefinitionPage;
