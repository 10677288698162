import Lookup from '../../../../types/functions/Lookup';
import StatementHelper from '../../../types/Functions/StatementHelper';
import stringHelper from '../../common/stringHelper';
import typeHelper from '../../common/typeHelper';

class LookupHelper extends StatementHelper<Lookup> {

    getDataSourceId(lookup: Lookup): number | null {

        return lookup.dataSource1Id;
    }

    getName(lookup: Lookup, dataSource: string): string {

        return `L${lookup.number}-${stringHelper.isNonEmpty(lookup.name) ? lookup.name : dataSource}`;
    }

    getEmpty(rawDataSourceId: number | null): Lookup {

        return {
            id: null,
            number: 0,
            name: '',
            dataSource1Id: rawDataSourceId,
            dataSource2Id: null,
            condition: null as any,
            isMultiResult: false,
            isEndpoint: !typeHelper.isNumber(rawDataSourceId),
            isBespoke: false,
            bespokeCondition: '',
            errors: [],
            _internalId: 1
        };
    }
}

const lookupHelper = new LookupHelper();

export default lookupHelper;
