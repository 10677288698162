import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import UserDefinedFunctionParameter from '../../../../types/report/UserDefinedFunctionParameter';
import clear from '../../common/array/clear';
import stringHelper from '../../common/stringHelper';
import declarationValidator from './declarationValidator';
import uniquenessValidator from './uniquenessValidator';

const maxNameCharacters = 50;

const validateParameter = (parameter: UserDefinedFunctionParameter, functions: UserDefinedFunction[]): void => {

    clear(parameter.errors);

    if (stringHelper.isEmpty(parameter.name)) {
        parameter.errors.push('Parameter name is required');
    }

    if (parameter.name.length > maxNameCharacters) {
        parameter.errors.push(`Parameter name max character count is ${maxNameCharacters}`);
    }
};

const validateFunction = (func: UserDefinedFunction, functions: UserDefinedFunction[]): void => {

    clear(func.errors);

    let metadataError = validateMetadata(func);
    if (metadataError) {
        func.errors.push(metadataError);
    }

    declarationValidator.validate(func, functions, [], [], [], [], [], null as any, func.returnType, null, true);

    func.parameters.forEach(x => validateParameter(x, functions));
};

const validate = (functions: UserDefinedFunction[]): string | null => {

    functions.forEach(x => validateFunction(x, functions));

    uniquenessValidator.validate(functions, a => a.name, 'User Defined Function', 'name');

    let valid = functions.every(x => x.errors.length === 0 && x.parameters.every(p => p.errors.length === 0));

    return valid ? null : 'Invalid functions(s)';
};

const validateMetadata = (func: UserDefinedFunction): string | null => {

    if (stringHelper.isEmpty(func.name)) {
        return 'Function name is required';
    }

    if (func.name.length > maxNameCharacters) {
        return `Function name max character count is ${maxNameCharacters}`;
    }

    return null;
};

const functionValidator = {
    validate,
    validateFunction,
    validateParameter
};

export default functionValidator;
