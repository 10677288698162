import { mapToBasicOption } from '../../../../../infrastructure/helpers/html/selectOptionHelper';
import booleanFormatString from '../../../../../infrastructure/constants/functions/formatString/types/booleanFormatString';
import ObjectIndexer from '../../../../../infrastructure/types/ObjectIndexer';
import operandSpecialValueType from '../../../../../infrastructure/constants/functions/operandSpecialValueType';
import SelectOption from '../../../../../infrastructure/types/SelectOption';
import timeZones from '../../../../../infrastructure/constants/timeZones/timeZones';

const buildTimeZoneOption = (key: string, pairs: ObjectIndexer<{ offset: string, commonName: string, daylightSaving: string }>): SelectOption => {
    const timeZoneInfo = pairs[key];
    return {
        text: `${key} (${timeZoneInfo.offset}, ${timeZoneInfo.daylightSaving}, ${timeZoneInfo.commonName})`,
        value: key
    };
};

const booleanFormatOptions = Object.values(booleanFormatString).map(mapToBasicOption);

const timeZoneOptions = Object.keys(timeZones).map(x => buildTimeZoneOption(x, timeZones));

const dateTimeFormatSpecialValueTypes = [
    operandSpecialValueType.formatStringDateTime,
    operandSpecialValueType.formatStringDate,
    operandSpecialValueType.formatStringTime
];

export {
    booleanFormatOptions,
    timeZoneOptions,
    dateTimeFormatSpecialValueTypes
};