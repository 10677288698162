import React from 'react';

interface ErrorMessageProps {
    error: string | null;
    className?: string;
}

class ErrorMessage extends React.Component<ErrorMessageProps> {
    render(): JSX.Element {
        return (
            <div className={this.props.className}>
                <span className="error">{this.props.error}</span>
            </div>
        );
    }
}

export default ErrorMessage;
