import assignmentHelper from '../assignment/assignmentHelper';
import Selector from '../../../../types/report/Selector';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import stringHelper from '../../common/stringHelper';
import NumberDeclarationHelper from '../../../types/Functions/NumberDeclarationHelper';
import ReportConfig from '../../../../types/report/ReportConfig';
import ComponentType from '../../../../types/report/ComponentType';

class SelectorHelper extends NumberDeclarationHelper<Selector> {

    getDeclarations(config: ReportConfig, location: ReportLogicLocation, componentType: ComponentType): Selector[] {
        switch (componentType) {
            case ComponentType.IsinSelector: return config.isinSelectors;
            case ComponentType.LeiSelector: return config.leiSelectors;
            case ComponentType.ExchangeRatesSelector: return config.exchangeRatesSelectors;
            case ComponentType.AnnaDsbUpiEnrichmentSelector: return config.annaDsbUpiEnrichmentSelectors;
            case ComponentType.AnnaDsbUpiSelector: return config.annaDsbUpiSelectors;
            case ComponentType.LSESelector: return config.lseSelectors;
            case ComponentType.FirdsFcaInstrumentSelectors: return config.firdsFcaInstrumentSelectors;
            case ComponentType.FirdsEsmaInstrumentSelectors: return config.firdsEsmaInstrumentSelectors;
            case ComponentType.FCARegulatedEntitiesSelector: return config.fcaRegulatedEntitiesSelectors;
            default: return [];
        }
    }

    getEmpty(rawDataSourceId: number | null, name: string): Selector {

        let assignment = assignmentHelper.getEmpty(false);
        assignment.number = 1;

        return {
            id: null,
            name,
            number: 0,
            dataSourceId: rawDataSourceId,
            assignment: assignment,
            errors: [],
            _internalId: 1
        };
    }

    get(selectors: Selector[], location: ReportLogicLocation): Selector {

        return selectors.find(x => x.number === location.statementNumber) as Selector;
    }

    fallbackName(selector: Selector, defaultName: string): string {

        return stringHelper.isNonEmpty(selector.name) ? selector.name : `${defaultName} ${selector.number}`
    };
}

const selectorHelper = new SelectorHelper();

export default selectorHelper;