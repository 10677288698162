import { validate } from './reportConfigBundleSerializer.validation';
import Aggregation from '../../../../types/report/Aggregation';
import copyObject from '../../common/copyObject';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import ReportCase from '../../../../types/report/ReportCase';
import ReportCaseField from '../../../../types/report/ReportCaseField';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportConfigBundle from '../../../../types/report/import/ReportConfigBundle';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import stringHelper from '../../common/stringHelper';
import typeHelper from '../../common/typeHelper';

const replacer = (key: string, value: any): any => {

    if (value === false) {
        return undefined;
    }

    return value;
};

const resetItem = (item: Partial<{ id: number | null, errors: string[] }>): void => {

    delete item.id;
    delete item.errors;
};

const resetConfig = (config: Partial<ReportConfig>): void => {

    delete config.id;
    delete config.isReleased;
    delete config.version;
};

const resetAggregation = (aggregation: Aggregation): void => {

    resetItem(aggregation);

    aggregation.fields.forEach(resetItem);
};

const resetCase = ($case: Partial<ReportCase>): void => {

    resetItem($case);

    delete $case.syncedWithId;

    ($case.fields as ReportCaseField[]).forEach(resetItem);
};

const serialize = (config: ReportConfig, dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[]): string => {

    let copy = copyObject(config);

    resetConfig(copy);

    copy.lookups.forEach(resetItem);
    copy.variables.forEach(resetItem);
    copy.isinSelectors.forEach(resetItem);
    copy.leiSelectors.forEach(resetItem);
    copy.exchangeRatesSelectors.forEach(resetItem);
    copy.annaDsbUpiEnrichmentSelectors.forEach(resetItem);
    copy.lseSelectors.forEach(resetItem);
    copy.firdsFcaInstrumentSelectors.forEach(resetItem);
    copy.firdsEsmaInstrumentSelectors.forEach(resetItem);
    copy.annaDsbUpiSelectors.forEach(resetItem);
    copy.fcaRegulatedEntitiesSelectors.forEach(resetItem);
    copy.filters.forEach(resetItem);
    copy.endpointFilters.forEach(resetItem);
    copy.validations.forEach(resetItem);
    copy.type = config.type;
    copy.aggregations.forEach(resetAggregation);
    copy.cases.forEach(resetCase);

    let bundle = {} as ReportConfigBundle;

    bundle.config = copy;
    bundle.dataSources = dataSources;
    bundle.reportFields = {};

    reportFields.forEach(x => bundle.reportFields[x.name] = x.id);

    return JSON.stringify(bundle, replacer);
};

const deserialize = (json: string, skipDataSourcesValidation?: boolean): ReportConfigBundle => {

    if (stringHelper.isEmpty(json)) {

        return { error: 'Report config source is required' } as ReportConfigBundle;
    }

    let bundle: ReportConfigBundle;

    try {
        bundle = JSON.parse(json);

    } catch (e) {

        return { error: 'Invalid json' } as ReportConfigBundle;
    }

    if (!typeHelper.isObject(bundle)) {

        return { error: 'Json object is expected' } as ReportConfigBundle;
    }

    validate(bundle, skipDataSourcesValidation);

    return bundle;
};

const reportConfigBundleSerializer = {
    serialize,
    deserialize
};

export default reportConfigBundleSerializer;