import '../../../../components/Functions/CodeBlock.scss';
import './ReportLogicList.scss';
import ColumnDefinition from '../../../../infrastructure/types/DataGrid/ColumnDefinition';
import React from 'react';
import UiDataGrid from '../../../Common/DataGrid/UiDataGrid/UiDataGrid';

interface ReportLogicListProps<T> {
    isWaiting: boolean;
    items: T[];
    columns: ColumnDefinition<T>[];
    getContent: (item: T) => JSX.Element;
    getKey: (item: T) => number;
    createHtmlId: (item: T) => string;
    onClick?: (e: React.MouseEvent<HTMLElement>, item: T) => void;
}

class ReportLogicList<T> extends React.Component<ReportLogicListProps<T>> {

    render(): JSX.Element {

        if (this.props.isWaiting || this.props.items.length === 0) {
            return (
                <></>
            );
        }
        return (
            <div className="report-logic">

                {this.props.items.map((item, index) => {
                    return (
                        <div className="item" key={this.props.createHtmlId(item)} id={this.props.createHtmlId(item)}
                            onClick={(e) => { this.props.onClick && this.props.onClick(e, item) }}>

                            <UiDataGrid key={this.props.createHtmlId(item)} columns={this.props.columns} data={[item]} getKey={(x, i) => i} />

                            {this.props.getContent(item)}
                        </div>
                    );
                })
                }

            </div>
        );
    }
}

export default ReportLogicList;
