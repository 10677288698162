import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import ImportReportConfig from '../../../Report/Import/ImportReportConfig';
import ImportReportConfigState from '../../../Report/Import/ImportReportConfigState';
import Modal from '../../Modal/Modal';
import ModalStateBase from '../../Modal/ModalStateBase';
import React from 'react';
import store from '../../../../store/store';

type State = ModalStateBase & ImportReportConfigState;

class ImportReportConfigModal extends React.Component<any, State> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: 'Import Reporting Config',
            isOpen: false,
            json: '',
            errors: [],
            error: null
        };

        this.openListener = this.openListener.bind(this);
        this.close = this.close.bind(this);
        this.onImportSuccess = this.onImportSuccess.bind(this);
    }

    componentDidMount(): void {
        if (this.props.title) {
            this.setState({ title: this.props.title });
        }

        this.unsubscribe = store.subscribe(this.openListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    openListener(): void {
        let state = store.getState();

        if (state.action !== actions.importReportConfigModal.open) {
            return;
        }

        this.setState({ isOpen: true, json: '', errors: [] });
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    onImportSuccess(): void {

        this.close();

        store.dispatch({ type: actions.importReportConfigModal.saveSuccess, payload: null as any });
    }

    render(): JSX.Element {

        return (
            <Modal state={this.state} close={this.close}>
                <ImportReportConfig
                    isValidationConfig={this.props.isValidationConfig}
                    state={this.state}
                    onChange={s => this.setState(s)}
                    onImportSuccess={this.onImportSuccess}
                    skipDataSourcesValidation={this.props.skipDataSourcesValidation}
                />
            </Modal>
        );
    }
}

export default ImportReportConfigModal;