import { mapToBasicOption } from '../../../infrastructure/helpers/html/selectOptionHelper';
import { ReactComponent as RemoveIcon } from '../../Common/BootstrapIcons/close.svg';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import dataType from '../../../infrastructure/constants/dataType';
import DateTimeFormatBuilder from './DateTimeFormatBuilder/DateTimeFormatBuilder';
import dateTimeFormatHelper from '../../../infrastructure/helpers/common/dateTimeFormatHelper';
import DraggableItem from '../../Common/DraggableItem/DraggableItem';
import EditableText from '../../Common/EditableText/EditableText';
import FieldDefinition from '../../../types/common/FieldDefinition';
import guidance from './FieldDefinitions.Guidance';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import pixelize from '../../../infrastructure/helpers/html/pixelize';
import React from 'react';
import SelectList from '../../Common/SelectList/SelectList';
import AlternativeNames from './AlternativeNames/AlternativeNames';
import { ReactComponent as CommentIcon } from '../../Common/BootstrapIcons/comment.svg';

const height = 60;

const dataTypeOptions = Object.values(dataType).map(mapToBasicOption);

const getNameContent = (
    definition: FieldDefinition,
    i: number,
    onChange: (i: number, changes: FieldDefinition) => void,
    reorder: (dragIndex: number, dropIndex: number) => void,
    isReadOnly: boolean
): JSX.Element => {

    let style = {} as React.CSSProperties;

    if (!isReadOnly) {
        style.cursor = 'all-scroll';
    }

    let content = (
        <EditableText
            //CNTRLBX-818 the editing must be prevented, that's why disabled is true always
            disabled={true}
            value={definition.name}
            onChange={name => onChange(i, { name: name } as FieldDefinition)}
            multiline={false}
            style={style} />
    );

    if (isReadOnly) {
        return content;
    }

    return (
        <DraggableItem identifier={i} onDrop={reorder} style={{ height: pixelize(height) }}>
            {content}
        </DraggableItem>
    );
};

const getDataTypeContent = (definition: FieldDefinition, i: number, onChange: (i: number, changes: FieldDefinition, isDataTypeChanged: boolean) => void, isReadOnly: boolean): JSX.Element | string => {

    if (isReadOnly) {
        return definition.type;
    }

    return (
        <SelectList
            className="inline-select"
            options={dataTypeOptions}
            value={definition.type}
            onChange={type => onChange(i, { type: type } as FieldDefinition, true)} />
    );
};

const getDateFormatContent = (definition: FieldDefinition, i: number, onChange: (i: number, changes: FieldDefinition) => void, isReadOnly: boolean): JSX.Element | string => {

    if (!dateTimeFormatHelper.isDateOrTime(definition.type)) {
        return '';
    }

    if (isReadOnly) {
        return (
            <>
                {definition.formats.map((f, i) => <div key={i}>{f}</div>)}
            </>
        );
    }

    return (
        <DateTimeFormatBuilder
            dataType={definition.type}
            formats={definition.formats}
            onChange={formats => onChange(i, { formats: formats } as FieldDefinition)} />
    );
};

const getAlternativeNamesContent = (definition: FieldDefinition, i: number, onChange: (i: number, changes: FieldDefinition) => void, fieldDefinitions: FieldDefinition[]) => {

    return <AlternativeNames
        maxItemsCount={3}
        items={definition.alternativeNames || []}
        onChange={(items) => { onChange(i, Object.assign(definition, { alternativeNames: items })); }}
        fieldDefinitions={fieldDefinitions}
        currentDefinitionIndex={i}
    />
}

const getColumns = (
    isReadOnly: boolean,
    onChange: (i: number, changes: FieldDefinition) => void,
    onRemoveClick: (i: number) => void,
    reorder: (dragIndex: number, dropIndex: number) => void,
    definitions: FieldDefinition[],
    onShowFunctionsClick: (x: FieldDefinition, i: number) => void,
    onCommentClick: (x: FieldDefinition, i: number) => void,
    onShowReferencesClick: (x: FieldDefinition, i: number) => void
): ColumnDefinition<FieldDefinition>[] => {

    return [
        {
            header: 'Name',
            getContent: (x, i) => getNameContent(x, i, onChange, reorder, isReadOnly),
            info: guidance.name,
        },
        {
            header: 'Alternative Names',
            getContent: (x, i) => getAlternativeNamesContent(x, i, onChange, definitions),
            info: guidance.alternativeNames,
        },
        {
            header: 'Type',
            getContent: (x, i) => getDataTypeContent(x, i, onChange, isReadOnly),
            info: guidance.type,
        },
        {
            header: 'Format',
            getContent: (x, i) => getDateFormatContent(x, i, onChange, isReadOnly),
            info: guidance.format,
        },
        {
            header: 'Example',
            getContent: x => x.exampleValue,
            className: 'break-all',
            info: guidance.example,
        },
        {
            header: 'Functions',
            getContent: (x, i) => isReadOnly ?
                <></> :
                <button className='btn btn-light height-30px line-height-1' onClick={() => onShowFunctionsClick(x, i)}>Show</button>,
            className: 'break-all',
            info: `${guidance.ingestionFunctions}`
        },
        {
            header: 'References',
                getContent: (x, i) => isReadOnly ?
                    <></> :
                    <button className='btn btn-light height-30px line-height-1' onClick={() => onShowReferencesClick(x, i)}>Show</button>,
                    className: 'break-all',
            info: guidance.references
        },
        {
            header: '',
            getContent: (x, i) => <button
                className="custom-icon-button"
                onClick={() => onCommentClick(x, i)}>
                <CustomIcon icon={CommentIcon} title={iconTooltip.comments} />
            </button>

        },
        {
            header: '',
            getContent: (x, i) => isReadOnly ?
                <></> :
                <button
                    className="custom-icon-button"
                    onClick={() => onRemoveClick(i)}>
                    <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                </button>,
        }
    ];
};

export { getColumns };
