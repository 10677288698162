import React from 'react';
import ReportCollections from '../../../infrastructure/types/Functions/ReportCollections';
import ReportConfig from '../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import DeclarationBlockBuilder from '../../Functions/Declaration/DeclarationBlockBuilder';
import ReportConfigCounts from '../../../types/report/ReportConfigCounts';
import Operand from '../../../types/functions/Operand/Operand';
import OperandRules from '../../../types/functions/Operand/OperandRules';
import dataSourceHelper from '../../../infrastructure/helpers/functions/common/dataSourceHelper';
import ReportConfigComponentLock from '../../../types/report/ReportConfigComponentLock';
import Selector from '../../../types/report/Selector';
import SelectorComponent from './SelectorComponent';
import SelectorBuilder from './SelectorBuilder';
import selectorHelper from '../../../infrastructure/helpers/functions/selectors/SelectorHelper';
import ComponentType from '../../../types/report/ComponentType';

interface Props {
    isWaiting: boolean;
    isReadOnly: boolean;
    selectorNumber: number | null;
    reportConfig: ReportConfig;
    clipboard: ReportLogicLocation | null;
    counts: ReportConfigCounts;
    collections: ReportCollections;
    locationTypeStatement: string;
    validate: (declaration: Selector) => void;
    validateRemoveDeclaration: (location: ReportLogicLocation) => string | null;
    getBundleSelector: (location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean) => [OperandRules, string];
    getReportFieldOperandRuleBundle: (location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean) => [OperandRules, string];
    onClipboardChange: (clipboard: ReportLogicLocation) => void;
    onSelected: (number: number) => void;
    onChange: (selectors: Selector[]) => void;
    onComponentContainerClick?: (reportConfigComponentLock: ReportConfigComponentLock) => Promise<boolean>;
    componentType: ComponentType
}

class SelectorBlockBuilder extends React.Component<Props, { selectors: Selector[], defaultName: string, displayName: string }> {

    constructor(props: Props) {
        super(props);

        this.state = {
            selectors: [],
            defaultName: '',
            displayName: ''
        }
        this.onAddSelectorClick = this.onAddSelectorClick.bind(this);
        this.onSelectorChange = this.onSelectorChange.bind(this);
    }

    componentDidMount() {
        switch (this.props.componentType) {
            case ComponentType.IsinSelector: this.setState({ defaultName: 'ISIN', displayName: 'Isin', selectors: this.props.reportConfig.isinSelectors }); break;
            case ComponentType.LeiSelector: this.setState({ defaultName: 'LEI', displayName: 'Lei', selectors: this.props.reportConfig.leiSelectors }); break;
            case ComponentType.ExchangeRatesSelector: this.setState({ defaultName: 'ExchangeRates', displayName: 'Exchange Rates', selectors: this.props.reportConfig.exchangeRatesSelectors }); break;
            case ComponentType.AnnaDsbUpiEnrichmentSelector: this.setState({ defaultName: 'AnnaDsbUpiEnrichment', displayName: 'Anna Dsb Upi Enrichment', selectors: this.props.reportConfig.annaDsbUpiEnrichmentSelectors }); break;
            case ComponentType.AnnaDsbUpiSelector: this.setState({ defaultName: 'AnnaDsbUpi', displayName: 'Anna Dsb Upi', selectors: this.props.reportConfig.annaDsbUpiSelectors }); break;
            case ComponentType.FirdsFcaInstrumentSelectors: this.setState({ defaultName: 'FirdsFcaInstrument', displayName: 'Firds Fca Instrument', selectors: this.props.reportConfig.firdsFcaInstrumentSelectors }); break;
            case ComponentType.FirdsEsmaInstrumentSelectors: this.setState({ defaultName: 'FirdsEsmaInstrument', displayName: 'Firds Esma Instrument', selectors: this.props.reportConfig.firdsEsmaInstrumentSelectors }); break;
            case ComponentType.LSESelector: this.setState({ defaultName: 'LSE', displayName: 'LSE', selectors: this.props.reportConfig.lseSelectors }); break;
            case ComponentType.FCARegulatedEntitiesSelector: this.setState({ defaultName: 'FCARegulatedEntities', displayName: 'FCA Regulated Entities', selectors: this.props.reportConfig.fcaRegulatedEntitiesSelectors }); break;
            default: break;
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps !== this.props) {
            switch (this.props.componentType) {
                case ComponentType.IsinSelector: this.setState({ selectors: this.props.reportConfig.isinSelectors }); break;
                case ComponentType.LeiSelector: this.setState({ selectors: this.props.reportConfig.leiSelectors }); break;
                case ComponentType.ExchangeRatesSelector: this.setState({ selectors: this.props.reportConfig.exchangeRatesSelectors }); break;
                case ComponentType.AnnaDsbUpiEnrichmentSelector: this.setState({ selectors: this.props.reportConfig.annaDsbUpiEnrichmentSelectors }); break;
                case ComponentType.AnnaDsbUpiSelector: this.setState({ selectors: this.props.reportConfig.annaDsbUpiSelectors }); break;
                case ComponentType.LSESelector: this.setState({ selectors: this.props.reportConfig.lseSelectors }); break;
                case ComponentType.FirdsFcaInstrumentSelectors: this.setState({ selectors: this.props.reportConfig.firdsFcaInstrumentSelectors }); break;
                case ComponentType.FirdsEsmaInstrumentSelectors: this.setState({ selectors: this.props.reportConfig.firdsEsmaInstrumentSelectors }); break;
                case ComponentType.FCARegulatedEntitiesSelector: this.setState({ selectors: this.props.reportConfig.fcaRegulatedEntitiesSelectors }); break;
                default: break;
            }
        }
    }

    onAddSelectorClick(): void {

        var rawDataSourceId = null;
        if (this.props.reportConfig.rawDataSourceId !== null) {
            rawDataSourceId = dataSourceHelper.getById(this.props.collections.dataSources, this.props.reportConfig.rawDataSourceId as number).id;
        }

        let newSelector = selectorHelper.getEmpty(rawDataSourceId, this.state.defaultName);

        let lastNumber = this.state.selectors.length > 0 ? this.state.selectors[this.state.selectors.length - 1].number : 0;

        newSelector.number = lastNumber + 1;
        newSelector.name = newSelector.name + newSelector.number;

        let selectors = this.state.selectors.slice();
        selectors.push(newSelector);

        this.props.onChange(selectors);

        this.setState({ selectors });

    }

    onSelectorChange(location: ReportLogicLocation, changes: Selector): void {

        let selector = selectorHelper.get(this.state.selectors, location);

        Object.assign(selector, changes);

        this.props.onChange(this.state.selectors);
    }

    render(): JSX.Element {
        return (
            <>
                <div className="row no-gutters mb-3">
                    <div className="col-auto">
                        <button
                            onClick={this.onAddSelectorClick}
                            className="btn btn-light">+ {this.state.displayName}</button>
                    </div>
                    <div className="col mt-2">
                        <SelectorComponent
                            selectorNumber={this.props.selectorNumber}
                            defaultName={`${this.state.defaultName} ${this.props.selectorNumber}`}
                            selectors={this.state.selectors}
                            onSelectorSelected={this.props.onSelected} />
                    </div>
                </div>

                <DeclarationBlockBuilder
                    childComponentType={SelectorBuilder}
                    isWaiting={this.props.isWaiting}
                    isReadOnly={this.props.isReadOnly}
                    declarationBatchNumber={this.props.selectorNumber}
                    reportConfig={this.props.reportConfig}
                    counts={this.props.counts}
                    clipboard={this.props.clipboard}
                    collections={this.props.collections}
                    onDeclarationChange={this.props.onChange}
                    onClipboardChange={this.props.onClipboardChange}
                    getOperandRuleBundle={this.props.getBundleSelector}
                    getReportFieldOperandRuleBundle={this.props.getReportFieldOperandRuleBundle}
                    validate={this.props.validate}
                    validateRemoveDeclaration={this.props.validateRemoveDeclaration}
                    declarationHelper={selectorHelper}
                    type={this.props.locationTypeStatement}
                    initialDoNotPopulate={false}
                    onComponentContainerClick={this.props.onComponentContainerClick}
                    componentType={this.props.componentType} />
                {
                    this.props.children
                }
            </>
        );
    }
}

export default SelectorBlockBuilder;
