import urlHelper from '../../../infrastructure/helpers/common/urlHelper';

const buildInstructionRoute = (id: number): string => {

    return urlHelper.buildRoute(['report-field', id]);
};

const buildUrlsRoute = (id: number): string => {

    return urlHelper.buildRoute(['report-field', id, 'urls']);
};

export { buildInstructionRoute, buildUrlsRoute };
