import comparisonTypeDisplayHelper from '../condition/comparisonTypeDisplayHelper';
import Condition from '../../../../types/functions/Condition/Condition';
import conditionHelper from '../condition/conditionHelper';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import Operand from '../../../../types/functions/Operand/Operand';
import operandDisplayHelper from './../operand/operandDisplayHelper';
import operandHelper from '../operand/operandHelper';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import typeHelper from '../../common/typeHelper';
import Dictionary from '../../../../types/report/Dictionary';

const notSetUpText = '<not set>';

const openParentheses = '(';
const closeParentheses = ')';

const buildOperand = (operand: Operand, lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): string => {

    let details = operandDisplayHelper.getOperandDetails(
        operand,
        [],
        lookups,
        dictionaries,
        variables,
        dataSources,
        reportFields,
        customDataSourceFields,
        null
    );

    return details.isSetUp ? details.displayText : notSetUpText;
};

const buildConditions = (condition: Condition, lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): string => {

    let conditions = conditionHelper.asCollection(condition);

    if (conditions.every(x => !operandHelper.isSetUp(x.operand1) && !operandHelper.isSetUp(x.operand2))) {

        return '';
    }

    return conditions.map(x => buildConditionItem(x, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields)).join('\n');
};

const buildConditionItem = (item: Condition, lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): string => {

    let parts = [
        openParentheses.repeat(item.openParentheses) +
        buildOperand(item.operand1, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields),

        comparisonTypeDisplayHelper.resolve(item.comparisonType),

        buildOperand(item.operand2, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields) +
        closeParentheses.repeat(item.closeParentheses)
    ];

    let logicalOperator = conditionHelper.getLogicalOperator(item);

    if (typeHelper.isString(logicalOperator)) {

        parts.push((logicalOperator as string).toUpperCase());
    }

    return parts.join(' ');
};

const conditionDisplayHelper = {
    buildOperand,
    buildConditions
};


export default conditionDisplayHelper;
