const operandType = {
    field: 'Field',
    lookupField: 'LookupField',
    reportField: 'ReportField',
    function: 'Function',
    constant: 'Constant',
    variable: 'Variable',
    group: 'Group',
    lookupResultSet: 'LookupResultSet',
    firdsResultSetEsma: 'FirdsResultSetEsma',
    firdsResultSetFca: 'FirdsResultSetFca',
    firdsField: 'FirdsField',
    leiResultSetLevel1: 'LeiResultSetLevel1',
    leiLevel1Field: 'LeiLevel1Field',
    isinSelector: 'IsinSelector',
    exchangeRatesResultSet: 'ExchangeRatesResultSet',
    exchangeRatesField: 'ExchangeRatesField',
    annaDsbUpiEnrichmentResultSet: 'annaDsbUpiEnrichmentResultSet',
    annaDsbUpiEnrichmentField: 'annaDsbUpiEnrichmentField',
    annaDsbUpiResultSet: 'annaDsbUpiResultSet',
    annaDsbUpiField: 'annaDsbUpiField',
    arrayItem: 'ArrayItem',
    dictionary: 'Dictionary',
    userDefinedFunction: 'UserDefinedFunction',
    parameter: 'Parameter'
};

export default operandType;
