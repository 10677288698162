import fileExtension from '../../../infrastructure/constants/fileExtension';

const requiresCsvArguments = (fileType: string): boolean => {
    return (
        fileType === fileExtension.csv ||
        fileType === fileExtension.txt
    );
};

export { requiresCsvArguments };
