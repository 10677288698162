import ReportConfig from '../../../../types/report/ReportConfig';

const map = <T extends { id: number | null }>(source: T[], target: T[]): void => {

    for (let i = 0; i < Math.min(source.length, target.length); i++) {

        source[i].id = target[i].id;
    }
};

const mapIds = (source: ReportConfig, target: ReportConfig): void => {

    source.id = target.id;
    source.version = target.version;

    map(source.lookups, target.lookups);
    map(source.variables, target.variables);
    map(source.isinSelectors, target.isinSelectors);
    map(source.leiSelectors, target.leiSelectors);
    map(source.exchangeRatesSelectors, target.exchangeRatesSelectors);
    map(source.annaDsbUpiEnrichmentSelectors, target.annaDsbUpiEnrichmentSelectors);
    map(source.lseSelectors, target.lseSelectors);
    map(source.firdsFcaInstrumentSelectors, target.firdsFcaInstrumentSelectors);
    map(source.firdsEsmaInstrumentSelectors, target.firdsEsmaInstrumentSelectors);
    map(source.annaDsbUpiSelectors, target.annaDsbUpiSelectors);
    map(source.fcaRegulatedEntitiesSelectors, target.fcaRegulatedEntitiesSelectors);
    map(source.filters, target.filters);
    map(source.endpointFilters, target.endpointFilters);
    map(source.cases, target.cases);
    map(source.validations, target.validations);
    map(source.aggregations, target.aggregations);
};

const reportConfigComponentIdMapper = {
    mapIds
};

export default reportConfigComponentIdMapper;
