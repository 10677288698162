import { getColumns } from './FieldDefinitions.Grid';
import FieldDefinition from '../../../types/common/FieldDefinition';
import React from 'react';
import UiDataGrid from '../../Common/DataGrid/UiDataGrid/UiDataGrid';

interface Props {
    definitions: FieldDefinition[];
}

class FieldDefinitions extends React.Component<Props> {
    render(): JSX.Element {
        return (
            <UiDataGrid
                columns={getColumns()}
                data={this.props.definitions}
                getKey={(x, i) => i} />
        );
    }
}

export default FieldDefinitions;
