import { buildInstructionRoute, buildUrlsRoute } from './ReportFields.Helpers';
import { getColumns } from './ReportFields.Grid';
import actions from '../../../store/actions';
import ComplianceUrl from '../../../types/common/ComplianceUrl';
import ComplianceUrlEditModalState from '../../Common/Modals/ComplianceUrlEditModal/ComplianceUrlEditModalState';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import React from 'react';
import ReportFieldDefinition from '../../../types/report/ReportFieldDefinition';
import reportFieldHelper from '../../../infrastructure/helpers/functions/common/reportFieldHelper';
import ReportFieldInstructionEditModalState from '../../Common/Modals/ReportFieldInstructionEditModal/ReportFieldInstructionEditModalState';
import ReportFieldInstructions from '../../../types/report/ReportFieldInstructions';
import ReportRegimeCounts from '../../../types/report/ReportRegimeCounts';
import store from '../../../store/store';
import UiDataGrid from '../../Common/DataGrid/UiDataGrid/UiDataGrid';
import reportRegime from '../../../infrastructure/constants/reportRegime';

interface Props {
    fields: ReportFieldDefinition[];
    counts: ReportRegimeCounts;
    regime: string
}

class ReportFields extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onInfoClick = this.onInfoClick.bind(this);
        this.onUrlClick = this.onUrlClick.bind(this);
        this.onGetInstructionSuccess = this.onGetInstructionSuccess.bind(this);
        this.onGetUrlSuccess = this.onGetUrlSuccess.bind(this);
        this.getUrlCount = this.getUrlCount.bind(this);
    }

    onInfoClick(id: number): Promise<any> {

        return httpClient.get<ReportFieldInstructions>(buildInstructionRoute(id), { regime: this.props.regime }).then(response => this.onGetInstructionSuccess(id, response.data));
    }

    onUrlClick(id: number): Promise<any> {

        return httpClient.get<ComplianceUrl[]>(buildUrlsRoute(id)).then(response => this.onGetUrlSuccess(id, response.data));
    }

    onGetInstructionSuccess(id: number, instructions: ReportFieldInstructions): void {

        let state = {} as ReportFieldInstructionEditModalState;

        state.isOpen = true;
        state.title = reportFieldHelper.getById(this.props.fields, id).name;
        state.saveUrl = buildInstructionRoute(id);
        state.instructions = instructions;
        state.error = null;

        store.dispatch({ type: actions.reportFieldInstructionEditModal.open, payload: state });
    }

    onGetUrlSuccess(id: number, urls: ComplianceUrl[]): void {

        let state = {} as ComplianceUrlEditModalState;

        state.isOpen = true;
        state.saveUrl = buildUrlsRoute(id);
        state.urls = urls;
        state.error = null;

        store.dispatch({ type: actions.complianceUrlEditModal.open, payload: state });
    }

    getUrlCount(fieldId: number): number {

        return this.props.counts.urlCounts[fieldId.toString()] || 0
    }

    render(): JSX.Element {

        return (
            <UiDataGrid columns={getColumns(this.onInfoClick, this.onUrlClick, this.getUrlCount, `${this.props.regime === reportRegime['ASIC Rewrite'] ? 'ASIC Field Number' : 'RTS Field Number'}`)} data={this.props.fields} getKey={(x, i) => i} />
        );
    }
}

export default ReportFields;
