import { User } from 'oidc-client';
import IdentityDetails from '../../types/authorization/IdentityDetails';
import stringHelper from '../helpers/common/stringHelper';

const keys = {
    token: 'TOKEN',
    expiration: 'TOKEN_EXPIRATION',
    userId: 'USER_ID',
    userName: 'USER_NAME',
    userRole: 'USER_ROLE',
    clientId: 'CLIENT_ID'
};

const toIso = (expiresAt: number): string => new Date(expiresAt * 1000).toISOString();

const set = (user: User): void => {
    localStorage.setItem(keys.token, user.access_token);
    localStorage.setItem(keys.expiration, toIso(user.expires_at));
    localStorage.setItem(keys.userId, user.profile['userId'] as any);
    localStorage.setItem(keys.userName, user.profile['name'] as any);
    localStorage.setItem(keys.userRole, user.profile['role'] as any);
    localStorage.setItem(keys.clientId, user.profile['clientId'] as any);
};

const get = (): IdentityDetails => {

    let clientId = localStorage.getItem(keys.clientId) as string;

    return {
        accessToken: localStorage.getItem(keys.token) as string,
        expirationTimestampIso: localStorage.getItem(keys.expiration) as string,
        userId: localStorage.getItem(keys.userId) as string,
        userName: localStorage.getItem(keys.userName) as string,
        userRole: localStorage.getItem(keys.userRole) as string,
        clientId: stringHelper.isNonEmpty(clientId) ? parseInt(clientId) : null,
    };
};

const clear = (): void => Object.values(keys).forEach(key => localStorage.removeItem(key));

const identityStorage = {
    set,
    get,
    clear
};

export default identityStorage;
