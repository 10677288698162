import { getIngestionConfig } from './IngestionPage/IngestionPage.Helpers';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import IngestionConfigForm from '../IngestionConfigForm/IngestionConfigForm';
import IngestionStepIndicator from '../IngestionStepIndicator';
import PromiseButton from '../../Common/PromiseButton';
import React from 'react';
import store from '../../../store/store';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

class IngestionSignoffPage extends React.Component {
    private config: IngestionConfig;

    constructor(props: any) {
        super(props);

        let validState = typeHelper.isObject(history.location.state);

        if (!validState) {
            history.push(appRoutes.ingestion.home);
        }

        this.config = getIngestionConfig(history);

        this.onSaveClick = this.onSaveClick.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
    }

    onSaveClick(): Promise<any> {
        return httpClient.post('ingestion', this.config).then(this.onSaveSuccess);
    }

    onBackClick(): void {
        store.dispatch({ type: actions.navigation.execute, payload: () => history.push(appRoutes.ingestion.fieldDefinition, this.config) });
    }

    onSaveSuccess(): void {
        store.dispatch({ type: actions.navigation.execute, payload: () => history.push(appRoutes.ingestion.home) });
    }

    render(): JSX.Element {
        return (
            <>
                <IngestionStepIndicator
                    currentStepNumber={3}
                    config={this.config} />

                <IngestionConfigForm
                    isWaiting={false}
                    isReadOnly={true}
                    config={this.config}
                    onChange={() => { }}
                    ingestionConfigId={this.config.id || 0} />

                <div className="mt-4 mb-4">
                    <PromiseButton text="Save" className="btn cb-btn" enableOnErrorOnly={true} task={this.onSaveClick} />

                    <button className="btn btn-light" onClick={this.onBackClick}>Back</button>
                </div>
            </>
        );
    }
}

export default IngestionSignoffPage;
