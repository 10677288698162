import { buildClassName } from '../../../infrastructure/helpers/html/htmlUtils';
import ButtonContext from '../../../infrastructure/helpers/common/ButtonContext';
import React from 'react';

interface Props {
    errors: ButtonContext[];
    className?: string;
}

const getListItem = (error: ButtonContext, i: number): JSX.Element => {

    return (
        <li key={i}>
            <button className="transparent-button error" onClick={error.onClick}>{error.content}</button>
        </li>
    );
};

class ClickableErrorList extends React.Component<Props> {
    render(): JSX.Element {
        return (
            <ul className={buildClassName('error', this.props.className)}>
                {this.props.errors.map(getListItem)}
            </ul>
        );
    }
}

export default ClickableErrorList;
