import cnProductMatch from '../../../constants/functions/cnProductMatch';
import functionHelper from './functionHelper';
import functionName from '../../../constants/functions/definition/functionName';
import ObjectIndexer from '../../../types/ObjectIndexer';

const buildNameOverrideMap = (): ObjectIndexer<string> => {
    let map = {} as ObjectIndexer<string>;

    map[functionName.toStringBoolean] = functionName.toString;
    map[functionName.toStringDateTime] = functionName.toString;
    map[functionName.toStringDate] = functionName.toString;
    map[functionName.toStringTime] = functionName.toString;
    map[functionName.toStringArrayOfStrings] = functionName.toString;
    map[functionName.toStringArrayOfIntegers] = functionName.toString;
    map[functionName.toStringArrayOfDecimals] = functionName.toString;
    map[functionName.toStringArrayOfBooleans] = functionName.toString;
    map[functionName.toStringArrayOfDates] = functionName.toString;
    map[functionName.toStringArrayOfTimes] = functionName.toString;
    map[functionName.toStringArrayOfDateTimes] = functionName.toString;
    map[functionName.toStringArrayOfUnixTimes] = functionName.toString;

    map[functionName.substringLength] = functionName.substring;

    map[functionName.replaceIgnoreCase] = functionName.replace;

    map[functionName.inInteger] = functionName.in;
    map[functionName.inDelimited] = functionName.in;

    map[functionName.sumAggregate] = functionName.sum;

    map[functionName.countHasValue] = functionName.count;

    map[functionName.concatenateDelimiter] = functionName.concatenate;

    map[functionName.cnProductMatch] = cnProductMatch.displayName;

    map[functionName.addDaysBusiness] = functionName.addDays;
    map[functionName.addDaysFx] = functionName.addDays;

    map[functionName.toArrayOfIntegers] = functionName.toArray;
    map[functionName.toArrayOfDecimals] = functionName.toArray;
    map[functionName.toArrayOfBooleans] = functionName.toArray;
    map[functionName.toArrayOfDates] = functionName.toArray;
    map[functionName.toArrayOfTimes] = functionName.toArray;
    map[functionName.toArrayOfDateTimes] = functionName.toArray;
    map[functionName.toArrayOfUnixTimes] = functionName.toArray;

    map[functionName.allInteger] = functionName.all;
    map[functionName.allDecimal] = functionName.all;
    map[functionName.allBoolean] = functionName.all;
    map[functionName.allDate] = functionName.all;
    map[functionName.allTime] = functionName.all;
    map[functionName.allDateTime] = functionName.all;
    map[functionName.allUnixTime] = functionName.all;

    map[functionName.anyInteger] = functionName.any;
    map[functionName.anyDecimal] = functionName.any;
    map[functionName.anyBoolean] = functionName.any;
    map[functionName.anyDate] = functionName.any;
    map[functionName.anyTime] = functionName.any;
    map[functionName.anyDateTime] = functionName.any;
    map[functionName.anyUnixTime] = functionName.any;

    map[functionName.firstOrdered] = functionName.first;
    map[functionName.lastOrdered] = functionName.last;

    map[functionName.toDateTimeString] = functionName.toDateTime;

    return map;
};

const map = buildNameOverrideMap()

const getMethod = (functionName: string, args: string[]): string => {

    return `${args[0]}.${functionName}(${args.slice(1).join(', ')})`;
};

const getGlobalFunction = (functionName: string, args: string[]): string => {

    return `${functionName}(${args.join(', ')})`;
};

const getOperator = (operator: string, args: string[]): string => {

    return `(${args[0]} ${operator} ${args[1]})`;
};

const getFunctionCall = (name: string, args: string[]): string => {

    let definition = functionHelper.getFunctionDefinition(name);
    let displayName = getDisplayName(name);

    return (
        definition.isMethod ? getMethod(displayName, args) :
        definition.operator ? getOperator(definition.operator, args) :
        getGlobalFunction(displayName, args));
};

const getDisplayName = (name: string): string => {

    if (Object.keys(map).includes(name)) {

        return map[name];
    }

    return name;
};

const functionDisplayHelper = {
    getDisplayName,
    getFunctionCall
};

export default functionDisplayHelper;