import Aggregation from '../../../../types/report/Aggregation';
import assignmentHelper from '../assignment/assignmentHelper';
import clear from '../../common/array/clear';
import DataSourceField from '../../../../types/report/DataSourceField';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../operand/operandHelper';
import operandType from '../../../constants/functions/operandType';
import pseudoFunctionHelper from '../function/pseudoFunctionHelper';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import stringHelper from '../../common/stringHelper';
import copyArray from '../../common/copyArray';
import typeHelper from '../../common/typeHelper';

const add = (aggregations: Aggregation[], aggregation: Aggregation): void => {

    let lastNumber = aggregations.length > 0 ? aggregations[aggregations.length - 1].number : 0;

    aggregation.number = lastNumber + 1;

    aggregations.push(aggregation);
};

const get = (aggregations: Aggregation[], location: ReportLogicLocation): Aggregation => {

    return aggregations.find(x => x.number === location.statementNumber) as Aggregation;
};

const getDefault = (dataSource: IngestionConfig): Aggregation => {

    let result = {} as Aggregation;

    result.id = null;
    result.name = '';
    result.number = 0;
    result.businessDescription = '';
    result.fields = [];
    result.errors = [];

    reset(result, dataSource);

    return result;
};

const reset = (aggregation: Aggregation, dataSource: IngestionConfig): void => {

    aggregation.dataSourceId = dataSource.id as number;

    aggregation.groupBy = [operandHelper.getEmpty()];

    clear(aggregation.fields);

    syncFields(aggregation, dataSource);
};

const syncFields = (aggregation: Aggregation, dataSource: IngestionConfig): void => {

    dataSource.fieldDefinitions.forEach(x => {

        if (!aggregation.fields.some(f => f.fieldId === x.id)) {

            aggregation.fields.push(buildField(x.id as number));
        }
    });

    aggregation.fields = aggregation.fields.filter(f => dataSource.fieldDefinitions.some(x => x.id === f.fieldId));
};

const buildField = (fieldId: number): DataSourceField => {

    let assignment = assignmentHelper.getEmpty(false);
    assignment.number = 1;
    assignment.value = pseudoFunctionHelper.build(operandType.group);

    (assignment.value.arguments as Operand[])[1].operandType = operandType.field;
    (assignment.value.arguments as Operand[])[1].fieldId = fieldId

    return { fieldId: fieldId, assignment: assignment, errors: [] };
};

const handleAggregationChanges = (aggregations: Aggregation[]): void => {

    aggregations.filter(x => typeHelper.isNumber(x.syncedWithId)).forEach(x => {

        let syncedWith = aggregations.find(c => c.id === x.syncedWithId) as Aggregation;

        x.fields = copyArray(syncedWith.fields);
    });
}

const fallbackName = (aggregation: Aggregation): string => {

    return stringHelper.isNonEmpty(aggregation.name) ? aggregation.name : `Aggregation ${aggregation.number}`
};

const aggregationHelper = {
    add,
    get,
    getDefault,
    reset,
    syncFields,
    fallbackName,
    handleAggregationChanges
};

export default aggregationHelper;
