import { comparisonTypeDisplayMap } from '../../../constants/functions/comparisonType';

const resolve = (type: string): string => {

    return comparisonTypeDisplayMap[type];
}

const comparisonTypeDisplayHelper = {
    resolve
};

export default comparisonTypeDisplayHelper;
