import conditionHelper from '../condition/conditionHelper';
import dataSourceHelper from '../common/dataSourceHelper';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../operand/operandHelper';
import typeHelper from '../../common/typeHelper';

const findDataSourceId = (dataSources: IngestionConfig[], operand: Operand): number | null => {

    if (!typeHelper.isNumber(operand.fieldId)) {
        return null;
    }

    let dataSource = dataSourceHelper.getByFieldId(dataSources, operand.fieldId as number);

    if (!typeHelper.isObject(dataSource)) {
        return null;
    }

    return dataSource.id;
};

const updateLookupDataSourceId = (lookup: Lookup, dataSources: IngestionConfig[]): void => {

    let conditions = conditionHelper.asCollection(lookup.condition);

    for (let i = 0; i < conditions.length; i++) {

        let operands = operandHelper.asCollection(conditions[i].operand2);

        for (let j = 0; j < operands.length; j++) {

            let dataSourceId = findDataSourceId(dataSources, operands[j]);

            if (typeHelper.isNumber(dataSourceId)) {
                lookup.dataSource2Id = dataSourceId;
                return;
            }
        }
    }
};

const lookupDataSourceHelper = {
    updateLookupDataSourceId
};

export default lookupDataSourceHelper;