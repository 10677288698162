import copyObject from '../../../common/copyObject';
import CustomDataSourceFields from '../../../../../types/report/CustomDataSourceFields';
import customDataSourceHelper from '../../customDataSource/customDataSourceHelper';
import dataSourceHelper from '../../common/dataSourceHelper';
import functionHelper from '../../function/functionHelper';
import IngestionConfig from '../../../../../types/ingestion/IngestionConfig';
import Operand from '../../../../../types/functions/Operand/Operand';
import operandHelper from '../operandHelper';
import OverrideOperandCase from './OverrideOperandCase';
import ReportFieldDefinition from '../../../../../types/report/ReportFieldDefinition';
import reportFieldHelper from '../../common/reportFieldHelper';
import ReportVariable from '../../../../../types/report/ReportVariable';

const getDataType = (operand: Operand, dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, variables: ReportVariable[]): string => {

    if (operandHelper.isValidField(operand) || operandHelper.isValidLookupField(operand)) {

        let definition = dataSourceHelper.getFieldDefinitionById(dataSources, operand.fieldId as number);

        return definition.type;
    }

    if (operandHelper.isValidReportField(operand)) {

        let definition = reportFieldHelper.getById(reportFields, operand.reportFieldId as number);

        return definition.dataType;
    }

    if (operandHelper.isValidCustomDataSourceField(operand)) {

        let definition = customDataSourceHelper.getFieldById(customDataSourceFields, operand.customDataSourceFieldId as number);

        return definition.type;
    }

    if (operandHelper.isValidVariable(operand)) {

        let variable = variables.find(x => x.number === operand.variableNumber) as ReportVariable;

        return variable.dataType;
    }

    throw new Error('Unexpected operand');
};

const qualifies = (previous: Operand, current: Operand, dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, variables: ReportVariable[]): boolean => {

    let isPreviousValid =
        operandHelper.isValidField(previous) ||
        operandHelper.isValidLookupField(previous) ||
        operandHelper.isValidReportField(previous) ||
        operandHelper.isValidCustomDataSourceField(previous) ||
        operandHelper.isValidVariable(previous);

    if (!isPreviousValid) {
        return false;
    }

    if (!operandHelper.isValidFunction(current)) {
        return false;
    }

    let definition = functionHelper.getFunctionDefinition(current.function);

    let dataType = getDataType(previous, dataSources, reportFields, customDataSourceFields, variables);

    return (
        definition.arguments.length > 0 &&
        definition.arguments[0].allowedTypes.includes(dataType)
    );
};

const override = (previous: Operand, current: Operand, dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, variables: ReportVariable[]): void => {

    let copy = copyObject(previous);

    (current.arguments as Operand[])[0] = copy;

    Object.assign(previous, current);
};

const fieldToFunction: OverrideOperandCase = { qualifies: qualifies, override: override };

export default fieldToFunction;
