import Assignment from '../../../../types/functions/Assignment/Assignment';
import assignmentHelper from './assignmentHelper';
import Condition from '../../../../types/functions/Condition/Condition';
import conditionHelper from '../condition/conditionHelper';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../operand/operandHelper';

const getAll = (assignment: Assignment): Operand[] => {

    let result: Operand[] = [];

    assignmentHelper.asCollection(assignment).forEach(item => {

        conditionHelper.getAllOperands(item.condition as Condition).forEach(x => result.push(x));

        operandHelper.asCollection(item.value).forEach(x => result.push(x));
    });

    return result;
};

const assignmentOperandHelper = {
    getAll
};

export default assignmentOperandHelper;
