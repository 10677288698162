import './IngestionReplacementModal.scss';
import { AxiosResponse } from 'axios';
import { getColumns } from './IngestionReplacementModal.Grid';
import { readCsv, buildCsvContent } from './IngestionReplacementModal.Helpers';
import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import downloadHelper from '../../../../infrastructure/helpers/common/downloadHelper';
import fileExtension from '../../../../infrastructure/constants/fileExtension';
import FilePicker from '../../../Common/FilePicker/FilePicker';
import IngestionReplacementModalState from './IngestionReplacementModalState';
import Modal from '../../../Common/Modal/Modal';
import React from 'react';
import store from '../../../../store/store';
import StringReplacement from '../../../../infrastructure/types/StringReplacement';
import UiDataGrid from '../../../Common/DataGrid/UiDataGrid/UiDataGrid';

class IngestionReplacementModal extends React.Component<any, IngestionReplacementModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            isOpen: false,
            error: null,
            index: null as any,
            replacements: []
        };

        this.openListener = this.openListener.bind(this);
        this.onFilePicked = this.onFilePicked.bind(this);
        this.onReadCsvSuccess = this.onReadCsvSuccess.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.openListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    openListener(): void {
        let state = store.getState();

        if (state.action !== actions.ingestionReplacementModal.open) {
            return;
        }

        this.setState(state.ingestionReplacementModalState);
    }

    onFilePicked(files: FileList, error: string): void {

        this.setState({ error: error });

        if (error) {
            return;
        }

        readCsv(files.item(0) as File).then(this.onReadCsvSuccess);
    }

    onReadCsvSuccess(response: AxiosResponse<StringReplacement[]>): void {

        this.setState({ replacements: response.data });
    }

    onSaveClick(): void {

        store.dispatch({ type: actions.ingestionReplacementModal.save, payload: this.state });

        this.close();
    }

    onDownloadClick(): void {

        let content = buildCsvContent(this.state.replacements);

        let blob = new Blob([content], { type: 'text/csv' });

        downloadHelper.download(blob, `${this.state.title}.csv`);
    }

    onClearClick(): void {

        this.setState({ replacements: [] });
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    render(): JSX.Element {

        return (
            <Modal state={this.state} close={this.close} size="large">

                <div className="ingestion-replacement-modal-grid">

                    <UiDataGrid
                        columns={getColumns()}
                        data={this.state.replacements}
                        getKey={(x, i) => i} />

                </div>

                <div className="mt-4">
                    <FilePicker
                        uniqueName="ingestion-replacements"
                        allowedExtensions={[fileExtension.csv]}
                        maxFileCount={1}
                        maxFileSizeInMb={5}
                        autoReset={true}
                        height={100}
                        onPicked={this.onFilePicked} />
                </div>

                <div className="mt-3">
                    <button className="btn cb-btn" onClick={this.onSaveClick}>Save</button>
                    <button className="btn btn-light" onClick={this.onDownloadClick}>Download</button>
                    <button className="btn btn-light" onClick={this.onClearClick}>Clear</button>
                </div>

            </Modal>
        );
    }
}

export default IngestionReplacementModal;
