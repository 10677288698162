import Aggregation from '../../../types/report/Aggregation';
import SelectOption from '../../../infrastructure/types/SelectOption';

const createAggregationOption = (aggregation: Aggregation): SelectOption => {

    return {
        value: aggregation.number.toString(),
        text: aggregation.name || `Aggregation ${aggregation.number}`
    }
};

export { createAggregationOption }