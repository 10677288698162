import React from 'react';
import userDefinedFunctionHelper from '../../../infrastructure/helpers/functions/userDefinedFunctions/userDefinedFunctionHelper';
import referenceValidator from '../../../infrastructure/helpers/functions/validator/referenceValidator';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportCollections from '../../../infrastructure/types/Functions/ReportCollections';
import Assignment from '../../../types/functions/Assignment/Assignment';
import locationType from '../../../types/functions/Location/LocationType';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import ReportConfig from '../../../types/report/ReportConfig';
import UserDefinedFunction from '../../../types/report/UserDefinedFunction';
import ErrorList from '../../Common/ErrorList/ErrorList';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import { getColumns } from './UserDefinedFunctionBuilder.Grid';
import UserDefinedFunctionSelector from './UserDefinedFunctionSelector/UserDefinedFunctionSelector';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';

interface Props {
    isWaiting: boolean;
    isReadOnly: boolean;
    functionNumber: number | null;
    reportConfig: ReportConfig;
    collections: ReportCollections;
    onSelected: (number: number) => void;
    onChange: (dictionaries: UserDefinedFunction[]) => void;
    clipboard: ReportLogicLocation | null;
    onClipboardChange: (clipboard: ReportLogicLocation) => void;
}
interface State {
    showWarningModal: boolean
}
class UserDefinedFunctionBuilder extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showWarningModal: false
        }

        this.onAddFunctionClick = this.onAddFunctionClick.bind(this);
        this.onRemoveFunctionClick = this.onRemoveFunctionClick.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onReturnTypeChange = this.onReturnTypeChange.bind(this);
        this.onBusinessDescriptionChange = this.onBusinessDescriptionChange.bind(this);
        this.onFunctionChange = this.onFunctionChange.bind(this);
        this.getContent = this.getContent.bind(this);
        this.onRemoveFunctionApproved = this.onRemoveFunctionApproved.bind(this);
    }

    onAddFunctionClick(): void {

        let dictionary = userDefinedFunctionHelper.getDefault();

        userDefinedFunctionHelper.add(this.props.reportConfig.userDefinedFunctions, dictionary);

        this.props.onChange(this.props.reportConfig.userDefinedFunctions);
    }

    validateReferences(): string {
        return referenceValidator.validateUserDefinedFunctionReference(this.props.functionNumber as number, this.props.reportConfig) || '';
    }

    onRemoveFunctionClick(): void {

        let functionNumber = this.props.functionNumber || 0;

        let location = this.getLocation(functionNumber, []);

        let error = this.validateReferences();

        if (error) {
            let target = userDefinedFunctionHelper.get(this.props.reportConfig.userDefinedFunctions, location);

            target.errors = [error];

            this.props.onChange(this.props.reportConfig.userDefinedFunctions);

            return;
        }

        this.setState({ showWarningModal: true });
    }

    onRemoveFunctionApproved() {

        let location = this.getLocation(this.props.functionNumber as number, []);

        let reduced = this.props.reportConfig.userDefinedFunctions.filter(x => x.number !== location.statementNumber);

        this.props.onChange(reduced);
    }

    onNameChange(name: string, number: number): void {

        let location = this.getLocation(number, []);

        this.onFunctionChange(location, { name: name } as UserDefinedFunction);
    }

    onReturnTypeChange(dataType: string, number: number, assignment: Assignment): void {

        let location = this.getLocation(number, []);

        //let proceed = window.confirm('Changing the data type will reset the logic for this field.');
        //if (!proceed) {
        //    return;
        //}

        this.onFunctionChange(location, { returnType: dataType } as UserDefinedFunction);
    }

    onBusinessDescriptionChange(businessDescription: string, number: number): void {

        let location = this.getLocation(number, []);

        this.onFunctionChange(location, { businessDescription: businessDescription } as UserDefinedFunction);
    }

    getLocation(number: number, argumentIndexes: number[]): ReportLogicLocation {

        let location = {} as ReportLogicLocation;

        location.statement = locationType.statement.userDefinedFunction;
        location.statementNumber = number;
        location.argumentIndexes = argumentIndexes;

        return location;
    }

    onFunctionChange(location: ReportLogicLocation, changes: UserDefinedFunction): void {

        let target = userDefinedFunctionHelper.get(this.props.reportConfig.userDefinedFunctions, location);

        Object.assign(target, changes);

        this.props.onChange(this.props.reportConfig.userDefinedFunctions);
    }

    getContent(func: UserDefinedFunction): JSX.Element {

        return (
            <>
                {
                    this.props.children
                }

                <ErrorList errors={func.errors} className="mt-2" />
            </>
        );
    }

    render(): JSX.Element {
        return (
            <>
                <div className="row no-gutters mb-3">
                    <div className="col-auto">
                        <button
                            onClick={this.onAddFunctionClick}
                            className="btn btn-light">+ Function</button>
                    </div>
                    <div className="col mt-2">
                        <UserDefinedFunctionSelector
                            isWaiting={this.props.isWaiting}
                            functionNumber={this.props.functionNumber}
                            functions={this.props.reportConfig.userDefinedFunctions}
                            onFunctionSelected={this.props.onSelected} />
                    </div>
                </div>

                {
                    this.props.functionNumber &&
                    <>
                        <ReportLogicList
                            isWaiting={this.props.isWaiting}
                            items={this.props.reportConfig.userDefinedFunctions.filter(x => x.number === this.props.functionNumber)}
                            columns={getColumns(this.onNameChange, this.onReturnTypeChange, this.onBusinessDescriptionChange, this.onRemoveFunctionClick, this.props.isReadOnly)}
                            getContent={this.getContent}
                            getKey={a => a.number}
                            createHtmlId={v => reportConfigNavigation.buildFunctionId(v.number)} />
                    </>
                }
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onRemoveFunctionApproved}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Remove item'
                        message='Are you sure you want to delete this item? This action cannot be undone.' />
                }
            </>
        );
    }
}

export default UserDefinedFunctionBuilder;
