import { ReactComponent as MinusIcon } from '../../../components/Common/BootstrapIcons/dash.svg';
import { ReactComponent as PlusIcon } from '../../../components/Common/BootstrapIcons/plus.svg';
import { resolveSectionPresence } from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation.HtmlIds';
import InfoIcon from '../../Common/InfoIcon/InfoIcon';
import React, { Fragment } from 'react';

interface Props {
    reportType: string;
    isWaiting: boolean;
    heading: string;
    info: string;
    htmlId: string;
    onClick: (htmlId: string) => void;
    isExpanded: (htmlId: string) => boolean;
}

class Section extends React.Component<Props>{

    constructor(props: Props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(): void {
        this.props.onClick(this.props.htmlId);
    }

    getClassName(): string {
        let classes = ['collapse'];

        if (this.props.isExpanded(this.props.htmlId)) {
            classes.push('show');
        }

        return classes.join(' ');
    }

    resolveIcon(): JSX.Element {

        return this.props.isExpanded(this.props.htmlId) ? <MinusIcon /> : <PlusIcon />;
    }

    resolveTooltip(): string {

        return this.props.isExpanded(this.props.htmlId) ? 'collapse' : 'expand';
    }

    render(): JSX.Element {

        if (this.props.isWaiting || !resolveSectionPresence(this.props.htmlId, this.props.reportType)) {

            return (<></>);
        }

        return (
            <Fragment key={this.props.htmlId}>
                <div className="section-heading">

                    <InfoIcon info={this.props.info as string} />

                    <h4 id={this.props.htmlId}>

                        {this.props.heading}

                        <button className="transparent-button" onClick={this.onClick} title={this.resolveTooltip()}>
                            {this.resolveIcon()}
                        </button>
                    </h4>
                </div>

                <div className={this.getClassName()}>
                    {this.props.children}
                </div>
            </Fragment>
        );
    }
}

export default Section;
