import './RichTextEditor.scss';
import React from 'react';

interface Props {
    html: string;
    update: (html: string) => void;
}

class RichTextEditor extends React.Component<Props> {

    render(): JSX.Element {

        return (
            <div
                className="rich-text-editor"
                contentEditable={true}
                dangerouslySetInnerHTML={{ __html: this.props.html }}
                onBlur={e => this.props.update(e.currentTarget.innerHTML)}>
            </div>
        );
    }
}

export default RichTextEditor;
