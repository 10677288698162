import { History } from 'history';
import dataSourceKind from '../../../../infrastructure/constants/dataSourceKind';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';

const getIngestionConfig = (history: History<any>): IngestionConfig => {

    return typeHelper.isObject(history.location.state) ? history.location.state : getEmptyIngestionConfig();
};

const getEmptyIngestionConfig = (): IngestionConfig => {
    return {
        id: null,
        name: '',
        filenames: '',
        xmlSectionName: '',
        jsonSectionName: '',
        description: '',
        emptyValueTokens: '',
        isTransposed: null,
        dataSourceKind: dataSourceKind.raw,
        fileType: '',
        fieldDefinitions: [],
        csvSpecification: {
            separator: '',
            textSeparator: null,
            headerLineNumber: 0,
            dataLineNumber: null as any,
            escapeCharacter: '"'
        },
        version: null as any
    };
};

export {
    getIngestionConfig,
    getEmptyIngestionConfig
};
