import functionHelper from './functionHelper';
import functionName from '../../../constants/functions/definition/functionName';
import Operand from '../../../../types/functions/Operand/Operand';
import operandType from '../../../constants/functions/operandType';
import pseudoFunction from '../../../constants/functions/pseudoFunction';

const dataSourceTypes = [
    operandType.firdsResultSetEsma,
    operandType.firdsResultSetFca,
    operandType.leiResultSetLevel1,
    operandType.exchangeRatesResultSet,
    operandType.annaDsbUpiEnrichmentResultSet,
    operandType.group
];

const validate = (dataSourceType: string): void => {

    if (!dataSourceTypes.includes(dataSourceType)) {

        throw new Error(`Unexpected data source type option '${dataSourceType}'`);
    }
};

const isPseudoFunction = ($function: string): boolean => {
    return (
        $function === pseudoFunction.firds ||
        $function === pseudoFunction.lei ||
        $function === pseudoFunction.exchangeRates ||
        $function === pseudoFunction.annaDsbUpiEnrichment
    );
};

const resolveDefaultDataSourceType = ($function: string): string => {

    switch ($function) {

        case pseudoFunction.firds:
            return operandType.firdsResultSetEsma;

        case pseudoFunction.lei:
            return operandType.leiResultSetLevel1;

        case pseudoFunction.exchangeRates:
            return operandType.exchangeRatesResultSet;

        case pseudoFunction.annaDsbUpiEnrichment:
            return operandType.annaDsbUpiEnrichmentResultSet;

        default:
            throw new Error(`Unexpected pseudo function '${$function}'`);
    }
};

const build = (dataSourceType: string): Operand => {

    validate(dataSourceType);

    let result = {} as Operand;

    result.operandType = operandType.function;
    result.function = functionName.first;

    result.arguments = functionHelper.getEmptyArguments(result.function, null);

    (result.arguments as Operand[])[0].operandType = dataSourceType;

    return result;
};

const buildSpecial = (dataSourceType: string, selectorNumber: number): Operand => {

    let result = {} as Operand;

    result.operandType = operandType.function;
    result.function = functionName.first;

    result.arguments = functionHelper.getEmptyArguments(result.function, null);

    (result.arguments as Operand[])[0].operandType = dataSourceType;
    (result.arguments as Operand[])[0].selectorNumber = selectorNumber;

    return result;
};

const pseudoFunctionHelper = {
    isPseudoFunction,
    resolveDefaultDataSourceType,
    build,
    buildSpecial
};

export default pseudoFunctionHelper;