import React from 'react';
import typeHelper from '../../infrastructure/helpers/common/typeHelper';
import Spinner from './Spinner/Spinner';

interface PromiseButtonProps {
    text?: string;
    title?: string;
    className: string;
    enableOnErrorOnly: boolean;
    disabled?: boolean;
    spinner?: boolean;
    task: (event?: React.MouseEvent<HTMLButtonElement>) => Promise<any>;
}

interface PromiseButtonState {
    isWaiting: boolean;
}

class PromiseButton extends React.Component<PromiseButtonProps, PromiseButtonState> {

    constructor(props: PromiseButtonProps) {
        super(props);

        this.state = { isWaiting: false };

        this.onClick = this.onClick.bind(this);
        this.enableButton = this.enableButton.bind(this);
    }

    enableButton(): void {
        this.setState({ isWaiting: false });
    }

    onClick(e: React.MouseEvent<HTMLButtonElement>): void {
        this.setState({ isWaiting: true });

        this.props.task(e)[this.props.enableOnErrorOnly ? 'catch' : 'finally'](this.enableButton);
    }

    render(): JSX.Element {
        return (
            <>
                <button
                    type="button"
                    className={this.props.className}
                    onClick={this.onClick}
                    disabled={this.state.isWaiting || this.props.disabled}
                    title={this.props.title}>
                    {typeHelper.isString(this.props.text) ? this.props.text : this.props.children}
                </button>
                {
                    this.props.spinner &&
                    this.state.isWaiting &&
                    <Spinner />
                }
            </>
        );
    }
}

export default PromiseButton;
