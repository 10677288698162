import './BespokeConditionBuilder.scss';
import EditableText from '../../Common/EditableText/EditableText';
import ErrorList from '../../Common/ErrorList/ErrorList';
import React from 'react';
import Statement from '../../../infrastructure/types/Functions/Statement';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import ReportConfigComponentLock from '../../../types/report/ReportConfigComponentLock';

interface Props<T> {
    keyword: string;
    keyword2?: string;
    statement: T;
    onChange: (statement: T, reportConfigComponentLock?: ReportConfigComponentLock) => void;
    isReadOnly: boolean;
}

class BespokeConditionBuilder<T extends Statement> extends React.Component<Props<T>> {

    onChange(value: any) {
        this.props.onChange({ number: this.props.statement.number, bespokeCondition: value } as T)
    }

    render(): JSX.Element {
        return (
            
            <div className="bespoke-condition-builder">
                <div className="code-block">
                    <table>
                        <tbody>
                            <tr>
                                <td className="keyword code">{this.props.keyword}</td>
                                <td>
                                    <EditableText
                                        multiline={true}
                                        className="code"
                                        value={this.props.statement.bespokeCondition}
                                        onChange={this.onChange}
                                        disabled={this.props.isReadOnly} />
                                </td>
                            </tr>
                            {
                                typeHelper.isString(this.props.keyword2) &&
                                <tr className="code">
                                    <td colSpan={2}>{this.props.keyword2}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <ErrorList errors={this.props.statement.errors} />
            </div>
        );
    }
}

export default BespokeConditionBuilder;
