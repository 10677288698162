import { dateFormats, dateTimeSeparators, timeFormats } from '../../constants/dateTimeFormats';
import dataType from '../../constants/dataType';
import DateTimeFormat from '../../types/DateTimeFormatParts';
import dateTimeSeparatorHelper from './dateTimeSeparatorHelper';
import stringHelper from './stringHelper';
import typeHelper from './typeHelper';

const getEmptyResult = (): DateTimeFormat => {
    return {
        date: '',
        time: '',
        separator: ''
    };
};

const getParts = (format: string): DateTimeFormat => {

    if (stringHelper.isEmpty(format)) {
        return getEmptyResult();
    }

    let i = dateTimeSeparatorHelper.findIndex(format);

    if (i === -1) {
        throw new Error('Inalid DateTime format');
    }

    return {
        date: format.substring(0, i),
        time: format.substring(i + 1),
        separator: format.substring(i, i + 1)
    };
};

const swapParts = (format: string, date: string | null, time: string | null, separator: string | null): string => {

    let parts = getParts(format);

    if (typeHelper.isString(date)) {
        parts.date = date as string;
    }
    if (typeHelper.isString(time)) {
        parts.time = time as string;
    }
    if (typeHelper.isString(separator)) {
        parts.separator = separator as string;
    }

    return parts.date + parts.separator + parts.time;
};

const getDefaultFormat = (type: string): string => {

    if (!isDateOrTime(type)) {
        return '';
    }

    if (type === dataType.date ||
        type === dataType.arrayOfDates) {
        return dateFormats[0];
    }

    if (type === dataType.time ||
        type === dataType.arrayOfTimes) {
        return timeFormats[0];
    }

    return swapParts('', dateFormats[0], timeFormats[0], dateTimeSeparators[0]);
}

const isDateOrTime = (type: string): boolean => {
    return (
        type === dataType.date ||
        type === dataType.time ||
        type === dataType.dateTime ||
        type === dataType.arrayOfDates ||
        type === dataType.arrayOfTimes ||
        type === dataType.arrayOfDateTimes
    );
};

const dateTimeFormatHelper = {
    getParts,
    swapParts,
    getDefaultFormat,
    isDateOrTime
};

export default dateTimeFormatHelper;
