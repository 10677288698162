import systemField from '../../constants/ingestion/systemField';

const isSystemField = (name: string): boolean => {

    return name === systemField.dateTimeFromFilename || name === systemField.fileNameFromFile;
};

const systemFieldHelper = {
    isSystemField
};

export default systemFieldHelper;
