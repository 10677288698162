import { exchangeRatesResultSetOptions } from './ExchangeRatesResultSetBuilder.Helpers';
import RadioButtonGroup from '../../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';

interface Props {
    value: string;
}

class ExchangeRatesResultSetBuilder extends React.Component<Props> {

    render(): JSX.Element {

        return (
            <div className="mt-3">
                <RadioButtonGroup
                    options={exchangeRatesResultSetOptions}
                    name="exchange-rates-types"
                    value={this.props.value}
                    onChange={() => { }} />
            </div>
        );
    }
}

export default ExchangeRatesResultSetBuilder;
