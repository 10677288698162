import Aggregation from '../../../../types/report/Aggregation';
import DataSourceField from '../../../../types/report/DataSourceField';
import DeclarationHelper from '../../../types/Functions/DeclarationHelper';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';

class AggregatedRecordFieldHelper implements DeclarationHelper<DataSourceField> {

    add(declarations: DataSourceField[], declaration: DataSourceField): void {

        throw new Error('Not relevant');
    }

    remove(declarations: DataSourceField[], location: ReportLogicLocation): DataSourceField[] {

        throw new Error('Not relevant');
    }

    getDeclarations(config: ReportConfig, location: ReportLogicLocation): DataSourceField[] {

        let aggregation = this.getAggregation(config, location);

        return aggregation.fields;
    }

    getDeclaration(declarations: DataSourceField[], location: ReportLogicLocation): DataSourceField {

        let declaration = declarations.find(x => x.fieldId === location.fieldId) as DataSourceField;

        return declaration;
    }

    getDataSourceId(config: ReportConfig, location: ReportLogicLocation): number {

        let aggregation = this.getAggregation(config, location);

        return aggregation?.dataSourceId || 0;
    }

    getEmpty(rawDataSourceId: number | null): DataSourceField {

        throw new Error('Not relevant');
    }

    private getAggregation(config: ReportConfig, location: ReportLogicLocation): Aggregation {

        return config.aggregations.find(x => x.number === location.statementNumber) as Aggregation;
    }
}

const aggregatedRecordFieldHelper = new AggregatedRecordFieldHelper();

export default aggregatedRecordFieldHelper;
