import React from 'react';

interface Props {
    count: number;
    character: string;
}

class ParenthesesDisplay extends React.Component<Props> {
    render(): JSX.Element {
        return (
            <span className="code">{this.props.character.repeat(this.props.count)}</span>
        );
    }
}

export default ParenthesesDisplay;
