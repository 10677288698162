import { getDefaultState, createFieldOption, createEmptyFieldOption } from './CopyVariableModal.Helpers';
import { Unsubscribe } from 'redux';
import { validate } from './CopyVariableModal.Validation';
import actions from '../../../../store/actions';
import Modal from '../../../Common/Modal/Modal';
import React from 'react';
import Variable from '../../../../types/report/ReportVariable';
import SelectSearch, { SelectedOptionValue, SelectSearchOption } from 'react-select-search';
import store from '../../../../store/store';
import WarningModal from '../../../Common/Modals/WarningModal/WarningModal';
import CopyVariableModalState from './CopyVariableModalState';
import operandHelper from '../../../../infrastructure/helpers/functions/operand/operandHelper';
import Operand from '../../../../types/functions/Operand/Operand';

interface Props {
    variables: Variable[];
}

class CopyVariableModal extends React.Component<Props, CopyVariableModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: Props) {
        super(props);

        this.state = getDefaultState();

        this.onOpen = this.onOpen.bind(this);
        this.onSourceFieldChange = this.onSourceFieldChange.bind(this);
        this.onTargetFieldChange = this.onTargetFieldChange.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.close = this.close.bind(this);
        this.getFieldsWithEmptyOption = this.getFieldsWithEmptyOption.bind(this);
        this.onCopyConfirmed = this.onCopyConfirmed.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onOpen);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    onOpen(): void {
        let state = store.getState();

        if (state.action !== actions.copyVariableModal.change) {
            return;
        }

        this.setState(state.copyVariableModalChange);
    }

    onSourceFieldChange(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {

        let changes = { sourceVariableNumber: selectedValue.toString() } as CopyVariableModalState;

        this.setState(changes);
    }

    onTargetFieldChange(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {

        let changes = { targetVariableNumber: selectedValue.toString() } as CopyVariableModalState;

        this.setState(changes);
    }

    onCopyClick(): void {

        let error = validate(this.state, this.props.variables);

        if (error) {
            this.setState({ error: error });
            return;
        }

        let target = this.props.variables.find(x => x.number.toString() === this.state.targetVariableNumber);

        let message = `The logic of ${target?.name} will be overwritten. Are you sure you want to proceed?`;

        this.setState({ showWarningModal: true, message });
    }

    onCopyConfirmed() {

        store.dispatch({ type: actions.copyVariableModal.save, payload: this.state });

        var defaultState = getDefaultState();

        defaultState.showWarningModal = false;

        this.setState(defaultState);

        this.close();
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    getFieldsWithEmptyOption(): SelectSearchOption[] {

        let targetVariables: Variable[] = [];

        let sourceNumber = parseInt(this.state.sourceVariableNumber);

        var variable = this.props.variables.find(v => v.number === sourceNumber);

        let hightestReferencedVariableNumber = 0;

        if (variable?.assignment) {

            operandHelper.proceedWhitAssignment(variable.assignment, (operand: Operand) => {

                if (operand.variableNumber) {
                    hightestReferencedVariableNumber = Math.max(hightestReferencedVariableNumber, operand.variableNumber);
                }
            });

        }

        targetVariables = this.props.variables.filter(v => v.number > hightestReferencedVariableNumber && v.number !== sourceNumber);

        var fields = targetVariables.map(createFieldOption);

        fields.splice(0, 0, createEmptyFieldOption());

        return fields;
    }

    render(): JSX.Element {

        return (
            <>
                <Modal state={this.state} close={this.close} size="large">

                    <h5 className="mb-3">From</h5>

                    {
                        <SelectSearch
                            options={this.props.variables.map(createFieldOption)}
                            value={this.state.sourceVariableNumber}
                            onChange={this.onSourceFieldChange}
                            search={true} />
                    }

                    <h5 className="mt-4 mb-3">To</h5>

                    {
                        <SelectSearch
                            options={this.getFieldsWithEmptyOption()}
                            value={this.state.targetVariableNumber}
                            onChange={this.onTargetFieldChange}
                            search={true} />
                    }

                    <div className="mt-3 mb-3">
                        <button className="btn cb-btn" onClick={this.onCopyClick}>Copy</button>
                    </div>

                </Modal>
                {
                    this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onCopyConfirmed}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Copy variable'
                        message={this.state.message} />
                }
            </>
        );
    }
}

export default CopyVariableModal;