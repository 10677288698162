import CopyReportFieldModalState from './CopyReportFieldModalState';
import ReportCase from '../../../../types/report/ReportCase';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';

const sameDataSource = (state: CopyReportFieldModalState, cases: ReportCase[]): boolean => {

    let sourceCaseNumber = parseInt(state.sourceCaseNumber);
    state.targetCaseNumbers.forEach((number) => {
        if ((cases.find(x => x.number === sourceCaseNumber) as ReportCase).dataSourceId !==
            (cases.find(x => x.number === parseInt(number)) as ReportCase).dataSourceId) {
            return false;
        }
    });
    return true;
}

const validate = (state: CopyReportFieldModalState, cases: ReportCase[], fields: ReportFieldDefinition[]): string | null => {

    if (!sameDataSource(state, cases)) {

        return 'Unable to copy field(s) because \'From\' and \'To\' cases have different data sources';
    }

    if (state.copyAllFields) {
        return null;
    }

    if (stringHelper.isEmpty(state.sourceFieldId)) {
        return 'From field is required';
    }

    if (stringHelper.isEmpty(state.targetFieldId)) {
        return 'To field is required';
    }

    let sameCase = state.targetCaseNumbers.has(state.sourceCaseNumber);
    let sameField = state.sourceFieldId === state.targetFieldId;

    if (sameCase && sameField) {
        return 'Unable to copy from a field to the same field (same case)';
    }

    let sourceFieldId = parseInt(state.sourceFieldId);
    let targetFieldId = parseInt(state.targetFieldId);

    let sourceField = fields.find(x => x.id === sourceFieldId) as ReportFieldDefinition;
    let targetField = fields.find(x => x.id === targetFieldId) as ReportFieldDefinition;

    if (sourceField.dataType !== targetField.dataType) {
        return `Unable to copy from [${sourceField.name}] (${sourceField.dataType}) to [${targetField.name}] (${targetField.dataType}) because of type mismatch`;
    }

    return null;
};

export { validate };
