import { AxiosResponse } from 'axios';
import { getEmptyIngestionConfig } from './IngestionPage/IngestionPage.Helpers';
import validateMasterFileLocation from '../Details/IngestionDetails.Validation';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import identityStorage from '../../../infrastructure/authorization/identityStorage';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import IngestionConfigForm from '../IngestionConfigForm/IngestionConfigForm';
import React from 'react';
import store from '../../../store/store';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import validate from '../FieldDefinitions/FieldDefinitions.Validation';
import validateEmptyValueTokens from '../Details/IngestionDetails.Validation.EmptyValueTokens';
import validateFilenames from '../IngestionFilenames/IngestionFilenames.Validation';
import VersionEditor from '../../Common/VersionEditor/VersionEditor';
import writeUserRoles from '../../../infrastructure/constants/identity/writeUserRoles';
import QuickActions from '../../Common/QuickActions/QuickActions';
import validateConfigName from '../../../infrastructure/helpers/common/validator/nameValidator';
import domNavigationHelper from '../../../infrastructure/helpers/html/domNavigationHelper';

const ErrorMessageId = "ErrorMessage";

interface IngestionEditPageState {
    isWaiting: boolean;
    config: IngestionConfig;
    majorVersionIncremented: boolean;
    error: string | null;
}

class IngestionEditPage extends React.Component<any, IngestionEditPageState> {
    id: string;
    isValidQueryParam: boolean;

    constructor(props: any) {
        super(props);

        let query = urlHelper.deserializeQuery(history.location.search);

        this.id = query['id'];

        this.isValidQueryParam = typeHelper.isInteger(parseInt(this.id));

        if (!this.isValidQueryParam) {
            history.push(appRoutes.ingestion.home);
        }

        this.state = {
            config: getEmptyIngestionConfig(),
            majorVersionIncremented: false,
            isWaiting: true,
            error: null
        };

        this.onGetSuccess = this.onGetSuccess.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onMajorVersionIncrementedChange = this.onMajorVersionIncrementedChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onExitClick = this.onExitClick.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
    }

    componentDidMount(): void {
        if (!this.isValidQueryParam) {
            return;
        }

        let url = urlHelper.buildRoute(['ingestion', this.id]);

        httpClient.get<IngestionConfig>(url).then(this.onGetSuccess);
    }

    onGetSuccess(response: AxiosResponse<IngestionConfig>): void {

        this.setState({ config: response.data, isWaiting: false });
    }

    onChange(changes: IngestionConfig): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        this.setState({ config: Object.assign({}, this.state.config, changes) });
    }

    onMajorVersionIncrementedChange(value: boolean): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        this.setState({ majorVersionIncremented: value });
    }

    onSaveClick(exit: boolean): Promise<any> {

        let error =
            validate(this.state.config.fieldDefinitions, this.state.config.isTransposed) ||
            validateConfigName(this.state.config.name, false) ||
            validateFilenames(this.state.config.filenames) ||
            validateMasterFileLocation(this.state.config) ||
            validateEmptyValueTokens(this.state.config.emptyValueTokens);

        if (error) {
            this.setState({ error: error }, () => domNavigationHelper.navigate(ErrorMessageId));
            return Promise.reject(error)
        }

        let url = urlHelper.buildRoute(['ingestion', this.id, this.state.majorVersionIncremented]);

        return httpClient.put(url, this.state.config).then(() => this.onSaveSuccess(exit));
    }

    onExitClick(): void {
        history.push(appRoutes.ingestion.home);
    }

    onSaveSuccess(exit: boolean): void {

        const payload = (): void => {

            if (exit) {
                history.push(appRoutes.ingestion.home);
            }
            else {
                window.location.reload();
            }
        };

        store.dispatch({ type: actions.navigation.execute, payload: payload });
    }

    render(): JSX.Element {

        let identity = identityStorage.get();

        let hasWriteAccess = writeUserRoles.includes(identity.userRole);

        return (
            <>
                <IngestionConfigForm
                    isWaiting={this.state.isWaiting}
                    isReadOnly={!hasWriteAccess}
                    config={this.state.config}
                    onChange={this.onChange}
                    ingestionConfigId={parseInt(this.id)}
                />

                <div id={ErrorMessageId}>
                    <ErrorMessage error={this.state.error} />
                </div>

                {
                    hasWriteAccess &&
                    !this.state.isWaiting &&
                    <>
                        <VersionEditor
                            className="mt-2"
                            version={this.state.config.version}
                            majorIncremented={this.state.majorVersionIncremented}
                            onChange={this.onMajorVersionIncrementedChange} />
                    </>
                }

                <QuickActions
                    isWaiting={this.state.isWaiting}
                    isReadOnly={!hasWriteAccess}
                    onSaveClick={() => this.onSaveClick(false)}
                    onSaveAndExitClick={() => this.onSaveClick(true)}
                    onExitClick={this.onExitClick} />
            </>
        );
    }
}

export default IngestionEditPage;
