const publicHolidayCountries = [
    'UK',
    'US',
    'CA',
    'AU act',
    'AU nsw',
    'AU nt',
    'AU qld',
    'AU sa',
    'AU tas',
    'AU vic',
    'AU wa',
    'AX',
    'AL',
    'AD',
    'AR',
    'AT',
    'BS',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BJ',
    'BO',
    'BA',
    'BW',
    'BR',
    'BG',
    'CL',
    'CN',
    'CO',
    'CR',
    'HR',
    'CU',
    'CY',
    'CZ',
    'DK',
    'DO',
    'EC',
    'EG',
    'SV',
    'EE',
    'FO',
    'FI',
    'FR',
    'GA',
    'GM',
    'DE',
    'GI',
    'GR',
    'GL',
    'GD',
    'GT',
    'GG',
    'GY',
    'HT',
    'HN',
    'HU',
    'IS',
    'ID',
    'IE',
    'IM',
    'IT',
    'JM',
    'JP',
    'JE',
    'LV',
    'LS',
    'LI',
    'LT',
    'LU',
    'MK',
    'MG',
    'MT',
    'MX',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'NA',
    'NL',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NO',
    'PA',
    'PG',
    'PY',
    'PE',
    'PL',
    'PT',
    'PR',
    'RO',
    'RU',
    'SM',
    'RS',
    'SG',
    'SK',
    'SI',
    'ZA',
    'KR',
    'ES',
    'SR',
    'SJ',
    'SE',
    'CH',
    'TN',
    'TR',
    'UA',
    'GB',
    'UY',
    'VA',
    'VE',
    'VN',
    'ZW'
];

export default publicHolidayCountries;
