import copyObject from '../../common/copyObject';
import dataType from '../../../constants/dataType';
import functionHelper from '../function/functionHelper';
import functionName from '../../../constants/functions/definition/functionName';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from './operandHelper';
import operandType from '../../../constants/functions/operandType';
import typeHelper from '../../common/typeHelper';

const inject = (allowedTypes: string[], dataType: string, operand: Operand): boolean => {

    if (allowedTypes.includes(dataType)) {

        return false;
    }

    let functionName: string | null;

    for (let i = 0; i < allowedTypes.length; i++) {

        functionName = resolve(allowedTypes[i], dataType);

        if (typeHelper.isString(functionName)) {

            modify(operand, functionName as string);
            return true;
        }
    }

    return false;
};

const resolve = (expectedType: string, actualType: string): string | null => {

    if (expectedType === dataType.string) {

        switch (actualType) {

            case dataType.decimal:
            case dataType.integer:
                return functionName.toString;

            case dataType.boolean:
                return functionName.toStringBoolean;

            case dataType.dateTime:
                return functionName.toStringDateTime;

            case dataType.date:
                return functionName.toStringDate;

            case dataType.time:
                return functionName.toStringTime;

            case dataType.arrayOfStrings:
                return functionName.toStringArrayOfStrings;

            case dataType.arrayOfIntegers:
                return functionName.toStringArrayOfIntegers;

            case dataType.arrayOfDecimals:
                return functionName.toStringArrayOfDecimals;

            case dataType.arrayOfBooleans:
                return functionName.toStringArrayOfBooleans;

            case dataType.arrayOfDates:
                return functionName.toStringArrayOfDates;

            case dataType.arrayOfTimes:
                return functionName.toStringArrayOfTimes;

            case dataType.arrayOfDateTimes:
                return functionName.toStringArrayOfDateTimes;

            case dataType.arrayOfUnixTimes:
                return functionName.toStringArrayOfUnixTimes;

            default:
                return null;
        }
    }

    if (expectedType === dataType.integer && actualType === dataType.decimal) {

        return functionName.toInteger;
    }

    if (expectedType === dataType.decimal && actualType === dataType.integer) {

        return functionName.toDecimal;
    }

    return null;
};

const modify = (operand: Operand, functionName: string): void => {

    let copy = copyObject(operand);

    operandHelper.reset(operand);

    operand.operandType = operandType.function;
    operand.function = functionName;
    operand.arguments = functionHelper.getEmptyArguments(functionName, null);
    operand.arguments[0] = copy;
};


const typeConversionHelper = { inject };

export default typeConversionHelper;
