import FieldDefinition from '../../../../types/common/FieldDefinition';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import NumberMap from '../../../types/NumberMap';
import typeHelper from '../../common/typeHelper';

const seed = (source: IngestionConfig[], target: IngestionConfig[], fieldIds: NumberMap<number>, errors: string[]): void => {

    source.forEach(s => {

        let t = target.find(x => x.name === s.name) as IngestionConfig;

        if (!typeHelper.isObject(t)) {

            errors.push(`Data source ${s.name} was not found`);
        }

        addDataSource(s, t, fieldIds, errors);
    });
};

const addDataSource = (source: IngestionConfig, target: IngestionConfig, fieldIds: NumberMap<number>, errors: string[]): void => {

    if (source.emptyValueTokens !== target.emptyValueTokens) {

        errors.push(`Ingestion Config ${source.name} has different Empty Value Tokens in source and target environment`);
        return;
    }

    source.fieldDefinitions.forEach(s => {

        let t = target.fieldDefinitions.find(x => x.name === s.name) as FieldDefinition;

        if (!typeHelper.isObject(t)) {

            errors.push(`Field ${s.name} was not found in data source ${source.name}`);
            return;
        }

        if (s.type !== t.type) {

            errors.push(`Field ${s.name} (data source ${source.name}) has different data type in source and target environment`);
            return;
        }

        fieldIds[s.id as number] = t.id as number;
    });

};

const dataSourceFieldMapHelper = {
    seed
};

export default dataSourceFieldMapHelper;
