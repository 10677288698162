import typeHelper from '../../common/typeHelper';

const separator = '.';

interface Version {
    major: number;
    minor: number;
    granular: number | null;
}

const parse = (version: string): Version => {

    let parts = version.split(separator);

    return {
        major: parseInt(parts[0]),
        minor: parseInt(parts[1]),
        granular: parts.length === 3 ? parseInt(parts[2]) : null
    };
};

const stringify = (version: Version): string => {

    let parts = [
        version.major,
        version.minor
    ];

    if (typeHelper.isNumber(version.granular)) {

        parts.push(version.granular as number);
    }

    return parts.join(separator);
};

const incrementMajorVersion = (version: string): string => {

    let v = parse(version);

    v.major++;
    v.minor = 0;

    if (typeHelper.isNumber(v.granular)) {

        v.granular = 0;
    }

    return stringify(v);
};

const incrementMinorVersion = (version: string): string => {

    let v = parse(version);

    v.minor++;

    if (typeHelper.isNumber(v.granular)) {

        v.granular = 0;
    }

    return stringify(v);
};

const isReleasedVersion = (version: string): boolean => {

    let v = parse(version);

    return v.minor === 0 && (!typeHelper.isNumber(v.granular) || v.granular === 0);
};

const getDisplayName = (version: string): string => 'V' + version;

const versionHelper = {
    incrementMajorVersion,
    incrementMinorVersion,
    isReleasedVersion,
    getDisplayName
};

export default versionHelper;
