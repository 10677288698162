import ReportFieldInstructions from '../../../../types/report/ReportFieldInstructions';
import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';
import urlValidator from '../../../../infrastructure/helpers/common/validator/urlValidator';

const validate = (instructions: ReportFieldInstructions): string | null => {

    if (stringHelper.isNonEmpty(instructions.detailsToBeReportedUrl)) {

        let error = urlValidator.validate(instructions.detailsToBeReportedUrl, 'Details to be reported');
        if (error) {
            return error;
        }
    }

    if (stringHelper.isNonEmpty(instructions.formatToBeReportedUrl)) {

        let error = urlValidator.validate(instructions.formatToBeReportedUrl, 'Format to be reported');
        if (error) {
            return error;
        }
    }


    if (stringHelper.isNonEmpty(instructions.classOfSpecifiedDerivativesContractsUrl)) {

        let error = urlValidator.validate(instructions.classOfSpecifiedDerivativesContractsUrl, 'Class of specified derivatives contracts');
        if (error) {
            return error;
        }
    }


    if (stringHelper.isNonEmpty(instructions.explanatoryNotesUrl)) {

        let error = urlValidator.validate(instructions.explanatoryNotesUrl, 'Explanatory notes');
        if (error) {
            return error;
        }
    }

    if (stringHelper.isNonEmpty(instructions.validationsUrl)) {

        let error = urlValidator.validate(instructions.validationsUrl, 'Validations');
        if (error) {
            return error;
        }
    }

    if (stringHelper.isNonEmpty(instructions.cnPopulationGuidelinesUrl)) {

        let error = urlValidator.validate(instructions.cnPopulationGuidelinesUrl, 'CN Population Guidelines');
        if (error) {
            return error;
        }
    }

    return null;
};

export { validate };
