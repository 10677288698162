import Action from './Action';
import actions from './actions';

import SelectorsState from '../types/report/SelectorsState';
import SelectorsAppState from './state/SelectorsAppState';
import Index from '../types/report/Index';
import AnnaDsbSelector from '../types/report/AnnaDsbSelector';
import Selector from '../types/report/Selector';
import FirdsInstrumentSelector from '../types/report/FirdsInstrumentSelector';

const reducer = (state: SelectorsAppState | undefined, action: Action): SelectorsAppState => {

    if (!state) {
        state = {
            action: '',
            selectors: {
                isinSelectors: [],
                leiSelectors: [],
                exchangeRatesSelectors: [],
                annaDsbUpiEnrichmentSelectors: [],
                annaDsbUpiSelectors: [],
                lSESelectors: [],
                firdsFcaInstrumentSelectors: [],
                firdsEsmaInstrumentSelectors: [],
                fcaRegulatedEntitiesSelectors: [],
                selectorIndex: 0
            }
        };
    }

    switch (action.type) {
        case actions.selectors.open:
        case actions.selectors.save:
            state.selectors = action.payload as SelectorsState;
            break;
        case actions.selectors.annaDsbUpiEnrichmentChanged:
            state.selectors.annaDsbUpiEnrichmentSelectors = action.payload as AnnaDsbSelector[];
            break;
        case actions.selectors.annaDsbUpiChanged:
            state.selectors.annaDsbUpiSelectors = action.payload as Selector[];
            break;
        case actions.selectors.lseChanged:
            state.selectors.lSESelectors = action.payload as Selector[];
            break;
        case actions.selectors.isinChanged:
            state.selectors.isinSelectors = action.payload as Selector[];
            break;
        case actions.selectors.leiChanged:
            state.selectors.leiSelectors = action.payload as Selector[];
            break;
        case actions.selectors.fcaRegulatedEntitiesChange:
            state.selectors.fcaRegulatedEntitiesSelectors = action.payload as Selector[];
            break;
        case actions.selectors.exchangeRatesChanged:
            state.selectors.exchangeRatesSelectors = action.payload as Selector[];
            break;
        case actions.selectors.index:
            state.selectors.selectorIndex = (action.payload as Index).index;
            break;
        case actions.selectors.firdsFcaInstrumentChange:
            state.selectors.firdsFcaInstrumentSelectors = action.payload as FirdsInstrumentSelector[];
            break;
        case actions.selectors.firdsEsmaInstrumentChange:
            state.selectors.firdsEsmaInstrumentSelectors = action.payload as FirdsInstrumentSelector[];
            break;
        default: break;
    }

    return state;
};

export default reducer;
