import bespokeValidator from './bespokeValidator';
import clear from '../../common/array/clear';
import conditionValidator from './conditionValidator';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import typeHelper from '../../common/typeHelper';
import uniquenessValidator from './uniquenessValidator';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const maxNameCharacters = 20;

const validateLookup = (lookup: Lookup, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): void => {

    clear(lookup.errors);

    if (typeHelper.isString(lookup.name) && lookup.name.length > maxNameCharacters) {

        lookup.errors.push(`Lookup name max character count is ${maxNameCharacters}`);
    }

    if (!typeHelper.isNumber(lookup.dataSource2Id)) {

        lookup.errors.push('Lookup does not reference any ref data source');
    }

    if (!lookup.isBespoke) {

        Array.prototype.push.apply(lookup.errors, conditionValidator.validate(lookup.condition, functions, lookups, dictionaries, [], dataSources, reportFields, customDataSourceFields, true, null));
        return;
    }

    let error = bespokeValidator.validateCondition(lookup.bespokeCondition);
    if (error) {
        lookup.errors.push(error);
    }
};

const validate = (lookups: Lookup[], functions: UserDefinedFunction[], dictionaries: Dictionary[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): string | null => {

    lookups.forEach(l => validateLookup(l, functions, lookups, dictionaries, dataSources, reportFields, customDataSourceFields));

    uniquenessValidator.validate(lookups, l => l.name, 'Lookup', 'name');

    let valid = lookups.every(x => x.errors.length === 0);

    return valid ? null : 'Invalid lookups(s)';
};

const lookupValidator = {
    validate
};

export default lookupValidator;
