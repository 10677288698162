const reportRegime: { [key: string]: any } = {
    MiFIR: 'Mifir',
    EMIR: 'Emir',
    ASIC: 'Asic',
    REFIT: 'Refit',
    MAS: 'Mas',
    IIROC: 'Iiroc',
    'ASIC Rewrite': 'AsicRewrite',
    'MAS Rewrite': 'MasRewrite',
};

export default reportRegime;
