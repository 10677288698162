import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Dictionary from '../../../../types/report/Dictionary';
import clear from '../../common/array/clear';
import stringHelper from '../../common/stringHelper';

const add = (dictionaries: Dictionary[], dictionary: Dictionary): void => {

    let lastNumber = dictionaries.length > 0 ? dictionaries[dictionaries.length - 1].number : 0;

    dictionary.number = lastNumber + 1;
    dictionary.name = `dictionary-${dictionary.number}`;

    dictionaries.push(dictionary);
};

const get = (dictionaries: Dictionary[], location: ReportLogicLocation): Dictionary => {

    return getByNumber(dictionaries, location.statementNumber);
};

const getDefault = (dataSource: IngestionConfig): Dictionary => {

    let result = {} as Dictionary;

    result.id = null;
    result.name = '';
    result.number = 0;
    result.businessDescription = '';
    result.fields = [];
    result.errors = [];

    reset(result, dataSource);

    return result;
};

const reset = (dictionary: Dictionary, dataSource: IngestionConfig): void => {

    dictionary.dataSourceId = dataSource.id as number;

    clear(dictionary.fields);
};

const getName = (dictionary: Dictionary, dataSource: string): string => {

    return `D${dictionary.number}-${stringHelper.isNonEmpty(dictionary.name) ? dictionary.name : dataSource}`;
}

const getByNumber = (statements: Dictionary[], number: number | null): Dictionary => {

    return statements.find(x => x.number === number) as Dictionary;
}

const aggregationHelper = {
    add,
    get,
    getDefault,
    reset,
    getName,
    getByNumber
};

export default aggregationHelper;
