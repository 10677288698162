import React from 'react';
import { Unsubscribe } from 'redux';
import copyObject from '../../../../infrastructure/helpers/common/copyObject';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import outsideContextHelper from '../../../../infrastructure/helpers/functions/common/outsideContextHelper';
import conditionHelper from '../../../../infrastructure/helpers/functions/condition/conditionHelper';
import conditionModifyHelper from '../../../../infrastructure/helpers/functions/condition/conditionModifyHelper';
import customDataSourceHelper from '../../../../infrastructure/helpers/functions/customDataSource/customDataSourceHelper';
import functionHelper from '../../../../infrastructure/helpers/functions/function/functionHelper';
import lookupHelper from '../../../../infrastructure/helpers/functions/lookup/lookupHelper';
import operand2ExpectedTypeHelper from '../../../../infrastructure/helpers/functions/operand/operand2ExpectedTypeHelper';
import operandHelper from '../../../../infrastructure/helpers/functions/operand/operandHelper';
import operandRuleHelper from '../../../../infrastructure/helpers/functions/operand/operandRuleHelper';
import overrideOperandHelper from '../../../../infrastructure/helpers/functions/operand/overrideOperandHelper/overrideOperandHelper';
import CustomDataSourceLegitimacy from '../../../../infrastructure/types/Functions/CustomDataSourceLegitimacy';
import actions from '../../../../store/actions';
import store from '../../../../store/store';
import Condition from '../../../../types/functions/Condition/Condition';
import locationType from '../../../../types/functions/Location/LocationType';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import Operand from '../../../../types/functions/Operand/Operand';
import OperandModalState from '../../../Functions/Operand/Modal/OperandModalState';
import validateOperand from '../../../Functions/Operand/OperandBuilder.Validation';
import AggregationOperandSetBlockBuilderProps from './AggregationOperandSetBlockBuilderProps';
import AggregationOperandSetBuilderProps from './AggregationOperandSetBuilderProps';
import Aggregation from '../../../../types/report/Aggregation';
import autoCompleteHelper from '../../../../infrastructure/helpers/functions/condition/autoCompleteHelper';
import arrayItemHelper from '../../../../infrastructure/helpers/functions/common/arrayItemHelper';
import getArgumentDefinition from '../../../../infrastructure/helpers/functions/common/argumentHelper';

class OperandSetBlockBuilder<T> extends React.Component<AggregationOperandSetBlockBuilderProps<T>> {
    private unsubscribe: Unsubscribe | undefined;
    private unsubscribeCondition: Unsubscribe | undefined;

    constructor(props: AggregationOperandSetBlockBuilderProps<T>) {
        super(props);

        this.getByNumber = this.getByNumber.bind(this);
        this.onOperandClick = this.onOperandClick.bind(this);
        this.onOperandSave = this.onOperandSave.bind(this);
        this.updateOperand = this.updateOperand.bind(this);
        this.updateCondition = this.updateCondition.bind(this);
        this.onLogicalOperatorChange = this.onLogicalOperatorChange.bind(this);
        this.onParenthesesChange = this.onParenthesesChange.bind(this);
        this.onAddConditionClick = this.onAddConditionClick.bind(this);
        this.onRemoveConditionClick = this.onRemoveConditionClick.bind(this);
        this.onComparisonTypeChange = this.onComparisonTypeChange.bind(this);
        this.onConditionOperandClick = this.onConditionOperandClick.bind(this);
        this.onConditionOperandSave = this.onConditionOperandSave.bind(this);
        this.onPastePieceOfCodeClick = this.onPastePieceOfCodeClick.bind(this);
        this.allowPaste = this.allowPaste.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onOperandSave);
        this.unsubscribeCondition = store.subscribe(this.onConditionOperandSave);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
        (this.unsubscribeCondition as Unsubscribe)();
    }

    getByNumber(number: number | null): Aggregation {

        return this.props.reportConfig.aggregations.find(x => x.number === number) as Aggregation;
    }

    onOperandClick(location: ReportLogicLocation): void {

        let set = this.props.helper.getSet(this.props.reportConfig, location);

        let copy = copyObject(location);

        let i = copy.argumentIndexes.shift() as number;

        let operand = operandHelper.getOperand(set[i], copy);

        let argumentDefinition = getArgumentDefinition(null, set[i], location, this.props.reportConfig.dictionaries, this.props.reportConfig.userDefinedFunctions);

        let legitimacy: CustomDataSourceLegitimacy = { isin: false, lei: false, exchangeRates: false, annaDsbUpiEnrichment: false, annaDsbUpi: false, lse: false, firdsFcaInstrument: false, firdsEsmaInstrument: false, fcaRegulatedEntities: false };

        let operandRules = operandRuleHelper.get(argumentDefinition, this.props.details.expectedType, this.props.details.statement, legitimacy);

        let callStack = operandHelper.getOperandCallStack(set[i], location.argumentIndexes.slice(1));
        let arrayItemLegitimacy = arrayItemHelper.getLegitimacy(callStack, operand);

        let state: OperandModalState = {
            title: this.props.details.name,
            isOpen: true,
            operand: operandHelper.prepareForEdit(operand, operandRules, outsideContextHelper.getDefaults()),
            location: location,
            dataSource1Id: this.props.helper.getDataSourceId(this.props.reportConfig, location),
            dataSource2Id: null,
            customDataSourceLegitimacy: legitimacy,
            operandRules: operandRules,
            specialScenario: { doNotPopulate: false, pseudoFunction: null },
            customDataSourceContext: null,
            error: null,
            arrayItemLegitimacy: arrayItemLegitimacy,
            parameters: null
        };

        this.dispatchStateChange(state);
    }

    onOperandSave(): void {

        let appState = store.getState();
        if (appState.action !== actions.operandModal.save) {
            return;
        }

        let state = appState.operandModalSave;
        if (state.location.statement !== this.props.details.statement) {
            return;
        }

        if (state.location.pieceOfCode !== locationType.pieceOfCode.operandsList) {
            return;
        }

        if (state.location.argumentIndexes.length === 0) {
            return;
        }

        let error = validateOperand(state.operand, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, null as any, state.operandRules);

        let changes = { error: error } as OperandModalState;

        if (error) {
            this.dispatchStateChange(changes);
            return;
        }

        this.updateOperand(state.operand, state.location);

        changes.isOpen = false;

        this.dispatchStateChange(changes);
    }

    updateOperand(operand: Operand, location: ReportLogicLocation): void {

        let set = this.props.helper.getSet(this.props.reportConfig, location);

        let i = location.argumentIndexes.shift() as number;

        let target = operandHelper.getOperand(set[i], location);

        overrideOperandHelper.override(target, operand, this.props.collections.dataSources, this.props.collections.reportFields, null as any, []);

        this.props.onChange(this.props.helper.resolveTarget(this.props.reportConfig));
    }

    dispatchStateChange(changes: OperandModalState): void {

        store.dispatch({ type: actions.operandModal.change, payload: changes });
    }

    updateCondition(_location: ReportLogicLocation, update: (condition: Condition) => Condition): void {

        let aggregation = this.getByNumber(this.props.selectedSetNumber);

        aggregation.condition = update(aggregation.condition);

        this.props.onChange(this.props.helper.resolveTarget(this.props.reportConfig));
    }

    onLogicalOperatorChange(location: ReportLogicLocation, operator: string): void {

        const update = (condition: Condition): Condition => {

            let target = conditionHelper.getByNumber(condition, location.conditionNumber);

            conditionModifyHelper.changeLogicalOperator(target, operator);

            return condition;
        };

        this.updateCondition(location, update);
    }

    onParenthesesChange(location: ReportLogicLocation, count: number, type: string): void {

        const update = (condition: Condition): Condition => {

            let target = conditionHelper.getByNumber(condition, location.conditionNumber);

            conditionModifyHelper.changeParenthesesCount(target, count, type);

            return condition;
        };

        this.updateCondition(location, update);
    }

    onAddConditionClick(location: ReportLogicLocation, operator: string | null): void {

        const update = (condition: Condition): Condition => {

            let added = conditionHelper.add(condition, operator);

            return added;
        };

        this.updateCondition(location, update);
    }

    onRemoveConditionClick(location: ReportLogicLocation): void {

        const update = (condition: Condition): Condition => conditionHelper.remove(condition, location.conditionNumber);

        this.updateCondition(location, update);
    }

    onComparisonTypeChange(location: ReportLogicLocation, value: string): void {

        const update = (condition: Condition): Condition => {

            let target = conditionHelper.getByNumber(condition, location.conditionNumber);

            Object.assign(target, { comparisonType: value });

            return condition;
        };

        this.updateCondition(location, update);
    }

    onConditionOperandClick(location: ReportLogicLocation): void {

        let aggregation = this.getByNumber(this.props.selectedSetNumber);

        let dataSourceId = this.props.helper.getDataSourceId(this.props.reportConfig, location);

        let operand = conditionHelper.getOperand(aggregation.condition, location);

        let lookupDataSourceId = location.statement === locationType.statement.lookup ? lookupHelper.getByNumber(this.props.reportConfig.lookups, location.statementNumber).dataSource2Id : null;

        let argumentDefinition = getArgumentDefinition(aggregation.condition, null, location, this.props.reportConfig.dictionaries, this.props.reportConfig.userDefinedFunctions);

        let expectedType = operand2ExpectedTypeHelper.resolve(aggregation.condition, location, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields);

        let legitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, dataSourceId);

        let operandRules = operandRuleHelper.get(argumentDefinition, expectedType, this.props.details.statement, legitimacy);

        let outsideContextDetails = outsideContextHelper.get(aggregation.condition, null, location, this.props.reportConfig.lookups, this.props.details.statement);

        let conditionOperand = conditionHelper.getExactConditionOperand(aggregation.condition, location);
        let callStack = operandHelper.getOperandCallStack(conditionOperand, location.argumentIndexes);
        let arrayItemLegitimacy = arrayItemHelper.getLegitimacy(callStack, operand);

        let state: OperandModalState = {
            title: `Operand ${location.operandNumber}`,
            isOpen: true,
            operand: operandHelper.prepareForEdit(operand, operandRules, outsideContextDetails),
            location: location,
            dataSource1Id: outsideContextDetails.isOutsideContext ? outsideContextDetails.dataSourceId : dataSourceId,
            dataSource2Id: lookupDataSourceId,
            customDataSourceLegitimacy: legitimacy,
            operandRules: operandRules,
            specialScenario: { doNotPopulate: false, pseudoFunction: null },
            customDataSourceContext: outsideContextDetails.customDataSource,
            error: null,
            arrayItemLegitimacy: arrayItemLegitimacy,
            parameters: null
        };

        this.dispatchStateChange(state);
    }

    onConditionOperandSave(): void {

        let appState = store.getState();
        if (appState.action !== actions.operandModal.save) {
            return;
        }

        let state = appState.operandModalSave;

        if (state.location.statement !== this.props.details.statement) {
            return;
        }

        if (state.location.pieceOfCode !== locationType.pieceOfCode.conditionOperand) {
            return;
        }

        let error = validateOperand(state.operand, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields, state.operandRules);

        let changes = { error: error } as OperandModalState;

        if (error) {
            this.dispatchStateChange(changes);
            return;
        }

        this.updateCondition(state.location, condition => this.updateConditionOperand(state, condition));

        changes.isOpen = false;

        this.dispatchStateChange(changes);
    }

    updateConditionOperand(state: OperandModalState, condition: Condition): Condition {

        let operand = conditionHelper.getOperand(condition, state.location);

        overrideOperandHelper.override(operand, state.operand, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields, this.props.reportConfig.variables);

        autoCompleteHelper.execute(condition);

        return condition;
    }

    onPastePieceOfCodeClick(location: ReportLogicLocation): void {

        if (!typeHelper.isObject(this.props.clipboard)) {
            return;
        }

        const update = (_condition: Condition): Condition => {

            let number = (this.props.clipboard as ReportLogicLocation).statementNumber;
            let aggregation = this.getByNumber(number);

            return copyObject(aggregation.condition);
        };

        this.updateCondition(location, update);
    }

    allowPaste(location: ReportLogicLocation): boolean {

        if (!typeHelper.isObject(this.props.clipboard)) {

            return false;
        }

        let proceed =
            (this.props.clipboard as ReportLogicLocation).statement === location.statement &&
            (this.props.clipboard as ReportLogicLocation).pieceOfCode === location.pieceOfCode;

        if (!proceed) {
            return false;
        }

        if ((this.props.clipboard as ReportLogicLocation).statementNumber === location.statementNumber) {

            return false;
        }

        let dataSourceCurrent = this.props.helper.getDataSourceId(this.props.reportConfig, location);
        let dataSourceClipboard = this.props.helper.getDataSourceId(this.props.reportConfig, (this.props.clipboard as ReportLogicLocation));

        return dataSourceCurrent === dataSourceClipboard;
    }

    render(): JSX.Element {

        let props: AggregationOperandSetBuilderProps = {
            isWaiting: this.props.isWaiting,
            isReadOnly: this.props.isReadOnly,
            selectedSetNumber: this.props.selectedSetNumber,
            reportConfig: this.props.reportConfig,
            collections: this.props.collections,
            onRemoveClick: this.props.onRemoveClick,
            onOperandClick: this.onOperandClick,
            onChange: this.props.onItemChange,
            configurationData: this.props.configurationData,

            onLogicalOperatorChange: this.onLogicalOperatorChange,
            onParenthesesChange: this.onParenthesesChange,
            onAddConditionClick: this.onAddConditionClick,
            onRemoveConditionClick: this.onRemoveConditionClick,
            onComparisonTypeChange: this.onComparisonTypeChange,
            onConditionOperandClick: this.onConditionOperandClick,
            onCopyClick: this.props.onClipboardChange,
            onPasteClick: this.onPastePieceOfCodeClick,
            allowPaste: this.allowPaste
        };

        return (
            <>
                {React.createElement(this.props.childComponentType, props)}
            </>
        );
    }
}

export default OperandSetBlockBuilder;
