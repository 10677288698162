import validateMasterFileLocation from './Details/IngestionDetails.Validation';
import actions from '../../store/actions';
import appRoutes from '../../infrastructure/constants/appRoutes';
import history from '../../infrastructure/helpers/react/history';
import IngestionConfig from '../../types/ingestion/IngestionConfig';
import React from 'react';
import StepDescription from '../Common/StepIndicator/Step/StepDescription';
import StepIndicator from '../Common/StepIndicator/StepIndicator';
import store from '../../store/store';
import typeHelper from '../../infrastructure/helpers/common/typeHelper';
import validate from './FieldDefinitions/FieldDefinitions.Validation';
import validateEmptyValueTokens from './Details/IngestionDetails.Validation.EmptyValueTokens';
import validateFilenames from './IngestionFilenames/IngestionFilenames.Validation';
import validateConfigName from '../../infrastructure/helpers/common/validator/nameValidator';

interface IngestionStepIndicatorProps {
    currentStepNumber: number;
    config: IngestionConfig;
}

class IngestionStepIndicator extends React.Component<IngestionStepIndicatorProps> {
    private steps: StepDescription[];

    constructor(props: IngestionStepIndicatorProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.allowUploadClick = this.allowUploadClick.bind(this);
        this.allowFieldDefinitionClick = this.allowFieldDefinitionClick.bind(this);
        this.allowSignoffClick = this.allowSignoffClick.bind(this);

        this.steps = [
            {
                title: 'Upload',
                onClick: () => this.onClick(appRoutes.ingestion.details),
                clickable: () => this.allowUploadClick(1)
            },
            {
                title: 'Field Definition',
                onClick: () => this.onClick(appRoutes.ingestion.fieldDefinition),
                clickable: () => this.allowFieldDefinitionClick(2)
            },
            {
                title: 'Sign Off',
                onClick: () => this.onClick(appRoutes.ingestion.signoff),
                clickable: () => this.allowSignoffClick(3)
            }
        ];

        this.onClick = this.onClick.bind(this);
    }

    onClick(route: string) {

        store.dispatch({ type: actions.navigation.execute, payload: () => history.push(route, this.props.config) });
    }

    allowUploadClick(stepNumber: number): boolean {

        return stepNumber !== this.props.currentStepNumber;
    }

    allowFieldDefinitionClick(stepNumber: number): boolean {

        if (stepNumber === this.props.currentStepNumber) {
            return false;
        }

        let error =
            validateConfigName(this.props.config.name, false) ||
            validateFilenames(this.props.config.filenames) ||
            validateMasterFileLocation(this.props.config) ||
            validateEmptyValueTokens(this.props.config.emptyValueTokens);

        return !typeHelper.isString(error);
    }

    allowSignoffClick(stepNumber: number): boolean {

        if (stepNumber === this.props.currentStepNumber) {
            return false;
        }

        let error =
            validate(this.props.config.fieldDefinitions, this.props.config.isTransposed) ||
            validateConfigName(this.props.config.name, false) ||
            validateFilenames(this.props.config.filenames) ||
            validateMasterFileLocation(this.props.config) ||
            validateEmptyValueTokens(this.props.config.emptyValueTokens);

        return !typeHelper.isString(error);
    }

    render() {
        return (
            <div className="mb-4">
                <StepIndicator
                    steps={this.steps}
                    currentStepNumber={this.props.currentStepNumber}
                    stepWidth={100}
                    fontSize={15} />
            </div>
        );
    }
}

export default IngestionStepIndicator;
