import { uniqueName, getColumns } from './IngestionHomePage.Grid';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import GridRefresh from '../../../store/state/events/GridRefresh';
import history from '../../../infrastructure/helpers/react/history';
import identityStorage from '../../../infrastructure/authorization/identityStorage';
import React from 'react';
import ServerDataGrid from '../../Common/DataGrid/ServerDataGrid/ServerDataGrid';
import store from '../../../store/store';
import writeUserRoles from '../../../infrastructure/constants/identity/writeUserRoles';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';
import IngestionConfigGridItem from '../../../types/ingestion/IngestionConfigGridItem';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import { AxiosResponse } from 'axios';
import CopyIngestionConfigModal from '../../Common/Modals/CopyIngestionConfigModal/CopyIngestionConfigModal';
import CopyIngestionConfigModalState from '../../Common/Modals/CopyIngestionConfigModal/CopyIngestionConfigModalState';
import { Unsubscribe } from 'redux';

interface State {
    enabled: boolean;
    name: string;
    showWarningModal: boolean;
    message: string;
    config: IngestionConfigGridItem
}

class IngestionHomePage extends React.Component<any, State> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = { enabled: true, name: '', showWarningModal: false, message: '', config: {} as IngestionConfigGridItem };

        this.onEnabledChange = this.onEnabledChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onRemoveConfirmed = this.onRemoveConfirmed.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.refreshGridListener = this.refreshGridListener.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.refreshGridListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    refreshGridListener(): void {

        let state = store.getState();

        let proceed = state.action === actions.copyIngestionConfigModal.saveSuccess;

        if (!proceed) {
            return;
        }

        this.refreshGrid();
    }

    onCreateNewClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        history.push(appRoutes.ingestion.upload);
    }

    onEnabledChange(e: React.ChangeEvent<HTMLInputElement>): void {

        this.setState({ enabled: !e.target.checked }, this.refreshGrid);
    }

    onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {

        this.setState({ name: e.target.value });
    }

    onSearchClick(): void {
        this.refreshGrid();
    }

    onSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        this.refreshGrid();
    }

    refreshGrid(): void {

        let payload: GridRefresh = {
            uniqueName: uniqueName,
            useCurrentPage: false
        };

        store.dispatch({ type: actions.grid.refresh, payload: payload });
    }

    onRemoveClick(config: IngestionConfigGridItem): void {

        let message = `Are you sure you want to delete config ${config.name}?`;

        this.setState({ message, showWarningModal: true, config });
    };

    onRemoveConfirmed(): Promise<void> {

        this.setState({ showWarningModal: false });

        let route = urlHelper.buildRoute(['ingestion', this.state.config.id]);

        return httpClient.delete(route).then(this.onSuccess);
    }

    onSuccess(response: AxiosResponse): void {

        let payload: GridRefresh = {
            uniqueName: uniqueName,
            useCurrentPage: true
        };

        store.dispatch({ type: actions.grid.refresh, payload: payload });
    };

    onCopyClick(config: IngestionConfigGridItem) {

        let state = {} as CopyIngestionConfigModalState;

        state.id = config.id as number;
        state.name = `${config.name} - Copy`;
        state.isOpen = true
        store.dispatch({ type: actions.copyIngestionConfigModal.open, payload: state });
    }

    render(): JSX.Element {

        let identity = identityStorage.get();

        return (
            <div className="ingestion-home">
                <h3>Ingestion Configs {this.state.enabled ? '' : '(archived)'}</h3>

                {
                    writeUserRoles.includes(identity.userRole) &&
                    <div className="mt-4">
                        <button type="button" className="btn cb-btn" onClick={this.onCreateNewClick}>Create New Ingestion Logic</button>
                    </div>
                }

                <div className="row no-gutters mt-4 mb-2">
                    <div className="col-auto">
                        <form onSubmit={this.onSubmit}>
                            <input
                                type="text"
                                value={this.state.name}
                                onChange={this.onNameChange}
                                className="form-control"
                                placeholder="Search By Name or Id" />
                        </form>
                    </div>
                    <div className="col-auto ml-1">
                        <button className="btn btn-light" onClick={this.onSearchClick}>Search</button>
                    </div>
                    <div className="col ml-2">
                        <label>
                            <input
                                type="checkbox"
                                checked={!this.state.enabled}
                                onChange={this.onEnabledChange} />
                            <span>Archived</span>
                        </label>
                    </div>
                </div>

                <div className="mb-4">
                    <ServerDataGrid
                        columns={getColumns(this.state.enabled, this.onCopyClick, this.onRemoveClick)}
                        uniqueName={uniqueName}
                        dataSourceRoute="ingestion/page"
                        pageSize={10}
                        getKey={x => x.id}
                        getAdditionalParams={() => this.state} />
                </div>
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onRemoveConfirmed}
                        onCancelClick={() => { this.setState({ showWarningModal: false }); }}
                        title='Remove item'
                        message={this.state.message} />
                }
                <CopyIngestionConfigModal />
            </div>
        );
    }
}

export default IngestionHomePage;
