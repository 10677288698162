import ArgumentDefinition from '../../../../../types/functions/ArgumentDefinition';
import FunctionDefinition from '../../../../../types/functions/FunctionDefinition';
import functionDisplayHelper from '../../../../../infrastructure/helpers/functions/function/functionDisplayHelper';

const addArgumentType = (parts: string[], type: string, addPipe: boolean): void => {

    parts.push(type);

    if (addPipe) {
        parts.push(' | ');
    }
};

const addArgument = (parts: string[], argument: ArgumentDefinition, addComma: boolean): void => {

    parts.push(argument.name);
    parts.push(': ');

    argument.allowedTypes.forEach((x, i, arr) => addArgumentType(parts, x, i !== arr.length - 1));

    if (addComma) {
        parts.push(', ');
    }
};

const getSignature = (definition: FunctionDefinition): string => {

    let parts: string[] = [];

    if (definition.operator) {
        parts.push('(');
        addArgument(parts, definition.arguments[0], false);
        parts.push(' ');
        parts.push(definition.operator);
        parts.push(' ');
        addArgument(parts, definition.arguments[1], false);
        parts.push(')');
    }
    else {
        if (definition.isMethod) {
            parts.push(definition.arguments[0].name);
            parts.push('.');
        }

        parts.push(functionDisplayHelper.getDisplayName(definition.name));

        parts.push('(');

        definition.arguments
            .slice(definition.isMethod ? 1 : 0)
            .concat(definition.arguments.filter(x => x.isArray))
            .forEach((x, i, arr) => addArgument(parts, x, i !== arr.length - 1));

        parts.push(')');
    }

    return parts.join('');
};

export {
    getSignature
};