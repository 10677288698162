import dataSourceHelper from '../common/dataSourceHelper';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../operand/operandHelper';
import operandType from '../../../constants/functions/operandType';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import typeHelper from '../../common/typeHelper';
import repeat from '../../common/array/repeat';
import dictionaryHelper from '../dictionaries/dictionaryHelper';
import Dictionary from '../../../../types/report/Dictionary';
import Lookup from '../../../../types/functions/Lookup';
import { lookup } from 'dns';

const separator = '-';

const prefix = {
    lookup: 'l',
    dataSource: 'ds',
    report: 'r',
    variable: 'v',
    dictionary: 'di'
};

const buildDictionaryIdentifier = (dictionaryNumber: number, dataSourceId: number): string => {

    return [prefix.dictionary, dictionaryNumber, dataSourceId].join(separator);
};

const buildLookupOptionIdentifier = (lookupName: string, lookupNumber: number): string => {

    return [prefix.lookup, lookupNumber, lookupName].join(separator);
};

const buildDirectDataSourceIdentifier = (dataSourceId: number): string => {

    return [prefix.dataSource, dataSourceId].join(separator);
};

const buildReportIdentifier = (): string => {

    return prefix.report;
};

const buildVariableIdentifier = (): string => {

    return prefix.variable;
};

const buildLookupIdentifier = (): string => {

    return prefix.lookup;
};

const buildIdentifier = (fieldId: number | null, dictionaryNumber: number | null, lookupNumber: number | null, variableNumber: number | null, reportFieldId: number | null, dataSources: IngestionConfig[]): string => {

    if (lookupNumber) {

        return buildLookupIdentifier();
    }

    if (typeHelper.isNumber(dictionaryNumber) && typeHelper.isNumber(fieldId)) {

        let dataSource = dataSourceHelper.getByFieldId(dataSources, fieldId as number);

        return buildDictionaryIdentifier(dictionaryNumber as number, dataSource.id as number);
    }

    if (typeHelper.isNumber(fieldId)) {

        let dataSource = dataSourceHelper.getByFieldId(dataSources, fieldId as number);

        return buildDirectDataSourceIdentifier(dataSource.id as number);
    }

    if (typeHelper.isNumber(variableNumber)) {

        return buildVariableIdentifier();
    }

    if (typeHelper.isNumber(reportFieldId)) {

        return buildReportIdentifier();
    }

    return '';
};

const setupLookupFieldOperand = (operand: Operand, lookups: Lookup[],  dataSources: IngestionConfig[]): void => {

    operand.operandType = operandType.lookupField;
    operand.lookupNumber = lookups[0].number;
    operand.fieldId = dataSourceHelper.getById(dataSources, lookups[0].dataSource2Id || 0).fieldDefinitions[0].id;
};

const setupFieldOperand = (operand: Operand, identifier: string, dataSources: IngestionConfig[]): void => {

    let parts = identifier.split(separator);

    let dataSourceId = parseInt(parts[1]);

    operand.operandType = operandType.field;
    operand.fieldId = dataSourceHelper.getById(dataSources, dataSourceId).fieldDefinitions[0].id;
};

const setupReportFieldOperand = (operand: Operand, reportFields: ReportFieldDefinition[]): void => {

    operand.operandType = operandType.reportField;
    operand.reportFieldId = reportFields[0].id;
};

const setupVariableOperand = (operand: Operand, variables: ReportVariable[]): void => {

    operand.operandType = operandType.variable;
    operand.variableNumber = variables[0].number;
};

const setupDictionaryOperand = (operand: Operand, identifier: string, dataSources: IngestionConfig[], dictionaries: Dictionary[]): void => {

    let parts = identifier.split(separator);

    let dictionaryNumber = parseInt(parts[1]);
    let dataSourceId = parseInt(parts[2]);
    let dictionary = dictionaryHelper.getByNumber(dictionaries, dictionaryNumber);

    operand.operandType = operandType.dictionary;
    operand.dictionaryNumber = dictionaryNumber;
    operand.fieldId = dataSourceHelper.getById(dataSources, dataSourceId).fieldDefinitions[0].id;
    operand.arguments = repeat(dictionary.fields.length, operandHelper.getEmpty);
};

const getOperand = (identifier: string, lookups: Lookup[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], dictionaries: Dictionary[]): Operand => {

    let result = operandHelper.getEmpty();

    if (identifier.startsWith(prefix.lookup)) {

        setupLookupFieldOperand(result, lookups, dataSources);
    }
    else if (identifier.startsWith(prefix.dictionary)) {

        setupDictionaryOperand(result, identifier, dataSources, dictionaries);
    }
    else if (identifier.startsWith(prefix.dataSource)) {

        setupFieldOperand(result, identifier, dataSources);
    }
    else if (identifier === prefix.variable) {

        setupVariableOperand(result, variables);
    }
    else if (identifier === prefix.report) {

        setupReportFieldOperand(result, reportFields);
    }
    else {
        throw new Error(`Invalid mixed operand identifier '${identifier}'`);
    }

    return result;
};

const mixedOperandHelper = {
    buildDictionaryIdentifier,
    buildLookupIdentifier,
    buildLookupOptionIdentifier,
    buildDirectDataSourceIdentifier,
    buildReportIdentifier,
    buildVariableIdentifier,
    buildIdentifier,
    getOperand
};

export default mixedOperandHelper;