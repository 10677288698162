import enpointLookupAdapter from '../../../infrastructure/helpers/functions/lookup/enpointLookupAdapter';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import SelectOption from '../../../infrastructure/types/SelectOption';
import { SelectSearchOption } from 'react-select-search';
import Lookup from '../../../types/functions/Lookup';
import dataSourceHelper from '../../../infrastructure/helpers/functions/common/dataSourceHelper';
import IngestionConfigOption from '../../../types/ingestion/IngestionConfigOption';
import pseudocodeHelper from '../../../infrastructure/helpers/functions/common/pseudocodeHelper';
import lookupHelper from '../../../infrastructure/helpers/functions/lookup/lookupHelper';

const createDataSourceOption = (dataSource: IngestionConfig): SelectOption => {
    return {
        text: dataSource.name,
        value: (dataSource.id as number).toString()
    };
};

const lookupTypeOtions: SelectOption[] = [

    { value: false.toString(), text: 'Regular' },
    { value: true.toString(), text: 'Multi Result' }
];

const endpointLookupOption: SelectOption = {
    text: 'Report',
    value: enpointLookupAdapter.specialValue
};

const prepareOptionsFromLookups = (lookups: Lookup[], datasources: IngestionConfigOption[]): SelectSearchOption[] => {

    let options: SelectSearchOption[] = [];
    lookups.forEach((lookup) => {
        let dataSource = dataSourceHelper.getById(datasources as IngestionConfig[], lookup.dataSource2Id as number);
        const name = dataSource?.name || '';
        var displayText = pseudocodeHelper.wrapInBrackets(lookupHelper.getName(lookup, name));
        options.push({
            name: displayText,
            value: displayText,
            items: [{ name: lookup.number.toString(), value: lookup.number.toString() }]
        });
    });

    return options;
}

export { createDataSourceOption, lookupTypeOtions, endpointLookupOption, prepareOptionsFromLookups };
