import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import SelectOption from '../../../infrastructure/types/SelectOption';

const createDataSourceOption = (dataSource: IngestionConfig): SelectOption => {
    return {
        text: dataSource.name,
        value: (dataSource.id as number).toString()
    };
};

export { createDataSourceOption };