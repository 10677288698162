import clear from '../../common/array/clear';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import declarationValidator from './declarationValidator';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import stringHelper from '../../common/stringHelper';
import uniquenessValidator from './uniquenessValidator';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const maxNameCharacters = 50;

const validateVariable = (variable: ReportVariable, functions: UserDefinedFunction[], variables: ReportVariable[], lookups: Lookup[], dictionaries: Dictionary[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): void => {

    clear(variable.errors);

    let metadataError = validateMetadata(variable);
    if (metadataError) {
        variable.errors.push(metadataError);
    }

    declarationValidator.validate(variable, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, variable.dataType, null, false);
};

const validateMetadata = (variable: ReportVariable): string | null => {

    if (stringHelper.isEmpty(variable.name)) {
        return 'Variable name is required';
    }

    if (variable.name.length > maxNameCharacters) {
        return `Variable name max character count is ${maxNameCharacters}`;
    }

    return null;
};

const validate = (variables: ReportVariable[], functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): string | null => {

    variables.forEach(x => validateVariable(x, functions, variables, lookups, dictionaries, dataSources, reportFields, customDataSourceFields));

    uniquenessValidator.validate(variables, v => v.name, 'Variable', 'name');

    let valid = variables.every(x => x.errors.length === 0);

    return valid ? null : 'Invalid variable(s)';
};

const variableValidator = {
    validate,
    validateVariable
};

export default variableValidator;
