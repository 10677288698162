import { mapToBasicOption } from '../../../infrastructure/helpers/html/selectOptionHelper';
import SelectOption from '../../../infrastructure/types/SelectOption';
import separator from '../../../infrastructure/constants/separator';
import textSeparator from '../../../infrastructure/constants/textSeparator';
import escapeCharacter from '../../../infrastructure/constants/escapeCharacter';

const getCsvLineNumberOptions = (number: number): SelectOption[] => {
    let options: SelectOption[] = [];

    for (let i = 0; i < number; i++) {
        let number = (i + 1).toString();
        options.push(mapToBasicOption(number));
    }

    return options;
};

const separatorOptions: SelectOption[] = [
    { text: 'Comma', value: separator.comma },
    { text: 'Pipe', value: separator.pipe },
    { text: 'Tab', value: separator.tab },
    { text: 'Semicolon', value: separator.semicolon }
];

const textSeparatorOptions: SelectOption[] = [
    { text: 'Double Quote', value: textSeparator.doubleQuote },
    { text: 'Single Quote', value: textSeparator.singleQuote },
    { text: 'Pipe', value: textSeparator.pipe }
];

const escapeCharacterOptions: SelectOption[] = [
    { text: 'Double Quote', value: escapeCharacter.doubleQuote },
    { text: 'Backslash', value: escapeCharacter.backslash }
];

const csvHeaderLineNumberOptions = getCsvLineNumberOptions(15);
const csvDataLineNumberOptions = getCsvLineNumberOptions(16);

const headerLinePresentId = 'header-line-present';

export {
    separatorOptions,
    textSeparatorOptions,
    csvHeaderLineNumberOptions,
    csvDataLineNumberOptions,
    headerLinePresentId,
    escapeCharacterOptions
};
