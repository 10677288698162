import React from "react";
import { ReactComponent as RemoveIcon } from '../../../Common/BootstrapIcons/trash.svg';
import FieldDefinition from "../../../../types/common/FieldDefinition";
import { findNotUniqueIndex } from "../FieldDefinitions.Helpers";

interface Props {
    items: string[];
    onChange: (items: string[]) => void;
    maxItemsCount?: number;
    fieldDefinitions: FieldDefinition[];
    currentDefinitionIndex: number;
}

class AlternativeNames extends React.Component<Props, { errors: Map<number, string> }> {

    constructor(props: Props) {
        super(props);

        this.state = {
            errors: new Map()
        }

        this.onAddClick = this.onAddClick.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onRemoveClick(i: number) {

        let items = this.props.items.slice();

        items.splice(i, 1);

        this.props.onChange(items);
    }

    onAddClick() {

        if (this.props.maxItemsCount === this.props.items.length) {

            let newMap = new Map(this.state.errors);

            newMap.set(this.props.items.length - 1, `Max ${this.props.maxItemsCount} fields allowed.`);

            this.setState({ errors: newMap });
        }
        else {

            let items = this.props.items.slice();

            items.push('');

            this.props.onChange(items);
        }
    }

    onChange(value: string, index: number) {

        let tempFieldDefinitions = this.props.fieldDefinitions.slice();

        let currentDefinitionName = this.props.fieldDefinitions[this.props.currentDefinitionIndex].name;

        let definition = tempFieldDefinitions?.find(x => x.name === currentDefinitionName);

        if (definition && definition.alternativeNames) {
            definition.alternativeNames[index] = value;
        }

        let errorMap = this.validateAlternativeNamesUniqueness(value, tempFieldDefinitions, definition?.alternativeNames || [], index);

        this.setState({ errors: errorMap });

        if (!errorMap.size) {

            let items = this.props.items.slice();

            items[index] = value;

            this.props.onChange(items);
        }
    }


    validateAlternativeNamesUniqueness(name: string, fieldDefinitions: FieldDefinition[], items: string[], index: number): Map<number, string> {

        let errorMap = new Map<number, string>();

        for (var i = 0; i < items.length; i++) {

            let notUniqueNameIndex = findNotUniqueIndex(items[i], fieldDefinitions, i);

            if (notUniqueNameIndex >= 0) {
                errorMap.set(notUniqueNameIndex, `Alternative name "${items[notUniqueNameIndex]}" already exists.`);
            }
        }

        return errorMap;
    }

    render(): JSX.Element {
        return (
            <>
                <ul className='padding-0 margin-0'>
                    {this.props.items.map((item, index) => {
                        return <li key={index + item} className="row no-gutters cursor-pointer padding-bottom-8px">
                            <div className="display-flex height-25px width-100-percents">
                                <button className="transparent-button" type="button" onClick={() => this.onRemoveClick(index)}>
                                    <RemoveIcon />
                                </button>
                                <input type='text' className="color-black-important form-control height-100-percents-important" onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { this.onChange(e.target.value, index) }} defaultValue={item} key={item} />
                            </div>
                            <p className='error margin-0 padding-left-10px'>{this.state.errors.get(index)}</p>
                        </li>
                    })
                    }
                </ul>
                <div>
                    <button className='btn btn-light height-30px line-height-1' onClick={this.onAddClick}>Add</button>
                </div>
            </>
        );
    }
}

export default AlternativeNames;
