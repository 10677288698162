import CustomDataSourceLegitimacy from '../../../types/Functions/CustomDataSourceLegitimacy';
import locationType from '../../../../types/functions/Location/LocationType';
import operandType from '../../../constants/functions/operandType';
import typeHelper from '../../common/typeHelper';

const statements = [
    locationType.statement.lookup,
    locationType.statement.variable,
    locationType.statement.filter,
    locationType.statement.case,
    locationType.statement.reportField,
    locationType.statement.validation,
    locationType.statement.isinSelector,
    locationType.statement.leiSelector,
    locationType.statement.exchangeRatesSelector,
    locationType.statement.annaDsbUpiEnrichmentSelector
];

const qualifies = (specialOperandType: string, statement: string, legitimacy: CustomDataSourceLegitimacy): boolean => {

    switch (specialOperandType) {

        case operandType.group:
            return statement === locationType.statement.aggregatedRecordField;

        case operandType.lookupResultSet:
            return statements.includes(statement);

        case operandType.firdsResultSetEsma:
        case operandType.firdsResultSetFca:
            return legitimacy.isin;

        case operandType.leiResultSetLevel1:
            return legitimacy.lei;

        case operandType.exchangeRatesResultSet:
            return legitimacy.exchangeRates;

        case operandType.annaDsbUpiEnrichmentResultSet:
            return legitimacy.annaDsbUpiEnrichment;

        case operandType.arrayItem:
            return legitimacy.annaDsbUpiEnrichment;

        default:
            throw new Error(`Unexpected special operand type: ${specialOperandType}`);
    }
};

const reduce = (specialOperandTypes: string[] | null, statement: string, legitimacy: CustomDataSourceLegitimacy): string[] | null => {

    if (!typeHelper.isArray(specialOperandTypes)) {

        return null;
    }

    return (specialOperandTypes as string[]).filter(x => qualifies(x, statement, legitimacy));
};

const specialOperandTypeReducer = {
    reduce
};

export default specialOperandTypeReducer;
