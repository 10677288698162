import stringHelper from '../common/stringHelper';

const maxLength = 100;

const validateName = (name: string): string | null => {

    if (stringHelper.isEmpty(name)) {

        return 'Action item name is required';
    }

    if (name.length > maxLength) {

        return `Action item name max length is ${maxLength} characters`;
    }

    return null;
};

const actionItemValidator = { validateName };

export default actionItemValidator;
