import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import customDataSourceHelper from '../customDataSource/customDataSourceHelper';
import dataSourceHelper from '../common/dataSourceHelper';
import dataType from '../../../constants/dataType';
import FieldDefinition from '../../../../types/common/FieldDefinition';
import FunctionDefinition from '../../../../types/functions/FunctionDefinition';
import functionDisplayHelper from '../function/functionDisplayHelper';
import functionHelper from '../function/functionHelper';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import lookupHelper from '../lookup/lookupHelper';
import Operand from '../../../../types/functions/Operand/Operand';
import OperandDetails from '../../../../types/functions/Operand/OperandDetails';
import operandHelper from './operandHelper';
import operandType from '../../../constants/functions/operandType';
import pseudocodeHelper from '../common/pseudocodeHelper';
import reportConfigNavigation from '../../report/navigation/reportConfigNavigation';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import reportFieldHelper from '../common/reportFieldHelper';
import ReportVariable from '../../../../types/report/ReportVariable';
import specialReturnType from '../../../constants/functions/definition/specialReturnType';
import typeHelper from '../../common/typeHelper';
import selectorsStore from '../../../../store/selectorsStore';
import dictionaryHelper from '../dictionaries/dictionaryHelper';
import Dictionary from '../../../../types/report/Dictionary';
import userDefinedFunctionHelper from '../userDefinedFunctions/userDefinedFunctionHelper';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

interface FieldDetails {
    dataSource: IngestionConfig;
    field: FieldDefinition;
}

const getFieldDetails = (operand: Operand, dataSources: IngestionConfig[]): FieldDetails | null => {

    for (let i = 0; i < dataSources.length; i++) {

        for (let j = 0; j < dataSources[i].fieldDefinitions.length; j++) {

            if (dataSources[i].fieldDefinitions[j].id === operand.fieldId) {
                return {
                    dataSource: dataSources[i],
                    field: dataSources[i].fieldDefinitions[j]
                };
            }
        }
    }

    return null;
};

const getConstantValue = (operand: Operand): string => {

    if (operand.dataType === dataType.string) {
        return pseudocodeHelper.wrapInQuotes(operand.value);
    }

    return operand.value;
};

const getFieldIdentifier = (operand: Operand, lookups: Lookup[], dictionaries: Dictionary[], details: FieldDetails): string => {
    let name: string;

    if (typeHelper.isNumber(operand.lookupNumber)) {

        let lookup = lookupHelper.getByNumber(lookups, operand.lookupNumber as number);

        name = lookupHelper.getName(lookup, details.dataSource.name);
    }
    else if (typeHelper.isNumber(operand.dictionaryNumber)) {

        let dictionary = dictionaryHelper.getByNumber(dictionaries, operand.dictionaryNumber);

        name = dictionaryHelper.getName(dictionary, details.dataSource.name);
    }
    else {
        name = details.dataSource.name;
    }

    return pseudocodeHelper.getFieldIdentifier(name, details.field.name);
};

const getFunctionDisplayText = (operand: Operand, args: OperandDetails[]): string => {

    let $arguments = args.map(x => x.displayText);

    return functionDisplayHelper.getFunctionCall(operand.function, $arguments);
};

const isIllegalReference = (definitions: ReportFieldDefinition[], ownerReportFieldId: number, referencedReportField: ReportFieldDefinition): boolean => {

    let ownerReportField = reportFieldHelper.getById(definitions, ownerReportFieldId);

    return referencedReportField.number >= ownerReportField.number;
};

const resolveFunctionReturnType = (definition: FunctionDefinition, args: OperandDetails[]): string => {

    if (definition.returnType !== specialReturnType.generic) {

        return definition.returnType;
    }

    let argumentIndex = definition.returnTypeArgumentIndex || args.length - 1;

    let genericArgument = args[argumentIndex];
    if (genericArgument.isSetUp) {

        return genericArgument.dataType;
    }

    return definition.returnType;
};

const getOperandDetails = (operand: Operand, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, reportFieldId: number | null): OperandDetails => {

    let result: OperandDetails = {
        isSetUp: false,
        isIllegalReportFieldReference: false,
        displayText: '',
        operandType: operand.operandType,
        dataType: '',
        definitionHtmlId: null
    };

    let selectorsState = selectorsStore.getState();

    if (operandHelper.isValidField(operand) ||
        operandHelper.isValidLookupField(operand) ||
        operandHelper.isValidDictionaryField(operand)) {

        result.isSetUp = true;

        let details = getFieldDetails(operand, dataSources) as FieldDetails;

        result.displayText = getFieldIdentifier(operand, lookups, dictionaries, details);

        result.dataType = details.field.type;

        if (operandHelper.isValidLookupField(operand)) {

            result.definitionHtmlId = reportConfigNavigation.buildLookupId(operand.lookupNumber as number);
        }

        if (operandHelper.isValidDictionaryField(operand)) {

            let parameters = operand.arguments as Operand[];

            result.isSetUp = result.isSetUp && parameters.every(x => {

                var parameterDetails = getOperandDetails(x, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, reportFieldId);

                return parameterDetails.isSetUp;
            });

            result.definitionHtmlId = reportConfigNavigation.buildDictionaryId(operand.dictionaryNumber as number);
        }
    }
    else if (operandHelper.isValidReportField(operand)) {

        result.isSetUp = true;

        let reportField = reportFieldHelper.getById(reportFields, operand.reportFieldId as number);

        if (typeHelper.isNumber(reportFieldId)) {

            result.isIllegalReportFieldReference = isIllegalReference(reportFields, reportFieldId as number, reportField);
        }

        result.displayText = pseudocodeHelper.wrapInBrackets(reportField.name);

        result.dataType = reportField.dataType;

        result.definitionHtmlId = reportConfigNavigation.buildReportFieldId(reportField.number);
    }
    else if (operandHelper.isValidCustomDataSourceField(operand)) {

        result.isSetUp = true;

        let definition = customDataSourceHelper.getFieldById(customDataSourceFields, operand.customDataSourceFieldId as number);

        result.displayText = pseudocodeHelper.wrapInBrackets(definition.name);

        result.dataType = definition.type;
    }
    else if (operandHelper.isValidVariable(operand)) {

        result.isSetUp = true;

        let variable = variables.find(x => x.number === operand.variableNumber) as ReportVariable;

        result.displayText = pseudocodeHelper.wrapInBracketsVariable(variable.number, variable.name);

        result.dataType = variable.dataType;

        result.definitionHtmlId = reportConfigNavigation.buildVariableId(variable.number);
    }
    else if (operandHelper.isValidFunction(operand)) {

        let args = (operand.arguments as Operand[]).map(x => getOperandDetails(x, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, reportFieldId));

        result.isSetUp = args.every(x => x.isSetUp);

        result.isIllegalReportFieldReference = args.some(x => x.isIllegalReportFieldReference);

        if (result.isSetUp) {

            result.displayText = getFunctionDisplayText(operand, args);
        }

        let definition = functionHelper.getFunctionDefinition(operand.function);

        result.dataType = resolveFunctionReturnType(definition, args);
    }
    else if (operandHelper.isValidConstant(operand)) {

        result.isSetUp = true;
        result.displayText = getConstantValue(operand);
        result.dataType = operand.dataType;
    }
    else if (operandHelper.isValidGroup(operand)) {

        result.isSetUp = true;
        result.displayText = '[Group]';
    }
    else if (operandHelper.isValidLookupResultSet(operand)) {

        result.isSetUp = true;

        let lookup = lookupHelper.getByNumber(lookups, operand.lookupNumber as number);

        let dataSource = dataSourceHelper.getById(dataSources, lookup.dataSource2Id as number);

        result.definitionHtmlId = reportConfigNavigation.buildLookupId(operand.lookupNumber as number);

        result.displayText = pseudocodeHelper.wrapInBrackets(lookupHelper.getName(lookup, dataSource.name));
    }
    else if (operandHelper.isValidFirdsResultSet(operand)) {

        result.isSetUp = true;

        let isinSelectors = selectorsState.selectors.isinSelectors;

        let displayTextIsin = operand.operandType === operandType.firdsResultSetEsma ? '[FIRDS-ESMA]' : '[FIRDS-FCA]';

        let isinSelectorName = isinSelectors[0].name;

        for (let i = 0; i < isinSelectors.length; i++) {
            if (isinSelectors[i].number === operand.selectorNumber) {
                isinSelectorName = isinSelectors[i].name;
            }
        }

        result.displayText = displayTextIsin + ".[" + isinSelectorName + "]";
    }
    else if (operandHelper.isValidLeiLevel1ResultSet(operand)) {

        result.isSetUp = true;

        let leiSelectors = selectorsState.selectors.leiSelectors;

        let leiSelectorName = leiSelectors[0].name;

        for (let i = 0; i < leiSelectors.length; i++) {
            if (leiSelectors[i].number === operand.selectorNumber) {
                leiSelectorName = leiSelectors[i].name;
            }
        }

        result.displayText = '[LEI-LEVEL-1].[' + leiSelectorName + ']';
    }
    else if (operandHelper.isValidExchangeRatesResultSet(operand)) {

        result.isSetUp = true;

        let exchangeRatesSelectors = selectorsState.selectors.exchangeRatesSelectors;

        let exchangeRatesSelectorName = exchangeRatesSelectors[0].name;

        for (let i = 0; i < exchangeRatesSelectors.length; i++) {
            if (exchangeRatesSelectors[i].number === operand.selectorNumber) {
                exchangeRatesSelectorName = exchangeRatesSelectors[i].name;
            }
        }

        result.displayText = '[EXCHANGE-RATES].[' + exchangeRatesSelectorName + ']';
    }
    else if (operandHelper.isValidAnnaDsbUpiEnrinchmentResultSet(operand)) {

        result.isSetUp = true;

        let annaDsbUpiEnrichmentSelectors = selectorsState.selectors.annaDsbUpiEnrichmentSelectors;

        let annaDsbUpiEnrichmentSelectorName = annaDsbUpiEnrichmentSelectors[0].name;

        for (let i = 0; i < annaDsbUpiEnrichmentSelectors.length; i++) {
            if (annaDsbUpiEnrichmentSelectors[i].number === operand.selectorNumber) {
                annaDsbUpiEnrichmentSelectorName = annaDsbUpiEnrichmentSelectors[i].name;
            }
        }

        result.displayText = '[ANNA DSB UPI ENRICHMENT].[' + annaDsbUpiEnrichmentSelectorName + ']';
    }
    else if (operandHelper.isValidAnnaDsbUpiResultSet(operand)) {

        result.isSetUp = true;

        let annaDsbUpiSelectors = selectorsState.selectors.annaDsbUpiSelectors;

        let annaDsbUpiSelectorName = annaDsbUpiSelectors[0].name;

        for (let i = 0; i < annaDsbUpiSelectors.length; i++) {
            if (annaDsbUpiSelectors[i].number === operand.selectorNumber) {
                annaDsbUpiSelectorName = annaDsbUpiSelectors[i].name;
            }
        }

        result.displayText = '[ANNA DSB UPI].[' + annaDsbUpiSelectorName + ']';
    }
    else if (operandHelper.isValidIsinSelector(operand)) {

        result.isSetUp = true;

        result.displayText = '';
    }
    else if (operand.operandType === operandType.arrayItem) {

        result.isSetUp = true;

        result.displayText = 'Array.[Item]';
    }
    else if (operand.operandType === operandType.parameter) {

        result.isSetUp = true;

        result.dataType = operand.dataType;

        result.displayText = operand.value;
    }
    else if (operand.operandType === operandType.userDefinedFunction) {

        let func = userDefinedFunctionHelper.getByNumber(functions, operand.functionNumber);
        let parameters = operand.arguments as Operand[];

        result.displayText = func.name;
        result.dataType = func.returnType;
       
        result.isSetUp = parameters.every(x => {

            var parameterDetails = getOperandDetails(x, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, reportFieldId);
            return parameterDetails.isSetUp;
        });
        result.definitionHtmlId = reportConfigNavigation.buildFunctionId(operand.functionNumber as number);
    }

    return result;
};

const operandDisplayHelper = {
    getOperandDetails
};

export default operandDisplayHelper;
