const getClassName = (isInlineMode: boolean): string => {
    let classes = ['format-picker'];

    if (isInlineMode) {
        classes.push('inline');
    }

    return classes.join(' ');
};

export { getClassName };