import booleanLiteral from '../../../../constants/functions/booleanLiteral';
import dataType from '../../../../constants/dataType';

const timeInitialValue: string = '00:00:00';

const get = (type: string): string => {

    switch (type) {
        case dataType.integer:
        case dataType.decimal:
        case dataType.unixTime:
            return '0';

        case dataType.boolean:
            return booleanLiteral.true;

        case dataType.date:
            return new Date().toISOString().substr(0, 10);

        case dataType.time:
            return timeInitialValue;

        case dataType.dateTime:
            return new Date().toISOString().substr(0, 11) + timeInitialValue + 'Z';
    }

    return '';
}
const constantInitialValueHelper = {
    get
};

export default constantInitialValueHelper;
