import Assignment from '../../../../types/functions/Assignment/Assignment';
import AssignmentItemDetails from '../../../../types/functions/Assignment/AssignmentItemDetails';
import Condition from '../../../../types/functions/Condition/Condition';
import typeHelper from '../../common/typeHelper';

const getDetails = (item: Assignment, i: number, collection: Assignment[]): AssignmentItemDetails => {
    return {
        isIf: collection.length > 1 && i === 0,
        isElse: i > 0 && typeHelper.isObject(collection[i - 1].else),
        isSingle: collection.length === 1,
        number: item.number
    };
};

const resolveConditionKeyword = (details: AssignmentItemDetails): string => {

    if (details.isSingle) {
        return '';
    }

    if (details.isIf) {
        return 'IF';
    }
    else if (details.isElse) {
        return 'ELSE';
    }
    else {
        return 'ELSE IF';
    }
};

const getElseIf = (assignment: Assignment, number: number): Condition | null => {
    if (assignment && assignment.number !== number && assignment.elseIf && assignment.elseIf.number !== number) {
        return getElseIf(assignment.elseIf, assignment.elseIf?.number);
    }
    else {
        return assignment.condition;
    }
};

const assignmentItemHelper = {
    getDetails,
    resolveConditionKeyword,
    getElseIf
};

export default assignmentItemHelper;