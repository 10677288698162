import { reportType } from '../../../infrastructure/constants/reportType';
import DataSourceAnalysisResult from './Types/DataSourceAnalysisResult';
import ReportConfig from '../../../types/report/ReportConfig';
import ReportConfigBundle from '../../../types/report/import/ReportConfigBundle';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

const validateUpdate = (bundle: ReportConfigBundle, analysis: DataSourceAnalysisResult, edited: ReportConfig | null): string | null => {

    if (!typeHelper.isObject(edited)) {

        return null;
    }

    if (bundle.config.regime !== (edited as ReportConfig).regime) {

        return 'Reporting regime mismatch. Unable to update config.';
    }

    if (bundle.config.type !== (edited as ReportConfig).type) {

        return 'Reporting type mismatch. Unable to update config.';
    }


    if (bundle.config.type === reportType.accuracy) {

        return null;
    }

    if (analysis.maps.dataSource[bundle.config.rawDataSourceId as number] !== (edited as ReportConfig).rawDataSourceId) {

        return 'Raw data source mismatch. Unable to update config.';
    }

    return null;
};

export { validateUpdate };
