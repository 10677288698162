import actions from '../../../store/actions';
import ChangeDescription from '../../../infrastructure/types/DifferenceVisualizer/ChangeDescription';
import ChangeLogModal from '../Modals/ChangeLogModal/ChangeLogModal';
import ChangeLogModalState from '../Modals/ChangeLogModal/ChangeLogModalState';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import React from 'react';
import ServerDataGrid from '../DataGrid/ServerDataGrid/ServerDataGrid';
import store from '../../../store/store';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import Version from '../../../types/common/Version';
import versionHelper from '../../../infrastructure/helpers/functions/common/versionHelper';

interface Props {
    getColumns: (onGetChangesClick: (identifier: string) => Promise<void>) => ColumnDefinition<Version>[];
    modalColumns: ColumnDefinition<ChangeDescription>[];
    backendRoute: string;
    uniqueName: string;
}

class ChangeLog extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onGetChangesClick = this.onGetChangesClick.bind(this);
        this.onGetChangesSuccess = this.onGetChangesSuccess.bind(this);
    }

    onGetChangesClick(identifier: string): Promise<void> {

        let route = urlHelper.buildRoute([this.props.backendRoute, identifier]);

        return httpClient.get<ChangeDescription[]>(route).then(response => this.onGetChangesSuccess(identifier, response.data));
    }

    onGetChangesSuccess(version: string, changes: ChangeDescription[]): void {

        let payload: ChangeLogModalState = {
            isOpen: true,
            title: versionHelper.getDisplayName(version),
            columns: this.props.modalColumns,
            data: changes,
            error: null
        };

        store.dispatch({ type: actions.changeLogModal.open, payload: payload });
    }

    render(): JSX.Element {
        return (
            <>
                <ServerDataGrid
                    columns={this.props.getColumns(this.onGetChangesClick)}
                    uniqueName={this.props.uniqueName}
                    dataSourceRoute={this.props.backendRoute}
                    getKey={x => x.identifier}
                    pageSize={10} />

                <ChangeLogModal />
            </>
        );
    }
}

export default ChangeLog;
