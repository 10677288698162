import Condition from '../../../../types/functions/Condition/Condition';
import conditionHelper from '../condition/conditionHelper';
import copyObject from '../../common/copyObject';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import operandDisplayHelper from './operandDisplayHelper';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import reportLogicLocationHelper from '../report/reportLogicLocationHelper';
import ReportVariable from '../../../../types/report/ReportVariable';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const resolve = (condition: Condition, location: ReportLogicLocation, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): string | null => {

    if (location.operandNumber !== 2 || !reportLogicLocationHelper.isRoot(location)) {

        return null;
    }

    let copy = copyObject(location);

    copy.operandNumber = 1;

    let operand1 = conditionHelper.getOperand(condition, copy);

    let details = operandDisplayHelper.getOperandDetails(operand1, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, null);

    return details.isSetUp ? details.dataType : null;
};

const operand2ExpectedTypeHelper = {
    resolve
};

export default operand2ExpectedTypeHelper;
