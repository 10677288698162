import Assignment from '../../../../types/functions/Assignment/Assignment';
import assignmentHelper from './assignmentHelper';
import AssignmentItemDetails from '../../../../types/functions/Assignment/AssignmentItemDetails';
import assignmentItemHelper from './assignmentItemHelper';
import Condition from '../../../../types/functions/Condition/Condition';
import conditionDisplayHelper from '../condition/conditionDisplayHelper';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import Dictionary from '../../../../types/report/Dictionary';

const buildAssignment = (fieldName: string, assignment: Assignment, lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): string => {

    let items = assignmentHelper.asCollection(assignment);
    let details = items.map(assignmentItemHelper.getDetails);

    return items.map((x, i) => buildAssignmentItem(fieldName, x, lookups, dictionaries, variables, details[i], dataSources, reportFields, customDataSourceFields)).join('\n');
};

const buildValue = (fieldName: string, item: Assignment, lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): string => {

    let value = item.doNotPopulate ?
        'DO NOT POPULATE' :
        conditionDisplayHelper.buildOperand(item.value, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields);

    return `[${fieldName}] = ${value}`;
};

const buildAssignmentItem = (fieldName: string, item: Assignment, lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], details: AssignmentItemDetails, dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): string => {

    let value = buildValue(fieldName, item, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields);

    if (details.isSingle) {
        return value;
    }

    if (details.isElse) {
        return `${assignmentItemHelper.resolveConditionKeyword(details)} ${value}`;
    }

    let conditions = conditionDisplayHelper.buildConditions(item.condition as Condition, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields);

    return `${assignmentItemHelper.resolveConditionKeyword(details)} ${conditions}\nTHEN ${value}`;
};

const assignmentDisplayHelper = {
    buildAssignment
};

export default assignmentDisplayHelper;
