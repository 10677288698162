import { moduleColumn, commonColumns } from '../../Common/Modals/ChangeLogModal/ChangeLogModal.Grid';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ChangeLog from '../../Common/ChangeLog/ChangeLog';
import getColumns from './IngestionChangeLogPage.Grid';
import history from '../../../infrastructure/helpers/react/history';
import React from 'react';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import RollBackVersionModal from '../../Common/Modals/RollbackVersionModal/RollBackVersionModal';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import RollbackVersionModalState from '../../Common/Modals/RollbackVersionModal/RollbackVersionModalState';
import IncrementVersionRequest from '../../../types/common/IncrementVersionRequest';
import store from '../../../store/store';
import actions from '../../../store/actions';
import GridRefresh from '../../../store/state/events/GridRefresh';

const gridName = 'ingestion-change-log';

class IngestionChangeLogPage extends React.Component {
    private id: number;
    private isValidQueryParam: boolean;

    constructor(props: any) {
        super(props);

        let query = urlHelper.deserializeQuery(history.location.search);

        this.id = parseInt(query['id']);

        this.isValidQueryParam = typeHelper.isNumber(this.id);

        if (!this.isValidQueryParam) {
            history.push(appRoutes.ingestion.home);
        }

        this.openRollBackModal = this.openRollBackModal.bind(this);
        this.onIncrementVersionSuccess = this.onIncrementVersionSuccess.bind(this);
    }

    openRollBackModal(): void {

        var getVersionsRoute = urlHelper.buildRoute(['ingestion-version', this.id, 'list']);

        httpClient.get<string[]>(getVersionsRoute).then((response) => {

            let payload: RollbackVersionModalState = {
                isOpen: true,
                title: 'Roll Back',
                name: '',
                comment: '',
                versions: response.data,
                selectedVersion: response.data[0],
                onSaveClick: (identifier: string, name: string, comment: string) => {
                    let route = urlHelper.buildRoute(['ingestion', this.id, 'rollback', identifier]);
                    return httpClient.post(route, { name: name, comment: comment } as IncrementVersionRequest).then(this.onIncrementVersionSuccess)
                },
                error: null
            };

            if (payload.versions.length > 0) {

                payload.selectedVersion = payload.versions[0];
                payload.name = `Roll Back to V${payload.selectedVersion}`;
            }

            store.dispatch({ type: actions.rollBackVersionModal.open, payload: payload });
        });
    }

    onIncrementVersionSuccess() {

        let payload: GridRefresh = {
            uniqueName: gridName,
            useCurrentPage: false
        };

        store.dispatch({ type: actions.grid.refresh, payload: payload });
    }

    render(): JSX.Element {

        if (!this.isValidQueryParam) {
            return (<></>);
        }

        return (
            <>
                <h4 className="mb-3">Version Management</h4>

                <div className="mb-4">
                    <button className="btn cb-btn" onClick={this.openRollBackModal}>
                        <span>Roll Back</span>
                    </button>
                </div>

                <h4 className="mb-3">Change Log</h4>

                <ChangeLog
                    getColumns={getColumns}
                    modalColumns={[moduleColumn].concat(commonColumns)}
                    backendRoute={urlHelper.buildRoute(['ingestion-version', this.id])}
                    uniqueName={gridName} />

                <div className="mt-3 mb-3">
                    <button onClick={() => history.push(appRoutes.ingestion.home)} className="btn btn-light">Back</button>
                </div>

                <RollBackVersionModal showOnlyVersions={true} />
            </>
        );
    }
}

export default IngestionChangeLogPage;
