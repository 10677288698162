import ReportVariable from '../../../types/report/ReportVariable';
import systemVariables from '../../../infrastructure/constants/functions/systemVariables';
import { SelectSearchOption } from 'react-select-search';

const resolveSystemVariableNameSuggestion = (variables: ReportVariable[]): string => {

    for (let i = 0; i < systemVariables.names.length; i++) {

        if (!variables.some(x => x.isSystem && x.name === systemVariables.names[i])) {

            return systemVariables.names[i];
        }
    }

    return systemVariables.names[0];
};

const prepareOptionsFromVariables = (variables: ReportVariable[]): SelectSearchOption[] => {

    let options: SelectSearchOption[] = [];
    variables.forEach((variable) => {
        options.push({
            value: variable.name,
            name: variable.name,
            items: [{ name: variable.number.toString(), value: variable.number.toString() }]
        });
    });

    return options;
}

const dataTypeChangeMessage = 'Changing the data type will reset the logic for this variable. Please copy the logic to a new variable before continuing.';
export { resolveSystemVariableNameSuggestion, prepareOptionsFromVariables, dataTypeChangeMessage };