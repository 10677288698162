import Condition from '../../../types/functions/Condition/Condition';
import conditionHelper from '../../../infrastructure/helpers/functions/condition/conditionHelper';
import ConditionItem from './ContitionItem/ConditionItem';
import copyObject from '../../../infrastructure/helpers/common/copyObject';
import CustomDataSourceFields from '../../../types/report/CustomDataSourceFields';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import locationType from '../../../types/functions/Location/LocationType';
import Lookup from '../../../types/functions/Lookup';
import React from 'react';
import ReportFieldDefinition from '../../../types/report/ReportFieldDefinition';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import ReportVariable from '../../../types/report/ReportVariable';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import { reorder } from './ContitionItem/ConditionItem.Helpers';
import './ConditionBuilder.scss';
import Dictionary from '../../../types/report/Dictionary';
import UserDefinedFunction from '../../../types/report/UserDefinedFunction';

interface ConditionBuilderProps {
    isReadOnly: boolean;
    allowCopy: boolean;
    keyword: string;
    allwaysAllowDelete: boolean;
    functions: UserDefinedFunction[];
    lookups: Lookup[];
    dictionaries: Dictionary[];
    variables: ReportVariable[];
    dataSources: IngestionConfig[];
    reportFields: ReportFieldDefinition[];
    customDataSourceFields: CustomDataSourceFields;
    location: ReportLogicLocation;
    condition: Condition;
    clauseNumber?: number;
    onOperandClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onComparisonTypeChange: (location: ReportLogicLocation, value: string, event?: React.ChangeEvent<HTMLElement>) => void;
    onRemoveConditionClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onLogicalOperatorChange: (location: ReportLogicLocation, operator: string, event?: React.ChangeEvent<HTMLElement>) => void;
    onParenthesesChange: (location: ReportLogicLocation, count: number, type: string, event?: React.MouseEvent<HTMLElement>) => void;
    onAddConditionClick: (location: ReportLogicLocation, operator: string | null, event?: React.MouseEvent<HTMLElement>) => void;
    onCopyClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onPasteClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onClearClick?: (e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLElement>) => Promise<boolean>;
    allowPaste: (location: ReportLogicLocation) => boolean;
    onReorderConditions?: (condition: Condition, error?: string) => void;
    onReorderAssignmentConditions?: (dragNumber: number, dropNumber: number, error?: string) => void;
}

class ConditionBuilder extends React.Component<ConditionBuilderProps> {
    public static defaultProps = {
        allwaysAllowDelete: false
    };

    constructor(props: ConditionBuilderProps) {
        super(props);

        this.onAddFirstConditionClick = this.onAddFirstConditionClick.bind(this);
        this.getConditionItem = this.getConditionItem.bind(this);
        this.getConditionItemLocation = this.getConditionItemLocation.bind(this);
        this.onReorderConditions = this.onReorderConditions.bind(this);
    }

    onAddFirstConditionClick(event: React.MouseEvent<HTMLElement>): void {
        this.props.onAddConditionClick(this.props.location, null, event);
    }

    onReorderConditions(dragNumber: number, dropNumber: number, error?: string) {
        let condition = Object.assign({}, this.props.condition);

        if (this.props.onReorderAssignmentConditions) {
            this.props.onReorderAssignmentConditions(dragNumber, dropNumber, error)
        }
        else if (this.props.onReorderConditions) {
            if (!error) {
                reorder(condition, dragNumber, dropNumber);
                this.props.onReorderConditions && this.props.onReorderConditions(condition);
            }
            else {
                this.props.onReorderConditions(condition, error);
            }
        }
    }


    getConditionItem(condition: Condition, i: number, items: Condition[]): JSX.Element {
        return (
            <ConditionItem
                key={i}
                isReadOnly={this.props.isReadOnly}
                isFirst={i === 0}
                isLast={i === items.length - 1}
                allowCopy={this.props.allowCopy}
                allwaysAllowDelete={this.props.allwaysAllowDelete}
                keyword={this.props.keyword}
                functions={this.props.functions}
                lookups={this.props.lookups}
                dictionaries={this.props.dictionaries}
                variables={this.props.variables}
                dataSources={this.props.dataSources}
                reportFields={this.props.reportFields}
                customDataSourceFields={this.props.customDataSourceFields}
                location={this.getConditionItemLocation(condition)}
                condition={condition}
                clauseNumber={this.props.clauseNumber || 0}
                onOperandClick={this.props.onOperandClick}
                onComparisonTypeChange={this.props.onComparisonTypeChange}
                onRemoveClick={this.props.onRemoveConditionClick}
                onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                onParenthesesChange={this.props.onParenthesesChange}
                onAddConditionClick={this.props.onAddConditionClick}
                onCopyClick={this.props.onCopyClick}
                onPasteClick={this.props.onPasteClick}
                onClearClick={this.props.onClearClick}
                allowPaste={this.props.allowPaste}
                onReorderConditions={this.onReorderConditions} />
        );

    }

    getConditionItemLocation(condition: Condition): ReportLogicLocation {

        let location = copyObject(this.props.location);

        location.pieceOfCode = locationType.pieceOfCode.condition;
        location.conditionNumber = condition.number;

        return location;
    }

    render(): JSX.Element {
        return (
            <div className="condition-builder">

                <div className="code-block">
                    {
                        typeHelper.isObject(this.props.condition) ?
                            conditionHelper.asCollection(this.props.condition).map(this.getConditionItem) :
                            <div>
                                <span>
                                    <span className="code">{this.props.keyword} </span>
                                    {
                                        !this.props.isReadOnly &&
                                        <button className="helper-btn" onClick={this.onAddFirstConditionClick}>+ Condition</button>
                                    }
                                </span>
                            </div>
                    }
                </div>

            </div>
        );
    }
}

export default ConditionBuilder;
