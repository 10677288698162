import Operand from '../../../types/functions/Operand/Operand';
import operandHelper from '../../helpers/functions/operand/operandHelper';
import ReportConfig from '../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';

abstract class OperandSetHelper<T> {

    handleArgumentCountChange(set: Operand[], count: number): void {

        let diff = count - set.length;

        for (let i = 0; i < Math.abs(diff); i++) {

            if (diff > 0) {

                set.push(operandHelper.getEmpty());
            }
            else if (diff < 0) {

                set.pop();
            }
        }
    }

    abstract getSet(config: ReportConfig, location: ReportLogicLocation): Operand[];

    abstract getDataSourceId(config: ReportConfig, location: ReportLogicLocation): number | null;

    abstract resolveTarget(config: ReportConfig): T;
}

export default OperandSetHelper;
