import { ReactComponent as EditIcon } from '../../Common/BootstrapIcons/edit.svg';
import { ReactComponent as HistoryIcon } from '../../Common/BootstrapIcons/history.svg';
import { ReactComponent as DownloadIcon } from '../../Common/BootstrapIcons/download.svg';
import { ReactComponent as ExportIcon } from '../../Common/BootstrapIcons/caret-up-fill.svg';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import history from '../../../infrastructure/helpers/react/history';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import React from 'react';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import PromiseButton from '../../Common/PromiseButton';
import ValidationConfigGridItem from '../../../types/report/ValidationConfigGridItem';
import Spinner from '../../Common/Spinner/Spinner';

const uniqueName = 'validation-grid';

const getColumns = (onEditClick: (id: number) => void, onDownloadClick: (id: number) => Promise<void>, onCopyConfigSourceClick: (id: number) => Promise<void>, showSpinnerForId: number): ColumnDefinition<ValidationConfigGridItem>[] => {

    return [
        {
            header: 'Id',
            sortKey: 'Id',
            getContent: x => x.id.toString(),
            width: '46px'
        },
        {
            header: 'Name',
            sortKey: 'Name',
            getContent: x => <button className="transparent-button" onClick={() => onEditClick(x.id)}>{x.name}</button>,
            className: 'break-all'
        },
        {
            header: 'Regime',
            sortKey: 'Regime',
            getContent: x => x.regime
        },
        {
            header: 'Stage',
            sortKey: 'IsReleased',
            getContent: x => x.isReleased ? 'Released' : 'Unreleased',
            width: '90px'
        },
        {
            header: 'Version Number',
            sortKey: 'ReleaseNumber',
            getContent: x => x.releaseNumber > 0 ? x.releaseNumber.toString() : '-',
            width: '130px'
        },
        {
            header: 'Actions',
            getContent: x =>
                <>
                    <button className="custom-icon-button" onClick={() => history.push(urlHelper.buildUrl(appRoutes.reporting.changeLog, { id: x.id, redirect: encodeURIComponent(appRoutes.validation.home) }))}>
                        <CustomIcon icon={HistoryIcon} title={iconTooltip.changeLog} />
                    </button>
                    <button className="custom-icon-button" onClick={() => onEditClick(x.id)}>
                        <CustomIcon icon={EditIcon} title={iconTooltip.edit} />
                    </button>
                    <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onDownloadClick(x.id)} disabled={!x.hasRefData}>
                        <CustomIcon icon={DownloadIcon} title={x.hasRefData ? iconTooltip.download : 'Ref data missing'} />
                    </PromiseButton>
                    <button className="custom-icon-button export-icon" onClick={async () => await onCopyConfigSourceClick(x.id)}>
                        {
                            x.id === showSpinnerForId ? <Spinner /> :
                                <CustomIcon icon={ExportIcon} title={iconTooltip.copyReportSource} />
                        }
                    </button>
                </>,
            width: '150px'
        }
    ];
};

export { getColumns, uniqueName };