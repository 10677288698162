import React from 'react';
import dataType from '../../../infrastructure/constants/dataType';
import { mapToBasicOption } from '../../../infrastructure/helpers/html/selectOptionHelper';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import Assignment from '../../../types/functions/Assignment/Assignment';
import UserDefinedFunction from '../../../types/report/UserDefinedFunction';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import { ReactComponent as RemoveIcon } from '../../Common/BootstrapIcons/close.svg';
import EditableText from '../../Common/EditableText/EditableText';
import SelectList from '../../Common/SelectList/SelectList';

const dataTypeOptions = Object.values(dataType).map(mapToBasicOption);

const getDataTypeContent = (func: UserDefinedFunction, onChange: (type: string, number: number, assignment: Assignment) => void, isReadOnly: boolean, onLockSectionClick?: (number: number) => Promise<boolean>): JSX.Element => {

    const onChangeCheckIfLocked = async (type: string, number: number, assignment: Assignment, event: React.ChangeEvent<HTMLElement>): Promise<void> => {

        const isLocked = onLockSectionClick && await onLockSectionClick(number);
        if (!isLocked) {
            onChange(type, func.number, func.assignment);
        }
        else {
            event.preventDefault();
        }
    }

    return (
        <SelectList
            className="inline-select"
            options={dataTypeOptions}
            value={func.returnType}
            onClick={async (event) => onLockSectionClick && await onLockSectionClick(func.number)}
            onChange={(type, event) => { onLockSectionClick ? onChangeCheckIfLocked(type, func.number, func.assignment, event) : onChange(type, func.number, func.assignment) }}
            disabled={isReadOnly} />
    );
};

const getColumns = (
    onNameChange: (name: string, number: number) => void,
    onReturnTypeChange: (type: string, number: number, assignment: Assignment) => void,
    onBusinessDescriptionChange: (businessDescription: string, number: number) => void,
    onRemoveClick: (number: number) => void,
    isReadOnly: boolean
): ColumnDefinition<UserDefinedFunction>[] => {

    let columns: ColumnDefinition<UserDefinedFunction>[] = [
        {
            header: 'Reference',
            getContent: x => x.number.toString(),
            width: '110px'
        },
        {
            header: 'Function Name',
            getContent: x => <EditableText disabled={isReadOnly} value={x.name} onChange={value => onNameChange(value, x.number)} multiline={false} />,
            width: '200px'
        },
        {
            header: 'Return Type',
            getContent: x => getDataTypeContent(x, onReturnTypeChange, isReadOnly),
            width: '200px'
        },
        {
            header: 'Business Description',
            getContent: x => <EditableText disabled={isReadOnly} value={x.businessDescription} onChange={value => onBusinessDescriptionChange(value, x.number)} multiline={true} style={{ width: '100%', height: '60px' }} />
        },
        {
            header: '',
            getContent: x =>
                <button
                    className="custom-icon-button float-right"
                    onClick={() => onRemoveClick(x.number)}
                    disabled={isReadOnly}>
                    <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                </button>,
            width: '40px'
        }
    ];

    return columns;
};

export { getColumns };
