import Condition from '../../../../types/functions/Condition/Condition';
import logicalOperator from '../../../constants/functions/logicalOperator';
import parenthesesType from '../../../constants/functions/parenthesesType';

const changeLogicalOperator = (condition: Condition, operator: string): void => {

    if (operator === logicalOperator.or) {

        condition.or = condition.and;
        condition.and = null;
    }
    else if (operator === logicalOperator.and) {

        condition.and = condition.or;
        condition.or = null;
    }
    else {
        throw new Error(`Unexpected logical operator '${operator}'`);
    }
};

const changeParenthesesCount = (condition: Condition, count: number, type: string): void => {

    if (type === parenthesesType.open) {

        condition.openParentheses = count;
    }
    else if (type === parenthesesType.close) {

        condition.closeParentheses = count;
    }
    else {
        throw new Error(`Unexpected parentheses type '${type}'`);
    }
};

const conditionModifyHelper = {
    changeLogicalOperator,
    changeParenthesesCount
};

export default conditionModifyHelper;
