import CopyValidationModalState from './CopyValidationModalState';
import AccuracyValidation from '../../../../types/report/AccuracyValidation';
import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';


const validate = (state: CopyValidationModalState, validations: AccuracyValidation[]): string | null => {

    if (stringHelper.isEmpty(state.sourceValidationNumber)) {
        return 'From validation is required';
    }

    if (stringHelper.isEmpty(state.targetValidationNumber)) {
        return 'To validation is required';
    }

    let sameValidation = state.sourceValidationNumber === state.targetValidationNumber;

    if (sameValidation) {
        return 'Unable to copy from a validation to the same validation';
    }

    return null;
};

export { validate };
