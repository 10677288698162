import './DateTimeFormatBuilder.scss'
import { ReactComponent as RemoveIcon } from '../../../../components/Common/BootstrapIcons/trash.svg';
import DateTimeFormatPicker from '../../../Common/DateTimeFormatPicker/DateTimeFormatPicker';
import iconTooltip from '../../../Common/BootstrapIcons/Tooltip/IconTooltip';
import React from 'react';

interface Props {
    dataType: string;
    formats: string[];
    onChange: (formats: string[]) => void;
}

class DateTimeFormatBuilder extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onAddClick = this.onAddClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);

        this.getItem = this.getItem.bind(this);
    }

    onAddClick(): void {

        let last = this.props.formats[this.props.formats.length - 1];

        this.props.onChange(this.props.formats.concat([last]));
    }

    onChange(i: number, value: string): void {

        this.props.formats[i] = value;

        this.props.onChange(this.props.formats);
    }

    onRemoveClick(i: number): void {

        this.props.formats.splice(i, 1);

        this.props.onChange(this.props.formats);
    }

    getItem(format: string, i: number, all: string[]): JSX.Element {

        return (
            <tr key={i}>
                <td>
                    {
                        all.length > 1 &&
                        <button className="transparent-button" onClick={() => this.onRemoveClick(i)} title={iconTooltip.remove}>
                            <RemoveIcon />
                        </button>
                    }
                </td>
                <td>
                    <DateTimeFormatPicker
                        dataType={this.props.dataType}
                        format={format}
                        onFormatChange={format => this.onChange(i, format)}
                        isInlineMode={true} />
                </td>
            </tr>
        );
    }

    render(): JSX.Element  {

        return (
            <div className="date-time-format-builder">
                <table>
                    <tbody>
                        {this.props.formats.map(this.getItem)}
                    </tbody>
                </table>
                <button onClick={this.onAddClick} className="btn btn-light">+ Format</button>
            </div>
        );
    }
}

export default DateTimeFormatBuilder;
