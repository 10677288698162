import clear from '../../common/array/clear';
import declarationValidator from './declarationValidator';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Selector from '../../../../types/report/Selector';
import Lookup from '../../../../types/functions/Lookup';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import uniquenessValidator from './uniquenessValidator';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const validateSelector = (selector: Selector, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], type: string): void => {

    clear(selector.errors);

    declarationValidator.validate(selector, functions, lookups, dictionaries, [], dataSources, reportFields, null as any, type, null, false);
};

const validate = (selectors: Selector[], functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], name: string, criteria: string, type: string): number[] => {

    selectors.forEach(x => validateSelector(x, functions, lookups, dictionaries, dataSources, reportFields, type));

    uniquenessValidator.validate(selectors, a => a.name, name, criteria);

    let numbers = selectors
        .filter(a => a.errors.length > 0)
        .map(a => a.number);

    return numbers;
};

const SelectorValidator = {
    validate,
    validateSelector
};

export default SelectorValidator;
