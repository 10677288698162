import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';

const getEmptyLocation = (): ReportLogicLocation => {

    return {} as ReportLogicLocation;
};

const isRoot = (location: ReportLogicLocation): boolean => {

    return location.argumentIndexes.length === 0;
}

const reportLogicLocationHelper = {
    getEmptyLocation,
    isRoot
};

export default reportLogicLocationHelper;
