import React from 'react';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import AccuracyValidation from '../../../types/report/AccuracyValidation';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import { ReactComponent as AssignmentIcon } from '../../Common/BootstrapIcons/assignment-late.svg';
import { ReactComponent as RemoveIcon } from '../../Common/BootstrapIcons/close.svg';
import { ReactComponent as CommentIcon } from '../../Common/BootstrapIcons/comment.svg';
import { ReactComponent as CopyIcon } from '../../Common/BootstrapIcons/copy.svg';
import { ReactComponent as HistoryIcon } from '../../Common/BootstrapIcons/history.svg';
import EditableText from '../../Common/EditableText/EditableText';
import PromiseButton from '../../Common/PromiseButton';
import BespokeToggleButton from '../../Functions/Bespoke/BespokeToggleButton';

const getColumns = (
    onIdentifierChange: (identifier: string, number: number, event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onRemoveClick: (number: number, event?: React.MouseEvent<HTMLButtonElement>) => void,
    onBespokeToggleClick: (isBespoke: boolean, number: number, event?: React.MouseEvent<HTMLButtonElement>) => void,
    onCommentClick: (id: number, event?: React.MouseEvent<HTMLButtonElement>) => Promise<any>,
    getCommentCount: (id: number) => number,
    getActionItemCount: (id: number, event?: React.MouseEvent<HTMLButtonElement>) => number,
    onActionItemClick: (id: number, event?: React.MouseEvent<HTMLButtonElement>) => Promise<any>,
    onCopyClick: (number: number, event?: React.MouseEvent<HTMLButtonElement>) => void,
    onViewChangelog: (id: number, event?: React.MouseEvent<HTMLButtonElement>) => void,
    isReadOnly: boolean,
    ): ColumnDefinition<AccuracyValidation>[] => {

    let columns: ColumnDefinition<AccuracyValidation>[] = [
        {
            header: 'Validation ID',
            getContent: x => <EditableText value={x.identifier}  onChange={(value, event) => onIdentifierChange(value, x.number, event)} multiline={false} disabled={x.isReadOnly || isReadOnly} />
        },
        {
            header: '',
            getContent: x =>
                <div className="float-right">
                    {
                        !isReadOnly && !x.isReadOnly &&
                        <BespokeToggleButton
                            isBespoke={x.isBespoke}
                            onClick={(isBespoke, event) => onBespokeToggleClick(isBespoke, x.number, event)}
                            style={{ verticalAlign: 'top', width: '150px' }} />
                    }
                    {
                        typeHelper.isNumber(x.id) &&
                        <>
                            <PromiseButton disabled={ x.isReadOnly} className="custom-icon-button" enableOnErrorOnly={false} task={(event) => onCommentClick(x.id as number, event)}>
                                <CustomIcon icon={CommentIcon} count={getCommentCount(x.id as number)} title={iconTooltip.comments} />
                            </PromiseButton>
                            <PromiseButton disabled={x.isReadOnly} className="custom-icon-button" enableOnErrorOnly={false} task={(event) => onActionItemClick(x.id as number, event)}>
                                <CustomIcon icon={AssignmentIcon} count={getActionItemCount(x.id as number)} title={iconTooltip.actionLog} />
                            </PromiseButton>
                        </>
                    }
                    <button className="custom-icon-button" onClick={(event) => onRemoveClick(x.number, event)} disabled={x.isReadOnly || isReadOnly}>
                        <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                    </button>
                    <button className="custom-icon-button" onClick={(event) => onCopyClick(x.number || 0, event)} disabled={x.isReadOnly || isReadOnly}>
                        <CustomIcon icon={CopyIcon} title={iconTooltip.copy} />
                    </button>
                    {
                        typeHelper.isNumber(x.id) &&
                        <>
                            <button className="custom-icon-button" onClick={(event) => onViewChangelog(x.id || 0, event)}>
                                <CustomIcon icon={HistoryIcon} title={iconTooltip.changeLog} />
                            </button>
                        </>
                    }
                </div>,
            width: '320px'
        }
    ];

    return columns;
};

export { getColumns };
