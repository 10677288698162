import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import ValidationChangeLogModalState from './ValidationChangeLogModalState';
import Modal from '../../../Common/Modal/Modal';
import React from 'react';
import store from '../../../../store/store';
import ChangeLog from '../../../Common/ChangeLog/ChangeLog';
import urlHelper from '../../../../infrastructure/helpers/common/urlHelper';
import { commonColumns } from '../../../Common/Modals/ChangeLogModal/ChangeLogModal.Grid';
import getColumns from './ValidationChangeLogModal.Grid';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';

const gridName = 'validation-change-log';

class ValidationChangeLogModal extends React.Component<any, ValidationChangeLogModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            isOpen: false,
            error: null,
            validationId: 0
        };

        this.onOpen = this.onOpen.bind(this);
        this.close = this.close.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onOpen);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    onOpen(): void {
        let state = store.getState();

        if (state.action !== actions.validationChangeLogModal.open) {
            return;
        }

        this.setState(state.validationChangeLogModalState);
    }

    close(): void {
        this.setState({ isOpen: false, validationId: 0 });
    }

    onDownloadClick(): Promise<void> {
        let route = urlHelper.buildRoute(['component-version', this.state.validationId, 'AccuracyValidation', 'export']);

        return httpClient.download(route, {});
    }

    render(): JSX.Element {

        return (
            <Modal state={this.state} close={this.close} size="large">

                <div className="mb-3 float-right">
                    <button className="btn cb-btn" onClick={this.onDownloadClick}>Download</button>
                </div>

                {
                    this.state.validationId &&
                    <ChangeLog
                        getColumns={getColumns}
                        modalColumns={commonColumns}
                        backendRoute={urlHelper.buildRoute(['component-version', this.state.validationId, 'AccuracyValidation'])}
                        uniqueName={gridName} />
                }
                
            </Modal>
        );
    }
}

export default ValidationChangeLogModal;