import './VersionEditor.scss';
import { buildClassName } from '../../../infrastructure/helpers/html/htmlUtils';
import React from 'react';
import VersionDisplay from './VersionDisplay';
import VersionDisplayProps from './VersionDisplayProps';

interface Props extends VersionDisplayProps {
    className?: string;
    onChange: (majorIncremented: boolean) => void;
}

class VersionEditor extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>): void {

        this.props.onChange(e.target.checked);
    }

    render(): JSX.Element {
        return (
            <div className={buildClassName('version-editor', this.props.className)}>

                <VersionDisplay version={this.props.version} majorIncremented={this.props.majorIncremented} />
                <label>
                    <input
                        type="checkbox"
                        checked={this.props.majorIncremented}
                        onChange={this.onChange} />
                    <span>Increment major version</span>
                </label>
            </div>
        );
    }
}

export default VersionEditor;