import { buildFunctions, convertSelectOptionToSelectSearchOption, createOption, overloadOptions } from './FunctionBuilder.Helpers';
import BaseOperandProps from '../Props/BaseOperandProps';
import CustomDataSourceLegitimacy from '../../../../infrastructure/types/Functions/CustomDataSourceLegitimacy';
import FirdsResultSetBuilder from '../FirdsResultSet/FirdsResultSetBuilder';
import FunctionDescription from './FunctionDescription/FunctionDescription';
import functionHelper from '../../../../infrastructure/helpers/functions/function/functionHelper';
import functionName from '../../../../infrastructure/constants/functions/definition/functionName';
import functionOptions from '../../../../infrastructure/constants/functions/definition/functionOptions';
import LeiResultSetBuilder from '../LeiResultSet/LeiResultSetBuilder';
import NumberRange from '../../../Common/NumberRange/NumberRange';
import Operand from '../../../../types/functions/Operand/Operand';
import OverloadCheckbox from './OverloadCheckbox';
import OverloadRadio from './OverloadRadio';
import pseudoFunction from '../../../../infrastructure/constants/functions/pseudoFunction';
import pseudoFunctionHelper from '../../../../infrastructure/helpers/functions/function/pseudoFunctionHelper';
import React from 'react';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import IsinsSetBuilder from '../FirdsResultSet/IsinsSetBuilder';
import LeiSetBuilder from '../LeiResultSet/LeiSetBuilder';
import operandType from '../../../../infrastructure/constants/functions/operandType';
import SelectSearch, { SelectedOptionValue } from 'react-select-search';
import ExchangeRatesResultSetBuilder from '../ExchangeRatesResultSet/ExchangeRatesResultSetBuilder';
import ExchangeRatesSetBuilder from '../ExchangeRatesResultSet/ExchangeRatesSetBuilder';
import Selector from '../../../../types/report/Selector';
import locationType from '../../../../types/functions/Location/LocationType';
import userDefinedFunctionHelper from '../../../../infrastructure/helpers/functions/userDefinedFunctions/userDefinedFunctionHelper';
import repeat from '../../../../infrastructure/helpers/common/array/repeat';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import operandHelper from '../../../../infrastructure/helpers/functions/operand/operandHelper';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';

type Props =
    BaseOperandProps &
    {
        customDataSourceLegitimacy: CustomDataSourceLegitimacy;
        pseudoFunction: string | null;
        isinSelectors: Selector[];
        leiSelectors: Selector[];
        exchangeRatesSelectors: Selector[];
        userDefinedFunctions: UserDefinedFunction[];
        location: ReportLogicLocation;
    };

class FunctionBuilder extends React.Component<Props> {
    private maxArrayArgumentCount: number;
    private firdsPseudoFunctionInitialized: boolean;
    private firdsPseudoFunction: Operand;
    private leiPseudoFunction: Operand;
    private exchangeRatesPseudoFunction: Operand;
    constructor(props: Props) {
        super(props);

        this.maxArrayArgumentCount = 50;

        this.onFunctionChange = this.onFunctionChange.bind(this);
        this.onFirdsDataSourceChange = this.onFirdsDataSourceChange.bind(this);
        this.onIsinSelectorChange = this.onIsinSelectorChange.bind(this);
        this.onLeiSelectorChange = this.onLeiSelectorChange.bind(this);
        this.onExchangeRatesSelectorChange = this.onExchangeRatesSelectorChange.bind(this);
        this.onArgumentCountChange = this.onArgumentCountChange.bind(this);

        this.mapToSelectListFunction = this.mapToSelectListFunction.bind(this);

        this.isArrayFunction = this.isArrayFunction.bind(this);
        this.getMinArrayArgumentCount = this.getMinArrayArgumentCount.bind(this);
        this.countArrayArguments = this.countArrayArguments.bind(this);
        this.mapOption = this.mapOption.bind(this);

        this.firdsPseudoFunctionInitialized = false

        this.firdsPseudoFunction = pseudoFunctionHelper.buildSpecial(operandType.firdsResultSetEsma, this.props.isinSelectors.length === 0 ? 0 : this.props.isinSelectors[0].number);
        this.leiPseudoFunction = pseudoFunctionHelper.buildSpecial(operandType.leiResultSetLevel1, this.props.leiSelectors.length === 0 ? 0 : this.props.leiSelectors[0].number);
        this.exchangeRatesPseudoFunction = pseudoFunctionHelper.buildSpecial(operandType.exchangeRatesResultSet, this.props.exchangeRatesSelectors.length === 0 ? 0 : this.props.exchangeRatesSelectors[0].number);
        this.onFunctionitemChange = this.onFunctionitemChange.bind(this);

        this.qualifies = this.qualifies.bind(this);
    }

    onFunctionChange(value: string): void {

        let changes: Operand;

        if (pseudoFunctionHelper.isPseudoFunction(value)) {

            let dataSourceType = pseudoFunctionHelper.resolveDefaultDataSourceType(value);

            changes = pseudoFunctionHelper.build(dataSourceType);

            if (value === pseudoFunction.firds) {
                this.props.onOperandChange(this.firdsPseudoFunction, { doNotPopulate: false, pseudoFunction: pseudoFunction.firds });
                return;
            }
            else if (value === pseudoFunction.lei) {
                this.props.onOperandChange(this.leiPseudoFunction, { doNotPopulate: false, pseudoFunction: pseudoFunction.lei });
                return;
            }
            else if (value === pseudoFunction.exchangeRates) {
                this.props.onOperandChange(this.exchangeRatesPseudoFunction, { doNotPopulate: false, pseudoFunction: pseudoFunction.exchangeRates });
                return;
            }
        }
        else if (typeHelper.isNumber(parseInt(value))) {

            let func = userDefinedFunctionHelper.getByNumber(this.props.userDefinedFunctions, parseInt(value));

            changes = {
                operandType: operandType.userDefinedFunction,
                functionNumber: func.number,
                function: ''
            } as Operand;

            changes.arguments = repeat(func.parameters.length, operandHelper.getEmpty);
        }
        else {

            changes = {
                operandType: operandType.function,
                function: value,
                functionNumber: null
            } as Operand;

            if (value) {
                changes.arguments = functionHelper.getEmptyArguments(value, null);
            }
        }

        this.props.onOperandChange(changes, { doNotPopulate: false, pseudoFunction: pseudoFunctionHelper.isPseudoFunction(value) ? value : null });
    }

    onFirdsDataSourceChange(value: string): void {

        if (!this.firdsPseudoFunctionInitialized) {
            this.firdsPseudoFunction = pseudoFunctionHelper.buildSpecial(value, 1);
            this.firdsPseudoFunctionInitialized = true;
        }

        (this.firdsPseudoFunction.arguments as Operand[])[0].operandType = value;

        this.props.onOperandChange(this.firdsPseudoFunction, { doNotPopulate: false, pseudoFunction: pseudoFunction.firds });
    }

    onIsinSelectorChange(value: string): void {

        if (!this.firdsPseudoFunctionInitialized) {
            this.firdsPseudoFunction = pseudoFunctionHelper.buildSpecial(operandType.firdsResultSetEsma, +value);
            this.firdsPseudoFunctionInitialized = true;
        }

        (this.firdsPseudoFunction.arguments as Operand[])[0].selectorNumber = +value;

        this.props.onOperandChange(this.firdsPseudoFunction, { doNotPopulate: false, pseudoFunction: pseudoFunction.firds });
    }

    onLeiSelectorChange(value: string): void {

        let changes = pseudoFunctionHelper.buildSpecial(operandType.leiResultSetLevel1, +value);

        this.props.onOperandChange(changes, { doNotPopulate: false, pseudoFunction: pseudoFunction.lei });
    }

    onExchangeRatesSelectorChange(value: string): void {

        let changes = pseudoFunctionHelper.buildSpecial(operandType.exchangeRatesResultSet, +value);

        this.props.onOperandChange(changes, { doNotPopulate: false, pseudoFunction: pseudoFunction.exchangeRates });
    }

    onArgumentCountChange(value: number): void {

        let changes = {} as Operand;

        changes.arguments = functionHelper.getEmptyArguments(this.props.operand.function, value);

        this.props.onOperandChange(changes);
    }

    mapToSelectListFunction(func: string, number: number | null): string {

        if (typeHelper.isNumber(number)) {

            return `${number}`;
        }

        if (typeHelper.isString(this.props.pseudoFunction)) {

            return this.props.pseudoFunction as string;
        }

        if (stringHelper.isEmpty(func)) {

            return func;
        }

        let try1 = functionOptions.find(x => x.value === func);

        if (typeHelper.isObject(try1)) {

            return (try1 as SelectOption).value;
        }

        let try2 = functionOptions.find(x => func.startsWith(x.value));

        if (typeHelper.isObject(try2)) {

            return (try2 as SelectOption).value;
        }

        let try3 = functionOptions.find(x => x.value.startsWith(func));

        return (try3 as SelectOption).value;
    }

    isArrayFunction(): boolean {

        let isFunction = stringHelper.isNonEmpty(this.props.operand.function);
        if (!isFunction) {
            return false;
        }

        return functionHelper.isArrayFunction(this.props.operand.function);
    }

    getMinArrayArgumentCount(): number {

        let isFunction = stringHelper.isNonEmpty(this.props.operand.function);
        if (!isFunction) {
            return 0;
        }

        return functionHelper.getMinArrayArgumentCount(this.props.operand.function);
    }

    countArrayArguments(): number {

        let isFunction = stringHelper.isNonEmpty(this.props.operand.function);
        if (!isFunction) {
            return 0;
        }

        if (!functionHelper.isArrayFunction(this.props.operand.function)) {
            return 0;
        }

        return (this.props.operand.arguments as Operand[]).length - functionHelper.getNonArrayArgumentCount(this.props.operand.function);
    }

    mapOption(option: SelectOption): SelectOption {

        switch (option.value) {
            case pseudoFunction.firds:
            case functionName.activeOnTradingDate:
                return { value: option.value, text: option.text, disabled: !this.props.customDataSourceLegitimacy.isin };

            case pseudoFunction.lei:
                return { value: option.value, text: option.text, disabled: !this.props.customDataSourceLegitimacy.lei };

            case pseudoFunction.exchangeRates:
                return { value: option.value, text: option.text, disabled: !this.props.customDataSourceLegitimacy.exchangeRates };

            case pseudoFunction.annaDsbUpiEnrichment:
                return { value: option.value, text: option.text, disabled: !this.props.customDataSourceLegitimacy.annaDsbUpiEnrichment };

            default:
                return option;
        }
    }

    onFunctionitemChange(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {
        this.onFunctionChange(selectedValue.toString());
    }

    qualifies(func: UserDefinedFunction): boolean {

        if (this.props.location.statement === locationType.statement.userDefinedFunction) {

            return this.props.location.statementNumber > func.number;
        }

        return true;
    }

    render(): JSX.Element {

        let userDefinedOptions = this.props.userDefinedFunctions.filter(this.qualifies).map(x => createOption(x));

        return (
            <>
                <SelectSearch
                    options={buildFunctions(functionOptions.concat(userDefinedOptions).map(this.mapOption).map(convertSelectOptionToSelectSearchOption))}
                    onChange={this.onFunctionitemChange}
                    value={this.mapToSelectListFunction(this.props.operand.function, this.props.operand.functionNumber)}
                    search={true}
                />

                <OverloadRadio
                    options={overloadOptions.toArray}
                    function={this.props.operand.function}
                    name="to-array-overloads"
                    onChange={this.onFunctionChange} />

                <OverloadRadio
                    options={overloadOptions.toDateTime}
                    function={this.props.operand.function}
                    name="to-dateTime-overloads"
                    onChange={this.onFunctionChange} />

                <OverloadRadio
                    options={overloadOptions.toString}
                    function={this.props.operand.function}
                    name="to-string-overloads"
                    onChange={this.onFunctionChange} />

                <OverloadRadio
                    options={overloadOptions.in}
                    function={this.props.operand.function}
                    name="in-overloads"
                    onChange={this.onFunctionChange} />

                <OverloadRadio
                    options={overloadOptions.round}
                    function={this.props.operand.function}
                    name="round-overloads"
                    onChange={this.onFunctionChange} />

                <OverloadCheckbox
                    function={this.props.operand.function}
                    true={functionName.substringLength}
                    false={functionName.substring}
                    label="Include length"
                    onChange={this.onFunctionChange} />

                <OverloadCheckbox
                    function={this.props.operand.function}
                    true={functionName.replaceIgnoreCase}
                    false={functionName.replace}
                    label="Include ignoreCase"
                    onChange={this.onFunctionChange} />

                <OverloadCheckbox
                    function={this.props.operand.function}
                    true={functionName.countHasValue}
                    false={functionName.count}
                    label="Particular field"
                    onChange={this.onFunctionChange} />

                <OverloadCheckbox
                    function={this.props.operand.function}
                    true={functionName.concatenateDelimiter}
                    false={functionName.concatenate}
                    label="Add delimiter"
                    onChange={this.onFunctionChange} />

                <OverloadRadio
                    options={overloadOptions.addDays}
                    function={this.props.operand.function}
                    name="add-days-overloads"
                    onChange={this.onFunctionChange} />

                <OverloadRadio
                    options={overloadOptions.all}
                    function={this.props.operand.function}
                    name="all-overloads"
                    onChange={this.onFunctionChange} />

                <OverloadRadio
                    options={overloadOptions.any}
                    function={this.props.operand.function}
                    name="any-overloads"
                    onChange={this.onFunctionChange} />

                <OverloadRadio
                    options={overloadOptions.first}
                    function={this.props.operand.function}
                    name="first-overloads"
                    onChange={this.onFunctionChange} />

                <OverloadRadio
                    options={overloadOptions.last}
                    function={this.props.operand.function}
                    name="last-overloads"
                    onChange={this.onFunctionChange} />

                {
                    this.props.pseudoFunction === pseudoFunction.firds &&
                    <FirdsResultSetBuilder
                        value={(this.props.operand.arguments as Operand[])[0].operandType}
                        onChange={this.onFirdsDataSourceChange} />
                }
                {
                    this.props.pseudoFunction === pseudoFunction.firds &&
                    <IsinsSetBuilder
                        options={this.props.isinSelectors}
                        value={(this.props.operand.arguments as Operand[])[0].selectorNumber}
                        onChange={this.onIsinSelectorChange} />
                }
                {
                    this.props.pseudoFunction === pseudoFunction.lei &&
                    <LeiResultSetBuilder value={(this.props.operand.arguments as Operand[])[0].operandType} />
                }
                {
                    this.props.pseudoFunction === pseudoFunction.lei &&
                    <LeiSetBuilder
                        options={this.props.leiSelectors}
                        value={(this.props.operand.arguments as Operand[])[0].selectorNumber}
                        onChange={this.onLeiSelectorChange} />
                }
                {
                    this.props.pseudoFunction === pseudoFunction.exchangeRates &&
                    <ExchangeRatesResultSetBuilder value={(this.props.operand.arguments as Operand[])[0].operandType} />
                }
                {
                    this.props.pseudoFunction === pseudoFunction.exchangeRates &&
                    <ExchangeRatesSetBuilder
                        options={this.props.exchangeRatesSelectors}
                        value={(this.props.operand.arguments as Operand[])[0].selectorNumber}
                        onChange={this.onExchangeRatesSelectorChange} />
                }
                {
                    (stringHelper.isNonEmpty(this.props.operand.function) ||
                        typeHelper.isNumber(this.props.operand.functionNumber)) &&
                    !typeHelper.isString(this.props.pseudoFunction) &&
                    <div className="mt-2">
                        <FunctionDescription
                            func={this.props.operand.function}
                            number={this.props.operand.functionNumber}
                            userDefinedFunctions={this.props.userDefinedFunctions} >
                            {
                                this.isArrayFunction() &&
                                <tr>
                                    <td>Arguments:</td>
                                    <td>
                                        <NumberRange
                                            min={this.getMinArrayArgumentCount()}
                                            max={this.maxArrayArgumentCount}
                                            value={this.countArrayArguments()}
                                            onChange={this.onArgumentCountChange}
                                            displayState={value => `(${value}) `} />
                                    </td>
                                </tr>
                            }
                        </FunctionDescription>
                    </div>
                }
            </>
        );
    }
}

export default FunctionBuilder;
