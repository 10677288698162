import './QuickActions.scss';
import PromiseButton from '../PromiseButton';
import React from 'react';

interface Props {
    isWaiting: boolean;
    isReadOnly: boolean;
    onSaveClick: () => Promise<any>;
    onSaveAndExitClick: () => Promise<any>;
    onExitClick: () => void;
}

class QuickActions extends React.Component<Props> {

    render(): JSX.Element {
        return (
            <div className="quick-actions-navigation">
                <div className="row no-gutters">
                    {this.props.children}
                    {
                        !this.props.isWaiting &&
                        !this.props.isReadOnly &&
                        <div className="col">
                            <PromiseButton
                                text="Save"
                                className="btn cb-btn"
                                enableOnErrorOnly={true}
                                task={this.props.onSaveClick} />

                            <PromiseButton
                                text="Save And Exit"
                                className="btn cb-btn"
                                enableOnErrorOnly={true}
                                task={this.props.onSaveAndExitClick} />

                            <button className="btn btn-light" onClick={this.props.onExitClick}>Exit</button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default QuickActions;
