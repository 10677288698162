import { getColumns } from './SystemFieldDefinitions.Grid';
import { getRemoveMessage } from '../FieldDefinitions.Helpers';
import dataSourceHelper from '../../../../infrastructure/helpers/functions/common/dataSourceHelper';
import dataType from '../../../../infrastructure/constants/dataType';
import FieldDefinitionProps from '../FieldDefinitionProps';
import React from 'react';
import systemField from '../../../../infrastructure/constants/ingestion/systemField';
import systemFieldHelper from '../../../../infrastructure/helpers/ingestion/systemFieldHelper';
import UiDataGrid from '../../../Common/DataGrid/UiDataGrid/UiDataGrid';
import WarningModal from '../../../Common/Modals/WarningModal/WarningModal';

class SystemFieldDefinitions extends React.Component<FieldDefinitionProps, { showWarningModal: boolean, message: string, index: number }> {

    constructor(props: FieldDefinitionProps) {

        super(props);

        this.state = {
            index: 0,
            message: '',
            showWarningModal: false
        }

        this.addSystemField = this.addSystemField.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onRemoveConfirmed = this.onRemoveConfirmed.bind(this);
        this.onAddSystemFieldDateTimeFromFilenameClick = this.onAddSystemFieldDateTimeFromFilenameClick.bind(this);
        this.onAddSystemFieldFileNameFromFileClick = this.onAddSystemFieldFileNameFromFileClick.bind(this);
    }

    onAddSystemFieldDateTimeFromFilenameClick(): void {
        this.addSystemField(systemField.dateTimeFromFilename, dataType.dateTime);
    }

    onAddSystemFieldFileNameFromFileClick(): void {
        this.addSystemField(systemField.fileNameFromFile, dataType.string);
    }

    addSystemField(fieldName: string, dataType: string): void {

        let definition = dataSourceHelper.getEmptyFieldDefinition();

        definition.name = fieldName;
        definition.type = dataType;

        this.props.definitions.push(definition);

        this.props.onChange(this.props.definitions);
    }

    onRemoveClick(i: number, name: string): void {

        let index = this.props.definitions.findIndex(x => systemFieldHelper.isSystemField(x.name) && name === x.name);

        let definition = this.props.definitions[index];

        this.setState({ message: getRemoveMessage(definition), index, showWarningModal: true });
    }

    onRemoveConfirmed() {

        this.props.definitions.splice(this.state.index, 1);

        this.props.onChange(this.props.definitions);

        this.setState({ showWarningModal: false });
    }

    render(): JSX.Element {
        return (
            <>
                <UiDataGrid
                    columns={getColumns(this.props.isReadOnly, this.onRemoveClick)}
                    data={this.props.definitions.filter(x => systemFieldHelper.isSystemField(x.name))}
                    getKey={x => x.name} />
                {
                    !this.props.isReadOnly &&
                    <div className="mt-4">
                        <button
                            type="button"
                            className="btn btn-light"
                            disabled={this.props.definitions.some(x => x.name === systemField.dateTimeFromFilename)}
                            onClick={this.onAddSystemFieldDateTimeFromFilenameClick}>+ Field '{systemField.dateTimeFromFilename}'</button>
                    </div>
                }
                {
                    !this.props.isReadOnly &&
                    <div className="mt-4">
                        <button
                            type="button"
                            className="btn btn-light"
                            disabled={this.props.definitions.some(x => x.name === systemField.fileNameFromFile)}
                            onClick={this.onAddSystemFieldFileNameFromFileClick}>+ Field '{systemField.fileNameFromFile}'</button>
                    </div>
                }
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onRemoveConfirmed}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Remove definition'
                        message={this.state.message} />
                }
            </>
        );
    }
}

export default SystemFieldDefinitions;
