import appRoutes from '../../../infrastructure/constants/appRoutes';

const message = 'The configuration has not been saved. All changes will be lost';

const unsafeRoutes = [
    appRoutes.ingestion.fieldDefinition,
    appRoutes.ingestion.details,
    appRoutes.ingestion.signoff
];

const isNavigationSafe = (route: string): boolean => !unsafeRoutes.includes(route);

export { message, isNavigationSafe };
