import { getColumns } from './EndpointFilterBuilder.Grid';
import BespokeConditionBuilder from '../../Functions/Bespoke/BespokeConditionBuilder';
import ConditionBuilder from '../../Functions/Condition/ConditionBuilder';
import conditionDisplayHelper from '../../../infrastructure/helpers/functions/condition/conditionDisplayHelper';
import EndpointFilter from '../../../types/report/EndpointFilter';
import ErrorList from '../../Common/ErrorList/ErrorList';
import React from 'react';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import StatementBuilderProps from '../../Functions/Statement/StatementBuilderProps';
import Condition from '../../../types/functions/Condition/Condition';

class EndpointFilterBuilder extends React.Component<StatementBuilderProps<EndpointFilter>> {
    constructor(props: StatementBuilderProps<EndpointFilter>) {
        super(props);

        this.onNameChange = this.onNameChange.bind(this);
        this.onBusinessDescriptionChange = this.onBusinessDescriptionChange.bind(this);
        this.onBespokeToggleClick = this.onBespokeToggleClick.bind(this);
        this.getConditionContent = this.getConditionContent.bind(this);
    }

    onNameChange(name: string, number: number): void {

        this.props.onStatementChange({ number: number, name: name } as EndpointFilter);
    }

    onBusinessDescriptionChange(businessDescription: string, number: number): void {

        this.props.onStatementChange({ number: number, businessDescription: businessDescription } as EndpointFilter);
    }

    onBespokeToggleClick(isBespoke: boolean, filter: EndpointFilter): void {

        let changes = { number: filter.number, isBespoke: isBespoke } as EndpointFilter;

        if (isBespoke) {
            changes.bespokeCondition = conditionDisplayHelper.buildConditions(filter.condition, [], [], [], [], this.props.collections.reportFields, null as any);
        }

        this.props.onStatementChange(changes);
    }

    onReorderConditions(condition: Condition, filter: EndpointFilter) {

        filter.condition = condition;
        this.props.onStatementChange(filter);
    }

    getConditionContent(filter: EndpointFilter): JSX.Element {

        if (filter.isBespoke) {
            return (
                <BespokeConditionBuilder
                    keyword={this.props.details.keyword}
                    keyword2={this.props.details.keyword2}
                    statement={filter}
                    onChange={this.props.onStatementChange}
                    isReadOnly={this.props.isReadOnly} />
            );
        }

        return (
            <>
                <ConditionBuilder
                    isReadOnly={this.props.isReadOnly}
                    allowCopy={true}
                    keyword={this.props.details.keyword}
                    functions={this.props.reportConfig.userDefinedFunctions}
                    lookups={this.props.reportConfig.lookups}
                    dictionaries={this.props.reportConfig.dictionaries}
                    variables={this.props.reportConfig.variables}
                    dataSources={this.props.collections.dataSources}
                    reportFields={this.props.collections.reportFields}
                    customDataSourceFields={null as any}
                    location={{ statement: this.props.details.statement, statementNumber: filter.number } as ReportLogicLocation}
                    condition={filter.condition}
                    onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                    onParenthesesChange={this.props.onParenthesesChange}
                    onAddConditionClick={this.props.onAddConditionClick}
                    onRemoveConditionClick={this.props.onRemoveConditionClick}
                    onComparisonTypeChange={this.props.onComparisonTypeChange}
                    onOperandClick={this.props.onOperandClick}
                    onCopyClick={this.props.onCopyClick}
                    onPasteClick={this.props.onPasteClick}
                    allowPaste={this.props.allowPaste}
                    onReorderConditions={(condition) => { this.onReorderConditions(condition, filter); }} />

                <div className="code-block">
                    <span className="code">{this.props.details.keyword2}</span>
                </div>

                <ErrorList errors={filter.errors} className="mt-2" />
            </>
        );
    }

    render(): JSX.Element {
        return (
            <ReportLogicList
                isWaiting={this.props.isWaiting}
                items={this.props.statements}
                columns={getColumns(this.onNameChange, this.onBusinessDescriptionChange, this.onBespokeToggleClick, this.props.onRemoveStatementClick, this.props.reorder, this.props.isReadOnly)}
                getContent={this.getConditionContent}
                getKey={f => f.number}
                createHtmlId={reportConfigNavigation.buildEndpointFilterId} />
        );
    }
}

export default EndpointFilterBuilder;
