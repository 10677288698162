import { AxiosResponse } from 'axios';
import { Unsubscribe } from 'redux';
import actions from '../../../store/actions';
import clear from '../../../infrastructure/helpers/common/array/clear';
import ComplianceUrlEditModal from '../../Common/Modals/ComplianceUrlEditModal/ComplianceUrlEditModal';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import React from 'react';
import ReportFieldDefinition from '../../../types/report/ReportFieldDefinition';
import ReportFieldInstructionEditModal from '../../Common/Modals/ReportFieldInstructionEditModal/ReportFieldInstructionEditModal';
import ReportFields from '../ReportFields/ReportFields';
import ReportRegimeCounts from '../../../types/report/ReportRegimeCounts';
import Spinner from '../../Common/Spinner/Spinner';
import store from '../../../store/store';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';

interface State {
    regime: string;
    counts: ReportRegimeCounts;
    isWaiting: boolean;
}

class MasterConfigurationHomePage extends React.Component<any, State> {
    private unsubscribe: Unsubscribe | undefined;
    private fields: ReportFieldDefinition[];

    constructor(props: any) {
        super(props);

        this.fields = [];

        this.state = {
            regime: this.getRegimeFromQuery(),
            counts: { urlCounts: {} },
            isWaiting: true
        };

        this.refreshCountsListener = this.refreshCountsListener.bind(this);
        this.loadFields = this.loadFields.bind(this);
        this.loadCounts = this.loadCounts.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onGetFieldSuccess = this.onGetFieldSuccess.bind(this);
        this.onGetCountSuccess = this.onGetCountSuccess.bind(this);
        this.navigateToVersions = this.navigateToVersions.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.refreshCountsListener);

        this.loadData();
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    componentDidUpdate(): void {

        let regime = this.getRegimeFromQuery();
        if (regime === this.state.regime) {
            return;
        }

        this.setState({ regime: regime, isWaiting: true }, this.loadData);
    }

    refreshCountsListener(): void {
        let state = store.getState();

        if (state.action !== actions.complianceUrlEditModal.saveSuccess) {
            return;
        }

        this.loadCounts();
    }

    loadFields(): void {

        let url = urlHelper.buildUrl('report-field', { regime: this.state.regime });

        httpClient.get<ReportFieldDefinition[]>(url).then(this.onGetFieldSuccess);
    }

    loadCounts(): void {

        let url = urlHelper.buildUrl('report-field/counts', { regime: this.state.regime });

        httpClient.get<ReportRegimeCounts>(url).then(this.onGetCountSuccess);
    }

    loadData(): void {
        this.loadFields();
        this.loadCounts();
    }

    onGetFieldSuccess(response: AxiosResponse<ReportFieldDefinition[]>): void {

        clear(this.fields);

        response.data.forEach(f => this.fields.push(f));

        this.setState({ isWaiting: false });
    }

    onGetCountSuccess(response: AxiosResponse<ReportRegimeCounts>): void {

        this.setState({ counts: response.data });
    }

    getRegimeFromQuery(): string {

        return urlHelper.deserializeQuery(history.location.search)['regime'];
    }

    navigateToVersions(): void {

        let url = urlHelper.buildUrl('master-configuration/versions', { regime: this.state.regime });

        history.push(url);
    }

    render(): JSX.Element {

        return (
            <>
                <h3 className="mb-3">
                    <span>Master Configuration</span>
                    <button className="btn cb-btn float-right" onClick={this.navigateToVersions}>
                        <span>Version Management</span>
                    </button>
                </h3>

                {
                    this.state.isWaiting ?
                        <Spinner /> :
                        <ReportFields fields={this.fields} counts={this.state.counts} regime={this.state.regime} />

                }

                <ReportFieldInstructionEditModal regime={this.state.regime} updateState={this.loadFields} />
                <ComplianceUrlEditModal />
            </>
        );
    }
}

export default MasterConfigurationHomePage;
