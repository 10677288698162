import separatedValueHelper from '../../../infrastructure/helpers/ingestion/separatedValueHelper';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

const validate = (token: string): string | null => {

    if (stringHelper.isEmpty(token)) {

        return 'Empty value token is required';
    }

    return null;
};

const validateEmptyValueTokens = (tokes: string): string | null => {

    if (!typeHelper.isString(tokes)) {

        return null;
    }

    if (stringHelper.isEmpty(tokes)) {

        return null;
    }

    let items = separatedValueHelper.split(tokes);

    for (let i = 0; i < items.length; i++) {

        let error = validate(items[i]);
        if (error) {

            return error;
        }
    }

    return null;
};

export default validateEmptyValueTokens;
