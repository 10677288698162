import { getColumns } from './SelectorBuilder.Grid';
import AssignmentBuilder from '../../Functions/Assignment/AssignmentBuilder';
import pseudocodeHelper from '../../../infrastructure/helpers/functions/common/pseudocodeHelper';
import React from 'react';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import ReportConfigComponentLock from '../../../types/report/ReportConfigComponentLock';
import editReportConfigHelper from '../../../infrastructure/helpers/report/editReportConfigHelper';
import reorderHelper from '../../../infrastructure/helpers/common/reorderHelper';
import Operand from '../../../types/functions/Operand/Operand';
import Selector from '../../../types/report/Selector';
import { buildId } from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation.Helpers';
import SelectorProps from './SelectorProps';
import ComponentType from '../../../types/report/ComponentType';
import locationType from '../../../types/functions/Location/LocationType';
import guidance from '../ReportConfigEditor/ReportConfigEditor.Guidance';
import isin from '../../../infrastructure/constants/functions/isin';
import lei from '../../../infrastructure/constants/functions/lei';
import exchangeRates from '../../../infrastructure/constants/functions/exchangeRates';
import annaDsbUpiEnrichment from '../../../infrastructure/constants/functions/annaDsbUpiEnrichment';
import { setEmptySelectorKeysByComponentType } from './Selector.Helpers';
import actions from '../../../store/actions';
import selectorsStore from '../../../store/selectorsStore';
import CacheKey from '../../../types/report/CacheKey';
import annaDsbUpi from '../../../infrastructure/constants/functions/annaDsbUpi';
import lse from '../../../infrastructure/constants/functions/lse';
import firdsFcaInstrument from '../../../infrastructure/constants/functions/firdsFcaInstrument';
import firdsEsmaInstrument from '../../../infrastructure/constants/functions/firdsEsmaInstrument';
import fcaRegulatedEntities from '../../../infrastructure/constants/functions/fcaRegulatedEntities';

interface State {
    locationTypeStatement: string,
    selectors: Selector[],
    info: string,
    selectorDataType: string,
    fieldNameSuffix: string,
    fieldNameSuffixes: Map<string, string>
}

class SelectorBuilder extends React.Component<SelectorProps, State> {

    constructor(props: SelectorProps) {
        super(props);

        this.state = {
            locationTypeStatement: '',
            selectors: [],
            info: '',
            selectorDataType: '',
            fieldNameSuffix: '',
            fieldNameSuffixes: new Map()
        }
        this.onNameChange = this.onNameChange.bind(this);
        this.onRemoveSelectorClick = this.onRemoveSelectorClick.bind(this);
        this.onResetAssignmentClick = this.onResetAssignmentClick.bind(this);
        this.getAssignmentContent = this.getAssignmentContent.bind(this);
        this.onLockSectionClick = this.onLockSectionClick.bind(this);
        this.tryLock = this.tryLock.bind(this);
    }

    componentDidMount() {
        this.determinateSelector();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps !== this.props) {
            this.determinateSelector();
        }
    }

    determinateSelector() {

        let nameSuffixes;

        switch (this.props.componentType) {
            case ComponentType.IsinSelector: this.setState({ selectorDataType: isin.type, locationTypeStatement: locationType.statement.isinSelector, info: guidance.isinSelectors, selectors: this.props.reportConfig.isinSelectors }); break;

            case ComponentType.LeiSelector: this.setState({ selectorDataType: lei.type, locationTypeStatement: locationType.statement.leiSelector, info: guidance.leiSelectors, selectors: this.props.reportConfig.leiSelectors }); break;

            case ComponentType.ExchangeRatesSelector: this.setState({ selectorDataType: exchangeRates.type, locationTypeStatement: locationType.statement.exchangeRatesSelector, info: guidance.exchangeRatesSelectors, selectors: this.props.reportConfig.exchangeRatesSelectors }); break;

            case ComponentType.AnnaDsbUpiEnrichmentSelector: this.setState({
                selectorDataType: annaDsbUpiEnrichment.type, locationTypeStatement: locationType.statement.annaDsbUpiEnrichmentSelector, info: guidance.annaDsbUpiEnrichmentSelectors,
                selectors: setEmptySelectorKeysByComponentType(this.props.componentType, this.props.reportConfig.annaDsbUpiEnrichmentSelectors.slice())
            }); break;

            case ComponentType.AnnaDsbUpiSelector: this.setState({ selectorDataType: annaDsbUpi.type, locationTypeStatement: locationType.statement.annaDsbUpiSelector, info: guidance.annaDsbUpiSelectors, selectors: this.props.reportConfig.annaDsbUpiSelectors }); break;

            case ComponentType.LSESelector: this.setState({ selectorDataType: lse.type, locationTypeStatement: locationType.statement.lseSelector, info: guidance.lseSelectors, selectors: this.props.reportConfig.lseSelectors }); break;

            case ComponentType.FCARegulatedEntitiesSelector: this.setState({ selectorDataType: fcaRegulatedEntities.type, locationTypeStatement: locationType.statement.fcaRegulatedEntitiesSelector, info: guidance.fcaRegulatedEntitiesSelectors, selectors: this.props.reportConfig.fcaRegulatedEntitiesSelectors }); break;

            case ComponentType.FirdsFcaInstrumentSelectors: nameSuffixes = new Map(this.state.fieldNameSuffixes); nameSuffixes.set('InstrumentName', 'startsWith'); this.setState({
                fieldNameSuffixes: nameSuffixes,
                selectorDataType: firdsFcaInstrument.type, locationTypeStatement: locationType.statement.firdsFcaInstrumentSelector, info: guidance.firdsFcaInstrumentSelectors,
                selectors: setEmptySelectorKeysByComponentType(this.props.componentType, this.props.reportConfig.firdsFcaInstrumentSelectors.slice())
            }); break;

            case ComponentType.FirdsEsmaInstrumentSelectors: nameSuffixes = new Map(this.state.fieldNameSuffixes); nameSuffixes.set('InstrumentName', 'startsWith'); this.setState({
                fieldNameSuffixes: nameSuffixes,
                selectorDataType: firdsEsmaInstrument.type, locationTypeStatement: locationType.statement.firdsEsmaInstrumentSelector, info: guidance.firdsEsmaInstrumentSelectors,
                selectors: setEmptySelectorKeysByComponentType(this.props.componentType, this.props.reportConfig.firdsEsmaInstrumentSelectors.slice())
            }); break;

            default: break;
        }
    }

    onNameChange(name: string, number: number): void {

        let location = this.getLocation(number);

        this.props.onDeclarationChange(location, { name: name } as Selector);
    }

    async onRemoveSelectorClick(number: number, event: React.MouseEvent<HTMLElement>): Promise<void> {

        const isLocked = await this.tryLock(number);
        if (!isLocked) {
            let location = this.getLocation(number);

            let changes = {} as Selector;

            let error = this.props.validateRemoveDeclaration(location);
            if (error) {

                changes.errors = [error];

                this.props.onDeclarationChange(location, changes);

                return;
            }
            let changedSelectors: Selector[] = [];
            this.props.onRemoveDeclarationClick(location, (selectors) => {
                selectors = reorderHelper.rearangeElementsWhenRemoveOne(selectors) as Selector[];

                reorderHelper.changeReferences(this.props.reportConfig,
                    selectors, (operand: Operand, oldAndNewNumbers: Map<number, number>) => {
                        if (operand.selectorNumber && oldAndNewNumbers.has(operand.selectorNumber)) {
                            operand.selectorNumber = oldAndNewNumbers.get(operand.selectorNumber) as number;
                        }
                    }
                );
                changedSelectors = selectors;
            });
            this.setState({ selectors: changedSelectors });
        }
    }

    async onResetAssignmentClick(location: ReportLogicLocation, selector: Selector, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        const isLocked = await this.tryLock(undefined, selector);
        if (!isLocked) {
            this.props.onResetAssignmentClick(location, false, event, reportConfigComponentLock);
        }
    }

    async onRemoveAssignmentClick(location: ReportLogicLocation, selector: Selector, isSingle?: boolean, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        const isLocked = await this.tryLock(undefined, selector);
        if (!isLocked) {
            this.props.onRemoveAssignmentClick(location, isSingle, event, reportConfigComponentLock);
        }
    }

    getLocation(number: number): ReportLogicLocation {

        return { statement: this.state.locationTypeStatement, statementNumber: number } as ReportLogicLocation;
    }

    async onLockSectionClick(event: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, number: number): Promise<boolean> {

        return await this.tryLock(number);
    }

    async tryLock(number?: number, selector?: Selector): Promise<boolean> {

        let isLocked = false;
        if (this.props.onComponentContainerClick) {
            let currentSelector = selector || this.state.selectors.find(v => v.number === number);
            if (!currentSelector?.isUsedByCurrentUser) {
                isLocked = await this.props.onComponentContainerClick({ componentId: currentSelector?.id || 0, componentType: this.props.componentType, number: currentSelector?.number || 0 });
            }
        }
        return isLocked;
    }

    getAssignmentContent(selector: Selector): JSX.Element {

        const setSelectorProps = (index: number, sel: Selector, item: CacheKey) => {
            sel.assignment = item.assignment;
            selectorsStore.dispatch({ type: actions.selectors.index, payload: { index } });
        }

        const reportConfigComponentLock: ReportConfigComponentLock = { componentId: selector.id || 0, componentType: this.props.componentType, number: selector.number };
        if (selector.keys) {
            return (
                <>
                    {selector.keys.map((key, index) => {
                        return <AssignmentBuilder
                            key={index}
                            isReadOnly={selector.isReadOnly || this.props.isReadOnly}
                            location={this.getLocation(selector.number)}
                            assignment={key.assignment}
                            fieldName={pseudocodeHelper.wrapInBrackets(key.name)}
                            fieldNameSuffix={this.state.fieldNameSuffixes.get(key.name)}
                            functions={this.props.reportConfig.userDefinedFunctions}
                            lookups={this.props.reportConfig.lookups}
                            dictionaries={this.props.reportConfig.dictionaries}
                            variables={[]}
                            dataSources={this.props.collections.dataSources}
                            reportFields={this.props.collections.reportFields}
                            customDataSourceFields={null as any}
                            onAddAssignmentClick={(location, event) => {
                                setSelectorProps(index, selector, key);
                                this.props.onAddAssignmentClick(location, event, reportConfigComponentLock);
                            }}
                            onRemoveAssignmentClick={(location, isSingle, event) => {
                                setSelectorProps(index, selector, key);
                                this.onRemoveAssignmentClick(location, selector, isSingle, event, reportConfigComponentLock);
                            }}
                            onResetAssignmentClick={(location, isSingle, event) => {
                                setSelectorProps(index, selector, key);
                                this.onResetAssignmentClick(location, selector, event, reportConfigComponentLock);
                            }}
                            onLogicalOperatorChange={(location, operator, event) => {
                                setSelectorProps(index, selector, key);
                                this.props.onLogicalOperatorChange(location, operator, event, reportConfigComponentLock);
                            }}
                            onParenthesesChange={(location, count, type, event) => {
                                setSelectorProps(index, selector, key);
                                this.props.onParenthesesChange(location, count, type, event, reportConfigComponentLock);
                            }}
                            onAddConditionClick={(location, operator, event) => {
                                setSelectorProps(index, selector, key);
                                this.props.onAddConditionClick(location, operator, event, reportConfigComponentLock);
                            }}
                            onRemoveConditionClick={(location, event) => {
                                setSelectorProps(index, selector, key);
                                this.props.onRemoveConditionClick(location, event, reportConfigComponentLock);
                            }}
                            onComparisonTypeChange={(location, value, event) => {
                                setSelectorProps(index, selector, key);
                                this.props.onComparisonTypeChange(location, value, event, reportConfigComponentLock);
                            }}
                            onOperandClick={(location, event) => {
                                setSelectorProps(index, selector, key);
                                this.props.onOperandClick(location, event, reportConfigComponentLock);
                            }}
                            onCopyClick={(location) => {
                                setSelectorProps(index, selector, key);
                                this.props.onCopyClick(location, reportConfigComponentLock);
                            }}
                            onPasteClick={(location, event) => {
                                setSelectorProps(index, selector, key);
                                this.props.onPasteClick(location, event, reportConfigComponentLock);
                            }}
                            onValidateFieldClick={(location, event) => {
                                setSelectorProps(index, selector, key);
                                this.props.onValidateDeclarationClick(location, event, reportConfigComponentLock);
                            }}
                            onClearClick={() => {
                                setSelectorProps(index, selector, key);
                                return this.tryLock(undefined, selector);
                            }}
                            allowPaste={this.props.allowPaste} />
                    }
                    )}
                </>
            )
        }
        else {
            return (
                <div key={selector.name}>
                    <AssignmentBuilder
                        isReadOnly={selector.isReadOnly || this.props.isReadOnly}
                        location={this.getLocation(selector.number)}
                        assignment={selector.assignment}
                        fieldName={pseudocodeHelper.wrapInBrackets(selector.name)}
                        functions={this.props.reportConfig.userDefinedFunctions}
                        lookups={this.props.reportConfig.lookups}
                        dictionaries={this.props.reportConfig.dictionaries}
                        variables={[]}
                        dataSources={this.props.collections.dataSources}
                        reportFields={this.props.collections.reportFields}
                        customDataSourceFields={null as any}
                        onAddAssignmentClick={(location, event) => { this.props.onAddAssignmentClick(location, event, reportConfigComponentLock); }}
                        onRemoveAssignmentClick={(location, isSingle, event) => { this.onRemoveAssignmentClick(location, selector, isSingle, event, reportConfigComponentLock); }}
                        onResetAssignmentClick={(location, isSingle, event) => { this.onResetAssignmentClick(location, selector, event, reportConfigComponentLock); }}
                        onLogicalOperatorChange={(location, operator, event) => { this.props.onLogicalOperatorChange(location, operator, event, reportConfigComponentLock); }}
                        onParenthesesChange={(location, count, type, event) => this.props.onParenthesesChange(location, count, type, event, reportConfigComponentLock)}
                        onAddConditionClick={(location, operator, event) => { this.props.onAddConditionClick(location, operator, event, reportConfigComponentLock); }}
                        onRemoveConditionClick={(location, event) => { this.props.onRemoveConditionClick(location, event, reportConfigComponentLock); }}
                        onComparisonTypeChange={(location, value, event) => { this.props.onComparisonTypeChange(location, value, event, reportConfigComponentLock); }}
                        onOperandClick={(location, event) => { this.props.onOperandClick(location, event, reportConfigComponentLock); }}
                        onCopyClick={(location) => this.props.onCopyClick(location, reportConfigComponentLock)}
                        onPasteClick={(location, event) => this.props.onPasteClick(location, event, reportConfigComponentLock)}
                        onValidateFieldClick={(location, event) => this.props.onValidateDeclarationClick(location, event, reportConfigComponentLock)}
                        onClearClick={(event) => this.tryLock(undefined, selector)}
                        allowPaste={this.props.allowPaste} />
                </div>
            )
        }
    }

    render(): JSX.Element {

        return (
            <>
                <ReportLogicList
                    isWaiting={this.props.isWaiting}
                    items={this.state.selectors.filter(x => x.number === this.props.declarationBatchNumber)}
                    columns={getColumns(this.onLockSectionClick,
                        this.onRemoveSelectorClick,
                        this.onNameChange,
                        this.props.isReadOnly,
                        this.state.info,
                        this.state.selectorDataType,
                        editReportConfigHelper.isValidationConfig(this.props.reportConfig))}
                    getContent={this.getAssignmentContent}
                    getKey={x => x.number}
                    createHtmlId={x => buildId(this.state.locationTypeStatement, x.number)}
                    onClick={(e: React.MouseEvent<HTMLElement>, item: Selector) => {
                        if (item.isLocked && item.isReadOnly) {
                            this.tryLock(item.number, item);
                        }
                    }} />
            </>
        );
    }
}

export default SelectorBuilder;
