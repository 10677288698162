import './EditableText.scss';
import { buildClassName } from '../../../infrastructure/helpers/html/htmlUtils';
import React from 'react';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

const className = 'editable-text';

interface EditableTextProps {
    value: string;
    onClick?: (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChange: (value: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onMouseOver?: (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    multiline: boolean;
    disabled: boolean;
    className?: string;
    style?: React.CSSProperties;
}

interface EditableTextState {
    value: string;
    old: string;
}

class EditableText extends React.Component<EditableTextProps, EditableTextState> {

    constructor(props: EditableTextProps) {
        super(props);

        this.state = {
            value: typeHelper.isString(this.props.value) ? this.props.value : '',
            old: this.props.value
        };

        this.onClick = this.onClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.getPlaceholder = this.getPlaceholder.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    static getDerivedStateFromProps(props: EditableTextProps, state: EditableTextState) {
        if (props.value !== state.old) {
            return {
                value: typeHelper.isString(props.value) ? props.value : '',
                old: props.value
            };
        }

        return null;
    } 

    onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {

        this.setState({ value: e.target.value });
    }

    onMouseOver(event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) {
        this.props.onMouseOver && this.props.onMouseOver(event);
    }

    onClick(event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) {
        this.props.onClick && this.props.onClick(event);
    }

    onBlur(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {

        this.props.onChange(e.target.value, e);
    }

    getPlaceholder(): string {
        return this.props.disabled ? '' : 'Type here...';
    }

    onKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        this.props.onKeyDown && this.props.onKeyDown(e);
    }

    render(): JSX.Element {

        if (this.props.multiline) {

            return (
                <textarea
                    className={buildClassName(className, this.props.className)}
                    placeholder={this.getPlaceholder()}
                    disabled={this.props.disabled}
                    style={this.props.style}
                    value={this.state.value}
                    onChange={this.onChange}
                    onClick={this.onClick}
                    onMouseOver={this.onMouseOver}
                    onKeyDown={this.onKeyDown}
                    onBlur={this.onBlur}>
                </textarea>
            );
        }

        return (
            <input
                type="text"
                className={buildClassName(className, this.props.className)}
                placeholder={this.getPlaceholder()}
                disabled={this.props.disabled}
                style={this.props.style}
                value={this.state.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onMouseOver={this.onMouseOver}
                onClick={this.onClick}
                onKeyDown={this.onKeyDown}
            />
        );
    }
}

export default EditableText;