import { Unsubscribe } from 'redux';
import ActionItemModalState from './ActionItemModalState';
import ActionItemOption from '../../../../types/common/ActionItem/ActionItemOption';
import ActionItemSaveRequest, { ActionItemSaveModel } from '../../../../types/common/ActionItem/ActionItemSaveRequest';
import actionItemValidator from '../../../../infrastructure/helpers/actionItem/actionItemValidator';
import actions from '../../../../store/actions';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import Modal from '../../Modal/Modal';
import PromiseButton from '../../PromiseButton';
import store from '../../../../store/store';
import ActionItemForm from '../../ActionItemForm/ActionItemForm';
import { ActionLogInitFormDto } from '../../../../types/common/ActionItem/ActionItemForm';
import identityStorage from '../../../../infrastructure/authorization/identityStorage';
import Spinner from '../../Spinner/Spinner';
import SelectWithSearch from '../../SelectWithSearch/SelectWithSearch';
import React from 'react';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import ActionItem from '../../../../types/common/ActionItem/ActionItem';
import urlHelper from '../../../../infrastructure/helpers/common/urlHelper';
import SelectWithSearchItem from '../../../../types/report/import/SelectWithSearchItem';
import ActionItemDto from '../../../../types/common/ActionItem/ActionItemDto';
import ActionType from '../../../../types/report/ActionType';
import ComponentType from '../../../../types/report/ComponentType';
import ActionitemDto from '../../../../types/common/ActionItem/ActionItemDto';

interface Props {
    reportConfigId: number;
    regime: string;
    onActionItemsCountChange: (actiontype: ActionType, componentType: ComponentType, moduleTypeId: number) => void;
}

class ActionItemModal extends React.Component<Props, ActionItemModalState> {
    private unsubscribe: Unsubscribe | undefined;
    private defaultState = {
        title: 'Action Log',
        isOpen: false,
        error: null,
        isWaiting: false,
        showForm: false,
        name: '',
        createFormData: undefined,
        saveRequest: {} as ActionItemSaveRequest,
        buildRelationUrl: () => '',
        module: '',
        actionItems: [],
        actionItemsForModule: [],
        isDataFetched: false,
        relatedActionItems: [],
        searchValue: '',
        selectedActionItem: {} as ActionItemOption,
        clickedActionItem: {} as ActionItem,
        originalActionItems: [],
        users: {},
        editedItem: {} as ActionItemDto,
        searchResults: [],
        linkedActionItems: []
    };

    constructor(props: any) {
        super(props);

        this.state = Object.assign({}, this.defaultState);

        this.openListener = this.openListener.bind(this);
        this.onActionItemRemoved = this.onActionItemRemoved.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.close = this.close.bind(this);
        this.openCreateForm = this.openCreateForm.bind(this);
        this.onSearchValueChange = this.onSearchValueChange.bind(this);
        this.onSelectedActionItemClick = this.onSelectedActionItemClick.bind(this);
        this.getItems = this.getItems.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSelectActionItem = this.onSelectActionItem.bind(this);
    }

    async componentDidMount(): Promise<void> {

        this.unsubscribe = store.subscribe(this.openListener);
        await this.fetchFormData();
    }

    async fetchFormData(): Promise<void> {
        this.setState({ isWaiting: true });

        let response = await httpClient.get<ActionLogInitFormDto>('action-item/init-form');
        let identity = identityStorage.get();
        response.data.components = [];
        response.data.product = 'Cb';
        response.data.assigneeId = identity.userId ?? 'CommonUnassigned';
        response.data.assignees.push({ id: identity.userId, userName: identity.userName.split('/')[identity.userName.split('/').length - 1] });

        this.setState({ createFormData: response.data, isWaiting: false, isDataFetched: true });

    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    openListener(): void {
        let state = store.getState();

        if (state.action !== actions.actionItemModal.open) {
            return;
        }

        this.setState(state.actionItemModalState);
    }

    onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {

        this.setState({ name: e.target.value });
    }

    onSaveClick(actionLog: ActionItemSaveModel, onError: () => void): Promise<any> {
        let error = actionItemValidator.validateName(actionLog.name);

        this.setState({ error: error });

        if (error) {
            return Promise.reject(error);
        }
        let actionItem = Object.assign({}, this.state.saveRequest);
        let promise;

        if (!actionLog.id) {
            promise = httpClient.post('action-item', { actionItem, actionLog });
        }
        else {
            const url = urlHelper.buildRoute(['action-item', actionLog.id]);
            promise = httpClient.put(url, { actionItem, actionLog });
        }
        return promise.then(() => { this.close(); this.onSaveSuccess() })
            .catch(() => onError());
    }

    onSaveSuccess(): void {
        this.setState({ isDataFetched: false, isWaiting: false, editedItem: {} as ActionitemDto });
        store.dispatch({ type: actions.actionItemModal.saveSuccess, payload: null as any });
    }

    async close(): Promise<void> {
        this.setState({ searchValue: '', showForm: false, isOpen: false, isDataFetched: false, actionItems: [], actionItemsForModule: [] });
    }

    openCreateForm(): void {
        this.setState({ showForm: true, editedItem: {} as ActionItemDto });
    }

    getItems() {
        let items: SelectOption[] = [];
        this.state.actionItems.forEach((item) => {
            items.push({
                text: item.name,
                value: item.id?.toString() || ''
            });
        });

        return items;
    }

    getItem(actionItem: ActionItemDto) {
        return { id: actionItem.clientActionId?.toString() || '', name: actionItem.name, item: actionItem, preventDelete: !actionItem.isLinkedToModule };
    }

    onSearchValueChange(value: string, searchByValue: string) {

        this.setState({ searchValue: value, }, (() => this.onSearchChange(searchByValue)));
    }

    async onSelectedActionItemClick(selectedItem: SelectWithSearchItem<ActionItemDto>) {
        const url = urlHelper.buildRoute([`action-item/external-id/${selectedItem.item.externalId}`]);
        const response = await httpClient.get<ActionItemDto>(url);

        const externalId = this.state.actionItemsForModule.find(i => i.clientActionId?.toString() === selectedItem.id)?.externalId;
        const id = this.state.originalActionItems.find(i => i.externalId === externalId)?.id || 0;
        let actionItem = response.data;
        actionItem.id = id;
        actionItem.modules = Object.entries(actionItem.module).filter(([_, v]) => v).map(([k, _]) => this.changeFirstLetterToUpper(k));

        this.setState({ showForm: true, editedItem: actionItem });
    }

    changeFirstLetterToUpper(k: string): any {
        return k[0].toUpperCase() + k.substring(1);
    }

    onSearchChange(searchByValue: string): void {
        let searchResultItems: ActionItemDto[] = [];

        if (searchByValue === 'name') {
            searchResultItems = this.state.actionItems.filter(x => x.name.trim().toLowerCase().indexOf(this.state.searchValue.toLowerCase().trim()) > -1);
        }
        else {
            searchResultItems = this.state.actionItems.filter(x => x.clientActionId?.toString().trim() === this.state.searchValue.toLowerCase().trim());
        }

        this.setState({ searchResults: searchResultItems });
    }

    async onSelectActionItem(actionItem: ActionItemDto): Promise<void> {

        this.setState({  isWaiting: true })
        let moduleTypeId;
        let routeModuleName;
        let componentType = ComponentType.None;
        switch (this.state.module) {
            case 'Filter': moduleTypeId = this.state.saveRequest.filterId; routeModuleName = 'filter'; componentType = ComponentType.Filter; break;
            case 'Case': moduleTypeId = this.state.saveRequest.caseId; routeModuleName = 'case'; componentType = ComponentType.Case; break;
            case 'Enrichment': moduleTypeId = this.state.saveRequest.fieldId; routeModuleName = 'report-field'; componentType = ComponentType.Enrichment; break;
            case 'AccuracyValidation': moduleTypeId = this.state.saveRequest.validationId; routeModuleName = 'validation'; componentType = ComponentType.AccuracyValidation; break;
            default: break;
        }
            const url = urlHelper.buildRoute([`action-item/${actionItem.id}/${routeModuleName}/${moduleTypeId}/is-linked/true/report-id/${this.props.reportConfigId}`]);

            const response = await httpClient.post(url);

            this.props.onActionItemsCountChange(ActionType.Add, componentType, moduleTypeId || 0);

            actionItem.id = response.data;
            actionItem.isLinkedToModule = true;

            let actionItemsForModule = this.state.actionItemsForModule.slice();

            actionItemsForModule.push(actionItem);
            this.setState({ actionItemsForModule, searchValue: '', isWaiting: false });
    }

    onActionItemRemoved(actionItem: ActionItemDto): void {

        let routeModuleName;
        let componentType = ComponentType.None;
        let moduleTypeId;

        switch (this.state.module) {
            case 'Filter': moduleTypeId = this.state.saveRequest.filterId; routeModuleName = 'filter'; componentType = ComponentType.Filter; break;
            case 'Case': moduleTypeId = this.state.saveRequest.caseId; routeModuleName = 'case'; componentType = ComponentType.Case; break;
            case 'Enrichment': moduleTypeId = this.state.saveRequest.fieldId; routeModuleName = 'report-field'; componentType = ComponentType.Enrichment; break;
            case 'AccuracyValidation': moduleTypeId = this.state.saveRequest.validationId; routeModuleName = 'validation'; componentType = ComponentType.AccuracyValidation; break;
            default: break;
        }

        const url = urlHelper.buildRoute([`action-item/${actionItem.id}/${routeModuleName}/${moduleTypeId}`]);
        httpClient.delete(url).then(this.onSaveSuccess);

        this.props.onActionItemsCountChange(ActionType.Remove, componentType, moduleTypeId || 0);

        let currentItems = this.state.actionItemsForModule.slice();
        currentItems = currentItems.filter(i => i.id !== actionItem.id);

        this.setState({ actionItemsForModule: currentItems });
    }


    render(): JSX.Element {
        return (
            <Modal state={this.state} close={this.close} size={'extra-large'}>
                <div className='action-item-modal'>
                    {
                        !this.state.showForm &&
                        <PromiseButton text='Create new' className='btn cb-btn' spinner={true} enableOnErrorOnly={false} task={() => new Promise<any>(this.openCreateForm)} disabled={this.state.isWaiting} />
                    }
                    <div className='margin-top-20px'>
                        {
                            this.state.isWaiting &&
                            <Spinner />
                        }
                    </div>
                    {!this.state.showForm &&
                        <SelectWithSearch
                            searchByLabel='Link to Existing'
                            label=''
                            onSelectItem={this.onSelectActionItem}
                            items={this.state.searchResults.map(this.getItem)}
                            onSelectedItemClick={this.onSelectedActionItemClick}
                            value={this.state.searchValue}
                            placeholder='Search by Name or Client action id'
                            onChangeSearchValue={this.onSearchValueChange}
                            onSearchChange={this.onSearchChange}
                            onRemoveSelectedItemClick={this.onActionItemRemoved}
                            searchBy={[{ text: 'Name', value: 'name' }, { text: 'Client action Id', value: 'client action id' }]}
                            selectedItems={this.state.actionItemsForModule.map(this.getItem)} />
                    }
                    <div className='form-group-horizontal row mb-2'>
                        <div className='col-12 margin-top-30-px'>
                            {
                                this.state.showForm &&
                                this.state.createFormData &&
                                <ActionItemForm
                                    data={this.state.createFormData}
                                    onSave={this.onSaveClick}
                                    regime={this.props.regime}
                                    module={this.state.module}
                                    editedItem={this.state.editedItem as ActionItemDto} />
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ActionItemModal;
