import { createUserOptions } from './ActionItemEditor.Helpers';
import { mapToBasicOption } from '../../infrastructure/helpers/html/selectOptionHelper';
import ActionItem from '../../types/common/ActionItem/ActionItem';
import actionItemStatus from '../../infrastructure/constants/actionItemStatus';
import FormGroup from '../Common/FormGroup/FormGroup';
import ObjectIndexer from '../../infrastructure/types/ObjectIndexer';
import React from 'react';
import SelectList from '../Common/SelectList/SelectList';

interface Props {
    isWaiting: boolean;
    actionItem: ActionItem;
    users: ObjectIndexer<string>;
    onChange: (changes: ActionItem) => void;
}

class ActionItemEditor extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onNameChange = this.onNameChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onAssigneeChange = this.onAssigneeChange.bind(this);
    }

    onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {

        let changes = { name: e.target.value } as ActionItem;

        this.props.onChange(changes);
    }

    onDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {

        let changes = { description: e.target.value } as ActionItem;

        this.props.onChange(changes);
    }

    onStatusChange(value: string): void {

        let changes = { status: value } as ActionItem;

        this.props.onChange(changes);
    }

    onAssigneeChange(value: string): void {

        let changes = { assigneeId: value } as ActionItem;

        this.props.onChange(changes);
    }

    render(): JSX.Element {
        const labelClass = 'col-3';
        const contentClass = 'col-9';

        return (
            <>
                <FormGroup label="Name:" labelClassName={labelClass} contentClassName={contentClass}>
                    <input
                        type="text"
                        className="form-control"
                        value={this.props.actionItem.name}
                        onChange={this.onNameChange}
                        disabled={this.props.isWaiting} />
                </FormGroup>
                <FormGroup label="Description:" labelClassName={labelClass} contentClassName={contentClass}>
                    <textarea
                        className="form-control"
                        onChange={this.onDescriptionChange}
                        value={this.props.actionItem.description}
                        disabled={this.props.isWaiting}>
                    </textarea>
                </FormGroup>
                <FormGroup label="Status:" labelClassName={labelClass} contentClassName={contentClass}>
                    <SelectList
                        options={Object.values(actionItemStatus).map(mapToBasicOption)}
                        className="form-control"
                        disabled={this.props.isWaiting}
                        value={this.props.actionItem.status}
                        onChange={this.onStatusChange} />
                </FormGroup>
                <FormGroup label="Assignee:" labelClassName={labelClass} contentClassName={contentClass}>
                    <SelectList
                        options={createUserOptions(this.props.users)}
                        includeEmptyOption={true}
                        className="form-control"
                        disabled={this.props.isWaiting}
                        value={this.props.actionItem.assigneeId}
                        onChange={this.onAssigneeChange} />
                </FormGroup>
            </>
        );
    }
}

export default ActionItemEditor;
