import Operand from '../../../../types/functions/Operand/Operand';
import arrayItemFunctions from '../../../constants/functions/definition/arrayItemFunctions';
import operandType from '../../../constants/functions/operandType';
import ArrayItemLegitimacy from '../../../types/Functions/ArrayItemLegitimacy';
import typeHelper from '../../common/typeHelper';
import { getFunctionDefinition } from '../function/functionHelper.Helpers';

const getLegitimacy = (operandStack: Operand[], operand: Operand): ArrayItemLegitimacy => {

    let isAllowed = false;
    let itemType = '';

    let operandIndex = operandStack.findIndex(x => x.operandType === operandType.function && arrayItemFunctions.includes(x.function));

    if (operandIndex >= 0) {

        var funcOperand = operandStack[operandIndex];

        let definition = getFunctionDefinition(funcOperand.function);

        let argumentIndex = definition.arguments.findIndex(x => typeHelper.isString(x.arrayItemType));

        if (argumentIndex >= 0) {

            itemType = definition.arguments[argumentIndex].arrayItemType as string;

            let args = funcOperand.arguments as Operand[];

            let stack = [args[argumentIndex]];
            while (stack.length > 0) {

                let current = stack.shift();

                if (current === operand) {

                    isAllowed = true;
                    break;
                }

                if ((current?.operandType === operandType.function || current?.operandType === operandType.dictionary) &&
                    current?.arguments !== null) {

                    for (var i = 0; i < current.arguments.length; i++) {
                        stack.push(current.arguments[i]);
                    }
                }
            } 
        }
    }

    let result: ArrayItemLegitimacy = {
        isAllowed: isAllowed,
        itemDataType: itemType
    };

    return result;
}

const arrayItemHelper = {
    getLegitimacy
};

export default arrayItemHelper;
