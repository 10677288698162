import { ReactComponent as RemoveIcon } from '../../../Common/BootstrapIcons/close.svg';
import ColumnDefinition from '../../../../infrastructure/types/DataGrid/ColumnDefinition';
import ComplianceUrl from '../../../../types/common/ComplianceUrl';
import CustomIcon from '../../BootstrapIcons/Custom/CustomIcon';
import EditableText from '../../EditableText/EditableText';
import iconTooltip from '../../BootstrapIcons/Tooltip/IconTooltip';
import React from 'react';

const getColumns = (onChange: (i: number, changes: ComplianceUrl) => void, onRemoveClick: (i: number) => void): ColumnDefinition<ComplianceUrl>[] => {
    return [
        {
            header: 'Name',
            getContent: (x, i) => <EditableText disabled={false} value={x.name} onChange={name => onChange(i, { name: name } as ComplianceUrl)} multiline={false} />,
            width: '540px'
        },
        {
            header: 'URL',
            getContent: (x, i) => <EditableText disabled={false} value={x.url} onChange={url => onChange(i, { url: url } as ComplianceUrl)} multiline={false} />
        },
        {
            header: '',
            getContent: (x, i) =>
                <button className="custom-icon-button" onClick={() => onRemoveClick(i)}>
                    <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                </button>,
            width: '40px'
        }
    ];
};

export { getColumns };
