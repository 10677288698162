import RadioButtonGroup from '../../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import { createFunctionOption } from './UserDefinedFunction.Helpers';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

interface Props {
    isWaiting: boolean;
    functionNumber: number | null;
    functions: UserDefinedFunction[];
    onFunctionSelected: (number: number) => void;
}

class UserDefinedFunctionSelector extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onFunctionSelected = this.onFunctionSelected.bind(this);
    }

    onFunctionSelected(value: string): void {

        this.props.onFunctionSelected(parseInt(value));
    }

    render(): JSX.Element {

        if (this.props.functions.length === 0) {

            return (<></>);
        }

        return (
            <>
                <RadioButtonGroup
                    options={this.props.functions.map(createFunctionOption)}
                    value={(this.props.functionNumber as number).toString()}
                    name="report-functions"
                    onChange={this.onFunctionSelected} />
            </>
        );
    }
}

export default UserDefinedFunctionSelector;
