import exchangeRates from '../../../infrastructure/constants/functions/exchangeRates';
import isin from '../../../infrastructure/constants/functions/isin';
import lei from '../../../infrastructure/constants/functions/lei';
import matchingKey from '../../../infrastructure/constants/functions/matchingKey';
import matchingKeyEndpoint from '../../../infrastructure/constants/functions/matchingKeyEndPoint';
import annaDsbUpiEnrichment from '../../../infrastructure/constants/functions/annaDsbUpiEnrichment';
import annaDsbUpi from '../../../infrastructure/constants/functions/annaDsbUpi';
import firdsFcaInstrument from '../../../infrastructure/constants/functions/firdsFcaInstrument';
import firdsEsmaInstrument from '../../../infrastructure/constants/functions/firdsEsmaInstrument';
import lse from '../../../infrastructure/constants/functions/lse';
import fcaRegulatedEntities from '../../../infrastructure/constants/functions/fcaRegulatedEntities';

const guidance = {
    dataSources: 'Data Sources',
    matchingKeys: 'This is used only for TR Completeness where unique matching keys can be defined for every reportable client record and non filtered end point record. Based on the matching keys, the records from client and endpoint are matched and reconciled.',
    cacheKeys: 'Cache keys are used to extract records from CN global ref data. This method is mainly used for big datasets (example: FIRDS, LEI etc) in an efficient way.',
    matchingKey: `${matchingKey.name} is combination of fields to be used for reconciling data between Client raw data and End point data`,
    matchingKeyEndPoint: `${matchingKeyEndpoint.name} is combination of fields to be used for reconciling data between Client raw data and End point data`,
    isinSelectors: `${isin.name} is used internally to lookup FIRDS details for each record`,
    leiSelectors: `${lei.name} is used internally to lookup LEI details for each record`,
    exchangeRatesSelectors: `${exchangeRates.name} is used internally to lookup Exchange Rates details for each record`,
    annaDsbUpiEnrichmentSelectors: `${annaDsbUpiEnrichment.name} is used internally to lookup AnnaDsb Upi Enrichment details for each record`,
    annaDsbUpiSelectors: `${annaDsbUpi.name} is used internally to lookup AnnaDsb Upi details for each record`,
    lseSelectors: `${lse.name} is used internally to lookup LSE details for each record`,
    fcaRegulatedEntitiesSelectors: `${fcaRegulatedEntities.name} is used internally to lookup Fca Regulated Entities details for each record`,
    firdsFcaInstrumentSelectors: `${firdsFcaInstrument.name} is used internally to lookup Firds Fca Instrument details for each record`,
    firdsEsmaInstrumentSelectors: `${firdsEsmaInstrument.name} is used internally to lookup Firds Esma Instrument details for each record`,
    lookups: 'This is a reference to link a raw file to a reference file (s) or one reference file to another using a unique key. The unique key could be a field or combination of fields.\nExample: LOOKUP [Raw File].[Field X] = [Reference File].[Field Y]',
    variables: 'Variables allow reuse of a piece of report logic in different components',
    aggregations: 'Aggregation allows grouping of multiple records together to produce one aggregated record from each group. Then aggregated records get reported',
    aggregatedRecord: 'One aggregated record is created from each group',
    filters: 'Filtering logic is defined by user to identify record in a raw data file which should not be reported. All records which are filtered should be recorded with a filter name.',
    endpointFilters: 'Filtering logic applied on CN records',
    expectedUnderReport: 'Matching records will be identified as expected under report',
    expectedOverReport: 'Matching records will be identified as expected over report',
    cases: 'All records which are not filtered should be assigned a reporting case. The reporting case will be used by TRC to identify that records have been successfully received by the end point.\nIf no reporting case is identified for a record it should be assigned a default reporting case of "unknown".',
    enrichment: 'Field Enrichment',
    validations: 'Validations',
    syncedWith: 'All enrichment changes made in the selected case will be automatically applied to the current case',
    syncedWithEnrichment: 'All enrichment changes made in the selected case will be automatically applied to the current case',
    syncedWithAggregation: 'All aggregation changes made in the selected case will be automatically applied to the current case',
    dictionaries: 'TBD',
    dictionaryField: 'TBD',
    userDefinedFunctions: 'TBD',
    userDefinedFunctionParameters: 'TBD',
    userDefinedFunctionBody: 'TBD',
};

export default guidance;
