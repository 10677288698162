import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import ComplianceUrl from '../../../../types/common/ComplianceUrl';
import Modal from '../../Modal/Modal';
import ModalState from '../../Modal/ModalState';
import React from 'react';
import store from '../../../../store/store';
import UrlList from '../../UrlList/UrlList';

class ComplianceUrlModal extends React.Component<any, ModalState<ComplianceUrl[]>> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: 'Compliance Guidance',
            isOpen: false,
            context: [],
            error: null
        };

        this.onStateChange = this.onStateChange.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onStateChange);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    onStateChange(): void {

        let state = store.getState();
        if (state.action !== actions.complianceUrlModal.open) {
            return;
        }

        this.setState({ isOpen: true, context: state.complianceUrls });
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    render(): JSX.Element {
        return (
            <Modal state={this.state} close={this.close} size="large">
                <UrlList urls={this.state.context} />
            </Modal>
        );
    }
}

export default ComplianceUrlModal;
