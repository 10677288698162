import locationType from '../../../types/functions/Location/LocationType';
import ObjectIndexer from '../../types/ObjectIndexer';

const endpointFilterType = {
    regular: 'Regular',
    underReport: 'UnderReport',
    overReport: 'OverReport'
};

const getStatementMap = (): ObjectIndexer<string> => {
    let map = {} as ObjectIndexer<string>;

    map[endpointFilterType.regular] = locationType.statement.endpointFilter;
    map[endpointFilterType.underReport] = locationType.statement.underReportFilter;
    map[endpointFilterType.overReport] = locationType.statement.overReportFilter;

    return map;
};

const getDisplayMap = (): ObjectIndexer<string> => {
    let map = {} as ObjectIndexer<string>;

    map[endpointFilterType.regular] = 'Endpoint Filter';
    map[endpointFilterType.underReport] = 'Under Report Filter';
    map[endpointFilterType.overReport] = 'Over Report Filter';

    return map;
};

const endpointFilterTypeStatementMap = getStatementMap();
const endpointFilterTypeDisplayMap = getDisplayMap();

export { endpointFilterType, endpointFilterTypeStatementMap, endpointFilterTypeDisplayMap  };
