import groupBy from '../../common/array/groupBy';
import stringHelper from '../../common/stringHelper';

const validate = <T extends { errors: string[] }>(items: T[], selectCriteria: (x: T) => string, item: string, criteria: string): void => {

    let nonEmpty = items.filter(x => stringHelper.isNonEmpty(selectCriteria(x)));

    let duplicates = groupBy(nonEmpty, x => selectCriteria(x).toLowerCase(), x => x).filter(x => x.group.length > 1);

    duplicates.forEach(g => g.group.forEach(x => x.errors.push(`${item} ${criteria} must be unique`)));
};

const uniquenessValidator = {
    validate
};

export default uniquenessValidator;
