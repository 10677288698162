import Modal from '../../Modal/Modal';
import FormGroup from '../../FormGroup/FormGroup';
import RadioButtonGroup from '../../RadioButtonGroup/RadioButtonGroup';
import React from 'react';

interface DownloadProps {
    onDownload: (downloadAsFragmented: boolean) => void;
    onClose: () => void;
    isOpen: boolean;
}

enum DownloadType {
    Full = 'Full',
    Fragmented = 'Fragmented'
}
interface DownloadState {
    downloadType: DownloadType;
}

const fullDescription = 'Description: The full config will be downloaded in an excel file containing separate tabs for each module';
const fragmentedDescription = 'Description: The full config can be downloaded in .zip format where each tab will be in a .csv file.';

class DownloadModal extends React.Component<DownloadProps, DownloadState> {
    constructor(props: any) {
        super(props);

        this.state = {
            downloadType: DownloadType.Full
        }
    }

    render(): JSX.Element {
        return (
            <Modal state={{ isOpen: this.props.isOpen, title: 'Download', error: '' }} close={this.props.onClose}>
                <FormGroup label='' isVertical={true}>
                    <RadioButtonGroup
                        options={[{ text: 'Full Config (.xslx)', value: 'Full' }, { text: 'Fragmented Config (.zip)', value: 'Fragmented' }]}
                        value={this.state.downloadType.toString()}
                        name={''}
                        onChange={(value) => {
                            this.setState({
                                downloadType: value === DownloadType.Full.toString() ? DownloadType.Full : DownloadType.Fragmented
                            });
                        }}
                        labelClassName='display-inline-flex'
                        className='margin-bottom-10px'
                    />
                    <p>{this.state.downloadType === DownloadType.Full ? fullDescription : fragmentedDescription}</p>
                    <button className='btn cb-btn' onClick={() => { this.props.onDownload(this.state.downloadType === DownloadType.Fragmented); }}>Download</button>
                </FormGroup>
            </Modal>
        );
    }
}

export default DownloadModal;
