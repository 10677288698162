import './MultiSelectList.scss';
import { ReactComponent as RemoveIcon } from '../BootstrapIcons/trash.svg';
import React from 'react';
import SelectList from '../SelectList/SelectList';
import SelectOption from '../../../infrastructure/types/SelectOption';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';

interface MultiSelectListProps<T> {
    items: T[];
    getItemValue: (item: T) => string;
    getItemText: (item: T) => string;
    isItemDisabled: (item: T) => boolean;
    values: string[];
    className: string;
    isSelectListDisabled: boolean;
    isRemoveDisabled: (value: string) => boolean;
    onSelectChange: (value: string) => void;
    onRemoveClick: (value: string) => void;
    buildItem: (context: T) => JSX.Element;
    showDefaultValues?: boolean;
}

class MultiSelectList<T> extends React.Component<MultiSelectListProps<T>> {
    constructor(props: MultiSelectListProps<T>) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.getListItem = this.getListItem.bind(this);
        this.getStringListItem = this.getStringListItem.bind(this);
        this.getOption = this.getOption.bind(this);
    }

    onChange(value: string): void {

        if (stringHelper.isNonEmpty(value) && !this.props.values.includes(value)) {
            this.props.onSelectChange(value);
        }
    }

    getListItem(item: T, i: number): JSX.Element {
        let value = this.props.getItemValue(item);

        return (
            <li key={i} className="row no-gutters">
                <div className="col-auto display-flex">
                    <button className="transparent-button" type="button" onClick={() => this.props.onRemoveClick(value)} disabled={this.props.isRemoveDisabled(value)}>
                        <RemoveIcon />
                    </button>
                </div>
                <div className="col">{this.props.buildItem(item)}</div>
            </li>
        );
    }

    getStringListItem(item: string, i: number): JSX.Element {

        return (
            <li key={i} className="row no-gutters display-flex align-items-center">{item}</li>
        );
    }

    getOption(item: T): SelectOption {
        return {
            value: this.props.getItemValue(item),
            text: this.props.getItemText(item),
            disabled: this.props.isItemDisabled(item)
        };
    }

    render(): JSX.Element {
        return (
            <>
                <SelectList
                    options={this.props.items.map(this.getOption)}
                    className={this.props.className}
                    disabled={this.props.isSelectListDisabled}
                    value=''
                    onChange={this.onChange}
                    includeEmptyOption={true} />
                <ul className="selected-items">
                    {this.props.showDefaultValues ? this.props.values.map(this.getStringListItem) : this.props.items.filter(x => this.props.values.includes(this.props.getItemValue(x))).map(this.getListItem)}
                </ul>
            </>
        );
    }
}

export default MultiSelectList;
