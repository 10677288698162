import { SelectSearchOption } from 'react-select-search';
import dataType from '../../../../infrastructure/constants/dataType';
import functionName from '../../../../infrastructure/constants/functions/definition/functionName';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const toString: SelectOption[] = [
    { value: functionName.toString, text: 'Number' },
    { value: functionName.toStringBoolean, text: dataType.boolean },
    { value: functionName.toStringDateTime, text: dataType.dateTime },
    { value: functionName.toStringDate, text: dataType.date },
    { value: functionName.toStringTime, text: dataType.time },
    { value: functionName.toStringArrayOfStrings, text: dataType.arrayOfStrings },
    { value: functionName.toStringArrayOfIntegers, text: dataType.arrayOfIntegers },
    { value: functionName.toStringArrayOfDecimals, text: dataType.arrayOfDecimals },
    { value: functionName.toStringArrayOfBooleans, text: dataType.arrayOfBooleans },
    { value: functionName.toStringArrayOfDates, text: dataType.arrayOfDates },
    { value: functionName.toStringArrayOfTimes, text: dataType.arrayOfTimes },
    { value: functionName.toStringArrayOfDateTimes, text: dataType.arrayOfDateTimes },
    { value: functionName.toStringArrayOfUnixTimes, text: dataType.arrayOfUnixTimes }
];

const $in: SelectOption[] = [
    { value: functionName.in, text: dataType.string },
    { value: functionName.inInteger, text: dataType.integer },
    { value: functionName.inDelimited, text: 'Delimited Values' }
];

const round: SelectOption[] = [
    { value: functionName.round, text: 'Away From Zero' },
    { value: functionName.roundToEven, text: 'To Even' },
    { value: functionName.roundUp, text: 'Up' },
    { value: functionName.roundDown, text: 'Down' }
];

const addDays: SelectOption[] = [
    { value: functionName.addDays, text: 'Non-business days' },
    { value: functionName.addDaysBusiness, text: 'Business days' },
    { value: functionName.addDaysFx, text: 'FX days' }
];

const toDateTime: SelectOption[] = [
    { value: functionName.toDateTime, text: dataType.unixTime },
    { value: functionName.toDateTimeString, text: dataType.string }
];

const toArray: SelectOption[] = [
    { value: functionName.toArray, text: dataType.arrayOfStrings },
    { value: functionName.toArrayOfIntegers, text: dataType.arrayOfIntegers },
    { value: functionName.toArrayOfDecimals, text: dataType.arrayOfDecimals },
    { value: functionName.toArrayOfBooleans, text: dataType.arrayOfBooleans },
    { value: functionName.toArrayOfDates, text: dataType.arrayOfDates },
    { value: functionName.toArrayOfTimes, text: dataType.arrayOfTimes },
    { value: functionName.toArrayOfDateTimes, text: dataType.arrayOfDateTimes },
    { value: functionName.toArrayOfUnixTimes, text: dataType.arrayOfUnixTimes }
];

const all: SelectOption[] = [
    { value: functionName.all, text: dataType.string },
    { value: functionName.allInteger, text: dataType.integer },
    { value: functionName.allDecimal, text: dataType.decimal },
    { value: functionName.allBoolean, text: dataType.boolean },
    { value: functionName.allDate, text: dataType.date },
    { value: functionName.allTime, text: dataType.time },
    { value: functionName.allDateTime, text: dataType.dateTime },
    { value: functionName.allUnixTime, text: dataType.unixTime }
];

const any: SelectOption[] = [
    { value: functionName.any, text: dataType.string },
    { value: functionName.anyInteger, text: dataType.integer },
    { value: functionName.anyDecimal, text: dataType.decimal },
    { value: functionName.anyBoolean, text: dataType.boolean },
    { value: functionName.anyDate, text: dataType.date },
    { value: functionName.anyTime, text: dataType.time },
    { value: functionName.anyDateTime, text: dataType.dateTime },
    { value: functionName.anyUnixTime, text: dataType.unixTime }
];

const first: SelectOption[] = [
    { value: functionName.first, text: 'Default' },
    { value: functionName.firstOrdered, text: 'Ordered' },
];

const last: SelectOption[] = [
    { value: functionName.last, text: 'Default' },
    { value: functionName.lastOrdered, text: 'Ordered' },
];

const overloadOptions = {
    toString: toString,
    toArray: toArray,
    in: $in,
    round: round,
    addDays: addDays,
    toDateTime: toDateTime,
    all: all,
    any: any,
    first: first,
    last: last
};

const convertSelectOptionToSelectSearchOption = (field: SelectOption): SelectSearchOption => {
    return {
        name: field.text,
        value: field.value.toString(),
        disabled: field.disabled
    };
};

const buildFunctions = (fields: SelectSearchOption[]): SelectSearchOption[] => {
    const empty: SelectSearchOption = { value: '', name: '' };
    return [empty].concat(fields);
};

const createOption = (func: UserDefinedFunction): SelectOption => {

    return {
        value: func.number.toString(),
        text: `UserDefinedFunctions.${func.name}`
    };
};

export {
    overloadOptions,
    convertSelectOptionToSelectSearchOption,
    buildFunctions,
    createOption
};
