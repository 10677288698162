import { AxiosResponse } from 'axios';
import { reportType } from '../../../../infrastructure/constants/reportType';
import clear from '../../../../infrastructure/helpers/common/array/clear';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import dataSourceKind from '../../../../infrastructure/constants/dataSourceKind';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import IngestionConfigOption from '../../../../types/ingestion/IngestionConfigOption';
import ReportConfig from '../../../../types/report/ReportConfig';
import reportConfigCountHelper from '../../../../infrastructure/helpers/report/reportConfigCountHelper';
import ReportConfigCounts from '../../../../types/report/ReportConfigCounts';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportPageContext from './ReportPageContext';
import ReportRegimeDetails from '../../../../types/report/ReportRegimeDetails';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../../infrastructure/helpers/common/urlHelper';
import Operand from '../../../../types/functions/Operand/Operand';
import MatchingKey from '../../../../types/report/MatchingKey';

const getDataSourceOptions = (reportType: string, reportConfigId?: number): Promise<AxiosResponse<IngestionConfigOption[]>> => {

    let route = '';

    if (reportConfigId) {
        route = urlHelper.buildRoute(['ingestion', reportConfigId, 'enabled', reportType]);
    }
    else {
        route = urlHelper.buildRoute(['ingestion', 'enabled', reportType]);
    }

    return httpClient.get<IngestionConfigOption[]>(route);
};

const getReportDataSources = (reportConfig: ReportConfig): Promise<AxiosResponse<IngestionConfig[]>> => {

    let ids = reportConfig.refDataSources.map(x => x.id);

    if (reportConfig.type !== reportType.accuracy) {

        ids.push(reportConfig.rawDataSourceId as number);
    }

    let url = urlHelper.buildCollectionParamUrl('ingestion', 'ids', ids);

    return httpClient.get<IngestionConfig[]>(url);
};

const getRegimeDetails = (reportConfig: ReportConfig): Promise<AxiosResponse<ReportRegimeDetails>> => {

    let url = urlHelper.buildUrl('report-field/details', { regime: reportConfig.regime });

    return httpClient.get<ReportRegimeDetails>(url);
};

const getReportFields = (reportConfig: ReportConfig): Promise<AxiosResponse<ReportFieldDefinition[]>> => {

    let url = urlHelper.buildUrl('report-field', { regime: reportConfig.regime });

    return httpClient.get<ReportFieldDefinition[]>(url);
};

const getCustomDataSourceFields = (): Promise<AxiosResponse<CustomDataSourceFields>> => {

    return httpClient.get<CustomDataSourceFields>('custom-data-source/fields');
};

const getConfigById = (id: number): Promise<AxiosResponse<ReportConfig>> => {

    let url = 'report/' + id;

    return httpClient.get<ReportConfig>(url);
};

const getCounts = (regime: string, reportId: number | null): Promise<AxiosResponse<ReportConfigCounts>> => {

    let parts = ['report', regime, 'counts'];

    if (typeHelper.isNumber(reportId)) {

        parts.push((reportId as number).toString());
    }

    let url = urlHelper.buildRoute(parts);

    return httpClient.get<ReportConfigCounts>(url);
};

const seedReportDataSourceOptions = (dataSourceOptions: IngestionConfigOption[], context: ReportPageContext): void => {

    dataSourceOptions.forEach(x => context.collections.dataSourceOptions.push(x));
};

const seedReportDataSources = (dataSources: IngestionConfig[], context: ReportPageContext): void => {

    clear(context.collections.dataSources);

    dataSources.filter(x => x.dataSourceKind !== dataSourceKind.accuracy && x.dataSourceKind !== dataSourceKind.globalRef).forEach(x => context.collections.dataSources.push(x));
    dataSources.filter(x => x.dataSourceKind === dataSourceKind.accuracy).forEach(x => context.collections.dataSources.push(x));
    dataSources.filter(x => x.dataSourceKind === dataSourceKind.globalRef).forEach(x => context.collections.dataSources.push(x));
};

const seedReportFields = (fieldDefinitions: ReportFieldDefinition[], context: ReportPageContext): void => {

    fieldDefinitions.forEach(x => context.collections.reportFields.push(x));
};

const seedCustomDataSourceFields = (customDataSourceFields: CustomDataSourceFields, context: ReportPageContext): void => {

    customDataSourceFields.firds.forEach(x => context.collections.customDataSourceFields.firds.push(x));
    customDataSourceFields.leiLevel1.forEach(x => context.collections.customDataSourceFields.leiLevel1.push(x));
    customDataSourceFields.exchangeRates.forEach(x => context.collections.customDataSourceFields.exchangeRates.push(x));
    customDataSourceFields.cnProduct.forEach(x => context.collections.customDataSourceFields.cnProduct.push(x));
};

const getEmptyReportPageContext = (): ReportPageContext => {
    return {
        collections: {
            dataSourceOptions: [],
            dataSources: [],
            reportFields: [],
            customDataSourceFields: {
                firds: [],
                leiLevel1: [],
                cnProduct: [],
                exchangeRates: [],
                annaDsbUpiEnrichment: [],
                annaDsbUpi: []
            }
        },
        counts: reportConfigCountHelper.getEmpty(),
        config: getEmptyReportConfig()
    };
};

const getEmptyReportConfig = (): ReportConfig => {
    return {
        id: null,
        name: '',
        regime: '',
        subRegime: null,
        type: reportType.completenessFieldLevelMatching,
        rawDataSourceId: null,
        refDataSources: [],
        matchingKey: {
            operands: [] as Operand[]
        } as MatchingKey,
        matchingKeyEndPoint: {
            operands: [] as Operand[]
        } as MatchingKey,
        isinSelectors: [],
        leiSelectors: [],
        exchangeRatesSelectors: [],
        annaDsbUpiEnrichmentSelectors: [],
        annaDsbUpiSelectors: [],
        lseSelectors: [],
        firdsFcaInstrumentSelectors: [],
        firdsEsmaInstrumentSelectors: [],
        fcaRegulatedEntitiesSelectors: [],
        userDefinedFunctions: [],
        lookups: [],
        dictionaries: [],
        variables: [],
        aggregations: [],
        filters: [],
        endpointFilters: [],
        cases: [],
        validations: [],
        isReleased: false,
        version: null as any
    };
};

export {
    getDataSourceOptions,
    getReportDataSources,
    getRegimeDetails,
    getReportFields,
    getCustomDataSourceFields,
    getConfigById,
    getCounts,
    seedReportDataSourceOptions,
    seedReportDataSources,
    seedReportFields,
    seedCustomDataSourceFields,
    getEmptyReportConfig,
    getEmptyReportPageContext
};
