import ReportCase from '../../../../types/report/ReportCase';
import SelectOption from '../../../../infrastructure/types/SelectOption';

const createCaseOption = ($case: ReportCase): SelectOption => {
    return {
        value: $case.number.toString(),
        text: $case.name
    };
};

export { createCaseOption };