import ReportCase from '../../../types/report/ReportCase';
import StatementHelper from '../../types/Functions/StatementHelper';

class CaseHelper extends StatementHelper<ReportCase> {

    getDataSourceId($case: ReportCase): number | null {

        return $case.dataSourceId;
    }

    getEmpty(rawDataSourceId: number): ReportCase {

        return {
            id: null,
            dataSourceId: rawDataSourceId,
            number: 0,
            condition: null as any,
            name: '',
            businessDescription: '',
            fields: [],
            syncedWithId: null,
            aggregationNumber: null,
            isSystemGenerated: false,
            isSeparateOutput: false,
            isBespoke: false,
            bespokeCondition: '',
            errors: []
        };
    }
}

const caseHelper = new CaseHelper();

export default caseHelper;
