import { displayErrorMessage } from './httpClient.errorMessage';
import { extractFilename, extractMessage } from './httpClient.helpers';
import { headerName, headerValue } from '../../constants/httpHeader';
import actions from '../../../store/actions';
import appRoutes from '../../constants/appRoutes';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import downloadHelper from './downloadHelper';
import history from '../../../../src/infrastructure/helpers/react/history';
import identityStorage from '../../authorization/identityStorage';
import ObjectIndexer from '../../../infrastructure/types/ObjectIndexer';
import resolveConfig from '../../configuration/resolveConfig';
import store from '../../../store/store';
import typeHelper from './typeHelper';

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

const getRequestConfig = (params?: Object): AxiosRequestConfig => {

    const currentConfig = resolveConfig();

    let config: AxiosRequestConfig = {
        baseURL: currentConfig.server.apiUrl,
        headers: { Authorization: `Bearer ${identityStorage.get().accessToken}` }
    };

    if (typeHelper.isObject(params)) {
        config.params = params;
    }

    return config;
};

const get = <T>(route: string, params?: Object): Promise<AxiosResponse<T>> => {
    let config = getRequestConfig(params);

    return axios.get(route, config).catch(onError);
};

const put = (route: string, data: Object): Promise<AxiosResponse<any>> => {
    let config = getRequestConfig();

    return axios.put(route, data, config).catch(onError);
};

const post = (route: string, data?: Object): Promise<AxiosResponse<any>> => {
    let config = getRequestConfig();

    return axios.post(route, data, config).catch(onError);
};

const $delete = (route: string): Promise<AxiosResponse<any>> => {
    let config = getRequestConfig();

    return axios.delete(route, config).catch(onError);
};

const postFile = (route: string, data: FormData, params?: Object): Promise<AxiosResponse<any>> => {
    let config = getRequestConfig(params);

    (config.headers as ObjectIndexer<string>)[headerName.contentType] = headerValue.contentType.multipartFormData;

    return axios.post(route, data, config).catch(onError);
};

const download = (route: string, data?: Object): Promise<void> => {

    let config = getRequestConfig();

    config.responseType = 'blob';

    return new Promise<void>(resolve => {

        if (data) {

            axios.post(route, data, config).then((response: AxiosResponse<Blob>) => { onDownloadSuccess(response); resolve(); }).catch(onError);
        } else {

            axios.get(route, config).then((response: AxiosResponse<Blob>) => { onDownloadSuccess(response); resolve(); }).catch(onError);
        }
    });
};

const onDownloadSuccess = (response: AxiosResponse<Blob>): void => {

    let filename = extractFilename(response.headers);

    downloadHelper.download(response.data, filename);
};

const onError = (error: AxiosError<any>): Promise<AxiosResponse<any>> => {

    let response = (error.response || {}) as AxiosResponse<any>;

    if (response.status === UNAUTHORIZED) {

        identityStorage.clear();

        store.dispatch({ type: actions.navigation.execute, payload: () => history.push(appRoutes.unauthorized) });

        return Promise.reject('Server request was unauthorized');
    }

    if (response.status === FORBIDDEN) {

        store.dispatch({ type: actions.navigation.execute, payload: () => history.push(appRoutes.forbidden) });

        return Promise.reject('Requested server resource is forbidden');
    }

    let message = extractMessage(response.data) || 'Error occurred while executing http call';

    displayErrorMessage(message);

    return Promise.reject(error);
};

const httpClient = {
    get: get,
    put: put,
    post: post,
    delete: $delete,
    postFile: postFile,
    download: download
};

export default httpClient;
