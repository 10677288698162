import React from 'react';
import Modal from '../../Modal/Modal';
import RadioButtonGroup from '../../RadioButtonGroup/RadioButtonGroup';
import { insertAtPositionOptions } from './InsertAtPositionModal.Helper';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import FormGroup from '../../FormGroup/FormGroup';
import SelectList from '../../SelectList/SelectList';
import SelectOption from '../../../../infrastructure/types/SelectOption';

interface InsertAtPositionModalProps {
    title: string,
    dropdownOptions: SelectSearchOption[],
    dropdownForTypeName: string,
    defaultDropdownOption?: SelectSearchOption,
    defaultDropdownOptionAlwaysLastOne: boolean,
    dataTypeOptions: SelectOption[],
    defaultDataType?: string,
    value?: string;
    name: string;
    onSaveClick: (name: string, type: string, insertAfter: boolean, selectedVariableNumber: string) => void;
    onClose: () => void;
    validateFunction?: (number: number, isInsertAfter: boolean) => Promise<string>
}

class InsertAtPositionModal extends React.Component<InsertAtPositionModalProps, {
    title: string,
    isOpen: boolean,
    error: string,
    close: boolean,
    selectedDataType: string,
    insertAtPositionValue: string,
    selectedDropdownName: string,
    selectedDropdownNumber: string,
    name: string
}> {

    constructor(props: InsertAtPositionModalProps) {
        super(props);

        this.state = {
            close: false,
            title: this.props.title || '',
            error: '',
            isOpen: true,
            name: '',
            selectedDataType: this.props.defaultDataType || '',
            insertAtPositionValue: 'Insert After',
            selectedDropdownName: '',
            selectedDropdownNumber: ''
        }

        this.onInsertAtPositionChange = this.onInsertAtPositionChange.bind(this);
        this.onDropdownOptionChange = this.onDropdownOptionChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onChangeDataType = this.onChangeDataType.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    componentDidMount() {
        if (this.props.defaultDropdownOptionAlwaysLastOne) {
            this.setState({
                selectedDropdownNumber: this.props.dropdownOptions[this.props.dropdownOptions.length - 1]?.items?.[0].value || '',
                selectedDropdownName: this.props.dropdownOptions[this.props.dropdownOptions.length - 1].name
            });
        }
    }

    onInsertAtPositionChange(value: string) {
        this.setState({ insertAtPositionValue: value });
    }

    getDropdownOptions() {
        return ['Insert Before', 'Insert After'];
    }

    onDropdownOptionChange(value: any, selectedOption: any) {
        this.setState({ selectedDropdownName: value, selectedDropdownNumber: selectedOption.items[0].value, error: '' });
    }

    async onSaveClick() {
        if (!this.state.error) {
            const insertAfter = this.state.insertAtPositionValue === 'Insert After';

            let error = '';
            if (this.props.validateFunction) {
                error = await this.props.validateFunction(parseInt(this.state.selectedDropdownNumber), insertAfter);
            }
            if (!error) {
                this.props.onSaveClick(this.state.name, this.state.selectedDataType, insertAfter, this.state.selectedDropdownNumber);
            }
            else {
                this.setState({ error });
            }
        }
    }

    onNameChange(e: React.ChangeEvent<HTMLInputElement>) {

        var variableName = e.currentTarget.value;
        var nameExists = this.props.dropdownOptions.find(v => v.name === variableName);
        if (!nameExists) {
            this.setState({ name: e.currentTarget.value, error: '' });
        }
        else {
            this.setState({ error: 'The name must be unique' });
        }
    }

    onChangeDataType(type: string) {
        this.setState({ selectedDataType: type });
    }

    render(): JSX.Element {
        return (
            <Modal state={this.state} close={this.props.onClose}>
                <div className='display-flex align-center justify-content-start margin-bottom-20px'>
                    <FormGroup
                        label={this.props.name + ' Name'}
                        isVertical={true}
                        labelClassName='font-robotoBold'
                        contentClassName=''
                        style={{ 'display': 'flex', 'flexDirection': 'column' }}
                    >
                        <input
                            className='form-control'
                            type='text'
                            placeholder='Type here'
                            onChange={this.onNameChange}
                        />
                    </FormGroup>

                    {this.props.dataTypeOptions ?
                        <FormGroup
                            label={this.props.dropdownForTypeName}
                            isVertical={true}
                            labelClassName='font-robotoBold'
                            contentClassName='display-flex height-100-percents'
                            style={{ 'display': 'flex', 'flexDirection': 'column', 'marginLeft': '20px' }}
                        >
                            <SelectList
                                className="inline-select"
                                options={this.props.dataTypeOptions}
                                value={this.state.selectedDataType}
                                onChange={this.onChangeDataType}
                            />
                        </FormGroup>
                        : null
                    }
                </div>
                <div className='margin-top-20px labels-display-inline-flex'>
                    <RadioButtonGroup
                        options={insertAtPositionOptions}
                        value={this.state.insertAtPositionValue}
                        name={this.props.name}
                        onChange={this.onInsertAtPositionChange}
                        labelClassName='display-inline-flex font-robotoBold'
                    />
                </div>
                <div>
                    <SelectSearch
                        options={this.props.dropdownOptions}
                        onChange={this.onDropdownOptionChange}
                        search={true}
                        value={this.state.selectedDropdownName}
                    />
                </div>
                <div className='margin-top-20px'>
                    <button className='btn cb-btn' onClick={this.onSaveClick} >Save</button>
                </div>
            </Modal>
        )
    }
}

export default InsertAtPositionModal;
