import './DateTimeFormatPicker.scss';
import { dateFormats, timeFormats, dateTimeSeparators } from '../../../infrastructure/constants/dateTimeFormats';
import { getClassName } from './DateTimeFormatPicker.Helpers';
import { mapToSelectSearchOption } from '../../../infrastructure/helpers/html/selectOptionHelper';
import dataType from '../../../infrastructure/constants/dataType';
import dateTimeFormatHelper from '../../../infrastructure/helpers/common/dateTimeFormatHelper';
import dateTimeSeparatorHelper from '../../../infrastructure/helpers/common/dateTimeSeparatorHelper';
import React from 'react';
import SelectSearch, { SelectedOptionValue } from 'react-select-search';

interface DateTimeFormatPickerProps {
    dataType: string;
    format: string;
    onFormatChange: (format: string) => void;
    isInlineMode: boolean;
}

class DateTimeFormatPicker extends React.Component<DateTimeFormatPickerProps> {
    constructor(props: DateTimeFormatPickerProps) {
        super(props);

        this.onDateChange = this.onDateChange.bind(this);
        this.onTimeChange = this.onTimeChange.bind(this);
        this.onSeparatorChange = this.onSeparatorChange.bind(this);
        this.onIncludeTimeChange = this.onIncludeTimeChange.bind(this);
        this.isTimeIncluded = this.isTimeIncluded.bind(this);
    }

    onDateChange(value: SelectedOptionValue | SelectedOptionValue[]): void {

        if (dateFormats.includes(this.props.format)) {

            this.props.onFormatChange(value.toString());
            return;
        }

        let format = dateTimeFormatHelper.swapParts(this.props.format, value.toString(), null, null);

        this.props.onFormatChange(format);
    }

    onTimeChange(value: SelectedOptionValue | SelectedOptionValue[]): void {

        if (this.props.dataType === dataType.time ||
            this.props.dataType === dataType.arrayOfTimes) {

            this.props.onFormatChange(value.toString());
            return;
        }

        let format = dateTimeFormatHelper.swapParts(this.props.format, null, value.toString(), null);

        this.props.onFormatChange(format);
    }

    onSeparatorChange(value: SelectedOptionValue | SelectedOptionValue[]): void {

        let format = dateTimeFormatHelper.swapParts(this.props.format, null, null, value.toString());

        this.props.onFormatChange(format);
    }

    onIncludeTimeChange(e: React.ChangeEvent<HTMLInputElement>): void {

        let format = e.target.checked ?
            dateTimeFormatHelper.swapParts('', this.props.format, timeFormats[0], dateTimeSeparators[0]) :
            dateTimeFormatHelper.getParts(this.props.format).date;

        this.props.onFormatChange(format);
    }

    isTimeIncluded(): boolean {

        return dateTimeSeparatorHelper.findIndex(this.props.format) > -1;
    }

    render(): JSX.Element {

        if (!dateTimeFormatHelper.isDateOrTime(this.props.dataType)) {

            return (<></>);
        }

        let dateOnly = dateFormats.includes(this.props.format);

        let isTimeType =
            this.props.dataType === dataType.time ||
            this.props.dataType === dataType.arrayOfTimes;

        let isDateTimeType =
            this.props.dataType === dataType.dateTime ||
            this.props.dataType === dataType.arrayOfDateTimes;

        let regularDateTime = isDateTimeType && !dateOnly;

        let parts = dateTimeFormatHelper.getParts(regularDateTime ? this.props.format : '');

        if (dateOnly) {
            parts.date = this.props.format;
        }
        else if (isTimeType) {
            parts.time = this.props.format;
        }

        return (
            <div className={getClassName(this.props.isInlineMode)}>
                {
                    !isTimeType &&
                    <div style={{ width: '130px', display: 'inline-block', marginRight: '4px' }}>
                    <SelectSearch 
                        options={dateFormats.map(mapToSelectSearchOption)}
                        onChange={this.onDateChange}
                        search={true}
                        value={parts.date} />
                        </div>
                }
                {
                    !dateOnly && !isTimeType &&
                    <div style={{ width: 'min-content', minWidth: '30px', display: 'inline-block', marginRight: '4px' }}>
                        <SelectSearch
                        options={dateTimeSeparators.map(mapToSelectSearchOption)}
                        onChange={this.onSeparatorChange}
                        search={true}
                        value={parts.separator} />
                        </div>
                }
                {
                    (!dateOnly || isTimeType) &&
                    <div style={{ width: '150px', display: 'inline-block', marginRight: '4px' }}>
                    <SelectSearch
                        options={timeFormats.map(mapToSelectSearchOption)}
                        onChange={this.onTimeChange}
                        search={true}
                        value={parts.time} />
                        </div>
                }
                {
                    isDateTimeType &&
                    <label>
                        <input type="checkbox" checked={this.isTimeIncluded()} onChange={this.onIncludeTimeChange} />
                        <span>Time</span>
                    </label>
                }
                
            </div>
        );
    }
}

export default DateTimeFormatPicker;