import bespokeValidator from './bespokeValidator';
import clear from '../../common/array/clear';
import conditionValidator from './conditionValidator';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import ReportFilter from '../../../../types/report/ReportFilter';
import ReportVariable from '../../../../types/report/ReportVariable';
import stringHelper from '../../common/stringHelper';
import uniquenessValidator from './uniquenessValidator';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import { reportType } from '../../../constants/reportType';

const maxNameCharacters = 50;

const validateFilter = (filter: ReportFilter, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], customDataSourceFields: CustomDataSourceFields, reportFields: ReportFieldDefinition[], configType: string): void => {

    clear(filter.errors);

    let metadataError = validateMetadata(filter);
    if (metadataError) {
        filter.errors.push(metadataError);
    }

    if (!filter.isBespoke) {

        let fields = configType === reportType.accuracy ? reportFields : [];
        Array.prototype.push.apply(filter.errors, conditionValidator.validate(filter.condition, functions, lookups, dictionaries, variables, dataSources, fields, customDataSourceFields, true, null));
        return;
    }

    let bespokeError = bespokeValidator.validateCondition(filter.bespokeCondition);
    if (bespokeError) {
        filter.errors.push(bespokeError);
    }
};

const validateMetadata = (filter: ReportFilter): string | null => {

    if (stringHelper.isEmpty(filter.name)) {
        return 'Filter name is required';
    }

    if (stringHelper.containsNotAllowedCharacters(filter.name)) {
        return 'Filter name contains characters that are not allowed';
    }

    if (filter.name.length > maxNameCharacters) {
        return `Filter name max character count is ${maxNameCharacters}`;
    }

    return null;
};

const validate = (functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], filters: ReportFilter[], dataSources: IngestionConfig[], customDataSourceFields: CustomDataSourceFields, reportFields: ReportFieldDefinition[], reportType: string): string | null => {
    filters.forEach(x => validateFilter(x, functions, lookups, dictionaries, variables, dataSources, customDataSourceFields, reportFields, reportType));

    uniquenessValidator.validate(filters, f => f.name, 'Filter', 'name');

    let valid = filters.every(x => x.errors.length === 0);

    return valid ? null : 'Invalid filter(s)';
};

const filterValidator = {
    validate
};

export default filterValidator;