import { buildId, extractPrefix } from './reportConfigNavigation.Helpers';
import { endpointFilterTypeStatementMap } from '../../../constants/report/endpointFilterType';
import { prefixMap } from './reportConfigNavigation.HtmlIds';
import actions from '../../../../store/actions';
import EndpointFilter from '../../../../types/report/EndpointFilter';
import locationType from '../../../../types/functions/Location/LocationType';
import ReportConfigNavigationPayload from '../../../../types/report/ReportConfigNavigationPayload';
import store from '../../../../store/store';

const matchingKeyId = buildId(locationType.statement.matchingKey, 1);

const matchingKeyEndPointId = buildId(locationType.statement.matchingKeyEndPoint, 1);

const buildAggregationId = (number: number): string => buildId(locationType.statement.aggregation, number);

const buildAggregatedRecordFieldId = (number: number, aggregationNumber?: number): string => aggregationNumber ? `${buildId(locationType.statement.aggregatedRecordField, number)}-${buildId(locationType.statement.aggregation, aggregationNumber)}` : `${buildId(locationType.statement.aggregatedRecordField, number)}`;

const buildLookupId = (number: number): string => buildId(locationType.statement.lookup, number);

const buildIsinSelectorId = (number: number): string => buildId(locationType.statement.isinSelector, number);

const buildLeiSelectorId = (number: number): string => buildId(locationType.statement.leiSelector, number);

const buildExchangeRatesSelectorId = (number: number): string => buildId(locationType.statement.exchangeRatesSelector, number);

const buildAnnaDsbUpiEnrichmentSelectorId = (number: number, selectorNumber?: number | 0): string => `${buildId(locationType.statement.annaDsbUpiEnrichmentSelectorKey, number)}-${buildId(locationType.statement.annaDsbUpiEnrichmentSelector, selectorNumber as number)}`;

const buildLSESelectorId = (number: number): string => buildId(locationType.statement.lseSelector, number);

const buildFirdsFcaInstrumentSelectorId = (number: number, selectorNumber?: number | 0): string => `${buildId(locationType.statement.firdsFcaInstrumentSelectorKey, number)}-${buildId(locationType.statement.firdsFcaInstrumentSelector, selectorNumber as number)}`;

const buildFirdsEsmaInstrumentSelectorId = (number: number, selectorNumber?: number | 0): string => `${buildId(locationType.statement.firdsEsmaInstrumentSelectorKey, number)}-${buildId(locationType.statement.firdsEsmaInstrumentSelector, selectorNumber as number)}`;

const buildFCARegulatedEntitiesSelectorId = (number: number): string => buildId(locationType.statement.fcaRegulatedEntitiesSelector, number);

const buildAnnaDsbUpiSelectorId = (number: number): string => buildId(locationType.statement.annaDsbUpiSelector, number);

const buildVariableId = (number: number): string => buildId(locationType.statement.variable, number);

const buildFilterId = (number: number): string => buildId(locationType.statement.filter, number);

const buildEndpointFilterId = (filter: EndpointFilter): string => {

    return buildId(endpointFilterTypeStatementMap[filter.type], filter.number);
};

const buildCaseId = (number: number): string => buildId(locationType.statement.case, number);

const buildReportFieldId = (number: number, caseNumber?: number): string => caseNumber ? `${buildId(locationType.statement.reportField, number)}-${buildId(locationType.statement.case, caseNumber)}` : `${buildId(locationType.statement.reportField, number)}`;

const buildValidationId = (number: number, kind: string): string => `${buildId(locationType.statement.validation, number)}-${kind}`;

const buildDictionaryId = (number: number): string => buildId(locationType.statement.dictionary, number);

const buildDictionaryFieldId = (number: number, dictionaryNumber?: number): string => dictionaryNumber ? `${buildId(locationType.statement.dictionaryField, number)}-${buildId(locationType.statement.dictionary, dictionaryNumber || 0)}` : `${buildId(locationType.statement.dictionaryField, number)}`;

const buildFunctionId = (number: number): string => buildId(locationType.statement.userDefinedFunction, number);

const buildFunctionParameterId = (number: number): string => buildId(locationType.statement.userDefinedFunctionParameter, number);

const buildUnderReportFilter = (number: number): string => buildId(locationType.statement.underReportFilter, number);

const buildOverReportFilter = (number: number): string => buildId(locationType.statement.overReportFilter, number);

const dispatch = (expandHtmlId: string, scrollToHtmlId: string): void => {

    let payload: ReportConfigNavigationPayload = {
        expandHtmlId: expandHtmlId,
        scrollToHtmlId: scrollToHtmlId
    };

    store.dispatch({ type: actions.navigation.reportConfigSection, payload: payload });
};

const navigateToSection = (htmlId: string): void => {

    dispatch(htmlId, htmlId);
};

const navigateToItem = (htmlId: string): void => {

    let prefix = extractPrefix(htmlId);

    let sectionHtmlId = prefixMap[prefix];

    dispatch(sectionHtmlId, htmlId);
};

const navigateToItemInSection = (htmlId: string, sectionHtmlId: string): void => {

    dispatch(sectionHtmlId, htmlId);
};

const reportConfigNavigation = {
    matchingKeyId,
    matchingKeyEndPointId,
    buildAggregationId,
    buildAggregatedRecordFieldId,
    buildLookupId,
    buildIsinSelectorId,
    buildLeiSelectorId,
    buildExchangeRatesSelectorId,
    buildAnnaDsbUpiEnrichmentSelectorId,
    buildLSESelectorId,
    buildFirdsEsmaInstrumentSelectorId,
    buildFirdsFcaInstrumentSelectorId,
    buildFCARegulatedEntitiesSelectorId,
    buildAnnaDsbUpiSelectorId,
    buildVariableId,
    buildFilterId,
    buildEndpointFilterId,
    buildCaseId,
    buildReportFieldId,
    buildValidationId,
    buildDictionaryId,
    buildDictionaryFieldId,
    buildFunctionId,
    buildFunctionParameterId,
    navigateToSection,
    navigateToItem,
    navigateToItemInSection,
    buildUnderReportFilter,
    buildOverReportFilter
};

export default reportConfigNavigation;
