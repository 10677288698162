import { comparisonType } from '../../../constants/functions/comparisonType';
import Condition from '../../../../types/functions/Condition/Condition';
import logicalOperator from '../../../constants/functions/logicalOperator';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../operand/operandHelper';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import typeHelper from '../../common/typeHelper';

const getInner = (condition: Condition): Condition | null => {

    if (typeHelper.isObject(condition.or)) {
        return condition.or;
    }

    if (typeHelper.isObject(condition.and)) {
        return condition.and;
    }

    return null;
};

const find = (condition: Condition, predicate: (x: Condition) => boolean): Condition | null => {
    do {
        if (predicate(condition)) {
            return condition;
        }

        condition = getInner(condition) as Condition;
    }
    while (condition != null);

    return null;
};

const getPrevious = (condition: Condition, number: number): Condition | null => {

    return find(condition, x => x.or?.number === number || x.and?.number === number);
};

const getNext = (condition: Condition, number: number): Condition | null => {

    let target = getByNumber(condition, number);

    if (!typeHelper.isObject(target)) {
        return null;
    }

    if (typeHelper.isObject(target.or)) {
        return target.or;
    }

    if (typeHelper.isObject(target.and)) {
        return target.and;
    }

    return null;
};

const getLast = (condition: Condition): Condition | null => {

    return find(condition, x => !typeHelper.isObject(x.or) && !typeHelper.isObject(x.and));
};

const asCollection = (condition: Condition): Condition[] => {
    let collection: Condition[] = [];

    if (!typeHelper.isObject(condition)) {
        return collection;
    }

    do {
        collection.push(condition);
        condition = getInner(condition) as Condition;
    }
    while (condition != null);

    return collection;
};

const getAllOperands = (condition: Condition): Operand[] => {

    let result: Operand[] = [];

    asCollection(condition).forEach(item => {

        operandHelper.asCollection(item.operand1).forEach(o => result.push(o));
        operandHelper.asCollection(item.operand2).forEach(o => result.push(o));
    });

    return result;
};

const getByNumber = (condition: Condition, number: number): Condition => {

    return find(condition, x => x.number === number) as Condition;
};

const add = (condition: Condition, operator: string | null): Condition => {

    let empty = getEmpty();

    if (!typeHelper.isObject(condition)) {

        empty.number = 1;
        return empty;
    }

    let last = getLast(condition) as Condition;

    empty.number = last.number + 1;

    if (operator === logicalOperator.or) {
        last.or = empty;
    } else if (operator === logicalOperator.and) {
        last.and = empty;
    }

    return condition;
};

const remove = (condition: Condition, number: number): Condition => {

    let previous = getPrevious(condition, number) as Condition;

    let next = getNext(condition, number) as Condition;

    if (!typeHelper.isObject(previous)) {
        return next;
    }

    if (typeHelper.isObject(previous.or)) {
        previous.or = next;
    } else if (typeHelper.isObject(previous.and)) {
        previous.and = next;
    }

    return condition;
};

const getOperand = (condition: Condition, location: ReportLogicLocation): Operand => {

    let target = getByNumber(condition, location.conditionNumber);

    return operandHelper.getOperand(location.operandNumber === 1 ? target.operand1 : target.operand2, location);
};

const getEmpty = (): Condition => {
    return {
        number: 0,
        operand1: operandHelper.getEmpty(),
        operand2: operandHelper.getEmpty(),
        comparisonType: comparisonType.equal,
        or: null,
        and: null,
        openParentheses: 0,
        closeParentheses: 0
    };
};

const getLogicalOperator = (condition: Condition): string | null => {

    if (typeHelper.isObject(condition.or)) {
        return logicalOperator.or;
    }

    if (typeHelper.isObject(condition.and)) {
        return logicalOperator.and;
    }

    return null;
};

const getExactConditionByNumber = (condition: Condition, conditionNumber: number): Condition => {

    if (condition.number === conditionNumber) {
        return condition;
    }

    if (condition.and) {
        return getExactConditionByNumber(condition.and, conditionNumber);
    }

    if (condition.or) {
        return getExactConditionByNumber(condition.or, conditionNumber);
    }

    return condition;
}

const getExactConditionOperand = (condition: Condition, location: ReportLogicLocation): Operand => {

    let exactCondition = getExactConditionByNumber(condition, location.conditionNumber);

    let operand = location.operandNumber === 1 ? exactCondition.operand1 : exactCondition.operand2;

    return operand;
}

const conditionHelper = {
    asCollection,
    getAllOperands,
    getByNumber,
    add,
    remove,
    getOperand,
    getEmpty,
    getLogicalOperator,
    getExactConditionByNumber,
    getExactConditionOperand
};

export default conditionHelper;