const download = (content: Blob, filename: string): void => {

    let a = document.createElement('a');

    a.href = window.URL.createObjectURL(content);

    a.download = filename;

    (document.getElementById('object-url-container') as HTMLElement).appendChild(a);

    a.click();

    window.URL.revokeObjectURL(a.href);

    (a.parentNode as HTMLElement).removeChild(a);
};

const downloadHelper = {
    download
};

export default downloadHelper;
