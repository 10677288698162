import reportRegime from '../../../constants/reportRegime';

const getRegimeName = (regime: string) => {

    if (regime === reportRegime['ASIC Rewrite']) {
        return 'Asic Rewrite';
    }

    if (regime === reportRegime['MAS Rewrite']) {
        return 'Mas Rewrite';
    }

    return regime;
}

const hasRegimeSubregimes = (regime: string): boolean => {
    return regime === reportRegime.REFIT;
}

const reportRegimeHelper = {
    getRegimeName,
    hasRegimeSubregimes
};

export default reportRegimeHelper;
