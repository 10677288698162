import React from 'react';
import reportRegime from '../../infrastructure/constants/reportRegime';

const email = 'controlbox@control-now.com';

const Home = (): React.ReactElement => {

    return (
        <>
            <div style={{ height: '74px', width: '100%' }}>
                <img src="/logo-cb.png" alt="logo-cb" />
            </div>

            <p>Control Box can be used to design and build transaction reports. The solution includes the following modules;</p>

            <ol>
                <li>Ingestion: Upload and configure raw data sets</li>
                <li>Reporitng: Design filtering and enrichment logic to convert raw data sets into {Object.keys(reportRegime).join(', ')} transaction reports.</li>
            </ol>

            <p>The solution includes the following functionality;</p>

            <ul>
                <li>Lookups: Users can reference unlimited reference data sets to enrich raw system data to the necessary information required for reporting</li>
                <li>Audit: All changes to reporting configs are logged and stored for audit</li>
                <li>User and Compliance Guidance: Control Box is designed to include integrated compliance and user guidance.</li>
                <li>Collaborative working: Multiple users can access and amend each reporting config.</li>
                <li>Action Logs: Users can assign actions and monitor to completion.</li>
                <li>Real time development and testing: Once configured, the source is immediately ready for business testing.</li>
            </ul>

            <p>For any support with accessing and using the Control Box functionality, please email <a href={`mailto:${email}`}>{email}</a></p>
        </>
    );
};

export default Home;
