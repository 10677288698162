import reportRegime from "../../../infrastructure/constants/reportRegime";

const getAccuracyReportRawDataSource = (regime: string) => {

    switch (regime) {
        case reportRegime['MiFIR']: return 'CN_MiFIR';
        case reportRegime['EMIR']: return 'CN_EMIR';
        case reportRegime['REFIT']: return 'CN_REFIT';
        case reportRegime['MAS']: return 'CN_MAS';
        case reportRegime['ASIC']: return 'CN_ASIC';
        case reportRegime['MAS Rewrite']: return 'CN_MASRewrite';
        case reportRegime['ASIC Rewrite']: return 'CN_ASICRewrite';
        case reportRegime['IIROC']: return 'CN_IIROC';

        default: return '';
    }
}



let accuracyDataSourceFormHelper = {
    getAccuracyReportRawDataSource
}

export default accuracyDataSourceFormHelper;