const operandSpecialValueType = {
    formatStringBoolean: 'FormatStringBoolean',
    formatStringDateTime: 'FormatStringDateTime',
    formatStringDate: 'FormatStringDate',
    formatStringTime: 'FormatStringTime',
    timeZone: 'TimeZone',
    cnProductField: 'CnProductField',
    publicHolidayCountry: 'PublicHolidayCountry',
    publicHolidayCurrency: 'PublicHolidayCurrency',
    checkDateTimeFormat: 'CheckDateTimeFormat',
    sortDirection: 'SortDirection',
    daysOfWeek: 'DaysOfWeek',
    numberOfWeeksInMonth:'NumberOfWeeksInMonth'

};

export default operandSpecialValueType;
