import './DateTimeFormatPicker.scss';
import { dateFormats, timeFormats, dateTimeSeparators } from '../../../infrastructure/constants/dateTimeFormats';
import { mapToSelectSearchOption } from '../../../infrastructure/helpers/html/selectOptionHelper';
import dateTimeFormatHelper from '../../../infrastructure/helpers/common/dateTimeFormatHelper';
import React from 'react';
import dateTimeSeparatorHelper from '../../../infrastructure/helpers/common/dateTimeSeparatorHelper';
import DateTimeFormat from '../../../infrastructure/types/DateTimeFormatParts';
import { getClassName } from './DateTimeFormatPicker.Helpers';
import SelectSearch, { SelectedOptionValue } from 'react-select-search';

interface DateTimeFormatPickerSpecialProps {
    format: string;
    onFormatChange: (format: string) => void;
}

class DateTimeFormatPickerSpecial extends React.Component<DateTimeFormatPickerSpecialProps> {
    constructor(props: DateTimeFormatPickerSpecialProps) {
        super(props);
        
        this.onDateChange = this.onDateChange.bind(this);
        this.onTimeChange = this.onTimeChange.bind(this);
        this.onSeparatorChange = this.onSeparatorChange.bind(this);
        this.onIncludeTimeChange = this.onIncludeTimeChange.bind(this);
        this.onIncludeDateChange = this.onIncludeDateChange.bind(this);
        this.getParts = this.getParts.bind(this);
    }

    getParts(): DateTimeFormat {

        const format = this.props.format;

        let parts: DateTimeFormat = {
            date: '',
            time: '',
            separator: 'T'
        };

        if (format) {

            const formatToLower = format.toLowerCase();

            const isDateAndTime = dateTimeSeparatorHelper.findIndex(format) > -1;

            if (isDateAndTime) {
                parts = dateTimeFormatHelper.getParts(format);
            }
            else {
                const isDate = formatToLower.indexOf('d') > -1;
                if (isDate) {
                    parts.date = format;
                }
                else {
                    parts.time = format;
                }
            }
        }
        return parts;
    }

    onDateChange(value: SelectedOptionValue | SelectedOptionValue[]): void {

        let parts = this.getParts();

        let format = value.toString();

        if (parts.time && parts.time.length > 0) {
            format = format + parts.separator + parts.time;
        }

        this.props.onFormatChange(format);
    }

    onTimeChange(value: SelectedOptionValue | SelectedOptionValue[]): void {

        let parts = this.getParts();

        let format = value.toString();

        if (parts.date && parts.date.length > 0) {
            format = parts.date + parts.separator + value.toString();
        }

        this.props.onFormatChange(format);
    }

    onSeparatorChange(value: SelectedOptionValue | SelectedOptionValue[]): void {

        let parts = this.getParts();

        const format = parts.date + value.toString() + parts.time;

        this.props.onFormatChange(format);
    }

    onIncludeTimeChange(e: React.ChangeEvent<HTMLInputElement>): void {

        let parts = this.getParts();

        let format = '';

        if (!e.target.checked) {
            format = parts.date || dateFormats[0];
        }
        else {

            const time = parts.time || timeFormats[0];

            if (parts.date) {
                const separator = parts.separator || dateTimeSeparators[0];
                format = parts.date + separator + time;
            }
            else {
                format = parts.time || timeFormats[0];
            }
        }
        this.props.onFormatChange(format);
    }

    onIncludeDateChange(e: React.ChangeEvent<HTMLInputElement>): void {

        let parts = this.getParts();

        let format = '';

        if (!e.target.checked) {

            format = parts.time || timeFormats[0];

        }
        else {
            const date = parts.date || dateFormats[0];

            if (parts.time) {
                const separator = parts.separator || dateTimeSeparators[0];
                format = date + separator + parts.time;
            }
        }

        this.props.onFormatChange(format);
    }

    render(): JSX.Element {

        let parts = this.getParts();

        return (
            <div className={getClassName(true)}>
                {
                    parts.date &&
                    <div style={{ width: '130px', display: 'inline-block', marginRight: '4px' }}>
                        <SelectSearch
                            options={dateFormats.map(mapToSelectSearchOption)}
                            onChange={this.onDateChange}
                            search={true}
                            value={parts.date} />
                    </div>
                }
                {
                    parts.date.length > 0 && parts.time.length > 0 &&
                    <div style={{ width: 'min-content', minWidth: '30px', display: 'inline-block', marginRight: '4px' }}>
                        <SelectSearch
                            options={dateTimeSeparators.map(mapToSelectSearchOption)}
                            onChange={this.onSeparatorChange}
                            search={true}
                            value={parts.separator} />
                    </div>
                }
                {
                    parts.time &&
                    <div style={{ width: '150px', display: 'inline-block', marginRight: '4px' }}>
                        <SelectSearch
                            options={timeFormats.map(mapToSelectSearchOption)}
                            onChange={this.onTimeChange}
                            search={true}
                            value={parts.time} />
                    </div>
                }
                <div className='display-flex flex-column' style={{ float: 'right' }}>
                    <label>
                        <input type="checkbox" checked={parts.date.length > 0} onChange={this.onIncludeDateChange} />
                        <span>Date</span>
                    </label>
                    <label>
                        <input type="checkbox" checked={parts.time.length > 0} onChange={this.onIncludeTimeChange} />
                        <span>Time</span>
                    </label>
                </div>
            </div>
        );
    }
}

export default DateTimeFormatPickerSpecial;