import { ingestionDataTypeOptions, isSourcedFromMasterFile, resolveMasterFileLocationValue, resolveSource, sourceOptions } from './IngestionDetails.Helpers';
import fileExtension from '../../../infrastructure/constants/fileExtension';
import FormGroup from '../../Common/FormGroup/FormGroup';
import guidance from './IngestionDetails.Guidance';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import RadioButtonGroup from '../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import SelectList from '../../Common/SelectList/SelectList';
import SeparatedValueBuilder from '../SeparatedValueBuilder/SeparatedValueBuilder';
import separatedValueHelper from '../../../infrastructure/helpers/ingestion/separatedValueHelper';
import validateEmptyValueTokens from './IngestionDetails.Validation.EmptyValueTokens';
import validateFilenames from '../IngestionFilenames/IngestionFilenames.Validation';

interface Props {
    isReadOnly: boolean;
    config: IngestionConfig;
    onChange: (config: IngestionConfig) => void;
}

interface State {
    source: string;
}

class IngestionDetails extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = { source: resolveSource(this.props.config) }

        this.onNameChange = this.onNameChange.bind(this);
        this.onFilenamesChange = this.onFilenamesChange.bind(this);
        this.onSourceChange = this.onSourceChange.bind(this);
        this.onMasterFileLocationChange = this.onMasterFileLocationChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onDataSourceKindChange = this.onDataSourceKindChange.bind(this);
        this.onEmptyValueTokenChange = this.onEmptyValueTokenChange.bind(this);
        this.onTransposedDataChange = this.onTransposedDataChange.bind(this);
    }

    onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {

        let changes = { name: e.target.value } as IngestionConfig;

        this.props.onChange(changes);
    }

    onFilenamesChange(value: string): void {

        let changes = { filenames: value } as IngestionConfig;

        this.props.onChange(changes);
    }

    onSourceChange(source: string): void {

        let changes = {} as IngestionConfig;

        let $default = separatedValueHelper.split(this.props.config.filenames)[0];

        if (source === fileExtension.xml) {

            changes.xmlSectionName = $default;
            changes.jsonSectionName = '';
        }
        else if (source === fileExtension.json) {

            changes.jsonSectionName = $default;
            changes.xmlSectionName = '';
        }
        else {
            changes.xmlSectionName = '';
            changes.jsonSectionName = '';
        }

        this.props.onChange(changes);

        this.setState({ source: source });
    }

    onMasterFileLocationChange(e: React.ChangeEvent<HTMLInputElement>): void {

        if (this.state.source === fileExtension.xml) {

            this.props.onChange({ xmlSectionName: e.target.value } as IngestionConfig);
            return;
        }

        if (this.state.source === fileExtension.json) {

            this.props.onChange({ jsonSectionName: e.target.value } as IngestionConfig);
            return;
        }
    }

    onDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {

        let changes = { description: e.target.value } as IngestionConfig;

        this.props.onChange(changes);
    }

    onDataSourceKindChange(value: string): void {

        let changes = { dataSourceKind: value } as IngestionConfig;

        this.props.onChange(changes);
    }

    onEmptyValueTokenChange(value: string): void {

        let changes = { emptyValueTokens: value } as IngestionConfig;

        this.props.onChange(changes);
    }

    onTransposedDataChange(e: React.ChangeEvent<HTMLInputElement>): void {

        let changes = { isTransposed: e.target.checked } as IngestionConfig;

        this.props.onChange(changes);
    }

    render(): JSX.Element {
        const labelClass = 'col-5';
        const contentClass = 'col-7';

        return (
            <>
                <FormGroup label="File extension:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.fileType}>
                    <input
                        className="form-control"
                        type="text"
                        readOnly={true}
                        defaultValue={this.props.config.fileType} />
                </FormGroup>

                <FormGroup label="Config Name:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.name}>
                    <input
                        type="text"
                        className="form-control"
                        value={this.props.config.name}
                        onChange={this.onNameChange}
                        disabled={this.props.isReadOnly} />
                </FormGroup>

                <FormGroup label="File Names:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.filenames}>
                    <SeparatedValueBuilder
                        isReadOnly={this.props.isReadOnly}
                        items={this.props.config.filenames}
                        suffix={`.${isSourcedFromMasterFile(this.props.config) ? this.state.source : this.props.config.fileType}`}
                        validate={validateFilenames}
                        onChange={this.onFilenamesChange} />
                </FormGroup>
                {
                    this.props.config.fileType === fileExtension.csv &&
                    <FormGroup label="Sourced From:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.sourcedFrom}>

                        <div className="mt-2">
                            <RadioButtonGroup
                                options={sourceOptions}
                                value={this.state.source}
                                onChange={this.onSourceChange}
                                isDisabled={() => this.props.isReadOnly}
                                name="sourced-from" />
                        </div>
                    </FormGroup>
                }
                {
                    this.state.source !== fileExtension.csv &&
                    <FormGroup label={`${this.state.source.toUpperCase()} Section Name:`} labelClassName={labelClass} contentClassName={contentClass} info={guidance.masterFileLocation}>
                        <input
                            type="text"
                            className="form-control"
                            value={resolveMasterFileLocationValue(this.state.source, this.props.config)}
                            onChange={this.onMasterFileLocationChange}
                            disabled={this.props.isReadOnly} />
                    </FormGroup>
                }

                <FormGroup label="Description:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.description}>
                    <textarea
                        className="form-control"
                        value={this.props.config.description}
                        onChange={this.onDescriptionChange}
                        disabled={this.props.isReadOnly}>
                    </textarea>
                </FormGroup>

                <FormGroup label="Data type:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.dataSourceKind}>
                    <SelectList
                        className="form-control"
                        options={ingestionDataTypeOptions}
                        value={this.props.config.dataSourceKind}
                        onChange={this.onDataSourceKindChange}
                        disabled={this.props.isReadOnly} />
                </FormGroup>

                <FormGroup label="Empty value tokens:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.emptyValueToken}>
                    <SeparatedValueBuilder
                        isReadOnly={this.props.isReadOnly}
                        items={this.props.config.emptyValueTokens || ''}
                        suffix={null}
                        validate={validateEmptyValueTokens}
                        onChange={this.onEmptyValueTokenChange} />
                </FormGroup>

                {
                    this.props.config.fileType === fileExtension.json &&
                    <FormGroup label="Transposed Json:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.transposedData}>
                        <input
                            style={{ marginTop: '12px' }}
                            type="checkbox"
                            disabled={this.props.isReadOnly}
                            checked={this.props.config.isTransposed || false}
                            onChange={this.onTransposedDataChange} />
                    </FormGroup>
                }
            </>
        );
    }
}

export default IngestionDetails;
