import appRoutes from '../infrastructure/constants/appRoutes';
import RouteSettings from './RouteSettings';
import typeHelper from '../infrastructure/helpers/common/typeHelper';

const overrides: RouteSettings[] = [
    {
        route: appRoutes.home,
        hasSubHeader: false,
        hasBackground: true
    },
    {
        route: appRoutes.reporting.edit,
        hasSubHeader: true,
        hasBackground: false
    },
    {
        route: appRoutes.validation.edit,
        hasSubHeader: true,
        hasBackground: false
    }
];

const fallback: RouteSettings = {
    route: null as any,
    hasSubHeader: false,
    hasBackground: false
};

const getRouteSettings = (route: string): RouteSettings => {

    let override = overrides.find(x => x.route === route);

    if (typeHelper.isObject(override)) {

        return override as RouteSettings;
    }

    return fallback;
};

export { getRouteSettings };
