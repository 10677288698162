import { dateFormats, timeFormats, dateTimeSeparators } from '../../dateTimeFormats';
import { firdsResultSetTypes, specialOperandTypes } from '../specialOperandTypes';
import booleanFormatString from '../formatString/types/booleanFormatString';
import booleanLiteral from '../booleanLiteral';
import dataType from '../../dataType';
import FunctionDefinition from '../../../../types/functions/FunctionDefinition';
import functionName from '../definition/functionName';
import operandSpecialValueType from '../operandSpecialValueType';
import publicHolidayCountries from '../../publicHolidayCountries';
import publicHolidayCurrencies from '../../publicHolidayCurrencies';
import specialReturnType from './specialReturnType';
import operandType from '../operandType';
import sortDirections from '../../sortDirections';
import daysOfWeek from '../../daysOfWeek';
import numberOfWeeksInMonth from '../../numberOfWeeksInMonth';

const timeZoneDefault = 'UTC';

const roundDecimalPointDefault = '2';

const inDelimiter = ';';
const joinDelimiter = '|';

const cnProductFieldDefault = 'Derivative';

const allDataTypes = Object.values(dataType);

const arrayDataTypes = allDataTypes.filter(x => x.startsWith('Array'));
const nonArrayDataTypes = allDataTypes.filter(x => !x.startsWith('Array'));

const functionDefinitions: FunctionDefinition[] = [
    {
        name: functionName.startsWith,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'value', isArray: true, minArrayArgumentCount: 1, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Determines whether the beginning of a string matches a specified value',
        isMethod: true
    },
    {
        name: functionName.endsWith,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'value', isArray: true, minArrayArgumentCount: 1, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Determines whether the end of a string matches a specified value',
        isMethod: true
    },
    {
        name: functionName.contains,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'value', isArray: true, minArrayArgumentCount: 1, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Determines whether a specified value occurs within a string',
        isMethod: true
    },
    {
        name: functionName.toUpper,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns an uppercase variant of a specified value',
        isMethod: true
    },
    {
        name: functionName.toLower,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns a lowercase variant of a specified value',
        isMethod: true
    },
    {
        name: functionName.trim,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Removes all the leading and trailing white-space characters from a string value',
        isMethod: true
    },
    {
        name: functionName.trimStart,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Removes all the leading white-space characters from a string value',
        isMethod: true
    },
    {
        name: functionName.trimEnd,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Removes all the trailing white-space characters from a string value',
        isMethod: true
    },
    {
        name: functionName.substring,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.integer], name: 'startIndex', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns a substring from a string source. The substring starts at a specified character index (zero-based) and continues to the end of the string',
        isMethod: true
    },
    {
        name: functionName.substringLength,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.integer], name: 'startIndex', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.integer], name: 'length', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns a substring from a string source. The substring starts at a specified character index (zero-based) and has a specified length',
        isMethod: true
    },
    {
        name: functionName.replace,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'oldValue', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'newValue', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns a new variant of a string where all occurrences of a specified old value are replaced with a specified new value. Replace is case sensitive',
        isMethod: true
    },
    {
        name: functionName.replaceIgnoreCase,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'oldValue', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'newValue', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.boolean], name: 'ignoreCase', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: booleanLiteral.true }
        ],
        description: 'Returns a new variant of a string where all occurrences of a specified old value are replaced with a specified new value. When comparing case is/not ignored based on a specified Boolean value',
        isMethod: true
    },
    {
        name: functionName.splitAndPick,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'delimiter', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.integer], name: 'index', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Splits a string into substrings by a specified delimiter value and returns one of the substrings by a specified index (zero-based) value',
        isMethod: true
    },
    {
        name: functionName.sum,
        returnType: dataType.decimal,
        arguments: [
            { allowedTypes: [dataType.decimal, dataType.integer], name: 'summand', isArray: true, minArrayArgumentCount: 2, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Computes the sum of a sequence of summand values',
        isMethod: false
    },
    {
        name: functionName.subtract,
        returnType: dataType.decimal,
        arguments: [
            { allowedTypes: [dataType.decimal, dataType.integer], name: 'minuend', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.decimal, dataType.integer], name: 'subtrahend', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Computes the difference of specified minuend and subtrahend values',
        isMethod: false
    },
    {
        name: functionName.multiply,
        returnType: dataType.decimal,
        arguments: [
            { allowedTypes: [dataType.decimal, dataType.integer], name: 'multiplier', isArray: true, minArrayArgumentCount: 2, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Computes the product of a sequence of multiplier values',
        isMethod: false
    },
    {
        name: functionName.divide,
        returnType: dataType.decimal,
        arguments: [
            { allowedTypes: [dataType.decimal, dataType.integer], name: 'dividend', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.decimal, dataType.integer], name: 'divisor', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Computes the fraction of specified dividend and divisor values',
        isMethod: false
    },
    {
        name: functionName.power,
        returnType: dataType.decimal,
        arguments: [
            { allowedTypes: [dataType.decimal, dataType.integer], name: 'base', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.decimal, dataType.integer], name: 'exponent', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Computes the power of a specified base value raised by a specified exponent value',
        isMethod: false
    },
    {
        name: functionName.absolute,
        returnType: dataType.decimal,
        arguments: [
            { allowedTypes: [dataType.decimal, dataType.integer], name: 'number', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns the absolute value of a specified number',
        isMethod: false
    },
    {
        name: functionName.toString,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.decimal, dataType.integer], name: 'number', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Converts a number to string',
        isMethod: true
    },
    {
        name: functionName.toStringBoolean,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.boolean],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringBoolean,
                defaultValue: booleanFormatString.singleLetter
            }
        ],
        description: 'Converts a Boolean value to string based on a specified format string',
        isMethod: true
    },
    {
        name: functionName.toStringDateTime,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.dateTime],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringDateTime,
                defaultValue: dateFormats[0] + dateTimeSeparators[0] + timeFormats[0]
            }
        ],
        description: 'Converts a DateTime value to string based on a specified format string',
        isMethod: true
    },
    {
        name: functionName.toStringDate,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.date],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringDate,
                defaultValue: dateFormats[0]
            }
        ],
        description: 'Converts a Date value to string based on a specified format string',
        isMethod: true
    },
    {
        name: functionName.toStringTime,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.time],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringTime,
                defaultValue: timeFormats[0]
            }
        ],
        description: 'Converts a Time value to string based on a specified format string',
        isMethod: true
    },
    {
        name: functionName.toDateTime,
        returnType: dataType.dateTime,
        arguments: [
            { allowedTypes: [dataType.unixTime], name: 'unixTime', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Converts a UnixDateTime to DateTime based on specified',
        isMethod: true
    },
    {
        name: functionName.toInteger,
        returnType: dataType.integer,
        arguments: [
            { allowedTypes: [dataType.decimal, dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Converts a Decimal or String value to Integer',
        isMethod: true
    },
    {
        name: functionName.toDecimal,
        returnType: dataType.decimal,
        arguments: [
            { allowedTypes: [dataType.integer, dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Converts an Integer or String value to Decimal',
        isMethod: true
    },
    {
        name: functionName.in,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'sequenceItem', isArray: true, minArrayArgumentCount: 2, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Determines whether a specified string value is contained in a sequence of string items',
        isMethod: true
    },
    {
        name: functionName.concatenate,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'part', isArray: true, minArrayArgumentCount: 2, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Concatenates a sequence of string items into a new string',
        isMethod: false
    },
    {
        name: functionName.sortAndPickFirst,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.boolean], name: 'ascending', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: booleanLiteral.true },
            { allowedTypes: [dataType.string], name: 'sequenceItem', isArray: true, minArrayArgumentCount: 2, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Orders a sequence of string items alphabetically and returns the first one. The sequence is ordered in ascending or descending direction based on a specified Boolean value',
        isMethod: false
    },
    {
        name: functionName.hasValue,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: allDataTypes,
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Determines whether an instance has any value or is empty',
        isMethod: true
    },
    {
        name: functionName.convertFromTimeZone,
        returnType: dataType.dateTime,
        arguments: [
            { allowedTypes: [dataType.dateTime], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'fromTimeZone', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: operandSpecialValueType.timeZone, defaultValue: timeZoneDefault },
            { allowedTypes: [dataType.string], name: 'toTimeZone', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: operandSpecialValueType.timeZone, defaultValue: timeZoneDefault },
            { allowedTypes: [dataType.integer], name: 'TimeOffSet', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: "0" }
        ],
        description: 'Converts a DateTime value from one time zone to another time zone.',
        isMethod: true
    },
    {
        name: functionName.stripTime,
        returnType: dataType.date,
        arguments: [
            { allowedTypes: [dataType.dateTime], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns an instance containing only the Date part of a DateTime value. Time part is stripped',
        isMethod: true
    },
    {
        name: functionName.length,
        returnType: dataType.integer,
        arguments: [
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns the number of characters in a specified value',
        isMethod: true
    },
    {
        name: functionName.remove,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'valueToBeRemoved', isArray: true, minArrayArgumentCount: 1, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns a new variant of a string where all specified values are removed',
        isMethod: true
    },
    {
        name: functionName.removeNonAlphanumeric,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns a new variant of a string where all non-alphanumeric characters are removed',
        isMethod: true
    },
    {
        name: functionName.inInteger,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.integer], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.integer], name: 'sequenceItem', isArray: true, minArrayArgumentCount: 2, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Determines whether a specified integer value is contained in a sequence of integer items',
        isMethod: true
    },
    {
        name: functionName.addTime,
        returnType: dataType.dateTime,
        arguments: [
            { allowedTypes: [dataType.date], name: 'date', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.time], name: 'time', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns a DateTime value as a result of merging Date value and a Time value',
        isMethod: true
    },
    {
        name: functionName.addDays,
        returnType: dataType.dateTime,
        arguments: [
            {
                allowedTypes: [
                    dataType.dateTime,
                    dataType.date
                ],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.integer],
                name: 'days',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns a DateTime value with a number of days added. Pass negative days value for subtraction.',
        isMethod: true
    },
    {
        name: functionName.first,
        returnType: specialReturnType.generic,
        returnTypeArgumentIndex: 1,
        arguments: [
            {
                allowedTypes: [],
                name: 'set',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: specialOperandTypes,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: allDataTypes,
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns a value based on the first record in a data set',
        isMethod: true
    },
    {
        name: functionName.last,
        returnType: specialReturnType.generic,
        returnTypeArgumentIndex: 1,
        arguments: [
            {
                allowedTypes: [],
                name: 'set',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: specialOperandTypes,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: allDataTypes,
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns a value based on the last record in a data set',
        isMethod: true
    },
    {
        name: functionName.sumAggregate,
        returnType: specialReturnType.generic,
        arguments: [
            {
                allowedTypes: [],
                name: 'set',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: specialOperandTypes,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.decimal, dataType.integer],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Computes the sum of values aggregated based on each record from a data set',
        isMethod: true
    },
    {
        name: functionName.count,
        returnType: dataType.integer,
        arguments: [
            {
                allowedTypes: [],
                name: 'set',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: specialOperandTypes,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Counts all records in a data set',
        isMethod: true
    },
    {
        name: functionName.countHasValue,
        returnType: dataType.integer,
        arguments: [
            {
                allowedTypes: [],
                name: 'set',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: specialOperandTypes,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: allDataTypes,
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Counts all records (that have value for a particular field) in a data set',
        isMethod: true
    },
    {
        name: functionName.countDistinct,
        returnType: dataType.integer,
        arguments: [
            {
                allowedTypes: [],
                name: 'set',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: specialOperandTypes,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: allDataTypes,
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Counts all records (distinct by a particular field) in a data set',
        isMethod: true
    },
    {
        name: functionName.getUtcDateTime,
        returnType: dataType.dateTime,
        arguments: [],
        description: 'Returns current UTC DateTime value',
        isMethod: false
    },
    {
        name: functionName.activeOnTradingDate,
        returnType: dataType.boolean,
        hiddenDataTypeArguments: [
            'String',
            'Boolean'
        ],
        arguments: [
            {
                allowedTypes: [],
                name: 'firdsSet',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: firdsResultSetTypes,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.dateTime],
                name: 'tradingDate',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns True if there is at least one FIRDS record that was active on the passed trading date',
        isMethod: true
    },
    {
        name: functionName.min,
        returnType: specialReturnType.generic,
        returnTypeArgumentIndex: 1,
        arguments: [
            {
                allowedTypes: [],
                name: 'set',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: specialOperandTypes,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string, dataType.integer, dataType.decimal, dataType.date, dataType.time, dataType.dateTime],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns the minimum value in a data set',
        isMethod: true
    },
    {
        name: functionName.max,
        returnType: specialReturnType.generic,
        returnTypeArgumentIndex: 1,
        arguments: [
            {
                allowedTypes: [],
                name: 'set',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: specialOperandTypes,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string, dataType.integer, dataType.decimal, dataType.date, dataType.time, dataType.dateTime],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns the maximum value in a data set',
        isMethod: true
    }, 
    {
        name: functionName.isDaylightSaving,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.dateTime], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'timeZone', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: operandSpecialValueType.timeZone, defaultValue: timeZoneDefault }
        ],
        description: 'Determines whether a specified dateTime value is in the range of daylight saving time for a particular time zone',
        isMethod: true
    },
    {
        name: functionName.weekDay,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.date, dataType.dateTime], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns the day of week of a date value ("Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday")',
        isMethod: true
    },
    {
        name: functionName.stripMilliseconds,
        returnType: dataType.dateTime,
        arguments: [
            { allowedTypes: [dataType.dateTime], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns an instance with stripped milliseconds.',
        isMethod: true
    },
    {
        name: functionName.round,
        returnType: dataType.decimal,
        arguments: [
            { allowedTypes: [dataType.decimal], name: 'number', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.integer], name: 'decimalPoints', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: roundDecimalPointDefault }
        ],
        description: 'Rounds a decimal value to a specified number of decimal points. Uses \'Away From Zero\' rounding strategy.',
        isMethod: true
    },
    {
        name: functionName.roundToEven,
        returnType: dataType.decimal,
        arguments: [
            { allowedTypes: [dataType.decimal], name: 'number', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.integer], name: 'decimalPoints', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: roundDecimalPointDefault }
        ],
        description: 'Rounds a decimal value to a specified number of decimal points. Uses \'To Even\' rounding strategy.',
        isMethod: true
    },
    {
        name: functionName.roundUp,
        returnType: dataType.decimal,
        arguments: [
            { allowedTypes: [dataType.decimal], name: 'number', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.integer], name: 'decimalPoints', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: roundDecimalPointDefault }
        ],
        description: 'Rounds Up a decimal value to a specified number of decimal points.',
        isMethod: true
    },
    {
        name: functionName.roundDown,
        returnType: dataType.decimal,
        arguments: [
            { allowedTypes: [dataType.decimal], name: 'number', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.integer], name: 'decimalPoints', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: roundDecimalPointDefault }
        ],
        description: 'Rounds Down a decimal value to a specified number of decimal points.',
        isMethod: true
    },
    {
        name: functionName.concatenateDelimiter,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'delimiter', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'part', isArray: true, minArrayArgumentCount: 2, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Concatenates a sequence of string items with the provided delimiter',
        isMethod: false
    },
    {
        name: functionName.padLeft,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.integer], name: 'length', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'character', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns a new string of a specified length where the beginning is padded with the provided character',
        isMethod: true
    },
    {
        name: functionName.padRight,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.integer], name: 'length', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'character', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns a new string of a specified length where the end is padded with the provided character',
        isMethod: true
    },
    {
        name: functionName.findFirst,
        returnType: dataType.integer,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns the position of the first occurrence of specified character(s) in a string',
        isMethod: true
    },
    {
        name: functionName.findLast,
        returnType: dataType.integer,
        arguments: [
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Returns the position of the last occurrence of specified character(s) in a string',
        isMethod: true
    },
    {
        name: functionName.cnProductMatch,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.string], name: 'cfiCode', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'field', isArray: true, minArrayArgumentCount: 1, specialOperandTypes: null, specialValueType: operandSpecialValueType.cnProductField, defaultValue: cnProductFieldDefault }
        ],
        description: 'Determines whether the provided cfi code matches a record with value \'Y\' for any of the provided fields',
        isMethod: true
    },
    {
        name: functionName.inDelimited,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'source', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'delimiter', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: inDelimiter }
        ],
        description: 'Determines whether a specified string value is contained in a sequence of delimited values',
        isMethod: true
    },
    {
        name: functionName.join,
        returnType: dataType.string,
        arguments: [
            { allowedTypes: [], name: 'set', isArray: false, minArrayArgumentCount: null, specialOperandTypes: specialOperandTypes, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [dataType.string], name: 'delimiter', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: joinDelimiter }
        ],
        description: 'Concatenates a sequence of string items with the provided delimiter',
        isMethod: true
    },
    {
        name: functionName.addDaysBusiness,
        returnType: dataType.dateTime,
        arguments: [
            {
                allowedTypes: [
                    dataType.dateTime,
                    dataType.date
                ],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.integer],
                name: 'days',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'country',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.publicHolidayCountry,
                defaultValue: publicHolidayCountries[0]
            }
        ],
        description: 'Returns a DateTime value with a number of business days added according to the provided country. Pass negative days value for subtraction.',
        isMethod: true
    },
    {
        name: functionName.lastBusinessDayOfMonth,
        returnType: dataType.date,
        arguments: [
            {
                allowedTypes: [
                    dataType.date
                ],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'country',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.publicHolidayCountry,
                defaultValue: publicHolidayCountries[0]
            }
        ],
        description: 'Returns a Date value for last business day for the provided country.',
        isMethod: true
    }, 
    {
        name: functionName.isBusinessDay,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.date],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'country',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.publicHolidayCountry,
                defaultValue: publicHolidayCountries[0]
            }
        ],
        description: 'Determines whether a specified dateTime value is in the range of bissiness days for a particular time zone',
        isMethod: true
    },
    {
        name: functionName.specificBusinessDay,
        returnType: dataType.date,
        arguments: [
            {
                allowedTypes: [
                    dataType.date
                ],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'dayOfWeek',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.daysOfWeek,
                defaultValue: daysOfWeek[0]
            },
            {
                allowedTypes:  [dataType.integer],
                name: 'weekNumber',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.numberOfWeeksInMonth,
                defaultValue: numberOfWeeksInMonth[0]
            }
        ],
        description: 'Returns a Date value for specific week day.',
        isMethod: true
    }, 
    {
        name: functionName.addDaysFx,
        returnType: dataType.dateTime,
        arguments: [
            {
                allowedTypes: [
                    dataType.dateTime,
                    dataType.date
                ],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.integer],
                name: 'days',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'currency1',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.publicHolidayCurrency,
                defaultValue: publicHolidayCurrencies[0]
            },
            {
                allowedTypes: [dataType.string],
                name: 'currency2',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.publicHolidayCurrency,
                defaultValue: publicHolidayCurrencies[1]
            }
        ],
        description: 'Returns a DateTime value with a number of business days added according to the provided FX calendars. Pass negative days value for subtraction. Each FX pair has its own FX calendar based upon the country of currency\'s bank holiday schedule.',
        isMethod: true
    },
    {
        name: functionName.isIsin,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.string], name: 'isin', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Determines whether an isin has valid value',
        isMethod: true
    },
    {
        name: functionName.isAlphabetic,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.string], name: 'input', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Determines whether the input contains only alphabetic characters',
        isMethod: true
    },
    {
        name: functionName.isNumeric,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.string], name: 'input', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Determines whether the input contains only digits',
        isMethod: true
    },
    {
        name: functionName.checkDateTimeFormat,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.date, dataType.time, dataType.dateTime,dataType.string], name: 'input', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null },
            { allowedTypes: [ dataType.string], name: 'format', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: operandSpecialValueType.checkDateTimeFormat, defaultValue: 'yyyy-MM-ddTHH:mm' },
        ],
        description: 'Determines whether the input is in specified format',
        isMethod: false
    },
    {
        name: functionName.average,
        returnType: dataType.decimal,
        arguments: [
            {
                allowedTypes: [],
                name: 'set',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: specialOperandTypes,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.decimal, dataType.integer],
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns the average value aggregated based on each record from a data set',
        isMethod: true
    },
    {
        name: functionName.endpointMinTradingDate,
        returnType: dataType.dateTime,
        arguments: [],
        description: 'Returns the Min value of trading date from end point file after endpoint filtering logic executed.',
        isMethod: false
    },
    {
        name: functionName.endpointMaxTradingDate,
        returnType: dataType.dateTime,
        arguments: [],
        description: 'Returns the Max value of trading date from end point file after endpoint filtering logic executed.',
        isMethod: false
    },
    {
        name: functionName.fuzziness,
        returnType: dataType.decimal,
        arguments: [
            {
                allowedTypes: [dataType.string],
                name: 'first',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'second',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Calculate fuzziness (based on algorithm Levenshtein distance) for two input strings and returns a percentage of similarity.',
        isMethod: false
    },
    {
        name: functionName.rate,
        returnType: dataType.decimal,
        arguments: [
            {
                allowedTypes: [],
                name: 'firdsSet',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: [operandType.exchangeRatesResultSet],
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.date],
                name: 'tradingDate',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns Rate from ExchangeRate on the passed trading date',
        isMethod: true
    },
    {
        name: functionName.toArray,
        returnType: dataType.arrayOfStrings,
        arguments: [
            {
                allowedTypes: [dataType.string],
                name: 'input',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            }
        ],
        description: 'Returns array of strings based on an input string split by a delimiter',
        isMethod: false
    },
    {
        name: functionName.toStringArrayOfStrings,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfStrings],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            }
        ],
        description: 'Converts array of strings to string by joining with a delimiter',
        isMethod: true
    },
    {
        name: functionName.toArrayOfIntegers,
        returnType: dataType.arrayOfIntegers,
        arguments: [
            {
                allowedTypes: [dataType.string],
                name: 'input',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            }
        ],
        description: 'Returns array of integers based on an input string split by a delimiter',
        isMethod: false
    },
    {
        name: functionName.toStringArrayOfIntegers,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfIntegers],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            }
        ],
        description: 'Converts array of integers to string by joining with a delimiter',
        isMethod: true
    },
    {
        name: functionName.toArrayOfDecimals,
        returnType: dataType.arrayOfDecimals,
        arguments: [
            {
                allowedTypes: [dataType.string],
                name: 'input',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            }
        ],
        description: 'Returns array of decimals based on an input string split by a delimiter',
        isMethod: false
    },
    {
        name: functionName.toStringArrayOfDecimals,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfDecimals],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            }
        ],
        description: 'Converts array of decimals to string by joining with a delimiter',
        isMethod: true
    },
    {
        name: functionName.toArrayOfBooleans,
        returnType: dataType.arrayOfBooleans,
        arguments: [
            {
                allowedTypes: [dataType.string],
                name: 'input',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringBoolean,
                defaultValue: booleanFormatString.singleLetter
            }
        ],
        description: 'Returns array of booleans based on an input string split by a delimiter',
        isMethod: false
    },
    {
        name: functionName.toStringArrayOfBooleans,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfBooleans],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringBoolean,
                defaultValue: booleanFormatString.singleLetter
            }
        ],
        description: 'Converts array of booleans to string by joining with a delimiter',
        isMethod: true
    },
    {
        name: functionName.toArrayOfDates,
        returnType: dataType.arrayOfDates,
        arguments: [
            {
                allowedTypes: [dataType.string],
                name: 'input',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringDate,
                defaultValue: dateFormats[0]
            }
        ],
        description: 'Returns array of dates based on an input string split by a delimiter',
        isMethod: false
    },
    {
        name: functionName.toStringArrayOfDates,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfDates],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringDate,
                defaultValue: dateFormats[0]
            }
        ],
        description: 'Converts array of dates to string by joining with a delimiter',
        isMethod: true
    },
    {
        name: functionName.toArrayOfTimes,
        returnType: dataType.arrayOfTimes,
        arguments: [
            {
                allowedTypes: [dataType.string],
                name: 'input',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringTime,
                defaultValue: timeFormats[0]
            }
        ],
        description: 'Returns array of times based on an input string split by a delimiter',
        isMethod: false
    },
    {
        name: functionName.toStringArrayOfTimes,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfTimes],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringTime,
                defaultValue: timeFormats[0]
            }
        ],
        description: 'Converts array of times to string by joining with a delimiter',
        isMethod: true
    },
    {
        name: functionName.toArrayOfDateTimes,
        returnType: dataType.arrayOfDateTimes,
        arguments: [
            {
                allowedTypes: [dataType.string],
                name: 'input',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringDateTime,
                defaultValue: dateFormats[0] + dateTimeSeparators[0] + timeFormats[0]
            }
        ],
        description: 'Returns array of datetimes based on an input string split by a delimiter',
        isMethod: false
    },
    {
        name: functionName.toStringArrayOfDateTimes,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfDateTimes],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringDateTime,
                defaultValue: dateFormats[0] + dateTimeSeparators[0] + timeFormats[0]
            }
        ],
        description: 'Converts array of datetimes to string by joining with a delimiter',
        isMethod: true
    },
    {
        name: functionName.toArrayOfUnixTimes,
        returnType: dataType.arrayOfUnixTimes,
        arguments: [
            {
                allowedTypes: [dataType.string],
                name: 'input',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            }
        ],
        description: 'Returns array of unix times based on an input string split by a delimiter',
        isMethod: false
    },
    {
        name: functionName.toStringArrayOfUnixTimes,
        returnType: dataType.string,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfUnixTimes],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'delimiter',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: '|'
            }
        ],
        description: 'Converts array of unix times to string by joining with a delimiter',
        isMethod: true
    },
    {
        name: functionName.equal,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: nonArrayDataTypes,
                name: 'left',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: nonArrayDataTypes,
                name: 'right',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns TRUE if the left operand is equal to the right operand',
        isMethod: false,
        operator: '='
    },
    {
        name: functionName.notEqual,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: nonArrayDataTypes,
                name: 'left',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: nonArrayDataTypes,
                name: 'right',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns TRUE if the left operand is not equal to the right operand',
        isMethod: false,
        operator: '!='
    },
    {
        name: functionName.greaterThan,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: nonArrayDataTypes,
                name: 'left',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: nonArrayDataTypes,
                name: 'right',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns TRUE if the left operand is greater than the right operand',
        isMethod: false,
        operator: '>'
    },
    {
        name: functionName.lessThan,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: nonArrayDataTypes,
                name: 'left',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: nonArrayDataTypes,
                name: 'right',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns TRUE if the left operand is less than the right operand',
        isMethod: false,
        operator: '<'
    },
    {
        name: functionName.greaterOrEqualThan,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: nonArrayDataTypes,
                name: 'left',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: nonArrayDataTypes,
                name: 'right',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns TRUE if the left operand is greater than or equal to the right operand',
        isMethod: false,
        operator: '>='
    },
    {
        name: functionName.lessOrEqualThan,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: nonArrayDataTypes,
                name: 'left',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: nonArrayDataTypes,
                name: 'right',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Returns TRUE if the left operand is less than or equal to the right operand',
        isMethod: false,
        operator: '<='
    },
    {
        name: functionName.and,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.boolean],
                name: 'left',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'right',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'All conditions combined by AND must be true to return TRUE',
        isMethod: false,
        operator: 'AND'
    },
    {
        name: functionName.or,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.boolean],
                name: 'left',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'right',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            }
        ],
        description: 'Any conditions combined by OR if true will return TRUE',
        isMethod: false,
        operator: 'OR'
    },
    {
        name: functionName.all,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfStrings],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.string
            }
        ],
        description: 'Returns TRUE if all Array.[Item] satisfy the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.any,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfStrings],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.string
            }
        ],
        description: 'Returns TRUE if any Array.[Item] satisfies the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.allInteger,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfIntegers],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.integer
            }
        ],
        description: 'Returns TRUE if all Array.[Item] satisfy the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.anyInteger,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfIntegers],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.integer
            }
        ],
        description: 'Returns TRUE if any Array.[Item] satisfies the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.allDecimal,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfDecimals],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.decimal
            }
        ],
        description: 'Returns TRUE if all Array.[Item] satisfy the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.anyDecimal,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfDecimals],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.decimal
            }
        ],
        description: 'Returns TRUE if any Array.[Item] satisfies the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.allBoolean,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfBooleans],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.boolean
            }
        ],
        description: 'Returns TRUE if all Array.[Item] satisfy the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.anyBoolean,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfBooleans],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.boolean
            }
        ],
        description: 'Returns TRUE if any Array.[Item] satisfies the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.allDate,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfDates],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.date
            }
        ],
        description: 'Returns TRUE if all Array.[Item] satisfy the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.anyDate,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfDates],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.date
            }
        ],
        description: 'Returns TRUE if any Array.[Item] satisfies the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.allTime,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfTimes],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.time
            }
        ],
        description: 'Returns TRUE if all Array.[Item] satisfy the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.anyTime,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfTimes],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.time
            }
        ],
        description: 'Returns TRUE if any Array.[Item] satisfies the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.allDateTime,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfDateTimes],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.dateTime
            }
        ],
        description: 'Returns TRUE if all Array.[Item] satisfy the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.anyDateTime,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfDateTimes],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.dateTime
            }
        ],
        description: 'Returns TRUE if any Array.[Item] satisfies the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.allUnixTime,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfUnixTimes],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.unixTime
            }
        ],
        description: 'Returns TRUE if all Array.[Item] satisfy the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.anyUnixTime,
        returnType: dataType.boolean,
        arguments: [
            {
                allowedTypes: [dataType.arrayOfUnixTimes],
                name: 'array',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.boolean],
                name: 'logic',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null,
                arrayItemType: dataType.unixTime
            }
        ],
        description: 'Returns TRUE if any Array.[Item] satisfies the given condition(s)',
        isMethod: true,
    },
    {
        name: functionName.firstOrdered,
        returnType: specialReturnType.generic,
        returnTypeArgumentIndex: 1,
        arguments: [
            {
                allowedTypes: [],
                name: 'set',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: specialOperandTypes,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: nonArrayDataTypes,
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: nonArrayDataTypes,
                name: 'orderBy',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'direction',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.sortDirection,
                defaultValue: sortDirections[0]
            }
        ],
        description: 'Returns a value based on the first record in an ordered data set',
        isMethod: true
    },
    {
        name: functionName.lastOrdered,
        returnType: specialReturnType.generic,
        returnTypeArgumentIndex: 1,
        arguments: [
            {
                allowedTypes: [],
                name: 'set',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: specialOperandTypes,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: nonArrayDataTypes,
                name: 'value',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: nonArrayDataTypes,
                name: 'orderBy',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'direction',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.sortDirection,
                defaultValue: sortDirections[0]
            }
        ],
        description: 'Returns a value based on the last record in an ordered data set',
        isMethod: true
    },
    {
        name: functionName.toDateTimeString,
        returnType: dataType.dateTime,
        arguments: [
            {
                allowedTypes: [dataType.string],
                name: 'input',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: null,
                defaultValue: null
            },
            {
                allowedTypes: [dataType.string],
                name: 'format',
                isArray: false,
                minArrayArgumentCount: null,
                specialOperandTypes: null,
                specialValueType: operandSpecialValueType.formatStringDateTime,
                defaultValue: dateFormats[0] + dateTimeSeparators[0] + timeFormats[0]
            }
        ],
        description: 'Converts a string to DateTime based on specified format',
        isMethod: true
    },
    {
        name: functionName.isBlank,
        returnType: dataType.boolean,
        arguments: [
            { allowedTypes: [dataType.string], name: 'value', isArray: false, minArrayArgumentCount: null, specialOperandTypes: null, specialValueType: null, defaultValue: null }
        ],
        description: 'Determines whether a string is blank',
        isMethod: true
    },
];

export default functionDefinitions;
