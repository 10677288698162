import { ReactComponent as AssignmentIcon } from '../../../Common/BootstrapIcons/assignment-late.svg';
import { ReactComponent as CommentIcon } from '../../../Common/BootstrapIcons/comment.svg';
import { ReactComponent as CopyIcon } from '../../../Common/BootstrapIcons/copy.svg';
import { ReactComponent as InfoIcon } from '../../../Common/BootstrapIcons/info.svg';
import { ReactComponent as LinkIcon } from '../../../Common/BootstrapIcons/link.svg';
import BespokeToggleButton from '../../../Functions/Bespoke/BespokeToggleButton';
import ColumnDefinition from '../../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../../Common/BootstrapIcons/Custom/CustomIcon';
import iconTooltip from '../../../Common/BootstrapIcons/Tooltip/IconTooltip';
import LazySelectList from '../../../Common/LazySelectList/LazySelectList';
import PromiseButton from '../../../Common/PromiseButton';
import React from 'react';
import reportConfigNavigation from '../../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import SelectOption from '../../../../infrastructure/types/SelectOption';

const getColumns = (
    onInfoClick: (id: number) => Promise<any>,
    onCommentClick: (id: number) => Promise<any>,
    onUrlClick: (id: number) => Promise<any>,
    onActionItemClick: (id: number) => Promise<any>,
    onCopyFieldClick: (id: number) => void,
    onBespokeToggleClick: (isBespoke: boolean, definition: ReportFieldDefinition) => void,
    getFieldReferences: (id: number) => SelectOption[],
    getCommentCount: (id: number) => number,
    getUrlCount: (id: number) => number,
    getActionItemCount: (id: number) => number,
    isBespoke: (id: number) => boolean,
    isReadOnly: boolean,
    fieldNumberHeaderName:string
): ColumnDefinition<ReportFieldDefinition>[] => {

    let columns: ColumnDefinition<ReportFieldDefinition>[] = [
        {
            header: 'Reference',
            getContent: x => x.number.toString(),
            width: '110px'
        },
        {
            header: `${fieldNumberHeaderName}`,
            getContent: x => x.rtsFieldNumber === undefined ? '' : x.rtsFieldNumber.toString(),
            width: '110px'
        },
        {
            header: 'Field Name',
            getContent: x => x.name,
            width: '260px',
            className: 'break-all'
        },
        {
            header: 'CN Field Format',
            getContent: x =>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: x.format }}></div>
                </div>
        },
        {
            header: '',
            getContent: x =>
                <>
                    <LazySelectList
                        className="inline-select"
                        label="References"
                        width={106}
                        getOptions={() => getFieldReferences(x.id)}
                        onSelected={value => reportConfigNavigation.navigateToItem(value)} />
                    {
                        !isReadOnly &&
                        <BespokeToggleButton
                            isBespoke={isBespoke(x.id)}
                            onClick={isBespoke => onBespokeToggleClick(isBespoke, x)}
                            style={{ verticalAlign: 'top', width: '150px' }}/>
                    }
                    <div className="float-right">
                        <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onInfoClick(x.id)}>
                            <CustomIcon icon={InfoIcon} title={iconTooltip.info} />
                        </PromiseButton>
                        <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onUrlClick(x.id)}>
                            <CustomIcon icon={LinkIcon} count={getUrlCount(x.id)} title={iconTooltip.complianceGuidance} />
                        </PromiseButton>
                        <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onCommentClick(x.id)}>
                            <CustomIcon icon={CommentIcon} count={getCommentCount(x.id)} title={iconTooltip.comments} />
                        </PromiseButton>
                        <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onActionItemClick(x.id)}>
                            <CustomIcon icon={AssignmentIcon} count={getActionItemCount(x.id)} title={iconTooltip.actionLog} />
                        </PromiseButton>
                        <button className="custom-icon-button" onClick={() => onCopyFieldClick(x.id)} disabled={isReadOnly}>
                            <CustomIcon icon={CopyIcon} title={iconTooltip.copy} />
                        </button>
                    </div>
                </>,
            width: '430px'
        }
    ];

    return columns;
};

export {
    getColumns
};