import CustomDataSourceFieldDefinition from '../../../../types/report/CustomDataSourceFieldDefinition';
import SelectOption from '../../../../infrastructure/types/SelectOption';

const buildFieldOption = (definition: CustomDataSourceFieldDefinition, prefix: string): SelectOption => {

    return {
        value: definition.id.toString(),
        text: `[${prefix}].[${definition.name}] (${definition.type})`
    };
};

export { buildFieldOption };
