import { firdsResultSetTypes } from '../../../constants/functions/specialOperandTypes';
import copyObject from '../../common/copyObject';
import customDataSourceType from '../../../constants/functions/customDataSourceType';
import dataType from '../../../constants/dataType';
import Operand from '../../../../types/functions/Operand/Operand';
import OperandRules from '../../../../types/functions/Operand/OperandRules';
import operandType from '../../../constants/functions/operandType';
import OutsideContextDetails from '../../../types/Functions/OutsideContextDetails';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import stringHelper from '../../common/stringHelper';
import typeHelper from '../../common/typeHelper';
import Condition from '../../../../types/functions/Condition/Condition';
import Assignment from '../../../../types/functions/Assignment/Assignment';
import ReportConfig from '../../../../types/report/ReportConfig';
import ComponentType from '../../../../types/report/ComponentType';
import accuracyValidationKind from '../../../constants/accuracyValidationKind';
import dataSourceKind from '../../../constants/dataSourceKind';

const add = (operand: Operand, collection: Operand[]): void => {

    collection.push(operand);

    if (!typeHelper.isArray(operand.arguments)) {
        return;
    }

    (operand.arguments as Operand[]).forEach(o => add(o, collection));
};

const asCollection = (operand: Operand): Operand[] => {
    let collection: Operand[] = [];

    if (!typeHelper.isObject(operand)) {
        return collection;
    }

    add(operand, collection);

    return collection;
};

const isValidField = (operand: Operand): boolean => {

    let isField = operand.operandType === operandType.field;

    let isFieldIdSet = typeHelper.isNumber(operand.fieldId);

    return isField && isFieldIdSet;
};

const isValidDictionaryField = (operand: Operand): boolean => {

    let isDictionaryField = operand.operandType === operandType.dictionary;

    let isDictionaryNumberSet = typeHelper.isNumber(operand.dictionaryNumber);

    let isFieldIdSet = typeHelper.isNumber(operand.fieldId);

    return isDictionaryField && isDictionaryNumberSet && isFieldIdSet;
};


const isValidLookupField = (operand: Operand): boolean => {

    let isLookupField = operand.operandType === operandType.lookupField;

    let isLookupNumberSet = typeHelper.isNumber(operand.lookupNumber);

    let isFieldIdSet = typeHelper.isNumber(operand.fieldId);

    return isLookupField && isLookupNumberSet && isFieldIdSet;
};

const isValidReportField = (operand: Operand): boolean => {

    return operand.operandType === operandType.reportField && typeHelper.isNumber(operand.reportFieldId);
};

const isValidCustomDataSourceField = (operand: Operand): boolean => {

    let isCustomDataSourceField =
        operand.operandType === operandType.firdsField ||
        operand.operandType === operandType.leiLevel1Field ||
        operand.operandType === operandType.exchangeRatesField ||
        operand.operandType === operandType.annaDsbUpiEnrichmentField;

    return isCustomDataSourceField && typeHelper.isNumber(operand.customDataSourceFieldId);
};

const isValidVariable = (operand: Operand): boolean => {

    return operand.operandType === operandType.variable && typeHelper.isNumber(operand.variableNumber);
};

const isValidFunction = (operand: Operand): boolean => {

    let isFunction = operand.operandType === operandType.function;

    let isFunctionSelected = stringHelper.isNonEmpty(operand.function);

    return isFunction && isFunctionSelected;
};

const isValidConstant = (operand: Operand): boolean => {

    let isConstant = operand.operandType === operandType.constant;

    let hasValidDataType = Object.values(dataType).includes(operand.dataType);

    let hasValue = typeHelper.isString(operand.value);

    return isConstant && hasValidDataType && hasValue;
};

const isValidGroup = (operand: Operand): boolean => {

    return operand.operandType === operandType.group;
};

const isValidLookupResultSet = (operand: Operand): boolean => {

    return operand.operandType === operandType.lookupResultSet && typeHelper.isNumber(operand.lookupNumber);
};

const isValidFirdsResultSet = (operand: Operand): boolean => {

    return firdsResultSetTypes.includes(operand.operandType);
};

const isValidLeiLevel1ResultSet = (operand: Operand): boolean => {

    return operand.operandType === operandType.leiResultSetLevel1;
};

const isValidExchangeRatesResultSet = (operand: Operand): boolean => {

    return operand.operandType === operandType.exchangeRatesResultSet;
};

const isValidAnnaDsbUpiEnrinchmentResultSet = (operand: Operand): boolean => {

    return operand.operandType === operandType.annaDsbUpiEnrichmentResultSet;
};

const isValidAnnaDsbUpiResultSet = (operand: Operand): boolean => {

    return operand.operandType === operandType.annaDsbUpiResultSet;
};

const isValidIsinSelector = (operand: Operand): boolean => {

    return operand.operandType === operandType.isinSelector;
};

const isValidArrayItem = (operand: Operand): boolean => {

    let isArrayItem = operand.operandType === operandType.arrayItem;

    //let hasValidDataType = Object.values(dataType).includes(operand.dataType);

    return isArrayItem;
};

const isValidParameter = (operand: Operand): boolean => {

    let isParameter = operand.operandType === operandType.parameter;

    let hasValidDataType = Object.values(dataType).includes(operand.dataType);

    let hasValue = typeHelper.isString(operand.value);

    return isParameter && hasValidDataType && hasValue;
};

const isValidUserDefinedFunction = (operand: Operand): boolean => {

    let isFunction = operand.operandType === operandType.userDefinedFunction;

    let isFunctionSelected = typeHelper.isNumber(operand.functionNumber);

    return isFunction && isFunctionSelected;
};


const isSetUp = (operand: Operand): boolean => {

    return (
        isValidField(operand) ||
        isValidLookupField(operand) ||
        isValidReportField(operand) ||
        isValidCustomDataSourceField(operand) ||
        isValidVariable(operand) ||
        isValidFunction(operand) ||
        isValidConstant(operand) ||
        isValidGroup(operand) ||
        isValidLookupResultSet(operand) ||
        isValidFirdsResultSet(operand) ||
        isValidLeiLevel1ResultSet(operand) ||
        isValidExchangeRatesResultSet(operand) ||
        isValidAnnaDsbUpiEnrinchmentResultSet(operand) ||
        isValidAnnaDsbUpiResultSet(operand) ||
        isValidArrayItem(operand) ||
        isValidParameter(operand) ||
        isValidDictionaryField(operand) ||
        isValidUserDefinedFunction(operand)
    );
};

const isResetRequired = (operand: Operand, operandRules: OperandRules): boolean => {

    return (
        isValidConstant(operand) &&
        typeHelper.isArray(operandRules.allowedTypes) &&
        !(operandRules.allowedTypes as string[]).includes(operand.dataType)
    );
};

const getOperand = (rootOperand: Operand, location: ReportLogicLocation): Operand => {

    let operand = rootOperand;

    location.argumentIndexes.forEach(i => operand = (operand.arguments as Operand[])[i]);

    return operand;
};

const getOperandCallStack = (rootOperand: Operand, argumentIndexes: number[]): Operand[] => {

    let operandsArray = [rootOperand];

    argumentIndexes.forEach(i => {

        let lastIndex = operandsArray.length - 1;

        if (typeHelper.isArray(operandsArray[lastIndex].arguments)) {

            let args = operandsArray[lastIndex].arguments as Operand[];

            operandsArray.push(args[i]);
        }
    });

    return operandsArray.reverse();
};

const getEmpty = (): Operand => {

    let result = {} as Operand;

    reset(result);

    return result;
};

const reset = (operand: Operand): void => {

    operand.operandType = '';

    operand.fieldId = null;
    operand.lookupNumber = null;
    operand.variableNumber = null;
    operand.reportFieldId = null;
    operand.customDataSourceFieldId = null;

    operand.selectorNumber = null;

    operand.dataType = '';
    operand.value = '';

    operand.function = '';
    operand.arguments = null;
};

const resolveOperandType = (operandRules: OperandRules, outsideContextDetails: OutsideContextDetails): string => {

    if (typeHelper.isArray(operandRules.specialOperandTypes)) {

        return (operandRules.specialOperandTypes as string[])[0];
    }

    if (outsideContextDetails.isOutsideContext && outsideContextDetails.customDataSource === customDataSourceType.firds) {

        return operandType.firdsField;
    }

    if (outsideContextDetails.isOutsideContext && outsideContextDetails.customDataSource === customDataSourceType.leiLevel1) {

        return operandType.leiLevel1Field;
    }

    if (outsideContextDetails.isOutsideContext && outsideContextDetails.customDataSource === customDataSourceType.exchangeRates) {

        return operandType.exchangeRatesField;
    }

    return operandType.field;
};

const prepareForEdit = (operand: Operand, operandRules: OperandRules, outsideContextDetails: OutsideContextDetails): Operand => {

    if (isSetUp(operand) && !isResetRequired(operand, operandRules)) {

        return copyObject(operand);
    }

    let result = getEmpty();

    result.operandType = resolveOperandType(operandRules, outsideContextDetails);

    return result;
};

const traverseReportConfigSectionsForOperands = (reportConfig: ReportConfig,
    callback: (operand: Operand, itemName?: string, componentType?: ComponentType, number?: number, mainComponentType?: ComponentType, mainComponentNumber?: number) => void) => {

    reportConfig.variables.forEach((variable) => {
        if (variable.assignment) {
            proceedWhitAssignment(variable.assignment, callback, variable.name, ComponentType.Variable, variable.number);
        }
    });

    reportConfig.lookups.forEach((lookup) => {
        if (lookup.condition) {
            proceedWhitCondition(lookup.condition, callback, lookup.name, ComponentType.Lookup, lookup.number);
        }
    });

    reportConfig.endpointFilters.forEach((filter) => {
        if (filter.condition) {
            let type = filter.type === 'UnderReport' ? ComponentType.UnderReportFilter :
                filter.type === 'OverReport' ? ComponentType.OverReportFilter : ComponentType.EndpointFilter;

            proceedWhitCondition(filter.condition, callback, filter.name, type, filter.number);
        }
    });

    reportConfig.filters.forEach((filter) => {
        if (filter.condition) {
            proceedWhitCondition(filter.condition, callback, filter.name, ComponentType.Filter, filter.number);
        }
    });

    reportConfig.aggregations.forEach((aggr) => {

        if (aggr.assignment) {
            proceedWhitAssignment(aggr.assignment, callback, aggr.name, ComponentType.Aggregation, aggr.number);
        }

        if (aggr.condition) {
            proceedWhitCondition(aggr.condition, callback, aggr.name, ComponentType.Aggregation, aggr.number);
        }

        for (let i = 0; i < aggr.groupBy.length; i++) {
            proceedWithOperand(aggr.groupBy[i], callback, aggr.name, ComponentType.Aggregation, aggr.number);
        }

        for (let i = 0; i < aggr.fields.length; i++) {
            if (aggr.fields[i].assignment) {
                proceedWhitAssignment(aggr.fields[i].assignment, callback, undefined, ComponentType.AggregationField, aggr.fields[i].fieldId, ComponentType.Aggregation, aggr.number, aggr.name);
            }
        }
    });

    reportConfig.cases.forEach((cas) => {
        if (cas.condition) {
            proceedWhitCondition(cas.condition, callback, cas.name, ComponentType.Case, cas.number);
        }
        for (let i = 0; i < cas.fields.length; i++) {
            if (cas.fields[i].assignment) {
                proceedWhitAssignment(cas.fields[i].assignment, callback, undefined, ComponentType.CaseField, cas.fields[i].fieldId, ComponentType.Case, cas.number, cas.name);
            }
        }
    });

    reportConfig.leiSelectors.forEach((lei) => {
        if (lei.assignment) {
            proceedWhitAssignment(lei.assignment, callback, lei.name, ComponentType.LeiSelector, lei.number);
        }
    });

    reportConfig.exchangeRatesSelectors.forEach((exchangeRates) => {
        if (exchangeRates.assignment) {
            proceedWhitAssignment(exchangeRates.assignment, callback, exchangeRates.name, ComponentType.ExchangeRatesSelector, exchangeRates.number);
        }
    });

    reportConfig.annaDsbUpiEnrichmentSelectors.forEach((annaDsbUpiEnrichment) => {

        let number = 1;

        annaDsbUpiEnrichment.keys?.forEach((key) => {
            if (key.assignment) {
                proceedWhitAssignment(key.assignment, callback, key.name, ComponentType.AnnaDsbUpiEnrichmentSelector, number, ComponentType.AnnaDsbUpiEnrichmentSelector, annaDsbUpiEnrichment.number, annaDsbUpiEnrichment.name);
            }
            number++;
        });
    });

    reportConfig.annaDsbUpiSelectors.forEach((annaDsbUpi) => {
        if (annaDsbUpi.assignment) {
            proceedWhitAssignment(annaDsbUpi.assignment, callback, annaDsbUpi.name, ComponentType.AnnaDsbUpiSelector, annaDsbUpi.number);
        }
    });

    reportConfig.isinSelectors.forEach((isin) => {
        if (isin.assignment) {
            proceedWhitAssignment(isin.assignment, callback, isin.name, ComponentType.IsinSelector, isin.number);
        }
    });

    reportConfig.lseSelectors.forEach((lse) => {
        if (lse.assignment) {
            proceedWhitAssignment(lse.assignment, callback, lse.name, ComponentType.LseSelector, lse.number);
        }
    });

    reportConfig.firdsFcaInstrumentSelectors.forEach((firds) => {

        let number = 1;

        firds.keys?.forEach((key) => {
            if (key.assignment) {
                proceedWhitAssignment(key.assignment, callback, key.name, ComponentType.FirdsFcaInstrumentSelectors, number, ComponentType.FirdsFcaInstrumentSelectors, firds.number, firds.name);
            }
            number++;
        })
    });

    reportConfig.firdsEsmaInstrumentSelectors.forEach((firds) => {

        let number = 1;

        firds.keys?.forEach((key) => {
            if (key.assignment) {
                proceedWhitAssignment(key.assignment, callback, key.name, ComponentType.FirdsEsmaInstrumentSelectors, number, ComponentType.FirdsFcaInstrumentSelectors, firds.number, firds.name);
            }
            number++;
        })
    });

    reportConfig.fcaRegulatedEntitiesSelectors.forEach((fcaRegEnt) => {
        if (fcaRegEnt.assignment) {
            proceedWhitAssignment(fcaRegEnt.assignment, callback, fcaRegEnt.name, ComponentType.FCARegulatedEntitiesSelector, fcaRegEnt.number);
        }
    });

    reportConfig.userDefinedFunctions.forEach((func) => {
        if (func.assignment) {
            proceedWhitAssignment(func.assignment, callback, func.name, ComponentType.UserDefinedFunction, func.number);
        }
    });

    reportConfig.validations.forEach((val) => {
        if (val.assignment) {
            let componentType = val.kind === accuracyValidationKind.core ? ComponentType.AccuracyValidationCore : ComponentType.AccuracyValidationStandard;

            proceedWhitAssignment(val.assignment, callback, val.identifier, componentType , val.number, ComponentType.AccuracyValidation);
        }
    });

    if (reportConfig.matchingKeyEndPoint.operands?.[0]) {
        proceedWithOperand(reportConfig.matchingKeyEndPoint.operands?.[0], callback, '', ComponentType.MatchingKeyEndpoint, reportConfig.matchingKeyEndPoint.operands?.[0]?.fieldId || 0);
    }

    if (reportConfig.matchingKeyEndPoint.assignment) {
        proceedWhitAssignment(reportConfig.matchingKeyEndPoint.assignment, callback, '', ComponentType.MatchingKeyEndpoint, reportConfig.matchingKeyEndPoint.assignment.number);
    }

    if (reportConfig.matchingKey.operands?.[0]) {
        proceedWithOperand(reportConfig.matchingKey.operands?.[0], callback, '', ComponentType.MatchingKeyClient, reportConfig.matchingKey.operands?.[0]?.fieldId || 0);
    }

    if (reportConfig.matchingKey.assignment) {
        proceedWhitAssignment(reportConfig.matchingKey.assignment, callback, '', ComponentType.MatchingKeyClient, reportConfig.matchingKey.assignment.number);
    }

    reportConfig.dictionaries.forEach((dictionary) => {
        if (dictionary.dataSourceId === reportConfig.rawDataSourceId || reportConfig.type===dataSourceKind.accuracy) {
            dictionary.fields.forEach((field) => {
                if (field.assignment) {
                    proceedWhitAssignment(field.assignment, callback, field.name, ComponentType.DictionaryField, field.number as number, ComponentType.Dictionary, dictionary.number, dictionary.name);
                }
            });
        }
    });
}

const proceedWhitAssignment = (assignment: Assignment,
    callback: (operand: Operand, itemName?: string, componentType?: ComponentType, number?: number) => void,
    itemName?: string,
    componentType?: ComponentType,
    number?: number,
    mainComponentType?: ComponentType,
    mainComponentNumber?: number,
    mainComponentName?: string,
) => {
    if (assignment.condition) {
        proceedWhitCondition(assignment.condition, callback, itemName, componentType, number, mainComponentType, mainComponentNumber, mainComponentName);
    }

    if (assignment.value) {
        proceedWithOperand(assignment.value, callback, itemName, componentType, number, mainComponentType, mainComponentNumber, mainComponentName);
    }

    if (assignment.else) {
        proceedWhitAssignment(assignment.else, callback, itemName, componentType, number, mainComponentType, mainComponentNumber, mainComponentName);
    }

    if (assignment.elseIf) {
        proceedWhitAssignment(assignment.elseIf, callback, itemName, componentType, number, mainComponentType, mainComponentNumber, mainComponentName);
    }
}

const proceedWhitCondition = (condition: Condition,
    callback: (operand: Operand, itemName?: string, componentType?: ComponentType) => void,
    itemName?: string,
    componentType?: ComponentType,
    number?: number,
    mainComponentType?: ComponentType,
    mainComponentNumber?: number,
    mainComponentName?: string) => {

    if (condition) {
        if (condition.operand1) {
            proceedWithOperand(condition.operand1, callback, itemName, componentType, number, mainComponentType, mainComponentNumber, mainComponentName);
        }

        if (condition.operand2) {
            proceedWithOperand(condition.operand2, callback, itemName, componentType, number, mainComponentType, mainComponentNumber, mainComponentName);
        }

        if (condition.and) {
            proceedWhitCondition(condition.and, callback, itemName, componentType, number, mainComponentType, mainComponentNumber, mainComponentName);
        }

        if (condition.or) {
            proceedWhitCondition(condition.or, callback, itemName, componentType, number, mainComponentType, mainComponentNumber, mainComponentName);
        }
    }
}

const proceedWithOperand = (operand: Operand,
    callback: (operand: Operand, itemName?: string, componentType?: ComponentType, number?: number, mainComponentType?: ComponentType, mainComponentNumber?: number, mainComponentName?: string) => void,
    itemName?: string,
    componentType?: ComponentType,
    number?: number,
    mainComponentType?: ComponentType,
    mainComponentNumber?: number,
    mainComponentName?: string) => {

    callback(operand, itemName || '', componentType || ComponentType.None, number, mainComponentType, mainComponentNumber, mainComponentName);

    if (operand?.arguments) {
        for (var i = 0; i < operand.arguments.length; i++) {
            proceedWithOperand(operand.arguments[i], callback, itemName, componentType, number, mainComponentType, mainComponentNumber, mainComponentName);
        }
    }
}

const operandHelper = {
    asCollection,
    isValidField,
    isValidDictionaryField,
    isValidLookupField,
    isValidReportField,
    isValidCustomDataSourceField,
    isValidVariable,
    isValidFunction,
    isValidConstant,
    isValidGroup,
    isValidLookupResultSet,
    isValidFirdsResultSet,
    isValidLeiLevel1ResultSet,
    isSetUp,
    getOperand,
    getEmpty,
    reset,
    prepareForEdit,
    isValidIsinSelector,
    isValidExchangeRatesResultSet,
    isValidAnnaDsbUpiEnrinchmentResultSet,
    isValidAnnaDsbUpiResultSet,
    getOperandCallStack,
    traverseReportConfigSectionsForOperands,
    proceedWhitAssignment,
    proceedWhitCondition
};

export default operandHelper;
