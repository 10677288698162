import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import EditableText from '../../Common/EditableText/EditableText';
import PromiseButton from '../../Common/PromiseButton';
import React from 'react';
import Version from '../../../types/common/Version';
import versionHelper from '../../../infrastructure/helpers/functions/common/versionHelper';

const getColumns = (onGetChangesClick: (identifier: string) => Promise<void>): ColumnDefinition<Version>[] => {
    return [
        {
            header: 'Version',
            getContent: x => versionHelper.getDisplayName(x.identifier),
            width: '90px'
        },
        {
            header: 'Change Name',
            getContent: x => x.name,
            width: '200px'
        },
        {
            header: 'Change Comment',
            getContent: x => <EditableText value={x.comment} onChange={() => { }} multiline={true} disabled={true}></EditableText>
        },
        {
            header: 'Timestamp',
            getContent: x => x.timestamp,
            sortKey: 'Timestamp',
            width: '180px'
        },
        {
            header: 'User',
            getContent: x => x.user
        },
        {
            header: '',
            getContent: x => <PromiseButton className="transparent-button" enableOnErrorOnly={false} task={() => onGetChangesClick(x.identifier)} text="Changes" />,
            width: '80px'
        }
    ];
}

export default getColumns;