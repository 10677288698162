import appRoutes from '../infrastructure/constants/appRoutes';
import ObjectIndexer from '../infrastructure/types/ObjectIndexer';
import reportRegime from '../infrastructure/constants/reportRegime';
import urlHelper from '../infrastructure/helpers/common/urlHelper';

const buildRoute = (regime: string) => {

    return urlHelper.buildUrl(appRoutes.masterConfiguration.home, { regime });
};

const buildMasterConfigurationLinks = (): [string, string][] => {

    return Object.keys(reportRegime).map(key => [buildRoute((reportRegime as ObjectIndexer<string>)[key]), key]);
};

export { buildMasterConfigurationLinks };
