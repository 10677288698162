import SelectOption from '../../../infrastructure/types/SelectOption';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';
import Selector from '../../../types/report/Selector';
import Assignment from '../../../types/functions/Assignment/Assignment';
import Operand from '../../../types/functions/Operand/Operand';
import ComponentType from '../../../types/report/ComponentType';

const createSelectorOption = (selector: Selector, name: string): SelectOption => {

    return {
        value: selector.number?.toString(),
        text: stringHelper.isNonEmpty(selector.name) ? selector.name : name
    };
};

const getEmptyAssignment = (): Assignment => {
    return {
        condition: null,
        doNotPopulate: false,
        else: null,
        elseIf: null,
        number: 1,
        value: {} as Operand
    }
}

const setEmptySelectorKeysByComponentType = (componentType: ComponentType, selectors: Selector[]): Selector[] => {

    selectors.forEach((selector) => {
        if (!selector.keys) {
            switch (componentType) {
                case ComponentType.AnnaDsbUpiEnrichmentSelector: selector.keys = [
                    {
                        name: 'CFI',
                        assignment: getEmptyAssignment(),
                        errors: []
                    },
                    {
                        name: 'ISIN',
                        assignment: getEmptyAssignment(),
                        errors: []
                    }
                ]; break;

                case ComponentType.FirdsFcaInstrumentSelectors:
                case ComponentType.FirdsEsmaInstrumentSelectors: selector.keys = [
                    {
                        name: 'InstrumentName',
                        assignment: getEmptyAssignment(),
                        errors: []
                    },
                    {
                        name: 'Venue',
                        assignment: getEmptyAssignment(),
                        errors: []
                    }
                ]; break;

                default: break;
            }
        }
    });

    return selectors;
}

export { createSelectorOption, getEmptyAssignment, setEmptySelectorKeysByComponentType }