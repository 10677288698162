import './ServerDataGrid.scss';
import { AxiosResponse } from 'axios';
import { createHeaderCell, createDataRow } from '../DataGrid.Helpers';
import { getDefaultState, getGridClasses, getSortingIcon } from './ServerDataGrid.Helpers';
import { ReactComponent as CaretLeft } from '../../BootstrapIcons/caret-left-fill.svg';
import { ReactComponent as CaretRight } from '../../BootstrapIcons/caret-right-fill.svg';
import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import ColumnDefinition from '../../../../infrastructure/types/DataGrid/ColumnDefinition';
import DataGridPageRequest from '../../../../infrastructure/types/DataGrid/DataGridPageRequest';
import DataGridPageResponse from '../../../../infrastructure/types/DataGrid/DataGridPageResponse';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import React from 'react';
import ServerDataGridProps from './ServerDataGridProps';
import ServerDataGridState from './ServerDataGridState';
import sortSelectionStorage from '../sortSelectionStorage';
import Spinner from '../../Spinner/Spinner';
import store from '../../../../store/store';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';

class ServerDataGrid<T> extends React.Component<ServerDataGridProps<T>, ServerDataGridState<T>> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: ServerDataGridProps<T>) {
        super(props);

        this.state = getDefaultState(this.props);

        this.getRecords = this.getRecords.bind(this);
        this.onGetSuccess = this.onGetSuccess.bind(this);
        this.requestPage = this.requestPage.bind(this);
        this.onPreviousClick = this.onPreviousClick.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onHeaderClick = this.onHeaderClick.bind(this);
        this.refreshListener = this.refreshListener.bind(this);
        this.getRequestParams = this.getRequestParams.bind(this);
        this.createHeaderContent = this.createHeaderContent.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.refreshListener);

        this.requestPage(1);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    getRecords(): void {
        httpClient.get<DataGridPageResponse<T>>(this.props.dataSourceRoute, this.getRequestParams()).then(this.onGetSuccess);
    }

    onGetSuccess(response: AxiosResponse<DataGridPageResponse<T>>): void {
        let lastPage = Math.ceil(response.data.total / this.props.pageSize);

        this.setState({
            records: response.data.page,
            total: response.data.total,
            lastPage: lastPage,
            previousButtonEnabled: this.state.currentPage > 1,
            nextButtonEnabled: this.state.currentPage < lastPage,
            isWaiting: false
        });
    }

    requestPage(currentPage: number): void {

        this.setState({ currentPage: currentPage, isWaiting: true }, this.getRecords);
    }

    onPreviousClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        this.requestPage(this.state.currentPage - 1);
    }

    onNextClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        this.requestPage(this.state.currentPage + 1);
    }

    onHeaderClick(sortBy: string): void {

        let ascending = sortBy !== this.state.sortBy || !this.state.ascending;

        sortSelectionStorage.set(this.props.uniqueName, { columnName: sortBy, ascending: ascending });

        this.setState({ sortBy: sortBy, ascending: ascending, currentPage: 1, isWaiting: true }, this.getRecords);
    }

    refreshListener(): void {

        let state = store.getState();
        if (state.action !== actions.grid.refresh) {
            return;
        }

        if (state.gridRefresh.uniqueName !== this.props.uniqueName) {
            return;
        }

        let page = state.gridRefresh.useCurrentPage ? this.state.currentPage : 1;

        this.requestPage(page);
    }

    getRequestParams(): object {

        let request: DataGridPageRequest = {
            page: this.state.currentPage,
            size: this.props.pageSize,
            sortBy: this.state.sortBy,
            ascending: this.state.ascending
        };

        if (typeHelper.isFunction(this.props.getAdditionalParams)) {

            let params = (this.props.getAdditionalParams as () => object)();

            Object.assign(request, params);
        }

        return request;
    }

    createHeaderContent(column: ColumnDefinition<T>): JSX.Element {

        if (!typeHelper.isString(column.sortKey)) {

            return <>{column.header}</>;
        }

        return (
            <button className="transparent-button" onClick={() => this.onHeaderClick(column.sortKey as string)} disabled={this.state.isWaiting}>
                {column.header}
                {getSortingIcon(this.state.sortBy === column.sortKey, this.state.ascending)}
            </button>
        );
    }

    render(): JSX.Element {
        return (
            <table className={getGridClasses(this.state.isWaiting)}>
                <thead>
                    <tr>
                        {this.props.columns.map((column, index) => createHeaderCell(column, index, this.createHeaderContent))}
                    </tr>
                </thead>
                <tbody>
                    {this.state.records.map((record, index) => createDataRow(this.props.columns, record, index, this.props.getKey))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={this.props.columns.length}>
                            <button
                                type="button"
                                className="transparent-button"
                                title="Previous Page"
                                disabled={this.state.isWaiting || !this.state.previousButtonEnabled}
                                onClick={this.onPreviousClick}>
                                <CaretLeft />
                            </button>

                            <span className="page-indicator">{this.state.currentPage} of {this.state.lastPage}</span>

                            <button
                                type="button"
                                className="transparent-button"
                                title="Next Page"
                                disabled={this.state.isWaiting || !this.state.nextButtonEnabled}
                                onClick={this.onNextClick}>
                                <CaretRight />
                            </button>
                            {
                                this.state.isWaiting &&
                                <Spinner />
                            }
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }
}

export default ServerDataGrid;
