const custom = {
    date: [
        'M/d/yyyy',
        '\\dyyyy-MM-dd'
    ],
    time: [
        'H:mm:ss.fff',
        'h:mm:ss tt',
        'HH:mm:ss UTC',
        'HH:mm:ss.fff UTC',
        'HH.mm.ss.fffffff',
        'HH:mm:ss zzz'
    ]
};

const dateFormatsPriority1 = [
    ['yyyy', 'MM', 'dd'],
    ['dd', 'MM', 'yyyy'],
    ['dd', 'MM', 'yy'],
    ['yy', 'MM', 'dd'],

    ['yyyy', 'MMM', 'dd'],
    ['dd', 'MMM', 'yyyy'],
    ['dd', 'MMM', 'yy'],
    ['yy', 'MMM', 'dd']
];

const dateFormatsPriority2 = [
    ['MM', 'dd', 'yyyy'],
    ['MM', 'dd', 'yy'],
    ['MMM', 'dd', 'yyyy'],
    ['MMM', 'dd', 'yy']
];

const dateSeparators = [
    '-',
    '/',
    '.',
    ' ',
    ''
];

const timePart = [
    'HH:mm',
    'HH:mm:ss',
    'HH:mm:ss.f',
    'HH:mm:ss:f',
    'HH:mm:ss.ff',
    'HH:mm:ss:ff',
    'HH:mm:ss.fff',
    'HH:mm:ss:fff',
    'HH:mm:ss.ffff',
    'HH:mm:ss:ffff',
    'HH:mm:ss.fffff',
    'HH:mm:ss:fffff',
    'HH:mm:ss.ffffff',
    'HH:mm:ss:ffffff',
    'HH:mm:ss.fffffff',
    'HH:mm:ss:fffffff',
    'H:mm:ss',
    'H:mm:ss.f',
    'H:mm:ss:f',
    'H:mm:ss.ff',
    'H:mm:ss:ff',
    'H:mm:ss.fff',
    'H:mm:ss:fff',
    'H:mm:ss.ffff',
    'H:mm:ss:ffff',
    'H:mm:ss.fffff',
    'H:mm:ss:fffff',
    'H:mm:ss.ffffff',
    'H:mm:ss:ffffff',
    'H:mm:ss.fffffff',
    'H:mm:ss:fffffff',
    'HHmm',
    'HHmmss'
];

const offsetPart = [
    '',
    ' zzz',
    'zzz',
    'zz',
    'z',
    'Z'
];

const dateTimeSeparator = {
    t: 'T',
    colon: ':',
    space: ' ',
    d: 'D'
};

const dateTimeSeparators = Object.values(dateTimeSeparator);

const generateDateFormats = (): string[] => {
    let result: string[] = [];

    dateFormatsPriority1.forEach(d => {

        dateSeparators.forEach(s => {

            result.push(d.join(s));
        });
    });

    dateFormatsPriority2.forEach(d => {

        dateSeparators.forEach(s => {

            result.push(d.join(s));
        });
    });

    custom.date.forEach(d => result.push(d));

    return result;
};

const generateTimeFormats = (): string[] => {
    let result: string[] = [];

    timePart.forEach(t => {

        offsetPart.forEach(o => {

            result.push(t + o);
        });
    });

    custom.time.forEach(t => result.push(t));

    return result;
};

const dateFormats = generateDateFormats();
const timeFormats = generateTimeFormats();

export {
    dateFormats,
    timeFormats,
    dateTimeSeparator,
    dateTimeSeparators
};