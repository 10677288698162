import { reportType } from '../../constants/reportType';
import aggregationHelper from '../functions/aggregation/aggregationHelper';
import assignmentHelper from '../functions/assignment/assignmentHelper';
import dataSourceHelper from '../functions/common/dataSourceHelper';
import Declaration from '../../types/Functions/Declaration';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import NumberDeclaration from '../../types/Functions/NumberDeclaration';
import Operand from '../../../types/functions/Operand/Operand';
import ReportConfig from '../../../types/report/ReportConfig';

const fix = (declaration: Declaration, isBespoke: boolean): void => {

    declaration.errors = [];

    if (isBespoke) {
        return;
    }

    assignmentHelper.asCollection(declaration.assignment).forEach(a => {

        if (a.doNotPopulate) {
            a.value = {} as Operand;
        }
    });
};

const prepare = (config: ReportConfig, dataSources: IngestionConfig[]): void => {

    if (config.type === reportType.accuracy) {

        config.rawDataSourceId = null;
    }

    config.isinSelectors.forEach(x => x.errors = []);
    config.leiSelectors.forEach(x => x.errors = []);
    config.exchangeRatesSelectors.forEach(x => x.errors = []);
    config.annaDsbUpiEnrichmentSelectors.forEach(x => x.errors = []);
    config.lseSelectors.forEach(x => x.errors = []);
    config.firdsFcaInstrumentSelectors.forEach(x => x.errors = []);
    config.firdsEsmaInstrumentSelectors.forEach(x => x.errors = []);
    config.annaDsbUpiSelectors.forEach(x => x.errors = []);
    config.fcaRegulatedEntitiesSelectors.forEach(x => x.errors = []);
    config.variables.forEach(x => x.errors = []);
    config.validations.forEach(x => x.errors = []);
    config.filters.forEach(x => x.errors = []);
    config.endpointFilters.forEach(x => x.errors = []);
    config.matchingKey.errors = [];
    config.matchingKeyEndPoint.errors = [];

    config.lookups.forEach(l => {

        l.errors = [];

        if (l.isEndpoint) {

            l.dataSource1Id = null;
        }
    });

    config.aggregations.forEach(a => {

        a.errors = [];

        let dataSource = dataSourceHelper.getById(dataSources, a.dataSourceId);

        aggregationHelper.syncFields(a, dataSource);

        a.fields.forEach(f => fix(f, false));
    });

    config.cases.forEach(c => {

        c.errors = [];
        c.fields.forEach(f => fix(f, f.isBespoke));
    });

    config.dictionaries.forEach(d => {

        d.errors = [];
        d.fields.forEach(f => { f.errors = [] });
    });

    config.userDefinedFunctions.forEach(d => {

        d.errors = [];
        d.parameters.forEach(p => { p.errors = [] });
    });
};

const isValidationConfig = (reportConfig: ReportConfig) => {
    const reportConfigTypes = ['Accuracy', 'AccuracyCore'];
    return reportConfigTypes.includes(reportConfig.type);
}

const editReportConfigHelper = {
    prepare,
    isValidationConfig
};

export default editReportConfigHelper;
