import operandRuleHelper from '../../../../infrastructure/helpers/functions/operand/operandRuleHelper';
import operandHelper from '../../../../infrastructure/helpers/functions/operand/operandHelper';
import OperandModalState from '../../Operand/Modal/OperandModalState';
import reportLogicLocationHelper from '../../../../infrastructure/helpers/functions/report/reportLogicLocationHelper';

const getEmptyOperandModalState = (): OperandModalState => {
    return {
        title: '',
        isOpen: false,
        operand: operandHelper.getEmpty(),
        location: reportLogicLocationHelper.getEmptyLocation(),
        dataSource1Id: null,
        dataSource2Id: null,
        customDataSourceLegitimacy: { isin: false, lei: false, exchangeRates: false, annaDsbUpiEnrichment: false, annaDsbUpi: false, lse: false, firdsFcaInstrument: false, firdsEsmaInstrument: false, fcaRegulatedEntities: false },
        operandRules: operandRuleHelper.getEmpty(false),
        specialScenario: { doNotPopulate: false, pseudoFunction: null },
        customDataSourceContext: null,
        error: null,
        arrayItemLegitimacy: { isAllowed: false, itemDataType: '' },
        parameters: null
    };
};

export {
    getEmptyOperandModalState
};