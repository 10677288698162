import { ReactComponent as AssignmentIcon } from '../../../Common/BootstrapIcons/assignment-late.svg';
import { ReactComponent as CommentIcon } from '../../../Common/BootstrapIcons/comment.svg';
import { ReactComponent as RemoveIcon } from '../../../Common/BootstrapIcons/close.svg';
import BespokeToggleButton from '../../../Functions/Bespoke/BespokeToggleButton';
import ColumnDefinition from '../../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../../Common/BootstrapIcons/Custom/CustomIcon';
import DraggableItem from '../../../Common/DraggableItem/DraggableItem';
import EditableText from '../../../Common/EditableText/EditableText';
import iconTooltip from '../../../Common/BootstrapIcons/Tooltip/IconTooltip';
import PromiseButton from '../../../Common/PromiseButton';
import React from 'react';
import ReportFilter from '../../../../types/report/ReportFilter';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';

const getNumberContent = (number: number, reorder: (dragNumber: number, dropNumber: number) => void, isReadOnly: boolean): string | JSX.Element => {

    if (isReadOnly) {
        return number.toString();
    }

    return (
        <DraggableItem identifier={number} onDrop={reorder}>
            {number}
        </DraggableItem>
    );
};

const getColumns = (
    onNameChange: (name: string, number: number) => void,
    onBusinessDescriptionChange: (businessDescription: string, number: number) => void,
    onBespokeToggleClick: (isBespoke: boolean, filter: ReportFilter) => void,
    onRemoveClick: (number: number) => void,
    onCommentClick: (id: number) => Promise<any>,
    onActionItemClick: (id: number) => Promise<any>,
    reorder: (dragNumber: number, dropNumber: number) => void,
    getCommentCount: (id: number) => number,
    getActionItemCount: (id: number) => number,
    isReadOnly: boolean
): ColumnDefinition<ReportFilter>[] => {

    let columns: ColumnDefinition<ReportFilter>[] = [
        {
            header: 'Reference',
            getContent: x => getNumberContent(x.number, reorder, isReadOnly),
            width: '110px'
        },
        {
            header: 'Filter Name',
            getContent: x => <EditableText disabled={isReadOnly} value={x.name} onChange={value => onNameChange(value, x.number)} multiline={false} />,
            width: '200px'
        },
        {
            header: 'Business Description',
            getContent: x => <EditableText disabled={isReadOnly} value={x.businessDescription} onChange={value => onBusinessDescriptionChange(value, x.number)} multiline={true} style={{ width: '100%', height: '60px' }} />
        },
        {
            header: '',
            getContent: x =>
                <div className="float-right">
                    {
                        !isReadOnly &&
                        <BespokeToggleButton
                            isBespoke={x.isBespoke}
                            onClick={isBespoke => onBespokeToggleClick(isBespoke, x)}
                            style={{ verticalAlign: 'top', width: '150px' }} />
                    }
                    {
                        typeHelper.isNumber(x.id) &&
                        <>
                            <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onCommentClick(x.id as number)}>
                                <CustomIcon icon={CommentIcon} count={getCommentCount(x.id as number)} title={iconTooltip.comments} />
                            </PromiseButton>
                            <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onActionItemClick(x.id as number)}>
                                <CustomIcon icon={AssignmentIcon} count={getActionItemCount(x.id as number)} title={iconTooltip.actionLog} />
                            </PromiseButton>
                        </>
                    }
                    <button
                        className="custom-icon-button"
                        onClick={() => onRemoveClick(x.number)}
                        disabled={isReadOnly}>
                        <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                    </button>
                </div>,
            width: '254px'
        }
    ];

    return columns;
};

export {
    getColumns
};