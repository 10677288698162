import RadioButtonGroup from '../../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import SelectOption from '../../../../infrastructure/types/SelectOption';

interface Props {
    options: SelectOption[];
    function: string;
    name: string;
    onChange: (func: string) => void;
}

class OverloadRadio extends React.Component<Props> {

    render(): JSX.Element {

        if (!this.props.options.some(o => o.value === this.props.function)) {

            return (<></>);
        }

        return (
            <div className="mt-3">
                <RadioButtonGroup
                    options={this.props.options}
                    name={this.props.name}
                    value={this.props.function}
                    onChange={this.props.onChange} />
            </div>
        );
    }
}

export default OverloadRadio;
