import ActionItemModalState from '../../../components/Common/Modals/ActionItemModal/ActionItemModalState';
import ActionItem from '../../../types/common/ActionItem/ActionItem';
import ActionitemDto from '../../../types/common/ActionItem/ActionItemDto';
import ActionItemDto from '../../../types/common/ActionItem/ActionItemDto';
import ActionItemSaveRequest from '../../../types/common/ActionItem/ActionItemSaveRequest';

const createModalState = (originalActionItems: ActionItemDto[], items: ActionItemDto[], actionItemsForModule: ActionItemDto[], saveRequest: ActionItemSaveRequest, buildRelationUrl: (actionItemId: string) => string, module: string): ActionItemModalState => {

    let state = {} as ActionItemModalState;

    state.isOpen = true;
    state.error = null;
    state.name = '';
    state.isWaiting = false;
    state.actionItemsForModule = actionItemsForModule;
    state.originalActionItems = originalActionItems;
    state.actionItems = items;
    state.searchResults = items;
    state.saveRequest = saveRequest;
    state.buildRelationUrl = buildRelationUrl;
    state.module = module;

    return state;
};

const getEmpty = (): ActionItem => {
    return {
        name: '',
        description: '',
        priorityType: '',
        status: '',
        assigneeId: ''
    };
};


const prepareModuleActionitems = (allActionItems: ActionitemDto[], cbActionItems: ActionitemDto[]) => {
    let moduleActionItems: ActionitemDto[] = [];

    cbActionItems.forEach((item) => {
        let actionItem = allActionItems.find(x => x.externalId === item.externalId);
        if (actionItem) {
            actionItem.isLinkedToModule = item.isLinkedToModule;
            actionItem.id = item.id;
            moduleActionItems.push(Object.assign({},actionItem));
        }
    });

    return moduleActionItems;
}

const actionItemHelper = {
    createModalState,
    getEmpty,
    prepareModuleActionitems
};

export default actionItemHelper;