import CopyReportFieldModalState from './CopyReportFieldModalState';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import { SelectSearchOption } from 'react-select-search';

const getDefaultState = (): CopyReportFieldModalState => {
    return {
        title: '',
        isOpen: false,
        error: null,
        sourceCaseNumber: '',
        targetCaseNumbers: new Set(),
        sourceFieldId: '',
        targetFieldId: '',
        copyAllFields: false
    };
};

const createFieldOption = (field: ReportFieldDefinition): SelectSearchOption => {
    return {
        name: field.name,
        value: field.id.toString()
    };
};

const createEmptyFieldOption = (): SelectSearchOption => {
    return {
        name: '',
        value: ''
    };
};

export { getDefaultState, createFieldOption, createEmptyFieldOption };
