import { requiresCsvArguments } from '../IngestionConfigForm/IngestionConfigForm.Helpers';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

const validateCsvArguments = (config: IngestionConfig): string | null => {

    if (!requiresCsvArguments(config.fileType)) {

        return null;
    }

    if (!typeHelper.isString(config.csvSpecification.separator)) {
        return 'Field Delimiter is required';
    }

    if (!typeHelper.isString(config.csvSpecification.escapeCharacter)) {
        return 'Escape character is required';
    }

    if (!typeHelper.isNumber(config.csvSpecification.dataLineNumber)) {
        return 'Data Start Row Number is required';
    }

    if (config.csvSpecification.dataLineNumber <= config.csvSpecification.headerLineNumber) {
        return 'Data Start Row Number must be greater than Header Row Number';
    }

    return null;
};

export default validateCsvArguments;
