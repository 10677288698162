import Aggregation from '../../../../types/report/Aggregation';
import aggregationHelper from '../../../../infrastructure/helpers/functions/aggregation/aggregationHelper';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import SelectOption from '../../../../infrastructure/types/SelectOption';

const createDataSourceOption = (dataSource: IngestionConfig): SelectOption => {
    return {
        text: dataSource.name,
        value: (dataSource.id as number).toString()
    };
};

const createAggregationOption = (aggregation: Aggregation): SelectOption => {
    return {
        text: aggregationHelper.fallbackName(aggregation),
        value: aggregation.number.toString()
    };
};

export { createDataSourceOption, createAggregationOption };
