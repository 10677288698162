import React from 'react';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import SelectList from '../../../Common/SelectList/SelectList';

interface Props {
    ticked: boolean;
    onTickChange: (ticked: boolean) => void;
    value: string;
    onValueChange: (value: string) => void;
    options: SelectOption[];
    isReadOnly: boolean;
}

class OptionalSelectList extends React.Component<Props> {

    render(): JSX.Element {

        return (
            <>
                <input
                    type="checkbox"
                    checked={this.props.ticked}
                    onChange={e => this.props.onTickChange(e.target.checked)}
                    disabled={this.props.isReadOnly} />
                {
                    this.props.ticked &&
                    <SelectList
                        className="inline-select"
                        options={this.props.options}
                        value={this.props.value}
                        onChange={this.props.onValueChange}
                        disabled={this.props.isReadOnly} />
                }
                {
                    !this.props.ticked &&
                    <span>No</span>
                }
            </>
        );
    }
}

export default OptionalSelectList;
