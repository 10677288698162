import { Unsubscribe } from 'redux';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ComplianceComment from '../../../types/common/ComplianceComment';
import complianceCommentHelper from '../../../infrastructure/helpers/complianceComment/complianceCommentHelper';
import ComplianceCommentModal from '../../Common/Modals/ComplianceCommentModal/ComplianceCommentModal';
import getColumns from './ReportActionLogPage.Grid';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import ObjectIndexer from '../../../infrastructure/types/ObjectIndexer';
import React from 'react';
import ServerDataGrid from '../../Common/DataGrid/ServerDataGrid/ServerDataGrid';
import store from '../../../store/store';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';

interface State {
    counts: ObjectIndexer<number>;
}

class ReportActionLogPage extends React.Component<any, State> {
    private id: number;
    private isValidQueryParam: boolean;
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        let query = urlHelper.deserializeQuery(history.location.search);

        this.id = parseInt(query['id']);

        this.isValidQueryParam = typeHelper.isNumber(this.id);

        if (!this.isValidQueryParam) {
            history.push(appRoutes.reporting.home);
        }

        this.state = { counts: {} };

        this.refreshCountsListener = this.refreshCountsListener.bind(this);
        this.getCounts = this.getCounts.bind(this);
        this.onCommentClick = this.onCommentClick.bind(this);
        this.getCommentCount = this.getCommentCount.bind(this);
        this.onGetCommentSuccess = this.onGetCommentSuccess.bind(this);
    }

    componentDidMount() {
        if (!this.isValidQueryParam) {
            return;
        }

        this.unsubscribe = store.subscribe(this.refreshCountsListener);
        this.getCounts();
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    refreshCountsListener(): void {
        let state = store.getState();

        if (state.action !== actions.complianceCommentModal.saveSuccess || 
            state.action !== actions.complianceCommentModal.deleteCommentSuccess) {
            return;
        }

        this.getCounts();
    }

    getCounts(): void {

        let url = urlHelper.buildRoute(['action-item-comment', 'counts', this.id]);

        httpClient.get<ObjectIndexer<number>>(url).then(response => this.setState({ counts: response.data }));

    }

    onCommentClick(id: number): Promise<any> {

        let url = urlHelper.buildRoute(['action-item-comment', id]);

        return httpClient.get<ComplianceComment[]>(url).then(response => this.onGetCommentSuccess(response.data, url));
    }

    onGetCommentSuccess(comments: ComplianceComment[], url: string): void {

        let state = complianceCommentHelper.createModalState(comments, url);

        store.dispatch({ type: actions.complianceCommentModal.open, payload: state });
    }

    getCommentCount(id: number): number {

        return this.state.counts[id.toString()] || 0;
    }

    render(): JSX.Element {
        return (
            <>
                <h3>Action Log</h3>

                <div className="mt-4 mb-4">
                    <ServerDataGrid
                        columns={getColumns(this.id, this.onCommentClick, this.getCommentCount)}
                        uniqueName="report-action-log-grid"
                        dataSourceRoute={urlHelper.buildRoute(['action-item', 'page', this.id])}
                        pageSize={10}
                        getKey={x => x.id} />
                </div>

                <div className="mb-3">
                    <button onClick={() => history.push(appRoutes.reporting.home)} className="btn btn-light">Back</button>
                </div>

                <ComplianceCommentModal />
            </>
        );
    }
}

export default ReportActionLogPage;