import { Unsubscribe } from 'redux';
import React from 'react';
import { createAggregationOption } from './AggregationSelector.Helpers';
import Aggregation from '../../../types/report/Aggregation';
import store from '../../../store/store';
import CopyAggregationModalState from './CopyAggregationModal/CopyAggregationFieldModalState';
import actions from '../../../store/actions';
import copyObject from '../../../infrastructure/helpers/common/copyObject';
import RadioButtonGroup from '../../Common/RadioButtonGroup/RadioButtonGroup';
import SyncedWithEditor from '../../Common/SyncedWithEditor/SyncedWithEditor';
import Synced from '../../../types/report/Synced';
import copyArray from '../../../infrastructure/helpers/common/copyArray';
import DataSourceField from '../../../types/report/DataSourceField';
import guidance from '../ReportConfigEditor/ReportConfigEditor.Guidance';

interface Props {
    isWaiting: boolean;
    aggregationNumber: number | null;
    aggregations: Aggregation[];
    onAggregationSelected: (number: number) => void;
    onAggregationChange?: (number: number, aggregations: Aggregation[]) => void;
    onSyncedWithIdChange?: (number: number, syncedWithId: number | null) => void;
}

class AggregationSelector extends React.Component<Props> {
    private unsubscribeCopyFields: Unsubscribe | undefined;

    constructor(props: Props) {
        super(props);

        this.onaggregationSelected = this.onaggregationSelected.bind(this);
        this.onCopyAllClick = this.onCopyAllClick.bind(this);
        this.onCopyFields = this.onCopyFields.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribeCopyFields = store.subscribe(this.onCopyFields);
    }

    componentWillUnmount(): void {
        (this.unsubscribeCopyFields as Unsubscribe)();
    }

    onaggregationSelected(value: string): void {

        this.props.onAggregationSelected(parseInt(value));
    }

    onCopyAllClick(): void {

        let state: CopyAggregationModalState = {
            title: 'Copy All Fields',
            isOpen: true,
            error: null,
            copyAllFields: true,
            sourceNumber: this.props.aggregations[0].number.toString(),
            targetNumbers: new Set([this.props.aggregations[0].number.toString()]),
            sourceFieldId: '',
            targetFieldId: ''
        };

        store.dispatch({ type: actions.copyAggregationFieldModal.change, payload: state });
    }

    onCopyFields(): void {

        let state = store.getState();
        if (state.action !== actions.copyAggregationFieldModal.save) {
            return;
        }

        let sourceAggregation = copyObject(this.props.aggregations.find(x => x.number === parseInt(state.copyAggregationFieldModalSave.sourceNumber))) as Aggregation;
        let resultAggregations: Aggregation[] = this.props.aggregations.filter(a => !state.copyAggregationFieldModalSave.targetNumbers.has(a.number.toString()));
        let aggregations = copyArray(this.props.aggregations);

        if (state.copyAggregationFieldModalSave.copyAllFields) {

            state.copyAggregationFieldModalSave.targetNumbers.forEach((value) => {
                let targetAggregation = aggregations.find(x => x.number === parseInt(value)) as Aggregation;
                targetAggregation.fields = copyArray(sourceAggregation.fields);
                resultAggregations.push(targetAggregation);
            });
        }
        else {

            state.copyAggregationFieldModalSave.targetNumbers.forEach((value) => {

                let targetAggregation = aggregations.find(x => x.number === parseInt(value)) as Aggregation;

                targetAggregation.fields.forEach((field, index) => {
                    if (field.fieldId === parseInt(state.copyAggregationFieldModalSave.targetFieldId)) {
                        const sourceField = sourceAggregation.fields.find(f => f.fieldId === parseInt(state.copyAggregationFieldModalSave.sourceFieldId)) || {} as DataSourceField
                        targetAggregation.fields[index].assignment = sourceField.assignment;
                    }
                });

                resultAggregations.push(targetAggregation)
            });
        }
        resultAggregations.sort((prev, next) => prev.number - next.number);

        this.props.onAggregationChange && this.props.onAggregationChange(0, resultAggregations);
    }

    render(): JSX.Element {

        if (this.props.isWaiting || !this.props.aggregations.length) {

            return (<></>);
        }

        return (
            <>
                <RadioButtonGroup
                    className='sticky min-height-44px padding-10px'
                    options={this.props.aggregations.map(createAggregationOption)}
                    value={(this.props.aggregationNumber || 0 as number).toString()}
                    name="report-aggregations"
                    onChange={this.onaggregationSelected} />

                <div className="row mt-3">
                    <div className="col-4">
                        <SyncedWithEditor
                            itemNumber={this.props.aggregationNumber || 0 as number}
                            collection={this.props.aggregations as Synced[]}
                            onSyncedWithIdChange={this.props.onSyncedWithIdChange && this.props.onSyncedWithIdChange}
                            info={guidance.syncedWithAggregation} />
                    </div>
                    <div className="col-8">
                        <div className="float-right">
                            <button
                                className="btn btn-light"
                                onClick={this.onCopyAllClick}
                                disabled={this.props.aggregations.length < 2}>Copy All Fields</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AggregationSelector;
