import './RadioButtonGroup.scss';
import React from 'react';
import SelectOption from '../../../infrastructure/types/SelectOption';

interface RadioButtonGroupProps {
    options: SelectOption[];
    value: string;
    name: string;
    labelClassName?: string,
    onChange: (value: string) => void;
    isDisabled?: (value: string) => boolean;
    className?: string;
}

class RadioButtonGroup extends React.Component<RadioButtonGroupProps> {
    constructor(props: RadioButtonGroupProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.getRadioButton = this.getRadioButton.bind(this);
    }

    getRadioButton(option: SelectOption): JSX.Element {
        return (
            <label key={option.text} className={this.props.labelClassName || ''}>
                <input
                    key={option.value}
                    type="radio"
                    name={this.props.name}
                    value={option.value}
                    checked={this.props.value === option.value}
                    onChange={this.onChange}
                    disabled={this.props.isDisabled ? this.props.isDisabled(option.value) : false} />
                {option.text}
            </label>
        );
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.props.onChange(e.target.value);
    }

    render(): JSX.Element {
        return (
            <div className={`radio-button-group ${this.props.className || ''}`}>
                {this.props.options.map(this.getRadioButton)}
            </div>
        );
    }
}

export default RadioButtonGroup;
