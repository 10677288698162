import './Step.scss';
import { getContainerStyles, getStepTitleStyles, getPointContainerStyles, getLineStyles, getContainerClasses, getPointClasses } from './Step.Helpers';
import pixelize from '../../../../infrastructure/helpers/html/pixelize';
import React from 'react';
import StepProps from './StepProps';
import StepState from './StepState';

class Step extends React.Component<StepProps> {
    constructor(props: StepProps) {
        super(props);


        this.build = this.build.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    build(): StepState {

        let height = Math.round(0.6 * this.props.width);
        let halfHeight = Math.round(0.3 * this.props.width);
        let lineThickness = Math.round(0.1 * halfHeight);
        let pointFontSize = Math.round(0.6 * halfHeight);

        return {
            width: pixelize(this.props.width),
            height: pixelize(height),
            halfHeight: pixelize(halfHeight),
            lineThickness: pixelize(lineThickness),
            pointFontSize: pixelize(pointFontSize),
            titleFontSize: pixelize(this.props.titleFontSize),
            isFirst: this.props.orderNumber === 1,
            isLast: this.props.orderNumber === this.props.totalStepCount
        };
    }

    onClick(): void {

        if (!this.props.step.clickable()) {
            return;
        }

        this.props.step.onClick();
    }

    render(): JSX.Element {

        let step = this.build();

        return (
            <div className={getContainerClasses(this.props)} style={getContainerStyles(step)}>

                <div className="step-title" style={getStepTitleStyles(step)}>{this.props.step.title}</div>

                <div className="point-container" style={getPointContainerStyles(step)}>

                    <div className="column line left" style={getLineStyles(step, !step.isFirst)}></div>

                    <div className={getPointClasses(this.props.step.clickable())} onClick={this.onClick}>
                        <span>{this.props.orderNumber}</span>
                    </div>

                    <div className="column line right" style={getLineStyles(step, !step.isLast)}></div>
                </div>
            </div>
        );
    }
}

export default Step;