import Assignment from '../../../types/functions/Assignment/Assignment';
import assignmentHelper from '../../../infrastructure/helpers/functions/assignment/assignmentHelper';
import AssignmentItem from './AssignmentItem/AssignmentItem';
import assignmentItemHelper from '../../../infrastructure/helpers/functions/assignment/assignmentItemHelper';
import copyObject from '../../../infrastructure/helpers/common/copyObject';
import CustomDataSourceFields from '../../../types/report/CustomDataSourceFields';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import Lookup from '../../../types/functions/Lookup';
import React from 'react';
import ReportFieldDefinition from '../../../types/report/ReportFieldDefinition';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import ReportVariable from '../../../types/report/ReportVariable';
import Dictionary from '../../../types/report/Dictionary';
import UserDefinedFunction from '../../../types/report/UserDefinedFunction';

interface AssignmentBuilderProps {
    isReadOnly: boolean;
    location: ReportLogicLocation;
    assignment: Assignment;
    fieldName: string;
    fieldNameSuffix?: string;
    functions: UserDefinedFunction[];
    lookups: Lookup[];
    dictionaries: Dictionary[];
    variables: ReportVariable[];
    dataSources: IngestionConfig[];
    reportFields: ReportFieldDefinition[];
    customDataSourceFields: CustomDataSourceFields;
    onAddAssignmentClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onRemoveAssignmentClick: (location: ReportLogicLocation, isSingle?: boolean, event?: React.MouseEvent<HTMLElement>) => void;
    onResetAssignmentClick: (location: ReportLogicLocation, isSingle?: boolean, event?: React.MouseEvent<HTMLElement>) => void;
    onOperandClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onComparisonTypeChange: (location: ReportLogicLocation, value: string, event?: React.ChangeEvent<HTMLElement>) => void;
    onRemoveConditionClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onLogicalOperatorChange: (location: ReportLogicLocation, operator: string, event?: React.ChangeEvent<HTMLElement>) => void;
    onParenthesesChange: (location: ReportLogicLocation, count: number, type: string, event?: React.MouseEvent<HTMLElement>) => void;
    onAddConditionClick: (location: ReportLogicLocation, operator: string | null, event?: React.MouseEvent<HTMLElement>) => void;
    onCopyClick: (location: ReportLogicLocation) => void;
    onPasteClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    allowPaste: (location: ReportLogicLocation) => boolean;
    onValidateFieldClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    getContent?: (assignment: Assignment) => JSX.Element;
    onClearClick?: (e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLElement>) => Promise<boolean>;
    onReorderAssignmentConditions?: (error?: string) => void;
}

class AssignmentBuilder extends React.Component<AssignmentBuilderProps> {

    constructor(props: AssignmentBuilderProps) {
        super(props);

        this.getAssignmentItem = this.getAssignmentItem.bind(this);
        this.getAssignmentItemLocation = this.getAssignmentItemLocation.bind(this);
    }

    getAssignmentItem(item: Assignment, i: number, items: Assignment[]): JSX.Element {

        let details = assignmentItemHelper.getDetails(item, i, items);

        return (
            <AssignmentItem
                key={item.number}
                isReadOnly={this.props.isReadOnly}
                functions={this.props.functions}
                lookups={this.props.lookups}
                dictionaries={this.props.dictionaries}
                variables={this.props.variables}
                dataSources={this.props.dataSources}
                reportFields={this.props.reportFields}
                customDataSourceFields={this.props.customDataSourceFields}
                location={this.getAssignmentItemLocation(item)}
                assignment={item}
                fieldName={this.props.fieldName}
                fieldNameSuffix={this.props.fieldNameSuffix}
                details={details}
                onAddAssignmentClick={this.props.onAddAssignmentClick}
                onRemoveAssignmentClick={this.props.onRemoveAssignmentClick}
                onResetAssignmentClick={this.props.onResetAssignmentClick}
                onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                onParenthesesChange={this.props.onParenthesesChange}
                onAddConditionClick={this.props.onAddConditionClick}
                onRemoveConditionClick={this.props.onRemoveConditionClick}
                onComparisonTypeChange={this.props.onComparisonTypeChange}
                onOperandClick={this.props.onOperandClick}
                onCopyClick={this.props.onCopyClick}
                onPasteClick={this.props.onPasteClick}
                onClearClick={this.props.onClearClick}
                allowPaste={this.props.allowPaste}
                onValidateFieldClick={(event) => this.props.onValidateFieldClick(this.props.location, event)}
                getContent={this.props.getContent}
                onReorderConditions={this.props.onReorderAssignmentConditions}
            />
        );
    }

    getAssignmentItemLocation(assignment: Assignment): ReportLogicLocation {

        let location = copyObject(this.props.location);

        location.assignmentNumber = assignment.number;

        return location;
    }

    render(): JSX.Element {
        return (
            <div key={this.props.assignment.number}>
                {assignmentHelper.asCollection(this.props.assignment).map(this.getAssignmentItem)}
            </div>
        );
    }
}

export default AssignmentBuilder;
