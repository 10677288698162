import { dateTimeSeparator } from '../../constants/dateTimeFormats';

const hourChars = ['H', 'h'];

const isSeparatorIndex = (format: string, i: number): boolean => {

    return i > -1 && hourChars.includes(format[i + 1]);
};

const findSpaceIndex = (format: string): number => {

    let i = format.lastIndexOf(dateTimeSeparator.space);

    while (i > -1) {

        if (isSeparatorIndex(format, i)) {

            return i;
        }

        i = format.substr(0, i).lastIndexOf(dateTimeSeparator.space);
    }

    return i;
};

const findIndex = (format: string): number => {

    let tIndex = format.indexOf(dateTimeSeparator.t);

    if (isSeparatorIndex(format, tIndex)) {

        return tIndex;
    }

    let spaceIndex = findSpaceIndex(format);

    if (isSeparatorIndex(format, spaceIndex)) {

        return spaceIndex;
    }

    let colonIndex = format.indexOf(dateTimeSeparator.colon);

    if (isSeparatorIndex(format, colonIndex)) {

        return colonIndex;
    }

    let dIndex = format.indexOf(dateTimeSeparator.d);

    if (isSeparatorIndex(format, dIndex)) {

        return dIndex;
    }

    return -1;
};

const dateTimeSeparatorHelper = {
    findIndex
};

export default dateTimeSeparatorHelper;
