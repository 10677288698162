import ChangeDescription from '../../../../infrastructure/types/DifferenceVisualizer/ChangeDescription';
import ColumnDefinition from '../../../../infrastructure/types/DataGrid/ColumnDefinition';
import React from 'react';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';

const getDiffContent = (value: string, className: string): JSX.Element | string => {

    if (!typeHelper.isString(value)) {

        return 'n/a';
    }

    return (
        <div className={className}>{value}</div>
    );
};

const moduleColumn: ColumnDefinition<ChangeDescription> = {
    header: 'Module',
    getContent: x => x.module,
    width: '140px'
};

const moduleRefColumn: ColumnDefinition<ChangeDescription> = {
    header: 'Module Ref',
    getContent: x => x.moduleRef,
    width: '140px'
};

const commonColumns: ColumnDefinition<ChangeDescription>[] = [
    {
        header: 'Module Name',
        getContent: x => x.moduleName,
        className: 'break-all'
    },
    {
        header: 'Module Component',
        getContent: x => x.moduleComponent,
        width: '156px'
    },
    {
        header: 'Before',
        getContent: x => getDiffContent(x.previous, 'before')
    },
    {
        header: 'After',
        getContent: x => getDiffContent(x.current, 'after')
    }
];

export { moduleColumn, moduleRefColumn, commonColumns };
