import { AxiosResponse } from 'axios';
import React from 'react';
import { Unsubscribe } from 'redux';
import accuracyValidationKind from '../../../infrastructure/constants/accuracyValidationKind';
import actionItemHelper from '../../../infrastructure/helpers/actionItem/actionItemHelper';
import copyObject from '../../../infrastructure/helpers/common/copyObject';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import complianceCommentHelper from '../../../infrastructure/helpers/complianceComment/complianceCommentHelper';
import accuracyValidationHelper from '../../../infrastructure/helpers/functions/accuracyValidation/accuracyValidationHelper';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import actions from '../../../store/actions';
import store from '../../../store/store';
import ActionItemSaveRequest from '../../../types/common/ActionItem/ActionItemSaveRequest';
import ComplianceComment from '../../../types/common/ComplianceComment';
import locationType from '../../../types/functions/Location/LocationType';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import AccuracyValidation from '../../../types/report/AccuracyValidation';
import EditableText from '../../Common/EditableText/EditableText';
import ErrorList from '../../Common/ErrorList/ErrorList';
import AssignmentBuilder from '../../Functions/Assignment/AssignmentBuilder';
import DeclarationBuilderProps from '../../Functions/Declaration/DeclarationBuilderProps';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import CopyValidationModalState from './CopyValidationModal/CopyValidationModalState';
import { getColumns } from './ValidationBuilder.Grid';
import ValidationSection from './ValidationSection/ValidationSection';
import ObjectIndexer from '../../../infrastructure/types/ObjectIndexer';
import reportConfigComponentLock from '../../../types/report/ReportConfigComponentLock';
import { getConfirmRemoveMessage } from '../../Functions/Assignment/AssignmentItem/AssignmentItem.Helpers';
import ReportConfigComponentLock from '../../../types/report/ReportConfigComponentLock';
import ComponentType from '../../../types/report/ComponentType';
import ValidationChangeLogModalState from './ChangeLogModal/ValidationChangeLogModalState';
import ValidationChangeLogModal from './ChangeLogModal/ValidationChangeLogModal';
import identityStorage from '../../../infrastructure/authorization/identityStorage';
import ActionItemDto from '../../../types/common/ActionItem/ActionItemDto';
import ActionitemDto from '../../../types/common/ActionItem/ActionItemDto';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';
import { htmlIds } from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation.HtmlIds';

const getDefaultExpanded = (): ObjectIndexer<boolean> => {

    let result = {} as ObjectIndexer<boolean>;

    result[htmlIds.cnValidations] = false;
    result[htmlIds.coreValidations] = false;

    return result;
};

interface State extends DeclarationBuilderProps<AccuracyValidation> {
    expandedSections: ObjectIndexer<boolean>,
    showWarningModal: boolean,
    message: string,
    location: ReportLogicLocation,
    validation: AccuracyValidation,
    isSingle?: boolean,
    event?: React.MouseEvent<HTMLElement>,
    reportConfigComponentLock?: reportConfigComponentLock
}

class ValidationBuilder extends React.Component<DeclarationBuilderProps<AccuracyValidation>, State> {
    private unsubscribeCopyValidation: Unsubscribe | undefined;

    constructor(props: DeclarationBuilderProps<AccuracyValidation>) {
        super(props);

        this.state = {
            expandedSections: getDefaultExpanded(),
            showWarningModal: false,
            message: '',
            location: {} as ReportLogicLocation,
            validation: {} as AccuracyValidation,
            isSingle: false,
            event: undefined,
            reportConfigComponentLock: {} as reportConfigComponentLock
        } as State;

        this.onIdentifierChange = this.onIdentifierChange.bind(this);
        this.onBespokeToggleClick = this.onBespokeToggleClick.bind(this);
        this.onAddValidationClick = this.onAddValidationClick.bind(this);
        this.onRemoveValidationClick = this.onRemoveValidationClick.bind(this);
        this.onResetAssignmentClick = this.onResetAssignmentClick.bind(this);
        this.getAssignmentContent = this.getAssignmentContent.bind(this);
        this.onCommentClick = this.onCommentClick.bind(this);
        this.getCommentCount = this.getCommentCount.bind(this);
        this.onGetCommentSuccess = this.onGetCommentSuccess.bind(this);
        this.onActionItemClick = this.onActionItemClick.bind(this);
        this.onGetActionItemDataSuccess = this.onGetActionItemDataSuccess.bind(this);
        this.getActionItemCount = this.getActionItemCount.bind(this);
        this.onCopyValidationClick = this.onCopyValidationClick.bind(this);
        this.onCopyValidationSave = this.onCopyValidationSave.bind(this);
        this.tryLock = this.tryLock.bind(this);
        this.onToggleSectionClick = this.onToggleSectionClick.bind(this);
        this.isExpanded = this.isExpanded.bind(this);
        this.onLockSectionClick = this.onLockSectionClick.bind(this);
        this.onViewChangeLogClick = this.onViewChangeLogClick.bind(this);
        this.onRemoveAssignmentConfirmed = this.onRemoveAssignmentConfirmed.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribeCopyValidation = store.subscribe(this.onCopyValidationSave);
    }

    componentWillUnmount(): void {
        (this.unsubscribeCopyValidation as Unsubscribe)();
    }

    async onIdentifierChange(identifier: string, number: number, event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> {

        let location = this.getLocation(number);

        let validation = this.props.reportConfig.validations.find(v => v.number === number);

        const isLocked = await this.tryLock(validation);

        if (!isLocked) {
            this.props.onDeclarationChange(location, { identifier: identifier } as AccuracyValidation);
        }
    }

    async onBespokeToggleClick(isBespoke: boolean, number: number, event?: React.MouseEvent<HTMLElement>): Promise<void> {

        let location = this.getLocation(number);

        let validation = accuracyValidationHelper.getDeclaration(this.props.reportConfig.validations, location);

        const isLocked = await this.tryLock(validation);

        if (!isLocked) {
            let changes = { isBespoke: isBespoke } as AccuracyValidation;

            if (isBespoke) {
                changes.bespokeAssignment = '';
            }
            else if (!typeHelper.isObject(validation.assignment)) {

                changes.assignment = accuracyValidationHelper.getDefaultAssignment();
            }

            this.props.onDeclarationChange(location, changes);
        }
    }

    onAddValidationClick(kind: string): void {

        this.props.onAddDeclarationClick((v) => { v.kind = kind });
    }

    async onRemoveValidationClick(number: number, event?: React.MouseEvent<HTMLElement>): Promise<void> {

        let location = this.getLocation(number);

        let validation = this.props.reportConfig.validations.find(v => v.number === number);

        const isLocked = await this.tryLock(validation);

        if (!isLocked) {
            this.props.onRemoveDeclarationClick(location);
        }
    }

    async onRemoveAssignmentClick(location: ReportLogicLocation, validation: AccuracyValidation, isSingle?: boolean, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: reportConfigComponentLock): Promise<void> {

        const isLocked = await this.tryLock(validation);

        if (!isLocked) {
            this.setState({ showWarningModal: true, message: getConfirmRemoveMessage(isSingle || false), location});
        }
    }

    onRemoveAssignmentConfirmed() {

        this.setState({ showWarningModal: false });

        this.props.onRemoveAssignmentClick(this.state.location, this.state.isSingle, this.state.event, this.state.reportConfigComponentLock);
    }

    async onResetAssignmentClick(location: ReportLogicLocation, validation: AccuracyValidation, isSingle?: boolean, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: reportConfigComponentLock): Promise<void> {

        const isLocked = await this.tryLock(validation);

        if (!isLocked) {
            this.props.onResetAssignmentClick(location, false, event, reportConfigComponentLock);
        }
    }

    getLocation(number: number): ReportLogicLocation {

        return { statement: locationType.statement.validation, statementNumber: number } as ReportLogicLocation;
    }

    async onLockSectionClick(event: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, number: number): Promise<boolean> {

        return await this.tryLock(undefined, number);
    }

    async tryLock(validation?: AccuracyValidation, number?: number): Promise<boolean> {

        if (this.props.onComponentContainerClick) {

            if (!validation) {
                validation = this.props.reportConfig.validations.find(v => v.number === number);
            }
            if (!validation?.isUsedByCurrentUser) {

                return await this.props.onComponentContainerClick({ componentId: validation?.id || 0, componentType: ComponentType.AccuracyValidation, number: validation?.number || 0 });
            }
        }
        return false;
    }

    async onReorderConditions(validation: AccuracyValidation, error?: string) {
        let isLocked = await this.tryLock(validation);

        if (error && !validation.errors.includes(error)) {
            validation.errors.push(error);
        }
        if (!isLocked) {
            let location = this.getLocation(validation.number);
            this.props.onDeclarationChange(location, validation);
        }
    }

    getAssignmentContent(validation: AccuracyValidation): JSX.Element {

        let location = this.getLocation(validation.number);

        if (validation.isBespoke) {
            return (
                <>
                    <div className="code-block">
                        <EditableText
                            className="code"
                            multiline={true}
                            value={validation.bespokeAssignment}
                            onChange={value => this.props.onDeclarationChange(location, { bespokeAssignment: value } as AccuracyValidation)}
                            disabled={this.props.isReadOnly} />
                    </div>
                    <ErrorList errors={validation.errors} className="mt-2" />
                </>
            );
        }
        const reportConfigComponentLock: ReportConfigComponentLock = { componentId: validation.id || 0, componentType: ComponentType.AccuracyValidation, number: validation.number };

        return (
            <>
                <AssignmentBuilder
                    isReadOnly={validation.isReadOnly || this.props.isReadOnly}
                    location={this.getLocation(validation.number)}
                    assignment={validation.assignment}
                    fieldName="FLAG VALIDATION"
                    functions={this.props.reportConfig.userDefinedFunctions}
                    lookups={this.props.reportConfig.lookups}
                    dictionaries={this.props.reportConfig.dictionaries}
                    variables={this.props.reportConfig.variables}
                    dataSources={this.props.collections.dataSources}
                    reportFields={this.props.collections.reportFields}
                    customDataSourceFields={this.props.collections.customDataSourceFields}
                    onAddAssignmentClick={(location, event) => this.props.onAddAssignmentClick(location, event, reportConfigComponentLock)}
                    onRemoveAssignmentClick={(location, isSingle, event) => this.onRemoveAssignmentClick(location, validation, isSingle, event, reportConfigComponentLock)}
                    onResetAssignmentClick={(location, isSingle, event) => { this.onResetAssignmentClick(location, validation, isSingle, event, reportConfigComponentLock); }}
                    onLogicalOperatorChange={(location, operator, event) => this.props.onLogicalOperatorChange(location, operator, event, reportConfigComponentLock)}
                    onParenthesesChange={(location, count, type, event) => this.props.onParenthesesChange(location, count, type, event, reportConfigComponentLock)}
                    onAddConditionClick={(location, operator, event) => this.props.onAddConditionClick(location, operator, event, reportConfigComponentLock)}
                    onRemoveConditionClick={(location, event) => this.props.onRemoveConditionClick(location, event, reportConfigComponentLock)}
                    onComparisonTypeChange={(location, value, event) => this.props.onComparisonTypeChange(location, value, event, reportConfigComponentLock)}
                    onOperandClick={(async (location, event) => { this.props.onOperandClick(location, event, reportConfigComponentLock); })}
                    onCopyClick={(location) => this.props.onCopyClick(location, reportConfigComponentLock)}
                    onPasteClick={(location, event) => this.props.onPasteClick(location, event, reportConfigComponentLock)}
                    onValidateFieldClick={(location, event) => this.props.onValidateDeclarationClick(location, event, reportConfigComponentLock)}
                    allowPaste={this.props.allowPaste}
                    onReorderAssignmentConditions={(error) => { this.onReorderConditions(validation, error); }} />

                <ErrorList errors={validation.errors} className="mt-2" />
            </ >
        );
    }

    getCommentCount(id: number): number {
        return this.props.counts.validationCommentCounts[id === null || id === undefined ? -1 : id.toString()] || 0;
    }

    async onCommentClick(id: number, event?: React.MouseEvent<HTMLElement>): Promise<any> {

        const validation = this.props.reportConfig.validations.find(v => v.id === id)
        const isLocked = await this.tryLock(validation);
        if (!isLocked) {
            let url = urlHelper.buildRoute(['validation-comment', id]);

            return httpClient.get<ComplianceComment[]>(url).then(response => this.onGetCommentSuccess(response.data, url));
        }
    }

    onGetCommentSuccess(comments: ComplianceComment[], url: string): void {

        let state = complianceCommentHelper.createModalState(comments, url);

        store.dispatch({ type: actions.complianceCommentModal.open, payload: state });
    }

    async onActionItemClick(id: number, event?: React.MouseEvent<HTMLElement>,): Promise<any> {

        const validation = this.props.reportConfig.validations.find(v => v.id === id);

        const isLocked = await this.tryLock(validation);

        if (!isLocked) {

            let identity = identityStorage.get();

            let allItemsForClient = urlHelper.buildRoute(['action-item', 'by-client-id']);

            let validationActionItems = urlHelper.buildRoute(['action-item', 'validation', id]);

            let buildRelationUrl = (actionItemId: string) => urlHelper.buildRoute(['action-item', actionItemId, 'validation', id]);

            let calls: [Promise<AxiosResponse<ActionitemDto[]>>, Promise<AxiosResponse<ActionitemDto[]>>] = [httpClient.get<ActionitemDto[]>(allItemsForClient, { reportConfigId: this.props.reportConfig.id, clientId: identity.clientId || null }), httpClient.get<ActionitemDto[]>(validationActionItems)];

            let saveRequest = { reportConfigId: this.props.reportConfig.id, validationId: id } as ActionItemSaveRequest;

            return Promise.all(calls).then(responses => this.onGetActionItemDataSuccess(responses[0].data, responses[1].data, saveRequest, buildRelationUrl));
        }
    }

    async onCopyValidationClick(number: number, event?: React.MouseEvent<HTMLElement>): Promise<void> {

        const validation = this.props.reportConfig.validations.find(v => v.number === number)
        const isLocked = await this.tryLock(validation);
        if (!isLocked) {

            let state: CopyValidationModalState = {
                title: 'Copy Validation',
                isOpen: true,
                error: null,
                sourceValidationNumber: number.toString(),
                targetValidationNumber: ''
            };

            store.dispatch({ type: actions.copyValidationModal.change, payload: state });
        }
    }

    onCopyValidationSave(): void {

        let appState = store.getState();
        if (appState.action !== actions.copyValidationModal.save) {
            return;
        }

        let sourceNumber = parseInt(appState.copyValidationModalSave.sourceValidationNumber);
        let sourceValidation = this.props.reportConfig.validations.find(x => x.number === sourceNumber) as AccuracyValidation;

        let targetNumber = parseInt(appState.copyValidationModalSave.targetValidationNumber);
        let targetValidation = this.props.reportConfig.validations.find(x => x.number === targetNumber) as AccuracyValidation;

        targetValidation.assignment = copyObject(sourceValidation.assignment);

        let location = this.getLocation(targetValidation.number);

        this.props.onDeclarationChange(location, targetValidation);
    }

    onGetActionItemDataSuccess(allActionItems: ActionItemDto[], validationActionItems: ActionitemDto[], saveRequest: ActionItemSaveRequest, buildRelationUrl: (actionItemId: string) => string): void {

        let originalActionItems: ActionItemDto[] = [];
        allActionItems.forEach((iten) => {
            originalActionItems.push(Object.assign({}, iten));
        });

        const validationItems = actionItemHelper.prepareModuleActionitems(allActionItems, validationActionItems);

        let state = actionItemHelper.createModalState(originalActionItems, allActionItems, validationItems, saveRequest, buildRelationUrl, 'AccuracyValidation');

        store.dispatch({ type: actions.actionItemModal.open, payload: state });
    }

    getActionItemCount(id: number): number {

        return this.props.counts.validationActionItemCounts[id.toString()] || 0;
    }

    onToggleSectionClick(htmlId: string): void {

        let expandedSections = this.state.expandedSections;

        expandedSections[htmlId] = !expandedSections[htmlId];

        this.setState({ expandedSections: expandedSections });
    }

    isExpanded(htmlId: string): boolean {

        return this.state.expandedSections[htmlId];
    }

    onViewChangeLogClick(id: number, event?: React.MouseEvent<HTMLElement>): void {

        const validation = this.props.reportConfig.validations.find(v => v.id === id);

        let state: ValidationChangeLogModalState = {
            title: `Change Log for Validation ID : ${validation?.identifier}`,
            isOpen: true,
            error: null,
            validationId: id
        };

        store.dispatch({ type: actions.validationChangeLogModal.open, payload: state });
    }

    render(): JSX.Element {

        let standardValidations = this.props.reportConfig.validations.filter(x => x.kind === accuracyValidationKind.standard);
        let coreValidations = this.props.reportConfig.validations.filter(x => x.kind === accuracyValidationKind.core);

        return (
            <>
                <ValidationSection
                    key={1}
                    heading="CN Validations"
                    htmlId={htmlIds.cnValidations}
                    onClick={this.onToggleSectionClick}
                    isExpanded={this.isExpanded}>

                    <ReportLogicList
                        isWaiting={this.props.isWaiting}
                        items={standardValidations}
                        columns={getColumns(
                            this.onIdentifierChange,
                            this.onRemoveValidationClick,
                            this.onBespokeToggleClick,
                            this.onCommentClick,
                            this.getCommentCount,
                            this.getActionItemCount,
                            this.onActionItemClick,
                            this.onCopyValidationClick,
                            this.onViewChangeLogClick,
                            this.props.isReadOnly)}
                        getContent={this.getAssignmentContent}
                        getKey={v => v.number}
                        createHtmlId={v => reportConfigNavigation.buildValidationId(v.number, v.kind.toLowerCase())}
                        onClick={(e: any, item: AccuracyValidation) => {
                            if (item.isLocked && item.isReadOnly) {
                                this.tryLock(item);
                            }
                        }} />
                    {
                        !this.props.isReadOnly &&
                        <div className="mt-4 mb-4">
                            <button
                                onClick={() => this.onAddValidationClick(accuracyValidationKind.standard)}
                                className="btn btn-light">
                                + CN Validation
                            </button>
                        </div>
                    }
                </ValidationSection>

                <ValidationSection
                    key={2}
                    heading="Core Validations"
                    htmlId={htmlIds.coreValidations}
                    onClick={this.onToggleSectionClick}
                    isExpanded={this.isExpanded}>

                    <ReportLogicList
                        isWaiting={this.props.isWaiting}
                        items={coreValidations}
                        columns={getColumns(
                            this.onIdentifierChange,
                            this.onRemoveValidationClick,
                            this.onBespokeToggleClick,
                            this.onCommentClick,
                            this.getCommentCount,
                            this.getActionItemCount,
                            this.onActionItemClick,
                            this.onCopyValidationClick,
                            this.onViewChangeLogClick,
                            this.props.isReadOnly)}
                        getContent={this.getAssignmentContent}
                        getKey={v => v.number}
                        createHtmlId={v => reportConfigNavigation.buildValidationId(v.number, v.kind.toLowerCase())}
                        onClick={(e: React.MouseEvent<HTMLElement>, item: AccuracyValidation) => {
                            if (item.isLocked && item.isReadOnly) {
                                this.tryLock(item);
                            }
                        }} />
                    {
                        !this.props.isReadOnly &&
                        <div className="mt-4 mb-4">
                            <button
                                onClick={() => this.onAddValidationClick(accuracyValidationKind.core)}
                                className="btn btn-light">
                                + Core Validation
                            </button>
                        </div>
                    }
                </ValidationSection>

                <ValidationChangeLogModal />
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onRemoveAssignmentConfirmed}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Remove Assignment'
                        message={this.state.message} />
                }
            </>
        );
    }
}

export default ValidationBuilder;