import Statement from './Statement';

abstract class StatementHelper<T extends Statement> {

    protected match(statement: T, number: number): boolean {

        return statement.number === number;
    }

    add(statements: T[], statement: T): void {

        let filtered = this.getRelevant(statements);

        let lastNumber = filtered.length > 0 ? Math.max.apply(null, filtered.map(x => x.number)): 0;

        statement.number = lastNumber + 1;

        statements.push(statement);
    }

    remove(statements: T[], number: number): T[] {

        return statements.filter(x => !this.match(x, number));
    }

    getByNumber(statements: T[], number: number): T {

        return statements.find(x => this.match(x, number)) as T;
    }

    findIndex(statements: T[], number: number): number {

        return statements.findIndex(x => this.match(x, number));
    }

    getRelevant(statements: T[]): T[] {

        return statements;
    }

    abstract getDataSourceId(statement: T, dataSourceId?: number | null): number | null;

    abstract getEmpty(rawDataSourceId: number | null): T;
}

export default StatementHelper;