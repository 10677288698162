import ReportVariable from '../../../../types/report/ReportVariable';
import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';
import CopyVariableModalState from './CopyVariableModalState';

const validate = (state: CopyVariableModalState, variables: ReportVariable[]): string | null => {

    if (stringHelper.isEmpty(state.sourceVariableNumber)) {
        return 'From variable is required';
    }

    if (stringHelper.isEmpty(state.targetVariableNumber)) {
        return 'To variable is required';
    }

    let sameVariable = state.sourceVariableNumber === state.targetVariableNumber;

    if (sameVariable) {
        return 'Unable to copy from a variable to the same variable';
    }

    let source = variables.find(x => x.number === parseInt(state.sourceVariableNumber));
    let target = variables.find(x => x.number === parseInt(state.targetVariableNumber));

    if (source?.dataType !== target?.dataType) {
        return `Unable to copy from a variable of type ${source?.dataType} to variable of type ${target?.dataType}`;
    }

    return null;
};

export { validate };
