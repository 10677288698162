import booleanLiteral from '../../../constants/functions/booleanLiteral';
import Condition from '../../../../types/functions/Condition/Condition';
import dataType from '../../../constants/dataType';
import functionHelper from '../function/functionHelper';
import operandHelper from '../operand/operandHelper';
import operandType from '../../../constants/functions/operandType';

interface AutoCompleteCase {
    qualifies: (item: Condition) => boolean;
    autoComplete: (item: Condition) => void;
}

const qualifies1 = (item: Condition): boolean => {

    if (Object.values(operandType).includes(item.operand2.operandType)) {

        return false;
    }

    if (item.operand1.operandType !== operandType.function) {

        return false;
    }

    let definition = functionHelper.getFunctionDefinition(item.operand1.function);

    return definition.returnType === dataType.boolean;
};

const autoComplete1 = (item: Condition): void => {

    item.operand2 = operandHelper.getEmpty();

    item.operand2.operandType = operandType.constant;
    item.operand2.dataType = dataType.boolean;
    item.operand2.value = booleanLiteral.true;
};

const cases: AutoCompleteCase[] = [

    { qualifies: qualifies1, autoComplete: autoComplete1 }
];

export default cases;