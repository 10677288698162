import ObjectIndexer from '../../types/ObjectIndexer';
import typeHelper from './typeHelper';

const prefix = 'filename=';
const suffix = ';';
const quote = '"';

const extractFilename = (headers: ObjectIndexer<string>): string => {

    let value = headers['content-disposition'];

    let prefixIndex = value.indexOf(prefix);
    let suffixIndex = value.indexOf(suffix, prefixIndex);

    let filename = value.substring(prefixIndex + prefix.length, suffixIndex);

    if (!filename.startsWith(quote)) {

        return filename;
    }

    return filename.substring(1, filename.length - 1);
};

const extract = (messages: string[], response: ObjectIndexer<any>, key: string): void => {

    if (typeHelper.isString(response[key])) {

        messages.push(response[key]);
    }
    else if (typeHelper.isObject(response[key])) {

        messages.push(JSON.stringify(response[key], null, 4));
    }
};

const extractMessage = (response: ObjectIndexer<any>): string => {

    let messages: string[] = [];

    extract(messages, response, 'message');
    extract(messages, response, 'title');
    extract(messages, response, 'errors');

    return messages.join('\n');
};

export { extractFilename, extractMessage };