import ObjectIndexer from '../../types/ObjectIndexer';

const comparisonType = {
    equal: 'Equal',
    notEqual: 'NotEqual',
    lessThan: 'LessThan',
    greaterThan: 'GreaterThan',
    lessThanOrEqual: 'LessThanOrEqual',
    greaterThanOrEqual: 'GreaterThanOrEqual'
};

const getComparisonTypeDisplayMap = (): ObjectIndexer<string> => {
    let map = {} as ObjectIndexer<string>;

    map[comparisonType.equal] = '=';
    map[comparisonType.notEqual] = '!=';
    map[comparisonType.lessThan] = '<';
    map[comparisonType.greaterThan] = '>';
    map[comparisonType.lessThanOrEqual] = '<=';
    map[comparisonType.greaterThanOrEqual] = '>=';

    return map;
};

const comparisonTypeDisplayMap = getComparisonTypeDisplayMap();

export { comparisonType, comparisonTypeDisplayMap };
