import { getDefaultState, createFieldOption, createEmptyFieldOption } from './CopyValidationModal.Helpers';
import { Unsubscribe } from 'redux';
import { validate } from './CopyValidationModal.Validation';
import actions from '../../../../store/actions';
import CopyValidationModalState from './CopyValidationModalState';
import Modal from '../../../Common/Modal/Modal';
import React from 'react';
import AccuracyValidation from '../../../../types/report/AccuracyValidation';
import SelectSearch, { SelectedOptionValue, SelectSearchOption } from 'react-select-search';
import store from '../../../../store/store';
import WarningModal from '../../../Common/Modals/WarningModal/WarningModal';

interface Props {
    validations: AccuracyValidation[];
}

class CopyValidationModal extends React.Component<Props, CopyValidationModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: Props) {
        super(props);

        this.state = getDefaultState();

        this.onOpen = this.onOpen.bind(this);
        this.onSourceFieldChange = this.onSourceFieldChange.bind(this);
        this.onTargetFieldChange = this.onTargetFieldChange.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.close = this.close.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.onCopyConfirmed = this.onCopyConfirmed.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onOpen);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    onOpen(): void {
        let state = store.getState();

        if (state.action !== actions.copyValidationModal.change) {
            return;
        }

        this.setState(state.copyValidationModalChange);
    }

    onSourceFieldChange(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {

        let changes = { sourceValidationNumber: selectedValue.toString() } as CopyValidationModalState;

        this.setState(changes);
    }

    onTargetFieldChange(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {

        let changes = { targetValidationNumber: selectedValue.toString() } as CopyValidationModalState;

        this.setState(changes);
    }

    onCopyClick(): void {

        let error = validate(this.state, this.props.validations);

        if (error) {
            this.setState({ error: error });
            return;
        }

        let target = this.props.validations.find(x => x.number.toString() === this.state.targetValidationNumber);

        let message = `The logic of ${target?.identifier} will be overwritten. Are you sure you want to proceed?`;

        this.setState({ showWarningModal: true, message });
    }

    onCopyConfirmed() {

        store.dispatch({ type: actions.copyValidationModal.save, payload: this.state });

        var defaultState = getDefaultState();
        this.setState(defaultState);

        this.close();
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    getOptions(addEmptyOption: boolean): SelectSearchOption[] {

        var sorted = this.props.validations.sort(this.sortFunction);
        var options = sorted.map(createFieldOption);

        if (addEmptyOption) {

            options.splice(0, 0, createEmptyFieldOption());
        }

        return options;
    }

    sortFunction(a: AccuracyValidation, b: AccuracyValidation): number {

        if (a.kind === b.kind) {
            return a.number - b.number;
        }

        return a.identifier.localeCompare(b.identifier);
    }

    render(): JSX.Element {

        return (
            <>
                <Modal state={this.state} close={this.close} size="large">

                    <h5 className="mb-3">From</h5>

                    {
                        <SelectSearch
                            options={this.getOptions(false)}
                            value={this.state.sourceValidationNumber}
                            onChange={this.onSourceFieldChange}
                            search={true} />
                    }

                    <h5 className="mt-4 mb-3">To</h5>

                    {
                        <SelectSearch
                            options={this.getOptions(true)}
                            value={this.state.targetValidationNumber}
                            onChange={this.onTargetFieldChange}
                            search={true} />
                    }

                    <div className="mt-3 mb-3">
                        <button className="btn cb-btn" onClick={this.onCopyClick}>Copy</button>
                    </div>

                </Modal>
                {
                    this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onCopyConfirmed}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Copy validation'
                        message={this.state.message} />
                }
            </>
        );
    }
}

export default CopyValidationModal;