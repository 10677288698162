import ReportConfigCounts from '../../../types/report/ReportConfigCounts';

const getEmpty = (): ReportConfigCounts => {
    return {
        filterCommentCounts: {},
        filterActionItemCounts: {},
        caseCommentCounts: {},
        caseActionItemCounts: {},
        reportFieldCommentCounts: {},
        reportFieldActionItemCounts: {},
        validationCommentCounts: {},
        validationActionItemCounts: {},
        fieldDefinitionCommentCounts: {},
        fieldDefinitionUrlCounts: {}
    };
};

const reportConfigCountHelper = {
    getEmpty
};

export default reportConfigCountHelper;