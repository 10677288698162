import React from 'react';
import Spinner from '../Spinner/Spinner';

interface Props {
    isWaiting: boolean;
}

class SubHeader extends React.Component<Props> {
    render(): JSX.Element {
        return (
            <div className="sub-header">
                {
                    this.props.isWaiting &&
                    <div className="spinner-container">
                        <Spinner />
                    </div>
                }
                {
                    !this.props.isWaiting &&
                    this.props.children
                }
            </div>
        );
    }
}

export default SubHeader;