import React from 'react';

const Spinner = (): React.ReactElement => {
    return (
        <div className="spinner-border spinner-border-sm text-primary">
            <span className="sr-only">Loading...</span>
        </div>
    );
};

export default Spinner;
