import { overrideRegimeMessage } from './CopyReportConfigModal.Helpers';
import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import CopyReportConfigModalState from './CopyReportConfigModalState';
import FormGroup from '../../FormGroup/FormGroup';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import Modal from '../../Modal/Modal';
import PromiseButton from '../../PromiseButton';
import React from 'react';
import SelectList from '../../SelectList/SelectList';
import store from '../../../../store/store';
import urlHelper from '../../../../infrastructure/helpers/common/urlHelper';
import WarningModal from '../WarningModal/WarningModal';
import { getSubRegimeOptions, regimeOptions } from '../../../Report/DataSources/DataSourceForm.Helpers';
import { mapToSelectOptions } from '../../../../infrastructure/helpers/html/selectOptionHelper';
import reportRegime from '../../../../infrastructure/constants/reportRegime';
import RadioButtonGroup from '../../RadioButtonGroup/RadioButtonGroup';
import reportRegimeHelper from '../../../../infrastructure/helpers/report/reportRegime/reportRegimeHelper';

class CopyReportConfigModal extends React.Component<any, CopyReportConfigModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: 'Copy Report Config',
            isOpen: false,
            error: null,
            id: null as any,
            name: '',
            regimeOriginal: '',
            regimeOverride: '',
            validate: () => null,
            showWarningModal: false,
            selectedRegime: '',
            selectedSubRegime: '',
            copyFieldEnrichmentLogic: true,
            regimeOverrideSelected: false
        };

        this.openListener = this.openListener.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onRegimeChange = this.onRegimeChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.close = this.close.bind(this);
        this.onRegimeChangeAccepted = this.onRegimeChangeAccepted.bind(this);
        this.onSubRegimeSelected = this.onSubRegimeSelected.bind(this);
        this.onCopyFieldEnrichmentLogicChange = this.onCopyFieldEnrichmentLogicChange.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.openListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    openListener(): void {
        let state = store.getState();

        if (state.action !== actions.copyReportConfigModal.open) {
            return;
        }

        this.setState(state.copyReportConfigModalState);
    }

    onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {

        this.setState({ name: e.target.value });
    }

    onRegimeChange(value: string): void {
        if (value !== this.state.selectedRegime) {
            this.setState({ selectedRegime: value, showWarningModal: true, regimeOverrideSelected: true });
        }
    }

    onCopyFieldEnrichmentLogicChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let value = e.target.checked ? true : false;
        this.setState({ copyFieldEnrichmentLogic: value });
    }

    onRegimeChangeAccepted() {
        this.setState({ regimeOverride: this.state.selectedRegime, showWarningModal: false });
    }

    onSaveClick(): Promise<any> {

        let error = this.state.validate(this.state.name, true);

        this.setState({ error: error });

        if (error) {
            return Promise.reject(error);
        }

        let url = urlHelper.buildRoute(['report', this.state.id, 'copy']);

        return httpClient.post(url, { name: this.state.name, regime: this.state.regimeOverride, subRegime: this.state.selectedSubRegime, copyEnrichmentLogic: this.state.copyFieldEnrichmentLogic }).then(this.onSaveSuccess);
    }

    onSaveSuccess(): void {
        this.setState({ isOpen: false });

        store.dispatch({ type: actions.copyReportConfigModal.saveSuccess, payload: null as any });
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    onSubRegimeSelected(value: string) {
        this.setState({ selectedSubRegime: value });
    }

    render(): JSX.Element {
        return (
            <>
                <Modal state={this.state} close={this.close}>

                    <FormGroup label="Name:" labelClassName="col-2" contentClassName="col-10">

                        <input type="text" className="form-control" value={this.state.name} onChange={this.onNameChange} />

                    </FormGroup>

                    <FormGroup label="Regime:" labelClassName="col-2" contentClassName="col-10">

                        <SelectList
                            options={mapToSelectOptions(reportRegime)}
                            className="form-control"
                            value={this.state.regimeOverride}
                            onChange={this.onRegimeChange} />

                    </FormGroup>
                    {
                        this.state.regimeOverrideSelected &&
                        <FormGroup label="Copy Field Enrichment Logic:" labelClassName="col-6" contentClassName="col-1">
                            <input
                                style={{ marginTop: '12px' }}
                                type="checkbox"
                                disabled={this.props.isReadOnly}
                                checked={this.state.copyFieldEnrichmentLogic}
                                onChange={this.onCopyFieldEnrichmentLogicChange} />
                        </FormGroup>
                    }
                    {reportRegimeHelper.hasRegimeSubregimes(this.state.selectedRegime || this.state.regimeOriginal) &&
                        <RadioButtonGroup
                        options={getSubRegimeOptions(this.state.selectedRegime || this.state.regimeOriginal)}
                            value={this.state.selectedSubRegime}
                            onChange={this.onSubRegimeSelected}
                            name={`sub-regime-options`} />
                    }
                    <PromiseButton text="Copy" className="btn cb-btn" enableOnErrorOnly={false} task={this.onSaveClick} />
                </Modal>
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onRegimeChangeAccepted}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Regime change'
                        message={overrideRegimeMessage} />
                }
            </>
        );
    }
}

export default CopyReportConfigModal;
