import './NumberRange.scss';
import { ReactComponent as MinusIcon } from '../BootstrapIcons/dash.svg';
import { ReactComponent as PlusIcon } from '../BootstrapIcons/plus.svg';
import React from 'react';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

interface NumberRangeProps {
    min: number;
    max: number;
    value: number;
    onChange: (value: number) => void;
    displayState?: (value: number) => string;
}

class NumberRange extends React.Component<NumberRangeProps> {

    constructor(props: NumberRangeProps) {
        super(props);

        this.onIncreaseClick = this.onIncreaseClick.bind(this);
        this.onDecreaseClick = this.onDecreaseClick.bind(this);
    }

    onIncreaseClick(): void {
        this.props.onChange(this.props.value + 1);
    }

    onDecreaseClick(): void {
        this.props.onChange(this.props.value - 1);
    }

    render(): JSX.Element {
        return (
            <span className="number-range">
                {
                    typeHelper.isFunction(this.props.displayState) &&
                    <span className="state">{(this.props.displayState as (v: number) => string)(this.props.value)}</span>
                }
                <button className="transparent-button" onClick={this.onIncreaseClick} disabled={this.props.value >= this.props.max}><PlusIcon /></button>
                <button className="transparent-button" onClick={this.onDecreaseClick} disabled={this.props.value <= this.props.min}><MinusIcon /></button>
            </span>
        );
    }
}

export default NumberRange;
