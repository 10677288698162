import React from 'react';

interface Props {
    true: string;
    false: string;
    label: string;
    function: string;
    onChange: (func: string) => void;
}

class OverloadCheckbox extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>): void {

        let func = e.target.checked ? this.props.true : this.props.false;

        this.props.onChange(func);
    }

    render(): JSX.Element {

        if (this.props.function !== this.props.true &&
            this.props.function !== this.props.false) {

            return (<></>);
        }

        return (
            <div className="mt-2">
                <label>
                    <input
                        type="checkbox"
                        checked={this.props.function === this.props.true}
                        onChange={this.onChange} />
                    <span>{this.props.label}</span>
                </label>
            </div>
        );
    }
}

export default OverloadCheckbox;
