import annaDsbUpi from '../../../infrastructure/constants/functions/annaDsbUpi';
import annaDsbUpiEnrichment from '../../../infrastructure/constants/functions/annaDsbUpiEnrichment';
import exchangeRates from '../../../infrastructure/constants/functions/exchangeRates';
import firdsFcaInstrument from '../../../infrastructure/constants/functions/firdsFcaInstrument';
import firdsEsmaInstrument from '../../../infrastructure/constants/functions/firdsEsmaInstrument';
import isin from '../../../infrastructure/constants/functions/isin';
import lei from '../../../infrastructure/constants/functions/lei';
import lse from '../../../infrastructure/constants/functions/lse';
import operandType from '../../../infrastructure/constants/functions/operandType';
import { endpointFilterType } from '../../../infrastructure/constants/report/endpointFilterType';
import ButtonContext from '../../../infrastructure/helpers/common/ButtonContext';
import aggregationHelper from '../../../infrastructure/helpers/functions/aggregation/aggregationHelper';
import reportFieldHelper from '../../../infrastructure/helpers/functions/common/reportFieldHelper';
import selectorHelper from '../../../infrastructure/helpers/functions/selectors/SelectorHelper';
import accuracyValidationValidator from '../../../infrastructure/helpers/functions/validator/accuracyValidationValidator';
import aggregationValidator from '../../../infrastructure/helpers/functions/validator/aggregationValidator';
import caseValidator from '../../../infrastructure/helpers/functions/validator/caseValidator';
import dictionaryValidator from '../../../infrastructure/helpers/functions/validator/dictionaryValidator';
import endpointFilterValidator from '../../../infrastructure/helpers/functions/validator/endpointFilterValidator';
import enrichmentValidator from '../../../infrastructure/helpers/functions/validator/enrichmentValidator';
import filterValidator from '../../../infrastructure/helpers/functions/validator/filterValidator';
import lookupValidator from '../../../infrastructure/helpers/functions/validator/lookupValidator';
import matchingKeyValidator from '../../../infrastructure/helpers/functions/validator/matchingKeyValidator';
import selectorValidator from '../../../infrastructure/helpers/functions/validator/selectorValidator';
import userDefinedFunctionValidator from '../../../infrastructure/helpers/functions/validator/userDefinedFunctionValidator';
import variableValidator from '../../../infrastructure/helpers/functions/validator/variableValidator';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import { htmlIds } from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation.HtmlIds';
import ReportCollections from '../../../infrastructure/types/Functions/ReportCollections';
import Aggregation from '../../../types/report/Aggregation';
import ReportConfig from '../../../types/report/ReportConfig';
import Selector from '../../../types/report/Selector';
import validateDataSources from '../DataSources/DataSourceForm.Validation';

const validate = (
    config: ReportConfig,
    collections: ReportCollections,
    onAggregationErrorClick: (aggregationNumber: number) => void,
    onIsinSelectorErrorClick: (isinSelectorNumber: number) => void,
    onLeiSelectorErrorClick: (isinSelectorNumber: number) => void,
    onExchangeRatesSelectorErrorClick: (exchangeRateselectorNumber: number) => void,
    onAnnaDsbUpiEnrichmentSelectorErrorClick: (annaDsbUpiEnrichmentSelectorNumber: number) => void,
    onAnnaDsbUpiSelectorErrorClick: (annaDsbUpiSelectorNumber: number) => void,
    onLSESelectorErrorClick: (lseSelectorNumber: number) => void,
    onFCARegulatedEntitiesSelectorErrorClick: (fcaRegulatedEntitiesSelectorNumber: number) => void,
    onFirdsFcaInstrumentSelectorErrorClick: (firdsInstrumentSelectorNumber: number) => void,
    onFirdsEsmaInstrumentSelectorErrorClick: (firdsEsmaInstrumentSelectorNumber: number) => void,
    onCaseFieldErrorClick: (caseNumber: number, fieldNumber: number) => void): ButtonContext[] => {

    let errors: ButtonContext[] = [];

    let dataSourceError = validateDataSources(config);
    if (dataSourceError) {
        errors.push({ content: dataSourceError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.dataSources) });
    }

    let aggregationNumbers = aggregationValidator.validate(config, collections.dataSources);
    if (aggregationNumbers.length > 0) {

        aggregationNumbers.forEach(number => {

            let a = config.aggregations.find(a => a.number === number) as Aggregation;

            let name = aggregationHelper.fallbackName(a);

            errors.push({ content: `Invalid aggregation '${name}'`, onClick: () => onAggregationErrorClick(number) });
        });
    }

    let lookupError = lookupValidator.validate(config.lookups, config.userDefinedFunctions, config.dictionaries, collections.dataSources, collections.reportFields, collections.customDataSourceFields);
    if (lookupError) {
        errors.push({ content: lookupError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.lookups) });
    }

    let dictionaryError = dictionaryValidator.validate(config.dictionaries, config.userDefinedFunctions, collections.dataSources);
    if (dictionaryError) {
        errors.push({ content: dictionaryError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.dictionaries) });
    }

    let isinSelectorsNumbers = selectorValidator.validate(config.isinSelectors, config.userDefinedFunctions, config.lookups, config.dictionaries, collections.dataSources, collections.reportFields, 'ISIN', 'name', isin.type);
    if (isinSelectorsNumbers.length > 0) {

        isinSelectorsNumbers.forEach(number => {

            let a = config.isinSelectors.find(a => a.number === number) as Selector;

            let name = selectorHelper.fallbackName(a, 'ISIN');

            errors.push({ content: `Invalid ISIN '${name}'`, onClick: () => onIsinSelectorErrorClick(number) });
        });
    }

    let leiSelectorsNumbers = selectorValidator.validate(config.leiSelectors, config.userDefinedFunctions, config.lookups, config.dictionaries, collections.dataSources, collections.reportFields, 'LEI', 'name', lei.type);
    if (leiSelectorsNumbers.length > 0) {

        leiSelectorsNumbers.forEach(number => {

            let a = config.leiSelectors.find(a => a.number === number) as Selector;

            let name = selectorHelper.fallbackName(a, 'LEI');

            errors.push({ content: `Invalid LEI '${name}'`, onClick: () => onLeiSelectorErrorClick(number) });
        });
    }

    let exchangeRatesSelectorsNumbers = selectorValidator.validate(config.exchangeRatesSelectors, config.userDefinedFunctions, config.lookups, config.dictionaries, collections.dataSources, collections.reportFields, 'ExchangeRates', 'name', exchangeRates.type);
    if (exchangeRatesSelectorsNumbers.length > 0) {

        exchangeRatesSelectorsNumbers.forEach(number => {

            let a = config.exchangeRatesSelectors.find(a => a.number === number) as Selector;

            let name = selectorHelper.fallbackName(a, 'ExchangeRates');

            errors.push({ content: `Invalid Exchange Rates '${name}'`, onClick: () => onExchangeRatesSelectorErrorClick(number) });
        });
    }

    let firdsFcaInstrumentSelectorsNumbers = selectorValidator.validate(config.firdsFcaInstrumentSelectors, config.userDefinedFunctions, config.lookups, config.dictionaries, collections.dataSources, collections.reportFields, 'FirdsInstrument', 'name', firdsFcaInstrument.type);

    let isThereFirdsInstrumentsError = false;

    if (firdsFcaInstrumentSelectorsNumbers.length > 0) {

        firdsFcaInstrumentSelectorsNumbers.forEach(number => {

            let a = config.firdsFcaInstrumentSelectors.find(a => a.number === number) as Selector;

            let name = selectorHelper.fallbackName(a, 'FirdsFcaInstrument');

            errors.push({ content: `Invalid Firds FcaInstrument '${name}'`, onClick: () => onFirdsFcaInstrumentSelectorErrorClick(number) });

            isThereFirdsInstrumentsError = true;
        });
    }

    if (!isThereFirdsInstrumentsError) {
        let selectors = config.firdsFcaInstrumentSelectors;

        selectors.forEach((item) => {
            if (item.keys?.[1].assignment.value.operandType === undefined ||
                (item.keys?.[1].assignment.value.operandType === operandType.constant &&
                    !item.keys?.[1].assignment.value.value)) {
                errors.push({ content: `Invalid Firds Fca Instrument '${item.name}'. Venue must have value.`, onClick: () => onFirdsFcaInstrumentSelectorErrorClick(item.number) });
            }

            if (item.keys?.[0].assignment.value.operandType === undefined ||
                (item.keys?.[0].assignment.value.operandType === operandType.constant &&
                    !item.keys?.[0].assignment.value.value)) {
                errors.push({ content: `Invalid Firds Fca Instrument '${item.name}'. InstrumentName must be empty string or have some value.`, onClick: () => onFirdsFcaInstrumentSelectorErrorClick(item.number) });
            }
        });
    }

    let firdsEsmaInstrumentSelectorsNumbers = selectorValidator.validate(config.firdsEsmaInstrumentSelectors, config.userDefinedFunctions, config.lookups, config.dictionaries, collections.dataSources, collections.reportFields, 'FirdsInstrument', 'name', firdsEsmaInstrument.type);

    isThereFirdsInstrumentsError = false;

    if (firdsEsmaInstrumentSelectorsNumbers.length > 0) {

        firdsEsmaInstrumentSelectorsNumbers.forEach(number => {

            let a = config.firdsEsmaInstrumentSelectors.find(a => a.number === number) as Selector;

            let name = selectorHelper.fallbackName(a, 'FirdsEsmaInstrument');

            errors.push({ content: `Invalid Firds Esma Instrument '${name}'`, onClick: () => onFirdsEsmaInstrumentSelectorErrorClick(number) });

            isThereFirdsInstrumentsError = true;
        });
    }

    if (!isThereFirdsInstrumentsError) {
        let selectors = config.firdsEsmaInstrumentSelectors;

        selectors.forEach((item) => {
            if (item.keys?.[1].assignment.value.operandType === undefined ||
                (item.keys?.[1].assignment.value.operandType === operandType.constant &&
                    !item.keys?.[1].assignment.value.value)) {
                errors.push({ content: `Invalid Firds Esma Instrument '${item.name}'. Venue must have value.`, onClick: () => onFirdsEsmaInstrumentSelectorErrorClick(item.number) });
            }

            if (item.keys?.[0].assignment.value.operandType === undefined ||
                (item.keys?.[0].assignment.value.operandType === operandType.constant &&
                    !item.keys?.[0].assignment.value.value)) {
                errors.push({ content: `Invalid Firds Esma Instrument '${item.name}'. InstrumentName must be empty string or have some value.`, onClick: () => onFirdsEsmaInstrumentSelectorErrorClick(item.number) });
            }
        });
    }

    let annaDsbUpiEnrichmentSelectorsNumbers = selectorValidator.validate(config.annaDsbUpiEnrichmentSelectors, config.userDefinedFunctions, config.lookups, config.dictionaries, collections.dataSources, collections.reportFields, 'AnnaDsbUpiEnrichment', 'name', annaDsbUpiEnrichment.type);

    let isThereAnnaDsbUpiEnrichmentError = false;

    if (annaDsbUpiEnrichmentSelectorsNumbers.length > 0) {

        annaDsbUpiEnrichmentSelectorsNumbers.forEach(number => {

            let a = config.annaDsbUpiEnrichmentSelectors.find(a => a.number === number) as Selector;

            let name = selectorHelper.fallbackName(a, 'AnnaDsbUpiEnrichment');

            errors.push({ content: `Invalid Anna Dsb Upi Enrichment '${name}'`, onClick: () => onAnnaDsbUpiEnrichmentSelectorErrorClick(number) });

            isThereAnnaDsbUpiEnrichmentError = true;
        });
    }

    if (!isThereAnnaDsbUpiEnrichmentError) {

        let annaDsbUpiEnrichmentSelectors = config.annaDsbUpiEnrichmentSelectors;

        annaDsbUpiEnrichmentSelectors.forEach((item) => {
            if (item.keys?.[0].assignment.value.operandType === undefined ||
                (item.keys?.[0].assignment.value.operandType === operandType.constant &&
                    !item.keys?.[0].assignment.value.value)) {
                errors.push({ content: `Invalid Anna Dsb Upi Enrichment '${item.name}'. CFI must have some value.`, onClick: () => onAnnaDsbUpiEnrichmentSelectorErrorClick(item.number) });
            }

            if (item.keys?.[1].assignment.value.operandType === undefined) {
                errors.push({ content: `Invalid Anna Dsb Upi Enrichment '${item.name}'. ISIN must have some value or to be a empty string.`, onClick: () => onAnnaDsbUpiEnrichmentSelectorErrorClick(item.number) });
            }
        });
    }

    let annaDsbUpiSelectorsNumbers = selectorValidator.validate(config.annaDsbUpiSelectors, config.userDefinedFunctions, config.lookups, config.dictionaries, collections.dataSources, collections.reportFields, 'AnnaDsbUpi', 'name', annaDsbUpi.type);
    if (annaDsbUpiSelectorsNumbers.length > 0) {

        annaDsbUpiSelectorsNumbers.forEach(number => {

            let a = config.annaDsbUpiSelectors.find(a => a.number === number) as Selector;

            let name = selectorHelper.fallbackName(a, 'AnnaDsbUpi');

            errors.push({ content: `Invalid Anna Dsb Upi '${name}'`, onClick: () => onAnnaDsbUpiSelectorErrorClick(number) });
        });
    }

    let lseSelectorsNumbers = selectorValidator.validate(config.lseSelectors, config.userDefinedFunctions, config.lookups, config.dictionaries, collections.dataSources, collections.reportFields, 'LSE', 'name', lse.type);
    if (lseSelectorsNumbers.length > 0) {

        lseSelectorsNumbers.forEach(number => {

            let a = config.lseSelectors.find(a => a.number === number) as Selector;

            let name = selectorHelper.fallbackName(a, 'LSE');

            errors.push({ content: `Invalid LSE '${name}'`, onClick: () => onLSESelectorErrorClick(number) });
        });
    }

    let fcaRegulatedEntitiesSelectorsNumbers = selectorValidator.validate(config.fcaRegulatedEntitiesSelectors, config.userDefinedFunctions, config.lookups, config.dictionaries, collections.dataSources, collections.reportFields, 'FCARegulatedEntities', 'name', lse.type);
    if (fcaRegulatedEntitiesSelectorsNumbers.length > 0) {

        fcaRegulatedEntitiesSelectorsNumbers.forEach(number => {

            let a = config.fcaRegulatedEntitiesSelectors.find(a => a.number === number) as Selector;

            let name = selectorHelper.fallbackName(a, 'FCARegulatedEntities');

            errors.push({ content: `Invalid FCA Regulated Entities '${name}'`, onClick: () => onFCARegulatedEntitiesSelectorErrorClick(number) });
        });
    }

    let variableError = variableValidator.validate(config.variables, config.userDefinedFunctions, config.lookups, config.dictionaries, collections.dataSources, collections.reportFields, collections.customDataSourceFields);
    if (variableError) {
        errors.push({ content: variableError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.variables) });
    }

    let filterError = filterValidator.validate(config.userDefinedFunctions, config.lookups, config.dictionaries, config.variables, config.filters, collections.dataSources, collections.customDataSourceFields, collections.reportFields, config.type);
    if (filterError) {
        errors.push({ content: filterError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.filters) });
    }

    let endpointFilterError = endpointFilterValidator.validateByType(config.endpointFilters, config.userDefinedFunctions, config.lookups, config.dictionaries, collections, endpointFilterType.regular);
    if (endpointFilterError) {
        errors.push({ content: endpointFilterError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.endpointFilters) });
    }

    let underReportFilterError = endpointFilterValidator.validateByType(config.endpointFilters, config.userDefinedFunctions, config.lookups, config.dictionaries, collections, endpointFilterType.underReport, config.variables);
    if (underReportFilterError) {
        errors.push({ content: underReportFilterError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.expectedUnderReport) });
    }

    let overReportFilterError = endpointFilterValidator.validateByType(config.endpointFilters, config.userDefinedFunctions, config.lookups, config.dictionaries, collections, endpointFilterType.overReport);
    if (overReportFilterError) {
        errors.push({ content: overReportFilterError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.expectedOverReport) });
    }

    let caseError = caseValidator.validate(config, collections.dataSources, collections.customDataSourceFields);
    if (caseError) {
        errors.push({ content: caseError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.cases) });
    }

    let enrichmentErrors = enrichmentValidator.validate(config.userDefinedFunctions, config.lookups, config.dictionaries, config.variables, config.cases, collections.dataSources, collections.reportFields, collections.customDataSourceFields);

    enrichmentErrors.forEach(e => {

        let definition = reportFieldHelper.getById(collections.reportFields, e.fieldId);

        errors.push({

            content: `Case ${e.caseNumber}, Field ${definition.name}`,

            onClick: () => onCaseFieldErrorClick(e.caseNumber, definition.number)
        });
    });

    let validationError = accuracyValidationValidator.validate(config.validations, config.userDefinedFunctions, config.lookups, config.dictionaries, config.variables, collections.dataSources, collections.reportFields, collections.customDataSourceFields);
    if (validationError) {
        errors.push({ content: validationError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.validations) });
    }

    let matchingKeyError = matchingKeyValidator.validate(config.matchingKey);
    if (matchingKeyError) {
        errors.push({ content: matchingKeyError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.matchingKeys) });
    }

    let matchingKeyEndpointError = matchingKeyValidator.validate(config.matchingKeyEndPoint);
    if (matchingKeyEndpointError) {
        errors.push({ content: matchingKeyEndpointError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.matchingKeys) });
    }

    let userDefinedFunctionError = userDefinedFunctionValidator.validate(config.userDefinedFunctions);
    if (userDefinedFunctionError) {
        errors.push({ content: userDefinedFunctionError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.userDefinedFunctions) });
    }

    // Commented with ticket CNTRLBX-363 because the logic behind validation is not correct
    // and it is not clear what the purpose of it is

    //let referenceOrderError = referenceOrderValidator.validate(config);
    //if (referenceOrderError) {
    //    errors.push({ content: referenceOrderError, onClick: () => reportConfigNavigation.navigateToSection(htmlIds.lookups) });
    //}

    return errors;
};

export default validate;