import './ActionItemForm.scss';
import React from 'react';
import PromiseButton from '../PromiseButton';
import SelectList from '../SelectList/SelectList';
import MultiSelectList from '../MultiSelectList/MultiSelectList';
import { mapToBasicOption } from '../../../infrastructure/helpers/html/selectOptionHelper';
import { ActionLogInitFormDto, MenuBaseModel } from '../../../types/common/ActionItem/ActionItemForm';
import { ActionItemSaveModel } from '../../../types/common/ActionItem/ActionItemSaveRequest';
import ActionitemDto from '../../../types/common/ActionItem/ActionItemDto';

interface ActionItemFormProps {
    onSave: (actionLog: ActionItemSaveModel, onError: () => void) => Promise<any>;
    data: ActionLogInitFormDto;
    editedItem?: ActionitemDto;
    module: string;
    regime: string;
}

class ActionItemForm extends React.Component<ActionItemFormProps, { formDisabled: boolean, data: ActionItemSaveModel }> {
    private actionLog: ActionItemSaveModel;

    constructor(props: ActionItemFormProps) {
        super(props)

        this.state = {
            formDisabled: false,
            data: {
                id: props.editedItem?.id || 0,
                externalId: props.editedItem?.externalId,
                name: props.editedItem?.name || '',
                description: props.editedItem?.description || '',
                productType: props.editedItem?.productType || 'Cb',
                priorityType: props.editedItem?.priorityType || 'Medium',
                status: props.editedItem?.status || 'Open',
                color: props.editedItem?.color || '',
                regimes: props.editedItem?.regimes || [props.regime],
                module:
                {
                    filter: props.editedItem?.module === 'Filter' || props.data.moduleIds?.includes('Filter') || props.module === 'Filter',
                    case: props.editedItem?.module === 'Case' || props.data.moduleIds?.includes('Case') || props.module === 'Case',
                    enrichment: props.editedItem?.module === 'Enrichment' || props.data.moduleIds?.includes('Enrichment') || props.module === 'Enrichment',
                    accuracyValidation: props.editedItem?.module === 'AccuracyValidation' || props.data.moduleIds?.includes('AccuracyValidation') || props.module === 'AccuracyValidation'
                },
                component: props.editedItem?.component || '',
                jiraIssueKey: props.editedItem?.jiraIssueKey || '',
                jiraIssueSummary: props.editedItem?.jiraIssueSummary || '',
                jiraIssueStatus: props.editedItem?.jiraIssueStatus || '',
                jiraIssueUrl: props.editedItem?.jiraIssueUrl || '',
                assigneeId: props.data.assigneeId,
                linkedIds: props.editedItem?.linkedIds || []
            }
        };

        this.actionLog = { ...this.state.data };

        this.isFormValid = this.isFormValid.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    onChange(value: any, property: string): void {
        (this.actionLog as any)[property] = value;
        this.setState({ data: this.actionLog });
    }

    buildActionItemButton(item: MenuBaseModel<any>): JSX.Element {
        return (<label>{item.name}</label>);
    }

    isFormValid(): boolean {
        return !!this.state.data.name;
    }

    onSave() {
        this.setState({ formDisabled: true });
        return this.props.onSave(this.state.data, () => { this.setState({ formDisabled: false }) })
    }

    render(): JSX.Element {
        return (
            <form className="container action-item-container">
                <div className="row">
                    <div className="col-md-6">
                        <label>Linked action items</label>
                        <MultiSelectList
                            items={this.props.data.actionLogsLinks}
                            getItemValue={x => x.id.toString()}
                            getItemText={x => x.name}
                            isItemDisabled={x => !x}
                            values={this.actionLog.linkedIds?.map(d => d.toString())}
                            className="form-control"
                            isSelectListDisabled={this.state.formDisabled}
                            isRemoveDisabled={() => this.state.formDisabled}
                            onSelectChange={(v) => this.onChange([...this.actionLog.linkedIds, +v], 'linkedIds')}
                            onRemoveClick={(v) => this.onChange(this.actionLog.linkedIds.filter(i => i.toString() !== v), 'linkedIds')}
                            buildItem={this.buildActionItemButton}
                        />
                        <small className="form-text text-muted">Link to existing action items</small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <label>Product*</label>
                        <SelectList
                            options={this.props.data.productTypes?.map(p => mapToBasicOption(p.id))}
                            className="form-control"
                            disabled={true}
                            value={this.actionLog.productType}
                            onChange={(v) => this.onChange(v, 'productType')} />
                    </div>
                    <div className="col-md-3">
                        <label>Regime</label>
                        <MultiSelectList
                            items={this.props.data.regimeTypes}
                            getItemValue={x => x.id}
                            getItemText={x => x.name}
                            isItemDisabled={x => !x}
                            values={this.props.editedItem && Object.keys(this.props.editedItem).length && this.props.editedItem.regimes ? this.props.editedItem.regimes : this.actionLog.regimes}
                            className="form-control"
                            isSelectListDisabled={true}
                            isRemoveDisabled={() => true}
                            onSelectChange={(v) => this.onChange([...this.actionLog.regimes, v], 'regimes')}
                            onRemoveClick={(v) => this.onChange(this.actionLog.linkedIds.filter(i => i.toString() !== v), 'regimes')}
                            buildItem={this.buildActionItemButton}
                            showDefaultValues={true}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>Module</label>
                        <MultiSelectList
                            items={this.props.data.moduleTypes?.filter(m => m.type === this.actionLog.productType)}
                            getItemValue={x => x.id}
                            getItemText={x => x.name}
                            isItemDisabled={x => !x}
                            values={this.props.editedItem && Object.keys(this.props.editedItem).length && this.props.editedItem.modules ? this.props.editedItem.modules : [this.props.module]}
                            className="form-control"
                            isSelectListDisabled={true}
                            isRemoveDisabled={() => true}
                            onSelectChange={() => { throw new Error('"Module" multiselect not implemented!') }}
                            onRemoveClick={() => { throw new Error('"Module" multiselect not implemented!') }}
                            buildItem={this.buildActionItemButton}
                            showDefaultValues={true}
                        />
                    </div>
                    <div className="col-md-4">
                        <label>Component</label>
                        <MultiSelectList
                            items={
                                this.props.data.componentTypes?.filter(c => (c.regimes.indexOf(this.actionLog.regimes[0]) > -1) && c.module === 'Cb')
                                    .sort((a, b) => a.sortOrder - b.sortOrder || a.module.localeCompare(b.module))
                                    .map(c => { return { id: c.id, name: c.id } })
                            }
                            getItemValue={x => x.id}
                            getItemText={x => x.name}
                            isItemDisabled={x => !x}
                            values={this.props.editedItem && Object.keys(this.props.editedItem).length && this.props.editedItem.component ? [this.props.editedItem.component] : [this.actionLog.component]}
                            className="form-control"
                            isSelectListDisabled={true}
                            isRemoveDisabled={() => true}
                            onSelectChange={() => { throw new Error('"Component" multiselect not implemented!') }}
                            onRemoveClick={() => { throw new Error('"Component" multiselect not implemented!') }}
                            buildItem={this.buildActionItemButton}
                            showDefaultValues={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Name*</label>
                        <input className="form-control"
                            type="text"
                            maxLength={100}
                            value={this.actionLog.name}
                            disabled={this.state.formDisabled}
                            onChange={(e) => this.onChange(e.target.value, 'name')} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Description</label>
                        <textarea className="form-control"
                            value={this.actionLog.description}
                            disabled={this.state.formDisabled}
                            onChange={(e) => this.onChange(e.target.value, 'description')} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Jira Issue Key</label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.actionLog.jiraIssueKey}
                            disabled={this.state.formDisabled}
                            onChange={(e) => this.onChange(e.target.value, 'jiraIssueKey')} />
                        <small className="form-text text-muted">The value must match the Jira issue key, e.g. PROJECT-1234</small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label>Assignee*</label>
                        <SelectList
                            options={this.props.data?.assignees.map(d => { return { value: d.id, text: d.userName } })}
                            className="form-control"
                            value={this.actionLog.assigneeId}
                            disabled={true}
                            onChange={(v) => this.onChange(v, 'assigneeId')} />
                    </div>
                    <div className="col-md-3">
                        <label>Status*</label>
                        <SelectList
                            options={this.props.data?.statusTypes.map(d => { return { value: d.id, text: d.name } })}
                            className="form-control"
                            value={this.actionLog.status}
                            disabled={true}
                            onChange={(v) => this.onChange(v, 'status')} />
                    </div>
                    <div className="col-md-3">
                        <label>Priority*</label>
                        <SelectList
                            options={this.props.data?.priorityTypes.map(d => { return { value: d.id, text: d.name } })}
                            className="form-control"
                            value={this.actionLog.priorityType}
                            disabled={true}
                            onChange={(v) => this.onChange(v, 'priorityType')} />
                    </div>
                    <div className="col-md-3">
                        <label>RAG</label>
                        <SelectList
                            options={[...[{ id: '', name: '' }], ...this.props.data?.colorTypes].map(d => { return { value: d.id, text: d.name } })}
                            className="form-control"
                            value={this.actionLog.color}
                            disabled={this.state.formDisabled}
                            onChange={(v) => this.onChange(v, 'color')} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <PromiseButton text="Save" className="btn cb-btn" disabled={!this.isFormValid()} enableOnErrorOnly={false} task={this.onSave} />
                    </div>
                </div>
            </form>
        );
    }
}

export default ActionItemForm;
