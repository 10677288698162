import React from "react";
import Modal from "../../Modal/Modal";

interface Props {
    title?: string
    message: string;
    onOkClick: () => void;
    onCancelClick?: () => void;
}
class WarningModal extends React.Component<any, { title: string, isOpen: boolean, error: string | null }> {

    constructor(props: Props) {
        super(props);

        this.state = {
            title: this.props.title,
            isOpen: true,
            error: null,
        };

        this.close = this.close.bind(this);
    }

    close(): void {
        this.setState({ isOpen: false });
        this.props.onCancelClick && this.props.onCancelClick(); 
    }

    render(): JSX.Element {
        return (
            <>

                <Modal state={this.state} close={this.close}>
                    <p className='white-space-pre-line'>{this.props.message}</p>
                    <div className='display-flex align-center justify-content-start margin-top-20px'>
                        <button className='btn cb-btn width-75px' onClick={() => { this.props.onOkClick(); this.close(); }}>Ok</button>
                        <button className='btn btn-light' onClick={this.close}>Cancel</button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default WarningModal;