import Condition from '../../../../types/functions/Condition/Condition';

const isSelfWrapped = (item: Condition): boolean => {

    return (
        item.openParentheses > 0 &&
        item.openParentheses === item.closeParentheses
    );
};

const validate = (items: Condition[]): string | null => {

    let open = 0;
    let close = 0;

    for (let i = 0; i < items.length; i++) {

        if (isSelfWrapped(items[i])) {

            return 'Condition item is self-wrapped in brackets';
        }

        open += items[i].openParentheses;
        close += items[i].closeParentheses;


        if (open < close) {

            return 'Unexpected close brackets';
        }
    }

    if (open > close) {

        return `${open - close} close bracket(s) are missing`;
    }

    return null;
}

const parenthesesValidator = {
    validate
};

export default parenthesesValidator;
