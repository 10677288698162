import InfoIcon from '../InfoIcon/InfoIcon';
import React from 'react';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

interface FormGroupProps {
    label: string;
    labelClassName?: string;
    contentClassName?: string;
    htmlFor?: string;
    info?: string;
    style?: React.CSSProperties;
    isVertical?: boolean;
    onMouseLeave?: () => void;
}

class FormGroup extends React.Component<FormGroupProps> {
    render(): JSX.Element {
        return (
            <div className={`${this.props.isVertical ? '' : 'form-group-horizontal row mb-2'}`} style={this.props.style} onMouseLeave={this.props.onMouseLeave }>
                <label className={`${this.props.labelClassName} col-form-label`} htmlFor={this.props.htmlFor}>
                    {
                        typeHelper.isString(this.props.info) &&
                        <InfoIcon info={this.props.info as string} />
                    }

                    {this.props.label}
                </label>
                <div className={this.props.contentClassName}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default FormGroup;
