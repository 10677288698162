import { ToastOptions, toast } from 'react-toastify';
import actions from '../store/actions';
import store from '../store/store';
import identityStorage from '../infrastructure/authorization/identityStorage';
import SessionExpireState from './Common/SessionExpire/SessionExpireState';

const setupKeyDown = (): (() => void) => {

    const event = 'keydown';
    const handler = (e: KeyboardEvent): void => {

        if (e.key === 'Escape') {

            store.dispatch({ type: actions.common.closeModal, payload: null as any });
        }
    };

    window.addEventListener(event, handler);

    return () => {
        window.removeEventListener(event, handler);
    };
};

const sessionExpireCheck = () => {

    const oneMinuteInMileseconds = 60000;
    const minutesRequired = 5;
    const minutesRequiredForWarningDisplay = minutesRequired * oneMinuteInMileseconds;

    const onCheckIfExpireInFiveMinutes = () => {

        let identity = identityStorage.get();

        const minutesUntilSessionEpire = new Date(identity.expirationTimestampIso).getTime() - new Date(new Date().toISOString()).getTime();

        if (minutesUntilSessionEpire <= minutesRequiredForWarningDisplay) {

            const timeLeft = Math.round(minutesUntilSessionEpire / oneMinuteInMileseconds);

            let message = `${timeLeft < 0 ? `Your session has expired.` : `Please save your changes. Your session will expire in ${timeLeft} minutes.`}`;

            showMesage(message);

            store.dispatch({ type: actions.sessionExpire.warningShowned, payload: { warningShowned: true } as SessionExpireState });

            const intervalId = store.getState().sessionExpireState.intervalId;

            window.clearInterval(intervalId);
        }
    }

    const showMesage = (message: string): void => {

        const toastOptions: ToastOptions = {
            position: 'top-right',
            autoClose: false,
            closeOnClick: true,
            bodyStyle: { margin: '5px', whiteSpace: 'pre-line', fontSize: '14px' },
            delay: 0
        };

        toast.error(message, toastOptions);
    }


    const state = store.getState();

    if (!state.sessionExpireState.intervalId && !state.sessionExpireState.warningShowned) {

        const intervalId = setInterval(onCheckIfExpireInFiveMinutes, oneMinuteInMileseconds);

        store.dispatch({ type: actions.sessionExpire.intervalId, payload: { intervalId } });
    }
}

export { setupKeyDown, sessionExpireCheck };