import CopyVariableModalState from './CopyVariableModalState';
import ReportVariable from '../../../../types/report/ReportVariable';
import { SelectSearchOption } from 'react-select-search';

const getDefaultState = (): CopyVariableModalState => {
    return {
        title: '',
        isOpen: false,
        error: null,
        sourceVariableNumber: '',
        targetVariableNumber: '',
        showWarningModal: false,
        message: '',
        toVariables: []
    };
};

const createFieldOption = (field: ReportVariable): SelectSearchOption => {
    return {
        name: field.name,
        value: (field.number || 0).toString()
    };
};

const createEmptyFieldOption = (): SelectSearchOption => {
    return {
        name: '',
        value: ''
    };
};

export { getDefaultState, createFieldOption, createEmptyFieldOption };
