import { AxiosResponse } from 'axios';
import ActionItem from '../../../types/common/ActionItem/ActionItem';
import ActionItemEditor from '../../ActionItem/ActionItemEditor';
import actionItemHelper from '../../../infrastructure/helpers/actionItem/actionItemHelper';
import actionItemValidator from '../../../infrastructure/helpers/actionItem/actionItemValidator';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import ObjectIndexer from '../../../infrastructure/types/ObjectIndexer';
import PromiseButton from '../../Common/PromiseButton';
import React from 'react';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';

interface State {
    isWaiting: boolean;
    actionItem: ActionItem;
    users: ObjectIndexer<string>;
    error: string | null;
}

class ReportActionItemEditPage extends React.Component<any, State> {
    private id: number;
    private redirectRoute: string;
    private validQueryParams: boolean;

    constructor(props: any) {
        super(props);

        let query = urlHelper.deserializeQuery(history.location.search);

        this.id = parseInt(query['id']);
        this.redirectRoute = query['redirect'];

        this.validQueryParams =
            typeHelper.isNumber(this.id) &&
            typeHelper.isString(this.redirectRoute);

        if (!this.validQueryParams) {
            history.push(appRoutes.reporting.home);
        }

        this.state = {
            isWaiting: true,
            actionItem: actionItemHelper.getEmpty(),
            users: {},
            error: null
        };

        this.onGetDataSuccess = this.onGetDataSuccess.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    componentDidMount(): void {

        if (!this.validQueryParams) {
            return;
        }

        let getByIdUrl = urlHelper.buildRoute(['action-item', this.id]);

        let getReletedUsersUrl = urlHelper.buildRoute(['action-item', this.id, 'related-users']);

        Promise.all([httpClient.get<ActionItem>(getByIdUrl), httpClient.get<ObjectIndexer<string>>(getReletedUsersUrl)]).then(this.onGetDataSuccess);
    }

    onGetDataSuccess(responses: [AxiosResponse<ActionItem>, AxiosResponse<ObjectIndexer<string>>]): void {

        this.setState({ isWaiting: false, actionItem: responses[0].data, users: responses[1].data });
    }

    onChange(changes: ActionItem): void {

        this.setState({ actionItem: Object.assign({}, this.state.actionItem, changes) });
    }

    onSaveClick(): Promise<any> {

        let error = actionItemValidator.validateName(this.state.actionItem.name);

        this.setState({ error: error });

        if (error) {
            return Promise.reject(error);
        }

        let url = urlHelper.buildRoute(['action-item/cn', this.id]);

        return httpClient.put(url, this.state.actionItem).then(this.redirect);
    }

    redirect(): void {

        history.push(decodeURIComponent(this.redirectRoute));
    }

    render(): JSX.Element {
        return (
            <>
                <div className="row mb-3">
                    <div className="col-12 col-sm-11 col-md-8 col-lg-6">
                        <ActionItemEditor
                            isWaiting={this.state.isWaiting}
                            actionItem={this.state.actionItem}
                            users={this.state.users}
                            onChange={this.onChange} />
                    </div>
                </div>

                {
                    !this.state.isWaiting &&
                    <div className="mb-3">

                        <PromiseButton text="Save" className="btn cb-btn" enableOnErrorOnly={true} task={this.onSaveClick} />

                        <button onClick={this.redirect} className="btn btn-light">Cancel</button>
                    </div>
                }

                <ErrorMessage error={this.state.error} />
            </>
        );
    }
}

export default ReportActionItemEditPage;
