import { booleanFormatOptions, timeZoneOptions, dateTimeFormatSpecialValueTypes } from './SpecialValueBuilder.Helpers';
import { mapToBasicOption } from '../../../../../infrastructure/helpers/html/selectOptionHelper';
import BaseOperandProps from '../../Props/BaseOperandProps';
import dataType from '../../../../../infrastructure/constants/dataType';
import Operand from '../../../../../types/functions/Operand/Operand';
import operandSpecialValueType from '../../../../../infrastructure/constants/functions/operandSpecialValueType';
import publicHolidayCountries from '../../../../../infrastructure/constants/publicHolidayCountries';
import publicHolidayCurrencies from '../../../../../infrastructure/constants/publicHolidayCurrencies';
import React from 'react';
import ReportCollections from '../../../../../infrastructure/types/Functions/ReportCollections';
import SelectList from '../../../../Common/SelectList/SelectList';
import DateTimeFormatPickerComplete from '../../../../Common/DateTimeFormatPicker/DateTimeFormatPickerComplete';
import DateTimeFormatPicker from '../../../../Common/DateTimeFormatPicker/DateTimeFormatPicker';
import ReportLogicLocation from '../../../../../types/functions/Location/ReportLogicLocation';
import sortDirections from '../../../../../infrastructure/constants/sortDirections';
import daysOfWeek from '../../../../../infrastructure/constants/daysOfWeek';
import numberOfWeeksInMonth from '../../../../../infrastructure/constants/numberOfWeeksInMonth';

type Props =
    BaseOperandProps &
    {
        collections: ReportCollections,
        location?: ReportLogicLocation;
    };

class SpecialValueBuilder extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.getDataType = this.getDataType.bind(this);
    }

    async onChange(value: string): Promise<void> {

        let changes = { value: value } as Operand;

        await this.props.onOperandChange(changes);
    }

    getDataType(): string {

        switch (this.props.operandRules.specialValueType) {

            case operandSpecialValueType.formatStringDateTime:
                return dataType.dateTime;

            case operandSpecialValueType.formatStringDate:
                return dataType.date;

            case operandSpecialValueType.formatStringTime:
                return dataType.time;

            case operandSpecialValueType.checkDateTimeFormat:
                return dataType.dateTime;
        }

        throw new Error(`${this.props.operandRules.specialValueType} is invalid date/time special value type`);
    }

    render(): JSX.Element {

        if (this.props.operandRules.specialValueType === operandSpecialValueType.timeZone) {
            return (
                <SelectList
                    className="form-control"
                    options={timeZoneOptions}
                    value={this.props.operand.value}
                    onChange={this.onChange} />
            );
        }

        if (this.props.operandRules.specialValueType === operandSpecialValueType.formatStringBoolean) {
            return (
                <SelectList
                    className="form-control"
                    options={booleanFormatOptions}
                    value={this.props.operand.value}
                    onChange={this.onChange} />
            );
        }

        if (this.props.operandRules.specialValueType === operandSpecialValueType.cnProductField) {
            return (
                <SelectList
                    className="form-control"
                    options={this.props.collections.customDataSourceFields.cnProduct.map(mapToBasicOption)}
                    value={this.props.operand.value}
                    onChange={this.onChange} />
            );
        }

        if (this.props.operandRules.specialValueType === operandSpecialValueType.publicHolidayCountry) {
            return (
                <SelectList
                    className="form-control"
                    options={publicHolidayCountries.map(mapToBasicOption)}
                    value={this.props.operand.value}
                    onChange={this.onChange} />
            );
        }

        if (this.props.operandRules.specialValueType === operandSpecialValueType.daysOfWeek) {
            return (
                <SelectList
                    className="form-control"
                    options={daysOfWeek.map(mapToBasicOption)}
                    value={this.props.operand.value}
                    onChange={this.onChange} />
            );
        }

        if (this.props.operandRules.specialValueType === operandSpecialValueType.numberOfWeeksInMonth) {
            return (
                <SelectList
                    className="form-control"
                    options={numberOfWeeksInMonth.map(mapToBasicOption)}
                    value={this.props.operand.value}
                    onChange={this.onChange} />
            );
        }

        if (this.props.operandRules.specialValueType === operandSpecialValueType.publicHolidayCurrency) {
            return (
                <SelectList
                    className="form-control"
                    options={publicHolidayCurrencies.map(mapToBasicOption)}
                    value={this.props.operand.value}
                    onChange={this.onChange} />
            );
        }

        if (this.props.operandRules.specialValueType === operandSpecialValueType.sortDirection) {
            return (
                <SelectList
                    className="form-control"
                    options={sortDirections.map(mapToBasicOption)}
                    value={this.props.operand.value}
                    onChange={this.onChange} />
            );
        }

        if (dateTimeFormatSpecialValueTypes.includes(this.props.operandRules.specialValueType as string)) {
            return (
                <DateTimeFormatPicker
                    dataType={this.getDataType()}
                    format={this.props.operand.value}
                    onFormatChange={this.onChange}
                    isInlineMode={false} />
            );
        }
        if (this.props.operandRules.specialValueType === 'CheckDateTimeFormat') {

            return (
                <DateTimeFormatPickerComplete
                    format={this.props.operand.value}
                    onFormatChange={this.onChange}/>
            );
        }

        return (<></>);
    }
}

export default SpecialValueBuilder;
