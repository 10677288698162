import { reportTypeDisplayMap } from '../../../infrastructure/constants/reportType';
import reportRegime from '../../../infrastructure/constants/reportRegime';

const guidance = {
    name: 'Free text to identify the reporting configuration uniquely.',
    regime: Object.keys(reportRegime).join(' or '),
    reportType: Object.values(reportTypeDisplayMap).map(x => `'${x}'`).join(' or '),
    rawDataSource: 'Select only one file which will be used as the Raw file. User can select only those files where File Data Type is either Raw or Both.',
    refDataSources: 'Select one (or more) files to be used as Reference files by the tool. User can select only those files where File Data Type is either Ref or Both.',
    globalRefDataSources: 'Select one (or more) files to be used as Global Reference files by the tool'
};

export default guidance;
