import RadioButtonGroup from '../../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import Dictionary from '../../../../types/report/Dictionary';
import { createDictionaryOption } from './DictionarySelector.Helpers';

interface Props {
    isWaiting: boolean;
    dictionaryNumber: number | null;
    dictionaries: Dictionary[];
    onDictionarySelected: (number: number) => void;
}

class DictionarySelector extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onDictionarySelected = this.onDictionarySelected.bind(this);
    }

    onDictionarySelected(value: string): void {

        this.props.onDictionarySelected(parseInt(value));
    }

    render(): JSX.Element {

        if (this.props.dictionaries.length === 0) {

            return (<></>);
        }

        return (
            <>
                <RadioButtonGroup
                    options={this.props.dictionaries.map(createDictionaryOption)}
                    value={(this.props.dictionaryNumber as number).toString()}
                    name="report-dictionaries"
                    onChange={this.onDictionarySelected} />
            </>
        );
    }
}

export default DictionarySelector;
