const integer = '[-]?[\\d]{1,9}';

const decimal = '[-]?[\\d]{1,28}([.][\\d]{1,27})?';

const unixTime = '[\\d]{1,18}';

const isoDate = '([1-9][0-9]{3})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])';

const time = '([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])';

const isoDateTime = isoDate + 'T' + time + 'Z';

const setupPattern = (pattern: string): string => '^' + pattern + '$';

const regexPattern = {
    integer: new RegExp(setupPattern(integer)),

    decimal: new RegExp(setupPattern(decimal)),

    unixTime: new RegExp(setupPattern(unixTime)),

    isoDate: new RegExp(setupPattern(isoDate)),

    time: new RegExp(setupPattern(time)),

    isoDateTime: new RegExp(setupPattern(isoDateTime))
};

export default regexPattern;
