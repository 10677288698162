import { User, UserManager, UserManagerSettings } from 'oidc-client';
import AuthorizationResult from '../../types/authorization/AuthorizationResult';
import identityStorage from './identityStorage';
import resolveConfig from '../configuration/resolveConfig';
import typeHelper from '../helpers/common/typeHelper';

const config = resolveConfig();

const settings: UserManagerSettings = {
    client_id: 'control-box-ui',
    authority: config.identity.authority,
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    loadUserInfo: true,
    redirect_uri: window.location.origin + '/login',
    post_logout_redirect_uri: window.location.origin,
    response_type: 'id_token token',
    scope: 'openid profile control-box',
    prompt: 'login'
};

const manager = new UserManager(settings);

const onLoginSuccess = (user: User): void => {
    identityStorage.set(user);
};

const loginRedirect = (): void => {

    manager.clearStaleState().then(() => manager.signinRedirect());
};

const loginComplete = (): Promise<void> => {

    return new Promise<void>(resolve => {

        manager.signinRedirectCallback().then(onLoginSuccess).then(resolve);
    });
};

const logoutRedirect = (): void => {
    identityStorage.clear();
    manager.signoutRedirect();
};

const authorize = (): AuthorizationResult => {
    let identity = identityStorage.get();

    if (!typeHelper.isString(identity.accessToken)) {

        return unauthorized();
    }

    if (identity.expirationTimestampIso > new Date().toISOString()) {

        return { authorized: true, role: identity.userRole };
    }

    identityStorage.clear();

    return unauthorized();
};

const unauthorized = (): AuthorizationResult => {

    return { authorized: false } as AuthorizationResult;
};

const identityProvider = {
    loginRedirect,
    loginComplete,
    logoutRedirect,
    authorize
};

export default identityProvider;
