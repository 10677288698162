import accuracyValidation from '../../../constants/functions/accuracyValidation';
import aggregationFunctionLegitimacy from '../../../constants/functions/aggregationFunctionLegitimacy';
import ArgumentDefinition from '../../../../types/functions/ArgumentDefinition';
import CustomDataSourceLegitimacy from '../../../types/Functions/CustomDataSourceLegitimacy';
import FieldDefinition from '../../../../types/common/FieldDefinition';
import locationType from '../../../../types/functions/Location/LocationType';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import specialOperandTypeReducer from './specialOperandTypeReducer';
import typeHelper from '../../common/typeHelper';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import operandType from '../../../constants/functions/operandType';
import Condition from '../../../../types/functions/Condition/Condition';
import Operand from '../../../../types/functions/Operand/Operand';
import OperandRules from '../../../../types/functions/Operand/OperandRules';
import operand2ExpectedTypeHelper from './operand2ExpectedTypeHelper';
import operandHelper from './operandHelper';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportCollections from '../../../types/Functions/ReportCollections';
import DictionaryField from '../../../../types/report/DictionaryField';
import getArgumentDefinition from '../common/argumentHelper';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const isSpecialOperandTypeAllowed = (operandRules: OperandRules, specialOperandType: string): boolean => {

    if (!typeHelper.isArray(operandRules.specialOperandTypes)) {

        return false;
    }

    return (operandRules.specialOperandTypes as string[]).includes(specialOperandType);
};

const resolveAggregationFunctionLegitimacy = (statement: string): string => {

    switch (statement) {

        case locationType.statement.aggregatedRecordField:
            return aggregationFunctionLegitimacy.required;

        case locationType.statement.lookup:
        case locationType.statement.variable:
        case locationType.statement.filter:
        case locationType.statement.case:
        case locationType.statement.reportField:
        case locationType.statement.validation:
            return aggregationFunctionLegitimacy.possible;

        default:
            return aggregationFunctionLegitimacy.forbidden;
    }
};

const getEmpty = (injectTypeConversion: boolean): OperandRules => {
    return {
        allowedTypes: null,
        specialOperandTypes: null,
        specialValueType: null,
        allowedValues: null,
        stringLength: null,
        aggregationFunctionLegitimacy: aggregationFunctionLegitimacy.forbidden,
        injectTypeConversion: injectTypeConversion
    };
};

const get = (argumentDefinition: ArgumentDefinition | null, expectedType: string | null, statement: string, customDataSourceLegitimacy: CustomDataSourceLegitimacy): OperandRules => {

    let result = getEmpty(true);

    if (typeHelper.isObject(argumentDefinition)) {

        setup(result, argumentDefinition as ArgumentDefinition, statement, customDataSourceLegitimacy);
    }
    else {

        if (typeHelper.isString(expectedType)) {

            result.allowedTypes = [expectedType as string];
        }

        result.aggregationFunctionLegitimacy = resolveAggregationFunctionLegitimacy(statement);
    }

    return result;
};

const getOperandRules = (
    location: ReportLogicLocation,
    condition: Condition,
    getOperandRuleBundle: (location: ReportLogicLocation,
        operand: Operand,
        root: Operand,
        injectTypeConversion: boolean) => [OperandRules, string],
    operand: Operand, reportConfig: ReportConfig,
    customDataSourceLegitimacy: CustomDataSourceLegitimacy,
    collections: ReportCollections) => {

    let operandRules = {} as OperandRules;

    let currentOperandPlausable = !condition.operand2.operandType || condition.operand2.operandType === operandType.constant || location.argumentIndexes.length === 0;

    let otherOperandAlreadySet = condition.operand1.operandType === operandType.reportField && condition.operand1.reportFieldId !== null;

    if (location.operandNumber === 1) {

        otherOperandAlreadySet = condition.operand2.operandType === operandType.reportField && condition.operand2.reportFieldId !== null;
        currentOperandPlausable = !condition.operand1.operandType || condition.operand2.operandType === operandType.constant || location.argumentIndexes.length === 0;
    }

    if (otherOperandAlreadySet && currentOperandPlausable && location.operandNumber === 1) {

        location.fieldId = condition.operand2.reportFieldId as number;

        let bundle = getOperandRuleBundle(location, operand, operandHelper.getEmpty(), true);

        operandRules = bundle?.[0] || {} as OperandRules;
    }
    else if (otherOperandAlreadySet && currentOperandPlausable && location.operandNumber === 2) {

        location.fieldId = condition.operand1.reportFieldId as number;

        let bundle = getOperandRuleBundle(location, operand, operandHelper.getEmpty(), true);

        operandRules = bundle?.[0] || {} as OperandRules;
    }
    else {

        let argumentDefinition = getArgumentDefinition(condition, null, location, reportConfig.dictionaries, reportConfig.userDefinedFunctions);

        let expectedType = operand2ExpectedTypeHelper.resolve(condition, location, reportConfig.userDefinedFunctions, reportConfig.lookups, reportConfig.dictionaries, reportConfig.variables, collections.dataSources, collections.reportFields, collections.customDataSourceFields);

        operandRules = operandRuleHelper.get(argumentDefinition, expectedType, location.statement, customDataSourceLegitimacy);
    }

    return operandRules;
}


const getForReportField = (argumentDefinition: ArgumentDefinition | null, reportFieldDefinition: ReportFieldDefinition, customDataSourceLegitimacy: CustomDataSourceLegitimacy, injectTypeConversion: boolean): OperandRules => {

    let result = getEmpty(injectTypeConversion);

    if (typeHelper.isObject(argumentDefinition)) {

        setup(result, argumentDefinition as ArgumentDefinition, locationType.statement.reportField, customDataSourceLegitimacy);
    }
    else {
        result.allowedTypes = [reportFieldDefinition.dataType];
        result.allowedValues = parseAllowedValues(reportFieldDefinition);
        result.stringLength = reportFieldDefinition.stringLength;
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.possible;
    }

    return result;
};

const getForAggregatedRecordField = (argumentDefinition: ArgumentDefinition | null, fieldDefinition: FieldDefinition, injectTypeConversion: boolean): OperandRules => {

    let result = getEmpty(injectTypeConversion);

    if (typeHelper.isObject(argumentDefinition)) {

        setup(result, argumentDefinition as ArgumentDefinition, locationType.statement.aggregatedRecordField, { isin: false, lei: false, exchangeRates: false, annaDsbUpiEnrichment: false, annaDsbUpi: false, lse: false, firdsFcaInstrument: false, firdsEsmaInstrument: false, fcaRegulatedEntities: false });
    }
    else {
        result.allowedTypes = [fieldDefinition.type];
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.required;
    }

    return result;
};

const getForSelector = (argumentDefinition: ArgumentDefinition | null, reportFieldDefinition: ReportFieldDefinition, injectTypeConversion: boolean, selectorType: string, locationType: any): OperandRules => {

    let result = getEmpty(injectTypeConversion);

    if (typeHelper.isObject(argumentDefinition)) {

        setup(result, argumentDefinition as ArgumentDefinition, locationType, { isin: false, lei: false, exchangeRates: false, annaDsbUpiEnrichment: false, annaDsbUpi: false, lse: false, firdsFcaInstrument: false, firdsEsmaInstrument: false, fcaRegulatedEntities: false });
    }
    else if (reportFieldDefinition) {
        result.allowedTypes = [reportFieldDefinition.dataType];
        result.allowedValues = parseAllowedValues(reportFieldDefinition);
        result.stringLength = reportFieldDefinition.stringLength;
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.possible;
    }
    else {
        result.allowedTypes = [selectorType];
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.possible;
    }

    return result;
};

const getForVariable = (argumentDefinition: ArgumentDefinition | null, variable: ReportVariable, customDataSourceLegitimacy: CustomDataSourceLegitimacy, reportFieldDefinition: ReportFieldDefinition, injectTypeConversion: boolean): OperandRules => {

    let result = getEmpty(injectTypeConversion);

    if (typeHelper.isObject(argumentDefinition)) {

        setup(result, argumentDefinition as ArgumentDefinition, locationType.statement.variable, customDataSourceLegitimacy);
    }
    else if (reportFieldDefinition) {
        result.allowedTypes = [reportFieldDefinition.dataType];
        result.allowedValues = parseAllowedValues(reportFieldDefinition);
        result.stringLength = reportFieldDefinition.stringLength;
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.possible;
    }
    else {
        result.allowedTypes = [variable.dataType];
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.possible;
    }

    return result;
};

const getForValidation = (argumentDefinition: ArgumentDefinition | null, customDataSourceLegitimacy: CustomDataSourceLegitimacy, reportFieldDefinition: ReportFieldDefinition, injectTypeConversion: boolean): OperandRules => {

    let result = getEmpty(injectTypeConversion);

    if (typeHelper.isObject(argumentDefinition)) {

        setup(result, argumentDefinition as ArgumentDefinition, locationType.statement.validation, customDataSourceLegitimacy);
    }
    else if (reportFieldDefinition) {
        result.allowedTypes = [reportFieldDefinition.dataType];
        result.allowedValues = parseAllowedValues(reportFieldDefinition);
        result.stringLength = reportFieldDefinition.stringLength;
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.possible;
    }
    else {
        result.allowedTypes = [accuracyValidation.type];
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.possible;
    }

    return result;
};

const getForLookup = (argumentDefinition: ArgumentDefinition | null, customDataSourceLegitimacy: CustomDataSourceLegitimacy, reportFieldDefinition: ReportFieldDefinition, injectTypeConversion: boolean, lookupType: string): OperandRules => {

    let result = getEmpty(injectTypeConversion);

    if (typeHelper.isObject(argumentDefinition)) {

        setup(result, argumentDefinition as ArgumentDefinition, locationType.statement.lookup, customDataSourceLegitimacy);
    }
    else if (reportFieldDefinition) {
        result.allowedTypes = [reportFieldDefinition.dataType];
        result.allowedValues = parseAllowedValues(reportFieldDefinition);
        result.stringLength = reportFieldDefinition.stringLength;
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.possible;
    }
    else {
        result.allowedTypes = [lookupType];
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.possible;
    }

    return result;
};

const getForMatchingKey = (argumentDefinition: ArgumentDefinition | null, customDataSourceLegitimacy: CustomDataSourceLegitimacy, reportFieldDefinition: ReportFieldDefinition, injectTypeConversion: boolean): OperandRules => {

    let result = getEmpty(injectTypeConversion);

    if (typeHelper.isObject(argumentDefinition)) {

        setup(result, argumentDefinition as ArgumentDefinition, locationType.statement.matchingKey, customDataSourceLegitimacy);
    }
    else if (reportFieldDefinition) {
        result.allowedTypes = [reportFieldDefinition.dataType];
        result.allowedValues = parseAllowedValues(reportFieldDefinition);
        result.stringLength = reportFieldDefinition.stringLength;
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.possible;
    }
    else {
        result.allowedTypes = [];
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.forbidden;
    }

    return result;
};

const getForDictionaryField = (argumentDefinition: ArgumentDefinition | null, customDataSourceLegitimacy: CustomDataSourceLegitimacy, field: DictionaryField, injectTypeConversion: boolean): OperandRules => {

    let result = getEmpty(injectTypeConversion);

    if (typeHelper.isObject(argumentDefinition)) {

        setup(result, argumentDefinition as ArgumentDefinition, locationType.statement.dictionaryField, customDataSourceLegitimacy);
    }
    else {
        result.allowedTypes = [field.dataType];
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.forbidden;
    }

    return result;
};

const getForFunctionParameter = (): OperandRules => {

    let result = getEmpty(false);

    return result;
};

const getForFunctionBody = (argumentDefinition: ArgumentDefinition | null, customDataSourceLegitimacy: CustomDataSourceLegitimacy, func: UserDefinedFunction, injectTypeConversion: boolean): OperandRules => {

    let result = getEmpty(injectTypeConversion);

    if (typeHelper.isObject(argumentDefinition)) {

        setup(result, argumentDefinition as ArgumentDefinition, locationType.statement.userDefinedFunction, customDataSourceLegitimacy);
    }
    else {

        result.allowedTypes = [func.returnType];
        result.aggregationFunctionLegitimacy = aggregationFunctionLegitimacy.forbidden;
    }

    return result;
};

const setup = (result: OperandRules, argumentDefinition: ArgumentDefinition, statement: string, customDataSourceLegitimacy: CustomDataSourceLegitimacy): void => {

    result.allowedTypes = argumentDefinition.allowedTypes;
    result.specialValueType = argumentDefinition.specialValueType;
    result.specialOperandTypes = specialOperandTypeReducer.reduce(argumentDefinition.specialOperandTypes, statement, customDataSourceLegitimacy);
};

const parseAllowedValues = (reportFieldDefinition: ReportFieldDefinition): string[] | null => {

    if (!typeHelper.isString(reportFieldDefinition.allowedValues)) {
        return null;
    }

    return reportFieldDefinition.allowedValues.split(',');
};

const operandRuleHelper = {
    isSpecialOperandTypeAllowed,
    getEmpty,
    get,
    getForAggregatedRecordField,
    getForReportField,
    getForSelector,
    getForVariable,
    getForValidation,
    getForMatchingKey,
    getForLookup,
    getOperandRules,
    getForDictionaryField,
    getForFunctionParameter,
    getForFunctionBody
};

export default operandRuleHelper;
