import React from 'react';
import { getInputId, getAcceptAttribute } from './FilePicker.Helpers';

interface FileInputProps {
    uniqueName: string;
    maxFileCount: number;
    allowedExtensions: string[];
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class FileInput extends React.Component<FileInputProps> {
    render(): JSX.Element {
        return (
            <div>
                <label htmlFor={getInputId(this.props.uniqueName)}>Choose file</label>
                <input
                    type="file"
                    id={getInputId(this.props.uniqueName)}
                    accept={getAcceptAttribute(this.props.allowedExtensions)}
                    multiple={this.props.maxFileCount > 0}
                    onChange={this.props.onChange}
                    style={{ display: "none" }} />
            </div>
        );
    }
}

export default FileInput;

