import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import FieldDefinition from '../../../types/common/FieldDefinition';

const getColumns = (): ColumnDefinition<FieldDefinition>[] => {
    return [
        {
            header: 'Ref',
            getContent: (x, i) => (i + 1).toString(),
            width: '100px'
        },
        {
            header: 'Name',
            getContent: x => x.name
        },
        {
            header: 'Type',
            getContent: x => x.type
        }
    ];
};

export { getColumns };
