import { getConfigOptionText, getConfigOptionValue, isDataSourceReferenced, regimeOptions } from './DataSourceForm.Helpers';
import dataSourceKind from '../../../infrastructure/constants/dataSourceKind';
import FormGroup from '../../Common/FormGroup/FormGroup';
import guidance from './DataSourceForm.Guidance';
import IngestionConfigOption from '../../../types/ingestion/IngestionConfigOption';
import MultiSelectList from '../../Common/MultiSelectList/MultiSelectList';
import React from 'react';
import ReportConfig from '../../../types/report/ReportConfig';
import SelectList from '../../Common/SelectList/SelectList';
import { getAccuracyReportTypeDisplayText } from '../../../infrastructure/constants/reportType';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import ReferencesModal from '../../Common/Modals/ReferencesModal/ReferencesModal';
import operandType from '../../../infrastructure/constants/functions/operandType';
import ReportCollections from '../../../infrastructure/types/Functions/ReportCollections';
import accuracyDataSourceFormHelper from './AccuracyDataSourceForm.Helpers';
import IdAndName from '../../../types/report/IdAndName';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import ComponentType from '../../../types/report/ComponentType';
import { htmlIds } from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation.HtmlIds';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import { onReferenceSelected, toggleSection } from '../../../infrastructure/helpers/report/reference/helper';

interface Props {
    dataSources: IngestionConfigOption[];
    reportConfig: ReportConfig;
    onChange: (reportConfig: ReportConfig) => void;
    collections: ReportCollections;
}

interface State {
    fields: IdAndName[],
    sources: string[],
    showReferencesModal: boolean,
    referenceOperandType: string,
    coreIsExpanded: boolean,
    standardIsExpanded: boolean
}

class AccuracyDataSourceForm extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            fields: [],
            sources: [],
            showReferencesModal: false,
            referenceOperandType: '',
            coreIsExpanded: false,
            standardIsExpanded: false,
        }

        this.onDataSourceSelected = this.onDataSourceSelected.bind(this);
        this.onRemoveDataSourceClick = this.onRemoveDataSourceClick.bind(this);
        this.buildDataSourceItem = this.buildDataSourceItem.bind(this);
        this.onRegimeReferencesClick = this.onRegimeReferencesClick.bind(this);
        this.onRawReferenceClick = this.onRawReferenceClick.bind(this);
        this.onRefReferenceClick = this.onRefReferenceClick.bind(this);
        this.onReferenceButtonClick = this.onReferenceButtonClick.bind(this);
        this.onFieldSearchResultSelected = this.onFieldSearchResultSelected.bind(this);
    }

    async onRawReferenceClick(url: string) {

        let result = await httpClient.get<IdAndName[]>(url);

        let fields = result.data;

        this.setState({ fields, showReferencesModal: true, referenceOperandType: operandType.reportField });
    }

    async onRefReferenceClick(url: string) {

        let result = await httpClient.get<IngestionConfig>(url);

        let fields = result.data.fieldDefinitions as IdAndName[];

        this.setState({ fields, showReferencesModal: true, referenceOperandType: operandType.field });
    }

    onDataSourceSelected(value: string): void {

        let id = parseInt(value);

        let refDataSources = this.props.reportConfig.refDataSources.concat([{ id: id, runStatus: 0 }]);

        let changes = { refDataSources } as ReportConfig;

        this.props.onChange(changes);
    }

    onRemoveDataSourceClick(value: string): void {

        let id = parseInt(value);

        let refDataSources = this.props.reportConfig.refDataSources.filter(x => x.id !== id);

        let changes = { refDataSources } as ReportConfig;

        this.props.onChange(changes);
    }

    buildDataSourceItem(config: IngestionConfigOption, isGlobalRef: boolean): JSX.Element {

        return (
            <div style={{ padding: '2px 0' }}>
                <div className="flex-container margin-0-important padding-right-0-important height-30px">
                    <span>{config.name}</span>
                    <button
                        onClick={() => this.onReferenceButtonClick(config, isGlobalRef)}
                        className="btn btn-light margin-0-important height-30px line-height-1"
                    >References
                    </button>
                </div>
            </div>
        );
    }

    onReferenceButtonClick(config: IngestionConfigOption, isGlobalRef: boolean) {

        let route = isGlobalRef ? 'global-ref-data-source' : 'ingestion';

        let url = urlHelper.buildRoute([route, config.id]);

        this.onRefReferenceClick(url);
    }

    onRegimeReferencesClick() {

        this.onRawReferenceClick(urlHelper.buildUrl('report-field', { regime: this.props.reportConfig.regime }));
    }

    onFieldSearchResultSelected(value: string, selectedComponentType: ComponentType, mainComponentType: ComponentType): void {

        if (mainComponentType === ComponentType.AccuracyValidation) {

            if (!this.state.coreIsExpanded && !this.state.standardIsExpanded) {
                toggleSection(htmlIds.validations);
            }

            if (selectedComponentType === ComponentType.AccuracyValidationCore) {

                if (!this.state.coreIsExpanded) {

                    toggleSection(htmlIds.coreValidations);

                    this.setState({ coreIsExpanded: true });
                }
            }
            else if (selectedComponentType === ComponentType.AccuracyValidationStandard) {
                if (!this.state.standardIsExpanded) {

                    toggleSection(htmlIds.cnValidations);

                    this.setState({ standardIsExpanded: true });
                }
            }

            reportConfigNavigation.navigateToItem(value)
        }
        else {
            onReferenceSelected(value);
        }
    }

    render(): JSX.Element {
        const labelClass = 'col-4';
        const contentClass = 'col-8';

        return (
            <>
                <FormGroup label="Config name:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.name}>
                    <input
                        type="text"
                        className="form-control"
                        value={this.props.reportConfig.name}
                        readOnly={true} />
                </FormGroup>
                <FormGroup label="Regime:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.regime}>
                    <SelectList
                        options={regimeOptions}
                        className="form-control"
                        disabled={true}
                        value={this.props.reportConfig.regime}
                        onChange={() => { }}
                        includeEmptyOption={false} />
                </FormGroup>
                <FormGroup label="Reporting type:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.reportType}>
                    <input
                        type="text"
                        className="form-control"
                        value={getAccuracyReportTypeDisplayText(this.props.reportConfig.type)}
                        readOnly={true} />
                </FormGroup>
                <FormGroup label="Raw data source:" labelClassName={labelClass} contentClassName={`${contentClass} flex-container`} info={guidance.regime}>
                    <div className='flex-container  height-30px'>
                        <label className="margin-0-important">{accuracyDataSourceFormHelper.getAccuracyReportRawDataSource(this.props.reportConfig.regime)}</label>
                        <div className="col-auto margin-0-important padding-right-0-important">
                            <button
                                onClick={this.onRegimeReferencesClick}
                                className="btn btn-light margin-0-important height-30px line-height-1"
                            >References
                            </button>
                        </div>
                    </div>
                </FormGroup>
                <FormGroup label="Client accuracy ref data sources:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.refDataSources}>
                    <MultiSelectList
                        items={this.props.dataSources.filter(x => x.dataSourceKind === dataSourceKind.accuracy)}
                        getItemValue={getConfigOptionValue}
                        getItemText={getConfigOptionText}
                        isItemDisabled={x => false}
                        className="form-control"
                        isSelectListDisabled={false}
                        isRemoveDisabled={value => isDataSourceReferenced(parseInt(value), this.props.reportConfig)}
                        values={this.props.reportConfig.refDataSources.map(x => x.id.toString())}
                        onSelectChange={this.onDataSourceSelected}
                        onRemoveClick={this.onRemoveDataSourceClick}
                        buildItem={(config: IngestionConfigOption) => this.buildDataSourceItem(config, false)} />
                </FormGroup>
                <FormGroup label="Global ref data sources:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.refDataSources}>
                    <MultiSelectList
                        items={this.props.dataSources.filter(x => x.dataSourceKind === dataSourceKind.globalRef)}
                        getItemValue={getConfigOptionValue}
                        getItemText={getConfigOptionText}
                        isItemDisabled={x => false}
                        className="form-control"
                        isSelectListDisabled={false}
                        isRemoveDisabled={value => isDataSourceReferenced(parseInt(value), this.props.reportConfig)}
                        values={this.props.reportConfig.refDataSources.map(x => x.id.toString())}
                        onSelectChange={this.onDataSourceSelected}
                        onRemoveClick={this.onRemoveDataSourceClick}
                        buildItem={(config: IngestionConfigOption) => this.buildDataSourceItem(config, true)} />
                </FormGroup>
                {
                    this.state.showReferencesModal &&
                    <ReferencesModal
                        reportConfig={this.props.reportConfig}
                        onClose={() => this.setState({ showReferencesModal: false })}
                        fields={this.state.fields}
                        operandType={this.state.referenceOperandType}
                        defaultSourceOptionTitle='References'
                        defaultFieldsOptionTitle='Select from List of Fields'
                        defaultResultOptionTitle='List of References'
                        sourcesTitle='Source Level:'
                        fieldsTitle='Field Level:'
                        modalTitle='References'
                        collections={this.props.collections}
                        onResultFieldSelected={this.onFieldSearchResultSelected}
                    />
                }
            </>
        );
    }
}

export default AccuracyDataSourceForm;
