import ObjectIndexer from '../types/ObjectIndexer';

const reportType = {
    completenessFieldLevelMatching: 'CompletenessFieldLevelMatching',
    completenessRecordLevelMatching: 'CompletenessRecordLevelMatching',
    directReporting: 'DirectReporting',
    accuracy: 'Accuracy'
};

const getReportTypeDisplayMap = (): ObjectIndexer<string> => {
    let map = {} as ObjectIndexer<string>;

    map[reportType.completenessFieldLevelMatching] = 'TR Completeness - Field Level Matching';
    map[reportType.completenessRecordLevelMatching] = 'TR Completeness - Record Level Matching';
    map[reportType.directReporting] = 'CB Direct Reporting';

    return map;
};

const getAccuracyReportTypeDisplayText = (reportTypeIn: string): string => {

    if (reportTypeIn === reportType.accuracy) {
        return 'TR Accuracy';
    }

    return '';
};

const reportTypeDisplayMap = getReportTypeDisplayMap();

export { reportType, reportTypeDisplayMap, getAccuracyReportTypeDisplayText };
