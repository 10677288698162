import Aggregation from '../../../types/report/Aggregation';
import AggregationBuilder from './AggregationBuilder';
import aggregationGroupByHelper from '../../../infrastructure/helpers/functions/aggregation/aggregationGroupByHelper';
import aggregationHelper from '../../../infrastructure/helpers/functions/aggregation/aggregationHelper';
import dataSourceHelper from '../../../infrastructure/helpers/functions/common/dataSourceHelper';
import locationType from '../../../types/functions/Location/LocationType';
import React from 'react';
import referenceValidator from '../../../infrastructure/helpers/functions/validator/referenceValidator';
import ReportCollections from '../../../infrastructure/types/Functions/ReportCollections';
import ReportConfig from '../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import AggregationOperandSetBlockBuilder from './OperandSet/AggregationOperandSetBlockBuilder';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';

interface Props {
    isWaiting: boolean;
    isReadOnly: boolean;
    aggregationNumber: number | null;
    reportConfig: ReportConfig;
    collections: ReportCollections;
    onSelected: (number: number) => void;
    onChange: (aggregations: Aggregation[]) => void;
    clipboard: ReportLogicLocation | null;
    onClipboardChange: (clipboard: ReportLogicLocation) => void;
}

class AggregationBlockBuilder extends React.Component<Props, { location: ReportLogicLocation, showWarningModal: boolean }> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showWarningModal: false,
            location: {} as ReportLogicLocation
        }

        this.onAddAggregationClick = this.onAddAggregationClick.bind(this);
        this.onRemoveAggregationClick = this.onRemoveAggregationClick.bind(this);
        this.onAggregationChange = this.onAggregationChange.bind(this);
        this.onRemoveAggregationConfirmed = this.onRemoveAggregationConfirmed.bind(this);
    }

    onAddAggregationClick(): void {

        let dataSource = dataSourceHelper.getById(this.props.collections.dataSources, this.props.reportConfig.rawDataSourceId as number);

        let aggregation = aggregationHelper.getDefault(dataSource);

        aggregationHelper.add(this.props.reportConfig.aggregations, aggregation);

        this.props.onChange(this.props.reportConfig.aggregations);
    }

    onRemoveAggregationClick(location: ReportLogicLocation): void {

        this.setState({ showWarningModal: true, location });
    }

    onRemoveAggregationConfirmed() {

        this.setState({ showWarningModal: false });

        let error = referenceValidator.validateAggregationReferences(this.state.location.statementNumber, this.props.reportConfig);
        if (error) {

            let target = aggregationHelper.get(this.props.reportConfig.aggregations, this.state.location);

            target.errors = [error];

            this.props.onChange(this.props.reportConfig.aggregations);

            return;
        }

        let reduced = this.props.reportConfig.aggregations.filter(x => x.number !== this.state.location.statementNumber);

        this.props.onChange(reduced);
    }

    onAggregationChange(location: ReportLogicLocation, changes: Aggregation): void {

        let aggregation = aggregationHelper.get(this.props.reportConfig.aggregations, location);

        Object.assign(aggregation, changes);

        this.props.onChange(this.props.reportConfig.aggregations);
    }

    render(): JSX.Element {
        return (
            <>
                <div className="row no-gutters mb-3">
                    <div className="col-auto">
                        <button
                            onClick={this.onAddAggregationClick}
                            className="btn btn-light">+ Aggregation</button>
                    </div>
                </div>

                <AggregationOperandSetBlockBuilder
                    childComponentType={AggregationBuilder}
                    isWaiting={this.props.isWaiting}
                    isReadOnly={this.props.isReadOnly}
                    selectedSetNumber={this.props.aggregationNumber}
                    reportConfig={this.props.reportConfig}
                    collections={this.props.collections}
                    onRemoveClick={this.onRemoveAggregationClick}
                    onItemChange={this.onAggregationChange}
                    onChange={this.props.onChange}
                    helper={aggregationGroupByHelper}
                    details={{
                        statement: locationType.statement.aggregation,
                        expectedType: null,
                        name: 'GroupBy'
                    }}
                    clipboard={this.props.clipboard}
                    onClipboardChange={this.props.onClipboardChange} />
                {
                    this.props.children
                }
                {
                    this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onRemoveAggregationConfirmed}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Remove item'
                        message='Are you sure you want to delete this item? This action cannot be undone.' />
                }
            </>
        );
    }
}

export default AggregationBlockBuilder;
