import { getCurrentVersion } from './ReportChangeLogPage.Helpers';
import { moduleColumn, moduleRefColumn, commonColumns } from '../../Common/Modals/ChangeLogModal/ChangeLogModal.Grid';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ChangeLog from '../../Common/ChangeLog/ChangeLog';
import getColumns from './ReportChangeLogPage.Grid';
import GridRefresh from '../../../store/state/events/GridRefresh';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import identityStorage from '../../../infrastructure/authorization/identityStorage';
import IncrementVersionRequest from '../../../types/common/IncrementVersionRequest';
import React from 'react';
import store from '../../../store/store';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import VersionDisplay from '../../Common/VersionEditor/VersionDisplay';
import versionHelper from '../../../infrastructure/helpers/functions/common/versionHelper';
import writeUserRoles from '../../../infrastructure/constants/identity/writeUserRoles';
import CreateVersionModalState from '../../Common/Modals/CreateVersionModal/CreateVersionModalState';
import CreateVersionModal from '../../Common/Modals/CreateVersionModal/CreateVersionModal';
import RollBackVersionModal from '../../Common/Modals/RollbackVersionModal/RollBackVersionModal';
import RollbackVersionModalState from '../../Common/Modals/RollbackVersionModal/RollbackVersionModalState';

const gridName = 'reporting-change-log';

interface State {
    isWaiting: boolean;
    version: string;
}

class ReportChangeLogPage extends React.Component<any, State> {
    private id: number;
    private redirectRoute: string;
    private validQueryParams: boolean;

    constructor(props: any) {
        super(props);

        let query = urlHelper.deserializeQuery(history.location.search);

        this.id = parseInt(query['id']);
        this.redirectRoute = query['redirect'];

        this.validQueryParams =
            typeHelper.isNumber(this.id) &&
            typeHelper.isString(this.redirectRoute);

        if (!this.validQueryParams) {
            history.push(appRoutes.home);
        }

        this.state = {
            isWaiting: true,
            version: ''
        };

        this.getCurrentVersion = this.getCurrentVersion.bind(this);
        this.onGetCurrentVersionSuccess = this.onGetCurrentVersionSuccess.bind(this);
        this.openIncrementVersionModal = this.openIncrementVersionModal.bind(this);
        this.onIncrementVersionSuccess = this.onIncrementVersionSuccess.bind(this);
        this.redirect = this.redirect.bind(this);
        this.openRollBackModal = this.openRollBackModal.bind(this);
    }

    componentDidMount(): void {

        if (!this.validQueryParams) {
            return;
        }

        this.getCurrentVersion();
    }

    getCurrentVersion(): void {

        getCurrentVersion(this.id).then(response => this.onGetCurrentVersionSuccess(response.data.value));
    }

    onGetCurrentVersionSuccess(version: string): void {

        let changes = { isWaiting: false, version: version } as State;

        this.setState(changes);
    }

    openIncrementVersionModal(isReleased: boolean): void {

        let url = urlHelper.buildRoute(['report', this.id, this.state.version]);

        let payload: CreateVersionModalState = {
            isOpen: true,
            title: isReleased ? 'Release' : 'Increment Version',
            name: '',
            comment: '',
            onSaveClick: (name: string, comment: string) => httpClient.post(url, { isReleased: isReleased, name: name, comment: comment } as IncrementVersionRequest).then(this.onIncrementVersionSuccess),
            error: null
        };

        store.dispatch({ type: actions.createVersionModal.open, payload: payload });
    }

    onIncrementVersionSuccess() {

        this.getCurrentVersion();

        let payload: GridRefresh = {
            uniqueName: gridName,
            useCurrentPage: false
        };

        store.dispatch({ type: actions.grid.refresh, payload: payload });
    }

    redirect(): void {

        history.push(decodeURIComponent(this.redirectRoute));
    }

    openRollBackModal(): void {

        var getVersionsRoute = urlHelper.buildRoute(['report-version', this.id, 'list']);

        httpClient.get<string[]>(getVersionsRoute).then((response) => {

            let payload: RollbackVersionModalState = {
                isOpen: true,
                title: 'Roll Back',
                name: '',
                comment: '',
                versions: response.data,
                selectedVersion: response.data[0],
                onSaveClick: (identifier: string, name: string, comment: string) => {
                    let route = urlHelper.buildRoute(['report', this.id, 'rollback', identifier]);
                    return httpClient.post(route, { name: name, comment: comment } as IncrementVersionRequest).then(this.onIncrementVersionSuccess)
                },
                error: null
            };

            if (payload.versions.length > 0) {

                payload.selectedVersion = payload.versions[0];
                payload.name = `Roll Back to V${payload.selectedVersion}`;
            }

            store.dispatch({ type: actions.rollBackVersionModal.open, payload: payload });
        });
    }

    render(): JSX.Element {

        if (!this.validQueryParams) {
            return (<></>);
        }

        let identity = identityStorage.get();

        return (
            <>
                {
                    writeUserRoles.includes(identity.userRole) &&
                    <>
                        <h4 className="mb-3">Version Management</h4>
                        {
                            !this.state.isWaiting &&
                            <div className="mb-4">
                                <button className="btn cb-btn" onClick={() => this.openIncrementVersionModal(false)}>
                                    <span>Create Version </span>
                                    <VersionDisplay version={this.state.version} majorIncremented={false} />
                                </button>
                                <button className="btn cb-btn"  onClick={() => this.openIncrementVersionModal(true)} disabled={versionHelper.isReleasedVersion(this.state.version)}>
                                    <span>Release as </span>
                                    <VersionDisplay version={this.state.version} majorIncremented={true} />
                                </button>
                                <button className="btn cb-btn" onClick={this.openRollBackModal}>
                                    <span>Roll Back</span>
                                </button>
                            </div>
                        }
                    </>
                }

                <h4 className="mb-3">Change Log</h4>

                <ChangeLog
                    getColumns={getColumns}
                    modalColumns={[moduleColumn, moduleRefColumn].concat(commonColumns)}
                    backendRoute={urlHelper.buildRoute(['report-version', this.id])}
                    uniqueName={gridName} />

                <div className="mt-3 mb-3">
                    <button onClick={this.redirect} className="btn btn-light">Back</button>
                </div>

                <CreateVersionModal />
                <RollBackVersionModal />
            </>
        );
    }
}

export default ReportChangeLogPage;
