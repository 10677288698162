import { mapToBasicOption } from '../../../infrastructure/helpers/html/selectOptionHelper';
import { ReactComponent as RemoveIcon } from '../../Common/BootstrapIcons/close.svg';
import { ReactComponent as CopyIcon } from '../../Common/BootstrapIcons/copy.svg';
import { reportType } from '../../../infrastructure/constants/reportType';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import dataType from '../../../infrastructure/constants/dataType';
import EditableText from '../../Common/EditableText/EditableText';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import LazySelectList from '../../Common/LazySelectList/LazySelectList';
import React from 'react';
import ReportVariable from '../../../types/report/ReportVariable';
import SelectList from '../../Common/SelectList/SelectList';
import SelectOption from '../../../infrastructure/types/SelectOption';
import systemVariables from '../../../infrastructure/constants/functions/systemVariables';
import DraggableItem from '../../Common/DraggableItem/DraggableItem';
import Assignment from '../../../types/functions/Assignment/Assignment';

const dataTypeOptions = Object.values(dataType).map(mapToBasicOption);
const systemVariableNameOptions = Object.values(systemVariables.names).map(mapToBasicOption);


const getNumberContent = (number: number, reorder: (dragNumber: number, dropNumber: number, event: React.DragEvent<HTMLDivElement>) => void, isReadOnly: boolean): string | JSX.Element => {

    if (isReadOnly) {
        return number.toString();
    }

    return (
        <DraggableItem identifier={number} onDrop={reorder}>
            {number}
        </DraggableItem>
    );
};

const getNameContent = (variable: ReportVariable, onChange: (name: string, number: number) => void, isReadOnly: boolean): JSX.Element => {

    if (variable.isSystem) {

        return (
            <SelectList
                className="inline-select"
                options={systemVariableNameOptions}
                value={variable.name}
                onChange={value => onChange(value, variable.number)}
                disabled={variable.isReadOnly || isReadOnly} />
        );
    }

    return (
        <EditableText
            value={variable.name}
            key={variable.name}
            onChange={value => onChange(value, variable.number)}
            multiline={false}
            disabled={variable.isReadOnly || isReadOnly} />
    );
};

const getDataTypeContent = (variable: ReportVariable, onChange: (type: string, number: number, assignment: Assignment) => void, isReadOnly: boolean, onLockSectionClick?: (number: number) => Promise<boolean>): JSX.Element => {

    if (variable.isSystem) {

        return (
            <>{variable.dataType}</>
        );
    }

    const onChangeCheckIfLocked = async (type: string, number: number, assignment: Assignment, event: React.ChangeEvent<HTMLElement>): Promise<void> => {

        const isLocked = onLockSectionClick && await onLockSectionClick(number);
        if (!isLocked) {
            onChange(type, variable.number, variable.assignment);
        }
        else {
            event.preventDefault();
        }
    }

    return (
        <SelectList
            className="inline-select"
            options={dataTypeOptions}
            value={variable.dataType}
            onClick={async (event) => onLockSectionClick && await onLockSectionClick(variable.number)}
            onChange={(type, event) => { onLockSectionClick ? onChangeCheckIfLocked(type, variable.number, variable.assignment, event) : onChange(type, variable.number, variable.assignment) }}
            disabled={variable.isReadOnly || isReadOnly} />
    );
};

const getColumns = (
    onLockSectionClick: (number: number) => Promise<boolean>,
    onNameChange: (name: string, number: number) => void,
    onDataTypeChange: (type: string, number: number, assignment: Assignment) => void,
    onBusinessDescriptionChange: (businessDescription: string, number: number) => void,
    onIsSystemChange: (isSystem: boolean, dataType: string, number: number, event: React.ChangeEvent<HTMLElement>) => void,
    onRemoveClick: (number: number, event: React.MouseEvent<HTMLElement>) => void,
    onCopyClick: (number: number, event: React.MouseEvent<HTMLElement>) => void,
    getVariableReferences: (number: number) => SelectOption[],
    reorder: (dragNumber: number, dropNumber: number, event: React.DragEvent<HTMLDivElement>) => void,
    type: string,
    isReadOnly: boolean,
    onVariableReferenceSelected: (value: string) => void
): ColumnDefinition<ReportVariable>[] => {

    let columns: ColumnDefinition<ReportVariable>[] = [];

    columns.push({
        header: 'Reference',
        getContent: x => getNumberContent(x.number, reorder, x.isReadOnly || isReadOnly),
        width: '110px'
    });

    columns.push({
        header: 'Variable Name',
        getContent: x => getNameContent(x, onNameChange, isReadOnly),
        width: '200px'
    });

    columns.push({
        header: 'Data Type',
        getContent: x => getDataTypeContent(x, onDataTypeChange, x.isReadOnly || isReadOnly, onLockSectionClick),
        width: '200px'
    });

    columns.push({
        header: 'Business Description',
        getContent: x => <EditableText disabled={x.isReadOnly || isReadOnly} value={x.businessDescription} onChange={value => onBusinessDescriptionChange(value, x.number)} multiline={true} style={{ width: '100%', height: '60px' }} />
    });

    if (type !== reportType.accuracy) {

        columns.push({
            header: 'Is System Field',
            info: 'System Fields (ID, Counterparty and Product) are key information which are helpful while debugging.',
            getContent: x =>
                <>
                    <input
                        type="checkbox"
                        checked={x.isSystem}
                        onChange={e => onIsSystemChange(e.target.checked, x.dataType, x.number, e)}
                        disabled={x.isReadOnly || isReadOnly} />
                </>,
            width: '150px'
        });
    }

    columns.push({
        header: '',
        getContent: x =>
            <>
                <LazySelectList
                    className="inline-select"
                    label="References"
                    width={106}
                    getOptions={() => getVariableReferences(x.number)}
                    onSelected={(value) => onVariableReferenceSelected(value)} />
                <div className="float-right">
                    <button
                        className="custom-icon-button"
                        onClick={(e) => onRemoveClick(x.number, e)}
                        disabled={x.isReadOnly || isReadOnly}>
                        <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                    </button>
                    <button className="custom-icon-button"
                        onClick={(e) => onCopyClick(x.number, e)}
                        disabled={x.isReadOnly || isReadOnly}>
                        <CustomIcon icon={CopyIcon} title={iconTooltip.copy} />
                    </button>
                </div>
            </>,
        width: '180px'
    });

    return columns;
};

export { getColumns }