import Condition from '../../../../types/functions/Condition/Condition';
import cases from './autoCompleteHelper.Cases';
import conditionHelper from './conditionHelper';

const execute = (condition: Condition): void => {

    conditionHelper.asCollection(condition).forEach(item => {

        cases.forEach($case => {

            if ($case.qualifies(item)) {
                $case.autoComplete(item);
            }
        });
    });
};

const autoCompleteHelper = { execute };

export default autoCompleteHelper;
