import { ReactComponent as RemoveIcon } from '../../Common/BootstrapIcons/close.svg';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import InfoIcon from '../../Common/InfoIcon/InfoIcon';
import React from 'react';
import EditableText from '../../Common/EditableText/EditableText';
import Selector from '../../../types/report/Selector';

const onValueChange = async (selector: Selector, e: any, onLockSectionClick: (e: any, number: number) => Promise<boolean>, isValidationConfig?: boolean, changeFunction?: (text: string, number: number, event: React.ChangeEvent<HTMLElement>) => void, value?: any) => {

    let isLocked = false;
    if (isValidationConfig && !selector.isUsedByCurrentUser) {
        if (selector.isLocked) {
            e.stopPropagation();
        }
        else if (isValidationConfig) {
            isLocked = await onLockSectionClick(e, selector.number);
            if (isLocked) {
                e.stopPropagation();
            }
            else if (changeFunction) {
                changeFunction(value, selector.number, e);
            }
        }
    }

    if (!isValidationConfig) {
        if (changeFunction) {
            changeFunction(value, selector.number, e);
        }
    }

    return isLocked;
}

const getColumns = (
    onLockSectionClick: (event: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, number: number) => Promise<boolean>,
    onRemoveClick: (number: number, event: React.MouseEvent<HTMLElement>) => void,
    onNameChange: (name: string, number: number) => void,
    isReadOnly: boolean,
    info: string,
    selectorType: string,
    isValidationConfig?: boolean,

): ColumnDefinition<Selector>[] => {

    let columns: ColumnDefinition<Selector>[] = [
        {
            header: 'Info',
            getContent: x => <InfoIcon info={info} />,
            width: '110px'
        },
        {
            header: 'Reference',
            getContent: x => x.number.toString(),
            width: '110px'
        },
        {
            header: 'Name',
            getContent: x => <EditableText disabled={x.isReadOnly || isReadOnly}
                onChange={async (value, e) => { return await onValueChange(x, e, onLockSectionClick, isValidationConfig, onNameChange, value) }}
                value={x.name}
                multiline={false} />,
            width: '200px'
        },
        {
            header: 'Data Type',
            getContent: x => selectorType
        },
        {
            header: '',
            getContent: x =>
                <button
                    className="custom-icon-button float-right"
                    onClick={(event) => onRemoveClick(x.number, event)}
                    disabled={x.isReadOnly || isReadOnly}>
                    <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                </button>
        }
    ];

    return columns;
};

export { getColumns };
