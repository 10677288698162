import { separatorOptions, csvHeaderLineNumberOptions, csvDataLineNumberOptions, textSeparatorOptions, headerLinePresentId, escapeCharacterOptions } from './CsvArgumentsPicker.Helpers';
import CsvIngestionArguments from '../../../types/ingestion/csv/CsvIngestionArguments';
import FormGroup from '../../Common/FormGroup/FormGroup';
import guidance from './CsvArgumentsPicker.Guidance';
import React from 'react';
import SelectList from '../../Common/SelectList/SelectList';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

interface CsvArgumentsPickerProps {
    isReadOnly: boolean;
    arguments: CsvIngestionArguments;
    onChange: (args: CsvIngestionArguments) => void;
}

class CsvArgumentsPicker extends React.Component<CsvArgumentsPickerProps> {

    constructor(props: CsvArgumentsPickerProps) {
        super(props);

        this.onSeparatorChange = this.onSeparatorChange.bind(this);
        this.onTextSeparatorChange = this.onTextSeparatorChange.bind(this);
        this.onIsHeaderLinePresentChange = this.onIsHeaderLinePresentChange.bind(this);
        this.onHeaderLineNumberChange = this.onHeaderLineNumberChange.bind(this);
        this.onDataLineNumberChange = this.onDataLineNumberChange.bind(this);
        this.onEscapeCharacterChange = this.onEscapeCharacterChange.bind(this);
    }

    onSeparatorChange(value: string): void {

        let changes = { separator: value } as CsvIngestionArguments;

        this.props.onChange(changes);
    }

    onTextSeparatorChange(value: string): void {

        let textSeparator = stringHelper.isNonEmpty(value) ? value : null;

        let changes = { textSeparator } as CsvIngestionArguments;

        this.props.onChange(changes);
    }

    onIsHeaderLinePresentChange(e: React.ChangeEvent<HTMLInputElement>): void {

        let headerLineNumber = e.target.checked ? 1 : 0;

        let changes = {} as CsvIngestionArguments;

        changes.headerLineNumber = headerLineNumber;
        changes.dataLineNumber = headerLineNumber + 1;

        this.props.onChange(changes);
    }

    onHeaderLineNumberChange(value: string): void {

        let headerLineNumber = parseInt(value);

        let changes = {} as CsvIngestionArguments;

        changes.headerLineNumber = headerLineNumber;
        changes.dataLineNumber = headerLineNumber + 1;

        this.props.onChange(changes);
    }

    onEscapeCharacterChange(value: string): void {

        let changes = { escapeCharacter: value } as CsvIngestionArguments;

        this.props.onChange(changes);
    }

    onDataLineNumberChange(value: string): void {

        let dataLineNumber = stringHelper.isEmpty(value) ? (null as any as number) : parseInt(value);

        let changes = { dataLineNumber: dataLineNumber } as CsvIngestionArguments;

        this.props.onChange(changes);
    }

    render() {
        const labelClass = 'col-5 col-md-6';
        const contentClass = 'col-7 col-md-6';

        return (
            <>
                <FormGroup label="Field Delimiter:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.separator}>
                    <SelectList
                        disabled={this.props.isReadOnly}
                        className="form-control"
                        options={separatorOptions}
                        value={this.props.arguments.separator}
                        onChange={this.onSeparatorChange}
                        includeEmptyOption={true} />
                </FormGroup>

                <FormGroup label="Text Delimiter:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.textSeparator}>
                    <SelectList
                        disabled={this.props.isReadOnly}
                        className="form-control"
                        options={textSeparatorOptions}
                        value={this.props.arguments.textSeparator || ''}
                        onChange={this.onTextSeparatorChange}
                        includeEmptyOption={true} />
                </FormGroup>
                <FormGroup label="Escape Character:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.escapeCharacter}>
                    <SelectList
                        disabled={this.props.isReadOnly}
                        className="form-control"
                        options={escapeCharacterOptions}
                        value={this.props.arguments.escapeCharacter}
                        onChange={this.onEscapeCharacterChange}
                        includeEmptyOption={false} />
                </FormGroup>
                <FormGroup label="Header Row Present:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.headerLinePresent} htmlFor={headerLinePresentId}>
                    <input
                        id={headerLinePresentId}
                        style={{ marginTop: '12px' }}
                        type="checkbox"
                        disabled={this.props.isReadOnly}
                        checked={this.props.arguments.headerLineNumber > 0}
                        onChange={this.onIsHeaderLinePresentChange} />
                </FormGroup>
                {
                    this.props.arguments.headerLineNumber > 0 &&
                    <FormGroup label="Header Row Number:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.headerLineNumber}>
                        <SelectList
                            disabled={this.props.isReadOnly}
                            className="form-control"
                            options={csvHeaderLineNumberOptions}
                            value={this.props.arguments.headerLineNumber.toString()}
                            onChange={this.onHeaderLineNumberChange} />
                    </FormGroup>
                }
                <FormGroup label="Data Start Row Number:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.dataLineNumber}>
                    <SelectList
                        disabled={this.props.isReadOnly}
                        className="form-control"
                        options={csvDataLineNumberOptions}
                        value={typeHelper.isNumber(this.props.arguments.dataLineNumber) ? this.props.arguments.dataLineNumber.toString() : ''}
                        onChange={this.onDataLineNumberChange}
                        includeEmptyOption={true} />
                </FormGroup>
            </>
        );
    }
}

export default CsvArgumentsPicker;
