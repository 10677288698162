const appRoutes = {
    home: '/',
    login: '/login',
    unauthorized: '/unauthorized',
    forbidden: '/forbidden',

    ingestion: {
        home: '/ingestion',
        upload: '/ingestion/upload',
        fieldDefinition: '/ingestion/field-definition',
        details: '/ingestion/details',
        signoff: '/ingestion/signoff',
        edit: '/ingestion/edit',
        changeLog: '/ingestion/change-log'
    },

    reporting: {
        home: '/reporting',
        dataSources: '/reporting/data-sources',
        edit: '/reporting/edit',
        changeLog: '/reporting/change-log',
        actionLog: '/reporting/action-log',
        actionItem: '/reporting/action-item'
    },

    masterConfiguration: {
        home: '/master-configuration',
        versions: '/master-configuration/versions'
    },

    validation: {
        home: '/validation',
        edit: '/validation/edit'

    },

    globalRefDataSource: {
        preview: '/global-ref-data-source/preview'
    }
};

export default appRoutes;