import Assignment from '../../../../types/functions/Assignment/Assignment';
import assignmentHelper from '../assignment/assignmentHelper';
import AssignmentItemDetails from '../../../../types/functions/Assignment/AssignmentItemDetails';
import assignmentItemHelper from '../assignment/assignmentItemHelper';
import Condition from '../../../../types/functions/Condition/Condition';
import conditionValidator from './conditionValidator';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import Declaration from '../../../types/Functions/Declaration';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import operandDisplayHelper from '../operand/operandDisplayHelper';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const validateValue = (item: Assignment, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, dataType: string, reportFieldId: number | null, allowDoNotPopulate: boolean): string | null => {

    if (item.doNotPopulate && allowDoNotPopulate) {
        return null;
    }

    if (item.doNotPopulate && !allowDoNotPopulate) {

        return '\'Do not populate\' is not allowed';
    }

    let details = operandDisplayHelper.getOperandDetails(item.value, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, reportFieldId);

    if (!details.isSetUp) {
        return 'Assignment value not set up';
    }

    if (details.isIllegalReportFieldReference) {
        return 'Report field reference is not allowed. Referenced field must be above referencing field in the list of report fields';
    }

    if (details.dataType !== dataType) {

        return `Assignment type mismatch. Expected type: ${dataType}. Actual type: ${details.dataType}`
    }

    return null;
}

const validateItem = (errors: string[], item: Assignment, details: AssignmentItemDetails, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, dataType: string, reportFieldId: number | null, allowDoNotPopulate: boolean): void => {

    let valueError = validateValue(item, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, dataType, reportFieldId, allowDoNotPopulate);
    if (valueError) {
        errors.push(valueError);
    }

    if (!details.isSingle && !details.isElse) {

        conditionValidator.validate(item.condition as Condition, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, true, reportFieldId).forEach(x => errors.push(x));
    }
};

const validate = (declaration: Declaration, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, dataType: string, reportFieldId: number | null, allowDoNotPopulate: boolean): void => {

    let items = assignmentHelper.asCollection(declaration.assignment);
    let details = items.map(assignmentItemHelper.getDetails);

    items.forEach((x, i) => {

        validateItem(declaration.errors, x, details[i], functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, dataType, reportFieldId, allowDoNotPopulate);
    });
};

const declarationValidator = {
    validate
};

export default declarationValidator;
