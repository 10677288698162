import { htmlIds } from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation.HtmlIds';
import ObjectIndexer from '../../../infrastructure/types/ObjectIndexer';

const getDefaultExpanded = (): ObjectIndexer<boolean> => {

    let result = {} as ObjectIndexer<boolean>;

    result[htmlIds.dataSources] = Boolean(localStorage.getItem(htmlIds.dataSources));
    result[htmlIds.cacheKeys] = Boolean(localStorage.getItem(htmlIds.cacheKeys));
    result[htmlIds.lookups] = Boolean(localStorage.getItem(htmlIds.lookups));
    result[htmlIds.variables] = Boolean(localStorage.getItem(htmlIds.variables));
    result[htmlIds.aggregations] = Boolean(localStorage.getItem(htmlIds.aggregations));
    result[htmlIds.filters] = Boolean(localStorage.getItem(htmlIds.filters));
    result[htmlIds.endpointFilters] = Boolean(localStorage.getItem(htmlIds.endpointFilters));
    result[htmlIds.expectedUnderReport] = Boolean(localStorage.getItem(htmlIds.expectedUnderReport));
    result[htmlIds.expectedOverReport] = Boolean(localStorage.getItem(htmlIds.expectedOverReport));
    result[htmlIds.cases] = Boolean(localStorage.getItem(htmlIds.cases));
    result[htmlIds.matchingKeys] = Boolean(localStorage.getItem(htmlIds.matchingKeys));
    result[htmlIds.enrichment] = Boolean(localStorage.getItem(htmlIds.enrichment));
    result[htmlIds.validations] = Boolean(localStorage.getItem(htmlIds.validations));
    result[htmlIds.import] = Boolean(localStorage.getItem(htmlIds.import));

    return result;
};

const removeExpandedFromLocalStorage = (): void => {

    localStorage.removeItem(htmlIds.dataSources)
    localStorage.removeItem(htmlIds.cacheKeys)
    localStorage.removeItem(htmlIds.lookups)
    localStorage.removeItem(htmlIds.variables)
    localStorage.removeItem(htmlIds.filters)
    localStorage.removeItem(htmlIds.endpointFilters)
    localStorage.removeItem(htmlIds.expectedUnderReport)
    localStorage.removeItem(htmlIds.expectedOverReport)
    localStorage.removeItem(htmlIds.cases)
    localStorage.removeItem(htmlIds.matchingKeys)
    localStorage.removeItem(htmlIds.enrichment)
    localStorage.removeItem(htmlIds.validations)
    localStorage.removeItem(htmlIds.import)
};

const keyword = {
    if: 'IF',
    lookup: 'LOOKUP',
    exclude: 'THEN EXCLUDE RECORD',
    report: 'THEN REPORT RECORD',
    underReport: 'THEN RECORD IS EXPECTED UNDER REPORT',
    overReport: 'THEN RECORD IS EXPECTED OVER REPORT'
};

export { getDefaultExpanded, keyword, removeExpandedFromLocalStorage };
