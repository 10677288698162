import ArgumentDefinition from '../../../../types/functions/ArgumentDefinition';
import FunctionDefinition from '../../../../types/functions/FunctionDefinition';
import functionDefinitions from '../../../constants/functions/definition/functionDefinitions';
import typeHelper from '../../common/typeHelper';

const getFunctionDefinition = (functionName: string): FunctionDefinition => {

    let definition = functionDefinitions.find(x => x.name === functionName) as FunctionDefinition;

    if (!typeHelper.isObject(definition)) {

        throw new Error(`Invalid function name "${functionName}"`);
    }

    return definition;
};

const getArgumentDefinitionByIndex = (definition: FunctionDefinition, i: number): ArgumentDefinition => {

    if (i < definition.arguments.length) {

        return definition.arguments[i];
    }

    let last = definition.arguments[definition.arguments.length - 1];
    if (last.isArray) {

        return last;
    }

    throw new RangeError('Argument index is out of range');
};

export { getFunctionDefinition, getArgumentDefinitionByIndex };