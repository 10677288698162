import lockManager from "../../../infrastructure/locking/lockManager";
import IdNameAndNumber from "../../../types/report/IdNameAndNumber";
import DragAndDropError from "../../../types/report/DragAndDropError";
import ComponentType from "../../../types/report/ComponentType";
import ActionType from "../../../types/report/ActionType";
import { ToastOptions, toast } from "react-toastify";



const showMesage = (message: string, isStatic?: boolean): void => {

    const toastOptions: ToastOptions = {
        position: 'top-right',
        autoClose: isStatic ? false : 10000,
        closeOnClick: true,
        bodyStyle: { margin: '5px', whiteSpace: 'pre-line', fontSize: '14px' },
        delay: 0
    };

    toast.error(message, toastOptions);
}

const validateAction = async (number: number, isInsertAfter: boolean, collection: IdNameAndNumber[], componentType: ComponentType, reportConfigId: number, action: ActionType) => {

    const selectedIndex = collection.findIndex(v => v.number === number);
    const startIndex = isInsertAfter ? selectedIndex + 1 : selectedIndex;
    const componentsIds: number[] = [];

    const selectedItemName = collection[selectedIndex].name;

    for (var i = startIndex; i < collection.length; i++) {
        componentsIds.push(collection[i].id || 0);
    }

    const result = await lockManager.isAnyComponentLocked({ componentType, reportConfigId, componentsIds });
    if (result.lockedBy) {
        const id = parseInt(result.lockedBy);
        const lockedItemName = collection.find(v => v.id === id)?.name || '';
        return `Cannot ${action} ${selectedItemName} because ${componentType} ${lockedItemName} with ID: ${id} is locked.`;
    }

    return '';
}


const validateDragAndDrop = <T>(dragIndex: number, dropIndex: number, draggedItem: IdNameAndNumber, dropItem: IdNameAndNumber, collection: T[],
    getReferences: (dragIndex: number, dropIndex: number, collection: T[]) => Set<number>): DragAndDropError=> {

    const error = 'Cannot drag and drop. Remove reference first.';
    let result: DragAndDropError = { error: '', isDrag: false, number: 0 };
    const startIndex = Math.min(dragIndex, dropIndex);
    const endIndex = Math.max(dragIndex, dropIndex);

    const dragReferences = getReferences(dragIndex, dragIndex + 1, collection);
    const dropReferences = getReferences(dropIndex, dropIndex + 1, collection);
    const otherReferences = getReferences(startIndex + 1, endIndex, collection);

    dragReferences.forEach((number) => {
        if (otherReferences.has(number) || dropReferences.has(number) || dropItem.number === number) {
            result.error = error;
            result.isDrag = true;
            result.number = number;
        }
    });
    if (!result.error) {
        dropReferences.forEach((number) => {
            if (otherReferences.has(number) || dragReferences.has(number) || draggedItem.number === number) {
                result.error = error;
                result.isDrag = false;
                result.number = number;
            }
        });
    }
    if (!result.error) {
        otherReferences.forEach((number) => {
            if (dragReferences.has(number) || draggedItem.number === number || dropReferences.has(number) || dropItem.number === number) {
                result.error = error;
                result.isDrag = false;
                result.number = number;
            }
        });
    }

    return result;
}

const reportComponentBuildersHelper = {
    validateAction,
    validateDragAndDrop,
    showMesage
}

export default reportComponentBuildersHelper;