import functionName from './functionName';

const aggregationFunctions = [
    functionName.first,
    functionName.firstOrdered,
    functionName.last,
    functionName.lastOrdered,
    functionName.min,
    functionName.max,
    functionName.sumAggregate,
    functionName.count,
    functionName.countHasValue,
    functionName.countDistinct,
    functionName.join,
    functionName.average
];

export default aggregationFunctions;
