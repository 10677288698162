import ReportFilter from '../../../types/report/ReportFilter';
import StatementHelper from '../../types/Functions/StatementHelper';

class FilterHelper extends StatementHelper<ReportFilter> {

    getDataSourceId(filter: ReportFilter): number | null {

        return filter.dataSourceId;
    }

    getEmpty(rawDataSourceId: number): ReportFilter {

        return {
            id: null,
            dataSourceId: rawDataSourceId,
            number: 0,
            condition: null as any,
            name: '',
            businessDescription: '',
            isBespoke: false,
            bespokeCondition: '',
            errors: []
        };
    }
}

const filterHelper = new FilterHelper();

export default filterHelper;
