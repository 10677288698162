import { endpointFilterTypeDisplayMap } from '../../../constants/report/endpointFilterType';
import bespokeValidator from './bespokeValidator';
import clear from '../../common/array/clear';
import conditionValidator from './conditionValidator';
import EndpointFilter from '../../../../types/report/EndpointFilter';
import Lookup from '../../../../types/functions/Lookup';
import ReportCollections from '../../../types/Functions/ReportCollections';
import stringHelper from '../../common/stringHelper';
import uniquenessValidator from './uniquenessValidator';
import ReportVariable from '../../../../types/report/ReportVariable';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const maxNameCharacters = 50;

const validateFilter = (filter: EndpointFilter, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], collections: ReportCollections, name: string): void => {

    clear(filter.errors);

    let metadataError = validateMetadata(filter, name);
    if (metadataError) {
        filter.errors.push(metadataError);
    }

    if (!filter.isBespoke) {

        Array.prototype.push.apply(filter.errors, conditionValidator.validate(filter.condition, functions, lookups, dictionaries, variables, collections.dataSources, collections.reportFields, null as any, true, null));
        return;
    }

    let bespokeError = bespokeValidator.validateCondition(filter.bespokeCondition);
    if (bespokeError) {
        filter.errors.push(bespokeError);
    }
};

const validateMetadata = (filter: EndpointFilter, name: string): string | null => {

    if (stringHelper.isEmpty(filter.name)) {
        return `${name} name is required`;
    }

    if (filter.name.length > maxNameCharacters) {
        return `${name} name max character count is ${maxNameCharacters}`;
    }

    return null;
};

const validate = (filters: EndpointFilter[], functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], collections: ReportCollections, type: string): string | null => {

    filters.forEach(x => validateFilter(x, functions, lookups, dictionaries, variables, collections, type));

    uniquenessValidator.validate(filters, f => f.name, type, 'name');

    let valid = filters.every(x => x.errors.length === 0);

    return valid ? null : `Invalid ${type.toLowerCase()}(s)`;
};

const validateByType = (filters: EndpointFilter[], functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], collections: ReportCollections, type: string, variables: ReportVariable[] = []): string | null => {

    return validate(filters.filter(x => x.type === type), functions, lookups, dictionaries, variables, collections, endpointFilterTypeDisplayMap[type]);
};

const endpointFilterValidator = {
    validateByType
};

export default endpointFilterValidator;