import { AxiosResponse } from 'axios';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import StringReplacement from '../../../../infrastructure/types/StringReplacement';

const readCsv = (file: File): Promise<AxiosResponse<StringReplacement[]>> => {

    let data = new FormData();

    data.append('file', file);

    return httpClient.postFile('ingestion/read-replacements', data)
};

const buildCsvContent = (replacements: StringReplacement[]): string => {

    let lines = ['Before,After'];

    replacements.forEach(x => lines.push(`"${x.before}","${x.after}"`));

    return lines.join('\n');
};

export { readCsv, buildCsvContent };
