import { createEmptyOption, createSelectContent } from './SelectList.Helpers';
import React from 'react';
import SelectOption from '../../../infrastructure/types/SelectOption';

interface SelectListProps {
    options: SelectOption[];
    value: string;
    onChange: (value: string, e: React.ChangeEvent<HTMLElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    disabled?: boolean;
    includeEmptyOption?: boolean;
}

class SelectList extends React.Component<SelectListProps> {
    constructor(props: SelectListProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(e: React.ChangeEvent<HTMLSelectElement>): void {
        this.props.onChange(e.target.value, e);
    }

    render(): JSX.Element {

        let prependOptions = this.props.includeEmptyOption ? [createEmptyOption()] : [];

        return (
            <select
                onChange={this.onChange}
                onClick={this.props.onClick && this.props.onClick}
                value={this.props.value}
                className={this.props.className}
                disabled={this.props.disabled}>
                {prependOptions.concat(createSelectContent(this.props.options))}
            </select>
        );
    }
}

export default SelectList;