import { endpointFilterTypeDisplayMap } from '../../../constants/report/endpointFilterType';
import { reportType } from '../../../constants/reportType';
import assignmentOperandHelper from '../assignment/assignmentOperandHelper';
import conditionHelper from '../condition/conditionHelper';
import dataSourceHelper from '../common/dataSourceHelper';
import isin from '../../../constants/functions/isin';
import lei from '../../../constants/functions/lei';
import matchingKey from '../../../constants/functions/matchingKey';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../operand/operandHelper';
import ReportCase from '../../../../types/report/ReportCase';
import ReportCollections from '../../../types/Functions/ReportCollections';
import ReportConfig from '../../../../types/report/ReportConfig';
import reportConfigNavigation from '../../report/navigation/reportConfigNavigation';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import selectMany from '../../common/array/selectMany';
import SelectOption from '../../../types/SelectOption';
import stringHelper from '../../common/stringHelper';
import matchingKeyEndpoint from '../../../constants/functions/matchingKeyEndPoint';
import typeHelper from '../../common/typeHelper';
import exchangeRates from '../../../constants/functions/exchangeRates';
import annaDsbUpiEnrichment from '../../../constants/functions/annaDsbUpiEnrichment';
import annaDsbUpi from '../../../constants/functions/annaDsbUpi';
import lse from '../../../constants/functions/lse';
import firdsFcaInstrument from '../../../constants/functions/firdsFcaInstrument';
import firdsEsmaInstrument from '../../../constants/functions/firdsEsmaInstrument';
import fcaRegulatedEntities from '../../../constants/functions/fcaRegulatedEntities';

const isFieldReferenced = (fieldId: number, operand: Operand): boolean => {

    return operand.reportFieldId === fieldId;
};

const buildName = (type: string, name: string, fallback?: string): string => {

    return `${type} ${stringHelper.isNonEmpty(name) ? `'${name}'` : fallback}`;
};

const getReferences = (config: ReportConfig, collections: ReportCollections, isReferenced: (o: Operand) => boolean): SelectOption[] => {

    let result: SelectOption[] = [];

    config.lookups.forEach(l => {

        if (conditionHelper.getAllOperands(l.condition).some(isReferenced)) {

            result.push({ text: buildName('Lookup', l.name, l.number.toString()), value: reportConfigNavigation.buildLookupId(l.number) });
        }
    });

    config.isinSelectors.forEach(x => {

        if (assignmentOperandHelper.getAll(x.assignment).some(isReferenced)) {

            result.push({ text: buildName(isin.name, x.number.toString()), value: reportConfigNavigation.buildIsinSelectorId(x.number) });
        }
    });

    config.leiSelectors.forEach(x => {

        if (assignmentOperandHelper.getAll(x.assignment).some(isReferenced)) {

            result.push({ text: buildName(lei.name, x.number.toString()), value: reportConfigNavigation.buildLeiSelectorId(x.number) });
        }
    });

    config.exchangeRatesSelectors.forEach(x => {

        if (assignmentOperandHelper.getAll(x.assignment).some(isReferenced)) {

            result.push({ text: buildName(exchangeRates.name, x.number.toString()), value: reportConfigNavigation.buildExchangeRatesSelectorId(x.number) });
        }
    });

    config.annaDsbUpiEnrichmentSelectors.forEach(x => {

        if (assignmentOperandHelper.getAll(x.assignment).some(isReferenced)) {

            result.push({ text: buildName(annaDsbUpiEnrichment.name, x.number.toString()), value: reportConfigNavigation.buildAnnaDsbUpiEnrichmentSelectorId(x.number) });
        }
    });

    config.lseSelectors.forEach(x => {

        if (assignmentOperandHelper.getAll(x.assignment).some(isReferenced)) {

            result.push({ text: buildName(lse.name, x.number.toString()), value: reportConfigNavigation.buildLSESelectorId(x.number) });
        }
    });

    config.fcaRegulatedEntitiesSelectors.forEach(x => {

        if (assignmentOperandHelper.getAll(x.assignment).some(isReferenced)) {

            result.push({ text: buildName(fcaRegulatedEntities.name, x.number.toString()), value: reportConfigNavigation.buildFCARegulatedEntitiesSelectorId(x.number) });
        }
    });

    config.firdsFcaInstrumentSelectors.forEach(x => {

        if (assignmentOperandHelper.getAll(x.assignment).some(isReferenced)) {

            result.push({ text: buildName(firdsFcaInstrument.name, x.number.toString()), value: reportConfigNavigation.buildFirdsFcaInstrumentSelectorId(x.number) });
        }
    });

    config.firdsEsmaInstrumentSelectors.forEach(x => {

        if (assignmentOperandHelper.getAll(x.assignment).some(isReferenced)) {

            result.push({ text: buildName(firdsEsmaInstrument.name, x.number.toString()), value: reportConfigNavigation.buildFirdsEsmaInstrumentSelectorId(x.number) });
        }
    });

    config.annaDsbUpiSelectors.forEach(x => {

        if (assignmentOperandHelper.getAll(x.assignment).some(isReferenced)) {

            result.push({ text: buildName(annaDsbUpi.name, x.number.toString()), value: reportConfigNavigation.buildAnnaDsbUpiSelectorId(x.number) });
        }
    });

    config.variables.forEach(v => {

        if (assignmentOperandHelper.getAll(v.assignment).some(isReferenced)) {

            result.push({ text: buildName('Variable', v.name, v.number.toString()), value: reportConfigNavigation.buildVariableId(v.number) });
        }
    });

    config.validations.forEach(v => {

        if (assignmentOperandHelper.getAll(v.assignment).some(isReferenced)) {

            result.push({ text: buildName('Validation', v.identifier, v.number.toString()), value: reportConfigNavigation.buildValidationId(v.number, v.kind[0].toLowerCase()) });
        }
    });

    if (config.type === reportType.accuracy) {

        return result;
    }

    config.aggregations.forEach(a => {

        if (selectMany(a.groupBy, o => operandHelper.asCollection(o)).some(isReferenced)) {

            result.push({ text: buildName('Aggregation', a.name, a.number.toString()), value: reportConfigNavigation.buildAggregationId(a.number) });
        }

        a.fields.forEach(f => {

            if (assignmentOperandHelper.getAll(f.assignment).some(isReferenced)) {

                let definition = dataSourceHelper.getFieldDefinitionById(collections.dataSources, f.fieldId);

                result.push({ text: buildName(`Aggregation ${a.name } - Field`, definition.name), value: reportConfigNavigation.buildAggregatedRecordFieldId(f.fieldId, a.number) });
            }
        });
    });

    config.filters.forEach(f => {

        if (f.isBespoke) {
            return;
        }

        if (conditionHelper.getAllOperands(f.condition).some(isReferenced)) {

            result.push({ text: buildName('Filter', f.name, f.number.toString()), value: reportConfigNavigation.buildFilterId(f.number) });
        }
    });

    config.endpointFilters.forEach(f => {

        if (f.isBespoke) {
            return;
        }

        if (conditionHelper.getAllOperands(f.condition).some(isReferenced)) {

            result.push({
                text: buildName(endpointFilterTypeDisplayMap[f.type], f.name, f.number.toString()),
                value: reportConfigNavigation.buildEndpointFilterId(f)
            });
        }
    });

    config.cases.forEach(c => {

        if (c.isBespoke) {
            return;
        }

        if (conditionHelper.getAllOperands(c.condition).some(isReferenced)) {

            result.push({ text: buildName('Case', c.name, c.number.toString()), value: reportConfigNavigation.buildCaseId(c.number) });
        }

       c.fields.forEach(f => {

            if (assignmentOperandHelper.getAll(f.assignment).some(isReferenced)) {

                let definition = collections.reportFields.find(x => x.id === f.fieldId) as ReportFieldDefinition;

                result.push({ text: buildName(`Case ${c.name} - Field`, definition.name), value: reportConfigNavigation.buildReportFieldId(definition.number,c.number) });
            }
        });
    });

   

    return result;
};

const getFieldReferences = (fieldId: number, caseNumber: number, config: ReportConfig, reportFields: ReportFieldDefinition[]): SelectOption[] => {

    let result: SelectOption[] = [];

    let $case = config.cases.find(c => c.number === caseNumber) as ReportCase;

    $case.fields.forEach(f => {

        if (assignmentOperandHelper.getAll(f.assignment).some(o => isFieldReferenced(fieldId, o))) {

            let definition = reportFields.find(x => x.id === f.fieldId) as ReportFieldDefinition;

            result.push({ text: definition.name, value: reportConfigNavigation.buildReportFieldId(definition.number) });
        }
    });

    config.endpointFilters.forEach(f => {

        if (f.isBespoke) {
            return;
        }

        if (conditionHelper.getAllOperands(f.condition).some(o => isFieldReferenced(fieldId, o))) {

            result.push({
                text: buildName(endpointFilterTypeDisplayMap[f.type], f.name, f.number.toString()),
                value: reportConfigNavigation.buildEndpointFilterId(f)
            });
        }
    });

    if (config.matchingKey.operands.length > 0) {

        if (selectMany(config.matchingKey.operands, o => operandHelper.asCollection(o)).some(o => isFieldReferenced(fieldId, o))) {

            result.push({ text: matchingKey.name, value: reportConfigNavigation.matchingKeyId });
        }
    } else if (typeHelper.isObject(config.matchingKey.assignment)) {

        if (assignmentOperandHelper.getAll(config.matchingKey.assignment).some(o => isFieldReferenced(fieldId, o))) {

            result.push({ text: matchingKey.name, value: reportConfigNavigation.matchingKeyId });
        }
    }

    if (config.matchingKeyEndPoint.operands.length > 0) {

        if (selectMany(config.matchingKeyEndPoint.operands, o => operandHelper.asCollection(o)).some(o => isFieldReferenced(fieldId, o))) {

            result.push({ text: matchingKeyEndpoint.name, value: reportConfigNavigation.matchingKeyEndPointId });
        }
    } else if (typeHelper.isObject(config.matchingKeyEndPoint.assignment)) {

        if (assignmentOperandHelper.getAll(config.matchingKeyEndPoint.assignment).some(o => isFieldReferenced(fieldId, o))) {

            result.push({ text: matchingKeyEndpoint.name, value: reportConfigNavigation.matchingKeyEndPointId });
        }
    }
   

    return result;
};

const getLookupReferences = (lookupNumber: number, config: ReportConfig, collections: ReportCollections): SelectOption[] => {

    return getReferences(config, collections, o => o.lookupNumber === lookupNumber);
};

const getVariableReferences = (variableNumber: number, config: ReportConfig, collections: ReportCollections): SelectOption[] => {

    return getReferences(config, collections, o => o.variableNumber === variableNumber);
};

const reportLogicReferenceHelper = {
    getLookupReferences,
    getVariableReferences,
    getFieldReferences,
    buildName
};

export default reportLogicReferenceHelper;
