import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import FunctionOperand from './FunctionOperand';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import React from 'react';
import operandType from '../../../../infrastructure/constants/functions/operandType';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import operandDisplayHelper from '../../../../infrastructure/helpers/functions/operand/operandDisplayHelper';
import reportConfigNavigation from '../../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import Lookup from '../../../../types/functions/Lookup';
import Operand from '../../../../types/functions/Operand/Operand';
import OperandDetails from '../../../../types/functions/Operand/OperandDetails';
import Dictionary from '../../../../types/report/Dictionary';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import getDictionaryOperand from './getDictionaryOperand';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import getUserDefinedFunctionOperand from './getUserDefinedFunctionOperand';

const getOperandButtonText = (operand: OperandDetails): string => {
    if (!operand.isSetUp) {
        return '<not set>';
    }

    return operand.displayText;
};

const getOperandButtonClasses = (operand: OperandDetails): string => {
    let classes = ['transparent-button', 'code'];

    if (operand.isSetUp) {
        classes.push(operand.operandType.toLowerCase());
    }
    else {
        classes.push('instruction');
    }

    return classes.join(' ');
};

const getOperandButton = (
    operand: Operand,
    tooltip: string | null,
    functions: UserDefinedFunction[],
    lookups: Lookup[],
    dictionaries: Dictionary[],
    variables: ReportVariable[],
    dataSources: IngestionConfig[],
    reportFields: ReportFieldDefinition[],
    customDataSourceFields: CustomDataSourceFields,
    onClick: (event: React.MouseEvent<HTMLElement>) => void,
    disabled: boolean
): JSX.Element => {

    let details = operandDisplayHelper.getOperandDetails(operand, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, null);

    return (
        <>
            <button className={getOperandButtonClasses(details)} onClick={onClick} title={tooltip as string} disabled={disabled}>
                {getOperandButtonText(details)}
            </button>
            {
                typeHelper.isString(details.definitionHtmlId) &&
                <button className="transparent-button" onClick={() => reportConfigNavigation.navigateToItem(details.definitionHtmlId as string)} title="Go to definition">*</button>
            }
        </>
    );
};

const getOperandContent = (
    operand: Operand,
    operandNumber: number,
    argumentIndexes: number[],
    tooltip: string | null,
    functions: UserDefinedFunction[],
    lookups: Lookup[],
    dictionaries: Dictionary[],
    variables: ReportVariable[],
    dataSources: IngestionConfig[],
    reportFields: ReportFieldDefinition[],
    customDataSourceFields: CustomDataSourceFields,
    selectorNumber: number | null,
    onClick: (operandNumber: number, argumentIndexes: number[], event: React.MouseEvent<HTMLElement>) => void,
    disabled: boolean
): JSX.Element => {

    if (operand.operandType === operandType.function) {

        return <FunctionOperand
            operand={operand}
            operandNumber={operandNumber}
            argumentIndexes={argumentIndexes}
            functions={functions}
            lookups={lookups}
            dictionaries={dictionaries}
            variables={variables}
            dataSources={dataSources}
            reportFields={reportFields}
            customDataSourceFields={customDataSourceFields}
            selectorNumber={selectorNumber}
            onClick={(operandNumber: number, argumentIndexes: number[], event: React.MouseEvent<HTMLElement>) => { onClick(operandNumber, argumentIndexes, event) }}
            disabled={disabled }
            />
    }

    if (operand.operandType === operandType.dictionary) {

        return getDictionaryOperand(operand, operandNumber, argumentIndexes, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, selectorNumber, (number, argIndexes, event) => { onClick(number, argIndexes, event) }, disabled);
    }

    if (operand.operandType === operandType.userDefinedFunction) {

        return getUserDefinedFunctionOperand(operand, operandNumber, argumentIndexes, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, selectorNumber, (number, argIndexes, event) => { onClick(number, argIndexes, event) }, disabled);
    }

    return getOperandButton(operand, tooltip, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, (event) => { onClick(operandNumber, argumentIndexes, event) }, disabled);
};

export {
    getOperandContent
};
