import { getChangeDataTypeMessage, getRemoveMessage, findNotUniqueIndex } from './FieldDefinitions.Helpers';
import { getColumns } from './FieldDefinitions.Grid';
import { Unsubscribe } from 'redux';
import actions from '../../../store/actions';
import dataSourceHelper from '../../../infrastructure/helpers/functions/common/dataSourceHelper';
import dateTimeFormatHelper from '../../../infrastructure/helpers/common/dateTimeFormatHelper';
import FieldDefinition from '../../../types/common/FieldDefinition';
import FieldDefinitionProps from './FieldDefinitionProps';
import IngestionReplacementModal from './IngestionReplacementModal/IngestionReplacementModal';
import IngestionReplacementModalState from './IngestionReplacementModal/IngestionReplacementModalState';
import React from 'react';
import reorderHelper from '../../../infrastructure/helpers/common/reorderHelper';
import store from '../../../store/store';
import systemFieldHelper from '../../../infrastructure/helpers/ingestion/systemFieldHelper';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import UiDataGrid from '../../Common/DataGrid/UiDataGrid/UiDataGrid';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';
import Modal from '../../Common/Modal/Modal';
import ModalStateBase from '../../Common/Modal/ModalStateBase';
import InfoIcon from '../../Common/InfoIcon/InfoIcon';
import guidance from './FieldDefinitions.Guidance';
import EditableText from '../../Common/EditableText/EditableText';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';

interface FieldDefinitionState {
    showWarningModal: boolean;
    number: number;
    changes: FieldDefinition;
    callbackFunction?: () => void;
    message: string;
    title: string;
    showNamePopup: boolean;
    modalState: ModalStateBase;
    definitionName: string;
    showFunctionsModal: boolean;
    definition: FieldDefinition;
    index: number;
    addFieldTitle: string;
    showCommentModal: boolean;
    comment: string;
    showReferencesModal: boolean;
    references: string[];
}

class FieldDefinitions extends React.Component<FieldDefinitionProps, FieldDefinitionState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: FieldDefinitionProps) {

        super(props);

        this.state = {
            showWarningModal: false,
            number: 0,
            changes: {} as FieldDefinition,
            callbackFunction: () => { },
            message: '',
            title: '',
            showNamePopup: false,
            addFieldTitle: 'Add Field',
            modalState: {
                title: '',
                isOpen: false,
                error: ''
            },
            showFunctionsModal: false,
            definition: {} as FieldDefinition,
            index: 0,
            definitionName: '',
            showCommentModal: false,
            comment: '',
            showReferencesModal: false,
            references: []
        }

        this.replacementChangeListener = this.replacementChangeListener.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onReplacementClick = this.onReplacementClick.bind(this);
        this.reorder = this.reorder.bind(this);
        this.onChangeAccepted = this.onChangeAccepted.bind(this);
        this.onRemoveConfirmed = this.onRemoveConfirmed.bind(this);
        this.onShowFunctionClick = this.onShowFunctionClick.bind(this);
        this.onCommentClick = this.onCommentClick.bind(this);
        this.onSaveCommentClick = this.onSaveCommentClick.bind(this);
        this.getReferences = this.getReferences.bind(this);
        this.onShowReferencesClick = this.onShowReferencesClick.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.replacementChangeListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    replacementChangeListener(): void {
        let state = store.getState();

        if (state.action !== actions.ingestionReplacementModal.save) {
            return;
        }

        let definition = this.props.definitions[state.ingestionReplacementModalState.index];

        let replacements = state.ingestionReplacementModalState.replacements.length > 0 ? state.ingestionReplacementModalState.replacements : null;

        Object.assign(definition, { replacements: replacements } as FieldDefinition);

        this.props.onChange(this.props.definitions);
    }

    onAddClick(): void {

        this.setState({ showNamePopup: true, modalState: { title: this.state.addFieldTitle, isOpen: true, error: '' } });
    }

    onChange(i: number, changes: FieldDefinition, isDataTypeChange?: boolean): void {

        let definitionName = this.props.definitions.filter(x => !systemFieldHelper.isSystemField(x.name))[i].name;

        let definition = this.props.definitions.find(x => x.name === definitionName) as FieldDefinition;

        let definitionIndex = this.props.definitions.findIndex(x => x.name === definitionName);

        if (isDataTypeChange) {

            this.setState({ changes, number: definitionIndex, showWarningModal: true, callbackFunction: this.onChangeAccepted, message: getChangeDataTypeMessage(definition, changes.type), title: 'Change field' });
        }
        else {
            Object.assign(this.props.definitions[definitionIndex], changes);

            this.props.onChange(this.props.definitions);
        }
    }

    onChangeAccepted() {

        this.props.definitions[this.state.number].formats = [dateTimeFormatHelper.getDefaultFormat(this.state.changes.type)];
        this.props.definitions[this.state.number].type = this.state.changes.type;

        this.props.onChange(this.props.definitions);

        this.setState({ showWarningModal: false });
    }

    onRemoveConfirmed() {

        this.props.definitions.splice(this.state.number, 1);

        this.props.onChange(this.props.definitions);

        this.setState({ showWarningModal: false });
    }

    onRemoveClick(i: number): void {

        let definition = this.props.definitions.filter(x => !systemFieldHelper.isSystemField(x.name))[i];

        let index = this.props.definitions.findIndex(x => x.name === definition.name);

        this.setState({ message: getRemoveMessage(definition), showWarningModal: true, number: index, callbackFunction: this.onRemoveConfirmed, title: 'Remove field' });
    }

    onReplacementClick(i: number): void {

        this.setState({ showFunctionsModal: false });

        let definition = this.props.definitions[i];

        let state: IngestionReplacementModalState = {
            title: `${definition.name} replacements`,
            isOpen: true,
            error: null,
            index: i,
            replacements: definition.replacements || []
        };

        store.dispatch({ type: actions.ingestionReplacementModal.open, payload: state });
    }

    reorder(dragIndex: number, dropIndex: number): void {

        reorderHelper.reorder(this.props.definitions, dragIndex, dropIndex);

        this.props.onChange(this.props.definitions);
    }

    onSaveNewField() {

        if (this.state.definitionName === '') {
            this.setState({ modalState: { title: this.state.addFieldTitle, isOpen: true, error: 'The name cannot be empty.' } })
        }
        else {
            let doesNameExists = findNotUniqueIndex(this.state.definitionName, this.props.definitions) >= 0;

            if (doesNameExists) {

                this.setState({ modalState: { title: this.state.addFieldTitle, isOpen: true, error: 'There is already a field with this name.' } })
            }
            else {
                this.setState({ showNamePopup: false });

                let definition = dataSourceHelper.getEmptyFieldDefinition();

                definition.name = this.state.definitionName;

                this.props.definitions.push(definition);


                this.props.onChange(this.props.definitions);

            }
        }
    }

    onShowFunctionClick(definition: FieldDefinition, index: number): void {
        this.setState({ showFunctionsModal: true, definition, index, modalState: { title: 'Functions', isOpen: true, error: '' } });
    }

    onCommentClick(definition: FieldDefinition, index: number): void {
        this.setState({ showCommentModal: true, definition, index, modalState: { title: 'Comment', isOpen: true, error: '' } });
    }

    onSaveCommentClick() {
        this.setState({ showCommentModal: false });

        this.onChange(this.state.index, { comment: this.state.comment } as FieldDefinition);
    }


    async getReferences(id: number, fieldId: number): Promise<string[]> {

        let route = urlHelper.buildRoute(['ingestion', id, 'references', fieldId]);

        let response = await httpClient.get<string[]>(route);

        return response.data;
    };

    async onShowReferencesClick(definition: FieldDefinition, index: number) {

        let references = await this.getReferences(this.props.ingestionConfigId || 0, definition.id || 0);

        this.setState({ showReferencesModal: true, definition, index, modalState: { title: 'References', isOpen: true, error: '' }, references });
    }

    render(): JSX.Element {
        return (
            <>
                <UiDataGrid
                    columns={getColumns(this.props.isReadOnly,
                        this.onChange,
                        this.onRemoveClick,
                        this.reorder,
                        this.props.definitions,
                        this.onShowFunctionClick,
                        this.onCommentClick,
                        this.onShowReferencesClick)}
                    data={this.props.definitions.filter(x => !systemFieldHelper.isSystemField(x.name))}
                    getKey={(x, i) => `${i}-${x.name}`}
                    tableClassName='ingestion' />
                {
                    !this.props.isReadOnly &&
                    <div className="mt-4">
                        <button type="button" className="btn btn-light" onClick={this.onAddClick}>+ Field Definition</button>
                    </div>
                }

                <IngestionReplacementModal />
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.state.callbackFunction}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title={this.state.title}
                        message={this.state.message} />
                }
                {this.state.showNamePopup &&
                    <Modal
                        state={this.state.modalState}
                        close={() => { this.setState({ showNamePopup: false }) }}
                    >
                        <div className="display-flex justify-content-between margin-top-20px margin-bottom-10px">
                            <div className="col-form-label padding-right-15px">
                                <label>Name</label>
                            </div>
                            <div className="width-100-percents">
                                <input type="text" className="form-control" onBlur={(e) => { this.setState({ definitionName: e.target.value }); }} />
                            </div>
                        </div>
                        <div className='display-flex align-center justify-content-start margin-top-30-px'>
                            <button className='btn cb-btn width-75px' onClick={() => { this.onSaveNewField(); }}>Save</button>
                            <button className='btn btn-light' onClick={() => { this.setState({ showNamePopup: false }) }}>Cancel</button>
                        </div>
                    </Modal>
                }
                {this.state.showFunctionsModal &&
                    <Modal
                        state={this.state.modalState}
                        close={() => { this.setState({ showFunctionsModal: false }) }}
                    >
                        <div className='display-flex align-items-center margin-top-10px'>
                            <InfoIcon info={guidance.trim as string} />
                            <label className='margin-0'>Trim</label>
                            <input
                                className='margin-left-10px'
                                type="checkbox"
                                checked={this.state.definition.trim}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChange(this.state.index, { trim: e.target.checked } as FieldDefinition)}
                                disabled={this.props.isReadOnly} />
                        </div>
                        <div className='display-flex align-items-center margin-top-10px'>
                            <InfoIcon info={guidance.mandatory as string} />
                            <label className='margin-0'>Mandatory</label>
                            <input
                                className='margin-left-10px'
                                type="checkbox"
                                checked={this.state.definition.isRequired}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChange(this.state.index, { isRequired: e.target.checked } as FieldDefinition)}
                                disabled={this.props.isReadOnly} />
                        </div>
                        <div className='display-flex align-items-center margin-top-10px'>
                            <InfoIcon info={guidance.replacements as string} />
                            <label className='margin-0'>Replacements</label>
                            <button className="transparent-button" onClick={() => this.onReplacementClick(this.state.index)}>
                                {typeHelper.isArray(this.state.definition.replacements) ? 'Yes' : 'No'}
                            </button>
                        </div>
                    </Modal>
                }
                {this.state.showCommentModal &&
                    <Modal state={this.state.modalState} close={() => { this.setState({ showCommentModal: false }) }} size="medium">
                        <EditableText
                            disabled={this.props.isReadOnly}
                            value={this.state.definition.comment}
                            onChange={(comment) => this.setState({ comment })}
                            multiline={true} />

                        <button className="btn cb-btn" onClick={this.onSaveCommentClick} >Save</button>
                    </Modal>
                }
                {
                    this.state.showReferencesModal &&
                    <Modal state={this.state.modalState} close={() => { this.setState({ showReferencesModal: false }) }} size="large">
                            <ul className='selected-items'>
                            {this.state.references.map((reference) => {
                                return <li className='select-search-option' key={reference}>{reference}</li>
                            })
                            }
                        </ul>
                    </Modal>
                }
            </>
        );
    }
}

export default FieldDefinitions;
