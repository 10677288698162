import { getOperandContent } from './ConditionItem.Rendering';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import Operand from '../../../../types/functions/Operand/Operand';
import React from 'react';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const getArgument = (
    operand: Operand,
    operandNumber: number,
    argumentIndexes: number[],
    tooltip: string,
    functions: UserDefinedFunction[],
    lookups: Lookup[],
    dictionaries: Dictionary[],
    variables: ReportVariable[],
    dataSources: IngestionConfig[],
    reportFields: ReportFieldDefinition[],
    customDataSourceFields: CustomDataSourceFields,
    selectorNumber: number | null,
    onClick: (operandNumber: number, argumentIndexes: number[], event: React.MouseEvent<HTMLElement>) => void,
    i: number,
    addComma: boolean,
    disabled: boolean
): JSX.Element => {

    return (
        <span key={i}>
            {getOperandContent(operand, operandNumber, argumentIndexes, tooltip, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, selectorNumber, (number, argIndexes, event) => { onClick(number, argIndexes, event) }, disabled)}
            {addComma && <span className="code">,</span>}
        </span>
    );
};

export default getArgument;
