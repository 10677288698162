import { createDataSourceOption, lookupTypeOtions, endpointLookupOption } from './LookupBuilder.Helpers';
import { ReactComponent as RemoveIcon } from '../../Common/BootstrapIcons/close.svg';
import { reportType } from '../../../infrastructure/constants/reportType';
import BespokeToggleButton from '../../Functions/Bespoke/BespokeToggleButton';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import dataSourceHelper from '../../../infrastructure/helpers/functions/common/dataSourceHelper';
import EditableText from '../../Common/EditableText/EditableText';
import enpointLookupAdapter from '../../../infrastructure/helpers/functions/lookup/enpointLookupAdapter';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import LazySelectList from '../../Common/LazySelectList/LazySelectList';
import Lookup from '../../../types/functions/Lookup';
import React from 'react';
import ReportCollections from '../../../infrastructure/types/Functions/ReportCollections';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import SelectList from '../../Common/SelectList/SelectList';
import SelectOption from '../../../infrastructure/types/SelectOption';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import DraggableItem from '../../Common/DraggableItem/DraggableItem';

const getDataSource1Content = (lookup: Lookup, dataSources: IngestionConfig[], onDataSourceChange: (dataSourceId: string, lookupNumber: number) => void, type: string, isReadOnly: boolean, onLockSectionClick?: (number: number) => Promise<boolean>): JSX.Element | string => {

    let isRegular = !lookup.isBespoke;

    if ((isReadOnly || (lookup.condition.and || lookup.condition.or) || !isRegular) && lookup.dataSource1Id) {

        return dataSourceHelper.getById(dataSources, lookup.dataSource1Id as number).name;
    }

    let options = dataSources.map(createDataSourceOption);

    if (type !== reportType.directReporting) {

        options.push(endpointLookupOption);
    }

    const onChangeCheckIfLocked = async (value: string, number: number, event: React.ChangeEvent<HTMLElement>): Promise<void> => {

        const isLocked = onLockSectionClick && await onLockSectionClick(number);
        if (!isLocked) {
            onDataSourceChange(value, number);
        }
    }

    return (
        <SelectList
            className="inline-select"
            disabled={isReadOnly || type === reportType.accuracy}
            options={options}
            value={enpointLookupAdapter.getValue(lookup)}
            onChange={(value, event) => { onLockSectionClick ? onChangeCheckIfLocked(value, lookup.number, event) : onDataSourceChange(value, lookup.number) }} />
    );
};

const getDataSource2Content = (lookup: Lookup, dataSources: IngestionConfig[], onDataSourceChange: (dataSourceId: string, lookupNumber: number) => void, isReadOnly: boolean, onLockSectionClick?: (number: number) => Promise<boolean>,): JSX.Element | string => {

    let isRegular = !lookup.isBespoke;

    if (lookup.dataSource2Id && (isReadOnly || (lookup.condition.and || lookup.condition.or) || !isRegular)) {

        return dataSourceHelper.getById(dataSources, lookup.dataSource2Id as number).name;
    }

    const onChangeCheckIfLocked = async (value: string, number: number, event: React.ChangeEvent<HTMLElement>): Promise<void> => {

        const isLocked = onLockSectionClick && await onLockSectionClick(number);
        if (!isLocked) {
            onDataSourceChange(value, number);
        }
    }

    return (
        <SelectList
            includeEmptyOption={isRegular}
            className="inline-select"
            options={dataSources.map(createDataSourceOption)}
            value={typeHelper.isNumber(lookup.dataSource2Id) ? (lookup.dataSource2Id as number).toString() : ''}
            onChange={(value, event) => { onLockSectionClick ? onChangeCheckIfLocked(value, lookup.number, event) : onDataSourceChange(value, lookup.number) }} />
    );
};

const getNumberContent = (number: number, reorder: (dragNumber: number, dropNumber: number) => void, isReadOnly: boolean): string | JSX.Element => {
    if (isReadOnly) {
        return number.toString();
    }
    return (
        <DraggableItem identifier={number} onDrop={reorder}>
            {number}
        </DraggableItem>
    );
};

const onLookupTypeChangeInDropdown = async (value: boolean, number: number, event: React.ChangeEvent<HTMLElement>,
    onLockSectionClick: (number: number) => Promise<boolean>,
    onLookupTypeChange: (isMultiResult: boolean, number: number) => void) => {
    const isLocked = await onLockSectionClick(number);
    if (!isLocked) {
        onLookupTypeChange(value, number);
    }
    else {
        event.preventDefault();
    }
}

const getColumns = (
    onLockSectionClick: (number: number) => Promise<boolean>,
    onDataSource1Change: (dataSourceId: string, number: number) => void,
    onDataSource2Change: (dataSourceId: string, number: number) => void,
    onNameChange: (name: string, number: number) => void,
    onLookupTypeChange: (isMultiResult: boolean, number: number) => void,
    onBespokeToggleClick: (isBespoke: boolean, lookup: Lookup, event?: React.MouseEvent<HTMLElement>) => void,
    onRemoveClick: (number: number, setup?: (x: Lookup[]) => void, event?: React.MouseEvent<HTMLElement>) => void,
    getLookupReferences: (number: number) => SelectOption[],
    reorder: (dragNumber: number, dropNumber: number) => void,
    collections: ReportCollections,
    reportType: string,
    isReadOnly: boolean,
    onLookupReferenceSelected: (value: string) => void
): ColumnDefinition<Lookup>[] => {

    let columns: ColumnDefinition<Lookup>[] = [
        {
            header: 'Reference',
            getContent: x => getNumberContent(x.number, reorder, x.isReadOnly || isReadOnly),
            width: '110px'
        },
        {
            header: 'Lookup Name',
            getContent: l => <EditableText key={l.name} disabled={l.isReadOnly || isReadOnly} value={l.name}
                onChange={value => onNameChange(value, l.number)}
                multiline={false} />,
            width: '200px'
        },
        {
            header: 'Lookup Type',
            getContent: l =>
                <SelectList
                    className="inline-select"
                    disabled={l.isReadOnly || isReadOnly}
                    options={lookupTypeOtions}
                    value={l.isMultiResult.toString()}
                    onChange={((value, event) => { onLookupTypeChangeInDropdown(value === true.toString(), l.number, event, onLockSectionClick, onLookupTypeChange) })} />,
            width: '140px'
        },
        {
            header: 'From',
            getContent: l => getDataSource1Content(l, collections.dataSources, onDataSource1Change, reportType, l.isReadOnly || isReadOnly, onLockSectionClick),
            width: '200px'

        },
        {
            header: 'To',
            getContent: l => getDataSource2Content(l, collections.dataSources.filter(x => x.id !== l.dataSource1Id), onDataSource2Change, l.isReadOnly || isReadOnly, onLockSectionClick),
            className: 'break-all'
        },
        {
            header: '',
            getContent: l =>
                <>
                    <LazySelectList
                        className="inline-select"
                        label="References"
                        width={106}
                        getOptions={() => getLookupReferences(l.number)}
                        onSelected={(value) => onLookupReferenceSelected(value)} />
                    {
                        !isReadOnly && !l.isReadOnly &&
                        collections.dataSources.some(x => x.id !== l.dataSource1Id) &&
                        <BespokeToggleButton isBespoke={l.isBespoke} onClick={(isBespoke, event) => onBespokeToggleClick(isBespoke, l, event)} />
                    }
                    <button
                        className="custom-icon-button float-right"
                        onClick={(event) => onRemoveClick(l.number, undefined, event)}
                        disabled={l.isReadOnly || isReadOnly}>
                        <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                    </button>
                </>,
            width: '300px'
        }
    ];

    return columns;
};

export {
    getColumns
};