import React from 'react';
import { ReactComponent as MinusIcon } from '../../../../components/Common/BootstrapIcons/dash.svg';
import { ReactComponent as PlusIcon } from '../../../../components/Common/BootstrapIcons/plus.svg';

interface Props {
    heading: string;
    htmlId: string;
    onClick: (htmlId: string) => void;
    isExpanded: (htmlId: string) => boolean;
}

class ValidationSection extends React.Component<Props>{

    constructor(props: Props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(): void {
        this.props.onClick(this.props.htmlId);
    }

    getClassName(): string {
        let classes = ['collapse'];

        if (this.props.isExpanded(this.props.htmlId)) {
            classes.push('show');
        }

        return classes.join(' ');
    }

    resolveIcon(): JSX.Element {

        return this.props.isExpanded(this.props.htmlId) ? <MinusIcon /> : <PlusIcon />;
    }

    resolveTooltip(): string {

        return this.props.isExpanded(this.props.htmlId) ? 'collapse' : 'expand';
    }

    render(): JSX.Element {

        return (
            <>
                <div className="section-heading" style={{ paddingLeft: '25px' }}>

                    <h5 id={this.props.htmlId}>

                        {this.props.heading}

                        <button id={`btn-expand-${this.props.htmlId}`} className="transparent-button" onClick={this.onClick} title={this.resolveTooltip()}>
                        {this.resolveIcon()}
                    </button>
                </h5>
            </div >

                <div className={this.getClassName()} style={{ paddingLeft: '25px' }}>
                    {this.props.children}
                </div>
            </>
        );
    }
}

export default ValidationSection;
