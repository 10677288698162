import { getEmptyOperandModalState } from './OperandModal.Helpers';
import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import Lookup from '../../../../types/functions/Lookup';
import Modal from '../../../Common/Modal/Modal';
import Operand from '../../../../types/functions/Operand/Operand';
import OperandBuilder from '../OperandBuilder';
import OperandModalState from './OperandModalState';
import React from 'react';
import ReportCollections from '../../../../infrastructure/types/Functions/ReportCollections';
import ReportVariable from '../../../../types/report/ReportVariable';
import SpecialScenario from '../Props/SpecialScenario';
import store from '../../../../store/store';
import Selector from '../../../../types/report/Selector';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

interface OperandModalProps {
    collections: ReportCollections;
    userDefinedFunctions: UserDefinedFunction[];
    dictionaries: Dictionary[];
    lookups: Lookup[];
    variables: ReportVariable[];
    isinSelectors: Selector[],
    leiSelectors: Selector[],
    exchangeRatesSelectors: Selector[],
    onChange?: (operand: Operand) => Promise<boolean>;
    reportType: string;
}

class OperandModal extends React.Component<OperandModalProps, OperandModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: OperandModalProps) {
        super(props);

        this.state = getEmptyOperandModalState();

        this.onStateChange = this.onStateChange.bind(this);
        this.onOperandChange = this.onOperandChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onStateChange);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    onStateChange(): void {

        let state = store.getState();
        if (state.action !== actions.operandModal.change) {
            return;
        }

        this.setState(state.operandModalChange);
    }

    async onOperandChange(changes: Operand, specialScenario?: SpecialScenario): Promise<void> {

        let proceed = true;
        if (this.props.onChange) {
            proceed = await this.props.onChange(changes);
        }

        if (proceed) {
            let state = {} as OperandModalState;


            state.operand = Object.assign({}, this.state.operand, changes);

            state.specialScenario = {

                doNotPopulate: Boolean(specialScenario?.doNotPopulate),

                pseudoFunction: specialScenario?.pseudoFunction || null
            };

            this.setState(state);
        }
    }

    onSaveClick(): void {
        store.dispatch({ type: actions.operandModal.save, payload: this.state });
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    render(): JSX.Element {
        return (
            <Modal
                state={this.state}
                close={this.close}>

                <OperandBuilder
                    reportType={this.props.reportType}
                    operand={this.state.operand}
                    onOperandChange={this.onOperandChange}
                    operandRules={this.state.operandRules}
                    collections={this.props.collections}
                    location={this.state.location}
                    userDefinedFunctions={this.props.userDefinedFunctions}
                    dictionaries={this.props.dictionaries}
                    lookups={this.props.lookups}
                    variables={this.props.variables}
                    dataSource1Id={this.state.dataSource1Id}
                    dataSource2Id={this.state.dataSource2Id}
                    customDataSourceLegitimacy={this.state.customDataSourceLegitimacy}
                    specialScenario={this.state.specialScenario}
                    customDataSourceContext={this.state.customDataSourceContext}
                    isinSelectors={this.props.isinSelectors}
                    leiSelectors={this.props.leiSelectors}
                    exchangeRatesSelectors={this.props.exchangeRatesSelectors}
                    arrayItemLegitimacy={this.state.arrayItemLegitimacy}
                    functionParameters={this.state.parameters}
                />

                <div className="mt-3 mb-3">
                    <button className="btn cb-btn" onClick={this.onSaveClick}>Save</button>
                </div>
            </Modal>
        );
    }
}

export default OperandModal;