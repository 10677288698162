import appRoutes from '../../infrastructure/constants/appRoutes';
import history from '../../infrastructure/helpers/react/history';
import identityProvider from '../../infrastructure/authorization/identityProvider';
import React from 'react';

class Unauthorized extends React.Component {
    constructor(props: any) {
        super(props);

        if (identityProvider.authorize().authorized) {
            history.replace(appRoutes.home);
            return;
        }

        identityProvider.loginRedirect();
    }

    render(): string {
        return 'Redirecting...';
    }
}

export default Unauthorized;
