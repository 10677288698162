const iconTooltip = {
    info: 'info',
    edit: 'edit',
    remove: 'remove',
    download: 'download',
    copy: 'copy',
    comments: 'comments',
    complianceGuidance: 'compliance guidance',
    changeLog: 'change log',
    actionLog: 'action log',
    copyReportSource:'export config'
};

export default iconTooltip;
