import { ReactComponent as InfoIcon } from '../../Common/BootstrapIcons/info.svg';
import { ReactComponent as LinkIcon } from '../../Common/BootstrapIcons/link.svg';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import PromiseButton from '../../Common/PromiseButton';
import React from 'react';
import ReportFieldDefinition from '../../../types/report/ReportFieldDefinition';

const getColumns = (onInfoClick: (id: number) => Promise<any>, onUrlClick: (id: number) => Promise<any>, getUrlCount: (id: number) => number, fieldNumberHeaderName: string): ColumnDefinition<ReportFieldDefinition>[] => {

    let columns: ColumnDefinition<ReportFieldDefinition>[] = [
        {
            header: 'Reference',
            getContent: x => x.number.toString(),
            width: '110px'
        },
        {
            header: `${fieldNumberHeaderName}`,
            getContent: x => x.rtsFieldNumber === undefined ? '' : x.rtsFieldNumber.toString(),
            width: '110px'
        },
        {
            header: 'Field Name',
            getContent: x => x.name,
            width: '380px',
            className: 'break-all'
        },
        {
            header: 'CN Field Format',
            getContent: x => 
                <div>
                    <div dangerouslySetInnerHTML={{ __html: x.format }}></div>
                </div>
        },
        {
            header: '',
            getContent: x =>
                <>
                    <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onInfoClick(x.id)}>
                        <CustomIcon icon={InfoIcon} title={iconTooltip.info} />
                    </PromiseButton>
                    <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onUrlClick(x.id)}>
                        <CustomIcon icon={LinkIcon} count={getUrlCount(x.id)} title={iconTooltip.complianceGuidance} />
                    </PromiseButton>
                </>,
            width: '72px'
        }
    ];

    return columns;
};

export { getColumns };