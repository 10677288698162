import typeHelper from './typeHelper';

const alowedCharactersRegex = /^[a-zA-Z0-9\s()\-_,.]*$/;
const whiteSpaceRegex = /^\s*$/;

const stringHelper = {
    isEmpty: (value: string) => typeHelper.isString(value) && whiteSpaceRegex.test(value),
    isNonEmpty: (value: string) => typeHelper.isString(value) && !whiteSpaceRegex.test(value),
    containsNotAllowedCharacters: (value: string) => !alowedCharactersRegex.test(value)
};

export default stringHelper;
