import assignmentHelper from '../assignment/assignmentHelper';
import dataType from '../../../constants/dataType';
import NumberDeclarationHelper from '../../../types/Functions/NumberDeclarationHelper';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import ReportVariable from '../../../../types/report/ReportVariable';

class VariableHelper extends NumberDeclarationHelper<ReportVariable> {

    getDeclarations(config: ReportConfig, location: ReportLogicLocation): ReportVariable[] {

        return config.variables;
    }

    getEmpty(rawDataSourceId: number | null): ReportVariable {

        let assignment = assignmentHelper.getEmpty(false);
        assignment.number = 1;

        return {
            id: null,
            name: '',
            number: 0,
            dataSourceId: rawDataSourceId,
            dataType: dataType.boolean,
            assignment: assignment,
            businessDescription: '',
            isSystem: false,
            errors: [],
            _internalId: 1
        };
    }
}

const variableHelper = new VariableHelper();

export default variableHelper;
