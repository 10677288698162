import React from 'react';

interface Props {
    isBespoke: boolean;
    onClick: (isBespoke: boolean, event?: React.MouseEvent<HTMLButtonElement>) => void;
    style?: React.CSSProperties;
}

class BespokeToggleButton extends React.Component<Props> {
    render(): JSX.Element {
        return (
            <button
                className="transparent-button"
                style={this.props.style}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.props.onClick(!this.props.isBespoke, event)}
                title={this.props.isBespoke ? 'This will convert the logic to regular format which is supported by Control box and remove any bespoke logic.' : 'This will convert the logic to a free text field which can be used to write any bespoke logic which is not currently available in Control Box.'}>
                Convert to {this.props.isBespoke ? 'regular' : 'bespoke' }
            </button>
        );
    }
}

export default BespokeToggleButton;