import { AxiosResponse } from 'axios';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import SingleValueResponse from '../../../infrastructure/types/common/SingleValueResponse';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';

const getCurrentVersion = (id: number): Promise<AxiosResponse<SingleValueResponse<string>>> => {

    let route = urlHelper.buildRoute(['report', id, 'version']);

    return httpClient.get<SingleValueResponse<string>>(route);
};

export { getCurrentVersion };
