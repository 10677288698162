import { mapToBasicOption } from '../../../infrastructure/helpers/html/selectOptionHelper';
import locationType from '../../../types/functions/Location/LocationType';
import operandType from '../../../infrastructure/constants/functions/operandType';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import reportLogicLocationHelper from '../../../infrastructure/helpers/functions/report/reportLogicLocationHelper';
import SelectOption from '../../../infrastructure/types/SelectOption';

const allowDoNotPopulateStatements = [
    locationType.statement.reportField,
    locationType.statement.aggregatedRecordField
];

const groupOption: SelectOption = {
    text: 'Group',
    value: operandType.group
};

const lookupResultSetOption: SelectOption = {
    text: 'Multi Result Lookup',
    value: operandType.lookupResultSet
};

const doNotPopulateOption: SelectOption = {
    text: 'Do Not Populate',
    value: 'dnp'
};

const arrayItemOption: SelectOption = {
    text: 'Array.[Item]',
    value: operandType.arrayItem
};

const firdsResultSetOption: SelectOption = {
    text: 'FIRDS',
    value: 'firds'
};

const leiResultSetLevel1Option: SelectOption = {
    text: 'LEI',
    value: operandType.leiResultSetLevel1
};

const exchangeRatesResultSetOption: SelectOption = {
    text: 'EXCHANGE-RATES',
    value: operandType.exchangeRatesResultSet
};


const firdsFieldOption: SelectOption = {
    text: operandType.field,
    value: operandType.firdsField
};

const leiLevel1FieldOption: SelectOption = {
    text: operandType.field,
    value: operandType.leiLevel1Field
};

const exchangeRatesFieldOption: SelectOption = {
    text: operandType.field,
    value: operandType.exchangeRatesField
};

const parameterOption: SelectOption = {
    text: 'Parameter',
    value: operandType.parameter
};

const operandTypeOptions = [
    groupOption,
    lookupResultSetOption,
    firdsResultSetOption,
    leiResultSetLevel1Option,
    firdsFieldOption,
    leiLevel1FieldOption,
    exchangeRatesResultSetOption,
    exchangeRatesFieldOption
].concat([
    parameterOption,
]).concat([
    operandType.field,
    operandType.function,
    operandType.constant].map(mapToBasicOption)
).concat([
    doNotPopulateOption,
    arrayItemOption]);

const includeDoNotPopulate = (location: ReportLogicLocation): boolean => {
    return (
        location.pieceOfCode === locationType.pieceOfCode.assignmentValue &&
        allowDoNotPopulateStatements.includes(location.statement) &&
        reportLogicLocationHelper.isRoot(location)
    );
};

export {
    doNotPopulateOption,
    firdsResultSetOption,
    operandTypeOptions,
    includeDoNotPopulate,
    arrayItemOption,
    parameterOption
};