import { AxiosResponse } from 'axios';
import { getColumns } from './FilterBuilder.Grid';
import actionItemHelper from '../../../../infrastructure/helpers/actionItem/actionItemHelper';
import ActionItemSaveRequest from '../../../../types/common/ActionItem/ActionItemSaveRequest';
import actions from '../../../../store/actions';
import BespokeConditionBuilder from '../../../Functions/Bespoke/BespokeConditionBuilder';
import ComplianceComment from '../../../../types/common/ComplianceComment';
import complianceCommentHelper from '../../../../infrastructure/helpers/complianceComment/complianceCommentHelper';
import ConditionBuilder from '../../../Functions/Condition/ConditionBuilder';
import conditionDisplayHelper from '../../../../infrastructure/helpers/functions/condition/conditionDisplayHelper';
import ErrorList from '../../../Common/ErrorList/ErrorList';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import locationType from '../../../../types/functions/Location/LocationType';
import React from 'react';
import reportConfigNavigation from '../../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportFilter from '../../../../types/report/ReportFilter';
import ReportLogicList from '../../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import StatementBuilderProps from '../../../Functions/Statement/StatementBuilderProps';
import store from '../../../../store/store';
import urlHelper from '../../../../infrastructure/helpers/common/urlHelper';
import Condition from '../../../../types/functions/Condition/Condition';
import identityStorage from '../../../../infrastructure/authorization/identityStorage';
import ActionitemDto from '../../../../types/common/ActionItem/ActionItemDto';
import ActionItemDto from '../../../../types/common/ActionItem/ActionItemDto';
import { reportType } from '../../../../infrastructure/constants/reportType';


class FilterBuilder extends React.Component<StatementBuilderProps<ReportFilter>> {
    constructor(props: StatementBuilderProps<ReportFilter>) {
        super(props);

        this.onNameChange = this.onNameChange.bind(this);
        this.onBusinessDescriptionChange = this.onBusinessDescriptionChange.bind(this);
        this.onBespokeToggleClick = this.onBespokeToggleClick.bind(this);
        this.onCommentClick = this.onCommentClick.bind(this);
        this.onActionItemClick = this.onActionItemClick.bind(this);
        this.onGetCommentSuccess = this.onGetCommentSuccess.bind(this);
        this.onGetActionItemDataSuccess = this.onGetActionItemDataSuccess.bind(this);
        this.getCommentCount = this.getCommentCount.bind(this);
        this.getActionItemCount = this.getActionItemCount.bind(this);
        this.getConditionContent = this.getConditionContent.bind(this);
    }

    onNameChange(name: string, number: number): void {

        this.props.onStatementChange({ number: number, name: name } as ReportFilter);
    }

    onBusinessDescriptionChange(businessDescription: string, number: number): void {

        this.props.onStatementChange({ number: number, businessDescription: businessDescription } as ReportFilter);
    }

    onBespokeToggleClick(isBespoke: boolean, filter: ReportFilter): void {

        let changes = { number: filter.number, isBespoke: isBespoke } as ReportFilter;

        if (isBespoke) {
            changes.bespokeCondition = conditionDisplayHelper.buildConditions(filter.condition, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, [], this.props.collections.customDataSourceFields);
        }

        this.props.onStatementChange(changes);
    }

    onCommentClick(id: number): Promise<any> {

        let url = urlHelper.buildRoute(['filter-comment', id]);

        return httpClient.get<ComplianceComment[]>(url).then(response => this.onGetCommentSuccess(response.data, url));
    }

    onActionItemClick(id: number): Promise<any> {

        let identity = identityStorage.get();

        let allItemsForClient = urlHelper.buildRoute(['action-item', 'by-client-id']);

        let filterActionItems = urlHelper.buildRoute(['action-item', 'filter', id]);

        let buildRelationUrl = (actionItemId: string) => urlHelper.buildRoute(['action-item', actionItemId, 'filter', id]);

        let calls: [Promise<AxiosResponse<ActionitemDto[]>>, Promise<AxiosResponse<ActionitemDto[]>>] = [httpClient.get<ActionitemDto[]>(allItemsForClient, { reportConfigId: this.props.reportConfig.id, clientId: identity.clientId || null }), httpClient.get<ActionitemDto[]>(filterActionItems)];

        let saveRequest = { reportConfigId: this.props.reportConfig.id, filterId: id } as ActionItemSaveRequest;

        return Promise.all(calls).then(responses => {
            this.onGetActionItemDataSuccess(responses[0].data, responses[1].data, saveRequest, buildRelationUrl)
        });
    }

    onGetCommentSuccess(comments: ComplianceComment[], url: string): void {

        let state = complianceCommentHelper.createModalState(comments, url);

        store.dispatch({ type: actions.complianceCommentModal.open, payload: state });
    }

    onGetActionItemDataSuccess(allActionItems: ActionItemDto[], filterActionItems: ActionItemDto[], saveRequest: ActionItemSaveRequest, buildRelationUrl: (actionItemId: string) => string): void {

        let originalActionItems: ActionItemDto[] = [];
        allActionItems.forEach((iten) => {
            originalActionItems.push(Object.assign({}, iten));
        });

        const filterItems = actionItemHelper.prepareModuleActionitems(allActionItems, filterActionItems);

        let state = actionItemHelper.createModalState(originalActionItems, allActionItems, filterItems, saveRequest, buildRelationUrl, 'Filter');

        store.dispatch({ type: actions.actionItemModal.open, payload: state });
    }

    getCommentCount(id: number): number {

        return this.props.counts.filterCommentCounts[id.toString()] || 0;
    }

    getActionItemCount(id: number): number {

        return this.props.counts.filterActionItemCounts[id.toString()] || 0;
    }

    onReorderConditions(condition: Condition, filter: ReportFilter) {

        filter.condition = condition;
        this.props.onStatementChange(filter);
    }

    getConditionContent(filter: ReportFilter): JSX.Element {

        if (filter.isBespoke) {
            return (
                <BespokeConditionBuilder
                    keyword={this.props.details.keyword}
                    keyword2={this.props.details.keyword2}
                    statement={filter}
                    onChange={this.props.onStatementChange}
                    isReadOnly={this.props.isReadOnly} />
            );
        }

        return (
            <>
                <ConditionBuilder
                    isReadOnly={this.props.isReadOnly}
                    allowCopy={true}
                    keyword={this.props.details.keyword}
                    functions={this.props.reportConfig.userDefinedFunctions}
                    lookups={this.props.reportConfig.lookups}
                    dictionaries={this.props.reportConfig.dictionaries}
                    variables={this.props.reportConfig.variables}
                    dataSources={this.props.collections.dataSources}
                    reportFields={this.props.reportConfig.type === reportType.accuracy ? this.props.collections.reportFields : []}
                    customDataSourceFields={this.props.collections.customDataSourceFields}
                    location={{ statement: locationType.statement.filter, statementNumber: filter.number } as ReportLogicLocation}
                    condition={filter.condition}
                    onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                    onParenthesesChange={this.props.onParenthesesChange}
                    onAddConditionClick={this.props.onAddConditionClick}
                    onRemoveConditionClick={this.props.onRemoveConditionClick}
                    onComparisonTypeChange={this.props.onComparisonTypeChange}
                    onOperandClick={this.props.onOperandClick}
                    onCopyClick={this.props.onCopyClick}
                    onPasteClick={this.props.onPasteClick}
                    allowPaste={this.props.allowPaste}
                    onReorderConditions={(condition) => { this.onReorderConditions(condition, filter); }} />

                <div className="code-block">
                    <span className="code">{this.props.details.keyword2}</span>
                </div>

                <ErrorList errors={filter.errors} className="mt-2" />
            </>
        );
    }

    render(): JSX.Element {
        return (
            <ReportLogicList
                isWaiting={this.props.isWaiting}
                items={this.props.statements}
                columns={getColumns(
                    this.onNameChange,
                    this.onBusinessDescriptionChange,
                    this.onBespokeToggleClick,
                    this.props.onRemoveStatementClick,
                    this.onCommentClick,
                    this.onActionItemClick,
                    this.props.reorder,
                    this.getCommentCount,
                    this.getActionItemCount,
                    this.props.isReadOnly)}
                getContent={this.getConditionContent}
                getKey={f => f.number}
                createHtmlId={f => reportConfigNavigation.buildFilterId(f.number)} />
        );
    }

}

export default FilterBuilder;
