import { htmlIds, resolveSectionPresence } from '../../infrastructure/helpers/report/navigation/reportConfigNavigation.HtmlIds';
import React from 'react';
import reportConfigNavigation from '../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import StepDescription from '../Common/StepIndicator/Step/StepDescription';
import StepIndicator from '../Common/StepIndicator/StepIndicator';

interface Props {
    reportType: string;
}

interface State {
    currentStepNumber: number;
}

interface Step {
    title: string;
    htmlId: string;
}

class ReportStepIndicator extends React.Component<Props, State> {
    private steps: Step[];

    constructor(props: Props) {
        super(props);

        this.state = { currentStepNumber: 1 };

        this.onClick = this.onClick.bind(this);
        this.mapToDescription = this.mapToDescription.bind(this);

        this.steps = [
            {
                title: 'Data Sources',
                htmlId: htmlIds.dataSources
            },
            {
                title: 'Functions',
                htmlId: htmlIds.userDefinedFunctions
            },
            {
                title: 'Dictionaries',
                htmlId: htmlIds.dictionaries
            },
            {
                title: 'Cache Keys',
                htmlId: htmlIds.cacheKeys
            },
            {
                title: 'Lookups',
                htmlId: htmlIds.lookups
            },
            {
                title: 'Variables',
                htmlId: htmlIds.variables
            },
            {
                title: 'Filtering',
                htmlId: htmlIds.filters
            },
            {
                title: 'Aggregation',
                htmlId: htmlIds.aggregations
            },
            {
                title: 'Endpoint Filtering',
                htmlId: htmlIds.endpointFilters
            },
            {
                title: 'Under Report',
                htmlId: htmlIds.expectedUnderReport
            },
            {
                title: 'Over Report',
                htmlId: htmlIds.expectedOverReport
            },
            {
                title: 'Reporting Case',
                htmlId: htmlIds.cases
            },
            {
                title: 'Matching Keys',
                htmlId: htmlIds.matchingKeys
            },
            {
                title: 'Enrichment',
                htmlId: htmlIds.enrichment
            },
            {
                title: 'Validations',
                htmlId: htmlIds.validations
            }
        ];
    }

    onClick(stepNumber: number, htmlId: string): void {

        this.setState({ currentStepNumber: stepNumber });

        reportConfigNavigation.navigateToSection(htmlId);
    }

    mapToDescription(step: Step, i: number): StepDescription {
        return {
            title: step.title,
            onClick: () => this.onClick(i + 1, step.htmlId),
            clickable: () => true
        };
    }

    render() {
        return (
            <StepIndicator
                steps={this.steps.filter(x => resolveSectionPresence(x.htmlId, this.props.reportType)).map(this.mapToDescription)}
                currentStepNumber={this.state.currentStepNumber}
                stepWidth={100}
                fontSize={13} />
        );
    }
}

export default ReportStepIndicator;
