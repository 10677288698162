import { getIngestionConfig } from './IngestionPage/IngestionPage.Helpers';
import validateMasterFileLocation from '../Details/IngestionDetails.Validation';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import history from '../../../infrastructure/helpers/react/history';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import IngestionDetails from '../Details/IngestionDetails';
import IngestionPageState from './IngestionPage/IngestionPageState';
import IngestionStepIndicator from '../IngestionStepIndicator';
import React from 'react';
import store from '../../../store/store';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import validateEmptyValueTokens from '../Details/IngestionDetails.Validation.EmptyValueTokens';
import validateFilenames from '../IngestionFilenames/IngestionFilenames.Validation';
import FieldDefinition from '../../../types/common/FieldDefinition';
import dataType from '../../../infrastructure/constants/dataType';
import validateConfigName from '../../../infrastructure/helpers/common/validator/nameValidator';

class IngestionDetailsPage extends React.Component<any, IngestionPageState> {

    constructor(props: any) {
        super(props);

        if (!typeHelper.isObject(history.location.state)) {
            history.push(appRoutes.ingestion.home);
        }

        this.state = { config: getIngestionConfig(history), error: null };

        this.onChange = this.onChange.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
    }

    onChange(changes: IngestionConfig): void {

        this.setState({
            config: Object.assign({}, this.state.config, changes),
        });
    }

    onNextClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {

        let error =
            validateConfigName(this.state.config.name, false) ||
            validateFilenames(this.state.config.filenames) ||
            validateMasterFileLocation(this.state.config) ||
            validateEmptyValueTokens(this.state.config.emptyValueTokens);

        this.setState({ error });

        if (error) {
            return;
        }

        if (this.state.config.isTransposed) {

            let key = { name: 'key', type: dataType.string, isRequired: true } as FieldDefinition;
            let value = { name: 'value', type: dataType.string } as FieldDefinition;

            if (this.state.config.fieldDefinitions.length > 0) {

                let first = this.state.config.fieldDefinitions[0];

                key.exampleValue = first.name;

                value.type = first.type;
                value.exampleValue = first.exampleValue;
            }

            var transposedFieldDefinitions = [] as FieldDefinition[];

            transposedFieldDefinitions.push(key);
            transposedFieldDefinitions.push(value);

            let changes = { fieldDefinitions: transposedFieldDefinitions } as IngestionConfig;

            this.setState({
                config: Object.assign({}, this.state.config, changes),
            });
        }

        store.dispatch({ type: actions.navigation.execute, payload: () => history.push(appRoutes.ingestion.fieldDefinition, this.state.config) });
    }

    render(): JSX.Element {
        return (
            <>
                <IngestionStepIndicator
                    currentStepNumber={1}
                    config={this.state.config} />

                <div className="row">
                    <div className="col-12 col-sm-11 col-md-8 col-lg-6 col-xl-5">
                        <IngestionDetails
                            isReadOnly={false}
                            config={this.state.config}
                            onChange={this.onChange} />
                    </div>
                </div>

                <ErrorMessage error={this.state.error} />

                <div className="mt-3 mb-3">
                    <button onClick={this.onNextClick} className="btn cb-btn">Next</button>
                </div>
            </>
        );
    }
}


export default IngestionDetailsPage;
