import React from 'react';
import pixelize from '../../../infrastructure/helpers/html/pixelize';
import Step from './Step/Step';
import StepDescription from './Step/StepDescription';

interface StepIndicatorProps {
    steps: StepDescription[];
    currentStepNumber: number;
    stepWidth: number;
    fontSize: number;
}

class StepIndicator extends React.Component<StepIndicatorProps> {
    render(): JSX.Element {
        return (
            <div style={{ width: pixelize(this.props.steps.length * this.props.stepWidth), overflow: 'auto' }}>
                {
                    this.props.steps.map((step, i) => {

                        let orderNumber = i + 1;

                        return (
                            <Step
                                step={step}
                                orderNumber={orderNumber}
                                totalStepCount={this.props.steps.length}
                                titleFontSize={this.props.fontSize}
                                width={this.props.stepWidth}
                                isCurrent={orderNumber === this.props.currentStepNumber}
                                isCompleted={orderNumber < this.props.currentStepNumber}
                                key={i} />
                        );
                    })
                }
            </div>
        );
    }
}

export default StepIndicator;
