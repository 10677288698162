import { createCaseOption } from '../Enirchment/EnrichmentBuilder.Helpers';
import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import copyArray from '../../../../infrastructure/helpers/common/copyArray';
import copyObject from '../../../../infrastructure/helpers/common/copyObject';
import CopyReportFieldModalState from '../CopyReportFieldModal/CopyReportFieldModalState';
import RadioButtonGroup from '../../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import ReportCase from '../../../../types/report/ReportCase';
import ReportCaseField from '../../../../types/report/ReportCaseField';
import store from '../../../../store/store';
import SyncedWithEditor from '../../../Common/SyncedWithEditor/SyncedWithEditor';
import Synced from '../../../../types/report/Synced';
import guidance from '../../ReportConfigEditor/ReportConfigEditor.Guidance';

interface Props {
    isWaiting: boolean;
    caseNumber: number | null;
    cases: ReportCase[];
    onCaseSelected: (number: number) => void;
    onEnrichmentChange: (number: number, fields: ReportCaseField[]) => void;
    onSyncedWithIdChange: (number: number, syncedWithId: number | null) => void;
}

class EnrichmentSelector extends React.Component<Props> {
    private unsubscribeCopyFields: Unsubscribe | undefined;

    constructor(props: Props) {
        super(props);

        this.onCaseSelected = this.onCaseSelected.bind(this);
        this.onCopyAllClick = this.onCopyAllClick.bind(this);
        this.onCopyFields = this.onCopyFields.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribeCopyFields = store.subscribe(this.onCopyFields);
    }

    componentWillUnmount(): void {
        (this.unsubscribeCopyFields as Unsubscribe)();
    }

    onCaseSelected(value: string): void {

        this.props.onCaseSelected(parseInt(value));
    }

    onCopyAllClick(): void {

        let state: CopyReportFieldModalState = {
            title: 'Copy All Fields',
            isOpen: true,
            error: null,
            copyAllFields: true,
            sourceCaseNumber: this.props.cases[0].number.toString(),
            targetCaseNumbers: new Set([this.props.cases[0].number.toString()]),
            sourceFieldId: '',
            targetFieldId: ''
        };

        store.dispatch({ type: actions.copyFieldModal.change, payload: state });
    }

    onCopyFields(): void {

        let state = store.getState();
        if (state.action !== actions.copyFieldModal.save) {
            return;
        }

        let sourceCase = this.props.cases.find(x => x.number === parseInt(state.copyFieldModalSave.sourceCaseNumber)) as ReportCase;

        if (state.copyFieldModalSave.copyAllFields) {
            state.copyFieldModalSave.targetCaseNumbers.forEach((value) => {
                let targetCase = this.props.cases.find(x => x.number === parseInt(value)) as ReportCase;
                targetCase.fields = copyArray(sourceCase.fields);
                this.props.onEnrichmentChange(parseInt(value), targetCase.fields);
            });
        }
        else {
            let sourceField = sourceCase.fields.find(x => x.fieldId === parseInt(state.copyFieldModalSave.sourceFieldId)) as ReportCaseField;

            state.copyFieldModalSave.targetCaseNumbers.forEach((value) => {
                let targetCase = this.props.cases.find(x => x.number === parseInt(value)) as ReportCase;
                let targetField = targetCase.fields.find(x => x.fieldId === parseInt(state.copyFieldModalSave.targetFieldId)) as ReportCaseField;
                targetField.assignment = copyObject(sourceField.assignment);
                this.props.onEnrichmentChange(parseInt(value), targetCase.fields);
            });
        }

    }

    render(): JSX.Element {

        if (this.props.isWaiting) {

            return (<></>);
        }

        return (
            <>
                <RadioButtonGroup
                    className='sticky min-height-44px padding-10px'
                    options={this.props.cases.map(createCaseOption)}
                    value={(this.props.caseNumber as number).toString()}
                    name="report-cases"
                    onChange={this.onCaseSelected} />

                <div className="row mt-3">
                    <div className="col-4">
                        <SyncedWithEditor
                            itemNumber={this.props.caseNumber as number}
                            collection={this.props.cases as Synced[]}
                            onSyncedWithIdChange={this.props.onSyncedWithIdChange}
                            info={guidance.syncedWithEnrichment} />
                    </div>
                    <div className="col-8">
                        <div className="float-right">
                            <button
                                className="btn btn-light"
                                onClick={this.onCopyAllClick}
                                disabled={this.props.cases.length < 2}>Copy All Fields</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EnrichmentSelector;
