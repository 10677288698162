import './ErrorList.scss';
import { buildClassName } from '../../../infrastructure/helpers/html/htmlUtils';
import React from 'react';

interface ErrorListProps {
    errors: string[];
    className?: string;
}

const getListItem = (error: string, i: number): JSX.Element => <li key={i}>{error}</li>;

class ErrorList extends React.Component<ErrorListProps> {
    render(): JSX.Element {
        return (
            <ul className={buildClassName('error', this.props.className)}>
                {this.props.errors.map(getListItem)}
            </ul>
        );
    }
}

export default ErrorList;
