import SelectOption from "../../../../infrastructure/types/SelectOption";

const insertAfter: SelectOption = {
    text: 'Insert After',
    value: 'Insert After'
};

const insertBefore: SelectOption = {
    text: 'Insert Before',
    value: 'Insert Before'
};

const insertAtPositionOptions = [
    insertAfter,
    insertBefore
];

export {
    insertAtPositionOptions
};
