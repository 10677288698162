import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import FormGroup from '../../../Common/FormGroup/FormGroup';
import Modal from '../../../Common/Modal/Modal';
import PromiseButton from '../../../Common/PromiseButton';
import React from 'react';
import store from '../../../../store/store';
import SelectSearch, { SelectedOptionValue, SelectSearchOption } from 'react-select-search';
import RollbackVersionModalState from './RollbackVersionModalState';

interface RollBackVersionModalProps {
    showOnlyVersions?: boolean;
}

class RollBackVersionModal extends React.Component<RollBackVersionModalProps, RollbackVersionModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: RollBackVersionModalProps) {
        super(props);

        this.state = {
            title: '',
            isOpen: false,
            error: null,
            name: '',
            comment: '',
            selectedVersion: '',
            versions: [] as any,
            onSaveClick: null as any
        };

        this.onStateChange = this.onStateChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.onVersionSelect = this.onVersionSelect.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onStateChange);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    onStateChange(): void {

        let state = store.getState();
        if (state.action !== actions.rollBackVersionModal.open) {
            return;
        }

        this.setState(state.rollBackVersionModalState);
    }

    onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {

        let changes = { name: e.target.value } as RollbackVersionModalState;

        this.setState(changes);
    }

    onCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {

        let changes = { comment: e.target.value } as RollbackVersionModalState;

        this.setState(changes);
    }

    getOptions(): SelectSearchOption[] {

        const createFieldOption = (identifier: string): SelectSearchOption => {
            return {
                name: `V${identifier}`,
                value: identifier
            };
        };

        var options = this.state.versions.map(createFieldOption);

        return options;
    }

    onVersionSelect(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {

        let changes = {
            selectedVersion: selectedValue.toString(),
            name: `Roll Back to V${selectedValue}`
        } as RollbackVersionModalState;

        this.setState(changes);
    }

    onSaveClick(): Promise<void> {

        return this.state
            .onSaveClick(
                this.state.selectedVersion,
                this.state.name,
                this.state.comment)
            .then(this.close);
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    render(): JSX.Element {
        const labelClass = 'col-3';
        const contentClass = 'col-9';

        return (
            <Modal state={this.state} close={this.close}>

                <FormGroup label="Version:" labelClassName={labelClass} contentClassName={contentClass}>
                    <SelectSearch
                        options={this.getOptions()}
                        value={this.state.selectedVersion}
                        onChange={this.onVersionSelect}
                        search={true} />
                </FormGroup>

                {
                    !this.props.showOnlyVersions &&
                    <FormGroup label="Name:" labelClassName={labelClass} contentClassName={contentClass}>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.name}
                            onChange={this.onNameChange} />
                    </FormGroup>
                }

                {
                    !this.props.showOnlyVersions &&
                    <FormGroup label="Comment:" labelClassName={labelClass} contentClassName={contentClass}>
                        <textarea
                            className="form-control"
                            onChange={this.onCommentChange}
                            value={this.state.comment}>
                        </textarea>
                    </FormGroup>
                }
                
                <PromiseButton className="btn cb-btn" text="Save" enableOnErrorOnly={false} task={this.onSaveClick} />
            </Modal>
        );
    }
}

export default RollBackVersionModal;
