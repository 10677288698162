import Operand from '../../../../types/functions/Operand/Operand';
import OperandSetHelper from '../../../types/Functions/OperandSetHelper';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import matchingKeyEndpoint from '../../../constants/functions/matchingKeyEndPoint';
import MatchingKey from '../../../../types/report/MatchingKey';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import assignmentHelper from '../../../../infrastructure/helpers/functions/assignment/assignmentHelper';

export class MatchingKeyHelper extends OperandSetHelper<MatchingKey> {
    private readonly type: string;
    private get isEndPoint() {
        return this.type === matchingKeyEndpoint.name; 
    }

    /**
    * @param type The matching key type.
    */
    constructor(type: string) {
        super();
        this.type = type;
    }

    getSet(config: ReportConfig, location: ReportLogicLocation): Operand[] {

        let key = this.isEndPoint ? config.matchingKeyEndPoint : config.matchingKey;

        let hasAssignment = typeHelper.isObject(key.assignment);

        if (hasAssignment) {

            let target = assignmentHelper.getByNumber(key.assignment, location.assignmentNumber);

            return target.value.arguments as Operand[];

        } else {

            return key.operands;
        }
    }

    getDataSourceId(_config: ReportConfig, _location: ReportLogicLocation): number | null {

        return null;
    }

    resolveTarget(config: ReportConfig): MatchingKey {

        let key = this.isEndPoint ? config.matchingKeyEndPoint : config.matchingKey;

        return key;
    }
}