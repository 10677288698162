import React from 'react';
import VersionDisplayProps from './VersionDisplayProps';
import versionHelper from '../../../infrastructure/helpers/functions/common/versionHelper';

class VersionDisplay extends React.Component<VersionDisplayProps> {

    getVersion(): string {

        return (this.props.majorIncremented ? versionHelper.incrementMajorVersion : versionHelper.incrementMinorVersion)(this.props.version);
    }

    render(): JSX.Element {
        return (
            <>{versionHelper.getDisplayName(this.getVersion())}</>
        );
    }
}

export default VersionDisplay;
