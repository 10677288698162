import FilenameDetails from '../../types/common/FilenameDetails';
import typeHelper from './typeHelper';

const getEmpty = (): FilenameDetails => {

    return {
        name: null as any,
        extension: null as any
    };
};

const getFilenameDetails = (filename: string): FilenameDetails => {

    if (!typeHelper.isString(filename)) {
        return getEmpty();
    }

    let i = filename.lastIndexOf('.');
    if (i === -1) {
        return getEmpty();
    }

    return {
        name: filename.substring(0, i),
        extension: filename.substring(i + 1).toLowerCase()
    };
};

export { getFilenameDetails };
