import { endpointFilterTypeDisplayMap } from '../../../constants/report/endpointFilterType';
import assignmentOperandHelper from '../assignment/assignmentOperandHelper';
import conditionHelper from '../condition/conditionHelper';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../operand/operandHelper';
import ReportConfig from '../../../../types/report/ReportConfig';
import selectMany from '../../common/array/selectMany';
import operandType from '../../../constants/functions/operandType';

const buildMessage = (referenced: string, by: string): string => `${referenced} is referenced by ${by}. Remove references first.`;

const validateReferences = (config: ReportConfig, isReferenced: (o: Operand) => boolean, name: string): string | null => {

    for (let i = 0; i < config.lookups.length; i++) {

        if (conditionHelper.getAllOperands(config.lookups[i].condition).some(isReferenced)) {

            return buildMessage(name, 'lookup conditions');
        }
    }

    for (let i = 0; i < config.isinSelectors.length; i++) {

        if (assignmentOperandHelper.getAll(config.isinSelectors[i].assignment).some(isReferenced)) {

            return buildMessage(name, 'ISIN logic');
        }
    }

    for (let i = 0; i < config.leiSelectors.length; i++) {

        if (assignmentOperandHelper.getAll(config.leiSelectors[i].assignment).some(isReferenced)) {

            return buildMessage(name, 'LEI logic');
        }
    }

    for (let i = 0; i < config.exchangeRatesSelectors.length; i++) {

        if (assignmentOperandHelper.getAll(config.exchangeRatesSelectors[i].assignment).some(isReferenced)) {

            return buildMessage(name, 'Exchange Rates logic');
        }
    }

    for (let i = 0; i < config.annaDsbUpiEnrichmentSelectors.length; i++) {

        if (assignmentOperandHelper.getAll(config.annaDsbUpiEnrichmentSelectors[i].assignment).some(isReferenced)) {

            return buildMessage(name, 'Anna Dsb Upi Enrichment logic');
        }
    }

    for (let i = 0; i < config.annaDsbUpiSelectors.length; i++) {

        if (assignmentOperandHelper.getAll(config.annaDsbUpiSelectors[i].assignment).some(isReferenced)) {

            return buildMessage(name, 'Anna Dsb Upi logic');
        }
    }

    for (let i = 0; i < config.variables.length; i++) {

        if (assignmentOperandHelper.getAll(config.variables[i].assignment).some(isReferenced)) {

            return buildMessage(name, 'variable logic');
        }
    }

    for (let i = 0; i < config.aggregations.length; i++) {


        if (selectMany(config.aggregations[i].groupBy, o => operandHelper.asCollection(o)).some(isReferenced)) {

            return buildMessage(name, 'aggregation grouping criteria');
        }
    }

    for (let i = 0; i < config.aggregations.length; i++) {

        for (let j = 0; j < config.aggregations[i].fields.length; j++) {

            if (assignmentOperandHelper.getAll(config.aggregations[i].fields[j].assignment).some(isReferenced)) {

                return buildMessage(name, 'aggregated record fields');
            }
        }
    }

    for (let i = 0; i < config.filters.length; i++) {

        if (conditionHelper.getAllOperands(config.filters[i].condition).some(isReferenced)) {

            return buildMessage(name, 'filter conditions');
        }
    }

    for (let i = 0; i < config.endpointFilters.length; i++) {

        if (conditionHelper.getAllOperands(config.endpointFilters[i].condition).some(isReferenced)) {

            return buildMessage(name, `${endpointFilterTypeDisplayMap[config.endpointFilters[i].type]} conditions`);
        }
    }

    for (let i = 0; i < config.cases.length; i++) {

        if (conditionHelper.getAllOperands(config.cases[i].condition).some(isReferenced)) {

            return buildMessage(name, 'case conditions');
        }
    }

    for (let i = 0; i < config.cases.length; i++) {

        for (let j = 0; j < config.cases[i].fields.length; j++) {

            if (assignmentOperandHelper.getAll(config.cases[i].fields[j].assignment).some(isReferenced)) {

                return buildMessage(name, `enrichment logic of case '${config.cases[i].name}'`);
            }
        }
    }

    for (let i = 0; i < config.validations.length; i++) {

        if (assignmentOperandHelper.getAll(config.validations[i].assignment).some(isReferenced)) {

            return buildMessage(name, 'validation logic');
        }
    }

    for (let i = 0; i < config.userDefinedFunctions.length; i++) {

        if (assignmentOperandHelper.getAll(config.userDefinedFunctions[i].assignment).some(isReferenced)) {

            return buildMessage(name, 'user defined function');
        }
    }

    return null;
};

const validateAggregationReferences = (aggregationNumber: number, config: ReportConfig): string | null => {

    for (let i = 0; i < config.cases.length; i++) {

        if (config.cases[i].aggregationNumber === aggregationNumber) {

            return buildMessage('Aggregation', `case '${config.cases[i].name}'`);
        }
    }

    return null;
};

const validateDictionaryReferences = (dictionaryNumber: number, config: ReportConfig): string | null => {

    return validateReferences(config, o => o.dictionaryNumber === dictionaryNumber, 'Dictionary');
};

const validateLookupReferences = (lookupNumber: number, config: ReportConfig): string | null => {

    return validateReferences(config, o => o.lookupNumber === lookupNumber, 'Lookup');
};

const validateVariableReferences = (variableNumber: number, config: ReportConfig): string | null => {

    return validateReferences(config, o => o.variableNumber === variableNumber, 'Variable');
};

const validateFirdsReferences = (isinNumber: number, config: ReportConfig, customDataSourceFields: CustomDataSourceFields): string | null => {

    return validateReferences(config, o => (o.operandType === operandType.firdsResultSetEsma || o.operandType === operandType.firdsResultSetFca) && o.selectorNumber === isinNumber, 'FIRDS collection');
};

const validateLeiReferences = (leiNumber: number, config: ReportConfig, customDataSourceFields: CustomDataSourceFields): string | null => {

    return validateReferences(config, o => o.operandType === operandType.leiResultSetLevel1 && o.selectorNumber === leiNumber, 'LEI collection');
};

const validateExchangeRatesReferences = (exchangeRatesNumber: number, config: ReportConfig, customDataSourceFields: CustomDataSourceFields): string | null => {

    return validateReferences(config, o => o.operandType === operandType.exchangeRatesResultSet && o.selectorNumber === exchangeRatesNumber, 'Exchange Rates collection');
};

const validateUserDefinedFunctionReference = (userDefinedFunctionNumber: number, config: ReportConfig): string | null => {

    return validateReferences(config, o => o.functionNumber === userDefinedFunctionNumber, 'User defined function');
};

const referenceValidator = {
    validateAggregationReferences,
    validateLookupReferences,
    validateVariableReferences,
    validateFirdsReferences,
    validateLeiReferences,
    validateExchangeRatesReferences,
    validateDictionaryReferences,
    validateUserDefinedFunctionReference
};

export default referenceValidator;
