import './DraggableItem.scss';
import React from 'react';

interface Props {
    identifier: number;
    onDrop: (dragIdentifier: number, dropIdentifier: number, event: React.DragEvent<HTMLDivElement>, dragSpecialData?:string, dropSpecialData?: string) => void;
    style?: React.CSSProperties;
    specialData?: string;
}

const transferData = 'transferData';

class DraggableItem extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onDragStart = this.onDragStart.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    onDragStart(e: React.DragEvent<HTMLDivElement>, identifier: number, specialData?: string): void {

        e.dataTransfer.setData(transferData, JSON.stringify({ dragIdentifier: identifier.toString(), specialData: specialData }));
    }

    onDrop(e: React.DragEvent<HTMLDivElement>, identifier: number, dropSpecialData?: string): void {

        const data = JSON.parse(e.dataTransfer.getData(transferData));
        let dragIdentifier = parseInt(data.dragIdentifier);
        let dragSpecialData = data.specialData;
        this.props.onDrop(dragIdentifier, identifier, e, dragSpecialData, dropSpecialData);
    }

    onDragOver(e: React.DragEvent<HTMLDivElement>): void {
        e.preventDefault();
    }

    render(): JSX.Element {
        return (
            <div
                className="draggable-item"
                draggable={true}
                onDragStart={e => this.onDragStart(e, this.props.identifier, this.props.specialData)}
                onDrop={e => this.onDrop(e, this.props.identifier, this.props.specialData)}
                onDragOver={this.onDragOver}
                style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}

export default DraggableItem;