import { getColumns } from './MatchingKeyBuilder.Grid';
import locationType from '../../../types/functions/Location/LocationType';
import Operand from '../../../types/functions/Operand/Operand';
import OperandSet from '../../Functions/OperandSet/OperandSet';
import React from 'react';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import matchingKeyEndpoint from '../../../infrastructure/constants/functions/matchingKeyEndPoint';
import { MatchingKeyHelper } from '../../../infrastructure/helpers/functions/matchingKey/matchingKeyHelper';
import AssignmentBuilder from '../../Functions/Assignment/AssignmentBuilder';
import MatchingKey from '../../../types/report/MatchingKey';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import Assignment from '../../../types/functions/Assignment/Assignment';
import conditionHelper from '../../../infrastructure/helpers/functions/condition/conditionHelper';
import operandType from '../../../infrastructure/constants/functions/operandType';
import dataType from '../../../infrastructure/constants/dataType';
import copyObject from '../../../infrastructure/helpers/common/copyObject';
import operandHelper from '../../../infrastructure/helpers/functions/operand/operandHelper';
import ErrorList from '../../Common/ErrorList/ErrorList';
import MatchingKeyOperandSetBuilderProps from './OperandSet/MatchingKeyOperandSetBuilderProps';

class MatchingKeyBuilder extends React.Component<MatchingKeyOperandSetBuilderProps> {
    private readonly matchingKeyConfig: {
        data: { name: string, type: string },
        info: string
    };

    private get isEndPoint() {
        return this.matchingKeyConfig.data.name === matchingKeyEndpoint.name;
    }

    private get matchingKey() {
        return this.isEndPoint
            ? this.props.reportConfig.matchingKeyEndPoint
            : this.props.reportConfig.matchingKey
    };

    constructor(props: MatchingKeyOperandSetBuilderProps) {
        super(props);

        this.onCountChange = this.onCountChange.bind(this);
        this.onOperandClick = this.onOperandClick.bind(this);
        this.getContent = this.getContent.bind(this);
        this.getConditionContent = this.getConditionContent.bind(this);
        this.onAddFirstConditionClick = this.onAddFirstConditionClick.bind(this);

        this.matchingKeyConfig = props.configurationData;
    }

    onCountChange(operands: Operand[], value: number): void {
        let helper = new MatchingKeyHelper(this.matchingKeyConfig.data.name);

        helper.handleArgumentCountChange(operands, value);

        this.props.onChange(null as any, this.matchingKey);
    }

    onOperandClick(assignmentNumber: number, argumentIndexes: number[]) {

        let location = {} as ReportLogicLocation;

        location.statement = this.isEndPoint ? locationType.statement.matchingKeyEndPoint : locationType.statement.matchingKey;
        location.argumentIndexes = argumentIndexes;
        location.assignmentNumber = assignmentNumber;
        location.pieceOfCode = locationType.pieceOfCode.operandsList;

        this.props.onOperandClick(location);
    }

    onAddFirstConditionClick(): void {

        let location = {} as ReportLogicLocation;

        location.statement = this.isEndPoint ? locationType.statement.matchingKeyEndPoint : locationType.statement.matchingKey;

        let $true = {
            operandType: operandType.function,
            dataType: dataType.string,
            function: 'Match',
            arguments: copyObject(this.matchingKey.operands)
        } as Operand;
        let $false = {
            operandType: operandType.function,
            dataType: dataType.string,
            function: 'Match',
            arguments: copyObject(this.matchingKey.operands)
        } as Operand;

        let assignment = {
            number: 1,
            condition: conditionHelper.getEmpty(),
            value: $true,
            doNotPopulate: false,
            elseIf: null,
            else: {
                number: 2,
                condition: null,
                value: $false,
                doNotPopulate: false,
                elseIf: null,
                else: null
            }
        } as Assignment;

        this.matchingKey.assignment = assignment;

        this.matchingKey.operands = [];

        this.props.onChange(location, this.matchingKey);
    }

    getContent(assignment: Assignment): JSX.Element {

        if (assignment.value.arguments === null) {

            assignment.value.operandType = operandType.function;
            assignment.value.dataType = dataType.string;
            assignment.value.function = 'Match';
            assignment.value.arguments = [operandHelper.getEmpty()];
        }

        return (
            <OperandSet
                maxArgumentsCount={20}
                isReadOnly={this.props.isReadOnly}
                assignment={''}
                function="Match"
                set={assignment.value.arguments as Operand[]}
                assignmentNumber={assignment.number}
                reportConfig={this.props.reportConfig}
                collections={this.props.collections}
                onCountChange={this.onCountChange}
                onOperandClick={this.onOperandClick} />
        );
    }

    onReorderConditions(matchingKey: MatchingKey, error?: string) {

        if (error && !matchingKey.errors.includes(error)) {
            matchingKey.errors.push(error);
        }
        let location = {} as ReportLogicLocation;

        location.statement = this.isEndPoint ? locationType.statement.matchingKeyEndPoint : locationType.statement.matchingKey;

        this.props.onChange(location, matchingKey);
    }

    getConditionContent(matchingKey: MatchingKey): JSX.Element {

        let location = {} as ReportLogicLocation;

        location.statement = this.isEndPoint ? locationType.statement.matchingKeyEndPoint : locationType.statement.matchingKey;
        location.statementNumber = this.isEndPoint ? 2 : 1;
        location.argumentIndexes = [];

        let hasAssignment = typeHelper.isObject(matchingKey.assignment);

        return (
            <>
                {
                    hasAssignment &&
                    <>
                        <AssignmentBuilder
                            isReadOnly={this.props.isReadOnly}
                            location={location}
                            assignment={matchingKey.assignment}
                            fieldName={this.matchingKeyConfig.data.name}
                            functions={this.props.reportConfig.userDefinedFunctions}
                            lookups={this.props.reportConfig.lookups}
                            dictionaries={this.props.reportConfig.dictionaries}
                            variables={this.props.reportConfig.variables}
                            dataSources={this.props.collections.dataSources}
                            reportFields={this.props.collections.reportFields}
                            customDataSourceFields={this.props.collections.customDataSourceFields}
                            onAddAssignmentClick={this.props.onAddAssignmentClick}
                            onRemoveAssignmentClick={this.props.onRemoveAssignmentClick}
                            onResetAssignmentClick={this.props.onResetAssignmentClick}
                            onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                            onParenthesesChange={this.props.onParenthesesChange}
                            onAddConditionClick={this.props.onAddConditionClick}
                            onRemoveConditionClick={this.props.onRemoveConditionClick}
                            onComparisonTypeChange={this.props.onComparisonTypeChange}
                            onOperandClick={this.props.onConditionOperandClick}
                            onCopyClick={this.props.onCopyClick}
                            onPasteClick={this.props.onPasteClick}
                            onValidateFieldClick={this.props.onValidateDeclarationClick}
                            allowPaste={this.props.allowPaste}
                            getContent={this.getContent}
                            onReorderAssignmentConditions={(error) => { this.onReorderConditions(matchingKey, error); }}
                        />
                    </>
                }
                {
                    !hasAssignment &&
                    <>
                        <OperandSet
                            maxArgumentsCount={20}
                            isReadOnly={this.props.isReadOnly}
                            assignment={`[${this.matchingKeyConfig.data.name}] = `}
                            function="Match"
                            set={matchingKey.operands}
                            assignmentNumber={1}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onCountChange={this.onCountChange}
                            onOperandClick={this.onOperandClick} />

                        <div className="code-block">
                            {
                                !this.props.isReadOnly &&
                                <button className="helper-btn" onClick={() => { this.onAddFirstConditionClick() }}>+ Condition</button>
                            }
                        </div>
                    </>
                }

                <ErrorList errors={matchingKey.errors} className="mt-2" />
            </>
        );
    }

    render(): JSX.Element {
        return (
            <ReportLogicList
                isWaiting={this.props.isWaiting}
                items={[this.matchingKey]}
                columns={getColumns({ name: this.matchingKeyConfig.data.name, type: this.matchingKeyConfig.data.type, info: this.matchingKeyConfig.info })}
                getContent={this.getConditionContent}
                getKey={() => 1}
                createHtmlId={() => this.isEndPoint ? reportConfigNavigation.matchingKeyEndPointId : reportConfigNavigation.matchingKeyId} />
        );
    }
}

export default MatchingKeyBuilder;
