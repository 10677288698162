import typeHelper from '../common/typeHelper';

const getVisibility = (visible: boolean): React.CSSProperties => {

    return { display: visible ? 'block' : 'none' };
};

const buildClassName = (primary: string, optional?: string): string => {

    let classes = [primary];

    if (typeHelper.isString(optional)) {

        classes.push(optional as string);
    }

    return classes.join(' ');
};

export {
    getVisibility,
    buildClassName
};
