import './ReportConfigEditor.scss';
import { endpointFilterHelper, underReportFilterHelper, overReportFilterHelper } from '../../../infrastructure/helpers/functions/endpointFilterHelper';
import { getDefaultExpanded, keyword, removeExpandedFromLocalStorage } from './ReportConfigEditor.Helpers';
import { htmlIds } from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation.HtmlIds';
import { reportType } from '../../../infrastructure/constants/reportType';
import { Unsubscribe } from 'redux';
import AccuracyDataSourceForm from '../DataSources/AccuracyDataSourceForm';
import AccuracyValidation from '../../../types/report/AccuracyValidation';
import accuracyValidationHelper from '../../../infrastructure/helpers/functions/accuracyValidation/accuracyValidationHelper';
import accuracyValidationValidator from '../../../infrastructure/helpers/functions/validator/accuracyValidationValidator';
import ActionItemModal from '../../Common/Modals/ActionItemModal/ActionItemModal';
import actions from '../../../store/actions';
import AggregatedRecordBuilder from '../Aggregation/AggregatedRecordBuilder';
import aggregatedRecordFieldHelper from '../../../infrastructure/helpers/functions/aggregation/aggregatedRecordFieldHelper';
import Aggregation from '../../../types/report/Aggregation';
import AggregationBlockBuilder from '../Aggregation/AggregationBlockBuilder';
import aggregationValidator from '../../../infrastructure/helpers/functions/validator/aggregationValidator';
import ArgumentDefinition from '../../../types/functions/ArgumentDefinition';
import ButtonContext from '../../../infrastructure/helpers/common/ButtonContext';
import CaseBuilder from '../Case/Case/CaseBuilder';
import caseHelper from '../../../infrastructure/helpers/functions/caseHelper';
import ClickableErrorList from '../../Common/ErrorList/ClickableErrorList';
import ComplianceCommentModal from '../../Common/Modals/ComplianceCommentModal/ComplianceCommentModal';
import ComplianceUrlModal from '../../Common/Modals/ComplianceUrlModal/ComplianceUrlModal';
import customDataSourceHelper from '../../../infrastructure/helpers/functions/customDataSource/customDataSourceHelper';
import DataSourceField from '../../../types/report/DataSourceField';
import DataSourceForm from '../DataSources/DataSourceForm';
import dataSourceHelper from '../../../infrastructure/helpers/functions/common/dataSourceHelper';
import DeclarationBlockBuilder from '../../Functions/Declaration/DeclarationBlockBuilder';
import domNavigationHelper from '../../../infrastructure/helpers/html/domNavigationHelper';
import EndpointFilter from '../../../types/report/EndpointFilter';
import EndpointFilterBuilder from '../EndpointFilter/EndpointFilterBuilder';
import EnrichmentBuilder from '../Enrichment/Enirchment/EnrichmentBuilder';
import enrichmentHelper from '../../../infrastructure/helpers/functions/enrichment/enrichmentHelper';
import EnrichmentSelector from '../Enrichment/EnrichmentSelector/EnrichmentSelector';
import enrichmentValidator from '../../../infrastructure/helpers/functions/validator/enrichmentValidator';
import FilterBuilder from '../Filtering/Filter/FilterBuilder';
import filterHelper from '../../../infrastructure/helpers/functions/filterHelper';
import guidance from './ReportConfigEditor.Guidance';
import isin from '../../../infrastructure/constants/functions/isin';
import selectorValidator from '../../../infrastructure/helpers/functions/validator/selectorValidator';
import Selector from '../../../types/report/Selector';
import lei from '../../../infrastructure/constants/functions/lei';
import exchangeRates from '../../../infrastructure/constants/functions/exchangeRates';
import locationType from '../../../types/functions/Location/LocationType';
import Lookup from '../../../types/functions/Lookup';
import LookupBuilder from '../Lookup/LookupBuilder';
import lookupHelper from '../../../infrastructure/helpers/functions/lookup/lookupHelper';
import matchingKey from '../../../infrastructure/constants/functions/matchingKey';
import MatchingKeyBuilder from '../MatchingKey/MatchingKeyBuilder';
import ObjectIndexer from '../../../infrastructure/types/ObjectIndexer';
import Operand from '../../../types/functions/Operand/Operand';
import operandRuleHelper from '../../../infrastructure/helpers/functions/operand/operandRuleHelper';
import OperandRules from '../../../types/functions/Operand/OperandRules';
import React from 'react';
import referenceValidator from '../../../infrastructure/helpers/functions/validator/referenceValidator';
import ReportCase from '../../../types/report/ReportCase';
import ReportCaseField from '../../../types/report/ReportCaseField';
import ReportCollections from '../../../infrastructure/types/Functions/ReportCollections';
import ReportConfig from '../../../types/report/ReportConfig';
import ReportConfigCounts from '../../../types/report/ReportConfigCounts';
import reportFieldHelper from '../../../infrastructure/helpers/functions/common/reportFieldHelper';
import ReportFilter from '../../../types/report/ReportFilter';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import reportPageMode from '../Pages/ReportPage/ReportPageMode';
import ReportStepIndicator from '../ReportStepIndicator';
import ReportVariable from '../../../types/report/ReportVariable';
import Section from './Section';
import StatementBlockBuilder from '../../Functions/Statement/StatementBlockBuilder';
import store from '../../../store/store';
import SubHeader from '../../Common/SubHeader/SubHeader';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import ValidationBuilder from '../Validation/ValidationBuilder';
import VariableBuilder from '../Variable/VariableBuilder';
import variableHelper from '../../../infrastructure/helpers/functions/variable/variableHelper';
import variableValidator from '../../../infrastructure/helpers/functions/validator/variableValidator';
import matchingKeyEndPoint from '../../../infrastructure/constants/functions/matchingKeyEndPoint';
import { MatchingKeyHelper } from '../../../infrastructure/helpers/functions/matchingKey/matchingKeyHelper';
import SelectorBlockBuilder from '../Selectors/SelectorBlockBuilder';
import MatchingKey from '../../../types/report/MatchingKey';
import matchingKeyDeclarationHelper from '../../../infrastructure/helpers/functions/matchingKey/matchingKeyDeclarationHelper';
import matchingKeyValidator from '../../../infrastructure/helpers/functions/validator/matchingKeyValidator';
import ReportConfigComponentLock from '../../../types/report/ReportConfigComponentLock';
import MatchingKeyOperandSetBlockBuilder from '../MatchingKey/OperandSet/MatchingKeyOperandSetBlockBuilder';
import ActionType from '../../../types/report/ActionType';
import ComponentType from '../../../types/report/ComponentType';
import annaDsbUpiEnrichment from '../../../infrastructure/constants/functions/annaDsbUpiEnrichment';
import annaDsbUpi from '../../../infrastructure/constants/functions/annaDsbUpi';
import lse from '../../../infrastructure/constants/functions/lse';
import DictionaryBlockBuilder from '../Dictionaries/DictionaryBlockBuilder';
import Dictionary from '../../../types/report/Dictionary';
import DictionaryFieldBuilder from '../Dictionaries/DictionaryFieldBuilder';
import DictionaryField from '../../../types/report/DictionaryField';
import dictionaryFieldHelper from '../../../infrastructure/helpers/functions/dictionaries/dictionaryFieldHelper';
import dictionaryHelper from '../../../infrastructure/helpers/functions/dictionaries/dictionaryHelper';
import dictionaryValidator from '../../../infrastructure/helpers/functions/validator/dictionaryValidator';
import getArgumentDefinition from '../../../infrastructure/helpers/functions/common/argumentHelper';
import lookup from '../../../infrastructure/constants/functions/lookup';
import AggregationSelector from '../Aggregation/AggregationSelector';
import UserDefinedFunctionParameterBuilder from '../UserDefinedFunctions/UserDefinedFunctionParameterBuilder';
import UserDefinedFunction from '../../../types/report/UserDefinedFunction';
import UserDefinedFunctionParameter from '../../../types/report/UserDefinedFunctionParameter';
import userDefinedFunctionParameterHelper from '../../../infrastructure/helpers/functions/userDefinedFunctions/userDefinedFunctionsParameterHelper';
import UserDefinedFunctionBodyBuilder from '../UserDefinedFunctions/UserDefinedFunctionBodyBuilder';
import userDefinedFunctionBodyHelper from '../../../infrastructure/helpers/functions/userDefinedFunctions/userDefinedFunctionBodyHelper';
import UserDefinedFunctionBuilder from '../UserDefinedFunctions/UserDefinedFunctionBuilder';
import userDefinedFunctionHelper from '../../../infrastructure/helpers/functions/userDefinedFunctions/userDefinedFunctionHelper';
import userDefinedFunctionValidator from '../../../infrastructure/helpers/functions/validator/userDefinedFunctionValidator';
import firdsFcaInstrument from '../../../infrastructure/constants/functions/firdsFcaInstrument';
import firdsEsmaInstrument from '../../../infrastructure/constants/functions/firdsEsmaInstrument';
import fcaRegulatedEntities from '../../../infrastructure/constants/functions/fcaRegulatedEntities';

interface Props {
    isWaiting: boolean;
    isReadOnly: boolean;
    aggregationNumber: number | null;
    isinSelectorNumber: number | null;
    leiSelectorNumber: number | null;
    exchangeRatesSelectorNumber: number | null;
    annaDsbUpiEnrichmentSelectorNumber: number | null;
    annaDsbUpiSelectorNumber: number | null;
    lseSelectorNumber: number | null;
    firdsFcaInstrumentSelectorNumber: number | null;
    firdsEsmaInstrumentSelectorNumber: number | null;
    fcaRegulatedEntitiesSelectorNumber: number | null;
    caseNumber: number | null;
    dictionaryNumber: number | null;
    functionNumber: number | null;
    collections: ReportCollections;
    reportConfig: ReportConfig;
    counts: ReportConfigCounts;
    clipboard: ReportLogicLocation | null;
    onAggregationSelected: (number: number) => void;
    onIsinSelectorSelected: (number: number) => void;
    onLeiSelectorSelected: (number: number) => void;
    onExchangeRatesSelectorSelected: (number: number) => void;
    onAnnaDsbUpiEnrichmentSelectorSelected: (number: number) => void;
    onAnnaDsbUpiSelectorSelected: (number: number) => void;
    onLSESelectorSelected: (number: number) => void;
    onFirdsFcaInstrumentSelected: (number: number) => void;
    onFirdsEsmaInstrumentSelected: (number: number) => void;
    onCaseSelected: (number: number, callback?: () => void) => void;
    onFCARegulatedEntitiesSelected: (number: number) => void;
    onDictionarySelected: (number: number) => void;
    onFunctionSelected: (number: number) => void;
    onDataSourceChange: (reportConfig: ReportConfig) => void;
    onAggregationChange: (number: number, aggregations: Aggregation[]) => void;
    onAggregatedRecordFieldChange: (number: number, fields: DataSourceField[]) => void;
    onLookupChange: (lookups: Lookup[]) => void;
    onIsinSelectorChange: (isinSelectors: Selector[]) => void;
    onLeiSelectorChange: (leiSelectors: Selector[]) => void;
    onExchangeRatesSelectorChange: (exchangeRates: Selector[]) => void;
    onAnnaDsbUpiEnrichmentSelectorChange: (selectors: Selector[]) => void;
    onAnnaDsbUpiSelectorChange: (selectors: Selector[]) => void;
    onFirdsFcaInstrumentChanged: (selectors: Selector[]) => void;
    onFirdsEsmaInstrumentChanged: (selectors: Selector[]) => void;
    onLSESelectorChange: (selectors: Selector[]) => void;
    onFCARegulatedEntitiesSelectorChange: (selectors: Selector[]) => void;
    onVariableChange: (variables: ReportVariable[]) => void;
    onFilterChange: (filters: ReportFilter[]) => void;
    onEndpointFilterChange: (filters: EndpointFilter[]) => void;
    onValidationChange: (validations: AccuracyValidation[]) => void;
    onCaseChange: (cases: ReportCase[]) => void;
    onEnrichmentChange: (number: number, fields: ReportCaseField[]) => void;
    onSyncedWithIdChange: (number: number, syncedWithId: number | null) => void;
    onMatchingKeyChange: (matchingKey: MatchingKey) => void;
    onMatchingKeyEndPointChange: (matchingKeyEndPoint: MatchingKey) => void;
    onDictionaryChange: (dictionaries: Dictionary[]) => void;
    onDictionaryFieldChange: (number: number, fields: DictionaryField[]) => void;
    onFunctionChange: (functions: UserDefinedFunction[]) => void;
    onFunctionParameterChange: (number: number, fields: UserDefinedFunctionParameter[]) => void;
    onClipboardChange: (clipboard: ReportLogicLocation) => void;
    onLookupConditionUpdated: (lookup: Lookup) => void;
    onImportSuccess: () => void;
    errors: ButtonContext[];
    regime: string;
    onComponentContainerClick?: (reportConfigComponentLock: ReportConfigComponentLock) => Promise<boolean>
    onActionItemsCountChange: (actiontype: ActionType, componentType: ComponentType, moduleTypeId: number) => void;
    onAggregationSyncedWithIdChange: (number: number, syncedWithId: number | null) => void;
}

interface State {
    expandedSections: ObjectIndexer<boolean>;
}

class ReportConfigEditor extends React.Component<Props, State> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: Props) {
        super(props);

        this.state = { expandedSections: getDefaultExpanded() };

        this.navigationListener = this.navigationListener.bind(this);
        this.onToggleSectionClick = this.onToggleSectionClick.bind(this);
        this.isExpanded = this.isExpanded.bind(this);
        this.onEnrichmentChange = this.onEnrichmentChange.bind(this);
        this.onAggregatedRecordFieldChange = this.onAggregatedRecordFieldChange.bind(this);
        this.onDictionaryFieldChange = this.onDictionaryFieldChange.bind(this);
        this.onFunctionParameterChange = this.onFunctionParameterChange.bind(this);

        this.validateRemoveLookup = this.validateRemoveLookup.bind(this);
        this.validateRemoveIsinSelector = this.validateRemoveIsinSelector.bind(this);
        this.validateRemoveLeiSelector = this.validateRemoveLeiSelector.bind(this);
        this.validateRemoveExchangeRatesSelector = this.validateRemoveExchangeRatesSelector.bind(this);
        this.validateRemoveVariable = this.validateRemoveVariable.bind(this);
        this.validateRemoveCase = this.validateRemoveCase.bind(this);
        this.validateRemoveLSESelector = this.validateRemoveLSESelector.bind(this);
        this.validateRemoveFirdsFcaInstrumentSelector = this.validateRemoveFirdsFcaInstrumentSelector.bind(this);
        this.validateRemoveFirdsEsmaInstrumentSelector = this.validateRemoveFirdsEsmaInstrumentSelector.bind(this);
        this.validateRemoveFCARegulatedEntitiesSelector = this.validateRemoveFCARegulatedEntitiesSelector.bind(this);

        this.validateIsinSelector = this.validateIsinSelector.bind(this);
        this.validateLeiSelector = this.validateLeiSelector.bind(this);
        this.validateExchangeRatesSelector = this.validateExchangeRatesSelector.bind(this);
        this.validateReportVariable = this.validateReportVariable.bind(this);
        this.validateReportCaseField = this.validateReportCaseField.bind(this);
        this.validateAggregatedRecordField = this.validateAggregatedRecordField.bind(this);
        this.validateAccuracyValidation = this.validateAccuracyValidation.bind(this);
        this.validateAnnaDsbUpiEnrichmentSelector = this.validateAnnaDsbUpiEnrichmentSelector.bind(this);
        this.validateLSESelector = this.validateLSESelector.bind(this);
        this.validateFirdsFcaInstrumentSelector = this.validateFirdsFcaInstrumentSelector.bind(this);
        this.validateFirdsEsmaInstrumentSelector = this.validateFirdsEsmaInstrumentSelector.bind(this);
        this.validateFCARegulatedEntitiesSelector = this.validateFCARegulatedEntitiesSelector.bind(this);
        this.validateDictionaryField = this.validateDictionaryField.bind(this);
        this.validateFunctionBody = this.validateFunctionBody.bind(this);
        this.validateFunctionParameter = this.validateFunctionParameter.bind(this);

        this.getBundleEnrichment = this.getBundleEnrichment.bind(this);
        this.getBundleIsinSelector = this.getBundleIsinSelector.bind(this);
        this.getBundleLeiSelector = this.getBundleLeiSelector.bind(this);
        this.getBundleExchangeRatesSelector = this.getBundleExchangeRatesSelector.bind(this);
        this.getBundleVariable = this.getBundleVariable.bind(this);
        this.getBundleAggregatedRecordField = this.getBundleAggregatedRecordField.bind(this);
        this.getBundleValidation = this.getBundleValidation.bind(this);
        this.getBundleMatchingKey = this.getBundleMatchingKey.bind(this);
        this.getArgumentDefinition = this.getArgumentDefinition.bind(this);
        this.getBundleAnnaDsbUpiEnrichmentSelector = this.getBundleAnnaDsbUpiEnrichmentSelector.bind(this);
        this.validateRemoveAnnaDsbUpiEnrichmentSelector = this.validateRemoveAnnaDsbUpiEnrichmentSelector.bind(this);
        this.validateRemoveAnnaDsbUpiSelector = this.validateRemoveAnnaDsbUpiSelector.bind(this);
        this.getBundleAnnaDsbUpiSelector = this.getBundleAnnaDsbUpiSelector.bind(this);
        this.getBundleLSESelector = this.getBundleLSESelector.bind(this);
        this.getBundleFCARegulatedEntitiesSelector = this.getBundleFCARegulatedEntitiesSelector.bind(this);
        this.getBundleFirdsFcaInstrumentSelector = this.getBundleFirdsFcaInstrumentSelector.bind(this);
        this.getBundleFirdsEsmaInstrumentSelector = this.getBundleFirdsEsmaInstrumentSelector.bind(this);
        this.getBundleLookup = this.getBundleLookup.bind(this);
        this.getBundleEndpointFilter = this.getBundleEndpointFilter.bind(this);
        this.getBundleDictionaryField = this.getBundleDictionaryField.bind(this);
        this.getBundleFunctionParameter = this.getBundleFunctionParameter.bind(this);
        this.getBundleFunctionBody = this.getBundleFunctionBody.bind(this);
        this.onAggregationChange = this.onAggregationChange.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.navigationListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();

        removeExpandedFromLocalStorage();
    }

    navigationListener(): void {

        let state = store.getState();
        if (state.action !== actions.navigation.reportConfigSection) {
            return;
        }

        let expandedSections = this.state.expandedSections;

        expandedSections[state.navigateToReportConfigSection.expandHtmlId] = true;

        this.setState({ expandedSections: expandedSections }, () => domNavigationHelper.navigate(state.navigateToReportConfigSection.scrollToHtmlId));
    }

    onToggleSectionClick(htmlId: string): void {

        let expandedSections = this.state.expandedSections;

        expandedSections[htmlId] = !expandedSections[htmlId];

        if (expandedSections[htmlId]) {
            localStorage.setItem(htmlId, 'true');
        }
        else {
            localStorage.removeItem(htmlId);
        }

        this.setState({ expandedSections: expandedSections });
    }

    isExpanded(htmlId: string): boolean {

        return this.state.expandedSections[htmlId];
    }

    onEnrichmentChange(fields: ReportCaseField[]): void {

        this.props.onEnrichmentChange(this.props.caseNumber as number, fields);
    }

    onAggregationChange(aggregations: Aggregation[]): void {

        this.props.onAggregationChange(this.props.aggregationNumber as number, aggregations);
    }

    onAggregatedRecordFieldChange(fields: DataSourceField[]): void {

        this.props.onAggregatedRecordFieldChange(this.props.aggregationNumber as number, fields);
    }

    onDictionaryFieldChange(fields: DictionaryField[]): void {

        this.props.onDictionaryFieldChange(this.props.dictionaryNumber as number, fields);
    }

    onFunctionParameterChange(parameters: UserDefinedFunctionParameter[]): void {

        this.props.onFunctionParameterChange(this.props.functionNumber as number, parameters);
    }

    validateRemoveLookup(number: number): string | null {

        return referenceValidator.validateLookupReferences(number, this.props.reportConfig);
    }

    validateRemoveIsinSelector(location: ReportLogicLocation): string | null {

        var isin = this.props.reportConfig.isinSelectors.find(x => x.number === location.statementNumber);
        var isinnumber = this.props.reportConfig.isinSelectors[0].number || 1;
        if (isin !== undefined && isin !== null) {
            isinnumber = isin.number || 1;
        }
        return referenceValidator.validateFirdsReferences(isinnumber, this.props.reportConfig, this.props.collections.customDataSourceFields);
    }

    validateRemoveLeiSelector(location: ReportLogicLocation): string | null {

        var lei = this.props.reportConfig.leiSelectors.find(x => x.number === location.statementNumber);
        var leiNumber = this.props.reportConfig.leiSelectors[0].number || 1;
        if (lei !== undefined && lei !== null) {
            leiNumber = lei.number || 1;
        }

        return referenceValidator.validateLeiReferences(leiNumber, this.props.reportConfig, this.props.collections.customDataSourceFields);
    }

    validateRemoveExchangeRatesSelector(location: ReportLogicLocation): string | null {

        var exchangeRates = this.props.reportConfig.exchangeRatesSelectors.find(x => x.number === location.statementNumber);
        var exchangeRatesNumber = this.props.reportConfig.exchangeRatesSelectors[0].number || 1;
        if (exchangeRates !== undefined && exchangeRates !== null) {
            exchangeRatesNumber = exchangeRates.number || 1;
        }

        return referenceValidator.validateExchangeRatesReferences(exchangeRatesNumber, this.props.reportConfig, this.props.collections.customDataSourceFields);
    }

    validateRemoveAnnaDsbUpiEnrichmentSelector(location: ReportLogicLocation): string | null {

        // cannot be referenced
        return null;
    }

    validateRemoveLSESelector(location: ReportLogicLocation): string | null {

        // cannot be referenced
        return null;
    }

    validateRemoveFCARegulatedEntitiesSelector(location: ReportLogicLocation): string | null {

        // cannot be referenced
        return null;
    }

    validateRemoveFirdsFcaInstrumentSelector(location: ReportLogicLocation): string | null {

        // cannot be referenced
        return null;
    }

    validateRemoveFirdsEsmaInstrumentSelector(location: ReportLogicLocation): string | null {

        // cannot be referenced
        return null;
    }

    validateRemoveAnnaDsbUpiSelector(location: ReportLogicLocation): string | null {

        // cannot be referenced
        return null;
    }

    validateRemoveVariable(location: ReportLogicLocation): string | null {

        return referenceValidator.validateVariableReferences(location.statementNumber, this.props.reportConfig);
    }

    validateRemoveCase(number: number): string | null {

        if (this.props.reportConfig.cases.length === 1) {

            return 'Unable to remove case. At least one case is required';
        }

        let $case = this.props.reportConfig.cases.find(x => x.number === number) as ReportCase;

        let dependent = this.props.reportConfig.cases.filter(x => typeHelper.isNumber(x.syncedWithId) && x.syncedWithId === $case.id);

        if (dependent.length > 0) {

            return `Unable to remove case. The following cases are kept in sync with it: ${dependent.map(x => x.name).join(', ')}`;
        }

        return null;
    }

    validateIsinSelector(isinSelector: Selector): void {

        selectorValidator.validateSelector(isinSelector, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.collections.dataSources, this.props.collections.reportFields, isin.type);
    }

    validateLeiSelector(leiSelector: Selector): void {

        selectorValidator.validateSelector(leiSelector, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.collections.dataSources, this.props.collections.reportFields, lei.type);
    }

    validateExchangeRatesSelector(exchangeRatesSelector: Selector): void {

        selectorValidator.validateSelector(exchangeRatesSelector, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.collections.dataSources, this.props.collections.reportFields, exchangeRates.type);
    }

    validateAnnaDsbUpiEnrichmentSelector(selector: Selector): void {

        selectorValidator.validateSelector(selector, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.collections.dataSources, this.props.collections.reportFields, annaDsbUpiEnrichment.type);
    }

    validateFirdsFcaInstrumentSelector(selector: Selector): void {

        selectorValidator.validateSelector(selector, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.collections.dataSources, this.props.collections.reportFields, firdsFcaInstrument.type);
    }

    validateFirdsEsmaInstrumentSelector(selector: Selector): void {

        selectorValidator.validateSelector(selector, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.collections.dataSources, this.props.collections.reportFields, firdsEsmaInstrument.type);
    }

    validateLSESelector(selector: Selector): void {

        selectorValidator.validateSelector(selector, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.collections.dataSources, this.props.collections.reportFields, lse.type);
    }

    validateFCARegulatedEntitiesSelector(selector: Selector): void {

        selectorValidator.validateSelector(selector, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.collections.dataSources, this.props.collections.reportFields, fcaRegulatedEntities.type);
    }

    validateReportVariable(variable: ReportVariable): void {

        variableValidator.validateVariable(variable, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.variables, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields);
    }

    validateReportCaseField(field: ReportCaseField): void {

        enrichmentValidator.validateField(field, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields);
    }

    validateAggregatedRecordField(field: DataSourceField): void {

        aggregationValidator.validateField(field, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources);
    }

    validateAccuracyValidation(validation: AccuracyValidation): void {

        accuracyValidationValidator.validateValidation(validation, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields);
    }

    validateMatchingKey(matcingKey: MatchingKey): void {

        matchingKeyValidator.validate(matcingKey);
    }

    validateDictionaryField(field: DictionaryField): void {

        dictionaryValidator.validateField(field, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.dictionaries, this.props.collections.dataSources);
    }

    validateFunctionParameter(param: UserDefinedFunctionParameter): void {

        userDefinedFunctionValidator.validateParameter(param, this.props.reportConfig.userDefinedFunctions);
    }

    validateFunctionBody(func: UserDefinedFunction): void {

        userDefinedFunctionValidator.validateFunction(func, this.props.reportConfig.userDefinedFunctions);
    }

    getBundleEnrichment(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let dataSourceId;
        if (this.props.reportConfig.type === 'Accuracy') {
            dataSourceId = this.props.reportConfig.refDataSources[0].id;
        }
        else {
            dataSourceId = enrichmentHelper.getDataSourceId(this.props.reportConfig, location);
        }

        let legitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, dataSourceId);

        let operandRules = operandRuleHelper.getForReportField(argumentDefinition, fieldDefinition, legitimacy, injectTypeConversion);

        return [operandRules, fieldDefinition.name];
    }

    getBundleIsinSelector(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let operandRules = operandRuleHelper.getForSelector(argumentDefinition, fieldDefinition, injectTypeConversion, isin.type, locationType.statement.isinSelector);

        return [operandRules, isin.name];
    }

    getBundleLeiSelector(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let operandRules = operandRuleHelper.getForSelector(argumentDefinition, fieldDefinition, injectTypeConversion, lei.type, locationType.statement.leiSelector);

        return [operandRules, lei.name];
    }

    getBundleExchangeRatesSelector(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let operandRules = operandRuleHelper.getForSelector(argumentDefinition, fieldDefinition, injectTypeConversion, exchangeRates.type, locationType.statement.exchangeRatesSelector);

        return [operandRules, exchangeRates.name];
    }

    getBundleAnnaDsbUpiEnrichmentSelector(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let operandRules = operandRuleHelper.getForSelector(argumentDefinition, fieldDefinition, injectTypeConversion, annaDsbUpiEnrichment.type, locationType.statement.annaDsbUpiEnrichmentSelector);

        return [operandRules, annaDsbUpiEnrichment.name];
    }

    getBundleFirdsFcaInstrumentSelector(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let operandRules = operandRuleHelper.getForSelector(argumentDefinition, fieldDefinition, injectTypeConversion, firdsFcaInstrument.type, locationType.statement.firdsFcaInstrumentSelector);

        return [operandRules, firdsFcaInstrument.name];
    }

    getBundleFirdsEsmaInstrumentSelector(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let operandRules = operandRuleHelper.getForSelector(argumentDefinition, fieldDefinition, injectTypeConversion, firdsEsmaInstrument.type, locationType.statement.firdsEsmaInstrumentSelector);

        return [operandRules, firdsFcaInstrument.name];
    }

    getBundleLSESelector(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let operandRules = operandRuleHelper.getForSelector(argumentDefinition, fieldDefinition, injectTypeConversion, lse.type, locationType.statement.lseSelector);

        return [operandRules, lse.name];
    }

    getBundleFCARegulatedEntitiesSelector(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let operandRules = operandRuleHelper.getForSelector(argumentDefinition, fieldDefinition, injectTypeConversion, fcaRegulatedEntities.type, locationType.statement.fcaRegulatedEntitiesSelector);

        return [operandRules, fcaRegulatedEntities.name];
    }

    getBundleAnnaDsbUpiSelector(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let operandRules = operandRuleHelper.getForSelector(argumentDefinition, fieldDefinition, injectTypeConversion, annaDsbUpi.type, locationType.statement.annaDsbUpiSelector);

        return [operandRules, annaDsbUpi.name];
    }

    getBundleVariable(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let variable = variableHelper.getDeclaration(this.props.reportConfig.variables, location);

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let legitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, variable.dataSourceId);

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let operandRules = operandRuleHelper.getForVariable(argumentDefinition, variable, legitimacy, fieldDefinition, injectTypeConversion);

        return [operandRules, variable.name];
    }

    getBundleAggregatedRecordField(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let fieldDefinition = dataSourceHelper.getFieldDefinitionById(this.props.collections.dataSources, location.fieldId);

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let operandRules = operandRuleHelper.getForAggregatedRecordField(argumentDefinition, fieldDefinition, injectTypeConversion);

        return [operandRules, fieldDefinition.name];
    }

    getBundleEndpointFilter(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let legitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, null);

        let operandRules = operandRuleHelper.getForMatchingKey(argumentDefinition, legitimacy, fieldDefinition, injectTypeConversion);

        return [operandRules, ''];
    }

    getBundleValidation(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let validation = accuracyValidationHelper.getDeclaration(this.props.reportConfig.validations, location);

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let legitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, null);

        let operandRules = operandRuleHelper.getForValidation(argumentDefinition, legitimacy, fieldDefinition, injectTypeConversion);

        return [operandRules, validation.identifier];
    }

    getBundleMatchingKey(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let legitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, null);

        let operandRules = operandRuleHelper.getForMatchingKey(argumentDefinition, legitimacy, fieldDefinition, injectTypeConversion);

        return [operandRules, ''];
    }

    getBundleLookup(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let fieldDefinition = reportFieldHelper.getById(this.props.collections.reportFields, location.fieldId);

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let legitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, null);

        let operandRules = operandRuleHelper.getForLookup(argumentDefinition, legitimacy, fieldDefinition, injectTypeConversion, lookup.type);

        return [operandRules, ''];
    }

    getBundleDictionaryField(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let legitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, null);

        let dictionary = dictionaryHelper.get(this.props.reportConfig.dictionaries, location);

        let field = dictionaryFieldHelper(location.statementNumber).getDeclaration(dictionary.fields, location);

        let operandRules = operandRuleHelper.getForDictionaryField(argumentDefinition, legitimacy, field, injectTypeConversion);

        return [operandRules, field.name];
    }

    getBundleFunctionParameter(_location: ReportLogicLocation, _operand: Operand, _root: Operand, _injectTypeConversion: boolean): [OperandRules, string] {

        let operandRules = operandRuleHelper.getForFunctionParameter();

        return [operandRules, ''];
    }

    getBundleFunctionBody(location: ReportLogicLocation, operand: Operand, root: Operand, injectTypeConversion: boolean): [OperandRules, string] {

        let argumentDefinition = this.getArgumentDefinition(location, operand, root);

        let legitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, null);

        let func = userDefinedFunctionHelper.get(this.props.reportConfig.userDefinedFunctions, location);

        let operandRules = operandRuleHelper.getForFunctionBody(argumentDefinition, legitimacy, func, injectTypeConversion);

        return [operandRules, func.name];
    }

    getArgumentDefinition(location: ReportLogicLocation, operand: Operand, root: Operand): ArgumentDefinition | null {

        if (operand === root) {

            return null;
        }

        let argumentDefinition = getArgumentDefinition(null, root, location, this.props.reportConfig.dictionaries, this.props.reportConfig.userDefinedFunctions);

        return argumentDefinition;
    }

    render(): JSX.Element {

        return (
            <>
                <div className="report-config-editor">
                    <SubHeader isWaiting={this.props.isWaiting}>
                        <ReportStepIndicator reportType={this.props.reportConfig.type} />
                    </SubHeader>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Data Sources"
                        htmlId={htmlIds.dataSources}
                        info={guidance.dataSources}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <div className="page-section mb-4">
                            <div className="row">
                                <div className="col-12">
                                    {
                                        (this.props.reportConfig.type === reportType.accuracy) &&
                                        <AccuracyDataSourceForm
                                            dataSources={this.props.collections.dataSourceOptions}
                                            reportConfig={this.props.reportConfig}
                                            onChange={this.props.onDataSourceChange}
                                            collections={this.props.collections} />
                                    }
                                    {
                                        this.props.reportConfig.type !== reportType.accuracy &&
                                        <DataSourceForm
                                            isWaiting={this.props.isWaiting}
                                            dataSources={this.props.collections.dataSourceOptions}
                                            reportConfig={this.props.reportConfig}
                                            onChange={this.props.onDataSourceChange}
                                            collections={this.props.collections}
                                            mode={this.props.isReadOnly ? reportPageMode.readOnly : reportPageMode.edit}
                                            setSelectedCase={this.props.onCaseSelected} />
                                    }
                                </div>
                            </div>
                        </div>

                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="User Defined Functions"
                        htmlId={htmlIds.userDefinedFunctions}
                        info={guidance.userDefinedFunctions}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <UserDefinedFunctionBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            functionNumber={this.props.functionNumber}
                            reportConfig={this.props.reportConfig}
                            clipboard={this.props.clipboard}
                            collections={this.props.collections}
                            onSelected={this.props.onFunctionSelected}
                            onChange={this.props.onFunctionChange}
                            onClipboardChange={this.props.onClipboardChange}>

                            <DeclarationBlockBuilder
                                childComponentType={UserDefinedFunctionBodyBuilder}
                                isWaiting={this.props.isWaiting}
                                isReadOnly={this.props.isReadOnly}
                                declarationBatchNumber={this.props.functionNumber}
                                reportConfig={this.props.reportConfig}
                                counts={this.props.counts}
                                clipboard={this.props.clipboard}
                                collections={this.props.collections}
                                onDeclarationChange={this.props.onFunctionChange}
                                onClipboardChange={this.props.onClipboardChange}
                                getOperandRuleBundle={this.getBundleFunctionBody}
                                getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                                validate={this.validateFunctionBody}
                                validateRemoveDeclaration={() => null}
                                declarationHelper={userDefinedFunctionBodyHelper}
                                type={locationType.statement.userDefinedFunction}
                                initialDoNotPopulate={false} />

                        </UserDefinedFunctionBuilder>

                        <DeclarationBlockBuilder
                            childComponentType={UserDefinedFunctionParameterBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            declarationBatchNumber={this.props.functionNumber}
                            reportConfig={this.props.reportConfig}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            collections={this.props.collections}
                            onDeclarationChange={this.onFunctionParameterChange}
                            onClipboardChange={this.props.onClipboardChange}
                            getOperandRuleBundle={this.getBundleFunctionParameter}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateFunctionParameter}
                            validateRemoveDeclaration={() => null}
                            declarationHelper={userDefinedFunctionParameterHelper(this.props.functionNumber as number)}
                            type={locationType.statement.userDefinedFunctionParameter}
                            initialDoNotPopulate={false} />

                    </Section>
                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Dictionaries"
                        htmlId={htmlIds.dictionaries}
                        info={guidance.dictionaries}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <DictionaryBlockBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            dictionaryNumber={this.props.dictionaryNumber}
                            reportConfig={this.props.reportConfig}
                            clipboard={this.props.clipboard}
                            collections={this.props.collections}
                            onSelected={this.props.onDictionarySelected}
                            onChange={this.props.onDictionaryChange}
                            onClipboardChange={this.props.onClipboardChange}>

                            <DeclarationBlockBuilder
                                childComponentType={DictionaryFieldBuilder}
                                isWaiting={this.props.isWaiting}
                                isReadOnly={this.props.isReadOnly}
                                declarationBatchNumber={this.props.dictionaryNumber}
                                reportConfig={this.props.reportConfig}
                                counts={this.props.counts}
                                clipboard={this.props.clipboard}
                                collections={this.props.collections}
                                onDeclarationChange={this.onDictionaryFieldChange}
                                onClipboardChange={this.props.onClipboardChange}
                                getOperandRuleBundle={this.getBundleDictionaryField}
                                getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                                validate={this.validateDictionaryField}
                                validateRemoveDeclaration={() => null}
                                declarationHelper={dictionaryFieldHelper(this.props.dictionaryNumber as number)}
                                type={locationType.statement.dictionaryField}
                                initialDoNotPopulate={false} />
                        </DictionaryBlockBuilder>

                    </Section>
                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Cache Keys"
                        htmlId={htmlIds.cacheKeys}
                        info={guidance.cacheKeys}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <SelectorBlockBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            selectorNumber={this.props.isinSelectorNumber}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onSelected={this.props.onIsinSelectorSelected}
                            onChange={this.props.onIsinSelectorChange}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            onClipboardChange={this.props.onClipboardChange}
                            getBundleSelector={this.getBundleIsinSelector}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateIsinSelector}
                            validateRemoveDeclaration={this.validateRemoveIsinSelector}
                            onComponentContainerClick={this.props.onComponentContainerClick}
                            locationTypeStatement={locationType.statement.isinSelector}
                            componentType={ComponentType.IsinSelector} />

                        <SelectorBlockBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            selectorNumber={this.props.leiSelectorNumber}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onSelected={this.props.onLeiSelectorSelected}
                            onChange={this.props.onLeiSelectorChange}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            onClipboardChange={this.props.onClipboardChange}
                            getBundleSelector={this.getBundleLeiSelector}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateLeiSelector}
                            validateRemoveDeclaration={this.validateRemoveLeiSelector}
                            onComponentContainerClick={this.props.onComponentContainerClick}
                            locationTypeStatement={locationType.statement.leiSelector}
                            componentType={ComponentType.LeiSelector} />

                        <SelectorBlockBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            selectorNumber={this.props.exchangeRatesSelectorNumber}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onSelected={this.props.onExchangeRatesSelectorSelected}
                            onChange={this.props.onExchangeRatesSelectorChange}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            onClipboardChange={this.props.onClipboardChange}
                            getBundleSelector={this.getBundleExchangeRatesSelector}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateExchangeRatesSelector}
                            validateRemoveDeclaration={this.validateRemoveExchangeRatesSelector}
                            onComponentContainerClick={this.props.onComponentContainerClick}
                            locationTypeStatement={locationType.statement.exchangeRatesSelector}
                            componentType={ComponentType.ExchangeRatesSelector} />

                        <SelectorBlockBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            selectorNumber={this.props.annaDsbUpiEnrichmentSelectorNumber}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onSelected={this.props.onAnnaDsbUpiEnrichmentSelectorSelected}
                            onChange={this.props.onAnnaDsbUpiEnrichmentSelectorChange}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            onClipboardChange={this.props.onClipboardChange}
                            getBundleSelector={this.getBundleAnnaDsbUpiEnrichmentSelector}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateAnnaDsbUpiEnrichmentSelector}
                            validateRemoveDeclaration={this.validateRemoveAnnaDsbUpiEnrichmentSelector}
                            onComponentContainerClick={this.props.onComponentContainerClick}
                            locationTypeStatement={locationType.statement.annaDsbUpiEnrichmentSelector}
                            componentType={ComponentType.AnnaDsbUpiEnrichmentSelector} />

                        <SelectorBlockBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            selectorNumber={this.props.annaDsbUpiSelectorNumber}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onSelected={this.props.onAnnaDsbUpiSelectorSelected}
                            onChange={this.props.onAnnaDsbUpiSelectorChange}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            onClipboardChange={this.props.onClipboardChange}
                            getBundleSelector={this.getBundleAnnaDsbUpiSelector}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateExchangeRatesSelector}
                            validateRemoveDeclaration={this.validateRemoveAnnaDsbUpiSelector}
                            onComponentContainerClick={this.props.onComponentContainerClick}
                            locationTypeStatement={locationType.statement.annaDsbUpiSelector}
                            componentType={ComponentType.AnnaDsbUpiSelector} />

                        <SelectorBlockBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            selectorNumber={this.props.lseSelectorNumber}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onSelected={this.props.onLSESelectorSelected}
                            onChange={this.props.onLSESelectorChange}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            onClipboardChange={this.props.onClipboardChange}
                            getBundleSelector={this.getBundleLSESelector}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateLSESelector}
                            validateRemoveDeclaration={this.validateRemoveLSESelector}
                            onComponentContainerClick={this.props.onComponentContainerClick}
                            locationTypeStatement={locationType.statement.lseSelector}
                            componentType={ComponentType.LSESelector} />

                        <SelectorBlockBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            selectorNumber={this.props.firdsFcaInstrumentSelectorNumber}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onSelected={this.props.onFirdsFcaInstrumentSelected}
                            onChange={this.props.onFirdsFcaInstrumentChanged}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            onClipboardChange={this.props.onClipboardChange}
                            getBundleSelector={this.getBundleFirdsFcaInstrumentSelector}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateFirdsFcaInstrumentSelector}
                            validateRemoveDeclaration={this.validateRemoveFirdsFcaInstrumentSelector}
                            onComponentContainerClick={this.props.onComponentContainerClick}
                            locationTypeStatement={locationType.statement.firdsFcaInstrumentSelector}
                            componentType={ComponentType.FirdsFcaInstrumentSelectors} />

                        <SelectorBlockBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            selectorNumber={this.props.firdsEsmaInstrumentSelectorNumber}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onSelected={this.props.onFirdsEsmaInstrumentSelected}
                            onChange={this.props.onFirdsEsmaInstrumentChanged}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            onClipboardChange={this.props.onClipboardChange}
                            getBundleSelector={this.getBundleFirdsEsmaInstrumentSelector}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateFirdsEsmaInstrumentSelector}
                            validateRemoveDeclaration={this.validateRemoveFirdsEsmaInstrumentSelector}
                            onComponentContainerClick={this.props.onComponentContainerClick}
                            locationTypeStatement={locationType.statement.firdsEsmaInstrumentSelector}
                            componentType={ComponentType.FirdsEsmaInstrumentSelectors} />

                        <SelectorBlockBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            selectorNumber={this.props.fcaRegulatedEntitiesSelectorNumber}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onSelected={this.props.onFCARegulatedEntitiesSelected}
                            onChange={this.props.onFCARegulatedEntitiesSelectorChange}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            onClipboardChange={this.props.onClipboardChange}
                            getBundleSelector={this.getBundleFCARegulatedEntitiesSelector}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateFCARegulatedEntitiesSelector}
                            validateRemoveDeclaration={this.validateRemoveFCARegulatedEntitiesSelector}
                            onComponentContainerClick={this.props.onComponentContainerClick}
                            locationTypeStatement={locationType.statement.fcaRegulatedEntitiesSelector}
                            componentType={ComponentType.FCARegulatedEntitiesSelector} />

                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Lookups"
                        htmlId={htmlIds.lookups}
                        info={guidance.lookups}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <StatementBlockBuilder
                            childComponentType={LookupBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            caseNumber={this.props.caseNumber}
                            reportConfig={this.props.reportConfig}
                            statements={this.props.reportConfig.lookups}
                            counts={this.props.counts}
                            collections={this.props.collections}
                            clipboard={null}
                            onChange={this.props.onLookupChange}
                            onClipboardChange={() => { }}
                            validateRemoveStatement={this.validateRemoveLookup}
                            onConditionUpdate={this.props.onLookupConditionUpdated}
                            statementHelper={lookupHelper}
                            details={{ statement: locationType.statement.lookup, name: 'Lookup', keyword: keyword.lookup }}
                            onComponentContainerClick={this.props.onComponentContainerClick}
                            getOperandRuleBundle={this.getBundleLookup} />

                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Variables"
                        htmlId={htmlIds.variables}
                        info={guidance.variables}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <DeclarationBlockBuilder
                            childComponentType={VariableBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            declarationBatchNumber={this.props.caseNumber}
                            reportConfig={this.props.reportConfig}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            collections={this.props.collections}
                            onDeclarationChange={this.props.onVariableChange}
                            onClipboardChange={this.props.onClipboardChange}
                            getOperandRuleBundle={this.getBundleVariable}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateReportVariable}
                            validateRemoveDeclaration={this.validateRemoveVariable}
                            declarationHelper={variableHelper}
                            type={locationType.statement.variable}
                            initialDoNotPopulate={false}
                            onDragAndDrop={this.props.onVariableChange}
                            onComponentContainerClick={this.props.onComponentContainerClick} />

                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Filtering Logic"
                        htmlId={htmlIds.filters}
                        info={guidance.filters}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <StatementBlockBuilder
                            childComponentType={FilterBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            caseNumber={this.props.caseNumber}
                            reportConfig={this.props.reportConfig}
                            counts={this.props.counts}
                            statements={this.props.reportConfig.filters}
                            collections={this.props.collections}
                            clipboard={this.props.clipboard}
                            onChange={this.props.onFilterChange}
                            onClipboardChange={this.props.onClipboardChange}
                            validateRemoveStatement={() => null}
                            onConditionUpdate={() => { }}
                            statementHelper={filterHelper}
                            details={{ statement: locationType.statement.filter, name: 'Filter', keyword: keyword.if, keyword2: keyword.exclude }} />

                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Aggregation"
                        htmlId={htmlIds.aggregations}
                        info={guidance.aggregations}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <AggregationSelector
                            isWaiting={this.props.isWaiting}
                            aggregationNumber={this.props.aggregationNumber}
                            aggregations={this.props.reportConfig.aggregations}
                            onAggregationSelected={this.props.onAggregationSelected}
                            onAggregationChange={this.props.onAggregationChange}
                            onSyncedWithIdChange={this.props.onAggregationSyncedWithIdChange} />

                        <AggregationBlockBuilder
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            aggregationNumber={this.props.aggregationNumber}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onSelected={this.props.onAggregationSelected}
                            onChange={this.onAggregationChange}
                            clipboard={this.props.clipboard}
                            onClipboardChange={this.props.onClipboardChange}>

                            <DeclarationBlockBuilder
                                childComponentType={AggregatedRecordBuilder}
                                isWaiting={this.props.isWaiting}
                                isReadOnly={this.props.isReadOnly}
                                declarationBatchNumber={this.props.aggregationNumber}
                                reportConfig={this.props.reportConfig}
                                counts={this.props.counts}
                                clipboard={this.props.clipboard}
                                collections={this.props.collections}
                                onDeclarationChange={this.onAggregatedRecordFieldChange}
                                onClipboardChange={this.props.onClipboardChange}
                                getOperandRuleBundle={this.getBundleAggregatedRecordField}
                                getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                                validate={this.validateAggregatedRecordField}
                                validateRemoveDeclaration={() => null}
                                declarationHelper={aggregatedRecordFieldHelper}
                                type={locationType.statement.aggregatedRecordField}
                                initialDoNotPopulate={true} />

                        </AggregationBlockBuilder>

                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Endpoint Filtering Logic"
                        htmlId={htmlIds.endpointFilters}
                        info={guidance.endpointFilters}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <StatementBlockBuilder
                            childComponentType={EndpointFilterBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            caseNumber={null}
                            reportConfig={this.props.reportConfig}
                            counts={this.props.counts}
                            statements={this.props.reportConfig.endpointFilters}
                            collections={this.props.collections}
                            clipboard={this.props.clipboard}
                            onChange={this.props.onEndpointFilterChange}
                            onClipboardChange={this.props.onClipboardChange}
                            validateRemoveStatement={() => null}
                            onConditionUpdate={() => { }}
                            statementHelper={endpointFilterHelper}
                            details={{ statement: locationType.statement.endpointFilter, name: 'Endpoint Filter', keyword: keyword.if, keyword2: keyword.exclude }}
                            getOperandRuleBundle={this.getBundleEndpointFilter} />
                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Expected Under Report Logic"
                        htmlId={htmlIds.expectedUnderReport}
                        info={guidance.expectedUnderReport}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <StatementBlockBuilder
                            childComponentType={EndpointFilterBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            caseNumber={null}
                            reportConfig={this.props.reportConfig}
                            counts={this.props.counts}
                            statements={this.props.reportConfig.endpointFilters}
                            collections={this.props.collections}
                            clipboard={this.props.clipboard}
                            onChange={this.props.onEndpointFilterChange}
                            onClipboardChange={this.props.onClipboardChange}
                            validateRemoveStatement={() => null}
                            onConditionUpdate={() => { }}
                            statementHelper={underReportFilterHelper}
                            details={{ statement: locationType.statement.underReportFilter, name: 'Under Report Filter', keyword: keyword.if, keyword2: keyword.underReport }} />

                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Expected Over Report Logic"
                        htmlId={htmlIds.expectedOverReport}
                        info={guidance.expectedOverReport}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <StatementBlockBuilder
                            childComponentType={EndpointFilterBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            caseNumber={null}
                            reportConfig={this.props.reportConfig}
                            counts={this.props.counts}
                            statements={this.props.reportConfig.endpointFilters}
                            collections={this.props.collections}
                            clipboard={this.props.clipboard}
                            onChange={this.props.onEndpointFilterChange}
                            onClipboardChange={this.props.onClipboardChange}
                            validateRemoveStatement={() => null}
                            onConditionUpdate={() => { }}
                            statementHelper={overReportFilterHelper}
                            details={{ statement: locationType.statement.overReportFilter, name: 'Over Report Filter', keyword: keyword.if, keyword2: keyword.overReport }} />

                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Reporting Case Identification logic"
                        htmlId={htmlIds.cases}
                        info={guidance.cases}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <StatementBlockBuilder
                            childComponentType={CaseBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            caseNumber={this.props.caseNumber}
                            reportConfig={this.props.reportConfig}
                            statements={this.props.reportConfig.cases}
                            counts={this.props.counts}
                            collections={this.props.collections}
                            clipboard={this.props.clipboard}
                            onChange={this.props.onCaseChange}
                            onClipboardChange={this.props.onClipboardChange}
                            validateRemoveStatement={this.validateRemoveCase}
                            onConditionUpdate={() => { }}
                            statementHelper={caseHelper}
                            details={{ statement: locationType.statement.case, name: 'Reporting Case', keyword: keyword.if, keyword2: keyword.report }} />

                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Matching Keys"
                        htmlId={htmlIds.matchingKeys}
                        info={guidance.matchingKeys}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <MatchingKeyOperandSetBlockBuilder
                            childComponentType={MatchingKeyBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            selectedSetNumber={null}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            clipboard={this.props.clipboard}
                            onRemoveClick={() => { }}
                            onItemChange={(_location: ReportLogicLocation, matchingKey: MatchingKey) => this.props.onMatchingKeyChange(matchingKey)}
                            onChange={this.props.onMatchingKeyChange}
                            onClipboardChange={this.props.onClipboardChange}
                            onDeclarationChange={matchingKeys => this.props.onMatchingKeyChange(matchingKeys[0])}
                            declarationHelper={matchingKeyDeclarationHelper}
                            helper={new MatchingKeyHelper(matchingKey.name)}
                            configurationData={{ data: matchingKey, info: guidance.matchingKey }}
                            details={{
                                statement: locationType.statement.matchingKey,
                                expectedType: matchingKey.type,
                                name: matchingKey.name
                            }}
                            type={locationType.statement.matchingKey}
                            validate={this.validateMatchingKey}
                            getOperandRuleBundle={this.getBundleMatchingKey} />


                        <MatchingKeyOperandSetBlockBuilder
                            childComponentType={MatchingKeyBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            selectedSetNumber={null}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            clipboard={this.props.clipboard}
                            onRemoveClick={() => { }}
                            onItemChange={(_location: ReportLogicLocation, matchingKeyEndPoint: MatchingKey) => this.props.onMatchingKeyEndPointChange(matchingKeyEndPoint)}
                            onChange={this.props.onMatchingKeyEndPointChange}
                            onClipboardChange={this.props.onClipboardChange}
                            onDeclarationChange={matchingKeys => this.props.onMatchingKeyEndPointChange(matchingKeys[0])}
                            declarationHelper={matchingKeyDeclarationHelper}
                            helper={new MatchingKeyHelper(matchingKeyEndPoint.name)}
                            configurationData={{ data: matchingKeyEndPoint, info: guidance.matchingKeyEndPoint }}
                            details={{
                                statement: locationType.statement.matchingKeyEndPoint,
                                expectedType: matchingKeyEndPoint.type,
                                name: matchingKeyEndPoint.name
                            }}
                            type={locationType.statement.matchingKeyEndPoint}
                            validate={this.validateMatchingKey}
                            getOperandRuleBundle={this.getBundleMatchingKey} />


                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Field Enrichment"
                        htmlId={htmlIds.enrichment}
                        info={guidance.enrichment}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <EnrichmentSelector
                            isWaiting={this.props.isWaiting}
                            caseNumber={this.props.caseNumber}
                            cases={this.props.reportConfig.cases}
                            onCaseSelected={this.props.onCaseSelected}
                            onEnrichmentChange={this.props.onEnrichmentChange}
                            onSyncedWithIdChange={this.props.onSyncedWithIdChange} />

                        <DeclarationBlockBuilder
                            childComponentType={EnrichmentBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            declarationBatchNumber={this.props.caseNumber}
                            reportConfig={this.props.reportConfig}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            collections={this.props.collections}
                            onDeclarationChange={this.onEnrichmentChange}
                            onClipboardChange={this.props.onClipboardChange}
                            getOperandRuleBundle={this.getBundleEnrichment}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateReportCaseField}
                            validateRemoveDeclaration={() => null}
                            declarationHelper={enrichmentHelper}
                            type={locationType.statement.reportField}
                            initialDoNotPopulate={true} />

                    </Section>

                    <Section
                        isWaiting={this.props.isWaiting}
                        reportType={this.props.reportConfig.type}
                        heading="Validations"
                        htmlId={htmlIds.validations}
                        info={guidance.validations}
                        onClick={this.onToggleSectionClick}
                        isExpanded={this.isExpanded}>

                        <DeclarationBlockBuilder
                            childComponentType={ValidationBuilder}
                            isWaiting={this.props.isWaiting}
                            isReadOnly={this.props.isReadOnly}
                            declarationBatchNumber={null}
                            reportConfig={this.props.reportConfig}
                            counts={this.props.counts}
                            clipboard={this.props.clipboard}
                            collections={this.props.collections}
                            onDeclarationChange={this.props.onValidationChange}
                            onClipboardChange={this.props.onClipboardChange}
                            getOperandRuleBundle={this.getBundleValidation}
                            getReportFieldOperandRuleBundle={this.getBundleEnrichment}
                            validate={this.validateAccuracyValidation}
                            validateRemoveDeclaration={() => null}
                            declarationHelper={accuracyValidationHelper}
                            type={locationType.statement.validation}
                            initialDoNotPopulate={false}
                            onComponentContainerClick={this.props.onComponentContainerClick} />

                    </Section>

                    <ClickableErrorList className="mt-3 mb-3" errors={this.props.errors} />

                    <ActionItemModal reportConfigId={this.props.reportConfig.id as number} regime={this.props.regime} onActionItemsCountChange={this.props.onActionItemsCountChange} />
                    <ComplianceCommentModal />
                    <ComplianceUrlModal />
                </div>
            </>
        );
    }
}

export default ReportConfigEditor;
