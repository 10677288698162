import ArgumentDefinition from '../../../../types/functions/ArgumentDefinition';
import Condition from '../../../../types/functions/Condition/Condition';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import Operand from '../../../../types/functions/Operand/Operand';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import operandType from '../../../constants/functions/operandType';
import copyObject from '../../common/copyObject';
import typeHelper from '../../common/typeHelper';
import conditionHelper from '../condition/conditionHelper';
import { getArgumentDefinitionByIndex, getFunctionDefinition } from '../function/functionHelper.Helpers';
import operandHelper from '../operand/operandHelper';
import reportLogicLocationHelper from '../report/reportLogicLocationHelper';

const getForFunction = (func: string, index: number): ArgumentDefinition | null => {

    let definition = getFunctionDefinition(func);

    return getArgumentDefinitionByIndex(definition, index);
};

const getForDictionary = (number: number | null, index: number, dictionaries: Dictionary[]): ArgumentDefinition | null => {

    let dictionary = dictionaries.find(x => x.number === number) as Dictionary;

    if (index >= dictionary.fields.length) {

        throw new RangeError('Argument index is out of range');
    }

    let field = dictionary.fields[index];

    return {
        allowedTypes: [field.dataType],
        name: field.name,
        isArray: false,
        minArrayArgumentCount: null,
        specialOperandTypes: null,
        specialValueType: null,
        defaultValue: null
    }
};

const getForUserDefinedFunction = (number: number | null, index: number, functions: UserDefinedFunction[]): ArgumentDefinition | null => {

    let func = functions.find(x => x.number === number) as UserDefinedFunction;

    if (index >= func.parameters.length) {

        throw new RangeError('Argument index is out of range');
    }

    let parameter = func.parameters[index];

    return {
        allowedTypes: [parameter.dataType],
        name: parameter.name,
        isArray: false,
        minArrayArgumentCount: null,
        specialOperandTypes: null,
        specialValueType: null,
        defaultValue: null
    }
};

const getArgumentDefinition = (condition: Condition | null, rootOperand: Operand | null, location: ReportLogicLocation, dictionaries: Dictionary[], functions: UserDefinedFunction[]): ArgumentDefinition | null => {

    if (reportLogicLocationHelper.isRoot(location)) {
        return null;
    }

    let copy = copyObject(location);

    let i = copy.argumentIndexes.pop() as number;

    let op = typeHelper.isObject(condition) ?
        conditionHelper.getOperand(condition as Condition, copy) :
        operandHelper.getOperand(rootOperand as Operand, copy);

    if (op.operandType === operandType.function) {

        return getForFunction(op.function, i);
    }

    if (op.operandType === operandType.dictionary) {

        return getForDictionary(op.dictionaryNumber, i, dictionaries);
    }

    if (op.operandType === operandType.userDefinedFunction) {

        return getForUserDefinedFunction(op.functionNumber, i, functions);
    }

    return null;
};

export default getArgumentDefinition;