import { createDataSourceOption, createAggregationOption } from './CaseBuilder.Helpers';
import { ReactComponent as AssignmentIcon } from '../../../Common/BootstrapIcons/assignment-late.svg';
import { ReactComponent as CommentIcon } from '../../../Common/BootstrapIcons/comment.svg';
import { ReactComponent as RemoveIcon } from '../../../Common/BootstrapIcons/close.svg';
import Aggregation from '../../../../types/report/Aggregation';
import BespokeToggleButton from '../../../Functions/Bespoke/BespokeToggleButton';
import ColumnDefinition from '../../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../../Common/BootstrapIcons/Custom/CustomIcon';
import DraggableItem from '../../../Common/DraggableItem/DraggableItem';
import EditableText from '../../../Common/EditableText/EditableText';
import iconTooltip from '../../../Common/BootstrapIcons/Tooltip/IconTooltip';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import OptionalSelectList from './OptionalSelectList';
import PromiseButton from '../../../Common/PromiseButton';
import React from 'react';
import ReportCase from '../../../../types/report/ReportCase';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';

const getNumberContent = (number: number, reorder: (dragNumber: number, dropNumber: number) => void, isReadOnly: boolean): string | JSX.Element => {

    if (isReadOnly) {
        return number.toString();
    }

    return (
        <DraggableItem identifier={number} onDrop={reorder}>
            {number}
        </DraggableItem>
    );
};

const getColumns = (
    onDataSourceChange: (dataSourceId: string, number: number) => void,
    onNameChange: (name: string, number: number) => void,
    onIsSystemGeneratedChange: (value: boolean, number: number, dataSourceId: number) => void,
    onIsSeparateOutputChange: (value: boolean, number: number) => void,
    onAggregatedChange: (aggregated: boolean, number: number) => void,
    onAggregationChange: (aggregationNumber: number, caseNumber: number) => void,
    onBusinessDescriptionChange: (businessDescription: string, number: number) => void,
    onBespokeToggleClick: (isBespoke: boolean, $case: ReportCase) => void,
    onRemoveClick: (number: number) => void,
    onCommentClick: (id: number) => Promise<any>,
    onActionItemClick: (id: number) => Promise<any>,
    reorder: (dragNumber: number, dropNumber: number) => void,
    getCommentCount: (id: number) => number,
    getActionItemCount: (id: number) => number,
    dataSources: IngestionConfig[],
    aggregations: Aggregation[],
    isReadOnly: boolean
): ColumnDefinition<ReportCase>[] => {

    let columns: ColumnDefinition<ReportCase>[] = [
        {
            header: 'Reference',
            getContent: x => getNumberContent(x.number, reorder, isReadOnly),
            width: '110px'
        },
        {
            header: 'Reporting Case Id Name',
            getContent: x => <EditableText disabled={isReadOnly} value={x.name} onChange={value => onNameChange(value, x.number)} multiline={false} />,
            width: '200px'
        },
        {
            header: 'Business Description',
            getContent: x => <EditableText disabled={isReadOnly} value={x.businessDescription} onChange={value => onBusinessDescriptionChange(value, x.number)} multiline={true} style={{ width: '100%', height: '60px' }} />
        },
        {
            header: 'Aggregated',
            getContent: x =>
                <OptionalSelectList
                    ticked={typeHelper.isNumber(x.aggregationNumber)}
                    onTickChange={ticked => onAggregatedChange(ticked, x.number)}
                    value={x.aggregationNumber?.toString() || ''}
                    onValueChange={value => onAggregationChange(parseInt(value), x.number)}
                    options={aggregations.filter(a => a.dataSourceId === x.dataSourceId).map(createAggregationOption)}
                    isReadOnly={isReadOnly || !aggregations.some(a => a.dataSourceId === x.dataSourceId)} />,
            width: '180px'
        },
        {
            header: 'System Generated',
            getContent: x =>
                <OptionalSelectList
                    ticked={x.isSystemGenerated}
                    onTickChange={ticked => onIsSystemGeneratedChange(ticked, x.number, x.dataSourceId)}
                    value={x.dataSourceId.toString()}
                    onValueChange={value => onDataSourceChange(value, x.number)}
                    options={dataSources.map(createDataSourceOption)}
                    isReadOnly={isReadOnly} />,
            width: '258px'
        },
        {
            header: 'Separate Output',
            getContent: x =>
                <input
                    type="checkbox"
                    checked={x.isSeparateOutput}
                    onChange={e => onIsSeparateOutputChange(e.target.checked, x.number)}
                    disabled={isReadOnly} />,
            width: '140px'
        },
        {
            header: '',
            getContent: x =>
                <div className="float-right">
                    {
                        !isReadOnly &&
                        <BespokeToggleButton
                            isBespoke={x.isBespoke}
                            onClick={isBespoke => onBespokeToggleClick(isBespoke, x)}
                            style={{ verticalAlign: 'top', width: '150px' }} />
                    }
                    {
                        typeHelper.isNumber(x.id) &&
                        <>
                            <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onCommentClick(x.id as number)}>
                                <CustomIcon icon={CommentIcon} count={getCommentCount(x.id as number)} title={iconTooltip.comments} />
                            </PromiseButton>
                            <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onActionItemClick(x.id as number)}>
                                <CustomIcon icon={AssignmentIcon} count={getActionItemCount(x.id as number)} title={iconTooltip.actionLog} />
                            </PromiseButton>
                        </>
                    }
                    <button
                        className="custom-icon-button"
                        onClick={() => onRemoveClick(x.number)}
                        disabled={isReadOnly}>
                        <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                    </button>
                </div>,
            width: '254px'
        }
    ];

    return columns;
};

export {
    getColumns
};