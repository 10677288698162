import { firdsResultSetOptions } from './FirdsResultSetBuilder.Helpers';
import RadioButtonGroup from '../../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';

interface Props {
    value: string;
    onChange: (value: string) => void;
}

class FirdsResultSetBuilder extends React.Component<Props> {

    render(): JSX.Element {

        return (
            <div className="mt-3">
                <RadioButtonGroup
                    options={firdsResultSetOptions}
                    name="firds-types"
                    value={this.props.value}
                    onChange={this.props.onChange} />
            </div>
        );
    }
}

export default FirdsResultSetBuilder;
