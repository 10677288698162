const wrapInQuotes = (value: string): string => '"' + value + '"';

const wrapInBrackets = (value: string): string => '[' + value + ']';

const getFieldIdentifier = (dataSourceName: string, fieldName: string): string => wrapInBrackets(dataSourceName) + '.' + wrapInBrackets(fieldName);

const wrapInBracketsVariable = (number: number, name: string) => `[V${number}-${name}]`;

const pseudocodeHelper = {
    wrapInQuotes,
    wrapInBrackets,
    getFieldIdentifier,
    wrapInBracketsVariable
};

export default pseudocodeHelper;
