import Modal from '../../Modal/Modal';
import React from 'react';
import SelectSearch, { SelectedOptionValue } from 'react-select-search';
import SelectList from '../../SelectList/SelectList';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import ReportConfig from '../../../../types/report/ReportConfig';
import referenceHelper from './ReferenceModal.Helpers';
import './ReferenceModal.scss';
import IdAndName from '../../../../types/report/IdAndName';
import ReportCollections from '../../../../infrastructure/types/Functions/ReportCollections';
import ComponentType from '../../../../types/report/ComponentType';
import { getComponentType, onReferenceSelected } from '../../../../infrastructure/helpers/report/reference/helper';

interface Props {
    reportConfig: ReportConfig;
    onClose: () => void;
    defaultResultOptionTitle: string;
    defaultSourceOptionTitle: string;
    defaultFieldsOptionTitle: string;
    sourcesTitle: string;
    fieldsTitle: string;
    modalTitle: string;
    fields: IdAndName[];
    operandType: string;
    collections: ReportCollections;
    onResultFieldSelected?: (value: string, selectedComponentType: ComponentType, mainComponentType: ComponentType) => void;
}

interface State {
    fieldSearchResultsOptions: SelectOption[]
}
class ReferencesModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            fieldSearchResultsOptions: [{ text: this.props.defaultResultOptionTitle, value: this.props.defaultResultOptionTitle }]
        }

        this.onFieldDropdownChange = this.onFieldDropdownChange.bind(this);
        this.onChangeFieldResult = this.onChangeFieldResult.bind(this);
    }
    
    onFieldDropdownChange(value: SelectedOptionValue | SelectedOptionValue[]) {

        let references = referenceHelper.getSelectOptions(this.props.collections, this.props.reportConfig, this.props.operandType, [parseInt(value.toString())], this.props.defaultResultOptionTitle);

        this.setState({ fieldSearchResultsOptions: references });
    }

    onChangeFieldResult(value: string) {
        if (this.props.onResultFieldSelected) {

            let parts = value.split('-');

            let mainComponentType = getComponentType(parts[0]);

            let selectedComponentType = getComponentType(parts[2]);

            this.props.onResultFieldSelected(value, selectedComponentType, mainComponentType);
        }
        else {
            onReferenceSelected(value);
        }
    }

    render(): JSX.Element {
        return (
            <div className='referenceModalContainer'>
                <Modal state={{ isOpen: true, title: this.props.modalTitle, error: '' }} close={this.props.onClose}>
                    <div className='flex-container'>
                        <label className="max-width-150px padding-10px margin-0-important">{this.props.sourcesTitle}</label>
                        <SelectList
                            value=''
                            key='source dropdown'
                            options={referenceHelper.getSelectOptions(this.props.collections, this.props.reportConfig, this.props.operandType, this.props?.fields?.map(f => f.id) || [], this.props.defaultSourceOptionTitle)}
                            className="form-control"
                            onChange={this.onChangeFieldResult}
                            includeEmptyOption={false} />
                    </div>
                    <div className='flex-container margin-top-20px'>
                        <label className="max-width-150px padding-10px margin-0-important">{this.props.fieldsTitle}</label>
                        <div className='display-flex width-100-percents'>
                            <SelectSearch
                                key='fields dropdown'
                                options={referenceHelper.getFieldsOptions(this.props.fields, this.props.defaultFieldsOptionTitle)}
                                onChange={this.onFieldDropdownChange}
                                search={true}
                            />
                            <SelectList
                                value=''
                                key='field search results dropdown'
                                options={this.state.fieldSearchResultsOptions}
                                className="margin-left-15px form-control"
                                onChange={this.onChangeFieldResult}
                                includeEmptyOption={false} />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ReferencesModal;
