import { getColumns } from './AggregationBuilder.Grid';
import Aggregation from '../../../types/report/Aggregation';
import aggregationGroupByHelper from '../../../infrastructure/helpers/functions/aggregation/aggregationGroupByHelper';
import aggregationHelper from '../../../infrastructure/helpers/functions/aggregation/aggregationHelper';
import dataSourceHelper from '../../../infrastructure/helpers/functions/common/dataSourceHelper';
import ErrorList from '../../Common/ErrorList/ErrorList';
import locationType from '../../../types/functions/Location/LocationType';
import OperandSet from '../../Functions/OperandSet/OperandSet';
import React from 'react';
import referenceValidator from '../../../infrastructure/helpers/functions/validator/referenceValidator';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import conditionHelper from '../../../infrastructure/helpers/functions/condition/conditionHelper';
import ConditionBuilder from '../../Functions/Condition/ConditionBuilder';
import AggregationOperandSetBuilderProps from './OperandSet/AggregationOperandSetBuilderProps';
import Condition from '../../../types/functions/Condition/Condition';

class AggregationBuilder extends React.Component<AggregationOperandSetBuilderProps> {

    constructor(props: AggregationOperandSetBuilderProps) {
        super(props);

        this.onNameChange = this.onNameChange.bind(this);
        this.onDataSourceChange = this.onDataSourceChange.bind(this);
        this.onBusinessDescriptionChange = this.onBusinessDescriptionChange.bind(this);
        this.onRemoveAggregationClick = this.onRemoveAggregationClick.bind(this);
        this.onGroupByValueClick = this.onGroupByValueClick.bind(this);
        this.onGroupByArgumentCountChange = this.onGroupByArgumentCountChange.bind(this);
        this.getAssignmentContent = this.getAssignmentContent.bind(this);
        this.onAddFirstConditionClick = this.onAddFirstConditionClick.bind(this);
    }

    onNameChange(name: string, number: number): void {

        let location = this.getLocation(number, []);

        this.props.onChange(location, { name: name } as Aggregation);
    }

    onDataSourceChange(dataSourceId: string, number: number): void {

        let changes = {} as Aggregation;

        let location = this.getLocation(number, []);

        let error = referenceValidator.validateAggregationReferences(number, this.props.reportConfig);
        if (error) {

            changes.errors = [error];

            this.props.onChange(location, changes);

            return;
        }

        let dataSource = dataSourceHelper.getById(this.props.collections.dataSources, parseInt(dataSourceId));

        changes.fields = [];

        aggregationHelper.reset(changes, dataSource);

        this.props.onChange(location, changes);
    }

    onBusinessDescriptionChange(businessDescription: string, number: number): void {

        let location = this.getLocation(number, []);

        this.props.onChange(location, { businessDescription: businessDescription } as Aggregation);
    }

    onRemoveAggregationClick(number: number): void {

        let location = this.getLocation(number, []);

        this.props.onRemoveClick(location);
    }

    onGroupByValueClick(aggregation: Aggregation, argumentIndexes: number[]): void {

        let location = this.getLocation(aggregation.number, argumentIndexes);

        location.pieceOfCode = locationType.pieceOfCode.operandsList;

        this.props.onOperandClick(location);
    }

    onGroupByArgumentCountChange(number: number, value: number): void {

        let location = this.getLocation(number, []);

        let aggregation = aggregationHelper.get(this.props.reportConfig.aggregations, location);

        aggregationGroupByHelper.handleArgumentCountChange(aggregation.groupBy, value);

        this.props.onChange(location, aggregation);
    }

    getLocation(number: number, argumentIndexes: number[]): ReportLogicLocation {

        let location = {} as ReportLogicLocation;

        location.statement = locationType.statement.aggregation;
        location.statementNumber = number;
        location.argumentIndexes = argumentIndexes;

        return location;
    }

    onAddFirstConditionClick(number: number): void {

        let location = this.getLocation(number, []);

        let aggregation = aggregationHelper.get(this.props.reportConfig.aggregations, location);

        let condition = conditionHelper.getEmpty();
        aggregation.condition = condition;

        this.props.onChange(location, aggregation);
    }

    onReorderConditions(condition: Condition, aggregation: Aggregation) {
        let location = this.getLocation(aggregation.number, []);
        this.props.onChange(location, { condition } as Aggregation);
    }

    getAssignmentContent(aggregation: Aggregation): JSX.Element {

        let hasCondition = typeHelper.isObject(aggregation.condition);

        return (
            <>
                {
                    hasCondition &&
                    <>
                        <ConditionBuilder
                            isReadOnly={this.props.isReadOnly}
                            allowCopy={true}
                            keyword="IF"
                            allwaysAllowDelete={true}
                            functions={this.props.reportConfig.userDefinedFunctions}
                            lookups={this.props.reportConfig.lookups}
                            dictionaries={this.props.reportConfig.dictionaries}
                            variables={this.props.reportConfig.variables}
                            dataSources={this.props.collections.dataSources}
                            reportFields={[]}
                            customDataSourceFields={this.props.collections.customDataSourceFields}
                            location={{ statement: locationType.statement.aggregation, statementNumber: aggregation.number } as ReportLogicLocation}
                            condition={aggregation.condition}
                            onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                            onParenthesesChange={this.props.onParenthesesChange}
                            onAddConditionClick={this.props.onAddConditionClick}
                            onRemoveConditionClick={this.props.onRemoveConditionClick}
                            onComparisonTypeChange={this.props.onComparisonTypeChange}
                            onOperandClick={this.props.onConditionOperandClick}
                            onCopyClick={this.props.onCopyClick}
                            onPasteClick={this.props.onPasteClick}
                            allowPaste={this.props.allowPaste}
                            onReorderConditions={(condition) => { this.onReorderConditions(condition, aggregation); }} />

                        <div className="code-block">
                            <span className="code">THEN </span>
                            <OperandSet
                                maxArgumentsCount={5}
                                isReadOnly={this.props.isReadOnly}
                                assignment={`[${dataSourceHelper.getById(this.props.collections.dataSources, aggregation.dataSourceId).name}].`}
                                function="GroupBy"
                                set={aggregation.groupBy}
                                assignmentNumber={1}
                                reportConfig={this.props.reportConfig}
                                collections={this.props.collections}
                                onCountChange={(_operands, count) => this.onGroupByArgumentCountChange(aggregation.number, count)}
                                onOperandClick={(_assignmentNuber, indexes) => this.onGroupByValueClick(aggregation, indexes)} />
                        </div>
                    </>
                }

                {
                    !hasCondition &&
                    <>
                        <OperandSet
                            maxArgumentsCount={5}
                            isReadOnly={this.props.isReadOnly}
                            assignment={`[${dataSourceHelper.getById(this.props.collections.dataSources, aggregation.dataSourceId).name}].`}
                            function="GroupBy"
                            set={aggregation.groupBy}
                            assignmentNumber={1}
                            reportConfig={this.props.reportConfig}
                            collections={this.props.collections}
                            onCountChange={(_operands, count) => this.onGroupByArgumentCountChange(aggregation.number, count)}
                            onOperandClick={(_assignmentNuber, indexes) => this.onGroupByValueClick(aggregation, indexes)} />
                        <div className="code-block">
                            {
                                !this.props.isReadOnly &&
                                <button className="helper-btn" onClick={() => { this.onAddFirstConditionClick(aggregation.number) }}>+ Condition</button>
                            }
                        </div>
                    </>
                }

                <ErrorList errors={aggregation.errors} className="mt-2" />
            </>
        );
    }

    render(): JSX.Element {

        return (
            <ReportLogicList
                isWaiting={this.props.isWaiting}
                items={this.props.reportConfig.aggregations.filter(x => x.number === this.props.selectedSetNumber)}
                columns={getColumns(this.onNameChange, this.onDataSourceChange, this.onBusinessDescriptionChange, this.onRemoveAggregationClick, this.props.collections.dataSources, this.props.isReadOnly)}
                getContent={this.getAssignmentContent}
                getKey={a => a.number}
                createHtmlId={v => reportConfigNavigation.buildAggregationId(v.number)} />
        );
    }
}

export default AggregationBuilder;
