import { buildMasterConfigurationLinks } from './Layout.MasterConfiguration';
import { getRouteSettings } from './Layout.Helpers';
import { isNavigationSafe, message } from './Common/NavigationPrompt/NavigationPrompt.Helpers';
import { NavLink } from 'react-router-dom';
import { Location } from 'history';
import { ReactComponent as MenuIcon } from './Common/BootstrapIcons/menu.svg';
import { Unsubscribe } from 'redux';
import actions from '../store/actions';
import appRoutes from '../infrastructure/constants/appRoutes';
import history from './../infrastructure/helpers/react/history';
import identityProvider from '../infrastructure/authorization/identityProvider';
import identityStorage from '../infrastructure/authorization/identityStorage';
import LinkWrapper from './LinkWrapper';
import lockManager from '../infrastructure/locking/lockManager';
import React from 'react';
import store from '../store/store';
import userRole from '../infrastructure/constants/identity/userRole';
import reportConfigStorage from '../infrastructure/common/reportConfig';
import editReportConfigHelper from '../infrastructure/helpers/report/editReportConfigHelper';
import resolveConfig from '../infrastructure/configuration/resolveConfig';
import WarningModal from './Common/Modals/WarningModal/WarningModal';

interface State {
    isSidebarOpen: boolean;
    isNavigationSafe: boolean;
    isPRODEnvironment: boolean;
    showWarningModal: boolean;
    onWarningOkClick: () => void;
    warningMessage: string;
    route: string;
}

class Layout extends React.Component<any, State>  {
    private unsubscribe1: Unsubscribe | undefined;
    private unsubscribe2: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        var environmentConfig = resolveConfig();
        this.state = {
            isSidebarOpen: true,
            isNavigationSafe: true,
            isPRODEnvironment: environmentConfig.env.name === 'prod',
            showWarningModal: false,
            onWarningOkClick: () => { },
            warningMessage: '',
            route: ''
        };

        this.onLocationChange = this.onLocationChange.bind(this);
        this.navigationListener = this.navigationListener.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onLogoutClick = this.onLogoutClick.bind(this);
        this.onLogOutConfirmed = this.onLogOutConfirmed.bind(this);
        this.onNavigateOkClick = this.onNavigateOkClick.bind(this);
        this.onNavLinkClick = this.onNavLinkClick.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe1 = history.listen(this.onLocationChange);
        this.unsubscribe2 = store.subscribe(this.navigationListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe1 as Unsubscribe)();
        (this.unsubscribe2 as Unsubscribe)();
    }

    onLocationChange(location: Location<any>) {

        if (location.pathname !== appRoutes.reporting.edit) {
            lockManager.unlock();
        }

        this.setState({ isNavigationSafe: isNavigationSafe(location.pathname) });
    }

    navigationListener(): void {
        let state = store.getState();

        let proceed =
            state.action === actions.navigation.setUnsafe ||
            state.action === actions.navigation.execute;

        if (!proceed) {
            return;
        }

        if (state.action === actions.navigation.execute) {

            this.setState({ isNavigationSafe: true }, state.navigate);

            return;
        }

        this.setState({ isNavigationSafe: false });
    }

    onMenuClick(): void {
        this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
    }

    onLogoutClick(): void {

        if (this.state.isNavigationSafe) {
            this.onLogOutConfirmed();
        }
        else {
            this.setState({ showWarningModal: true, onWarningOkClick: this.onLogOutConfirmed, warningMessage: message });
        }
    }

    onLogOutConfirmed() {

        var storageConfig = reportConfigStorage.get();
        if (editReportConfigHelper.isValidationConfig(storageConfig)) {
            lockManager.unlockComponents(storageConfig.id || 0);
        }
        else {
            lockManager.unlock();
        }

        identityProvider.logoutRedirect()
    }

    appendClosed(classes: string[]): void {

        if (!this.state.isSidebarOpen) {

            classes.push('closed');
        }
    }

    buildLeftSidebarClasses(): string {

        let classes = ['left-sidebar'];

        if (!this.state.isPRODEnvironment) {
            classes = ['left-sidebar-qa'];
        }

        this.appendClosed(classes);

        return classes.join(' ');
    }

    buildTopHeaderClass(): string {

        let cssClass = 'top-header';

        if (!this.state.isPRODEnvironment) {
            cssClass = 'top-header-qa';
        }

        return cssClass;
    }

    buildContentClasses(): string {

        let classes = ['content'];

        let settings = getRouteSettings(history.location.pathname);

        if (settings.hasSubHeader) {
            classes.push('has-sub-header');
        }

        if (settings.hasBackground) {
            classes.push('has-background');
        }

        this.appendClosed(classes);

        return classes.join(' ');
    }

    onNavigateOkClick() {
        history.push(this.state.route);
        this.setState({ showWarningModal: false });
    }

    onNavLinkClick(event: React.MouseEvent<HTMLElement>, route: string) {
        if (!this.state.isNavigationSafe) {
            this.setState({ showWarningModal: true, route, warningMessage: message, onWarningOkClick: this.onNavigateOkClick });
            event.preventDefault();
        }
        else {
            history.push(route);
        }
    }

    render(): JSX.Element {
        let identity = identityStorage.get();

        return (
            <>
                <div className={this.buildTopHeaderClass()}>
                    <NavLink exact activeClassName="active-sidebar-option" to={appRoutes.home} className="navbar-brand" onClick={(e: React.MouseEvent<HTMLElement>) => { this.onNavLinkClick(e, appRoutes.home) }} >
                        <img src="/logo.png" className="logo" alt="logo" />
                    </NavLink >
                    <div className="user-details">
                        {identity.userName},
                        <button className="transparent-button" onClick={this.onLogoutClick} >Logout</button>
                    </div>
                </div>

                <div className={this.buildLeftSidebarClasses()}>
                    <button className="custom-icon-button" onClick={this.onMenuClick}><MenuIcon /></button>
                    <ul>
                        <li>
                            <NavLink activeClassName="active-sidebar-option" to={appRoutes.home} onClick={(e: React.MouseEvent<HTMLElement>) => { this.onNavLinkClick(e, appRoutes.home) }} exact>Home</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active-sidebar-option" to={appRoutes.ingestion.home} onClick={(e: React.MouseEvent<HTMLElement>) => { this.onNavLinkClick(e, appRoutes.ingestion.home) }}>Ingestion</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active-sidebar-option" to={appRoutes.reporting.home} onClick={(e: React.MouseEvent<HTMLElement>) => { this.onNavLinkClick(e, appRoutes.reporting.home) }}>Reporting</NavLink>
                        </li>
                        {
                            identity.userRole === userRole.admin &&
                            <>
                                <li>
                                    <LinkWrapper onClick={this.onNavLinkClick} name="CB Master Configuration" links={buildMasterConfigurationLinks()} />
                                </li>
                                <li>
                                    <NavLink activeClassName="active-sidebar-option" to={appRoutes.validation.home} onClick={(e: React.MouseEvent<HTMLElement>) => { this.onNavLinkClick(e, appRoutes.validation.home) }}>Validation</NavLink>
                                </li>
                            </>
                        }
                    </ul>
                </div>

                <div className={this.buildContentClasses()}>
                    <div className="container-fluid">
                        {this.props.children}
                    </div>
                </div>
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.state.onWarningOkClick}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Unsaved changes'
                        message={message} />
                }
            </>
        );
    }

}

export default Layout;
