import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import Operand from '../../../../types/functions/Operand/Operand';
import ComponentType from '../../../../types/report/ComponentType';
import ReportConfig from '../../../../types/report/ReportConfig';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import dataType from '../../../constants/dataType';
import clear from '../../common/array/clear';
import assignmentHelper from '../assignment/assignmentHelper';
import operandHelper from '../operand/operandHelper';

const add = (functions: UserDefinedFunction[], func: UserDefinedFunction): void => {

    let lastNumber = functions.length > 0 ? functions[functions.length - 1].number : 0;

    func.number = lastNumber + 1;
    func.name = `function-${func.number}`;

    functions.push(func);
};

const get = (functions: UserDefinedFunction[], location: ReportLogicLocation): UserDefinedFunction => {

    return getByNumber(functions, location.statementNumber);
};

const getDefault = (): UserDefinedFunction => {

    let result = {} as UserDefinedFunction;

    result.id = null;
    result.name = '';
    result.number = 0;
    result.returnType = dataType.string;
    result.businessDescription = '';
    result.parameters = [];
    result.assignment = assignmentHelper.getEmpty(false);
    result.errors = [];

    reset(result);

    return result;
};

const reset = (func: UserDefinedFunction): void => {

    clear(func.parameters);
};

const getName = (func: UserDefinedFunction): string => {

    return `UDF${func.number}-${func.name}`;
}

const getByNumber = (statements: UserDefinedFunction[], number: number | null): UserDefinedFunction => {

    return statements.find(x => x.number === number) as UserDefinedFunction;
}

const findUserDefinedFunctionReferences = (userDefinedFunctionNumber: number, reportConfig: ReportConfig): Map<ComponentType,Set<string>> => {

    let references = new Map<ComponentType, Set<string>>();

    operandHelper.traverseReportConfigSectionsForOperands(reportConfig, (operand: Operand, itemName?: string, componentType?: ComponentType) => {

        if (operand?.functionNumber === userDefinedFunctionNumber) {

            let values: Set<string> = references.get(componentType as ComponentType) || new Set();

            values.add(itemName as string);

            references.set(componentType as ComponentType, values);
        }
    });

    return references;
}

const userDefinedFunctionHelper = {
    add,
    get,
    getDefault,
    reset,
    getName,
    getByNumber,
    findUserDefinedFunctionReferences
};

export default userDefinedFunctionHelper;
