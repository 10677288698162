import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import React from 'react';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import InfoIcon from '../InfoIcon/InfoIcon';

function createHeaderCell<T>(column: ColumnDefinition<T>, i: number, createHeaderContent: (c: ColumnDefinition<T>) => JSX.Element): JSX.Element {
    if (column.info === undefined || column.info === null) {
        return (
            <th style={getHeaderCellStyle(column)} key={i}>

                {typeHelper.isFunction(createHeaderContent) ? createHeaderContent(column) : column.header}

            </th>
        );
    }
    else {
        return (
            <th style={getHeaderCellStyle(column)} key={i}>

                <InfoIcon info={column.info as string} />{typeHelper.isFunction(createHeaderContent) ? createHeaderContent(column) : column.header}

            </th>
        );
    }
}

function createDataCell<T>(column: ColumnDefinition<T>, item: T, columnIndex: number, rowIndex: number): JSX.Element {
    return (
        <td key={columnIndex} className={column.className}>{column.getContent(item, rowIndex)}</td>
    );
}

function createDataRow<T>(columns: ColumnDefinition<T>[], item: T, i: number, getKey: (item: T, i: number) => string | number): JSX.Element {

    return (
        <tr key={getKey(item, i)}>
            {columns.map((column, j) => createDataCell(column, item, j, i))}
        </tr>
    );
}

function getHeaderCellStyle<T>(column: ColumnDefinition<T>): React.CSSProperties {

    let style = {} as React.CSSProperties;

    if (typeHelper.isString(column.width)) {
        style.width = column.width;
    }

    return style;
}

export {
    createHeaderCell,
    createDataRow
};
