import React from 'react';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import FieldDefinition from '../../../types/common/FieldDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import { ReactComponent as CopyIcon } from '../../Common/BootstrapIcons/copy.svg';

const getColumns = (
    onCopyFieldClick: (number: number) => void,
    isReadOnly: boolean
): ColumnDefinition<FieldDefinition>[] => {

    let columns: ColumnDefinition<FieldDefinition>[] = [
        {
            header: 'Reference',
            getContent: x => (x.id as number).toString(),
            width: '110px'
        },
        {
            header: 'Data Type',
            getContent: x => x.type
        },
        {
            header: '',
            getContent: x =>
                <div className="float-right display-flex">
                    <button className="custom-icon-button" onClick={() => {
                        onCopyFieldClick(x.id as number)
                    }
                    } disabled={isReadOnly}>
                        <CustomIcon icon={CopyIcon} title={iconTooltip.copy} />
                    </button>
                </div>,
            width: '40px'
        }
    ];

    return columns;
};

export {
    getColumns
};