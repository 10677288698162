import Aggregation from '../../../../types/report/Aggregation';
import clear from '../../common/array/clear';
import DataSourceField from '../../../../types/report/DataSourceField';
import dataSourceHelper from '../common/dataSourceHelper';
import declarationValidator from './declarationValidator';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import operandHelper from '../operand/operandHelper';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportVariable from '../../../../types/report/ReportVariable';
import selectMany from '../../common/array/selectMany';
import stringHelper from '../../common/stringHelper';
import uniquenessValidator from './uniquenessValidator';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const maxNameCharacters = 50;

const validateField = (field: DataSourceField, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[]): void => {

    clear(field.errors);

    let definition = dataSourceHelper.getFieldDefinitionById(dataSources, field.fieldId);

    declarationValidator.validate(field, functions, lookups, dictionaries, variables, dataSources, [], null as any, definition.type, null, true);
};

const validateAggregation = (aggregation: Aggregation, config: ReportConfig, dataSources: IngestionConfig[]): void => {

    clear(aggregation.errors);

    let metadataError = validateMetadata(aggregation);
    if (metadataError) {
        aggregation.errors.push(metadataError);
    }

    if (selectMany(aggregation.groupBy, o => operandHelper.asCollection(o)).some(o => !operandHelper.isSetUp(o))) {

        aggregation.errors.push('Group by criteria not set up');
    }

    if (!config.cases.some(c => c.aggregationNumber === aggregation.number)) {

        aggregation.errors.push('Aggregation isn\'t referenced in any reporting case');
    }

    aggregation.fields.forEach(x => validateField(x, config.userDefinedFunctions, config.lookups, config.dictionaries, config.variables, dataSources));
};

const validate = (config: ReportConfig, dataSources: IngestionConfig[]): number[] => {

    config.aggregations.forEach(x => validateAggregation(x, config, dataSources));

    uniquenessValidator.validate(config.aggregations, a => a.name, 'Aggregation', 'name');

    let numbers = config.aggregations
        .filter(a => a.errors.length > 0 || a.fields.some(f => f.errors.length > 0))
        .map(a => a.number);

    return numbers;
};

const validateMetadata = (aggregation: Aggregation): string | null => {

    if (stringHelper.isEmpty(aggregation.name)) {
        return 'Aggregation name is required';
    }

    if (aggregation.name.length > maxNameCharacters) {
        return `Aggregation name max character count is ${maxNameCharacters}`;
    }

    return null;
};

const aggregationValidator = {
    validate,
    validateField
};

export default aggregationValidator;
