import { mapToBasicOption } from '../../../../infrastructure/helpers/html/selectOptionHelper';
import booleanLiteral from '../../../../infrastructure/constants/functions/booleanLiteral';
import dataType from '../../../../infrastructure/constants/dataType';
import SelectOption from '../../../../infrastructure/types/SelectOption';

const typeOptions: SelectOption[] = Object.values(dataType).map(mapToBasicOption);

const booleanOptions: SelectOption[] = [booleanLiteral.true, booleanLiteral.false].map(mapToBasicOption);

export { typeOptions, booleanOptions };
