const guidance = {
    separator: 'This information tells our system how to determine between one field and another field in your raw data. It is a single character to be populated by the user, and when this symbol is identified it will identify the end of one field and begining of next field.\nWe expect the majortiy of files control box will ingest will be CSV with delimeter: \'Comma\'. However, please open your file in a text editor or speak with your IT team to establish this information.',
    textSeparator: 'Text delimiters are used to inform Control Box where a field delimiter needs to be read as a character of text. E.g. " could be used as a text delimiter in a csv file to indicate a comma being reported;\n"Bloggs, Joe" will be read as a single field containing Bloggs, Joe',
    headerLinePresent: 'Header rows are rows that contain information that help identify the content of a particular column (i.e. column names). If header row is present, then tick the box.',
    headerLineNumber: 'Header rows are rows that contain information that help identify the content of a particular column (i.e. column names). If header row is present, then populate the row number of header.',
    dataLineNumber: 'This information tells our system from which row number data starts.',
    escapeCharacter: 'Select the escape character and the character next to it is expected to be retained.'
};

export default guidance;
