import dataSourceHelper from '../../../infrastructure/helpers/functions/common/dataSourceHelper';
import React from 'react';
import referenceValidator from '../../../infrastructure/helpers/functions/validator/referenceValidator';
import ReportCollections from '../../../infrastructure/types/Functions/ReportCollections';
import ReportConfig from '../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import DictionarySelector from './DictionarySelector/DictionarySelector';
import Dictionary from '../../../types/report/Dictionary';
import dictionaryHelper from '../../../infrastructure/helpers/functions/dictionaries/dictionaryHelper';
import DictionaryBuilder from './DictionaryBuilder';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';
import dataSourceKind from '../../../infrastructure/constants/dataSourceKind';

interface Props {
    isWaiting: boolean;
    isReadOnly: boolean;
    dictionaryNumber: number | null;
    reportConfig: ReportConfig;
    collections: ReportCollections;
    onSelected: (number: number) => void;
    onChange: (dictionaries: Dictionary[]) => void;
    clipboard: ReportLogicLocation | null;
    onClipboardChange: (clipboard: ReportLogicLocation) => void;
}

class DictionaryBlockBuilder extends React.Component<Props, { location: ReportLogicLocation, showWarningModal: boolean }> {

    constructor(props: Props) {
        super(props);

        this.state = {
            location: {} as ReportLogicLocation,
            showWarningModal: false
        }

        this.onAddDictionaryClick = this.onAddDictionaryClick.bind(this);
        this.onRemoveDictionaryClick = this.onRemoveDictionaryClick.bind(this);
        this.onDictionaryChange = this.onDictionaryChange.bind(this);
        this.onRemoveFunctionApproved = this.onRemoveFunctionApproved.bind(this);
    }

    onAddDictionaryClick(): void {

        let datasourceId = this.props.collections.dataSources.find(x => x.dataSourceKind = dataSourceKind.accuracy)?.id;

        let dataSource = dataSourceHelper.getById(this.props.collections.dataSources, datasourceId as number);

        let dictionary = dictionaryHelper.getDefault(dataSource);

        dictionaryHelper.add(this.props.reportConfig.dictionaries, dictionary);

        this.props.onChange(this.props.reportConfig.dictionaries);
    }

    onRemoveDictionaryClick(location: ReportLogicLocation): void {

        let error = referenceValidator.validateDictionaryReferences(location.statementNumber, this.props.reportConfig);
        if (error) {

            let target = dictionaryHelper.get(this.props.reportConfig.dictionaries, location);

            target.errors = [error];

            this.props.onChange(this.props.reportConfig.dictionaries);

            return;
        }

        this.setState({ showWarningModal: true, location });

    }

    onRemoveFunctionApproved() {

        let reduced = this.props.reportConfig.dictionaries.filter(x => x.number !== this.state.location.statementNumber);

        this.props.onChange(reduced);
    }

    onDictionaryChange(location: ReportLogicLocation, changes: Dictionary): void {

        let target = dictionaryHelper.get(this.props.reportConfig.dictionaries, location);

        Object.assign(target, changes);

        this.props.onChange(this.props.reportConfig.dictionaries);
    }

    render(): JSX.Element {
        return (
            <>
                <div className="row no-gutters mb-3">
                    <div className="col-auto">
                        <button
                            onClick={this.onAddDictionaryClick}
                            className="btn btn-light">+ Dictionary</button>
                    </div>
                    <div className="col mt-2">
                        <DictionarySelector
                            isWaiting={this.props.isWaiting}
                            dictionaryNumber={this.props.dictionaryNumber}
                            dictionaries={this.props.reportConfig.dictionaries}
                            onDictionarySelected={this.props.onSelected} />
                    </div>
                </div>

                <DictionaryBuilder
                    isWaiting={this.props.isWaiting}
                    isReadOnly={this.props.isReadOnly}
                    dictionaryNumber={this.props.dictionaryNumber}
                    reportConfig={this.props.reportConfig}
                    collections={this.props.collections}
                    onRemoveClick={this.onRemoveDictionaryClick}
                    onChange={this.onDictionaryChange} />
                {
                    this.props.children
                }
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onRemoveFunctionApproved}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Remove item'
                        message='Are you sure you want to delete this item? This action cannot be undone.' />
                }
            </>
        );
    }
}

export default DictionaryBlockBuilder;
