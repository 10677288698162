import React from 'react';
import stringHelper from '../../../../../infrastructure/helpers/common/stringHelper';
import typeHelper from '../../../../../infrastructure/helpers/common/typeHelper';
import functionHelper from '../../../../../infrastructure/helpers/functions/function/functionHelper';
import userDefinedFunctionHelper from '../../../../../infrastructure/helpers/functions/userDefinedFunctions/userDefinedFunctionHelper';
import ArgumentDefinition from '../../../../../types/functions/ArgumentDefinition';
import FunctionDefinition from '../../../../../types/functions/FunctionDefinition';
import UserDefinedFunction from '../../../../../types/report/UserDefinedFunction';
import { getSignature } from './FunctionDescription.Helpers';
import './FunctionDescription.scss';

interface FunctionDescriptionProps {
    func: string;
    number: number | null,
    userDefinedFunctions: UserDefinedFunction[];
}

class FunctionDescription extends React.Component<FunctionDescriptionProps> {

    render(): JSX.Element {

        let definition = {} as FunctionDefinition;
        
        if (stringHelper.isNonEmpty(this.props.func)) {

            definition = functionHelper.getFunctionDefinition(this.props.func);
        }

        if (typeHelper.isNumber(this.props.number)) {

            let func = userDefinedFunctionHelper.getByNumber(this.props.userDefinedFunctions, this.props.number);

            definition = {
                isMethod: false,
                description: func.businessDescription || '',
                name: func.name,
                returnType: func.returnType
            } as FunctionDefinition;

            definition.arguments = func.parameters.map(x => {
                return {
                    allowedTypes: [x.dataType],
                    name: x.name
                } as ArgumentDefinition
            });
        }

        return (
            <div className="function-description">
                <table>
                    <tbody>
                        <tr>
                            <td>Description:</td>
                            <td>{definition.description}</td>
                        </tr>
                        <tr>
                            <td>Returns:</td>
                            <td>{definition.returnType}</td>
                        </tr>
                        <tr>
                            <td>Example:</td>
                            <td>{getSignature(definition)}</td>
                        </tr>
                        {this.props.children}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default FunctionDescription;
