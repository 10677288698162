import './Modal.scss';
import { getModalDialogClasses } from './Modal.Helpers';
import { getVisibility } from '../../../infrastructure/helpers/html/htmlUtils';
import { Unsubscribe } from 'redux';
import actions from '../../../store/actions';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import ModalSize from './ModalSize';
import ModalStateBase from './ModalStateBase';
import React from 'react';
import store from '../../../store/store';

interface ModalProps {
    state: ModalStateBase;
    close: () => void;
    size?: ModalSize;
    modalContentClasses?: string;
}

class Modal extends React.Component<ModalProps> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: ModalProps) {
        super(props);

        this.closeListener = this.closeListener.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.closeListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    closeListener(): void {

        let state = store.getState();
        if (state.action !== actions.common.closeModal) {
            return;
        }

        if (!this.props.state.isOpen) {
            return;
        }

        this.props.close();
    }

    render(): JSX.Element {
        return (
            <div className="modal" style={getVisibility(this.props.state.isOpen)}>
                <div className={getModalDialogClasses(this.props.size)}>
                    <div className={`modal-content ${this.props.modalContentClasses || ''}`}>
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.state.title}</h5>
                            <button type="button" className="close" onClick={this.props.close}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.props.children}
                            <ErrorMessage error={this.props.state.error} className="mt-3" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;
