import SelectOption from '../../../infrastructure/types/SelectOption';
import Synced from '../../../types/report/Synced';

const createOption = (item: Synced): SelectOption => {
    return {
        value: (item.id as number).toString(),
        text: item.name
    };
};

export { createOption };
