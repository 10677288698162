import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import Synced from '../../../types/report/Synced';

const validate = (number: number, syncedWithId: number | null, cases: Synced[]): string | null => {

    if (!typeHelper.isNumber(syncedWithId)) {

        return null;
    }

    let sameDataSourceId =
        (cases.find(x => x.number === number) as Synced).dataSourceId ===
        (cases.find(x => x.id === syncedWithId) as Synced).dataSourceId;

    if (!sameDataSourceId) {

        return 'Unable to sync cases because of different data source';
    }

    return null;
};

export default validate;