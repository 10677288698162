import { allowEdit, createSelectSearchOption, getLockedMessage, isResetRequired } from './ReportEditPage.Helpers';
import { AxiosResponse } from 'axios';
import { buildOptions } from '../Common/ReportQuickActions.Helpers';
import { getConfigById, getReportDataSources, getReportFields, getCustomDataSourceFields, seedReportDataSources, seedReportFields, seedCustomDataSourceFields, getDataSourceOptions, getEmptyReportPageContext, getCounts, seedReportDataSourceOptions } from './ReportPage/ReportPage.Helpers';
import { htmlIds } from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation.HtmlIds';
import { reportType } from '../../../infrastructure/constants/reportType';
import { Unsubscribe } from 'redux';
import AccuracyValidation from '../../../types/report/AccuracyValidation';
import actions from '../../../store/actions';
import Aggregation from '../../../types/report/Aggregation';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ButtonContext from '../../../infrastructure/helpers/common/ButtonContext';
import copyObject from '../../../infrastructure/helpers/common/copyObject';
import CopyReportFieldModal from '../Enrichment/CopyReportFieldModal/CopyReportFieldModal';
import CustomDataSourceFields from '../../../types/report/CustomDataSourceFields';
import DataSourceField from '../../../types/report/DataSourceField';
import editReportConfigHelper from '../../../infrastructure/helpers/report/editReportConfigHelper';
import EndpointFilter from '../../../types/report/EndpointFilter';
import enrichmentHelper from '../../../infrastructure/helpers/functions/enrichment/enrichmentHelper';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import IngestionConfigOption from '../../../types/ingestion/IngestionConfigOption';
import Selector from '../../../types/report/Selector';
import lockManager from '../../../infrastructure/locking/lockManager';
import Lookup from '../../../types/functions/Lookup';
import lookupDataSourceHelper from '../../../infrastructure/helpers/functions/lookup/lookupDataSourceHelper';
import OperandModal from '../../Functions/Operand/Modal/OperandModal';
import QuickActions from '../../Common/QuickActions/QuickActions';
import React from 'react';
import ReportCase from '../../../types/report/ReportCase';
import ReportCaseField from '../../../types/report/ReportCaseField';
import ReportConfig from '../../../types/report/ReportConfig';
import reportConfigCountHelper from '../../../infrastructure/helpers/report/reportConfigCountHelper';
import ReportConfigCounts from '../../../types/report/ReportConfigCounts';
import ReportConfigEditor from '../ReportConfigEditor/ReportConfigEditor';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportFieldDefinition from '../../../types/report/ReportFieldDefinition';
import ReportFilter from '../../../types/report/ReportFilter';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import ReportPageContext from './ReportPage/ReportPageContext';
import ReportPageState from './ReportPage/ReportPageState';
import ReportVariable from '../../../types/report/ReportVariable';
import store from '../../../store/store';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import validate from './ReportEditPage.Validation';
import SelectSearch, { SelectedOptionValue } from 'react-select-search';
import './ReportEditPage.scss';
import SelectorsState from '../../../types/report/SelectorsState';
import selectorsStore from '../../../store/selectorsStore';
import CopyValidationModal from '../Validation/CopyValidationModal/CopyValidationModal';
import CopyVariableModal from '../Variable/CopyVariableModal/CopyVariableModal';
import MatchingKey from '../../../types/report/MatchingKey';
import ReportConfigComponentLock from '../../../types/report/ReportConfigComponentLock';
import ComponentType from '../../../types/report/ComponentType';
import reportComponentBuildersHelper from '../Common/ReportComponentsHelper';
import ActionType from '../../../types/report/ActionType';
import AnnaDsbSelector from '../../../types/report/AnnaDsbSelector';
import Dictionary from '../../../types/report/Dictionary';
import DictionaryField from '../../../types/report/DictionaryField';
import reportConfigStorage from '../../../infrastructure/common/reportConfig';
import aggregationHelper from '../../../infrastructure/helpers/functions/aggregation/aggregationHelper';
import CopyAggregationModal from '../Aggregation/CopyAggregationModal/CopyAggregationFieldModal';
import UserDefinedFunction from '../../../types/report/UserDefinedFunction';
import UserDefinedFunctionParameter from '../../../types/report/UserDefinedFunctionParameter';
import FirdsInstrumentSelector from '../../../types/report/FirdsInstrumentSelector';
import dataSourceKind from '../../../infrastructure/constants/dataSourceKind';
import ReportConfigLockResponse from '../../../types/report/ReportConfigLockResponse';

export interface State extends ReportPageState {
    isWaiting: boolean;
    allowEdit: boolean;
    counts: ReportConfigCounts;
    aggregationNumber: number | null;
    isinSelectorNumber: number | null;
    leiSelectorNumber: number | null;
    exchangeRatesSelectorNumber: number | null;
    caseNumber: number | null;
    clipboard: ReportLogicLocation | null;
    lockMessage: string | null;
    errors: ButtonContext[];
    quickActionNumber: string;
    showComponentLockedPopup: boolean;
    editedComponents: ReportConfigComponentLock[] | [],
    reportConfigIntervalId: any,
    annaDsbUpiEnrichmentNumber: number | null,
    lseNumber: number | null,
    fcaRegulatedEntitiesNumber: number | null,
    annaDsbUpiNumber: number | null
    dictionaryNumber: number | null
    functionNumber: number | null,
    firdsFcaInstrumentSelectorNumber: number | null,
    firdsEsmaInstrumentSelectorNumber: number | null,
}

class ReportEditPage extends React.Component<any, State> {
    private id: number;
    private isValidQueryParam: boolean;
    private pageContext: ReportPageContext;
    private unsubscribe: Unsubscribe | undefined;
    private unsubscribeSelectComponent: Unsubscribe | undefined;
    private oneMinutesInMileseconds = 60000;

    constructor(props: any) {
        super(props);
        let query = urlHelper.deserializeQuery(history.location.search);

        this.id = parseInt(query['id']);

        this.isValidQueryParam = typeHelper.isNumber(this.id);

        if (!this.isValidQueryParam) {
            let route = appRoutes.reporting.home;
            if (this.state.reportConfig.type === reportType.accuracy) {
                route = appRoutes.validation.home;
            }

            history.push(route);
        }

        this.pageContext = getEmptyReportPageContext();

        this.state = {
            isWaiting: true,
            allowEdit: false,
            reportConfig: this.pageContext.config,
            counts: reportConfigCountHelper.getEmpty(),
            aggregationNumber: null,
            isinSelectorNumber: null,
            leiSelectorNumber: null,
            exchangeRatesSelectorNumber: null,
            annaDsbUpiEnrichmentNumber: null,
            lseNumber: null,
            fcaRegulatedEntitiesNumber: null,
            annaDsbUpiNumber: null,
            caseNumber: null,
            dictionaryNumber: null,
            functionNumber: null,
            clipboard: null,
            lockMessage: null,
            errors: [],
            quickActionNumber: '',
            showComponentLockedPopup: false,
            editedComponents: [],
            reportConfigIntervalId: null,
            firdsFcaInstrumentSelectorNumber: null,
            firdsEsmaInstrumentSelectorNumber: null
        };

        this.refreshCountsListener = this.refreshCountsListener.bind(this);
        this.updateLookupDataSourceId = this.updateLookupDataSourceId.bind(this);
        this.onAggregationSelected = this.onAggregationSelected.bind(this);
        this.onCaseSelected = this.onCaseSelected.bind(this);
        this.onDataSourceChange = this.onDataSourceChange.bind(this);
        this.onAggregationChange = this.onAggregationChange.bind(this);
        this.onAggregatedRecordFieldChange = this.onAggregatedRecordFieldChange.bind(this);
        this.onLookupChange = this.onLookupChange.bind(this);
        this.onIsinSelectorChange = this.onIsinSelectorChange.bind(this);
        this.onIsinSelectorSelected = this.onIsinSelectorSelected.bind(this);
        this.onLeiSelectorSelected = this.onLeiSelectorSelected.bind(this);
        this.onLeiSelectorChange = this.onLeiSelectorChange.bind(this);
        this.onExchangeRatesSelectorChange = this.onExchangeRatesSelectorChange.bind(this);
        this.onExchangeRatesSelectorSelected = this.onExchangeRatesSelectorSelected.bind(this);
        this.onAnnaDsbUpiEnrichmentSelectorSelected = this.onAnnaDsbUpiEnrichmentSelectorSelected.bind(this);
        this.onAnnaDsbUpiSelectorSelected = this.onAnnaDsbUpiSelectorSelected.bind(this);
        this.onFCARegulatedEntitiesSelectorSelected = this.onFCARegulatedEntitiesSelectorSelected.bind(this);
        this.onVariableChange = this.onVariableChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onEndpointFilterChange = this.onEndpointFilterChange.bind(this);
        this.onValidationChange = this.onValidationChange.bind(this);
        this.onCaseChange = this.onCaseChange.bind(this);
        this.onEnrichmentChange = this.onEnrichmentChange.bind(this);
        this.onSyncedWithIdChange = this.onSyncedWithIdChange.bind(this);
        this.onMatchingKeyChange = this.onMatchingKeyChange.bind(this);
        this.onMatchingKeyEndPointChange = this.onMatchingKeyEndPointChange.bind(this);
        this.onClipboardChange = this.onClipboardChange.bind(this);
        this.onAggregationErrorClick = this.onAggregationErrorClick.bind(this);
        this.onCaseFieldErrorClick = this.onCaseFieldErrorClick.bind(this);
        this.save = this.save.bind(this);
        this.onRefreshDataSourcesSuccess = this.onRefreshDataSourcesSuccess.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.onLeiSelectorErrorClick = this.onLeiSelectorErrorClick.bind(this);
        this.onIsinSelectorErrorClick = this.onIsinSelectorErrorClick.bind(this);
        this.onExchangeRatesSelectorErrorClick = this.onExchangeRatesSelectorErrorClick.bind(this);
        this.onAnnaDsbUpiEnrichmentSelectorErrorClick = this.onAnnaDsbUpiEnrichmentSelectorErrorClick.bind(this);
        this.onAnnaDsbUpiSelectorErrorClick = this.onAnnaDsbUpiSelectorErrorClick.bind(this);
        this.onFCARegulatedEntitiesSelectorErrorClick = this.onFCARegulatedEntitiesSelectorErrorClick.bind(this);
        this.onQuickActionItemSelected = this.onQuickActionItemSelected.bind(this);
        this.onComponentContainerClick = this.onComponentContainerClick.bind(this);
        this.onCheckMinutesUntilLockWillExpire = this.onCheckMinutesUntilLockWillExpire.bind(this);
        this.onExitClick = this.onExitClick.bind(this);
        this.determinateComponent = this.determinateComponent.bind(this);
        this.processComponentWhenNotLocked = this.processComponentWhenNotLocked.bind(this);
        this.onActionItemsCountChange = this.onActionItemsCountChange.bind(this);
        this.onAnnaDsbUpiEnrichmentSelectorChange = this.onAnnaDsbUpiEnrichmentSelectorChange.bind(this);
        this.onAnnaDsbUpiSelectorChange = this.onAnnaDsbUpiSelectorChange.bind(this);
        this.onLSESelectorChange = this.onLSESelectorChange.bind(this);
        this.onFCARegulatedEntitiesSelectorChange = this.onFCARegulatedEntitiesSelectorChange.bind(this);
        this.onLSESelectorSelected = this.onLSESelectorSelected.bind(this);
        this.onLSESelectorErrorClick = this.onLSESelectorErrorClick.bind(this);
        this.onFirdsFcaInstrumentSelectorChange = this.onFirdsFcaInstrumentSelectorChange.bind(this);
        this.onFirdsEsmaInstrumentSelectorChange = this.onFirdsEsmaInstrumentSelectorChange.bind(this);
        this.onFirdsFcaInstrumentSelectorSelected = this.onFirdsFcaInstrumentSelectorSelected.bind(this);
        this.onFirdsEsmaInstrumentSelectorSelected = this.onFirdsEsmaInstrumentSelectorSelected.bind(this);
        this.onFirdsFcaInstrumentSelectorErrorClick = this.onFirdsFcaInstrumentSelectorErrorClick.bind(this);
        this.onFirdsEsmaInstrumentSelectorErrorClick = this.onFirdsEsmaInstrumentSelectorErrorClick.bind(this);
        this.onDictionarySelected = this.onDictionarySelected.bind(this);
        this.onDictionaryChange = this.onDictionaryChange.bind(this);
        this.onDictionaryFieldChange = this.onDictionaryFieldChange.bind(this);
        this.onAggregationSyncedWithIdChange = this.onAggregationSyncedWithIdChange.bind(this);
        this.onFunctionSelected = this.onFunctionSelected.bind(this);
        this.onFunctionChange = this.onFunctionChange.bind(this);
        this.onFunctionParameterChange = this.onFunctionParameterChange.bind(this);
        this.selectComponent = this.selectComponent.bind(this);
    }

    async componentDidMount(): Promise<void> {

        if (!this.isValidQueryParam) {
            return;
        }

        this.unsubscribe = store.subscribe(this.refreshCountsListener);

        this.unsubscribeSelectComponent = store.subscribe(this.selectComponent);

        const getState = (config: ReportConfig): State => {

            let result = {} as State;

            result.isWaiting = false;
            result.reportConfig = config;

            if (config.type !== reportType.accuracy) {

                result.caseNumber = config.cases[0].number;
            }

            if (config.dictionaries.length > 0) {

                result.dictionaryNumber = config.dictionaries[0].number;
            }

            if (config.userDefinedFunctions.length > 0) {

                result.functionNumber = config.userDefinedFunctions[0].number;
            }

            if (config.aggregations.length > 0) {

                result.aggregationNumber = config.aggregations[0].number;
            }

            if (config.isinSelectors.length > 0) {

                result.isinSelectorNumber = config.isinSelectors[0].number;
            }

            if (config.leiSelectors.length > 0) {

                result.leiSelectorNumber = config.leiSelectors[0].number;
            }

            if (config.exchangeRatesSelectors.length > 0) {

                result.exchangeRatesSelectorNumber = config.exchangeRatesSelectors[0].number;
            }

            if (config.annaDsbUpiEnrichmentSelectors.length > 0) {

                result.annaDsbUpiEnrichmentNumber = config.annaDsbUpiEnrichmentSelectors[0].number;
            }

            if (config.annaDsbUpiSelectors.length > 0) {

                result.annaDsbUpiNumber = config.annaDsbUpiSelectors[0].number;
            }

            if (config.lseSelectors.length > 0) {

                result.lseNumber = config.lseSelectors[0].number;
            }

            if (config.firdsFcaInstrumentSelectors.length > 0) {

                result.firdsFcaInstrumentSelectorNumber = config.firdsFcaInstrumentSelectors[0].number;
            }

            if (config.firdsEsmaInstrumentSelectors.length > 0) {

                result.firdsEsmaInstrumentSelectorNumber = config.firdsEsmaInstrumentSelectors[0].number;
            }

            if (config.fcaRegulatedEntitiesSelectors.length > 0) {

                result.fcaRegulatedEntitiesNumber = config.fcaRegulatedEntitiesSelectors[0].number;
            }

            return result;

        };

        const onGetCollectionsSuccess = (config: ReportConfig, dataSourceOptions: IngestionConfigOption[], dataSources: IngestionConfig[], reportFieldDefinitions: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): void => {

            reportConfigStorage.set(config);

            editReportConfigHelper.prepare(config, dataSources);

            if (typeHelper.isString(config.subRegime)) {

                for (var i = 0; i < reportFieldDefinitions.length; i++) {

                    let definition = reportFieldDefinitions[i];

                    definition.isHidden = !definition.subRegimes.includes(config.subRegime as string);
                }
            }

            seedReportDataSourceOptions(dataSourceOptions, this.pageContext);
            seedReportDataSources(dataSources, this.pageContext);
            seedReportFields(reportFieldDefinitions, this.pageContext);
            seedCustomDataSourceFields(customDataSourceFields, this.pageContext);

            let selectorsAppState: SelectorsState = {
                isinSelectors: config.isinSelectors,
                leiSelectors: config.leiSelectors,
                exchangeRatesSelectors: config.exchangeRatesSelectors,
                annaDsbUpiEnrichmentSelectors: config.annaDsbUpiEnrichmentSelectors,
                annaDsbUpiSelectors: config.annaDsbUpiSelectors,
                lSESelectors: config.lseSelectors,
                firdsFcaInstrumentSelectors: config.firdsFcaInstrumentSelectors,
                firdsEsmaInstrumentSelectors: config.firdsEsmaInstrumentSelectors,
                fcaRegulatedEntitiesSelectors: config.fcaRegulatedEntitiesSelectors,
                selectorIndex: 0
            };

            selectorsStore.dispatch({ type: actions.selectors.save, payload: selectorsAppState });

            let state = getState(config);
            this.setState(state, () => {
                getCounts(this.state.reportConfig.regime, this.id).then(response => this.setState({ counts: response.data }));
            });
        };

        const lock = (): Promise<void> => {

            return lockManager.lock(this.id).then(response => {

                this.setState({ allowEdit: allowEdit(response), lockMessage: getLockedMessage(response) });

                return Promise.resolve();
            });
        };

        const reportConfigTypes = ['Accuracy', 'AccuracyCore'];

        var usedByAnotherUserMessage = await lockManager.isReportConfigUsedByOtherUser(this.id)
        if (usedByAnotherUserMessage) {
            reportComponentBuildersHelper.showMesage(usedByAnotherUserMessage, false);
        }

        getConfigById(this.id)
            .then((response) => {
                if (!reportConfigTypes.includes(response.data.type)) {
                    lock();
                }
                else {

                    const intervalId = this.setReportConfigInterval();
                    this.setState({ reportConfigIntervalId: intervalId });
                }

                return response;
            })
            .then(response => Promise.all([response.data, getDataSourceOptions(response.data.type, this.id), getReportDataSources(response.data), getReportFields(response.data), getCustomDataSourceFields()]))
            .then(responses => onGetCollectionsSuccess(responses[0], responses[1].data, responses[2].data, responses[3].data, responses[4].data));
    }

    setReportConfigInterval() {
        const intervalId = setInterval(async () => {
            clearInterval(this.state.reportConfigIntervalId);
        }, this.oneMinutesInMileseconds);

        return intervalId;
    }

    async onCheckMinutesUntilLockWillExpire(componentId: number, componentType: ComponentType) {

        if (this.state.editedComponents.length) {
            const editedComponent = this.state.editedComponents.find(x => x.componentId === componentId && x.componentType === componentType);
            if (editedComponent) {
                let reportConfigCopy = Object.assign({}, this.state.reportConfig);
                let component;
                let name;
                switch (editedComponent.componentType) {
                    case ComponentType.Variable: component = reportConfigCopy.variables.find(v => v.id === editedComponent?.componentId); name = component?.name; break;
                    case ComponentType.Lookup: component = reportConfigCopy.lookups.find(v => v.id === editedComponent?.componentId); name = component?.name; break;
                    case ComponentType.LeiSelector: component = reportConfigCopy.leiSelectors.find(v => v.id === editedComponent?.componentId); name = component?.name; break;
                    case ComponentType.ExchangeRatesSelector: component = reportConfigCopy.exchangeRatesSelectors.find(v => v.id === editedComponent?.componentId); name = component?.name; break;
                    case ComponentType.AnnaDsbUpiEnrichmentSelector: component = reportConfigCopy.annaDsbUpiEnrichmentSelectors.find(v => v.id === editedComponent?.componentId); name = component?.name; break;
                    case ComponentType.AnnaDsbUpiSelector: component = reportConfigCopy.annaDsbUpiSelectors.find(v => v.id === editedComponent?.componentId); name = component?.name; break;
                    case ComponentType.LSESelector: component = reportConfigCopy.lseSelectors.find(v => v.id === editedComponent?.componentId); name = component?.name; break;
                    case ComponentType.FirdsFcaInstrumentSelectors: component = reportConfigCopy.firdsFcaInstrumentSelectors.find(v => v.id === editedComponent?.componentId); name = component?.name; break;
                    case ComponentType.FirdsEsmaInstrumentSelectors: component = reportConfigCopy.firdsEsmaInstrumentSelectors.find(v => v.id === editedComponent?.componentId); name = component?.name; break;
                    case ComponentType.IsinSelector: component = reportConfigCopy.isinSelectors.find(v => v.id === editedComponent?.componentId); name = component?.name; break;
                    case ComponentType.AccuracyValidation: component = reportConfigCopy.validations.find(v => v.id === editedComponent?.componentId); name = component?.identifier; break;
                    default: break;
                }

                const willExpireIn = await lockManager.getMinutesWhenLockWillExpire(editedComponent);

                if (willExpireIn > 5 && willExpireIn < 6) {

                    let message = `Please save the config.The automatic lock on ${editedComponent.componentType}, ID: ${editedComponent.componentId}, Name: ${name || ''} will be released in 5 minutes.`;

                    reportComponentBuildersHelper.showMesage(message, true);
                }
                else if (willExpireIn === 0) {

                    clearInterval(editedComponent.intervalId);
                    if (component) {
                        component.isLocked = false;
                        component.isUsedByCurrentUser = false;
                        component.isReadOnly = false;
                    }

                    let editedComponents = this.state.editedComponents.slice();
                    editedComponents = editedComponents.filter(c => c.intervalId !== editedComponent.intervalId);

                    let message = `${editedComponent.componentType}, ID: ${editedComponent.componentId}, Name: ${name || ''} has been unlocked. Save the config immediately to ensure that your changes are not lost or overwritten by another user.`;
                    reportComponentBuildersHelper.showMesage(message, true);

                    this.setState({ reportConfig: reportConfigCopy, editedComponents });
                }
            }
        }
    }

    componentWillUnmount(): void {

        (this.unsubscribe as Unsubscribe)();
        (this.unsubscribeSelectComponent as Unsubscribe)();

        this.state.editedComponents.forEach((component) => {
            clearInterval(component.intervalId);
        })
        clearInterval(this.state.reportConfigIntervalId);
    }

    selectComponent(): void {
        let storeState = store.getState();

        let proceed = storeState.action === actions.component.selected;

        if (!proceed) {
            return;
        }

        switch (storeState.selectedComponentState.componentType) {
            case ComponentType.AnnaDsbUpiEnrichmentSelector: this.onAnnaDsbUpiEnrichmentSelectorSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.AnnaDsbUpiSelector: this.onAnnaDsbUpiSelectorSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.IsinSelector: this.onIsinSelectorSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.LeiSelector: this.onLeiSelectorSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.LseSelector: this.onLSESelectorSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.FirdsEsmaInstrumentSelectors: this.onFirdsEsmaInstrumentSelectorSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.FirdsFcaInstrumentSelectors: this.onFirdsFcaInstrumentSelectorSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.ExchangeRatesSelector: this.onExchangeRatesSelectorSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.FCARegulatedEntitiesSelector: this.onFCARegulatedEntitiesSelectorSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.CaseField: this.onCaseSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.AggregationField: this.onAggregationSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.DictionaryField: this.onDictionarySelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.Aggregation: this.onAggregationSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.Dictionary: this.onDictionarySelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;
            case ComponentType.Case: this.onCaseSelected(storeState.selectedComponentState.number, storeState.selectedComponentState.callback); break;

            default: break;
        }
    }

    refreshCountsListener(): void {
        let state = store.getState();

        let proceed =
            state.action === actions.complianceCommentModal.saveSuccess ||
            state.action === actions.complianceCommentModal.deleteCommentSuccess ||
            state.action === actions.actionItemModal.saveSuccess;

        if (!proceed) {
            return;
        }

        getCounts(this.state.reportConfig.regime, this.id).then(response => this.setState({ counts: response.data }));
    }

    updateLookupDataSourceId(lookup: Lookup): void {

        lookupDataSourceHelper.updateLookupDataSourceId(lookup, this.pageContext.collections.dataSources.filter(x => x.id !== lookup.dataSource1Id));
    }

    onAggregationSelected(number: number, callback?: () => void): void {

        this.setState({ aggregationNumber: number }, () => callback ? callback() : undefined);
    }

    onIsinSelectorSelected(number: number, callback?: () => void): void {

        this.setState({ isinSelectorNumber: number }, () => callback ? callback() : undefined);
    }

    onLeiSelectorSelected(number: number, callback?: () => void): void {

        this.setState({ leiSelectorNumber: number }, () => callback ? callback() : undefined);
    }

    onExchangeRatesSelectorSelected(number: number, callback?: () => void): void {

        this.setState({ exchangeRatesSelectorNumber: number }, () => callback ? callback() : undefined);
    }

    onAnnaDsbUpiEnrichmentSelectorSelected(number: number, callback?: () => void): void {

        this.setState({ annaDsbUpiEnrichmentNumber: number }, () => callback ? callback() : undefined);
    }

    onLSESelectorSelected(number: number, callback?: () => void): void {

        this.setState({ lseNumber: number }, () => callback ? callback() : undefined);
    }

    onFCARegulatedEntitiesSelectorSelected(number: number, callback?: () => void): void {

        this.setState({ fcaRegulatedEntitiesNumber: number }, () => callback ? callback() : undefined);
    }

    onFirdsFcaInstrumentSelectorSelected(number: number, callback?: () => void): void {

        this.setState({ firdsFcaInstrumentSelectorNumber: number }, () => callback ? callback() : undefined);
    }

    onFirdsEsmaInstrumentSelectorSelected(number: number, callback?: () => void): void {

        this.setState({ firdsEsmaInstrumentSelectorNumber: number }, () => callback ? callback() : undefined);
    }

    onAnnaDsbUpiSelectorSelected(number: number, callback?: () => void): void {

        this.setState({ annaDsbUpiNumber: number }, () => callback ? callback() : undefined);
    }

    onCaseSelected(number: number, callback?: () => void): void {

        this.setState({ caseNumber: number }, () => callback ? callback() : undefined);
    }

    onDictionarySelected(number: number, callback?: () => void): void {

        this.setState({ dictionaryNumber: number }, () => callback ? callback() : undefined);
    }

    onFunctionSelected(number: number): void {

        this.setState({ functionNumber: number });
    }

    onDataSourceChange(changes: ReportConfig): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let isDataSourceChange =
            typeHelper.isNumber(changes.rawDataSourceId) ||
            (typeHelper.isArray(changes.refDataSources) && changes.refDataSources.length !== this.state.reportConfig.refDataSources.length);

        let reportConfig = Object.assign({}, this.state.reportConfig, changes);

        this.setState({ reportConfig: reportConfig }, () => {

            if (!isDataSourceChange) {
                return;
            }

            getReportDataSources(this.state.reportConfig).then(this.onRefreshDataSourcesSuccess);
        });
    }

    onAggregationChange(aggregationNumber: number, aggregations: Aggregation[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { aggregations } as ReportConfig);

        changes.aggregationNumber = aggregationNumber || 1;


        this.setState(changes);
    }

    onQuickActionFieldChange(value: string): void {

        let number = parseInt(value);

        let fieldId = reportConfigNavigation.buildReportFieldId(number);

        reportConfigNavigation.navigateToItem(fieldId);
    }

    onAggregatedRecordFieldChange(number: number, fields: DataSourceField[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let target = this.state.reportConfig.aggregations.find(x => x.number === number) as Aggregation;

        target.fields = fields;

        aggregationHelper.handleAggregationChanges(this.state.reportConfig.aggregations);

        this.setState({
            reportConfig: Object.assign({}, this.state.reportConfig, { aggregations: this.state.reportConfig.aggregations } as ReportConfig)
        });
    }

    onLookupChange(lookups: Lookup[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        this.setState({
            reportConfig: Object.assign({}, this.state.reportConfig, { lookups: lookups } as ReportConfig)
        });
    }

    onIsinSelectorChange(isinSelectors: Selector[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { isinSelectors: isinSelectors } as ReportConfig);

        if (isinSelectors.length === 0) {

            changes.isinSelectorNumber = null;
        }
        else if (!isinSelectors.some(a => a.number === this.state.isinSelectorNumber)) {

            changes.isinSelectorNumber = isinSelectors[0].number;
        }

        this.setState(changes);

        selectorsStore.dispatch({ type: actions.selectors.isinChanged, payload: isinSelectors });
    }

    onLeiSelectorChange(leiSelectors: Selector[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { leiSelectors: leiSelectors } as ReportConfig);

        if (leiSelectors.length === 0) {

            changes.leiSelectorNumber = null;
        }
        else if (!leiSelectors.some(a => a.number === this.state.leiSelectorNumber)) {

            changes.leiSelectorNumber = leiSelectors[0].number;
        }

        this.setState(changes);

        selectorsStore.dispatch({ type: actions.selectors.leiChanged, payload: leiSelectors });
    }

    onExchangeRatesSelectorChange(exchangeRatesSelectors: Selector[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { exchangeRatesSelectors: exchangeRatesSelectors } as ReportConfig);

        if (exchangeRatesSelectors.length === 0) {

            changes.exchangeRatesSelectorNumber = null;
        }
        else if (!exchangeRatesSelectors.some(a => a.number === this.state.exchangeRatesSelectorNumber)) {

            changes.exchangeRatesSelectorNumber = exchangeRatesSelectors[0].number;
        }

        this.setState(changes);

        selectorsStore.dispatch({ type: actions.selectors.exchangeRatesChanged, payload: exchangeRatesSelectors });
    }

    onAnnaDsbUpiSelectorChange(selectors: Selector[]): void {
        var state = selectorsStore.getState();

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;

        if (selectors.length === 0) {

            changes.annaDsbUpiNumber = null;
        }
        else if (!selectors.some(a => a.number === this.state.annaDsbUpiNumber)) {

            changes.annaDsbUpiNumber = selectors[0].number;
        }

        let newSelectors = selectors;
        newSelectors.forEach((sel) => {
            if (state.selectors.selectorIndex && sel.keys && sel.number === this.state.annaDsbUpiNumber) {
                sel.keys[state.selectors.selectorIndex].assignment = Object.assign({}, sel.assignment);
            }
        })

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { annaDsbUpiSelectors: newSelectors } as ReportConfig);

        this.setState(changes);

        selectorsStore.dispatch({ type: actions.selectors.annaDsbUpiChanged, payload: selectors });
    }

    onFirdsFcaInstrumentSelectorChange(selectors: Selector[]): void {
        var state = selectorsStore.getState();

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;


        if (selectors.length === 0) {

            changes.firdsFcaInstrumentSelectorNumber = null;
        }
        else if (!selectors.some(a => a.number === this.state.firdsFcaInstrumentSelectorNumber)) {

            changes.firdsFcaInstrumentSelectorNumber = selectors[0].number;
        }

        let newSelectors = selectors as FirdsInstrumentSelector[];
        newSelectors.forEach((sel) => {
            if (state.selectors.selectorIndex && sel.keys && sel.number === this.state.firdsFcaInstrumentSelectorNumber) {
                sel.keys[state.selectors.selectorIndex].assignment = Object.assign({}, sel.assignment);
            }
        })

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { firdsFcaInstrumentSelectors: newSelectors } as ReportConfig);

        this.setState(changes);

        selectorsStore.dispatch({ type: actions.selectors.firdsFcaInstrumentChange, payload: selectors });
    }

    onFirdsEsmaInstrumentSelectorChange(selectors: Selector[]): void {
        var state = selectorsStore.getState();

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;


        if (selectors.length === 0) {

            changes.firdsEsmaInstrumentSelectorNumber = null;
        }
        else if (!selectors.some(a => a.number === this.state.firdsEsmaInstrumentSelectorNumber)) {

            changes.firdsEsmaInstrumentSelectorNumber = selectors[0].number;
        }

        let newSelectors = selectors as FirdsInstrumentSelector[];
        newSelectors.forEach((sel) => {
            if (state.selectors.selectorIndex && sel.keys && sel.number === this.state.firdsEsmaInstrumentSelectorNumber) {
                sel.keys[state.selectors.selectorIndex].assignment = Object.assign({}, sel.assignment);
            }
        })

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { firdsEsmaInstrumentSelectors: newSelectors } as ReportConfig);

        this.setState(changes);

        selectorsStore.dispatch({ type: actions.selectors.firdsEsmaInstrumentChange, payload: selectors });
    }


    onAnnaDsbUpiEnrichmentSelectorChange(selectors: Selector[]): void {
        var state = selectorsStore.getState();

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;


        if (selectors.length === 0) {

            changes.annaDsbUpiEnrichmentNumber = null;
        }
        else if (!selectors.some(a => a.number === this.state.annaDsbUpiEnrichmentNumber)) {

            changes.annaDsbUpiEnrichmentNumber = selectors[0].number;
        }

        let newSelectors = selectors as AnnaDsbSelector[];
        newSelectors.forEach((sel) => {
            if (state.selectors.selectorIndex && sel.keys && sel.number === this.state.annaDsbUpiEnrichmentNumber) {
                sel.keys[state.selectors.selectorIndex].assignment = Object.assign({}, sel.assignment);
            }
        })

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { annaDsbUpiEnrichmentSelectors: newSelectors } as ReportConfig);

        this.setState(changes);

        selectorsStore.dispatch({ type: actions.selectors.annaDsbUpiEnrichmentChanged, payload: selectors });
    }

    onLSESelectorChange(selectors: Selector[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { lseSelectors: selectors } as ReportConfig);

        if (selectors.length === 0) {

            changes.lseNumber = null;
        }
        else if (!selectors.some(a => a.number === this.state.lseNumber)) {

            changes.lseNumber = selectors[0].number;
        }

        this.setState(changes);

        selectorsStore.dispatch({ type: actions.selectors.lseChanged, payload: selectors });
    }

    onFCARegulatedEntitiesSelectorChange(selectors: Selector[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { fcaRegulatedEntitiesSelectors: selectors } as ReportConfig);

        if (selectors.length === 0) {

            changes.fcaRegulatedEntitiesNumber = null;
        }
        else if (!selectors.some(a => a.number === this.state.fcaRegulatedEntitiesNumber)) {

            changes.fcaRegulatedEntitiesNumber = selectors[0].number;
        }

        this.setState(changes);

        selectorsStore.dispatch({ type: actions.selectors.fcaRegulatedEntitiesChange, payload: selectors });
    }

    onVariableChange(variables: ReportVariable[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        this.setState({
            reportConfig: Object.assign({}, this.state.reportConfig, { variables: variables } as ReportConfig),
            clipboard: null
        });
    }

    onFilterChange(filters: ReportFilter[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        this.setState({
            reportConfig: Object.assign({}, this.state.reportConfig, { filters: filters } as ReportConfig),
            clipboard: null
        });
    }

    onEndpointFilterChange(filters: EndpointFilter[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        this.setState({
            reportConfig: Object.assign({}, this.state.reportConfig, { endpointFilters: filters } as ReportConfig),
            clipboard: null
        });
    }

    onValidationChange(validations: AccuracyValidation[], shouldLock?: boolean): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        this.setState({
            reportConfig: Object.assign({}, this.state.reportConfig, { validations: validations } as ReportConfig),
            clipboard: null
        });
    }

    onCaseChange(cases: ReportCase[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        enrichmentHelper.handleCaseChanges(cases, this.pageContext.collections.reportFields.map(x => x.id));

        let changes = {} as State;

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { cases: cases } as ReportConfig);
        changes.clipboard = null

        if (isResetRequired(this.state.caseNumber, cases)) {
            changes.caseNumber = cases[0].number;
        }

        this.setState(changes);
    }

    onEnrichmentChange(number: number, fields: ReportCaseField[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let target = this.state.reportConfig.cases.find(x => x.number === number) as ReportCase;

        target.fields = fields;

        enrichmentHelper.handleEnrichmentChanges(this.state.reportConfig.cases);

        this.setState({
            reportConfig: Object.assign({}, this.state.reportConfig, { cases: this.state.reportConfig.cases } as ReportConfig)
        });
    }

    onSyncedWithIdChange(number: number, syncedWithId: number | null): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let target = this.state.reportConfig.cases.find(x => x.number === number) as ReportCase;

        target.syncedWithId = syncedWithId;

        enrichmentHelper.handleEnrichmentChanges(this.state.reportConfig.cases);

        this.setState({
            reportConfig: Object.assign({}, this.state.reportConfig, { cases: this.state.reportConfig.cases } as ReportConfig)
        });
    }


    onAggregationSyncedWithIdChange(number: number, syncedWithId: number | null): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let target = this.state.reportConfig.aggregations.find(x => x.number === number) as Aggregation;

        target.syncedWithId = syncedWithId;

        aggregationHelper.handleAggregationChanges(this.state.reportConfig.aggregations);

        this.setState({
            reportConfig: Object.assign({}, this.state.reportConfig, { aggregations: this.state.reportConfig.aggregations } as ReportConfig)
        });
    }

    onMatchingKeyChange(matchingKey: MatchingKey): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        this.setState({
            reportConfig: Object.assign({}, this.state.reportConfig, { matchingKey: matchingKey } as ReportConfig)
        });
    }

    onMatchingKeyEndPointChange(matchingKeyEndPoint: MatchingKey): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        this.setState({
            reportConfig: Object.assign({}, this.state.reportConfig, { matchingKeyEndPoint: matchingKeyEndPoint } as ReportConfig)
        });
    }

    onDictionaryChange(dictionaries: Dictionary[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { dictionaries: dictionaries } as ReportConfig);

        if (dictionaries.length === 0) {

            changes.dictionaryNumber = null;
        }
        else if (!dictionaries.some(a => a.number === this.state.dictionaryNumber)) {

            changes.dictionaryNumber = dictionaries[0].number;
        }

        this.setState(changes);
    }

    onDictionaryFieldChange(number: number, fields: DictionaryField[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let target = this.state.reportConfig.dictionaries.find(x => x.number === number) as Dictionary;

        target.fields = fields;

        let changes = {} as State;

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { dictionaries: this.state.reportConfig.dictionaries } as ReportConfig);

        this.setState(changes);
    }

    onFunctionChange(functions: UserDefinedFunction[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let changes = {} as State;

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { userDefinedFunctions: functions } as ReportConfig);

        if (functions.length === 0) {

            changes.functionNumber = null;
        }
        else if (!functions.some(a => a.number === this.state.functionNumber)) {

            changes.functionNumber = functions[0].number;
        }

        this.setState(changes);
    }

    onFunctionParameterChange(number: number, parameters: UserDefinedFunctionParameter[]): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let target = this.state.reportConfig.userDefinedFunctions.find(x => x.number === number) as UserDefinedFunction;

        target.parameters = parameters;

        let changes = {} as State;

        changes.reportConfig = Object.assign({}, this.state.reportConfig, { userDefinedFunctions: this.state.reportConfig.userDefinedFunctions } as ReportConfig);

        this.setState(changes);
    }

    onClipboardChange(clipboard: ReportLogicLocation) {

        this.setState({ clipboard: clipboard });
    }

    onAggregationErrorClick(aggregationNumber: number): void {

        this.setState({ aggregationNumber: aggregationNumber }, () => reportConfigNavigation.navigateToSection(htmlIds.aggregations));
    }

    onIsinSelectorErrorClick(isinNumber: number): void {

        this.setState({ isinSelectorNumber: isinNumber }, () => reportConfigNavigation.navigateToSection(htmlIds.cacheKeys));
    }

    onLeiSelectorErrorClick(leiNumber: number): void {

        this.setState({ leiSelectorNumber: leiNumber }, () => reportConfigNavigation.navigateToSection(htmlIds.cacheKeys));
    }

    onExchangeRatesSelectorErrorClick(exchangeRatesNumber: number): void {

        this.setState({ exchangeRatesSelectorNumber: exchangeRatesNumber }, () => reportConfigNavigation.navigateToSection(htmlIds.cacheKeys));
    }

    onAnnaDsbUpiEnrichmentSelectorErrorClick(number: number): void {

        this.setState({ annaDsbUpiEnrichmentNumber: number }, () => reportConfigNavigation.navigateToSection(htmlIds.cacheKeys));
    }

    onLSESelectorErrorClick(number: number): void {

        this.setState({ lseNumber: number }, () => reportConfigNavigation.navigateToSection(htmlIds.cacheKeys));
    }

    onFCARegulatedEntitiesSelectorErrorClick(number: number): void {

        this.setState({ fcaRegulatedEntitiesNumber: number }, () => reportConfigNavigation.navigateToSection(htmlIds.cacheKeys));
    }

    onFirdsFcaInstrumentSelectorErrorClick(number: number): void {

        this.setState({ firdsFcaInstrumentSelectorNumber: number }, () => reportConfigNavigation.navigateToSection(htmlIds.cacheKeys));
    }

    onFirdsEsmaInstrumentSelectorErrorClick(number: number): void {

        this.setState({ firdsEsmaInstrumentSelectorNumber: number }, () => reportConfigNavigation.navigateToSection(htmlIds.cacheKeys));
    }

    onAnnaDsbUpiSelectorErrorClick(number: number): void {

        this.setState({ annaDsbUpiNumber: number }, () => reportConfigNavigation.navigateToSection(htmlIds.cacheKeys));
    }

    onCaseFieldErrorClick(caseNumber: number, fieldNumber: number): void {

        this.setState({ caseNumber: caseNumber }, () => reportConfigNavigation.navigateToItem(reportConfigNavigation.buildReportFieldId(fieldNumber)));
    }

    save(exit: boolean): Promise<any> {

        let copy = copyObject(this.state.reportConfig);

        let errors = validate(copy, this.pageContext.collections, this.onAggregationErrorClick, this.onIsinSelectorErrorClick, this.onLeiSelectorErrorClick, this.onExchangeRatesSelectorErrorClick, this.onAnnaDsbUpiEnrichmentSelectorErrorClick, this.onAnnaDsbUpiSelectorErrorClick, this.onLSESelectorErrorClick, this.onFCARegulatedEntitiesSelectorErrorClick, this.onFirdsFcaInstrumentSelectorErrorClick, this.onFirdsEsmaInstrumentSelectorErrorClick, this.onCaseFieldErrorClick);

        if (errors.length > 0) {

            this.setState({ reportConfig: copy, errors: errors }, () => reportConfigNavigation.navigateToSection(htmlIds.bottom));

            return Promise.reject('Invalid report config');
        }

        let url = urlHelper.buildRoute(['report', this.id]);

        return httpClient.put(url, copy).then(() => this.onSaveSuccess(exit));
    }

    onExitClick(): void {
        let route = appRoutes.reporting.home;
        if (this.state.reportConfig.type === reportType.accuracy) {
            route = appRoutes.validation.home;
        }

        history.push(route);

        if (editReportConfigHelper.isValidationConfig(this.state.reportConfig)) {
            lockManager.unlockComponents(this.state.reportConfig.id || 0);
        }
    }

    onSaveSuccess(exit: boolean): void {
        let route = appRoutes.reporting.home;
        if (this.state.reportConfig.type === reportType.accuracy) {
            route = appRoutes.validation.home;
        }

        const payload = (): void => {

            if (exit) {
                history.push(route);
            }
            else {
                window.location.reload();
            }
        };

        store.dispatch({ type: actions.navigation.execute, payload: payload });
    }

    onRefreshDataSourcesSuccess(response: AxiosResponse<IngestionConfig[]>): void {

        seedReportDataSources(response.data, this.pageContext);

        this.setState({ isWaiting: false });
    }

    onQuickActionItemSelected(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {
        this.onQuickActionFieldChange(selectedValue.toString());
    }

    processComponentWhenNotLocked(number: number, reportConfigCopy: ReportConfig, reportConfigComponentLock: ReportConfigComponentLock) {

        let editedComponents = this.state.editedComponents.slice();
        const componentAlreadyLocked = this.state.editedComponents.find(x => x.componentType === reportConfigComponentLock.componentType && x.componentId === reportConfigComponentLock.componentId);
        if (!componentAlreadyLocked) {
            this.setState({ showComponentLockedPopup: false, reportConfig: reportConfigCopy });

            const intervalId = setInterval(() => {
                this.onCheckMinutesUntilLockWillExpire(reportConfigComponentLock.componentId || 0, reportConfigComponentLock.componentType);
            }, this.oneMinutesInMileseconds);

            let editedComponent = { number: number, intervalId, lockedById: 'noone', componentId: reportConfigComponentLock.componentId, componentType: reportConfigComponentLock.componentType || ComponentType.None, reportConfigId: this.state.reportConfig.id || 0 };
            editedComponents.push(editedComponent);
        }
        return editedComponents;
    }

    determinateComponent(reportConfigComponentLock: ReportConfigComponentLock, reportConfig: ReportConfig) {
        let component;
        switch (reportConfigComponentLock.componentType) {
            case ComponentType.Variable: component = reportConfig.variables.find(v => v.id === reportConfigComponentLock.componentId); break;
            case ComponentType.Lookup: component = reportConfig.lookups.find(v => v.id === reportConfigComponentLock.componentId); break;
            case ComponentType.LeiSelector: component = reportConfig.leiSelectors.find(v => v.id === reportConfigComponentLock.componentId); break;
            case ComponentType.ExchangeRatesSelector: component = reportConfig.exchangeRatesSelectors.find(v => v.id === reportConfigComponentLock.componentId); break;
            case ComponentType.AnnaDsbUpiEnrichmentSelector: component = reportConfig.annaDsbUpiEnrichmentSelectors.find(v => v.id === reportConfigComponentLock.componentId); break;
            case ComponentType.AnnaDsbUpiSelector: component = reportConfig.annaDsbUpiSelectors.find(v => v.id === reportConfigComponentLock.componentId); break;
            case ComponentType.LSESelector: component = reportConfig.lseSelectors.find(v => v.id === reportConfigComponentLock.componentId); break;
            case ComponentType.FCARegulatedEntitiesSelector: component = reportConfig.fcaRegulatedEntitiesSelectors.find(v => v.id === reportConfigComponentLock.componentId); break;
            case ComponentType.FirdsEsmaInstrumentSelectors: component = reportConfig.firdsEsmaInstrumentSelectors.find(v => v.id === reportConfigComponentLock.componentId); break;
            case ComponentType.FirdsFcaInstrumentSelectors: component = reportConfig.firdsFcaInstrumentSelectors.find(v => v.id === reportConfigComponentLock.componentId); break;
            case ComponentType.IsinSelector: component = reportConfig.isinSelectors.find(v => v.id === reportConfigComponentLock.componentId); break;
            case ComponentType.AccuracyValidation: component = reportConfig.validations.find(v => v.id === reportConfigComponentLock.componentId); break;
            default: break;
        }
        return component;
    }

    async onComponentContainerClick(reportConfigComponentLock: ReportConfigComponentLock): Promise<boolean> {

        if (reportConfigComponentLock?.componentId && reportConfigComponentLock?.componentId > 0 && editReportConfigHelper.isValidationConfig(this.state.reportConfig)) {

            const componentAlreadyLocked = this.state.editedComponents.find(x => x.componentType === reportConfigComponentLock.componentType && x.componentId === reportConfigComponentLock.componentId);

            if (!componentAlreadyLocked) {

                let isValidJsonConfig = this.validateThatComponentsBeforeLock();

                if (isValidJsonConfig) {
                    let reportConfigCopy = Object.assign({}, this.state.reportConfig);


                    let component = this.determinateComponent(reportConfigComponentLock, reportConfigCopy);

                    if (component && !component.isUsedByCurrentUser) {
                        const result = await lockManager.lockValidationConfigComponent(this.state.reportConfig, { reportConfigId: this.state.reportConfig.id || 0, componentId: reportConfigComponentLock.componentId || 0, componentType: reportConfigComponentLock.componentType || ComponentType.None });

                        this.onLockValidationConfigComponentResult(result, component, reportConfigCopy, reportConfigComponentLock);
                    }
                }
            }
        }
        return false;
    }

    onLockValidationConfigComponentResult(result: ReportConfigLockResponse, component: any, reportConfig: ReportConfig, reportConfigComponentLock: ReportConfigComponentLock) {

        if (result?.lockedBy || result.error) {
            component.isReadOnly = true;
            component.isLocked = true;
            component.isUsedByCurrentUser = false;
            reportComponentBuildersHelper.showMesage(result.error || '', false);
            this.setState({ reportConfig: reportConfig });
            return true;
        }
        else {
            component.isUsedByCurrentUser = true;
            component.isReadOnly = false;
            component.isLocked = false;
            let editedComponents = this.processComponentWhenNotLocked(component.number, reportConfig, reportConfigComponentLock);
            this.setState({ editedComponents });
        }
    }

    validateThatComponentsBeforeLock() {

        let lookupWithoutDataSource2 = Object.assign({}, this.state.reportConfig).lookups.find(l => !l.dataSource2Id);

        if (lookupWithoutDataSource2) {

            let error = `Please set Data Source 2 to lookup ${lookupWithoutDataSource2.name}`;

            if (!lookupWithoutDataSource2.errors.includes(error)) {
                lookupWithoutDataSource2.errors.push(error);
            }

            return false;
        }
    }

    onActionItemsCountChange(actionType: ActionType, componentType: ComponentType, itemId: number) {
        const counts = Object.assign({}, this.state.counts);

        if (componentType === ComponentType.AccuracyValidation) {
            let validationCount = counts.validationActionItemCounts[itemId] || 0;
            counts.validationActionItemCounts[itemId] = actionType === ActionType.Add ? validationCount + 1 : validationCount - 1;
        }
        else if (componentType === ComponentType.Filter) {
            let filterActionItemsCount = counts.filterActionItemCounts[itemId] || 0;
            counts.filterActionItemCounts[itemId] = actionType === ActionType.Add ? filterActionItemsCount + 1 : filterActionItemsCount - 1;
        }
        else if (componentType === ComponentType.Enrichment) {
            let enrichmentActionItemsCount = counts.reportFieldActionItemCounts[itemId] || 0;
            counts.reportFieldActionItemCounts[itemId] = actionType === ActionType.Add ? enrichmentActionItemsCount + 1 : enrichmentActionItemsCount - 1;
        }
        else if (componentType === ComponentType.Case) {
            let caseActionItemsCount = counts.caseActionItemCounts[itemId] || 0;
            counts.caseActionItemCounts[itemId] = actionType === ActionType.Add ? caseActionItemsCount + 1 : caseActionItemsCount - 1;
        };

        this.setState({ counts });
    }

    render(): JSX.Element {

        return (
            <>
                <ErrorMessage error={this.state.lockMessage} />

                <ReportConfigEditor
                    isWaiting={this.state.isWaiting}
                    isReadOnly={false}
                    aggregationNumber={this.state.aggregationNumber}
                    isinSelectorNumber={this.state.isinSelectorNumber}
                    leiSelectorNumber={this.state.leiSelectorNumber}
                    exchangeRatesSelectorNumber={this.state.exchangeRatesSelectorNumber}
                    annaDsbUpiEnrichmentSelectorNumber={this.state.annaDsbUpiEnrichmentNumber}
                    lseSelectorNumber={this.state.lseNumber}
                    fcaRegulatedEntitiesSelectorNumber={this.state.fcaRegulatedEntitiesNumber}
                    annaDsbUpiSelectorNumber={this.state.annaDsbUpiNumber}
                    caseNumber={this.state.caseNumber}
                    dictionaryNumber={this.state.dictionaryNumber}
                    functionNumber={this.state.functionNumber}
                    collections={this.pageContext.collections}
                    reportConfig={this.state.reportConfig}
                    counts={this.state.counts}
                    clipboard={this.state.clipboard}
                    onAggregationSelected={this.onAggregationSelected}
                    onIsinSelectorSelected={this.onIsinSelectorSelected}
                    onLeiSelectorSelected={this.onLeiSelectorSelected}
                    onExchangeRatesSelectorSelected={this.onExchangeRatesSelectorSelected}
                    onAnnaDsbUpiEnrichmentSelectorSelected={this.onAnnaDsbUpiEnrichmentSelectorSelected}
                    onAnnaDsbUpiSelectorSelected={this.onAnnaDsbUpiSelectorSelected}
                    onFirdsFcaInstrumentSelected={this.onFirdsFcaInstrumentSelectorSelected}
                    onFirdsEsmaInstrumentSelected={this.onFirdsEsmaInstrumentSelectorSelected}
                    onLSESelectorSelected={this.onLSESelectorSelected}
                    onFCARegulatedEntitiesSelected={this.onFCARegulatedEntitiesSelectorSelected}
                    onCaseSelected={this.onCaseSelected}
                    onDictionarySelected={this.onDictionarySelected}
                    onFunctionSelected={this.onFunctionSelected}
                    onDataSourceChange={this.onDataSourceChange}
                    onAggregationChange={this.onAggregationChange}
                    onAggregatedRecordFieldChange={this.onAggregatedRecordFieldChange}
                    onLookupChange={this.onLookupChange}
                    onIsinSelectorChange={this.onIsinSelectorChange}
                    onLeiSelectorChange={this.onLeiSelectorChange}
                    onExchangeRatesSelectorChange={this.onExchangeRatesSelectorChange}
                    onAnnaDsbUpiEnrichmentSelectorChange={this.onAnnaDsbUpiEnrichmentSelectorChange}
                    onAnnaDsbUpiSelectorChange={this.onAnnaDsbUpiSelectorChange}
                    onLSESelectorChange={this.onLSESelectorChange}
                    onFCARegulatedEntitiesSelectorChange={this.onFCARegulatedEntitiesSelectorChange}
                    onFirdsFcaInstrumentChanged={this.onFirdsFcaInstrumentSelectorChange}
                    onFirdsEsmaInstrumentChanged={this.onFirdsEsmaInstrumentSelectorChange}
                    onVariableChange={this.onVariableChange}
                    onFilterChange={this.onFilterChange}
                    onEndpointFilterChange={this.onEndpointFilterChange}
                    onValidationChange={this.onValidationChange}
                    onCaseChange={this.onCaseChange}
                    onEnrichmentChange={this.onEnrichmentChange}
                    onSyncedWithIdChange={this.onSyncedWithIdChange}
                    onMatchingKeyChange={this.onMatchingKeyChange}
                    onMatchingKeyEndPointChange={this.onMatchingKeyEndPointChange}
                    onDictionaryChange={this.onDictionaryChange}
                    onDictionaryFieldChange={this.onDictionaryFieldChange}
                    onFunctionChange={this.onFunctionChange}
                    onFunctionParameterChange={this.onFunctionParameterChange}
                    onClipboardChange={this.onClipboardChange}
                    onLookupConditionUpdated={this.updateLookupDataSourceId}
                    onImportSuccess={() => this.onSaveSuccess(false)}
                    errors={this.state.errors}
                    regime={this.state.reportConfig.regime}
                    onComponentContainerClick={this.onComponentContainerClick}
                    onActionItemsCountChange={this.onActionItemsCountChange}
                    onAggregationSyncedWithIdChange={this.onAggregationSyncedWithIdChange}
                    firdsEsmaInstrumentSelectorNumber={this.state.firdsEsmaInstrumentSelectorNumber}
                    firdsFcaInstrumentSelectorNumber={this.state.firdsFcaInstrumentSelectorNumber} />

                <div id={htmlIds.bottom}></div>

                <QuickActions
                    isWaiting={this.state.isWaiting}
                    isReadOnly={false}
                    onSaveClick={() => this.save(false)}
                    onSaveAndExitClick={() => this.save(true)}
                    onExitClick={this.onExitClick}>
                    {
                        this.state.reportConfig.type !== reportType.accuracy &&
                        <div className="col-auto">
                            <SelectSearch
                                disabled={this.state.isWaiting}
                                options={buildOptions(this.pageContext.collections.reportFields).map(createSelectSearchOption)}
                                value={this.state.quickActionNumber}
                                search={true}
                                onChange={this.onQuickActionItemSelected} />
                        </div>
                    }

                </QuickActions>

                <OperandModal
                    reportType={this.state.reportConfig.type}
                    collections={this.pageContext.collections}
                    userDefinedFunctions={this.state.reportConfig.userDefinedFunctions}
                    dictionaries={this.state.reportConfig.dictionaries}
                    lookups={this.state.reportConfig.lookups}
                    variables={this.state.reportConfig.variables}
                    isinSelectors={this.state.reportConfig.isinSelectors}
                    leiSelectors={this.state.reportConfig.leiSelectors}
                    exchangeRatesSelectors={this.state.reportConfig.exchangeRatesSelectors} />

                <CopyReportFieldModal fields={this.pageContext.collections.reportFields} cases={this.state.reportConfig.cases} />

                <CopyAggregationModal aggregations={this.state.reportConfig.aggregations} fieldDefinitions={this.pageContext.collections.dataSources.find(x => x.dataSourceKind === dataSourceKind.raw)?.fieldDefinitions || []} />

                <CopyValidationModal validations={this.state.reportConfig.validations} />

                <CopyVariableModal variables={this.state.reportConfig.variables} />
            </>
        );
    }
}

export default ReportEditPage;
