import './UrlList.scss';
import ComplianceUrl from '../../../types/common/ComplianceUrl';
import React from 'react';

interface UrlListProps {
    urls: ComplianceUrl[];
}

class UrlList extends React.Component<UrlListProps> {

    getUrlItem(url: ComplianceUrl, i: number): JSX.Element {
        return (
            <li key={i}>
                <a href={url.url} target="_blank" rel="noopener noreferrer">{url.name}</a>
            </li>
        );
    }

    render(): JSX.Element {

        return (
            <ul className="url-list">
                {this.props.urls.map(this.getUrlItem)}
            </ul>
        );

    }
}

export default UrlList;