import { addOptions, clearOptions } from './LazySelectList.Helpers';
import pixelize from '../../../infrastructure/helpers/html/pixelize';
import React from 'react';
import SelectOption from '../../../infrastructure/types/SelectOption';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';

interface Props {
    label: string;
    className: string;
    width: number;
    getOptions: () => SelectOption[];
    onSelected: (value: string) => void;
}

class LazySelectList extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onMouseDown(e: React.MouseEvent<HTMLSelectElement, MouseEvent>): void {

        clearOptions(e.currentTarget);

        let options = this.props.getOptions();

        addOptions(e.currentTarget, options);
    }

    onChange(e: React.ChangeEvent<HTMLSelectElement>): void {

        if (!stringHelper.isEmpty(e.target.value)) {

            this.props.onSelected(e.currentTarget.value);
        }

        clearOptions(e.currentTarget);
    }

    render(): JSX.Element {
        return (
            <select className={this.props.className} style={{ width: pixelize(this.props.width) }} onMouseDown={this.onMouseDown} onChange={this.onChange}>
                <option value="">{this.props.label}</option>
            </select>
        );
    }
}

export default LazySelectList;
