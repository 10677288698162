import { createOption } from './SyncedWithEditor.Helpers';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import FormGroup from '../FormGroup/FormGroup';
import guidance from '../../Report/ReportConfigEditor/ReportConfigEditor.Guidance';
import React from 'react';
import SelectList from '../SelectList/SelectList';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import validate from './SyncedWithEditor.Validation';
import Synced from '../../../types/report/Synced';

interface Props {
    itemNumber: number;
    collection: Synced[];
    onSyncedWithIdChange?: (number: number, syncedWithId: number | null) => void;
    info: string
}

interface State {
    error: string | null;
}

class SyncedWithEditor extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = { error: null };

        this.onChange = this.onChange.bind(this);
        this.getValue = this.getValue.bind(this);
        this.isEnabled = this.isEnabled.bind(this);
        this.includeAsOption = this.includeAsOption.bind(this);
    }

    onChange(value: string): void {

        let syncedWithId = stringHelper.isEmpty(value) ? null : parseInt(value);

        let error = validate(this.props.itemNumber, syncedWithId, this.props.collection);

        this.setState({ error });

        if (error) {
            return;
        }

        this.props.onSyncedWithIdChange && this.props.onSyncedWithIdChange(this.props.itemNumber, syncedWithId);
    }

    getValue(): string {

        let item = this.props.collection.find(x => x.number === this.props.itemNumber) as Synced;

        if (item && typeHelper.isNumber(item.syncedWithId)) {

            return (item.syncedWithId as number).toString();
        }

        return '';
    }

    isEnabled(): boolean {

        let item = this.props.collection.find(x => x.number === this.props.itemNumber) as Synced;

        if (item && typeHelper.isNumber(item.id) && this.props.collection.some(x => x.syncedWithId === item.id)) {

            return false;
        }

        return true;
    }

    includeAsOption(item: Synced): boolean {

        if (!typeHelper.isNumber(item.id)) {
            return false;
        }

        if (typeHelper.isNumber(item.syncedWithId)) {
            return false;
        }

        if (item.number === this.props.itemNumber) {
            return false;
        }

        return true;
    }

    render(): JSX.Element {
        return (
            <>
                <FormGroup label="Keep in sync with:" labelClassName="col-5" contentClassName="col-7" info={this.props.info}>
                    <SelectList
                        options={this.props.collection.filter(this.includeAsOption).map(createOption)}
                        value={this.getValue()}
                        onChange={this.onChange}
                        disabled={!this.isEnabled()}
                        className="form-control"
                        includeEmptyOption={true} />
                </FormGroup>
                <ErrorMessage error={this.state.error} />
            </>
        );
    }
}

export default SyncedWithEditor;