import { AxiosResponse } from 'axios';
import { ReactComponent as AssignmentIcon } from '../../Common/BootstrapIcons/assignment-late.svg';
import { ReactComponent as CopyIcon } from '../../Common/BootstrapIcons/copy.svg';
import { ReactComponent as DownloadIcon } from '../../Common/BootstrapIcons/download.svg';
import { ReactComponent as EditIcon } from '../../Common/BootstrapIcons/edit.svg';
import { ReactComponent as HistoryIcon } from '../../Common/BootstrapIcons/history.svg';
import { ReactComponent as OffIcon } from '../../Common/BootstrapIcons/toggle-off.svg';
import { ReactComponent as OnIcon } from '../../Common/BootstrapIcons/toggle-on.svg';
import { ReactComponent as RemoveIcon } from '../../Common/BootstrapIcons/close.svg';
import { ReactComponent as ExportIcon } from '../../Common/BootstrapIcons/caret-up-fill.svg';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import GridRefresh from '../../../store/state/events/GridRefresh';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import identityStorage from '../../../infrastructure/authorization/identityStorage';
import PromiseButton from '../../Common/PromiseButton';
import React from 'react';
import ReportConfigGridItem from '../../../types/report/ReportConfigGridItem';
import store from '../../../store/store';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import writeUserRoles from '../../../infrastructure/constants/identity/writeUserRoles';
import reportRegimeHelper from '../../../infrastructure/helpers/report/reportRegime/reportRegimeHelper';
import Spinner from '../../Common/Spinner/Spinner';

const uniqueName = 'report-config-grid';

const resolveAction = (isEnabled: boolean): string => isEnabled ? 'archive' : 'unarchive';
const resolveIcon = (isEnabled: boolean): React.FunctionComponent<React.SVGProps<SVGSVGElement>> => isEnabled ? OnIcon : OffIcon;

const onChangeStatusClick = (config: ReportConfigGridItem): Promise<any> => {

    let action = resolveAction(config.isEnabled);

    let route = urlHelper.buildRoute(['report', config.id, action]);

    return httpClient.post(route).then(onSuccess);
};

const onSuccess = (response: AxiosResponse): void => {

    let payload: GridRefresh = {
        uniqueName: uniqueName,
        useCurrentPage: true
    };

    store.dispatch({ type: actions.grid.refresh, payload: payload });
};

const getColumns = (onEditClick: (id: number) => void,
    onCopyClick: (config: ReportConfigGridItem) => void,
    enabled: boolean,
    onDownloadClick: (id: number) => Promise<void>,
    onRemoveClick: (config: ReportConfigGridItem) => void,
    onCopyConfigSourceClick: (id: number) => Promise<void>,
    showSpinnerForId: number
): ColumnDefinition<ReportConfigGridItem>[] => {

    let identity = identityStorage.get();

    let hasWriteRole = writeUserRoles.includes(identity.userRole);

    return [
        {
            header: 'Id',
            sortKey: 'Id',
            getContent: x => x.id.toString(),
            width: '46px'
        },
        {
            header: 'Name',
            sortKey: 'Name',
            getContent: x => <button className="transparent-button" onClick={() => onEditClick(x.id)}>{x.name}</button>,
            className: 'break-all'
        },
        {
            header: 'Created By',
            getContent: x => x.createdBy,
            className: 'break-all'
        },
        {
            header: 'Date Created',
            sortKey: 'CreatedTimestamp',
            getContent: x => x.createdTimestamp,
            width: '164px'
        },
        {
            header: 'Regime',
            sortKey: 'Regime',
            getContent: x => reportRegimeHelper.getRegimeName(x.regime),
            width: '100px'
        },
        {
            header: 'Raw Data Source',
            sortKey: 'RawDataSource',
            getContent: x => x.rawDataSource,
            width: '160px',
            className: 'break-all'

        },
        {
            header: 'Stage',
            sortKey: 'IsReleased',
            getContent: x => x.isReleased ? 'Released' : 'Unreleased',
            width: '90px'
        },
        {
            header: 'Version Number',
            sortKey: 'ReleaseNumber',
            getContent: x => x.releaseNumber > 0 ? x.releaseNumber.toString() : '-',
            width: '130px'
        },
        {
            header: 'Actions',
            getContent: x =>
                <>
                    <button className="custom-icon-button" onClick={() => history.push(urlHelper.buildUrl(appRoutes.reporting.changeLog, { id: x.id, redirect: encodeURIComponent(appRoutes.reporting.home) }))}>
                        <CustomIcon icon={HistoryIcon} title={iconTooltip.changeLog} />
                    </button>
                    <button className="custom-icon-button" onClick={() => history.push(urlHelper.buildUrl(appRoutes.reporting.actionLog, { id: x.id }))}>
                        <CustomIcon icon={AssignmentIcon} title={iconTooltip.actionLog} />
                    </button>
                    <button className="custom-icon-button" onClick={() => onEditClick(x.id)}>
                        <CustomIcon icon={EditIcon} title={iconTooltip.edit} />
                    </button>
                    {
                        hasWriteRole &&
                        <PromiseButton className="custom-icon-button" enableOnErrorOnly={true} task={() => onChangeStatusClick(x)}>
                            <CustomIcon icon={resolveIcon(x.isEnabled)} title={resolveAction(x.isEnabled)} />
                        </PromiseButton>
                    }
                    <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onDownloadClick(x.id)}>
                        <CustomIcon icon={DownloadIcon} title={iconTooltip.download} />
                    </PromiseButton>
                    <button className="custom-icon-button" onClick={() => onCopyClick(x)}>
                        <CustomIcon icon={CopyIcon} title={iconTooltip.copy} />
                    </button>
                    <button className="custom-icon-button export-icon" onClick={async () => await onCopyConfigSourceClick(x.id)}>
                        {
                            x.id === showSpinnerForId ? <Spinner /> :
                                <CustomIcon icon={ExportIcon} title={iconTooltip.copyReportSource} />
                        }
                    </button>
                    {
                        hasWriteRole &&
                        !enabled &&
                        <button className="custom-icon-button" onClick={() => onRemoveClick(x)}>
                            <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                        </button>
                    }
                </>,
            width: '270px'
        }
    ];
};

export { getColumns, uniqueName };