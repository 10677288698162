const typeHelper = {
    isString: (x: any): boolean => typeof x === 'string',
    isFunction: (x: any): boolean => typeof x === 'function',
    isObject: (x: any): boolean => typeof x === 'object' && x !== null && !Array.isArray(x),
    isNumber: (x: any): boolean => typeof x === 'number' && !Number.isNaN(x),
    isInteger: (x: any): boolean => Number.isInteger(x),
    isArray: (x: any): boolean => Array.isArray(x)
};

export default typeHelper;
