import operandHelper from '../operand/operandHelper';
import selectMany from '../../common/array/selectMany';
import MatchingKey from '../../../../types/report/MatchingKey';
import assignmentOperandHelper from '../assignment/assignmentOperandHelper';
import clear from '../../common/array/clear';

const validate = (matchingKey: MatchingKey): string | null => {

    const message = 'MatchingKey not set up';

    clear(matchingKey.errors);

    if (matchingKey.operands.length > 0) {

        if (selectMany(matchingKey.operands, o => operandHelper.asCollection(o)).some(o => !operandHelper.isSetUp(o))) {

            matchingKey.errors.push(message);
            return message;
        }
    } else {

        let operands = assignmentOperandHelper.getAll(matchingKey.assignment);

        if (selectMany(operands, o => operandHelper.asCollection(o)).some(o => !operandHelper.isSetUp(o))) {

            matchingKey.errors.push(message);
            return message;
        }
    }

    return null;
};

const matchingKeyValidator = { validate };

export default matchingKeyValidator;
