import stringHelper from '../stringHelper';

const nameMinCharCount = 3;
const nameMaxCharCount = 100;

const validateConfigName = (name: string, isReportConfig: boolean): string | null => {

    var configType = 'Ingestion';

    if (isReportConfig) {
        configType = 'Report';
    }

    if (stringHelper.isEmpty(name)) {
        return `${configType} config name is required`;
    }

    if (name.trim().length < nameMinCharCount) {
        return `${configType} config name must be at least ${nameMinCharCount} characters`;
    }

    if (name.length > nameMaxCharCount) {
        return `${configType} config name must be no more than ${nameMaxCharCount} characters`;
    }

    if (stringHelper.containsNotAllowedCharacters(name)) {
        return `${configType} config name contains characters that are not allowed`;
    }

    return null;
};


export default validateConfigName;
