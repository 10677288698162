import { getColumns, uniqueName } from './ValidationHomePage.Grid';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import history from '../../../infrastructure/helpers/react/history';
import React from 'react';
import ServerDataGrid from '../../Common/DataGrid/ServerDataGrid/ServerDataGrid';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import DownloadModal from '../../Common/Modals/DownloadModal/DownloadModal';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import reportConfigBundleSerializer from '../../../infrastructure/helpers/report/import/reportConfigBundleSerializer';
import { getReportDataSources, getReportFields } from '../../Report/Pages/ReportPage/ReportPage.Helpers';
import { getConfigDataSources } from '../../Report/Import/ServerCalls';
import dataSourceKind from '../../../infrastructure/constants/dataSourceKind';
import ReportConfig from '../../../types/report/ReportConfig';
import actions from '../../../store/actions';
import store from '../../../store/store';
import ImportReportConfigModal from '../../Common/Modals/ImportReportConfigModal/ImportReportConfigModal';
import { Unsubscribe } from 'redux';
import GridRefresh from '../../../store/state/events/GridRefresh';

class ValidationHomePage extends React.Component<any, { reportConfigId: number, showDownloadPopup: boolean, showSpinnerForId: number }>  {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            reportConfigId: 0,
            showDownloadPopup: false,
            showSpinnerForId: 0
        }

        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onDownloadTypeSelected = this.onDownloadTypeSelected.bind(this);
        this.onCopyConfigSourceClick = this.onCopyConfigSourceClick.bind(this);
        this.refreshGridListener = this.refreshGridListener.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.refreshGridListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    refreshGridListener(): void {

        let state = store.getState();

        let proceed =
            state.action === actions.copyReportConfigModal.saveSuccess ||
            state.action === actions.importReportConfigModal.saveSuccess;

        if (!proceed) {
            return;
        }

        this.refreshGrid();
    }

    refreshGrid(): void {

        let payload: GridRefresh = {
            uniqueName: uniqueName,
            useCurrentPage: false
        };

        store.dispatch({ type: actions.grid.refresh, payload: payload });
    }

    onEditClick(id: number): void {

        let route = urlHelper.buildUrl(appRoutes.validation.edit, { id: id });

        history.push(route);
    }

    onDownloadClick(id: number): Promise<void> {

        this.setState({ showDownloadPopup: true, reportConfigId: id });
        return Promise.resolve();
    }

    onDownloadTypeSelected(downloadAsFragmented: boolean): void {

        this.setState({ showDownloadPopup: false });
        let route = urlHelper.buildRoute(['report', this.state.reportConfigId, downloadAsFragmented ? 'validation-export-fragmented' : 'validation-export-full']);

        httpClient.download(route);
    }

    onImportClick() {

        store.dispatch({ type: actions.importReportConfigModal.open, payload: null as any });
    }

    async onCopyConfigSourceClick(id: number): Promise<void> {

        this.setState({ showSpinnerForId: id });

        let url = 'report/' + id;

        var response = await httpClient.get<ReportConfig>(url);

        let res = await getReportDataSources(response.data);

        let dataSources = await getConfigDataSources(res.data.filter(x => x.dataSourceKind !== dataSourceKind.globalRef).map(x => x.id as number),
            res.data.filter(x => x.dataSourceKind === dataSourceKind.globalRef).map(x => x.id as number)
        );

        let reportFields = (await getReportFields(response.data)).data;

        let json = reportConfigBundleSerializer.serialize(response.data, dataSources, reportFields);

        await navigator.clipboard.writeText(json);

        this.setState({ showSpinnerForId: 0 });
    }

    render(): JSX.Element {
        return (
            <>
                <h3>Validation Configs</h3>
                <button type="button" className="btn btn-light mt-4 mb-4" onClick={this.onImportClick}>Import Validation Config</button>
                <ServerDataGrid
                    columns={getColumns(this.onEditClick, this.onDownloadClick, this.onCopyConfigSourceClick, this.state.showSpinnerForId)}
                    uniqueName={uniqueName}
                    dataSourceRoute="report/validation-page"
                    pageSize={10}
                    getKey={x => x.id} />
                <DownloadModal
                    onClose={() => { this.setState({ showDownloadPopup: false }) }}
                    onDownload={this.onDownloadTypeSelected}
                    isOpen={this.state.showDownloadPopup} />
                <ImportReportConfigModal
                    title="Import Validation Config"
                    skipDataSourcesValidation={true}
                    isValidationConfig={true}/>
            </>
        );
    }
}

export default ValidationHomePage;
