const separator = '|';

const add = (filenames: string): string => {

    return filenames + separator;
};

const remove = (filenames: string, i: number): string => {

    let parts = filenames.split(separator);

    parts.splice(i, 1);

    return parts.join(separator);
};

const edit = (filenames: string, i: number, value: string): string => {

    let parts = filenames.split(separator);

    parts[i] = value;

    return parts.join(separator);
};

const split = (filenames: string): string[] => {

    return filenames.split(separator);
};

const separatedValueHelper = {
    add,
    remove,
    edit,
    split
};

export default separatedValueHelper;
