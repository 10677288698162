import FieldDefinition from '../../../../types/common/FieldDefinition';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import lookupHelper from '../../../../infrastructure/helpers/functions/lookup/lookupHelper';
import mixedOperandHelper from '../../../../infrastructure/helpers/functions/operand/mixedOperandHelper';
import pseudocodeHelper from '../../../../infrastructure/helpers/functions/common/pseudocodeHelper';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import { SelectSearchOption } from 'react-select-search';
import Dictionary from '../../../../types/report/Dictionary';
import dictionaryHelper from '../../../../infrastructure/helpers/functions/dictionaries/dictionaryHelper';
import dataSourceHelper from '../../../../infrastructure/helpers/functions/common/dataSourceHelper';

const nameAndType = (name: string, type: string): string => `[${name}] (${type})`;

const createFieldOption = (definition: FieldDefinition): SelectSearchOption => {
    return {
        value: (definition.id as number).toString(),
        name: nameAndType(definition.name, definition.type)
    };
};

const createReportFieldOption = (definition: ReportFieldDefinition): SelectSearchOption => {
    return {
        value: definition.id.toString(),
        name: nameAndType(definition.name, definition.dataType)
    };
};

const createVariableOption = (variable: ReportVariable): SelectSearchOption => {
    return {
        value: variable.number.toString(),
        name: pseudocodeHelper.wrapInBracketsVariable(variable.number, variable.name)
    };
};

const createDirectDataSourceOption = (dataSource: IngestionConfig): SelectOption => {
    return {
        value: mixedOperandHelper.buildDirectDataSourceIdentifier(dataSource.id as number),
        text: pseudocodeHelper.wrapInBrackets(dataSource.name)
    };
};

const createLookupOption = (lookup: Lookup, dataSources: IngestionConfig[]): SelectSearchOption[] => {

    let dataSource = dataSourceHelper.getById(dataSources, lookup.dataSource2Id as number);

    let lookupName = pseudocodeHelper.wrapInBrackets(lookupHelper.getName(lookup, dataSource?.name || ''));

    let options: SelectSearchOption[] = [];

    dataSource.fieldDefinitions.forEach((definition) => {
        const name = [lookupName, nameAndType(definition.name, definition.type)].join('.');

        options.push({
            value: lookup.number + '-' + definition.id,
            name
        });
    });

    return options;
};

const createDictionaryOption = (dictionary: Dictionary, dataSources: IngestionConfig[]): SelectOption => {

    let dataSource = dataSourceHelper.getById(dataSources, dictionary.dataSourceId as number);

    return {
        value: mixedOperandHelper.buildDictionaryIdentifier(dictionary.number, dictionary.dataSourceId as number),
        text: pseudocodeHelper.wrapInBrackets(dictionaryHelper.getName(dictionary, dataSource.name))
    };
};

const reportOption = (text: string): SelectOption => {
    return {
        value: mixedOperandHelper.buildReportIdentifier(),
        text
    }
};

const variableOption: SelectOption = {
    value: mixedOperandHelper.buildVariableIdentifier(),
    text: '[Variable]'
};

const lookupOption: SelectOption = {
    value: mixedOperandHelper.buildLookupIdentifier(),
    text: '[Lookup]'
};

const emptyOption: SelectSearchOption = {
    value: '',
    name: ''
};

const createAccuracyFilterOption = (definition: ReportFieldDefinition): SelectOption => {
    return {
        value: definition.id.toString(),
        text: nameAndType(definition.name, definition.dataType)
    };
};

export {
    createFieldOption,
    createReportFieldOption,
    createVariableOption,
    createDirectDataSourceOption,
    createLookupOption,
    reportOption,
    variableOption,
    createDictionaryOption,
    lookupOption,
    emptyOption,
    createAccuracyFilterOption
};