import '../RadioButtonGroup/RadioButtonGroup.scss';
import React from 'react';
import SelectOption from '../../../infrastructure/types/SelectOption';

interface RadioButtonGroupMultiSelectProps {
    options: SelectOption[];
    values: Set<string>;
    name: string;
    onClick: (value: string, isChecked: boolean) => void;
    isDisabled?: (value: string) => boolean;
    onChange?: (isChecked: boolean) => void;
}

class RadioButtonGroupMultiSelect extends React.Component<RadioButtonGroupMultiSelectProps> {
    constructor(props: RadioButtonGroupMultiSelectProps) {
        super(props);

        this.getRadioButton = this.getRadioButton.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    getRadioButton(option: SelectOption): JSX.Element {
        return (
            <label key={option.value}>
                <input
                    type="radio"
                    name={option.text}
                    value={option.value}
                    checked={this.props.values.has(option.value)}
                    onChange={e => this.props.onChange ? this.props.onChange( e.currentTarget.checked) : null}
                    onClick={this.onClick}
                    disabled={this.props.isDisabled ? this.props.isDisabled(option.value) : false} />
                {option.text}
            </label>
        );
    }

    onClick(e: React.MouseEvent<HTMLInputElement>): void {
        this.props.onClick(e.currentTarget.value, e.currentTarget.checked);
    }

    render(): JSX.Element {
        return (
            <div className="radio-button-group">
                {this.props.options.map(this.getRadioButton)}
            </div>
        );
    }
}

export default RadioButtonGroupMultiSelect;
