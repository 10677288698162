import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const createFunctionOption = (func: UserDefinedFunction): SelectOption => {
    return {
        value: func.number.toString(),
        text: stringHelper.isNonEmpty(func.name) ? func.name : `Function ${func.number}`
    };
};

export { createFunctionOption };
