import SelectOption from '../../../../infrastructure/types/SelectOption';
import UserDefinedFunctionParameter from '../../../../types/report/UserDefinedFunctionParameter';

const createOption = (parameter: UserDefinedFunctionParameter): SelectOption => {

    return {
        value: parameter.number.toString(),
        text: parameter.name
    };
};

export { createOption };
