import FieldDefinition from '../../../types/common/FieldDefinition';

const getChangeDataTypeMessage = (definition: FieldDefinition, dataType: string): string => {

    return `Field ${definition.name} has data type ${definition.type}. Are you sure you want to change it to ${dataType}?`;
};

const getRemoveMessage = (definition: FieldDefinition): string => {

    return `Are you sure you want to remove field ${definition.name}?`;
};

const findNotUniqueIndex = (name: string, definitions: FieldDefinition[], index?:number): number => {

    let set = new Set<string>();

    for (var i = 0; i < definitions.length; i++) {

        if (definitions[i].name === name) {
            return index || i;
        }

        let alternativeNames = definitions?.[i]?.alternativeNames || [];

        for (var j = 0; j < alternativeNames.length; j++) {

            if (set.has(alternativeNames[j])) {
                return j;
            }
            else {
                set.add(alternativeNames[j]);
            }
        }
    }

    return -1;
}

export { getChangeDataTypeMessage, getRemoveMessage, findNotUniqueIndex };
