import { getRemoveTooltip } from './AssignmentItem.Helpers';
import { getOperandContent } from '../../Condition/ContitionItem/ConditionItem.Rendering';
import { ReactComponent as RemoveIcon } from '../../../Common/BootstrapIcons/trash.svg';
import Assignment from '../../../../types/functions/Assignment/Assignment';
import AssignmentItemDetails from '../../../../types/functions/Assignment/AssignmentItemDetails';
import assignmentItemHelper from '../../../../infrastructure/helpers/functions/assignment/assignmentItemHelper';
import Condition from '../../../../types/functions/Condition/Condition';
import ConditionBuilder from '../../Condition/ConditionBuilder';
import copyObject from '../../../../infrastructure/helpers/common/copyObject';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import locationType from '../../../../types/functions/Location/LocationType';
import Lookup from '../../../../types/functions/Lookup';
import React from 'react';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import ReportVariable from '../../../../types/report/ReportVariable';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import assignmentHelper from '../../../../infrastructure/helpers/functions/assignment/assignmentHelper';
import { reorder } from '../../Condition/ContitionItem/ConditionItem.Helpers';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import './AssignmentItem.scss';

interface AssignmentItemProps {
    isReadOnly: boolean;
    functions: UserDefinedFunction[],
    lookups: Lookup[];
    dictionaries: Dictionary[];
    variables: ReportVariable[];
    dataSources: IngestionConfig[];
    reportFields: ReportFieldDefinition[];
    customDataSourceFields: CustomDataSourceFields;
    location: ReportLogicLocation;
    assignment: Assignment;
    fieldName: string;
    fieldNameSuffix?: string;
    details: AssignmentItemDetails;
    onAddAssignmentClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onRemoveAssignmentClick: (location: ReportLogicLocation, isSingle?: boolean, event?: React.MouseEvent<HTMLElement>) => void;
    onResetAssignmentClick: (location: ReportLogicLocation, isSingle?: boolean, event?: React.MouseEvent<HTMLElement>) => void;
    onOperandClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onComparisonTypeChange: (location: ReportLogicLocation, value: string, event?: React.ChangeEvent<HTMLElement>) => void;
    onRemoveConditionClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onLogicalOperatorChange: (location: ReportLogicLocation, operator: string, event?: React.ChangeEvent<HTMLElement>) => void;
    onParenthesesChange: (location: ReportLogicLocation, count: number, type: string, event?: React.MouseEvent<HTMLElement>) => void;
    onAddConditionClick: (location: ReportLogicLocation, operator: string | null, event?: React.MouseEvent<HTMLElement>) => void;
    onCopyClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onPasteClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    allowPaste: (location: ReportLogicLocation) => boolean;
    onValidateFieldClick: (event?: React.MouseEvent<HTMLElement>) => void;
    getContent?: (assignment: Assignment) => JSX.Element;
    onClearClick?: (e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLElement>) => Promise<boolean>;
    onReorderConditions?: (error?: string) => void;
}

class AssignmentItem extends React.Component<AssignmentItemProps, any> {

    constructor(props: AssignmentItemProps) {
        super(props);

        this.onAssignmentValueClick = this.onAssignmentValueClick.bind(this);
        this.onRemoveAssignmentClick = this.onRemoveAssignmentClick.bind(this);
        this.onAddAssignmentClick = this.onAddAssignmentClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.onPasteClick = this.onPasteClick.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
        this.onReorderConditions = this.onReorderConditions.bind(this);
    }

    onAssignmentValueClick(operandNumber: number, argumentIndexes: number[], event?: React.MouseEvent<HTMLElement>): void {

        let location = copyObject(this.props.location);

        location.pieceOfCode = locationType.pieceOfCode.assignmentValue;
        location.operandNumber = operandNumber;
        location.argumentIndexes = argumentIndexes;

        this.props.onOperandClick(location, event);
    }

    onRemoveAssignmentClick(event: React.MouseEvent<HTMLElement>): void {

        (this.props.details.isSingle ? this.props.onResetAssignmentClick : this.props.onRemoveAssignmentClick)(this.props.location, this.props.details.isSingle, event);
    }

    onAddAssignmentClick(event: React.MouseEvent<HTMLElement>): void {

        this.props.onAddAssignmentClick(this.props.location, event);
    }

    onCopyClick(event: React.MouseEvent<HTMLElement>): void {

        this.props.onCopyClick(this.getRootValueLocation(this.props.location), event);
    }

    onPasteClick(event: React.MouseEvent<HTMLElement>): void {

        this.props.onPasteClick(this.getRootValueLocation(this.props.location), event);
    }

    async onClearClick(e: React.MouseEvent<HTMLElement>): Promise<void> {
        const isLocked = this.props.onClearClick && await this.props.onClearClick(e);
        if (isLocked) {
            e.stopPropagation();
        }
        else {
            this.setState({ assignment: assignmentHelper.removeOnlyValues(false, this.props.assignment) });
        }
    }

    allowPaste(): boolean {

        return this.props.allowPaste(this.getRootValueLocation(this.props.location));
    }

    getRootValueLocation(location: ReportLogicLocation): ReportLogicLocation {

        let result = copyObject(location);

        result.pieceOfCode = locationType.pieceOfCode.assignmentValue;
        result.argumentIndexes = [];

        return result;
    }

    onReorderConditions(dragNumber: number, dropNumber: number, error?: string) {

        if (!error) {
            if (this.props.details.isIf && this.props.assignment.condition) {
                reorder(this.props.assignment.condition, dragNumber, dropNumber);
            }
            else if (!this.props.details.isSingle && !this.props.details.isElse) {
                const elseIfCondition = assignmentItemHelper.getElseIf(this.props.assignment, this.props.details.number);
                if (elseIfCondition) {
                    reorder(elseIfCondition, dragNumber, dropNumber);
                }
            }
        }
        this.props.onReorderConditions && this.props.onReorderConditions(error);
    }

    render(): JSX.Element {

        let hasCondition =
            !this.props.details.isSingle &&
            !this.props.details.isElse;

        return (
            <>
                {
                    hasCondition &&
                    <ConditionBuilder
                        isReadOnly={this.props.isReadOnly}
                        allowCopy={true}
                        keyword={assignmentItemHelper.resolveConditionKeyword(this.props.details)}
                        functions={this.props.functions}
                        lookups={this.props.lookups}
                        dictionaries={this.props.dictionaries}
                        variables={this.props.variables}
                        dataSources={this.props.dataSources}
                        reportFields={this.props.reportFields}
                        customDataSourceFields={this.props.customDataSourceFields}
                        location={this.props.location}
                        condition={this.props.assignment.condition as Condition}
                        clauseNumber={this.props.details.number}
                        onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                        onParenthesesChange={this.props.onParenthesesChange}
                        onAddConditionClick={this.props.onAddConditionClick}
                        onRemoveConditionClick={this.props.onRemoveConditionClick}
                        onComparisonTypeChange={this.props.onComparisonTypeChange}
                        onOperandClick={this.props.onOperandClick}
                        onCopyClick={this.props.onCopyClick}
                        onPasteClick={this.props.onPasteClick}
                        allowPaste={this.props.allowPaste}
                        onReorderAssignmentConditions={this.onReorderConditions} />
                }

                <div className="code-block">
                    {
                        hasCondition &&
                        <span className="code">THEN </span>
                    }
                    {
                        this.props.details.isElse &&
                        <span className="code">ELSE </span>
                    }

                    <span className='code'>{this.props.fieldName}</span>
                    <span className={this.props.fieldNameSuffix ? 'fieldNameSuffix' :'' }> {this.props.fieldNameSuffix || '='} </span>
                    {
                        this.props.assignment.doNotPopulate ?

                            <button className="transparent-button code" onClick={(event) => this.onAssignmentValueClick(1, [], event)} disabled={this.props.isReadOnly}>DO NOT POPULATE</button> :

                            this.props.getContent?.(this.props.assignment) ||
                            getOperandContent(this.props.assignment.value, 1, [], null, this.props.functions, this.props.lookups, this.props.dictionaries, this.props.variables, this.props.dataSources, this.props.reportFields, this.props.customDataSourceFields, this.props.assignment.value.selectorNumber, this.onAssignmentValueClick, this.props.isReadOnly)
                    }
                    {
                        !this.props.isReadOnly &&
                        <>
                            <button className="helper-btn" onClick={this.onAddAssignmentClick}>
                                {
                                    (this.props.details.isSingle || this.props.details.isIf) ? '+ If' : '+ Else If'
                                }
                            </button>
                            {
                                !this.props.assignment.doNotPopulate &&
                                <button className="helper-btn" onClick={this.onCopyClick}>Copy</button>
                            }
                            {
                                this.allowPaste() &&
                                <button className="helper-btn" onClick={this.onPasteClick}>Paste</button>
                            }
                            {
                                (this.props.details.isSingle || this.props.details.isElse) &&
                                <button className="helper-btn" onClick={(event) => { this.props.onValidateFieldClick(event) }}>Validate</button>
                            }
                            {
                                (this.props.assignment.value.operandType) &&
                                <button disabled={this.props.isReadOnly} className="helper-btn" onClick={this.onClearClick}>CLEAR</button>
                            }
                            {
                                !this.props.details.isElse &&
                                !(this.props.details.isSingle && this.props.assignment.doNotPopulate) &&
                                !(this.props.details.isIf && typeHelper.isObject(this.props.assignment.elseIf)) &&
                                <button onClick={this.onRemoveAssignmentClick} className="transparent-button" title={getRemoveTooltip(this.props.details.isSingle)}>
                                    <RemoveIcon />
                                </button>
                            }
                        </>
                    }
                </div>

            </>
        );
    }
}

export default AssignmentItem;
