import ReportConfigLockResponse from '../../../types/report/ReportConfigLockResponse';
import reportConfigLockResult from '../../../infrastructure/constants/reportConfigLockResult';
import Statement from '../../../infrastructure/types/Functions/Statement';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import SelectOption from '../../../infrastructure/types/SelectOption';
import { SelectSearchOption } from 'react-select-search';
import ReportConfig from '../../../types/report/ReportConfig';
import ComponentType from '../../../types/report/ComponentType';

const allowEdit = (response: ReportConfigLockResponse): boolean => {

    return response.result === reportConfigLockResult.success;
};

const getLockedMessage = (response: ReportConfigLockResponse): string | null => {

    if (response.result === reportConfigLockResult.lockedByAnotherUser) {

        return `Config is locked. User ${response.lockedBy} is currently editing this config`;
    }

    return null;
};

const isResetRequired = (number: number | null, statements: Statement[]): boolean => {

    if (!typeHelper.isNumber(number)) {

        return false;
    }

    return !statements.some(c => c.number === number);
};

const createSelectSearchOption = (field: SelectOption): SelectSearchOption => {
    return {
        name: field.text,
        value: field.value.toString(),
    };
};

const setValidationConfigComponentsProperties = (reportConfig: ReportConfig,  id: number, isUsedByCurrentUser: boolean, componentType: ComponentType): void => {

    reportConfig.variables.forEach((variable) => {
        if (variable.id === id && componentType === ComponentType.Variable && isUsedByCurrentUser) {
            variable.isReadOnly = false;
            variable.isUsedByCurrentUser = true;
        }
        else {
            variable.isReadOnly = true;
        }
        variable.isLocked = true;
    });
    reportConfig.lookups.forEach((lookup) => {
        if (lookup.id === id && componentType === ComponentType.Lookup && isUsedByCurrentUser) {
            lookup.isReadOnly = false;
            lookup.isUsedByCurrentUser = true;
        }
        else {
            lookup.isReadOnly = true;
        }
        lookup.isLocked = true;
    });
    reportConfig.isinSelectors.forEach((isinSelector) => {
        if (isinSelector.id === id && componentType === ComponentType.IsinSelector && isUsedByCurrentUser) {
            isinSelector.isReadOnly = false;
            isinSelector.isUsedByCurrentUser = true;
        }
        else {
            isinSelector.isReadOnly = true;
        }
        isinSelector.isLocked = true;
    });
    reportConfig.leiSelectors.forEach((leiSelector) => {
        if (leiSelector.id === id && componentType === ComponentType.LeiSelector && isUsedByCurrentUser) {
            leiSelector.isReadOnly = false;
            leiSelector.isUsedByCurrentUser = true;
        }
        else {
            leiSelector.isReadOnly = true;
        }
        leiSelector.isLocked = true;
    });
    reportConfig.exchangeRatesSelectors.forEach((exchangeRatesSelector) => {
        if (exchangeRatesSelector.id === id && componentType === ComponentType.ExchangeRatesSelector && isUsedByCurrentUser) {
            exchangeRatesSelector.isReadOnly = false;
            exchangeRatesSelector.isUsedByCurrentUser = true;
        }
        else {
            exchangeRatesSelector.isReadOnly = true;
        }
        exchangeRatesSelector.isLocked = true;
    });
    reportConfig.annaDsbUpiEnrichmentSelectors.forEach((selector) => {
        if (selector.id === id && componentType === ComponentType.AnnaDsbUpiEnrichmentSelector && isUsedByCurrentUser) {
            selector.isReadOnly = false;
            selector.isUsedByCurrentUser = true;
        }
        else {
            selector.isReadOnly = true;
        }
        selector.isLocked = true;
    });
    reportConfig.annaDsbUpiSelectors.forEach((selector) => {
        if (selector.id === id && componentType === ComponentType.AnnaDsbUpiSelector && isUsedByCurrentUser) {
            selector.isReadOnly = false;
            selector.isUsedByCurrentUser = true;
        }
        else {
            selector.isReadOnly = true;
        }
        selector.isLocked = true;
    });
    reportConfig.lseSelectors.forEach((selector) => {
        if (selector.id === id && componentType === ComponentType.LSESelector && isUsedByCurrentUser) {
            selector.isReadOnly = false;
            selector.isUsedByCurrentUser = true;
        }
        else {
            selector.isReadOnly = true;
        }
        selector.isLocked = true;
    });
    reportConfig.firdsFcaInstrumentSelectors.forEach((selector) => {
        if (selector.id === id && componentType === ComponentType.FirdsFcaInstrumentSelectors && isUsedByCurrentUser) {
            selector.isReadOnly = false;
            selector.isUsedByCurrentUser = true;
        }
        else {
            selector.isReadOnly = true;
        }
        selector.isLocked = true;
    });
    reportConfig.firdsEsmaInstrumentSelectors.forEach((selector) => {
        if (selector.id === id && componentType === ComponentType.FirdsEsmaInstrumentSelectors && isUsedByCurrentUser) {
            selector.isReadOnly = false;
            selector.isUsedByCurrentUser = true;
        }
        else {
            selector.isReadOnly = true;
        }
        selector.isLocked = true;
    });
    reportConfig.validations.forEach((validation) => {
        if (validation.id === id && componentType === ComponentType.AccuracyValidation && isUsedByCurrentUser) {
            validation.isReadOnly = false;
            validation.isUsedByCurrentUser = true;
        }
        else {
            validation.isReadOnly = true;
        }
        validation.isLocked = true;
    });
    reportConfig.fcaRegulatedEntitiesSelectors.forEach((selector) => {
        if (selector.id === id && componentType === ComponentType.FCARegulatedEntitiesSelector && isUsedByCurrentUser) {
            selector.isReadOnly = false;
            selector.isUsedByCurrentUser = true;
        }
        else {
            selector.isReadOnly = true;
        }
        selector.isLocked = true;
    });
}

export { allowEdit, getLockedMessage, isResetRequired, createSelectSearchOption, setValidationConfigComponentsProperties };