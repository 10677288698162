import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import InfoIcon from '../../Common/InfoIcon/InfoIcon';
import React from 'react';
import MatchingKey from '../../../types/report/MatchingKey';

const getColumns = (matchingKeyData: { name: string, type: string, info: string }): ColumnDefinition<MatchingKey>[] => {

    let columns: ColumnDefinition<MatchingKey>[] = [
        {
            header: 'Info',
            getContent: () => <InfoIcon info={ matchingKeyData.info } />,
            width: '110px'
        },
        {
            header: 'Name',
            getContent: () => matchingKeyData.name,
            width: '200px'
        },
        {
            header: 'Data Type',
            getContent: () => matchingKeyData.type
        }
    ];

    return columns;
};

export { getColumns };