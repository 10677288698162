import CollectionBundle from './Types/CollectionBundle';
import DataSourceAnalysisResult from './Types/DataSourceAnalysisResult';
import dataSourceKind from '../../../infrastructure/constants/dataSourceKind';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import IngestionConfigGridItem from '../../../types/ingestion/IngestionConfigGridItem';
import ReportConfigBundle from '../../../types/report/import/ReportConfigBundle';
import ReportFieldDefinition from '../../../types/report/ReportFieldDefinition';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

const handleDataSource = (current: IngestionConfig, collections: CollectionBundle, result: DataSourceAnalysisResult): void => {

    if (current.dataSourceKind === dataSourceKind.globalRef ||
        current.dataSourceKind === dataSourceKind.accuracy) {

        let id = collections.globalRef[current.name];

        if (typeHelper.isNumber(id)) {

            result.maps.dataSource[current.id as number] = id;
        }
        else {
            result.missingGlobalRefDataSources.push(current.name);
        }
    }
    else {

        let enabled = collections.enabled.filter(x => x.name === current.name).sort((a, b) => { return a.id < b.id ? 1 : b.id < a.id ? -1 : 0 })[0] as IngestionConfigGridItem;
        let disabled = collections.disabled.filter(x => x.name === current.name).sort((a, b) => { return a.id < b.id ? 1 : b.id < a.id ? -1 : 0 })[0] as IngestionConfigGridItem;

        if (typeHelper.isObject(enabled)) {

            result.maps.dataSource[current.id as number] = enabled.id;
        }
        else if (typeHelper.isObject(disabled)) {

            result.disabledClientDataSources.push(current.name);
        }
        else {
            result.missingClientDataSources.push(current.name);
        }
    }
};

const handleReportField = (name: string, bundle: ReportConfigBundle, collections: CollectionBundle, result: DataSourceAnalysisResult): void => {

    let definition = collections.reportFields.find(x => x.name === name) as ReportFieldDefinition;

    if (typeHelper.isObject(definition)) {

        let id = bundle.reportFields[name];

        result.maps.reportField[id] = definition.id;
    }
    else {
        result.missingReportFields.push(name);
    }
};

const analyse = (bundle: ReportConfigBundle, collections: CollectionBundle): DataSourceAnalysisResult => {

    let result: DataSourceAnalysisResult = {
        missingGlobalRefDataSources: [],
        disabledClientDataSources: [],
        missingClientDataSources: [],
        missingReportFields: [],
        maps: {
            dataSource: {},
            dataSourceField: {},
            reportField: {}
        },
        proceed: false
    };

    bundle.dataSources.forEach(x => handleDataSource(x, collections, result));

    Object.keys(bundle.reportFields).forEach(name => handleReportField(name, bundle, collections, result));

    return result;
};

export { analyse };
