import operandType from './operandType';

const firdsResultSetTypes = [
    operandType.firdsResultSetEsma,
    operandType.firdsResultSetFca
];

const specialOperandTypes = [
    operandType.group,
    operandType.lookupResultSet,
    operandType.leiResultSetLevel1,
    operandType.exchangeRatesResultSet,
    operandType.annaDsbUpiEnrichmentResultSet
].concat(firdsResultSetTypes);


export { specialOperandTypes, firdsResultSetTypes };
