import CustomDataSourceFields from '../../../../../types/report/CustomDataSourceFields';
import fieldToFunction from './overrideOperandHelper.Case1';
import functionToFunction from './overrideOperandHelper.Case2';
import IngestionConfig from '../../../../../types/ingestion/IngestionConfig';
import Operand from '../../../../../types/functions/Operand/Operand';
import OverrideOperandCase from './OverrideOperandCase';
import ReportFieldDefinition from '../../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../../types/report/ReportVariable';

const cases: OverrideOperandCase[] = [
    fieldToFunction,
    functionToFunction
];

const override = (previous: Operand, current: Operand, dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, variables: ReportVariable[]): void => {

    for (let i = 0; i < cases.length; i++) {

        if (cases[i].qualifies(previous, current, dataSources, reportFields, customDataSourceFields, variables)) {
            cases[i].override(previous, current, dataSources, reportFields, customDataSourceFields, variables);
            return;
        }
    }

    Object.assign(previous, current);
};

const overrideOperandHelper = { override };

export default overrideOperandHelper;
