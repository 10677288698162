import { getColumns } from './LookupBuilder.Grid';
import BespokeConditionBuilder from '../../Functions/Bespoke/BespokeConditionBuilder';
import ConditionBuilder from '../../Functions/Condition/ConditionBuilder';
import conditionDisplayHelper from '../../../infrastructure/helpers/functions/condition/conditionDisplayHelper';
import conditionHelper from '../../../infrastructure/helpers/functions/condition/conditionHelper';
import enpointLookupAdapter from '../../../infrastructure/helpers/functions/lookup/enpointLookupAdapter';
import ErrorList from '../../Common/ErrorList/ErrorList';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import locationType from '../../../types/functions/Location/LocationType';
import Lookup from '../../../types/functions/Lookup';
import React from 'react';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import reportLogicReferenceHelper from '../../../infrastructure/helpers/functions/report/reportLogicReferenceHelper';
import SelectOption from '../../../infrastructure/types/SelectOption';
import StatementBuilderProps from '../../Functions/Statement/StatementBuilderProps';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import reorderHelper from '../../../infrastructure/helpers/common/reorderHelper';
import Operand from '../../../types/functions/Operand/Operand';
import { lookupTypeOtions, prepareOptionsFromLookups } from './LookupBuilder.Helpers';
import InsertAtPositionModal from '../../Common/Modals/InsertAtPositionModal/InsertAtPositionModal';
import Condition from '../../../types/functions/Condition/Condition';
import IdNameAndNumber from '../../../types/report/IdNameAndNumber';
import reportComponentBuildersHelper from '../Common/ReportComponentsHelper';
import ReportConfigComponentLock from '../../../types/report/ReportConfigComponentLock';
import DragAndDropError from '../../../types/report/DragAndDropError';
import ComponentType from '../../../types/report/ComponentType';
import ActionType from '../../../types/report/ActionType';
import { reportType } from '../../../infrastructure/constants/reportType';
import operandHelper from '../../../infrastructure/helpers/functions/operand/operandHelper';
import { onReferenceSelected } from '../../../infrastructure/helpers/report/reference/helper';

class LookupBuilder extends React.Component<StatementBuilderProps<Lookup>, { showPopup: boolean }> {
    constructor(props: StatementBuilderProps<Lookup>) {
        super(props);
        this.state = {
            showPopup: false
        }
        this.onDataSource1Change = this.onDataSource1Change.bind(this);
        this.onDataSource2Change = this.onDataSource2Change.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onLookupTypeChange = this.onLookupTypeChange.bind(this);
        this.onBespokeToggleClick = this.onBespokeToggleClick.bind(this);
        this.getConditionContent = this.getConditionContent.bind(this);
        this.getLookupReferences = this.getLookupReferences.bind(this);
        this.reorder = this.reorder.bind(this);
        this.onAddLookupClick = this.onAddLookupClick.bind(this);
        this.onLookupConfiguredInPopup = this.onLookupConfiguredInPopup.bind(this);
        this.onRemoveLookupClick = this.onRemoveLookupClick.bind(this);
        this.tryLock = this.tryLock.bind(this);
        this.validateInsertBeforeAfter = this.validateInsertBeforeAfter.bind(this);
    }

    componentDidMount() {
        this.setInternalIds();
    }

    setInternalIds() {
        let index = 1;
        this.props.reportConfig.lookups.forEach((lookup) => {
            lookup._internalId = index;
            index += 1;
        })
    }

    async reorder(dragNumber: number, dropNumber: number): Promise<void> {
        let dragIndex = this.props.reportConfig.lookups.findIndex(x => x.number === dragNumber);
        let dropIndex = this.props.reportConfig.lookups.findIndex(x => x.number === dropNumber);
        let draggedLookup = this.props.reportConfig.lookups.find(v => v.number === dragNumber) as Lookup;
        let dropLookup = this.props.reportConfig.lookups.find(v => v.number === dropNumber) as Lookup;


        const isLockedDragged = await this.tryLock(undefined, draggedLookup);
        let isLockedDropped = false;
        if (!isLockedDragged) {
            isLockedDropped = await this.tryLock(undefined, dropLookup);
        }

        if (!isLockedDragged && !isLockedDropped) {
            this.clearAllErrors();

            let result: DragAndDropError = reportComponentBuildersHelper.validateDragAndDrop<Lookup>(dragIndex,
                dropIndex,
                draggedLookup as IdNameAndNumber,
                dropLookup as IdNameAndNumber,
                this.props.reportConfig.lookups,
                this.getLookupsReferences
            );

            if (result.error) {
                const lookupReferenced = this.props.statements.find(s => s.number === result.number) || {} as Lookup;
                result.error = `${result.error} The referenced lookup is "${lookupReferenced.name}".`;
                const number = result.isDrag ? draggedLookup.number : dropLookup.number;
                const statement = this.props.statements.find(s => s.number === number) || {} as Lookup;
                statement.errors = [result.error];
                this.props.onStatementChange(statement);
                return;
            }
            else {

                reorderHelper.reorderWithNumbersChange(this.props.reportConfig.lookups, dragIndex, dropIndex);

                reorderHelper.changeReferences(this.props.reportConfig,
                    this.props.reportConfig.lookups, (operand: Operand) => {
                        if (operand.lookupNumber && operand.lookupNumber === draggedLookup.number) {
                            operand.lookupNumber = dropLookup.number;
                        }
                        else if (operand.lookupNumber && operand.lookupNumber === dropLookup.number) {
                            operand.lookupNumber = draggedLookup.number;
                        }
                    }
                );

                this.props.onDragAndDrop && this.props.onDragAndDrop(this.props.reportConfig.lookups);
            }
        }
    }

    createLookupIdAndNumber(): Map<number, number> {

        const lookupsIdAndNumber = new Map<number, number>();
        this.props.reportConfig.lookups.forEach((lookup) => {
            const id = lookup._internalId;
            lookupsIdAndNumber.set(id, lookup.number);
        });

        return lookupsIdAndNumber;
    }

    getLookupsReferences(startIndex: number, endIndex: number, collection: Lookup[]): Set<number> {
        let referencedLookupsNumbers = new Set<number>();
        for (let i = startIndex; i < endIndex; i++) {
            if (collection[i].condition) {
                operandHelper.proceedWhitCondition(collection[i].condition as Condition, (operand: Operand) => {
                    if (operand.lookupNumber) {
                        referencedLookupsNumbers.add(operand.lookupNumber);
                    }
                });
            }
            referencedLookupsNumbers.add(collection[i].number);
        }
        return referencedLookupsNumbers;
    }

    onDataSource1Change(value: string, lookupNumber: number): void {
        let changes = { number: lookupNumber } as Lookup;
        let error = this.props.validateRemoveStatement(lookupNumber);
        if (error) {
            changes.errors = [error];
        }
        else {
            changes.dataSource1Id = enpointLookupAdapter.getDataSourceId(value);
            changes.dataSource2Id = null;
            changes.condition = conditionHelper.getEmpty();
            changes.isEndpoint = enpointLookupAdapter.isEndpoint(value)
            changes.isBespoke = false;
            changes.bespokeCondition = '';
        }
        this.props.onStatementChange(changes);
    }

    onDataSource2Change(dataSourceId: string, lookupNumber: number): void {
        let changes = { number: lookupNumber } as Lookup;
        let error = this.props.validateRemoveStatement(lookupNumber);
        if (error) {
            changes.errors = [error];
        }
        else {
            changes.errors = [];
            
            changes.dataSource2Id = parseInt(dataSourceId);
            let currentLookup = this.props.reportConfig.lookups.find(l => l.number === lookupNumber);
            let condition = Object.assign({}, currentLookup?.condition);
            condition.operand2 = {} as Operand;
            changes.condition = condition;
        }
        this.props.onStatementChange(changes);
    }

    async onLookupConfiguredInPopup(name: string, type: string, isInsertAfter: boolean, selectedLookupNumber: string): Promise<void> {
        this.setState({ showPopup: false });
        const insertionAfterLookupNumber: number = parseInt(selectedLookupNumber);

        this.props.onAddStatementClick(name, (lookup, lookups) => {
            lookups = reorderHelper.rearangeElementsWhenAddNewOne(lookups, lookup, isInsertAfter, insertionAfterLookupNumber) as Lookup[];
            if (!name) {
                let number = lookups.map(v => v.number).sort((x, y) => { return x > y ? 1 : x < y ? -1 : 0; }).pop() || 0;
                lookup.name = `lookup-${number}`;
                lookup._internalId = lookup.number;
            }
            else {
                lookup.name = name
            }
            lookup.isMultiResult = type === 'true';
            reorderHelper.changeReferences(this.props.reportConfig,
                lookups, (operand: Operand, oldAndNewNumbers: Map<number, number>, componentType: ComponentType | undefined) => {
                    if (componentType !== ComponentType.AccuracyValidation) {
                        if (operand.lookupNumber && oldAndNewNumbers.has(operand.lookupNumber)) {
                            operand.lookupNumber = oldAndNewNumbers.get(operand.lookupNumber) as number;
                        }
                    }
                }
            );
        });
    }

    clearAllErrors(): void {
        this.props.reportConfig.lookups.forEach((lookup) => {
            lookup.errors = [];
        });
    }

    async onRemoveLookupClick(number: number, setup?: (x: Lookup[]) => void, event?: React.MouseEvent<HTMLElement>): Promise<void> {

        const isLocked = await this.tryLock(number);

        if (!isLocked) {

            const validationError = await reportComponentBuildersHelper.validateAction(
                number,
                false,
                this.props.reportConfig.lookups as IdNameAndNumber[],
                ComponentType.Lookup,
                this.props.reportConfig.id || 0,
                ActionType.Remove);
            if (!validationError) {

                this.props.onRemoveStatementClick(number, (lookups: Lookup[]) => {
                    lookups = reorderHelper.rearangeElementsWhenRemoveOne(lookups) as Lookup[];

                    reorderHelper.changeReferences(this.props.reportConfig,
                        lookups, (operand: Operand, oldAndNewNumbers: Map<number, number>, componentType: ComponentType | undefined) => {
                            if (componentType !== ComponentType.AccuracyValidation) {
                                if (operand.lookupNumber && oldAndNewNumbers.has(operand.lookupNumber)) {
                                    operand.lookupNumber = oldAndNewNumbers.get(operand.lookupNumber) as number;
                                }
                            }
                        }
                    );
                });
            }
            else {
                this.props.onRemoveStatementClick(number, undefined, undefined, validationError);
            }
        }
    }

    async onNameChange(name: string, number: number): Promise<void> {
        const isLocked = await this.tryLock(number);
        if (!isLocked) {
            this.props.onStatementChange({ name: name, number: number } as Lookup);
        }
    }

    onLookupTypeChange(isMultiResult: boolean, number: number): void {
        let changes = { number: number } as Lookup;
        let error = this.props.validateRemoveStatement(number);
        if (error) {
            changes.errors = [error];
        }
        else {
            changes.isMultiResult = isMultiResult;
        }
        this.props.onStatementChange(changes);
    }

    async onBespokeToggleClick(isBespoke: boolean, lookup: Lookup, event?: React.MouseEvent<HTMLElement>): Promise<void> {

        const isLocked = await this.tryLock(undefined, lookup);
        if (!isLocked) {
            let changes = { number: lookup.number, isBespoke: isBespoke } as Lookup;
            if (!typeHelper.isNumber(lookup.dataSource2Id)) {
                changes.dataSource2Id = (this.props.collections.dataSources.find(x => x.id !== lookup.dataSource1Id) as IngestionConfig).id;
            }
            if (isBespoke) {
                changes.bespokeCondition = conditionDisplayHelper.buildConditions(lookup.condition, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, [], this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields);
            }
            this.props.onStatementChange(changes);
        }
    }

    getLookupReferences(number: number): SelectOption[] {
        return reportLogicReferenceHelper.getLookupReferences(number, this.props.reportConfig, this.props.collections);
    }

    onAddLookupClick(): void {
        if (this.props.reportConfig.lookups.length > 0) {
            this.setState({ showPopup: true });
        }
        else {
            const name = `lookup-${this.props.reportConfig.lookups.length + 1}`;
            this.props.onAddStatementClick(name, () => { });
        }
    }

    async tryLock(number?: number, lookup?: Lookup): Promise<boolean> {

        if (this.props.reportConfig.type === reportType.accuracy && this.props.onComponentContainerClick) {

            let currentLookup = lookup || this.props.reportConfig.lookups.find(v => v.number === number);
            if (!currentLookup?.isUsedByCurrentUser) {
                return await this.props.onComponentContainerClick({ componentId: currentLookup?.id || 0, componentType: ComponentType.Lookup, number: currentLookup?.number || 0 });
            }
        }
        return false;
    }

    async onReorderConditions(condition: Condition, lookup: Lookup) {

        const isLocked = await this.tryLock(lookup.number);
        if (!isLocked) {
            lookup.condition = condition;
            this.props.onStatementChange(lookup);
        }
    }

    async validateInsertBeforeAfter(number: number, isInsertAfter: boolean) {
        return await reportComponentBuildersHelper.validateAction(
            number,
            isInsertAfter,
            this.props.reportConfig.lookups as IdNameAndNumber[],
            ComponentType.Lookup,
            this.props.reportConfig.id || 0,
            ActionType.Add);
    }

    getConditionContent(lookup: Lookup): JSX.Element {
        if (lookup.isBespoke) {
            return (
                <>
                    <BespokeConditionBuilder
                        keyword={this.props.details.keyword}
                        statement={lookup}
                        onChange={this.props.onStatementChange}
                        isReadOnly={this.props.isReadOnly}
                    />
                </>
            );
        }
        const reportConfigComponentLock: ReportConfigComponentLock = { componentId: lookup.id || 0, componentType: ComponentType.Lookup, number: lookup.number };

        return (
            <>
                <ConditionBuilder
                    isReadOnly={lookup.isReadOnly || this.props.isReadOnly}
                    allowCopy={false}
                    keyword='IF'
                    functions={this.props.reportConfig.userDefinedFunctions}
                    lookups={this.props.reportConfig.lookups}
                    dictionaries={this.props.reportConfig.dictionaries}
                    variables={[]}
                    dataSources={this.props.collections.dataSources}
                    reportFields={this.props.collections.reportFields}
                    customDataSourceFields={this.props.collections.customDataSourceFields}
                    location={{ statement: locationType.statement.lookup, statementNumber: lookup.number } as ReportLogicLocation}
                    condition={lookup.condition}
                    onLogicalOperatorChange={(location, operator, event) => this.props.onLogicalOperatorChange(location, operator, event, reportConfigComponentLock)}
                    onParenthesesChange={(location, count, type, event) => this.props.onParenthesesChange(location, count, type, event, reportConfigComponentLock)}
                    onAddConditionClick={(location, operator, event) => this.props.onAddConditionClick(location, operator, event, reportConfigComponentLock)}
                    onRemoveConditionClick={(location, event) => this.props.onRemoveConditionClick(location, event, reportConfigComponentLock)}
                    onComparisonTypeChange={(location, value, event) => this.props.onComparisonTypeChange(location, value, event, reportConfigComponentLock)}
                    onOperandClick={(location, event) => this.props.onOperandClick(location, event, reportConfigComponentLock)}
                    onCopyClick={(location, event) => this.props.onCopyClick(location, event, reportConfigComponentLock)}
                    onPasteClick={(location, event) => this.props.onPasteClick(location, event, reportConfigComponentLock)}
                    onClearClick={(event) => this.tryLock(undefined, lookup)}
                    allowPaste={() => false}
                    onReorderConditions={(condition) => { this.onReorderConditions(condition, lookup); }} />
                <ErrorList errors={lookup.errors} className="mt-2" />
            </>
        );
    }

    render(): JSX.Element {
        const dropdownOptions = prepareOptionsFromLookups(this.props.reportConfig.lookups, this.props.collections.dataSourceOptions)
        return (
            <div>
                <ReportLogicList
                    isWaiting={this.props.isWaiting}
                    items={this.props.statements}
                    columns={getColumns(
                        this.tryLock,
                        this.onDataSource1Change,
                        this.onDataSource2Change,
                        this.onNameChange,
                        this.onLookupTypeChange,
                        this.onBespokeToggleClick,
                        this.onRemoveLookupClick,
                        this.getLookupReferences,
                        this.reorder,
                        this.props.collections,
                        this.props.reportConfig.type,
                        this.props.isReadOnly,
                        onReferenceSelected)}
                    getContent={this.getConditionContent}
                    getKey={l => l.number}
                    createHtmlId={l => reportConfigNavigation.buildLookupId(l.number)}
                    onClick={(e: React.MouseEvent<HTMLElement>, item: Lookup) => {
                        if (item.isLocked && item.isReadOnly) {
                            this.tryLock(item.number);
                        }
                    }} />
                {
                    this.state.showPopup ?
                        <InsertAtPositionModal
                            title='New Lookup'
                            onSaveClick={this.onLookupConfiguredInPopup}
                            name='Lookup'
                            dropdownOptions={dropdownOptions}
                            dropdownForTypeName='Lookup Type'
                            onClose={() => { this.setState({ showPopup: false }); }}
                            dataTypeOptions={lookupTypeOtions}
                            defaultDropdownOption={dropdownOptions[0]}
                            defaultDropdownOptionAlwaysLastOne={true}
                            validateFunction={this.validateInsertBeforeAfter}
                        />
                        : null
                }
            </div>
        );
    }
}
export default LookupBuilder;