import './App.scss';
import './Grid.scss';
import './Layout.scss';
import './SelectSearch.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { Router, Switch, Route } from 'react-router-dom';
import { sessionExpireCheck, setupKeyDown } from './App.Helpers';
import { ToastContainer } from 'react-toastify';
import appRoutes from '../infrastructure/constants/appRoutes';
import Forbidden from './Authorization/Forbidden';
import GlobalRefDataSourcePreviewPage from './GlobalRefDataSource/Pages/GlobalRefDataSourcePreviewPage';
import history from './../infrastructure/helpers/react/history';
import Home from './Home/Home';
import IngestionChangeLogPage from './Ingestion/Pages/IngestionChangeLogPage';
import IngestionDetailsPage from './Ingestion/Pages/IngestionDetailsPage';
import IngestionEditPage from './Ingestion/Pages/IngestionEditPage';
import IngestionFieldDefinitionPage from './Ingestion/Pages/IngestionFieldDefinitionPage';
import IngestionHomePage from './Ingestion/Pages/IngestionHomePage';
import IngestionSignoffPage from './Ingestion/Pages/IngestionSignoffPage';
import IngestionUploadPage from './Ingestion/Pages/IngestionUploadPage';
import Layout from './Layout';
import LoginComplete from './Authorization/LoginComplete';
import MasterConfigurationHomePage from './MasterConfiguration/Pages/MasterConfigurationHomePage';
import ProtectedRoute from './Authorization/ProtectedRoute';
import React from 'react';
import ReportActionItemEditPage from './Report/Pages/ReportActionItemEditPage';
import ReportActionLogPage from './Report/Pages/ReportActionLogPage';
import ReportChangeLogPage from './Report/Pages/ReportChangeLogPage';
import ReportDataSourcePage from './Report/Pages/ReportDataSourcePage';
import ReportEditPage from './Report/Pages/ReportEditPage';
import ReportHomePage from './Report/Pages/ReportHomePage';
import Unauthorized from './Authorization/Unauthorized';
import userRole from '../infrastructure/constants/identity/userRole';
import ValidationHomePage from './Validation/Pages/ValidationHomePage';
import writeUserRoles from '../infrastructure/constants/identity/writeUserRoles';
import VersionManagement from './MasterConfiguration/VersionManagement/VersionManagement';

const App = (): React.ReactElement => {

    React.useEffect(setupKeyDown, []);

    React.useEffect(sessionExpireCheck,[]);

    return (
        <Router history={history}>
            <Switch>
                <Route path={appRoutes.unauthorized} component={Unauthorized} />
                <Route path={appRoutes.login} component={LoginComplete} />
                <Route>
                    <Layout>
                        <Switch>
                            <ProtectedRoute path={appRoutes.ingestion.upload} component={IngestionUploadPage} roles={writeUserRoles} />
                            <ProtectedRoute path={appRoutes.ingestion.fieldDefinition} component={IngestionFieldDefinitionPage} roles={writeUserRoles} />
                            <ProtectedRoute path={appRoutes.ingestion.details} component={IngestionDetailsPage} roles={writeUserRoles} />
                            <ProtectedRoute path={appRoutes.ingestion.signoff} component={IngestionSignoffPage} roles={writeUserRoles} />
                            <ProtectedRoute path={appRoutes.ingestion.edit} component={IngestionEditPage} roles={[]} />
                            <ProtectedRoute path={appRoutes.ingestion.changeLog} component={IngestionChangeLogPage} roles={[]} />
                            <ProtectedRoute path={appRoutes.ingestion.home} component={IngestionHomePage} roles={[]} />

                            <ProtectedRoute path={appRoutes.reporting.dataSources} component={ReportDataSourcePage} roles={writeUserRoles} />
                            <ProtectedRoute path={appRoutes.reporting.edit} component={ReportEditPage} roles={[]} />
                            <ProtectedRoute path={appRoutes.reporting.changeLog} component={ReportChangeLogPage} roles={[]} />
                            <ProtectedRoute path={appRoutes.reporting.actionLog} component={ReportActionLogPage} roles={[]} />
                            <ProtectedRoute path={appRoutes.reporting.actionItem} component={ReportActionItemEditPage} roles={[]} />
                            <ProtectedRoute path={appRoutes.reporting.home} component={ReportHomePage} roles={[]} />

                            <ProtectedRoute path={appRoutes.masterConfiguration.versions} component={VersionManagement} roles={[userRole.admin]} />
                            <ProtectedRoute path={appRoutes.masterConfiguration.home} component={MasterConfigurationHomePage} roles={[userRole.admin]} />

                            <ProtectedRoute path={appRoutes.validation.edit} component={ReportEditPage} roles={[userRole.admin]} />
                            <ProtectedRoute path={appRoutes.validation.home} component={ValidationHomePage} roles={[userRole.admin]} />
                            <ProtectedRoute path={appRoutes.globalRefDataSource.preview} component={GlobalRefDataSourcePreviewPage} roles={[]} />

                            <ProtectedRoute path={appRoutes.forbidden} component={Forbidden} roles={[]} />
                            <ProtectedRoute path={appRoutes.home} component={Home} roles={[]} />
                        </Switch>
                    </Layout>
                </Route>
            </Switch>

            <ToastContainer />

            <div id="object-url-container" style={{ display: 'none' }}></div>

        </Router>
    );
}

export default App;
