import DeclarationHelper from './DeclarationHelper';
import NumberDeclaration from './NumberDeclaration';
import ReportConfig from '../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import ComponentType from '../../../types/report/ComponentType';

abstract class NumberDeclarationHelper<T extends NumberDeclaration> implements DeclarationHelper<NumberDeclaration> {

    add(declarations: T[], declaration: T): void {

        let lastNumber = declarations.length > 0 ? declarations[declarations.length - 1].number : 0;

        declaration.number = lastNumber + 1;

        declarations.push(declaration);
    }

    remove(declarations: T[], location: ReportLogicLocation): T[] {

        return declarations.filter(x => x.number !== location.statementNumber);
    }

    getDeclaration(declarations: T[], location: ReportLogicLocation): T {

        return declarations.find(x => x.number === location.statementNumber) as T;
    }

    getDataSourceId(config: ReportConfig, location: ReportLogicLocation, componentType:ComponentType): number | null {

        let declarations = this.getDeclarations(config, location, componentType);

        let declaration = this.getDeclaration(declarations, location);

        return declaration.dataSourceId;
    }

    abstract getDeclarations(config: ReportConfig, location: ReportLogicLocation, componentType?: ComponentType): T[];

    abstract getEmpty(rawDataSourceId: number | null, name: string | null): T;
}

export default NumberDeclarationHelper;