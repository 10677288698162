import aggregationFunctions from '../../../constants/functions/definition/aggregationFunctions';
import Condition from '../../../../types/functions/Condition/Condition';
import conditionHelper from '../condition/conditionHelper';
import copyObject from '../../common/copyObject';
import customDataSourceType from '../../../constants/functions/customDataSourceType';
import locationType from '../../../../types/functions/Location/LocationType';
import Lookup from '../../../../types/functions/Lookup';
import lookupHelper from '../lookup/lookupHelper';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../operand/operandHelper';
import OutsideContextDetails from '../../../types/Functions/OutsideContextDetails';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import reportLogicLocationHelper from '../report/reportLogicLocationHelper';
import typeHelper from '../../common/typeHelper';
import functionName from '../../../constants/functions/definition/functionName';

const findAggregationFunction = (condition: Condition | null, rootOperand: Operand | null, location: ReportLogicLocation): boolean => {

    let current: Operand;

    do {
        location.argumentIndexes.pop();

        current = typeHelper.isObject(condition) ?
            conditionHelper.getOperand(condition as Condition, location) :
            operandHelper.getOperand(rootOperand as Operand, location);

        if (aggregationFunctions.includes(current.function)) {

            return true;
        }
    }
    while (location.argumentIndexes.length > 0);

    return false;
};

const findArraySelectorFunction = (condition: Condition | null, rootOperand: Operand | null, location: ReportLogicLocation): boolean => {

    let current: Operand;

    const selectorFunctions = [
        functionName.all,
        functionName.any,
    ];

    do {
        location.argumentIndexes.pop();

        current = typeHelper.isObject(condition) ?
            conditionHelper.getOperand(condition as Condition, location) :
            operandHelper.getOperand(rootOperand as Operand, location);

        if (selectorFunctions.includes(current.function)) {

            return true;
        }
    }
    while (location.argumentIndexes.length > 0);

    return false;
};

const get = (condition: Condition | null, rootOperand: Operand | null, location: ReportLogicLocation, lookups: Lookup[], statement: string): OutsideContextDetails => {

    let result = getDefaults();

    if (statement === locationType.statement.aggregatedRecordField) {

        return result;
    }

    if (reportLogicLocationHelper.isRoot(location)) {

        return result;
    }

    let copy = copyObject(location);

    if (!findAggregationFunction(condition, rootOperand, copy)) {

        return result;
    }

    let isSpecialOperand =
        location.argumentIndexes.length === copy.argumentIndexes.length + 1 &&
        location.argumentIndexes[location.argumentIndexes.length - 1] === 0;

    if (isSpecialOperand) {

        return result;
    }

    copy.argumentIndexes.push(0);

    let operand = typeHelper.isObject(condition) ?
        conditionHelper.getOperand(condition as Condition, copy) :
        operandHelper.getOperand(rootOperand as Operand, copy);

    result.isOutsideContext = true;

    if (operandHelper.isValidLookupResultSet(operand)) {

        let lookup = lookupHelper.getByNumber(lookups, operand.lookupNumber as number);

        result.dataSourceId = lookup.dataSource2Id;
    }
    else if (operandHelper.isValidFirdsResultSet(operand)) {

        result.customDataSource = customDataSourceType.firds;
    }
    else if (operandHelper.isValidLeiLevel1ResultSet(operand)) {

        result.customDataSource = customDataSourceType.leiLevel1;
    }
    else if (operandHelper.isValidExchangeRatesResultSet(operand)) {

        result.customDataSource = customDataSourceType.exchangeRates;
    }

    return result;
};

const getDefaults = (): OutsideContextDetails => {
    return {
        isOutsideContext: false,
        dataSourceId: null,
        customDataSource: null
    };
};

const outsideContextHelper = {
    get,
    getDefaults
};

export default outsideContextHelper;
