import { requiresCsvArguments } from './IngestionConfigForm.Helpers';
import CsvArgumentsPicker from '../CsvArgumentsPicker/CsvArgumentsPicker';
import FieldDefinition from '../../../types/common/FieldDefinition';
import FieldDefinitions from '../FieldDefinitions/FieldDefinitions';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import IngestionDetails from '../Details/IngestionDetails';
import React from 'react';
import SystemFieldDefinitions from '../FieldDefinitions/SystemFieldDefinitions/SystemFieldDefinitions';

interface IngestionConfigProps {
    isWaiting: boolean;
    isReadOnly: boolean;
    config: IngestionConfig;
    onChange: (config: IngestionConfig) => void;
    ingestionConfigId: number;
}

class IngestionConfigForm extends React.Component<IngestionConfigProps> {

    constructor(props: IngestionConfigProps) {
        super(props);

        this.onFieldDefinitionChange = this.onFieldDefinitionChange.bind(this);
    }

    onFieldDefinitionChange(definitions: FieldDefinition[]): void {

        let changes = { fieldDefinitions: definitions } as IngestionConfig;

        this.props.onChange(changes);
    }

    render(): JSX.Element {
        const gridClassName = 'col-12 col-sm-11 col-md-8 col-lg-6 col-xl-5';

        return (
            <>
                {
                    requiresCsvArguments(this.props.config.fileType) &&
                    <>
                        <h3 className="mt-5 mb-5">File Format Details</h3>
                        <div className="row mt-4">
                            <div className={gridClassName}>
                                <CsvArgumentsPicker
                                    isReadOnly={true}
                                    arguments={this.props.config.csvSpecification}
                                    onChange={() => { }} />
                            </div>
                        </div>
                    </>
                }
                {
                    !this.props.isWaiting &&
                    <>
                        <h3 className="mt-5 mb-5">Ingestion Config Details</h3>

                        <div className="row mt-4">
                            <div className={gridClassName}>
                                <IngestionDetails
                                    isReadOnly={this.props.isReadOnly}
                                    config={this.props.config}
                                    onChange={this.props.onChange} />
                            </div>
                        </div>
                    </>
                }

                <h3 className="mt-5 mb-5">Field Definitions</h3>

                <FieldDefinitions
                    definitions={this.props.config.fieldDefinitions}
                    onChange={this.onFieldDefinitionChange}
                    isReadOnly={this.props.isReadOnly}
                    ingestionConfigId={this.props.ingestionConfigId }                />

                <h3 className="mt-5 mb-5">System Fields</h3>

                <SystemFieldDefinitions
                    definitions={this.props.config.fieldDefinitions}
                    onChange={this.onFieldDefinitionChange}
                    isReadOnly={this.props.isReadOnly} />
            </>
        );
    }
}

export default IngestionConfigForm;
