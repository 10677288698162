import CustomDataSourceFields from '../../../../../types/report/CustomDataSourceFields';
import functionHelper from '../../function/functionHelper';
import IngestionConfig from '../../../../../types/ingestion/IngestionConfig';
import Operand from '../../../../../types/functions/Operand/Operand';
import operandHelper from '../operandHelper';
import OverrideOperandCase from './OverrideOperandCase';
import ReportFieldDefinition from '../../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../../types/report/ReportVariable';

const qualifies = (previous: Operand, current: Operand, dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, variables: ReportVariable[]): boolean => {

    return (
        operandHelper.isValidFunction(previous) &&
        operandHelper.isValidFunction(current)
    );
};

const override = (previous: Operand, current: Operand, dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, variables: ReportVariable[]): void => {

    functionHelper.assignCompatibleArguments(previous, current);

    Object.assign(previous, current);
};

const functionToFunction: OverrideOperandCase = { qualifies: qualifies, override: override };

export default functionToFunction;
