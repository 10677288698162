import assignmentHelper from '../assignment/assignmentHelper';
import copyArray from '../../common/copyArray';
import DeclarationHelper from '../../../types/Functions/DeclarationHelper';
import ReportCase from '../../../../types/report/ReportCase';
import ReportCaseField from '../../../../types/report/ReportCaseField';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import typeHelper from '../../common/typeHelper';

class EnrichmentHelper implements DeclarationHelper<ReportCaseField> {

    add(declarations: ReportCaseField[], declaration: ReportCaseField): void {

        throw new Error('Not relevant');
    }

    remove(declarations: ReportCaseField[], location: ReportLogicLocation): ReportCaseField[] {

        throw new Error('Not relevant');
    }

    getDeclarations(config: ReportConfig, location: ReportLogicLocation): ReportCaseField[] {

        let $case = this.getCase(config, location);

        return $case.fields;
    }

    getDeclaration(declarations: ReportCaseField[], location: ReportLogicLocation): ReportCaseField {

        let declaration = declarations.find(x => x.fieldId === location.fieldId) as ReportCaseField;

        return declaration;
    }

    getDataSourceId(config: ReportConfig, location: ReportLogicLocation): number {

        let $case = this.getCase(config, location);

        return $case.dataSourceId;
    }

    getEmpty(rawDataSourceId: number | null): ReportCaseField {

        let assignment = assignmentHelper.getEmpty(true);
        assignment.number = 1;

        return {
            fieldId: 0,
            assignment: assignment,
            isBespoke: false,
            bespokeAssignment: '',
            errors: []
        };
    }

    handleCaseChanges(cases: ReportCase[], fieldIds: number[]): void {

        cases.forEach(c => {

            if (c.fields.length > 0) {
                return;
            }

            fieldIds.forEach(id => {

                let field = this.getEmpty(null);

                field.fieldId = id;

                c.fields.push(field);
            });
        });
    }

    handleEnrichmentChanges(cases: ReportCase[]): void {

        cases.filter(x => typeHelper.isNumber(x.syncedWithId)).forEach(x => {

            let syncedWith = cases.find(c => c.id === x.syncedWithId) as ReportCase;

            x.fields = copyArray(syncedWith.fields);
        });
    }

    private getCase(config: ReportConfig, location: ReportLogicLocation): ReportCase {

        return config.cases.find(x => x.number === location.statementNumber) as ReportCase;
    }
}

const enrichmentHelper = new EnrichmentHelper();

export default enrichmentHelper;
