import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';

const getById = (fields: ReportFieldDefinition[], fieldId: number): ReportFieldDefinition => {

    return fields.find(x => x.id === fieldId) as ReportFieldDefinition;
};

const reportFieldHelper = {
    getById
};

export default reportFieldHelper;
