import { mapToSelectOptions } from '../../../infrastructure/helpers/html/selectOptionHelper';
import { reportTypeDisplayMap } from '../../../infrastructure/constants/reportType';
import dataSourceKind from '../../../infrastructure/constants/dataSourceKind';
import IngestionConfigOption from '../../../types/ingestion/IngestionConfigOption';
import ReportConfig from '../../../types/report/ReportConfig';
import SelectOption from '../../../infrastructure/types/SelectOption';
import reportSubRegime from '../../../infrastructure/constants/reportSubRegime';
import reportRegime from '../../../infrastructure/constants/reportRegime';

const getConfigOptionValue = (config: IngestionConfigOption): string => (config.id as number).toString();
const getConfigOptionText = (config: IngestionConfigOption): string => {

    if (config.dataSourceKind === dataSourceKind.accuracy ||
        config.dataSourceKind === dataSourceKind.globalRef) {

        return config.name;
    }

    return `${config.name} (${config.dataSourceKind})`
};

const isDataSourceReferenced = (dataSourceId: number, reportConfig: ReportConfig): boolean => {

    return (
        reportConfig.isinSelectors.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.leiSelectors.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.exchangeRatesSelectors.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.annaDsbUpiEnrichmentSelectors.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.lseSelectors.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.firdsFcaInstrumentSelectors.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.firdsEsmaInstrumentSelectors.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.annaDsbUpiSelectors.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.fcaRegulatedEntitiesSelectors.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.lookups.some(x => x.dataSource1Id === dataSourceId || x.dataSource2Id === dataSourceId) ||
        reportConfig.variables.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.filters.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.cases.some(x => x.dataSourceId === dataSourceId) ||
        reportConfig.dictionaries.some(x => x.dataSourceId === dataSourceId)
    );
};

const runAllOptions: SelectOption[] = [
    { text: 'Latest Only', value: "0" },
    { text: 'Run All', value: "1" },
    { text: 'Oldest Only', value: "2" }
];

const reportTypeOptions: SelectOption[] = Object.keys(reportTypeDisplayMap).map(key => { return { value: key, text: reportTypeDisplayMap[key] } });

const getSubRegimeOptions = (regime: string) => mapToSelectOptions(reportSubRegime(regime) );

const regimeOptions = mapToSelectOptions(reportRegime);

export {
    getConfigOptionValue,
    getConfigOptionText,
    runAllOptions,
    reportTypeOptions,
    isDataSourceReferenced,
    regimeOptions,
    getSubRegimeOptions
};
