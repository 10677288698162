import { reportType } from '../../../infrastructure/constants/reportType';
import ReportConfig from '../../../types/report/ReportConfig';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import validateConfigName from '../../../infrastructure/helpers/common/validator/nameValidator';

const validateDataSources = (reportConfig: ReportConfig): string | null => {

    let error = validateConfigName(reportConfig.name, true);
    if (error) {
        return error;
    }

    if (stringHelper.isEmpty(reportConfig.regime)) {
        return 'Reporting regime is required';
    }

    if (reportConfig.type === reportType.accuracy) {

        return null;
    }

    if (!typeHelper.isNumber(reportConfig.rawDataSourceId)) {
        return 'Raw data source is required';

    }

    if (reportConfig.refDataSources.some(x => x.id === reportConfig.rawDataSourceId)) {
        return 'Raw data source must be different than ref data sources';
    }

    return null;
};

export default validateDataSources;
