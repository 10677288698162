import ReportConfig from "../../types/report/ReportConfig";

const keys = {
    type: 'TYPE',
    id: 'ID',
};
const set = (reportConfig: ReportConfig): void => {
    localStorage.setItem(keys.type, reportConfig.type);
    localStorage.setItem(keys.id, (reportConfig.id || 0).toString());
};

const get = (): ReportConfig => {

    return {
        type: localStorage.getItem(keys.type) as string,
        id: parseInt(localStorage.getItem(keys.id) || '0')
    } as ReportConfig;
};

const reportConfigStorage = {
    set,
    get
};

export default reportConfigStorage;
