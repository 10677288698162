import Dictionary from '../../../../types/report/Dictionary';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';

const createDictionaryOption = (dictionary: Dictionary): SelectOption => {
    return {
        value: dictionary.number.toString(),
        text: stringHelper.isNonEmpty(dictionary.name) ? dictionary.name : `Dictionary ${dictionary.number}`
    };
};

export { createDictionaryOption };