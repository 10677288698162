import { buildFieldOption } from './CustomDataSourceFieldBuilder.Helpers';
import BaseOperandProps from '../Props/BaseOperandProps';
import CustomDataSourceFieldDefinition from '../../../../types/report/CustomDataSourceFieldDefinition';
import Operand from '../../../../types/functions/Operand/Operand';
import React from 'react';
import SelectList from '../../../Common/SelectList/SelectList';

type Props =
    BaseOperandProps &
    {
        fields: CustomDataSourceFieldDefinition[],
        prefix: string
    };

class CustomDataSourceFieldBuilder extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(value: string): void {

        let changes = {} as Operand;

        changes.customDataSourceFieldId = parseInt(value);

        this.props.onOperandChange(changes)
    }

    render(): JSX.Element {

        return (
            <SelectList
                includeEmptyOption={true}
                options={this.props.fields.map(x => buildFieldOption(x, this.props.prefix))}
                value={this.props.operand.customDataSourceFieldId?.toString() || ''}
                onChange={this.onChange}
                className="form-control" />
        );
    }
}

export default CustomDataSourceFieldBuilder;
