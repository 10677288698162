import { reportType } from '../../../constants/reportType';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportConfigBundle from '../../../../types/report/import/ReportConfigBundle';
import typeHelper from '../../common/typeHelper';

const validate = (bundle: ReportConfigBundle, skipDataSourcesValidation?: boolean): void => {

    if (!typeHelper.isObject(bundle.config)) {

        bundle.error = 'Config is missing';
        return;
    }

    if (!typeHelper.isArray(bundle.dataSources)) {

        bundle.error = 'Data source collection is missing';
        return;
    }

    if (!typeHelper.isObject(bundle.reportFields)) {

        bundle.error = 'Report field map is missing';
        return;
    }

    if (!skipDataSourcesValidation) {
        let error1 = validateDataSources(bundle.dataSources);
        if (error1) {
            bundle.error = error1;
            return;
        }
    }

    let error2 = validateConfig(bundle.config);
    if (error2) {
        bundle.error = error2;
    }
};

const validateConfig = (config: ReportConfig): string | null => {

    if (!typeHelper.isString(config.name)) {

        return 'Config name is missing';
    }

    if (!typeHelper.isString(config.regime)) {

        return 'Config regime is missing';
    }

    if (!typeHelper.isString(config.type)) {

        return 'Config type is missing';
    }

    if (!typeHelper.isNumber(config.rawDataSourceId) && config.type !== reportType.accuracy) {

        return 'Raw data source id is missing';
    }

    if (!typeHelper.isArray(config.refDataSources)) {

        return 'Ref data source collection is missing';
    }

    if (!typeHelper.isObject(config.matchingKey)) {

        return 'Config matching key is missing';
    }

    if (!typeHelper.isObject(config.matchingKeyEndPoint)) {

        return 'Config matching key endpoint is missing';
    }

    if (!typeHelper.isArray(config.lookups)) {

        return 'Lookup collection is missing';
    }

    if (!typeHelper.isArray(config.variables)) {

        return 'Variable collection is missing';
    }

    if (!typeHelper.isArray(config.isinSelectors)) {

        return 'Isin selector collection is missing';
    }

    if (!typeHelper.isArray(config.leiSelectors)) {

        return 'Lei selector collection is missing';
    }

    if (!typeHelper.isArray(config.exchangeRatesSelectors)) {

        return 'Exchange Rates selector collection is missing';
    }

    if (!typeHelper.isArray(config.annaDsbUpiEnrichmentSelectors)) {

        return 'Annd Dsb Upi Enrichment selector collection is missing';
    }

    if (!typeHelper.isArray(config.lseSelectors)) {

        return 'LSE selector collection is missing';
    }

    if (!typeHelper.isArray(config.firdsFcaInstrumentSelectors)) {

        return 'Firds Fca Instrument selector collection is missing';
    }

    if (!typeHelper.isArray(config.firdsEsmaInstrumentSelectors)) {

        return 'Firds Esma Instrument selector collection is missing';
    }

    if (!typeHelper.isArray(config.fcaRegulatedEntitiesSelectors)) {

        return 'FCA Regulated Entities selector collection is missing';
    }

    if (!typeHelper.isArray(config.annaDsbUpiSelectors)) {

        return 'Annd Dsb Upi selector collection is missing';
    }

    if (!typeHelper.isArray(config.aggregations)) {

        return 'Aggregation collection is missing';
    }

    if (!typeHelper.isArray(config.filters)) {

        return 'Filter collection is missing';
    }

    if (!typeHelper.isArray(config.endpointFilters)) {

        return 'Endpoint filter collection is missing';
    }

    if (!typeHelper.isArray(config.cases)) {

        return 'Reporting case collection is missing';
    }

    if (!typeHelper.isArray(config.validations)) {

        return 'Accuracy validation collection is missing';
    }

    return null;
};

const validateDataSources = (dataSources: IngestionConfig[]): string | null => {

    if (dataSources.length === 0) {

        return 'At least one data source is required';
    }

    if (dataSources.some(x => !typeHelper.isObject(x))) {

        return 'Data Source collection must contain objects';
    }

    return null;
};

export { validate };
