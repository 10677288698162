import { getDefaultState, createFieldOption, createEmptyFieldOption } from './CopyAggregationFieldModal.Helpers';
import { Unsubscribe } from 'redux';
import { validate } from './CopyAggregationFieldModal.Validation';
import actions from '../../../../store/actions';
import Modal from '../../../Common/Modal/Modal';
import RadioButtonGroup from '../../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import SelectSearch, { SelectedOptionValue, SelectSearchOption } from 'react-select-search';
import store from '../../../../store/store';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import RadioButtonGroupMultiSelect from '../../../Common/RadioButtonGroupMultiSelect/RadioButtonGroupMultiSelect';
import CopyAggregationFieldModalState from './CopyAggregationFieldModalState';
import Aggregation from '../../../../types/report/Aggregation';
import { createAggregationOption } from '../AggregationSelector.Helpers';
import FieldDefinition from '../../../../types/common/FieldDefinition';

interface Props {
    aggregations: Aggregation[];
    fieldDefinitions: FieldDefinition[];
}

class CopyAggregationFieldModal extends React.Component<Props, CopyAggregationFieldModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: Props) {
        super(props);

        this.state = getDefaultState();

        this.onOpen = this.onOpen.bind(this);
        this.onSourceAggregationChange = this.onSourceAggregationChange.bind(this);
        this.onTargetAggregationChange = this.onTargetAggregationChange.bind(this);
        this.onSourceFieldChange = this.onSourceFieldChange.bind(this);
        this.onTargetFieldChange = this.onTargetFieldChange.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.close = this.close.bind(this);
        this.getAggregationsFieldsEmptyOptions = this.getAggregationsFieldsEmptyOptions.bind(this);
        this.getAggregationsFieldsOptions = this.getAggregationsFieldsOptions.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onOpen);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    onOpen(): void {
        let state = store.getState();

        if (state.action !== actions.copyAggregationFieldModal.change) {
            return;
        }

        this.setState(state.copyAggregationFieldModalChange);
    }

    onSourceAggregationChange(value: string): void {

        let changes = { sourceNumber: value } as CopyAggregationFieldModalState;


        this.setState(changes);
    }

    onTargetAggregationChange(value: string, isChecked: boolean): void {

        let aggregationNumbers = new Set(this.state.targetNumbers);
        if (isChecked && aggregationNumbers.has(value)) {
            aggregationNumbers.delete(value);
        }
        else {
            aggregationNumbers.add(value)
        }
        let changes = { targetNumbers: aggregationNumbers } as CopyAggregationFieldModalState;

        this.setState(changes);
    }

    onSourceFieldChange(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {

        let changes = { sourceFieldId: selectedValue.toString() } as CopyAggregationFieldModalState;

        this.setState(changes);
    }

    onTargetFieldChange(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {

        this.setState({ targetFieldId: selectedValue.toString() });

    }

    onCopyClick(): void {

        let error = validate(this.state, this.props.aggregations, []);

        if (error) {
            this.setState({ error: error });
            return;
        }

        store.dispatch({ type: actions.copyAggregationFieldModal.save, payload: this.state });

        this.close();
    }

    includeAggregation(aggregation: Aggregation): boolean {

        return !typeHelper.isNumber(aggregation.syncedWithId);
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    getAggregationsFieldsEmptyOptions(aggregationNumber?: number): SelectSearchOption[] {
        let options = this.props.aggregations?.find(a => a.number === aggregationNumber)?.fields.map((field) => createFieldOption(field, this.props.fieldDefinitions)) || [];

        options.splice(0, 0, createEmptyFieldOption());

        return options;
    }

    getAggregationsFieldsOptions(aggregationNumber?: number): SelectSearchOption[] {
        const aggregation = aggregationNumber ? this.props.aggregations.find(a => a.number === aggregationNumber) : this.props.aggregations[0];
        if (aggregation) {
            return aggregation.fields.map((item) => createFieldOption(item, this.props.fieldDefinitions));
        }

        const emptyOption = createEmptyFieldOption();

        return [emptyOption];
    }

    render(): JSX.Element {

        let containerClass = this.props.aggregations.length > 5 ? 'copy-modal-big' : 'copy-modal-medium';

        return (
            <div className={`${containerClass}`}>
                <Modal state={this.state} close={this.close} size="large">
                    <div className='display-flex flex-direction-column'>
                        <div className='padding-right-15px width-100-percents'>
                            <h5>From</h5>
                            {
                                !this.state.copyAllFields && this.state.sourceNumber &&
                                <SelectSearch
                                    options={this.getAggregationsFieldsOptions(parseInt(this.state.sourceNumber))}
                                    value={this.state.sourceFieldId as string}
                                    onChange={this.onSourceFieldChange}
                                    search={true}
                                    disabled={this.state.copyAllFields} />
                            }
                            <RadioButtonGroup
                                options={this.props.aggregations.filter(this.includeAggregation).map(createAggregationOption)}
                                value={this.state.sourceNumber}
                                name="source-aggregations"
                                onChange={this.onSourceAggregationChange} />
                        </div>
                        <div className='width-100-percents'>
                            <h5>To</h5>
                            {
                                !this.state.copyAllFields &&
                                <SelectSearch
                                    options={this.getAggregationsFieldsEmptyOptions(parseInt(this.state.sourceNumber))}
                                    value={this.state.targetFieldId as string}
                                    onChange={this.onTargetFieldChange}
                                    search={true}
                                    disabled={this.state.copyAllFields} />
                            }
                            <RadioButtonGroupMultiSelect
                                options={this.props.aggregations.filter(this.includeAggregation).map(createAggregationOption)}
                                values={this.state.targetNumbers}
                                name="target-aggregations"
                                onClick={this.onTargetAggregationChange} />
                        </div>
                    </div>
                    <div className="mt-3 mb-3">
                        <button className="btn cb-btn" onClick={this.onCopyClick}>Copy</button>
                    </div>

                </Modal>
            </div>
        );
    }
}

export default CopyAggregationFieldModal;