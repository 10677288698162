import typeHelper from '../../../infrastructure/helpers/common/typeHelper';

interface SortSelection {
    columnName: string;
    ascending: boolean;
}

const buildKey = (gridName: string) => `SORT_SELECTION:${gridName}`;

const get = (gridName: string): SortSelection => {

    let key = buildKey(gridName);

    let item = localStorage.getItem(key);

    return (typeHelper.isString(item) ? JSON.parse(item as string) : {}) as SortSelection;
};

const set = (gridName: string, selection: SortSelection): void => {

    let key = buildKey(gridName);

    let value = JSON.stringify(selection);

    localStorage.setItem(key, value);
};

const sortSelectionStorage = {
    get,
    set
};

export default sortSelectionStorage;