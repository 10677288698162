const SEPARATOR = '-';

const buildId = (prefix: string, number: number): string => {

    return [prefix, number].join(SEPARATOR);
};

const extractPrefix = (htmlId: string): string => {

    return htmlId.split(SEPARATOR)[0];
};

export { buildId, extractPrefix };
