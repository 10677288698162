import SelectOption from '../../../infrastructure/types/SelectOption';

const addOptions = (select: HTMLSelectElement, options: SelectOption[]): void => {

    options.forEach(x => select.add(new Option(x.text, x.value)));
};

const clearOptions = (select: HTMLSelectElement): void => {

    while (select.options.length > 1) {

        select.remove(select.options.length - 1);
    }
};

export { addOptions, clearOptions };
