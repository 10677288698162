import { comparisonType } from '../../../constants/functions/comparisonType';
import comparisonTypeDisplayHelper from '../condition/comparisonTypeDisplayHelper';
import Condition from '../../../../types/functions/Condition/Condition';
import conditionHelper from '../condition/conditionHelper';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import dataType from '../../../constants/dataType';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import OperandDetails from '../../../../types/functions/Operand/OperandDetails';
import operandDisplayHelper from '../operand/operandDisplayHelper';
import parenthesesValidator from './parenthesesValidator';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import typeHelper from '../../common/typeHelper';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const isEqualityComparisonType = (type: string): boolean => type === comparisonType.equal || type === comparisonType.notEqual;

const resolveOperatorText = (type: string): string => {

    if (isEqualityComparisonType(type)) {
        return 'equality';
    }

    return 'comparison';
};

const getTypeMismatchMessage = (type: string, operand1: OperandDetails, operand2: OperandDetails): string => {

    let parts = [
        'Type mismatch. Can not apply ',
        resolveOperatorText(type),
        ' operator to operand ',
        operand1.displayText,
        ' (',
        operand1.dataType + ')',
        ' and operand ',
        operand2.displayText,
        ' (',
        operand2.dataType + ')'
    ];

    return parts.join('');
};

const validateOne = (condition: Condition, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, reportFieldId: number | null): string | null => {

    let operand1Details = operandDisplayHelper.getOperandDetails(condition.operand1, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, reportFieldId);
    let operand2Details = operandDisplayHelper.getOperandDetails(condition.operand2, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, reportFieldId);

    if (!operand1Details.isSetUp || !operand2Details.isSetUp) {
        return 'Operand(s) not set up';
    }

    if (operand1Details.isIllegalReportFieldReference || operand2Details.isIllegalReportFieldReference) {
        return 'Report field reference is not allowed. Referenced field must be above referencing field in the list of report fields';
    }

    if (operand1Details.dataType !== operand2Details.dataType) {
        return getTypeMismatchMessage(condition.comparisonType, operand1Details, operand2Details);
    }

    if (operand1Details.dataType === dataType.boolean && !isEqualityComparisonType(condition.comparisonType)) {

        return `Comparison '${comparisonTypeDisplayHelper.resolve(condition.comparisonType)}' is not allowed for data type ${dataType.boolean}`;
    }

    return null;
};

const validate = (condition: Condition, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields, atLeastOneConditionRequired: boolean, reportFieldId: number | null): string[] => {

    let hasConditon = typeHelper.isObject(condition);

    if (!hasConditon && !atLeastOneConditionRequired) {
        return [];
    }

    if (!hasConditon) {
        return ['At least one condition is required'];
    }

    let items = conditionHelper.asCollection(condition);

    let error = parenthesesValidator.validate(items);
    if (error) {
        return [error];
    }

    return items.map(x => validateOne(x, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, reportFieldId) as string).filter(x => x);
};

const conditionValidator = { validate };

export default conditionValidator;
