import { toast, ToastOptions } from 'react-toastify';

const suffix = '\n\ncopy';

const buildMessage = (message: string): string => message + suffix;

const extractMessage = (text: string): string => text.substr(0, text.length - suffix.length);

const onClick = async (e: React.MouseEvent<Element, MouseEvent>): Promise<void> => {

    let message = extractMessage((e.target as HTMLElement).innerText);

    await navigator.clipboard.writeText(message);
};

const options: ToastOptions = {
    position: 'top-right',
    autoClose: false,
    closeOnClick: false,
    onClick: onClick,
    bodyStyle: { whiteSpace: 'pre-line', fontSize: '14px' },
    delay: 0
};

const displayErrorMessage = (message: string): void => {

    toast.error(buildMessage(message), options);
};

export { displayErrorMessage };
