const dataType = {
    string: 'String',
    integer: 'Integer',
    decimal: 'Decimal',
    boolean: 'Boolean',
    date: 'Date',
    time: 'Time',
    dateTime: 'DateTime',
    unixTime: 'UnixTime',
    arrayOfStrings: 'ArrayOfStrings',
    arrayOfIntegers: 'ArrayOfIntegers',
    arrayOfDecimals: 'ArrayOfDecimals',
    arrayOfBooleans: 'ArrayOfBooleans',
    arrayOfDates: 'ArrayOfDates',
    arrayOfTimes: 'ArrayOfTimes',
    arrayOfDateTimes: 'ArrayOfDateTimes',
    arrayOfUnixTimes: 'ArrayOfUnixTimes',
};

export default dataType;
