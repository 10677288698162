const locationType = {

    statement: {
        lookup: 'l',
        isinSelector: 'is',
        leiSelector: 'ls',
        exchangeRatesSelector: 'ers',
        annaDsbUpiEnrichmentSelector: 'annaes',
        annaDsbUpiEnrichmentSelectorKey: 'annaesKey',
        lseSelector: 'lse',
        firdsFcaInstrumentSelector:'firdsFcaIs',
        firdsFcaInstrumentSelectorKey: 'firdsFcaIsKey',
        firdsEsmaInstrumentSelector:'firdsEsmaIs',
        firdsEsmaInstrumentSelectorKey: 'firdsEsmaIsKey',
        fcaRegulatedEntitiesSelector: 'fcaRegEnt',
        annaDsbUpiSelector: 'annaus',
        variable: 'v',
        aggregation: 'a',
        aggregatedRecordField: 'arf',
        filter: 'f',
        endpointFilter: 'ef',
        underReportFilter: 'urf',
        overReportFilter: 'orf',
        case: 'c',
        reportField: 'rf',
        validation: 'val',
        matchingKey: 'mk',
        matchingKeyEndPoint: 'mke',
        dictionary: 'd',
        dictionaryField: 'df',
        userDefinedFunction: 'udf',
        userDefinedFunctionParameter: 'udfp',
        validationCore:'core',
        validationStandard: 'standard'
    },

    pieceOfCode: {
        condition: 'c',
        conditionOperand: 'co',
        assignmentValue: 'av',
        operandsList: 'ol'
    }
};

export default locationType;