import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';

const masterFileLocationRegex = /^[A-Za-z0-9_.-]+$/;

const validateMasterFileLocation = (config: IngestionConfig): string | null => {

    let location = config.xmlSectionName || config.jsonSectionName;

    if (stringHelper.isNonEmpty(location) && !masterFileLocationRegex.test(location)) {

        return 'Parent file section name can contain only letters, digits, hyphen, underscore or period';
    }

    return null;
};

export default validateMasterFileLocation;
