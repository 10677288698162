import dataType from '../dataType';

const systemVariables = {
    dataType: dataType.string,
    names: [
        'ID',
        'Counterparty',
        'Product'
    ]
};

export default systemVariables;
