import ColumnDefinition from '../../../../infrastructure/types/DataGrid/ColumnDefinition';
import StringReplacement from '../../../../infrastructure/types/StringReplacement';

const getColumns = (): ColumnDefinition<StringReplacement>[] => {

    return [
        {
            header: 'Before',
            getContent: x => x.before
        },
        {
            header: 'After',
            getContent: x => x.after
        }
    ];
};

export { getColumns };
