import { endpointFilterType } from '../../constants/report/endpointFilterType';
import EndpointFilter from '../../../types/report/EndpointFilter';
import StatementHelper from '../../types/Functions/StatementHelper';

class EndpointFilterHelper extends StatementHelper<EndpointFilter> {
    private _type: string;

    constructor(type: string) {
        super();

        this._type = type;
    }

    protected match(filter: EndpointFilter, number: number): boolean {

        return filter.number === number && filter.type === this._type;
    }

    getRelevant(filters: EndpointFilter[]): EndpointFilter[] {

        return filters.filter(x => x.type === this._type);
    }

    getDataSourceId(filter: EndpointFilter, dataSourceId: number | null): number | null {

        return dataSourceId;
    }

    getEmpty(rawDataSourceId: number): EndpointFilter {

        return {
            id: null,
            dataSourceId: this._type === endpointFilterType.underReport ? rawDataSourceId : null,
            number: 0,
            condition: null as any,
            name: '',
            businessDescription: '',
            type: this._type,
            isBespoke: false,
            bespokeCondition: '',
            errors: []
        };
    }
}

const endpointFilterHelper = new EndpointFilterHelper(endpointFilterType.regular);
const underReportFilterHelper = new EndpointFilterHelper(endpointFilterType.underReport);
const overReportFilterHelper = new EndpointFilterHelper(endpointFilterType.overReport);

export { endpointFilterHelper, underReportFilterHelper, overReportFilterHelper };
