import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import FormGroup from '../../../Common/FormGroup/FormGroup';
import Modal from '../../../Common/Modal/Modal';
import PromiseButton from '../../../Common/PromiseButton';
import React from 'react';
import CreateVersionModalState from './CreateVersionModalState';
import store from '../../../../store/store';

class CreateVersionModal extends React.Component<any, CreateVersionModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            isOpen: false,
            error: null,
            name: '',
            comment: '',
            onSaveClick: null as any
        };

        this.onStateChange = this.onStateChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onStateChange);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    onStateChange(): void {

        let state = store.getState();
        if (state.action !== actions.createVersionModal.open) {
            return;
        }

        this.setState(state.createVersionModalState);
    }

    onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {

        let changes = { name: e.target.value } as CreateVersionModalState;

        this.setState(changes);
    }

    onCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {

        let changes = { comment: e.target.value } as CreateVersionModalState;

        this.setState(changes);
    }

    onSaveClick(): Promise<void> {

        return this.state
            .onSaveClick(
                this.state.name,
                this.state.comment)
            .then(this.close);
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    render(): JSX.Element {
        const labelClass = 'col-3';
        const contentClass = 'col-9';

        return (
            <Modal state={this.state} close={this.close}>

                <FormGroup label="Name:" labelClassName={labelClass} contentClassName={contentClass}>
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.name}
                        onChange={this.onNameChange} />
                </FormGroup>

                <FormGroup label="Comment:" labelClassName={labelClass} contentClassName={contentClass}>
                    <textarea
                        className="form-control"
                        onChange={this.onCommentChange}
                        value={this.state.comment}>
                    </textarea>
                </FormGroup>

                <PromiseButton className="btn cb-btn" text="Save" enableOnErrorOnly={false} task={this.onSaveClick} />
            </Modal>
        );
    }
}

export default CreateVersionModal;
