import { analyse } from './ImportReportConfig.Helpers';
import { getCollections, getConfigDataSources, importDataSources, importReportConfig } from './ServerCalls';
import { validateUpdate } from './ImportReportConfig.Validation';
import CollectionBundle from './Types/CollectionBundle';
import DataSourceAnalysisResult from './Types/DataSourceAnalysisResult';
import dataSourceFieldMapHelper from '../../../infrastructure/helpers/report/import/dataSourceFieldMapHelper';
import dataSourceKind from '../../../infrastructure/constants/dataSourceKind';
import ErrorList from '../../Common/ErrorList/ErrorList';
import IdMaps from '../../../types/report/import/IdMaps';
import ImportReportConfigState from './ImportReportConfigState';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import PromiseButton from '../../Common/PromiseButton';
import React from 'react';
import ReportConfig from '../../../types/report/ReportConfig';
import reportConfigAdapter from '../../../infrastructure/helpers/report/import/reportConfigAdapter';
import ReportConfigBundle from '../../../types/report/import/ReportConfigBundle';
import reportConfigComponentIdMapper from '../../../infrastructure/helpers/report/import/reportConfigComponentIdMapper';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import ChangeLogModal from '../../Common/Modals/ChangeLogModal/ChangeLogModal';
import store from '../../../store/store';
import ChangeLogModalState from '../../Common/Modals/ChangeLogModal/ChangeLogModalState';
import { commonColumns, moduleColumn, moduleRefColumn } from '../../Common/Modals/ChangeLogModal/ChangeLogModal.Grid';
import actions from '../../../store/actions';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import ChangeDescription from '../../../infrastructure/types/DifferenceVisualizer/ChangeDescription';
import reportConfigBundleSerializer from '../../../infrastructure/helpers/report/import/reportConfigBundleSerializer';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';

interface Props {
    isValidationConfig: boolean;
    state: ImportReportConfigState;
    onChange: (changes: ImportReportConfigState) => void;
    onImportSuccess: () => void;
    skipDataSourcesValidation?: boolean;
}

class ImportReportConfig extends React.Component<Props, { toImport: ReportConfig | null, dataSourceAnalysisResult: DataSourceAnalysisResult, showWarningModal: boolean, message: string, bundle: ReportConfigBundle }> {

    constructor(props: Props) {
        super(props);

        this.onSourceChange = this.onSourceChange.bind(this);
        this.onImportClick = this.onImportClick.bind(this);
        this.execute = this.execute.bind(this);

        this.notifyUser = this.notifyUser.bind(this);
        this.importDataSources = this.importDataSources.bind(this);
        this.finalize = this.finalize.bind(this);
        this.showChanges = this.showChanges.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onImportConfigConfirmed = this.onImportConfigConfirmed.bind(this);
        this.state = { toImport: null, dataSourceAnalysisResult: {} as DataSourceAnalysisResult, showWarningModal: false, message: '', bundle: {} as ReportConfigBundle };
    }

    onSourceChange(e: React.ChangeEvent<HTMLInputElement>): void {

        this.props.onChange({ json: e.target.value } as ImportReportConfigState);
    }

    onImportClick(): void {

        let bundle = reportConfigBundleSerializer.deserialize(this.props.state.json, this.props.skipDataSourcesValidation);

        this.props.onChange({ errors: [bundle.error] } as ImportReportConfigState);

        if (!bundle.error) {

            this.execute(bundle);
        }
    }

    execute(bundle: ReportConfigBundle): void {

        getCollections(bundle.config.regime)
            .then(collections => this.notifyUser(bundle, collections));
    }

    async tryGetValidationConfig(regime: string): Promise<ReportConfig | null> {

        let config = null;

        if (this.props.isValidationConfig) {

            let route =`report/regime/${regime}`;

            var response = await httpClient.get<ReportConfig>(route);

            config = response.data;
        }

        return config;
    }

    async notifyUser(bundle: ReportConfigBundle, collections: CollectionBundle): Promise<DataSourceAnalysisResult> {

        let result = analyse(bundle, collections);

        if (result.missingGlobalRefDataSources.length > 0) {

            let message = `The following global ref data sources are missing:\n${result.missingGlobalRefDataSources.join('\n')}`;

            this.props.onChange({ errors: [message] } as ImportReportConfigState);

            return Promise.reject(message);
        }

        if (result.disabledClientDataSources.length > 0) {

            let message = `The following data sources are archived:\n${result.disabledClientDataSources.join('\n')}\n\nPlease unarchive them first`;

            this.props.onChange({ errors: [message] } as ImportReportConfigState);

            return Promise.reject(message);
        }

        if (result.missingReportFields.length > 0) {

            let message = `The following report fields are missing:\n${result.missingReportFields.join('\n')}`;

            this.props.onChange({ errors: [message] } as ImportReportConfigState);

            return Promise.reject(message);
        }

        let validationConfig = await this.tryGetValidationConfig(bundle.config.regime)

        let updateError = validateUpdate(bundle, result, validationConfig);

        if (updateError) {

            this.props.onChange({ errors: [updateError] } as ImportReportConfigState);

            return Promise.reject(updateError);
        }

        if (result.missingClientDataSources.length > 0) {

            let message = `The following data sources were not found:\n${result.missingClientDataSources.join('\n')}\n\nDo you want to import them?`;

            this.setState({ showWarningModal: true, message, dataSourceAnalysisResult: result, bundle });
        }
        else {
            this.onImportConfigConfirmed(result, bundle, validationConfig);
        }

        return Promise.reject('');
    }

    onImportConfigConfirmed(result?: DataSourceAnalysisResult, bundle?: ReportConfigBundle, validationConfig?: ReportConfig | null): Promise<void> {

        this.setState({ showWarningModal: false });

        result = result || this.state.dataSourceAnalysisResult

        result.proceed = true;

        bundle = bundle || this.state.bundle;

        return this.importDataSources(bundle, result)
            .then(maps => maps ? this.finalize(bundle || {} as ReportConfigBundle, maps, validationConfig) : Promise.resolve());
    }

    importDataSources(bundle: ReportConfigBundle, result: DataSourceAnalysisResult): Promise<IdMaps | void> {

        if (!result.proceed) {

            return Promise.reject('Terminate import');
        }

        if (result.missingClientDataSources.length > 0) {

            let dataSources = bundle.dataSources.filter(x => result.missingClientDataSources.includes(x.name));

            return importDataSources(dataSources).then(() => this.execute(bundle));
        }

        return Promise.resolve(result.maps);
    }

    finalize(bundle: ReportConfigBundle, maps: IdMaps, validationConfig?: ReportConfig | null): Promise<void> {

        return getConfigDataSources(
            bundle.dataSources
                .filter(x =>
                    x.dataSourceKind !== dataSourceKind.globalRef)
                .map(x => maps.dataSource[x.id as number]),
            bundle.dataSources
                .filter(x =>
                    x.dataSourceKind === dataSourceKind.globalRef)
                .map(x => maps.dataSource[x.id as number])
        ).then(dataSources => this.showChanges(bundle, maps, dataSources, validationConfig));
    }

    showChanges(bundle: ReportConfigBundle, maps: IdMaps, dataSources: IngestionConfig[], validationConfig?: ReportConfig | null): Promise<void> {

        let errors: string[] = [];

        dataSourceFieldMapHelper.seed(bundle.dataSources, dataSources, maps.dataSourceField, errors);

        this.props.onChange({ errors: errors } as ImportReportConfigState);

        if (errors.length > 0) {

            return Promise.reject(errors.join('; '));
        }

        reportConfigAdapter.adapt(bundle.config, maps);

        if (typeHelper.isObject(validationConfig)) {

            reportConfigComponentIdMapper.mapIds(bundle.config, validationConfig as ReportConfig);

            this.setState({ toImport: bundle.config });

            let route = urlHelper.buildRoute(['import', bundle.config.id, 'changes']);

            return httpClient.post(route, bundle.config).then(response => this.onGetChangesSuccess(response.data));
        }
        else {

            return importReportConfig(bundle.config).then(this.props.onImportSuccess);
        }
    }

    onGetChangesSuccess(changes: ChangeDescription[]): void {

        let payload: ChangeLogModalState = {
            isOpen: true,
            title: 'Changes',
            columns: [moduleColumn, moduleRefColumn].concat(commonColumns),
            data: changes,
            error: null
        };

        store.dispatch({ type: actions.changeLogModal.open, payload: payload });
    }

    onSaveClick(): void {

        importReportConfig(this.state.toImport as ReportConfig).then(this.props.onImportSuccess)
    }

    onDownloadClick(): Promise<void> {

        let config = this.state.toImport as ReportConfig;

        let route = urlHelper.buildRoute(['import', config.id, 'changes-download']);

        return httpClient.download(route, config);
    }

    render(): JSX.Element {

        return (
            <>
                <div className="row no-gutters">
                    <div className="col-auto">
                        {this.props.children}
                    </div>
                    <div className="col-auto mr-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Paste Config Source"
                            value={this.props.state.json}
                            onChange={this.onSourceChange} />
                    </div>
                    <button
                        className="btn cb-btn"
                        onClick={this.onImportClick} >Import</button>
                </div>

                <ErrorList className="mt-2" errors={this.props.state.errors} />

                <ChangeLogModal>
                    <button className="btn btn-light" onClick={this.onDownloadClick}>Download</button>
                    <button className="btn cb-btn" onClick={this.onSaveClick}>Save</button>
                </ChangeLogModal>
                {
                    this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.onImportConfigConfirmed}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title='Remove config'
                        message={this.state.message} />
                }
            </>
        );
    }
}

export default ImportReportConfig;
