import { createCaseOption } from '../Enirchment/EnrichmentBuilder.Helpers';
import { getDefaultState, createFieldOption, createEmptyFieldOption } from './CopyReportFieldModal.Helpers';
import { Unsubscribe } from 'redux';
import { validate } from './CopyReportFieldModal.Validation';
import actions from '../../../../store/actions';
import CopyReportFieldModalState from './CopyReportFieldModalState';
import Modal from '../../../Common/Modal/Modal';
import RadioButtonGroup from '../../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import ReportCase from '../../../../types/report/ReportCase';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import SelectSearch, { SelectedOptionValue, SelectSearchOption } from 'react-select-search';
import store from '../../../../store/store';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import RadioButtonGroupMultiSelect from '../../../Common/RadioButtonGroupMultiSelect/RadioButtonGroupMultiSelect';
interface Props {
    fields: ReportFieldDefinition[];
    cases: ReportCase[];
}

class CopyReportFieldModal extends React.Component<Props, CopyReportFieldModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: Props) {
        super(props);

        this.state = getDefaultState();

        this.onOpen = this.onOpen.bind(this);
        this.onSourceCaseChange = this.onSourceCaseChange.bind(this);
        this.onTargetCaseChange = this.onTargetCaseChange.bind(this);
        this.onSourceFieldChange = this.onSourceFieldChange.bind(this);
        this.onTargetFieldChange = this.onTargetFieldChange.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.close = this.close.bind(this);
        this.getFieldsWithEmptyOption = this.getFieldsWithEmptyOption.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onOpen);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    onOpen(): void {
        let state = store.getState();

        if (state.action !== actions.copyFieldModal.change) {
            return;
        }

        this.setState(state.copyFieldModalChange);
    }

    onSourceCaseChange(value: string): void {

        let changes = { sourceCaseNumber: value } as CopyReportFieldModalState;


        this.setState(changes);
    }

    onTargetCaseChange(value: string, isChecked: boolean): void {

        let caseNumbers = new Set(this.state.targetCaseNumbers);
        if (isChecked && caseNumbers.has(value)) {
            caseNumbers.delete(value);
        }
        else {
            caseNumbers.add(value)
        }
        let changes = { targetCaseNumbers: caseNumbers } as CopyReportFieldModalState;

        this.setState(changes);
    }

    onSourceFieldChange(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {

        let changes = { sourceFieldId: selectedValue.toString() } as CopyReportFieldModalState;

        this.setState(changes);
    }

    onTargetFieldChange(selectedValue: SelectedOptionValue | SelectedOptionValue[]): void {

        this.setState({ targetFieldId: selectedValue.toString() });

    }

    onCopyClick(): void {

        let error = validate(this.state, this.props.cases, this.props.fields);

        if (error) {
            this.setState({ error: error });
            return;
        }

        store.dispatch({ type: actions.copyFieldModal.save, payload: this.state });

        this.close();
    }

    includeCase($case: ReportCase): boolean {

        return !typeHelper.isNumber($case.syncedWithId);
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    getFieldsWithEmptyOption(): SelectSearchOption[] {

        var fields = this.props.fields.map(createFieldOption);
        fields.splice(0, 0, createEmptyFieldOption());

        return fields;
    }

    render(): JSX.Element {

        let containerClass = this.props.cases.length > 5 ? 'copy-modal-big' : 'copy-modal-medium';

        return (
            <div className={`${containerClass}`}>
                <Modal state={this.state} close={this.close} size="large">
                    <div className='display-flex flex-direction-column'>
                        <div className='padding-right-15px width-100-percents'>
                            <h5>From</h5>
                            {
                                !this.state.copyAllFields &&
                                <SelectSearch
                                    options={this.props.fields.map(createFieldOption)}
                                    value={this.state.sourceFieldId as string}
                                    onChange={this.onSourceFieldChange}
                                    search={true}
                                    disabled={this.state.copyAllFields} />
                            }
                            <RadioButtonGroup
                                options={this.props.cases.filter(this.includeCase).map(createCaseOption)}
                                value={this.state.sourceCaseNumber}
                                name="source-cases"
                                onChange={this.onSourceCaseChange} />
                        </div>
                        <div className='width-100-percents'>
                            <h5>To</h5>
                            {
                                !this.state.copyAllFields &&
                                <SelectSearch
                                    options={this.getFieldsWithEmptyOption()}
                                    value={this.state.targetFieldId as string}
                                    onChange={this.onTargetFieldChange}
                                    search={true}
                                    disabled={this.state.copyAllFields} />
                            }
                            <RadioButtonGroupMultiSelect
                                options={this.props.cases.filter(this.includeCase).map(createCaseOption)}
                                values={this.state.targetCaseNumbers}
                                name="target-cases"
                                onClick={this.onTargetCaseChange} />
                        </div>
                    </div>
                    <div className="mt-3 mb-3">
                        <button className="btn cb-btn" onClick={this.onCopyClick}>Copy</button>
                    </div>

                </Modal>
            </div>
        );
    }
}

export default CopyReportFieldModal;