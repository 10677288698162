import { reportType } from '../../../constants/reportType';
import Aggregation from '../../../../types/report/Aggregation';
import Assignment from '../../../../types/functions/Assignment/Assignment';
import assignmentOperandHelper from '../../functions/assignment/assignmentOperandHelper';
import Condition from '../../../../types/functions/Condition/Condition';
import conditionHelper from '../../functions/condition/conditionHelper';
import DataSourceField from '../../../../types/report/DataSourceField';
import EndpointFilter from '../../../../types/report/EndpointFilter';
import IdMaps from '../../../../types/report/import/IdMaps';
import Lookup from '../../../../types/functions/Lookup';
import NumberDeclaration from '../../../types/Functions/NumberDeclaration';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../../functions/operand/operandHelper';
import ReportCase from '../../../../types/report/ReportCase';
import ReportCaseField from '../../../../types/report/ReportCaseField';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportFilter from '../../../../types/report/ReportFilter';
import selectMany from '../../common/array/selectMany';
import typeHelper from '../../common/typeHelper';
import { endpointFilterType } from '../../../constants/report/endpointFilterType';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import Dictionary from '../../../../types/report/Dictionary';

const adaptOperand = (operand: Operand, maps: IdMaps): void => {

    if (typeHelper.isNumber(operand.fieldId)) {

        operand.fieldId = maps.dataSourceField[operand.fieldId as number];
    }

    if (typeHelper.isNumber(operand.reportFieldId)) {

        operand.reportFieldId = maps.reportField[operand.reportFieldId as number];
    }
};

const adaptOperands = (operands: Operand[], maps: IdMaps): void => {

    selectMany(operands, o => operandHelper.asCollection(o)).forEach(o => adaptOperand(o, maps));
};

const adaptCondition = (condition: Condition, maps: IdMaps): void => {

    conditionHelper.getAllOperands(condition).forEach(o => adaptOperand(o, maps));
};

const adaptAssignment = (assignment: Assignment, maps: IdMaps): void => {

    assignmentOperandHelper.getAll(assignment).forEach(o => adaptOperand(o, maps));
};

const adaptFunction = (func: UserDefinedFunction, maps: IdMaps): void => {

    adaptAssignment(func.assignment, maps);
};

const adaptDictionary = (dictionary: Dictionary, maps: IdMaps): void => {

    dictionary.dataSourceId = maps.dataSource[dictionary.dataSourceId];

    dictionary.fields.forEach(x => adaptAssignment(x.assignment, maps));
};

const adaptLookup = (lookup: Lookup, maps: IdMaps): void => {

    if (typeHelper.isNumber(lookup.dataSource1Id)) {

        lookup.dataSource1Id = maps.dataSource[lookup.dataSource1Id as number];
    }

    lookup.dataSource2Id = maps.dataSource[lookup.dataSource2Id as number];

    adaptCondition(lookup.condition, maps);
};

const adaptDeclaration = (declaration: NumberDeclaration, maps: IdMaps, type: string): void => {

    if (type !== reportType.accuracy) {

        declaration.dataSourceId = maps.dataSource[declaration.dataSourceId as number];
    }

    adaptAssignment(declaration.assignment, maps);
};

const adaptFilter = (filter: ReportFilter, maps: IdMaps): void => {

    filter.dataSourceId = maps.dataSource[filter.dataSourceId];

    adaptCondition(filter.condition, maps);
};

const adaptEndpointFilter = (filter: EndpointFilter, maps: IdMaps): void => {
    if (filter.type === endpointFilterType.underReport && filter.dataSourceId) {
        filter.dataSourceId = maps.dataSource[filter.dataSourceId];
    }

    adaptCondition(filter.condition, maps);
};

const adaptDataSourceField = (field: DataSourceField, maps: IdMaps): void => {

    field.fieldId = maps.dataSourceField[field.fieldId];

    adaptAssignment(field.assignment, maps);
};

const adaptAggregation = (aggregation: Aggregation, maps: IdMaps): void => {

    aggregation.dataSourceId = maps.dataSource[aggregation.dataSourceId];

    adaptOperands(aggregation.groupBy, maps);

    adaptCondition(aggregation.condition, maps);

    aggregation.fields.forEach(x => adaptDataSourceField(x, maps));
};

const adaptReportCaseField = (field: ReportCaseField, maps: IdMaps): void => {

    field.fieldId = maps.reportField[field.fieldId];

    adaptAssignment(field.assignment, maps);
};

const adaptCase = ($case: ReportCase, maps: IdMaps): void => {

    $case.dataSourceId = maps.dataSource[$case.dataSourceId];

    adaptCondition($case.condition, maps);

    $case.fields.forEach(x => adaptReportCaseField(x, maps));
};

const adapt = (config: ReportConfig, maps: IdMaps): void => {

    if (config.type !== reportType.accuracy) {

        config.rawDataSourceId = maps.dataSource[config.rawDataSourceId as number];
    }

    config.refDataSources.forEach(x => x.id = maps.dataSource[x.id]);

    if (config.matchingKey.operands.length > 0) {

        adaptOperands(config.matchingKey.operands, maps);
    } else {

        adaptAssignment(config.matchingKey.assignment, maps);
    }

    if (config.matchingKeyEndPoint.operands.length > 0) {

        adaptOperands(config.matchingKeyEndPoint.operands, maps);
    } else {

        adaptAssignment(config.matchingKeyEndPoint.assignment, maps);
    }
    
    config.userDefinedFunctions.forEach(x => adaptFunction(x, maps));
    config.dictionaries.forEach(x => adaptDictionary(x, maps));
    config.lookups.forEach(x => adaptLookup(x, maps));
    config.variables.forEach(x => adaptDeclaration(x, maps, config.type));
    config.isinSelectors.forEach(x => adaptDeclaration(x, maps, config.type));
    config.leiSelectors.forEach(x => adaptDeclaration(x, maps, config.type));
    config.exchangeRatesSelectors.forEach(x => adaptDeclaration(x, maps, config.type));
    config.annaDsbUpiEnrichmentSelectors.forEach(x => adaptDeclaration(x, maps, config.type));
    config.lseSelectors.forEach(x => adaptDeclaration(x, maps, config.type));
    config.firdsFcaInstrumentSelectors.forEach(x => adaptDeclaration(x, maps, config.type));
    config.firdsEsmaInstrumentSelectors.forEach(x => adaptDeclaration(x, maps, config.type));
    config.annaDsbUpiSelectors.forEach(x => adaptDeclaration(x, maps, config.type));
    config.fcaRegulatedEntitiesSelectors.forEach(x => adaptDeclaration(x, maps, config.type));
    config.filters.forEach(x => adaptFilter(x, maps));
    config.endpointFilters.forEach(x => adaptEndpointFilter(x, maps));

    config.aggregations.forEach(x => adaptAggregation(x, maps));
    config.cases.forEach(x => adaptCase(x, maps));
    config.validations.forEach(x => adaptAssignment(x.assignment, maps));
};

const reportConfigAdapter = {
    adapt
};

export default reportConfigAdapter;
