import ObjectIndexer from '../../types/ObjectIndexer';
import stringHelper from './stringHelper';

const addParam = (param: string, result: ObjectIndexer<string>): void => {
    let parts = param.split('=');

    result[parts[0]] = parts[1];
};

const buildPair = <T>(key: string, value: T): string => key + '=' + value;

const joinParts = (url: string, pairs: string[]): string => url + '?' + pairs.join('&');

const deserializeQuery = (query: string): ObjectIndexer<string> => {

    let result = {} as ObjectIndexer<string>;

    if (stringHelper.isEmpty(query)) {
        return result;
    }

    let pairs = query.substr(1).split('&');

    pairs.forEach(pair => addParam(pair, result));

    return result;
};

const buildUrl = <T>(url: string, params: T): string => {

    let pairs = Object
        .keys(params as any)
        .map(key => buildPair(key, (params as ObjectIndexer<any>)[key]));

    return joinParts(url, pairs);
};

const buildCollectionParamUrl = <T>(url: string, paramName: string, collection: T[]): string => {

    let pairs = collection.map(x => buildPair(paramName, x));

    return joinParts(url, pairs);
};

const buildRoute = (parts: any[]): string => parts.join('/');

const urlHelper = {
    deserializeQuery,
    buildUrl,
    buildCollectionParamUrl,
    buildRoute
};

export default urlHelper;
