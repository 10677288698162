import StepProps from './StepProps';
import StepState from './StepState';

const getContainerStyles = (state: StepState): React.CSSProperties => {
    return {
        width: state.width,
        height: state.height,
        fontSize: state.pointFontSize
    };
};

const getStepTitleStyles = (state: StepState): React.CSSProperties => {
    return {
        height: state.halfHeight,
        fontSize: state.titleFontSize
    };
};

const getPointContainerStyles = (state: StepState): React.CSSProperties => {
    return {
        height: state.halfHeight
    };
};

const getLineStyles = (state: StepState, visible: boolean): React.CSSProperties => {

    let borderBottomWidth = visible ? state.lineThickness: '0';

    return {
        borderBottomWidth: borderBottomWidth
    };
};

const getContainerClasses = (props: StepProps): string => {
    let classes = ['step-container'];

    if (props.isCurrent) {
        classes.push('current');
    }

    if (props.isCompleted) {
        classes.push('completed');
    }

    return classes.join(' ');
};

const getPointClasses = (clickable: boolean): string => {
    let classes = ['column', 'point'];

    if (clickable) {
        classes.push('clickable');
    }

    return classes.join(' ');
};

export {
    getContainerStyles,
    getStepTitleStyles,
    getPointContainerStyles,
    getLineStyles,
    getContainerClasses,
    getPointClasses
};
