import { SelectSearchOption } from 'react-select-search';
import CopyAggregationModalState from './CopyAggregationFieldModalState';
import DataSourceField from '../../../../types/report/DataSourceField';
import FieldDefinition from '../../../../types/common/FieldDefinition';

const getDefaultState = (): CopyAggregationModalState => {
    return {
        title: '',
        isOpen: false,
        error: null,
        sourceNumber: '',
        targetNumbers: new Set(),
        sourceFieldId: '',
        targetFieldId: '',
        copyAllFields: false
    };
};

const createFieldOption = (field: DataSourceField, fieldDefinitions?: FieldDefinition[]): SelectSearchOption => {

    return {
        name: fieldDefinitions ? fieldDefinitions.find(x => x.id === field.fieldId)?.name || '' : '',
        value: field.fieldId.toString()
    };
};

const createEmptyFieldOption = (): SelectSearchOption => {
    return {
        name: '',
        value: ''
    };
};

export { getDefaultState, createFieldOption, createEmptyFieldOption };
