import React from 'react';
import './validationEsmaTable.scss';
import reportRegime from '../../../../infrastructure/constants/reportRegime';

const createValidationEsmaTable = (tableRows?: string[][], onChange?: (e: any, rowIndex: number, cellIndex: number) => void): JSX.Element => {

    tableRows = tableRows || [[]];

    const headers = ['', 'NEWT', 'MODI', 'VALU', 'CORR', 'TERM', 'EROR', 'REVI', 'POSC', 'MARU'];

    return (
        <div id='validationsEsmaTable'>
            <div>
                <div key='headerRow' className='display-flex'>
                    {headers.map((header: string) => {
                        return <div className='header' key={header}>{header || ''}</div>
                    })
                    }
                </div>
            </div>
            <div className='tbody'>
                {tableRows.map((row: string[], rowIndex: number) => {
                    return <div key={rowIndex} className='tr' >
                        {row.map((value: string, cellIndex: number) => {
                            return <input disabled={cellIndex > 0 && !onChange} type="text" className='td input' key={cellIndex} onChange={(e: any) => onChange ? onChange(e, rowIndex, cellIndex) : null} value={value ?? ''} />
                        })
                        }
                    </div>
                })
                }
            </div>
        </div>
    )
}

const getDetailsToBeReportedString = (regime: string): string => {

    switch (regime) {
        case reportRegime.ASIC:
        case reportRegime['ASIC Rewrite']:
            return 'Details to be reported (ASIC)';
        case reportRegime['MAS Rewrite']:
            return 'Description of data field (MAS)'
        default:
            return 'Details to be reported (ESMA)';
    }
}

const getFormatToBeReportedString = (regime: string): string => {

    switch (regime) {
        case reportRegime.ASIC:
        case reportRegime['ASIC Rewrite']:
            return 'Format to be reported (ASIC)';
        case reportRegime['MAS Rewrite']:
            return 'Field Value (MAS)'
        default:
            return 'Format to be reported (ESMA)';
    }
}

const getValidationsString = (regime: string): string => {

    switch (regime) {
        case reportRegime.ASIC:
            return 'Validations (ASIC)';
        case reportRegime['ASIC Rewrite']:
        case reportRegime['MAS Rewrite']:
            return 'Validations (DTCC)'
        default:
            return 'Validations (ESMA)';
    }
}

const getFieldNumberString = (regime: string): string => {

    switch (regime) {
        case reportRegime.ASIC:
        case reportRegime['ASIC Rewrite']:
            return 'ASIC Field Number';
        default:
            return 'RTS Field Number';
    }
}

const getClassOfSpecifiedDerivativesContractsString = (regime: string): string => {

    switch (regime) {
        case reportRegime['ASIC Rewrite']:
            return 'Class of specified derivatives contracts (ASIC)';
        case reportRegime['MAS Rewrite']:
            return 'Class of specified derivatives contracts (MAS)';
        default:
            return 'Class of specified derivatives contracts';
    }
}

const getExplanatoryNotesString = (regime: string): string => {

    switch (regime) {
        case reportRegime['ASIC Rewrite']:
            return 'Explanatory notes (ASIC)';
        case reportRegime['MAS Rewrite']:
            return 'Explanatory notes (MAS)';
        default:
            return 'Explanatory notes';
    }
}

const shouldShowRegimeSpecificColumn = (regime: string): boolean => {

    switch (regime) {
        case reportRegime['MAS Rewrite']:
            return true;
        default:
            return false;
    }
}

export { createValidationEsmaTable, getDetailsToBeReportedString, getFormatToBeReportedString, getValidationsString, getFieldNumberString, getClassOfSpecifiedDerivativesContractsString, getExplanatoryNotesString, shouldShowRegimeSpecificColumn } 
