import React from 'react';
import SelectOption from '../../../infrastructure/types/SelectOption';

const createOption = (option: SelectOption): JSX.Element => {
    return (
        <option key={option.value} value={option.value} disabled={option.disabled}>{option.text}</option>
    );
};

const createSelectContent = (options: SelectOption[]): JSX.Element[] => {

    return options.map(createOption);
};

const createEmptyOption = (): JSX.Element => createOption({ value: '', text: '' });

export { createEmptyOption, createSelectContent };
