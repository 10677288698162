import { getConfigOptionText, getConfigOptionValue, isDataSourceReferenced, reportTypeOptions, regimeOptions, runAllOptions, getSubRegimeOptions } from './DataSourceForm.Helpers';
import { reportType } from '../../../infrastructure/constants/reportType';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import dataSourceKind from '../../../infrastructure/constants/dataSourceKind';
import FormGroup from '../../Common/FormGroup/FormGroup';
import guidance from './DataSourceForm.Guidance';
import IngestionConfigOption from '../../../types/ingestion/IngestionConfigOption';
import MultiSelectList from '../../Common/MultiSelectList/MultiSelectList';
import RadioButtonGroup from '../../Common/RadioButtonGroup/RadioButtonGroup';
import RefDataSource from '../../../types/report/RefDataSource';
import ReportConfig from '../../../types/report/ReportConfig';
import reportPageMode from '../Pages/ReportPage/ReportPageMode';
import SelectList from '../../Common/SelectList/SelectList';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import ReferencesModal from '../../Common/Modals/ReferencesModal/ReferencesModal';
import operandType from '../../../infrastructure/constants/functions/operandType';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import IdAndName from '../../../types/report/IdAndName';
import React from 'react';
import ReportCollections from '../../../infrastructure/types/Functions/ReportCollections';

interface Props {
    isWaiting: boolean;
    dataSources: IngestionConfigOption[],
    mode: string;
    reportConfig: ReportConfig;
    onChange: (reportConfig: ReportConfig) => void;
    setSelectedCase?: (number: number, callback: () => void) => void;
    setSelectedAggregation?: (number: number, callback: () => void) => void;
    setSelectedDictionary?: (number: number, callback: () => void) => void;
    collections?: ReportCollections;
    hideReferences?: boolean;
}

interface State {
    fields: IdAndName[];
    showReferencesModal: boolean;
    referenceOperandType: string;
    hideReferences?: boolean;
}
class DataSourceForm extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            fields: [],
            showReferencesModal: false,
            referenceOperandType: operandType.field
        }

        this.onNameChange = this.onNameChange.bind(this);
        this.onRegimeSelected = this.onRegimeSelected.bind(this);
        this.onSubRegimeSelected = this.onSubRegimeSelected.bind(this);
        this.onTypeSelected = this.onTypeSelected.bind(this);
        this.onRawConfigSelected = this.onRawConfigSelected.bind(this);
        this.onRefConfigSelected = this.onRefConfigSelected.bind(this);
        this.onRunStatusChange = this.onRunStatusChange.bind(this);
        this.onRefConfigRemoveClick = this.onRefConfigRemoveClick.bind(this);

        this.isChangeRegimeDisabled = this.isChangeRegimeDisabled.bind(this);
        this.isAddRefDataSourceDisabled = this.isAddRefDataSourceDisabled.bind(this);
        this.isRemoveRefDataSourceDisabled = this.isRemoveRefDataSourceDisabled.bind(this);
        this.getRawDataSourceContent = this.getRawDataSourceContent.bind(this);
        this.buildRefDataSourceItem = this.buildRefDataSourceItem.bind(this);
        this.buildDataSourceLink = this.buildDataSourceLink.bind(this);
        this.buildGlobalRefDataSources = this.buildGlobalRefDataSources.bind(this);
        this.getFields = this.getFields.bind(this);
    }

    onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {

        let changes = { name: e.target.value } as ReportConfig;

        this.props.onChange(changes);
    }

    onRegimeSelected(value: string): void {

        let subRegime = getSubRegimeOptions(value)[0]?.value;

        let changes = { regime: value, subRegime: subRegime } as ReportConfig;

        this.props.onChange(changes);
    }

    onSubRegimeSelected(value: string): void {

        let changes = { subRegime: value } as ReportConfig;

        this.props.onChange(changes);
    }

    onTypeSelected(value: string): void {

        let changes = { type: value } as ReportConfig;

        if (value === reportType.directReporting) {

            changes.endpointFilters = [];
            changes.lookups = this.props.reportConfig.lookups.filter(x => !x.isEndpoint);
        }

        this.props.onChange(changes);
    }

    onRawConfigSelected(value: string): void {

        let rawDataSourceId = parseInt(value);

        let changes = { rawDataSourceId } as ReportConfig;

        this.props.onChange(changes);
    }

    onRefConfigSelected(value: string): void {

        let id = parseInt(value);

        let refDataSources = this.props.reportConfig.refDataSources.concat([{ id: id, runStatus: 0 }]);

        let changes = { refDataSources } as ReportConfig;

        this.props.onChange(changes);
    }

    onRunStatusChange(id: number, value: string): void {

        let target = this.props.reportConfig.refDataSources.find(x => x.id === id) as RefDataSource;

        target.runStatus = +value;

        let changes = { refDataSources: this.props.reportConfig.refDataSources } as ReportConfig;

        this.props.onChange(changes);
    }

    onRefConfigRemoveClick(value: string): void {

        let id = parseInt(value);

        let refDataSources = this.props.reportConfig.refDataSources.filter(x => x.id !== id);

        let changes = { refDataSources } as ReportConfig;

        this.props.onChange(changes);
    }

    isRawDataSource(config: IngestionConfigOption): boolean {
        return (
            config.dataSourceKind === dataSourceKind.raw ||
            config.dataSourceKind === dataSourceKind.rawAndRef
        );
    }

    isRefDataSource(config: IngestionConfigOption): boolean {
        return (
            config.dataSourceKind === dataSourceKind.ref ||
            config.dataSourceKind === dataSourceKind.rawAndRef
        );
    }

    isGlobalRefDataSource(config: IngestionConfigOption): boolean {
        return (
            config.dataSourceKind === dataSourceKind.globalRef
        );
    }

    isChangeRegimeDisabled(): boolean {

        return this.props.mode !== reportPageMode.create;
    }

    isAddRefDataSourceDisabled(): boolean {

        return this.props.mode === reportPageMode.readOnly;
    }

    isRemoveRefDataSourceDisabled(value: string): boolean {
        return (
            this.props.mode === reportPageMode.readOnly ||
            isDataSourceReferenced(parseInt(value), this.props.reportConfig)
        );
    }

    async getFields(id: number, route: string) {

        let url = urlHelper.buildRoute([route, id]);

        let result = await httpClient.get<IngestionConfig>(url);

        let fields = result.data.fieldDefinitions as IdAndName[];

        this.setState({ fields, showReferencesModal: true });
    }

    getRawDataSourceContent(): JSX.Element {

        if (this.props.isWaiting) {

            return (<></>);
        }

        if (this.props.mode === reportPageMode.create) {

            return (
                <SelectList
                    options={this.props.dataSources.filter(this.isRawDataSource).map(x => { return { value: getConfigOptionValue(x), text: getConfigOptionText(x) } })}
                    className="form-control"
                    value={typeHelper.isNumber(this.props.reportConfig.rawDataSourceId) ? (this.props.reportConfig.rawDataSourceId as number).toString() : ''}
                    onChange={this.onRawConfigSelected}
                    includeEmptyOption={true} />
            );
        }

        let config = this.props.dataSources.find(x => x.id === this.props.reportConfig.rawDataSourceId) as IngestionConfigOption;

        if (!typeHelper.isObject(config)) {
            config = {} as IngestionConfigOption;
            config.id = this.props.reportConfig.rawDataSourceId as number;
            config.name = `Config id ${this.props.reportConfig.rawDataSourceId}`;
        }

        return (
            <div className='flex-container'>
                {this.buildDataSourceLink(config)}
                {!this.props.hideReferences &&
                    <div className="col-auto margin-0-important padding-right-0-important">
                        <button
                            onClick={() => this.getFields(config.id, 'ingestion')}
                            className="btn btn-light margin-0-important height-30px line-height-1"
                        >References
                        </button>
                    </div>
                }
            </div>
        );
    }

    buildGlobalRefDataSources(config: IngestionConfigOption): JSX.Element {
        return (
            <div style={{ padding: '2px 0' }}>
                <div className='flex-container  height-30px'>
                    {this.buildDataSourceLink(config)}
                    {!this.props.hideReferences &&
                        <div className="col-auto margin-0-important padding-right-0-important">
                            <button
                                onClick={() => this.getFields(config.id, 'global-ref-data-source')}
                                className="btn btn-light margin-0-important height-30px line-height-1"
                            >References
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }

    buildRefDataSourceItem(config: IngestionConfigOption): JSX.Element {

        let refDataSource = this.props.reportConfig.refDataSources.find(x => x.id === config.id) as RefDataSource;

        return (
            <div style={{ padding: '2px 0' }}>
                <div className='flex-container  height-30px'>
                    <div>
                        {this.buildDataSourceLink(config)}
                    </div>
                    <div className="display-flex">
                        <div>
                        <RadioButtonGroup
                            options={runAllOptions}
                            value={refDataSource.runStatus.toString()}
                            onChange={value => this.onRunStatusChange(refDataSource.id, value)}
                            name={`run-all-${refDataSource.id}`} />
                    </div>
                    {!this.props.hideReferences &&
                        <div className="col-auto margin-0-important padding-right-0-important">
                            <button
                                onClick={() => this.getFields(config.id, 'ingestion')}
                                className="btn btn-light margin-0-important height-30px line-height-1"
                            >References
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    buildDataSourceLink(config: IngestionConfigOption): JSX.Element {

        let route = config.dataSourceKind === dataSourceKind.globalRef ?
            appRoutes.globalRefDataSource.preview :
            appRoutes.ingestion.edit;

        let url = urlHelper.buildUrl(route, { id: config.id });

        return (
            <a href={url} target="_blank" rel="noopener noreferrer" className="transparent-button">{config.name}</a>
        );
    }

    render(): JSX.Element {
        const labelClass = 'col-3';
        const contentClass = 'col-9';

        const subRegimeOptions = getSubRegimeOptions(this.props.reportConfig.regime);

        return (
            <>
                <FormGroup label="Report name:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.name}>
                    <input
                        type="text"
                        className="form-control"
                        value={this.props.reportConfig.name}
                        onChange={this.onNameChange}
                        disabled={this.props.mode === reportPageMode.readOnly} />
                </FormGroup>
                <FormGroup label="Reporting regime:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.regime}>
                    <SelectList
                        options={regimeOptions}
                        className="form-control"
                        disabled={this.isChangeRegimeDisabled()}
                        value={this.props.reportConfig.regime}
                        onChange={this.onRegimeSelected}
                        includeEmptyOption={true} />
                    {
                        subRegimeOptions.length > 0 && this.props.reportConfig.subRegime &&
                        <div className="mt-2">
                            <RadioButtonGroup
                                options={subRegimeOptions}
                                isDisabled={() => this.isChangeRegimeDisabled()}
                                value={this.props.reportConfig.subRegime}
                                onChange={this.onSubRegimeSelected}
                                name={`sub-regime-options`} />
                        </div>
                    }
                </FormGroup>
                <FormGroup label="Reporting type:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.reportType}>
                    <SelectList
                        options={reportTypeOptions}
                        className="form-control"
                        disabled={this.props.mode === reportPageMode.readOnly}
                        value={this.props.reportConfig.type}
                        onChange={this.onTypeSelected} />
                </FormGroup>
                <FormGroup label="Raw data source:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.rawDataSource}>
                    {this.getRawDataSourceContent()}
                </FormGroup>
                <FormGroup label="Client ref data sources:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.refDataSources}>
                    <MultiSelectList
                        items={this.props.dataSources.filter(this.isRefDataSource)}
                        getItemValue={getConfigOptionValue}
                        getItemText={getConfigOptionText}
                        isItemDisabled={x => false}
                        className="form-control"
                        isSelectListDisabled={this.isAddRefDataSourceDisabled()}
                        isRemoveDisabled={this.isRemoveRefDataSourceDisabled}
                        values={this.props.reportConfig.refDataSources.map(x => x.id.toString())}
                        onSelectChange={this.onRefConfigSelected}
                        onRemoveClick={this.onRefConfigRemoveClick}
                        buildItem={this.buildRefDataSourceItem} />
                </FormGroup>
                <FormGroup label="CN global ref data sources:" labelClassName={labelClass} contentClassName={contentClass} info={guidance.globalRefDataSources}>
                    <MultiSelectList
                        items={this.props.dataSources.filter(this.isGlobalRefDataSource)}
                        getItemValue={getConfigOptionValue}
                        getItemText={getConfigOptionText}
                        isItemDisabled={x => false}
                        className="form-control"
                        isSelectListDisabled={this.isAddRefDataSourceDisabled()}
                        isRemoveDisabled={this.isRemoveRefDataSourceDisabled}
                        values={this.props.reportConfig.refDataSources.map(x => x.id.toString())}
                        onSelectChange={this.onRefConfigSelected}
                        onRemoveClick={this.onRefConfigRemoveClick}
                        buildItem={this.buildGlobalRefDataSources} />
                </FormGroup>
                {
                    this.state.showReferencesModal &&
                    <ReferencesModal
                        reportConfig={this.props.reportConfig}
                        onClose={() => this.setState({ showReferencesModal: false })}
                        operandType={this.state.referenceOperandType}
                        fields={this.state.fields}
                        defaultSourceOptionTitle='References'
                        defaultFieldsOptionTitle='Select from List of Fields'
                        defaultResultOptionTitle='List of References'
                        sourcesTitle='Source Level:'
                        fieldsTitle='Field Level:'
                        modalTitle='References'
                        collections={this.props.collections as ReportCollections}
                    />
                }
            </>
        );
    }
}

export default DataSourceForm;
