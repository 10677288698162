import { getVisibility } from '../../../../infrastructure/helpers/html/htmlUtils';
import { mapToBasicOption } from '../../../../infrastructure/helpers/html/selectOptionHelper';
import { typeOptions, booleanOptions } from './ConstantBuilder.Helpers';
import BaseOperandProps from '../Props/BaseOperandProps';
import constantInitialValueHelper from '../../../../infrastructure/helpers/functions/operand/constant/constantInitialValueHelper';
import dataType from '../../../../infrastructure/constants/dataType';
import Operand from '../../../../types/functions/Operand/Operand';
import RadioButtonGroup from '../../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import SelectList from '../../../Common/SelectList/SelectList';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';

class ConstantBuilder extends React.Component<BaseOperandProps> {
    private textInput: React.RefObject<HTMLInputElement>;

    constructor(props: BaseOperandProps) {
        super(props);

        this.textInput = React.createRef<HTMLInputElement>();

        this.onDataTypeChange = this.onDataTypeChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onBooleanChange = this.onBooleanChange.bind(this);
        this.onAllowedValueChange = this.onAllowedValueChange.bind(this);
        this.getAllowedTypeOptions = this.getAllowedTypeOptions.bind(this);
        this.getAllowedValueOptions = this.getAllowedValueOptions.bind(this);
        this.getOperandValue = this.getOperandValue.bind(this);
        this.isTextInputType = this.isTextInputType.bind(this);
    }

    componentDidUpdate() {
        if (this.isTextInputType()) {
            this.textInput.current?.focus();
        }
    }

    onDataTypeChange(value: string) {

        let changes = { dataType: value, value: constantInitialValueHelper.get(value) } as Operand;

        this.props.onOperandChange(changes);
    }

    onTextChange(e: React.ChangeEvent<HTMLInputElement>) {

        let changes = { value: e.target.value } as Operand;

        this.props.onOperandChange(changes);
    }

    onBooleanChange(value: string) {

        let changes = { value: value } as Operand;

        this.props.onOperandChange(changes);
    }

    onAllowedValueChange(value: string) {

        let changes = { value } as Operand;

        this.props.onOperandChange(changes);
    }

    getAllowedTypeOptions(): SelectOption[] {

        if (!typeHelper.isArray(this.props.operandRules.allowedTypes)) {
            return typeOptions;
        }

        return typeOptions.filter(x => (this.props.operandRules.allowedTypes as string[]).includes(x.value));
    }

    getAllowedValueOptions(): SelectOption[] {

        if (!typeHelper.isArray(this.props.operandRules.allowedValues)) {
            return [];
        }

        return (this.props.operandRules.allowedValues as string[]).map(mapToBasicOption);
    }

    getOperandValue() {
        return typeHelper.isString(this.props.operand.value) ? this.props.operand.value : '';
    }

    isTextInputType(): boolean {

        return this.props.operand.dataType !== dataType.boolean;
    }

    render(): JSX.Element {

        if (typeHelper.isArray(this.props.operandRules.allowedValues)) {

            return (
                <SelectList
                    className="form-control"
                    options={this.getAllowedValueOptions()}
                    value={this.getOperandValue()}
                    onChange={this.onAllowedValueChange}
                    includeEmptyOption={true} />
            );
        }

        return (
            <div className="row">
                <div className="col">
                    <SelectList
                        className="form-control"
                        options={this.getAllowedTypeOptions()}
                        value={this.props.operand.dataType}
                        onChange={this.onDataTypeChange} />
                </div>
                <div className="col">
                    <div style={getVisibility(this.isTextInputType())}>
                        <input
                            ref={this.textInput}
                            type="text"
                            className="form-control"
                            value={this.getOperandValue()}
                            onChange={this.onTextChange} />
                    </div>
                    <div style={getVisibility(!this.isTextInputType())}>
                        <RadioButtonGroup
                            options={booleanOptions}
                            value={this.getOperandValue()}
                            name="boolean-values"
                            onChange={this.onBooleanChange} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ConstantBuilder;
