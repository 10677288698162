import ArgumentDefinition from '../../../../types/functions/ArgumentDefinition';
import copyArray from '../../../../infrastructure/helpers/common/copyArray';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import functionDisplayHelper from '../../../../infrastructure/helpers/functions/function/functionDisplayHelper';
import functionHelper from '../../../../infrastructure/helpers/functions/function/functionHelper';
import getFunctionArgument from './getArgument';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import Operand from '../../../../types/functions/Operand/Operand';
import React from 'react';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import functionName from '../../../../infrastructure/constants/functions/definition/functionName';
import { ReactComponent as RemoveIcon } from '../../../../components/Common/BootstrapIcons/trash.svg';
import Modal from '../../../Common/Modal/Modal';
import operandDisplayHelper from '../../../../infrastructure/helpers/functions/operand/operandDisplayHelper';
import './styles.css';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import Dictionary from '../../../../types/report/Dictionary';
import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';

interface FunctionOperandProps {
    operand: Operand,
    operandNumber: number,
    argumentIndexes: number[],
    functions: UserDefinedFunction[],
    lookups: Lookup[],
    dictionaries: Dictionary[],
    variables: ReportVariable[],
    dataSources: IngestionConfig[],
    reportFields: ReportFieldDefinition[],
    customDataSourceFields: CustomDataSourceFields,
    selectorNumber: number | null,
    onClick: (operandNumber: number, argumentIndexes: number[], event: React.MouseEvent<HTMLElement>) => void,
    disabled: boolean
}

class FunctionOperand extends React.Component<FunctionOperandProps, { showArgumentsModal: boolean, initialArguments: Operand[], error: string | null }> {

     defaultArgumentCount = 3;
     minArgumentsCount = functionHelper.getMinArrayArgumentCount(this.props.operand.function);
     maxArgumentsCount = functionHelper.getMaxArrayArgumentsCount(this.props.operand.function);

    constructor(props: FunctionOperandProps) {
        super(props);

        this.state = {
            showArgumentsModal: false,
            initialArguments: [],
            error: null
        }
        this.onOperandArgumentsChange = this.onOperandArgumentsChange.bind(this);
        this.onButtonMoreArgumentsClick = this.onButtonMoreArgumentsClick.bind(this);
        this.onCloseArgumentsModal = this.onCloseArgumentsModal.bind(this);
        this.onSaveArgumentsModal = this.onSaveArgumentsModal.bind(this);
        this.onAddArgument = this.onAddArgument.bind(this);
        this.onRemoveArgument = this.onRemoveArgument.bind(this);
        this.isArrayFunction = this.isArrayFunction.bind(this);
    }

    addComma(i: number, args: Operand[], isMethod: boolean, isOperator: boolean): boolean {
        if (isOperator) {
            return false;
        }

        return (
            (i > 0 || !isMethod) &&
            (i < args.length - 1)
        );
    };

    buildArgumentDescription(definition: ArgumentDefinition): string {

        return `${definition.name}: ${definition.allowedTypes.join(' | ')}`;
    };

    removeHiddenArguments(hiddenDataTypeArguments: string[], args: Operand[]): Operand[] {
        return args.filter(a => !hiddenDataTypeArguments.includes(a.dataType));
    }

    onOperandArgumentsChange(args: Operand[]) {
        this.props.operand.arguments = args;
    }

    onCloseArgumentsModal() {
        this.onOperandArgumentsChange(this.state.initialArguments);
        this.setState({ showArgumentsModal: false });
    }

    onButtonMoreArgumentsClick() {
        this.setState({ showArgumentsModal: true, initialArguments: this.props.operand.arguments?.slice() || [] });
    }

    validateArguments() {

        //start from 1 as on index 0 is the main function argument which is not defined here but on the main page
        for (var i = 1; i < (this.props.operand.arguments?.length || 0); i++) {
            const operand1Details = operandDisplayHelper.getOperandDetails(this.props.operand?.arguments?.[i] || {} as Operand, this.props.functions, this.props.lookups, this.props.dictionaries, this.props.variables, this.props.dataSources, this.props.reportFields, {} as CustomDataSourceFields, null);
            if (!operand1Details.isSetUp) {
                return 'Operand(s) not set up';
            }
        }

        return null;
    }

    onSaveArgumentsModal() {
        const error = this.validateArguments();
        if (!error) {
            this.setState({ error: null, showArgumentsModal: false });
        }
        else {
            this.setState({ error });
        }
    }


    getEmptyOperand() {
        return {
            operandType: '',
            fieldId: null,
            lookupNumber: null,
            variableNumber: null,
            reportFieldId: null,
            customDataSourceFieldId: null,
            selectorNumber: null,
            dataType: '',
            value: '',
            function: '',
            arguments: [],
            dictionaryNumber: null,
            functionNumber: null
        };
    }

    onAddArgument() {

        var mainFunctionArgument = 1;

        if (this.maxArgumentsCount > (this.props.operand.arguments?.length || 0) - mainFunctionArgument) {

            let args = this.props.operand.arguments?.slice() || [];

            args.push(this.getEmptyOperand());

            this.onOperandArgumentsChange(args);

            this.setState({ error: '' });
        }
        else {
            this.setState({ error: `Maximum arguments count is ${this.maxArgumentsCount}` });
        }
    }

    onRemoveArgument(index: number) {

        var mainFunctionArgument = 1;

        if (((this.props.operand.arguments?.length || 0) - mainFunctionArgument) > this.minArgumentsCount) {

            let args = this.props.operand.arguments?.slice() || [];

            args.splice(index, 1);

            this.onOperandArgumentsChange(args);

            this.setState({ error: '' });
        }
        else {
            this.setState({ error: `Minimum arguments count is ${this.minArgumentsCount}` });
        }
    }

    isArrayFunction(): boolean {

        let isFunction = stringHelper.isNonEmpty(this.props.operand.function);
        if (!isFunction) {
            return false;
        }

        return functionHelper.isArrayFunction(this.props.operand.function);
    }

    render() {
        let definition = functionHelper.getFunctionDefinition(this.props.operand.function);
        switch (this.props.operand.function) {
            case functionName.activeOnTradingDate:
                if (definition.hiddenDataTypeArguments) {
                    let newArgs: Operand[] = this.removeHiddenArguments(definition.hiddenDataTypeArguments as string[], this.props.operand.arguments as Operand[]);
                    this.props.operand.arguments = newArgs;
                }
                break;
            default: break;
        }

        let tooltips = functionHelper.getArgumentDescriptions(this.props.operand.function, (this.props.operand.arguments as Operand[]).length, this.buildArgumentDescription);

        let args: JSX.Element[] = (this.props.operand.arguments as Operand[])
            .map((x, i, arr) => getFunctionArgument(x, this.props.operandNumber, copyArray(this.props.argumentIndexes).concat([i]), tooltips[i], this.props.functions, this.props.lookups, this.props.dictionaries, this.props.variables, this.props.dataSources, this.props.reportFields, this.props.customDataSourceFields, this.props.selectorNumber, this.props.onClick, i, this.addComma(i, arr, definition.isMethod, !!definition.operator), this.props.disabled));

        return (
            <>
                {definition.operator ?
                    <>
                        <span className="code">(</span>
                        {args[0]}

                        <button className="transparent-button code function" onClick={(event) => this.props.onClick(this.props.operandNumber, this.props.argumentIndexes, event)} disabled={this.props.disabled} title={definition.description}>
                            {definition.operator}
                        </button>

                        {args[1]}
                        <span className="code">)</span>
                    </>
                    :
                    <>
                        {definition.isMethod && args[0]}
                        {definition.isMethod && <span className="code">.</span>}

                        <button className="transparent-button code function" onClick={(event) => this.props.onClick(this.props.operandNumber, this.props.argumentIndexes, event)} disabled={this.props.disabled} title={definition.description}>
                            {functionDisplayHelper.getDisplayName(this.props.operand.function)}
                        </button>
                    </>

                }
                {this.state.showArgumentsModal?
                    <div className='multiple-arguments'>
                        <Modal state={{title: 'Arguments',isOpen: true,error: this.state.error}} close={this.onCloseArgumentsModal}>
                            <div className='flex-column arguments'>
                                {args.slice(definition.isMethod ? 1 : 0).map((item, index) => {
                                    return <div key={item.key} className='flex-align-center display-flex align-center justify-content-start border-1px-solid-black margin-bottom-10px'>
                                        {!this.props.disabled &&
                                            <button className="transparent-button" type="button" onClick={() => { this.onRemoveArgument(index + (definition.isMethod ? 1 : 0)); }}>
                                            <RemoveIcon />
                                        </button>
                                        }
                                        {item}
                                    </div>
                                })
                                }
                            </div>
                            {!this.props.disabled &&
                                <div className='mt-3 mb-3 buttons-wrapper'>
                                    <button className='btn btn-light' onClick={this.onAddArgument}>Add</button>
                                    <button className='btn save-btn' onClick={this.onSaveArgumentsModal}>Save</button>
                                </div>
                            }
                        </Modal>
                    </div>
                    : !definition.operator?
                    <>
                        <span className="code">(</span>
                        {args.slice(definition.isMethod ? 1 : 0).slice(0, this.defaultArgumentCount)}
                            {this.isArrayFunction() && (this.props.operand.arguments?.length || 0) - (definition.isMethod ? 1 : 0) > this.defaultArgumentCount &&
                            <button className='transparent-button code function' onClick={this.onButtonMoreArgumentsClick}>...More</button>
                        }
                        <span className="code">)</span>
                        </>
                    : null
                }
            </>
        );
    };
}

export default FunctionOperand;