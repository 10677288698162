import { createDataSourceOption } from './AggregationBuilder.Helpers';
import { ReactComponent as RemoveIcon } from '../../Common/BootstrapIcons/close.svg';
import Aggregation from '../../../types/report/Aggregation';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import EditableText from '../../Common/EditableText/EditableText';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import React from 'react';
import SelectList from '../../Common/SelectList/SelectList';

const getColumns = (
    onNameChange: (name: string, number: number) => void,
    onDataSourceChange: (dataSourceId: string, number: number) => void,
    onBusinessDescriptionChange: (businessDescription: string, number: number) => void,
    onRemoveClick: (number: number) => void,
    dataSources: IngestionConfig[],
    isReadOnly: boolean
): ColumnDefinition<Aggregation>[] => {

    let columns: ColumnDefinition<Aggregation>[] = [
        {
            header: 'Reference',
            getContent: x => x.number.toString(),
            width: '110px'
        },
        {
            header: 'Aggregation Name',
            getContent: x => <EditableText disabled={isReadOnly} value={x.name} onChange={value => onNameChange(value, x.number)} multiline={false} />,
            width: '200px'
        },
        {
            header: 'Data Source',
            getContent: x =>
                <SelectList
                    className="inline-select"
                    options={dataSources.map(createDataSourceOption)}
                    value={x.dataSourceId.toString()}
                    onChange={value => onDataSourceChange(value, x.number)} />,
            width: '200px'
        },
        {
            header: 'Business Description',
            getContent: x => <EditableText disabled={isReadOnly} value={x.businessDescription} onChange={value => onBusinessDescriptionChange(value, x.number)} multiline={true} style={{ width: '100%', height: '60px' }} />
        },
        {
            header: '',
            getContent: x =>
                <button
                    className="custom-icon-button float-right"
                    onClick={() => onRemoveClick(x.number)}
                    disabled={isReadOnly}>
                    <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                </button>,
            width: '40px'
        }
    ];

    return columns;
};

export { getColumns };
