import { getColumns } from './AggregatedRecordBuilder.Grid';
import aggregatedRecordFieldHelper from '../../../infrastructure/helpers/functions/aggregation/aggregatedRecordFieldHelper';
import AssignmentBuilder from '../../Functions/Assignment/AssignmentBuilder';
import DataSourceField from '../../../types/report/DataSourceField';
import dataSourceHelper from '../../../infrastructure/helpers/functions/common/dataSourceHelper';
import DeclarationBuilderProps from '../../Functions/Declaration/DeclarationBuilderProps';
import ErrorList from '../../Common/ErrorList/ErrorList';
import FieldDefinition from '../../../types/common/FieldDefinition';
import guidance from '../ReportConfigEditor/ReportConfigEditor.Guidance';
import InfoIcon from '../../Common/InfoIcon/InfoIcon';
import locationType from '../../../types/functions/Location/LocationType';
import pseudocodeHelper from '../../../infrastructure/helpers/functions/common/pseudocodeHelper';
import React from 'react';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import CopyAggregationModalState from './CopyAggregationModal/CopyAggregationFieldModalState';
import store from '../../../store/store';
import actions from '../../../store/actions';

class AggregatedRecordBuilder extends React.Component<DeclarationBuilderProps<DataSourceField>> {

    constructor(props: DeclarationBuilderProps<DataSourceField>) {
        super(props);

        this.onResetAssignmentClick = this.onResetAssignmentClick.bind(this);
        this.getFieldDefinitions = this.getFieldDefinitions.bind(this);
        this.getField = this.getField.bind(this);
        this.getAssignmentContent = this.getAssignmentContent.bind(this);
        this.onCopyFieldClick = this.onCopyFieldClick.bind(this);
    }

    onCopyFieldClick(id: number) {
        let state: CopyAggregationModalState = {
            title: 'Copy Field',
            isOpen: true,
            error: null,
            copyAllFields: false,
            sourceNumber: (this.props.declarationBatchNumber as number).toString(),
            targetNumbers: new Set([(this.props.declarationBatchNumber || '').toString()]),
            sourceFieldId: id.toString(),
            targetFieldId: ''
        };

        // on opening modal
        store.dispatch({ type: actions.copyAggregationFieldModal.change, payload: state });
    }

    onResetAssignmentClick(location: ReportLogicLocation): void {

        this.props.onResetAssignmentClick(location, true);
    }

    getFieldDefinitions(): FieldDefinition[] {

        let location = { statementNumber: this.props.declarationBatchNumber as number } as ReportLogicLocation;

        let dataSourceId = aggregatedRecordFieldHelper.getDataSourceId(this.props.reportConfig, location);

        let dataSource = dataSourceHelper.getById(this.props.collections.dataSources, dataSourceId);

        return dataSource?.fieldDefinitions || [];
    }

    getField(location: ReportLogicLocation): DataSourceField {

        let fields = aggregatedRecordFieldHelper.getDeclarations(this.props.reportConfig, location);

        return aggregatedRecordFieldHelper.getDeclaration(fields, location);
    }

    getLocation(fieldId: number): ReportLogicLocation {

        let location = {} as ReportLogicLocation;

        location.statement = locationType.statement.aggregatedRecordField;
        location.statementNumber = this.props.declarationBatchNumber as number;
        location.fieldId = fieldId;

        return location;
    }

    onReorderConditions(field: DataSourceField, error?: string) {

        if (error && !field.errors.includes(error)) {
            field.errors.push(error);
        }
        let location = this.getLocation(field.fieldId);
        this.props.onDeclarationChange(location, field);
    }

    isSyncedAggregation(): boolean {
        let aggregation = this.props.reportConfig.aggregations.find(x => x.number === this.props.declarationBatchNumber);

        return aggregation ? typeHelper.isNumber(aggregation.syncedWithId) : false;
    }

    getAssignmentContent(definition: FieldDefinition, isReadOnly: boolean): JSX.Element {

        let location = this.getLocation(definition.id as number);

        let field = this.getField(location);

        return (
            <>
                <AssignmentBuilder
                    isReadOnly={isReadOnly}
                    location={location}
                    assignment={field.assignment}
                    fieldName={pseudocodeHelper.wrapInBrackets(definition.name)}
                    functions={this.props.reportConfig.userDefinedFunctions}
                    lookups={this.props.reportConfig.lookups}
                    dictionaries={this.props.reportConfig.dictionaries}
                    variables={this.props.reportConfig.variables}
                    dataSources={this.props.collections.dataSources}
                    reportFields={[]}
                    customDataSourceFields={null as any}
                    onAddAssignmentClick={this.props.onAddAssignmentClick}
                    onRemoveAssignmentClick={this.props.onRemoveAssignmentClick}
                    onResetAssignmentClick={this.onResetAssignmentClick}
                    onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                    onParenthesesChange={this.props.onParenthesesChange}
                    onAddConditionClick={this.props.onAddConditionClick}
                    onRemoveConditionClick={this.props.onRemoveConditionClick}
                    onComparisonTypeChange={this.props.onComparisonTypeChange}
                    onOperandClick={this.props.onOperandClick}
                    onCopyClick={this.props.onCopyClick}
                    onPasteClick={this.props.onPasteClick}
                    onValidateFieldClick={this.props.onValidateDeclarationClick}
                    allowPaste={this.props.allowPaste}
                    onReorderAssignmentConditions={(error) => { this.onReorderConditions(field, error); }} />

                <ErrorList errors={field.errors} className="mt-2" />
            </>
        );
    }

    render(): JSX.Element {

        let isReadOnly = this.props.isReadOnly || this.isSyncedAggregation();

        return (
            <>
                {
                    typeHelper.isNumber(this.props.declarationBatchNumber) &&
                    <>
                        <div className="section-heading">
                            <InfoIcon info={guidance.aggregatedRecord} />
                            <h4>Aggregated Record Fields</h4>
                        </div>

                        <ReportLogicList
                            isWaiting={this.props.isWaiting}
                            items={this.getFieldDefinitions()}
                            columns={getColumns(this.onCopyFieldClick, isReadOnly)}
                            getContent={x => this.getAssignmentContent(x, isReadOnly)}
                            getKey={x => x.id as number}
                            createHtmlId={x => reportConfigNavigation.buildAggregatedRecordFieldId(x.id as number)} />
                    </>
                }
            </>
        );
    }
}

export default AggregatedRecordBuilder;
