import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import FormGroup from '../../FormGroup/FormGroup';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import Modal from '../../Modal/Modal';
import PromiseButton from '../../PromiseButton';
import React from 'react';
import store from '../../../../store/store';
import urlHelper from '../../../../infrastructure/helpers/common/urlHelper';
import CopyIngestionConfigModalState from './CopyIngestionConfigModalState';

class CopyReportConfigModal extends React.Component<any, CopyIngestionConfigModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: 'Copy Ingestion Config',
            isOpen: false,
            error: null,
            id: null as any,
            name: ''
        };

        this.openListener = this.openListener.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.openListener);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    openListener(): void {
        let state = store.getState();

        if (state.action !== actions.copyIngestionConfigModal.open) {
            return;
        }

        this.setState(state.copyIngestionConfigModalState);
    }

    onNameChange(e: React.ChangeEvent<HTMLInputElement>): void {

        this.setState({ name: e.target.value });
    }

    onSaveClick(): Promise<any> {

        let error = '';

        if (!this.state.name) {

            error = 'Ingestion config name is required';

            this.setState({ error: error });
        }

        if (error) {
            return Promise.reject(error);
        }

        let url = urlHelper.buildRoute(['ingestion', this.state.id, 'copy']);

        return httpClient.post(url, { name: this.state.name }).then(this.onSaveSuccess);
    }

    onSaveSuccess(): void {
        this.setState({ isOpen: false });

        store.dispatch({ type: actions.copyIngestionConfigModal.saveSuccess, payload: null as any });
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    render(): JSX.Element {
        return (
            <>
                <Modal state={this.state} close={this.close}>

                    <FormGroup label="Name:" labelClassName="col-2" contentClassName="col-10">

                        <input type="text" className="form-control" value={this.state.name} onChange={this.onNameChange} />

                    </FormGroup>

                    <PromiseButton text="Copy" className="btn cb-btn" enableOnErrorOnly={false} task={this.onSaveClick} />
                </Modal>
            </>
        );
    }
}

export default CopyReportConfigModal;
