import { getColumns } from './DictionaryFieldBuilder.Grid';
import AssignmentBuilder from '../../Functions/Assignment/AssignmentBuilder';
import DeclarationBuilderProps from '../../Functions/Declaration/DeclarationBuilderProps';
import ErrorList from '../../Common/ErrorList/ErrorList';
import guidance from '../ReportConfigEditor/ReportConfigEditor.Guidance';
import InfoIcon from '../../Common/InfoIcon/InfoIcon';
import locationType from '../../../types/functions/Location/LocationType';
import pseudocodeHelper from '../../../infrastructure/helpers/functions/common/pseudocodeHelper';
import React from 'react';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import Assignment from '../../../types/functions/Assignment/Assignment';
import Dictionary from '../../../types/report/Dictionary';
import reorderHelper from '../../../infrastructure/helpers/common/reorderHelper';
import operandHelper from '../../../infrastructure/helpers/functions/operand/operandHelper';
import Operand from '../../../types/functions/Operand/Operand';
import operandType from '../../../infrastructure/constants/functions/operandType';
import DictionaryField from '../../../types/report/DictionaryField';
import ActionType from '../../../types/report/ActionType';
import dictionaryFieldHelper from '../../../infrastructure/helpers/functions/dictionaries/dictionaryFieldHelper';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';

interface State {
    showWarningModal: boolean;
    message: string;
    title: string;
    callbackFunction: () => void;
    dataType: string;
    number: number;
}

class DictionaryFieldBuilder extends React.Component<DeclarationBuilderProps<DictionaryField>, State> {

    constructor(props: DeclarationBuilderProps<DictionaryField>) {
        super(props);

        this.state = {
            showWarningModal: false,
            message: '',
            title: '',
            callbackFunction: this.onDataTypeChangeAccepted,
            dataType: '',
            number: 0
        }

        this.onResetAssignmentClick = this.onResetAssignmentClick.bind(this);
        this.getFields = this.getFields.bind(this);
        this.getField = this.getField.bind(this);
        this.getAssignmentContent = this.getAssignmentContent.bind(this);
        this.onReorderConditions = this.onReorderConditions.bind(this);
        this.onAddFieldClick = this.onAddFieldClick.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onDataTypeChange = this.onDataTypeChange.bind(this);
        this.onBusinessDescriptionChange = this.onBusinessDescriptionChange.bind(this);
        this.onRemoveFieldClick = this.onRemoveFieldClick.bind(this);
        this.onDataTypeChangeAccepted = this.onDataTypeChangeAccepted.bind(this);
    }

    onResetAssignmentClick(location: ReportLogicLocation): void {

        this.props.onResetAssignmentClick(location, true);
    }

    getFields(): DictionaryField[] {

        let dict = this.getDictionary();

        return dict.fields;
    }

    getField(location: ReportLogicLocation): DictionaryField {

        let dict = this.getDictionary();

        let field = dict.fields.find(f => f.number === location.fieldId) as DictionaryField;

        return field;
    }

    getDictionary(): Dictionary {

        return this.props.reportConfig.dictionaries.find(d => d.number === this.props.declarationBatchNumber) as Dictionary;
    }

    getLocation(fieldId: number): ReportLogicLocation {

        let location = {} as ReportLogicLocation;

        location.statement = locationType.statement.dictionaryField;
        location.statementNumber = this.props.declarationBatchNumber as number;
        location.fieldId = fieldId;

        return location;
    }

    onReorderConditions(field: DictionaryField, error?: string) {

        if (error && !field.errors.includes(error)) {
            field.errors.push(error);
        }

        let location = this.getLocation(field.number);
        this.props.onDeclarationChange(location, field);
    }

    getAssignmentContent(definition: DictionaryField, isReadOnly: boolean): JSX.Element {

        let location = this.getLocation(definition.number);

        let field = this.getField(location);

        return (
            <>
                <AssignmentBuilder
                    isReadOnly={isReadOnly}
                    location={location}
                    assignment={field.assignment}
                    fieldName={pseudocodeHelper.wrapInBrackets(definition.name)}
                    functions={this.props.reportConfig.userDefinedFunctions}
                    lookups={this.props.reportConfig.lookups}
                    dictionaries={this.props.reportConfig.dictionaries}
                    variables={this.props.reportConfig.variables}
                    dataSources={this.props.collections.dataSources}
                    reportFields={[]}
                    customDataSourceFields={null as any}
                    onAddAssignmentClick={this.props.onAddAssignmentClick}
                    onRemoveAssignmentClick={this.props.onRemoveAssignmentClick}
                    onResetAssignmentClick={this.onResetAssignmentClick}
                    onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                    onParenthesesChange={this.props.onParenthesesChange}
                    onAddConditionClick={this.props.onAddConditionClick}
                    onRemoveConditionClick={this.props.onRemoveConditionClick}
                    onComparisonTypeChange={this.props.onComparisonTypeChange}
                    onOperandClick={this.props.onOperandClick}
                    onCopyClick={this.props.onCopyClick}
                    onPasteClick={this.props.onPasteClick}
                    onValidateFieldClick={this.props.onValidateDeclarationClick}
                    allowPaste={this.props.allowPaste}
                    onReorderAssignmentConditions={(error) => { this.onReorderConditions(field, error); }} />

                <ErrorList errors={field.errors} className="mt-2" />
            </>
        );
    }

    onAddFieldClick(): void {

        this.props.onAddDeclarationClick((field: DictionaryField) => {

            let dictionary = this.props.reportConfig.dictionaries.find(d => d.number === this.props.declarationBatchNumber) as Dictionary;

            dictionaryFieldHelper(dictionary.number).changeReferences(ActionType.Add, dictionary, this.props.reportConfig);

            this.setFieldName(field);
        });
    }

    onRemoveFieldClick(number: number): void {

        let location = this.getLocation(number);

        this.props.onRemoveDeclarationClick(location, (fields) => {

            let dictionary = this.props.reportConfig.dictionaries.find(d => d.number === this.props.declarationBatchNumber) as Dictionary;

            let fieldIndex = dictionary.fields.findIndex(f => f.number === this.state.number);

            dictionaryFieldHelper(dictionary.number).changeReferences(ActionType.Remove, dictionary, this.props.reportConfig, fieldIndex, number);

            reorderHelper.resetNumbers(fields);
        });
    }

    setFieldName(field: DictionaryField): void {

        field.name = `field-${field.number}`;
    }

    onNameChange(name: string, number: number): void {

        let location = this.getLocation(number);

        this.props.onDeclarationChange(location, { name: name } as DictionaryField);
    }

    onBusinessDescriptionChange(businessDescription: string, number: number): void {

        let location = this.getLocation(number);

        this.props.onDeclarationChange(location, { businessDescription: businessDescription } as DictionaryField);
    }

    onDataTypeChange(dataType: string, number: number, assignment: Assignment): void {

        this.setState({
            showWarningModal: true,
            message: `Changing the data type requires a field value reset for all components which reference this parameter. Are you sure ?`,
            title: 'Data type change',
            callbackFunction: this.onDataTypeChangeAccepted,
            dataType,
            number
        });
    }

    onDataTypeChangeAccepted(): void {

        this.setState({ showWarningModal: false });

        let location = this.getLocation(this.state.number);

        let changes = {} as DictionaryField;

        let error = this.props.validateRemoveDeclaration(location);
        if (error) {
            changes.errors = [error];
        }
        else {
            changes.dataType = this.state.dataType;
        }

        this.props.onDeclarationChange(location, changes);

        let dictionary = this.props.reportConfig.dictionaries.find(d => d.number === this.props.declarationBatchNumber);

        if (dictionary) {

            let fieldIndex = dictionary.fields.findIndex(f => f.number === this.state.number);

            dictionaryFieldHelper(dictionary.number).changeReferences(ActionType.Change, dictionary, this.props.reportConfig, fieldIndex, this.state.number);
        }
    }

    render(): JSX.Element {

        return (
            <>
                {
                    typeHelper.isNumber(this.props.declarationBatchNumber) &&
                    <>
                        <div className="section-heading">
                            <InfoIcon info={guidance.dictionaryField} />
                            <h4>Fields</h4>
                        </div>

                        <ReportLogicList
                            isWaiting={this.props.isWaiting}
                            items={this.getFields()}
                            columns={getColumns(
                                this.onNameChange,
                                this.onDataTypeChange,
                                this.onBusinessDescriptionChange,
                                this.onRemoveFieldClick,
                                this.props.isReadOnly
                            )}
                            getContent={x => this.getAssignmentContent(x, this.props.isReadOnly)}
                            getKey={x => x.number}
                            createHtmlId={x => reportConfigNavigation.buildDictionaryFieldId(x.number)} />

                        <div className="row no-gutters mb-3">
                            <div className="col-auto">
                                <button
                                    onClick={this.onAddFieldClick}
                                    className="btn btn-light">+ Field</button>
                            </div>
                        </div>
                    </>
                }
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.state.callbackFunction}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title={this.state.title}
                        message={this.state.message} />
                }
            </>
        );
    }
}

export default DictionaryFieldBuilder;
