import RadioButtonGroup from '../../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import Selector from '../../../../types/report/Selector';
import SelectOption from '../../../../infrastructure/types/SelectOption';

interface Props {
    options: Selector[];
    value: number | null;
    onChange: (value: string) => void;
}

class LeiSetBuilder extends React.Component<Props> {

    getOptions(): SelectOption[] {
        const selectOptions: SelectOption[] = this.props.options.map((leiSelector) => {

            return {
                text: leiSelector.name,
                value: leiSelector.number.toString(),
            };
        });

        return selectOptions;
    }

    render(): JSX.Element {

        return (
            <div className="mt-3">
                <RadioButtonGroup
                    options={this.getOptions()}
                    name="lei-selectors-options"
                    value={(this.props.value === null || this.props.value === undefined) ? this.props.options[0].number.toString() : this.props.value.toString()}
                    onChange={this.props.onChange} />
            </div>
        );
    }
}

export default LeiSetBuilder;
