import React from 'react';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import Assignment from '../../../types/functions/Assignment/Assignment';
import locationType from '../../../types/functions/Location/LocationType';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import UserDefinedFunction from '../../../types/report/UserDefinedFunction';
import UserDefinedFunctionParameter from '../../../types/report/UserDefinedFunctionParameter';
import InfoIcon from '../../Common/InfoIcon/InfoIcon';
import DeclarationBuilderProps from '../../Functions/Declaration/DeclarationBuilderProps';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import guidance from '../ReportConfigEditor/ReportConfigEditor.Guidance';
import { getColumns } from './UserDefinedFunctionParameterBuilder.Grid';
import ErrorList from '../../Common/ErrorList/ErrorList';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';
import referenceValidator from '../../../infrastructure/helpers/functions/validator/referenceValidator';
import ActionType from '../../../types/report/ActionType';
import userDefinedFunctionsParameterHelper from '../../../infrastructure/helpers/functions/userDefinedFunctions/userDefinedFunctionsParameterHelper';
import userDefinedFunctionHelper from '../../../infrastructure/helpers/functions/userDefinedFunctions/userDefinedFunctionHelper';

interface State {
    showWarningModal: boolean,
    title: string,
    message: string,
    callbackFunction?: () => void;
    number: number,
    dataType: string,
}

class UserDefinedFunctionParameterBuilder extends React.Component<DeclarationBuilderProps<UserDefinedFunctionParameter>, State> {

    constructor(props: DeclarationBuilderProps<UserDefinedFunctionParameter>) {
        super(props);

        this.state = {
            showWarningModal: false,
            title: '',
            message: '',
            callbackFunction: () => { },
            number: 0,
            dataType: ''
        }

        this.getParameters = this.getParameters.bind(this);
        this.getField = this.getField.bind(this);
        this.onAddFieldClick = this.onAddFieldClick.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onDataTypeChange = this.onDataTypeChange.bind(this);
        this.onBusinessDescriptionChange = this.onBusinessDescriptionChange.bind(this);
        this.onRemoveFieldClick = this.onRemoveFieldClick.bind(this);
        this.onDataTypeChangeAccepted = this.onDataTypeChangeAccepted.bind(this);
        this.onAddOperatorConfirmed = this.onAddOperatorConfirmed.bind(this);
    }

    getParameters(): UserDefinedFunctionParameter[] {

        let func = this.getFunction();

        return func.parameters;
    }

    getField(location: ReportLogicLocation): UserDefinedFunctionParameter {

        let func = this.getFunction();

        let field = func.parameters.find(p => p.number === location.fieldId) as UserDefinedFunctionParameter;

        return field;
    }

    getFunction(): UserDefinedFunction {

        return this.props.reportConfig.userDefinedFunctions.find(d => d.number === this.props.declarationBatchNumber) as UserDefinedFunction;
    }

    getLocation(fieldId: number): ReportLogicLocation {

        let location = {} as ReportLogicLocation;

        location.statement = locationType.statement.userDefinedFunctionParameter;
        location.statementNumber = this.props.declarationBatchNumber as number;
        location.fieldId = fieldId;

        return location;
    }

    onAddFieldClick(): void {

        let func = this.getFunction();

        let title = '';

        let message = '';

        let referenceMessage = this.validateReferences();

        if (referenceMessage) {

            title = 'Add parameter';

            message = `Adding a Parameter requires a parameter to be added for all components which reference '${func.name}'. Are you sure ?`;
        }

        this.props.onAddDeclarationClick(this.onAddOperatorConfirmed, title, message);
    }

    onAddOperatorConfirmed(field: UserDefinedFunctionParameter): void {

        userDefinedFunctionsParameterHelper(0).changeReferences(ActionType.Add, this.getFunction(), this.props.reportConfig);

        this.setFieldName(field);
    }

    setFieldName(field: UserDefinedFunctionParameter): void {

        field.name = `param-${field.number}`;
    }

    validateReferences(): string {
        return referenceValidator.validateUserDefinedFunctionReference(this.props.declarationBatchNumber as number, this.props.reportConfig) || '';
    }

    onRemoveFieldClick(number: number): void {

        let func = this.getFunction();

        let title = '';

        let message = '';

        const references = userDefinedFunctionHelper.findUserDefinedFunctionReferences(func.number, this.props.reportConfig) || '';

        if (references.size) {

            title = 'Remove parameter';

            message = 'Removing the Parameter requires a parameter removing for components:\r\n';

            references.forEach((value, key) => {
                value.forEach((value) => {
                    message += `${key} - ${value}\r\n`;
                });
            });
            message += '\r\nAre you sure ?';
        }
        else {
            title = 'Remove item';
            message = 'Are you sure you want to delete this item? This action cannot be undone.';
        }

        let location = this.getLocation(number);

        //const validationError = await reportComponentBuildersHelper.validateAction(
        //    number,
        //    false,
        //    this.props.reportConfig.variables as IdNameAndNumber[],
        //    ComponentType.Variable,
        //    this.props.reportConfig.id || 0,
        //    ActionType.Remove);
        //if (!validationError) {
        this.props.onRemoveDeclarationClick(location, (userDefinedFunctions) => {

            let parameterIndex = func?.parameters.findIndex(p => p.number === number) as number;

            func?.parameters.splice(parameterIndex, 1);

            userDefinedFunctionsParameterHelper(func.number).changeReferences(ActionType.Remove, func, this.props.reportConfig, parameterIndex, number);

            //let updated = reorderHelper.rearangeElementsWhenRemoveOne(dictionaries) as ReportVariable[];

            //reorderHelper.changeReferences(
            //    this.props.reportConfig,
            //    updated,
            //    (operand: Operand, oldAndNewNumbers: Map<number, number>) => {
            //        if (operand.variableNumber && oldAndNewNumbers.has(operand.variableNumber)) {
            //            operand.variableNumber = oldAndNewNumbers.get(operand.variableNumber) as number;
            //        }
            //    }
            //);
        }, undefined, undefined, title, message);
        //}
        //else {
        //    this.props.onRemoveDeclarationClick(location, undefined, undefined, validationError);
        //}
    }

    onNameChange(name: string, number: number): void {

        let location = this.getLocation(number);

        this.props.onDeclarationChange(location, { name: name } as UserDefinedFunctionParameter);

        let func = this.getFunction();

        let parameterIndex = func?.parameters.findIndex(p => p.number === number) as number;

        userDefinedFunctionsParameterHelper(func.number).changeReferences(ActionType.Change, func, this.props.reportConfig, parameterIndex, number);
    }

    onBusinessDescriptionChange(businessDescription: string, number: number): void {

        let location = this.getLocation(number);

        this.props.onDeclarationChange(location, { businessDescription: businessDescription } as UserDefinedFunctionParameter);
    }

    onDataTypeChange(dataType: string, number: number, assignment: Assignment): void {

        this.setState({
            showWarningModal: true,
            message: `Changing the data type requires a parameter value reset for all components which reference this parameter. Are you sure ?`,
            title: 'Data type change',
            callbackFunction: this.onDataTypeChangeAccepted,
            dataType,
            number
        });
    }

    onDataTypeChangeAccepted(): void {

        this.setState({ showWarningModal: false });

        let location = this.getLocation(this.state.number);

        let changes = {} as UserDefinedFunctionParameter;

        let error = this.props.validateRemoveDeclaration(location);
        if (error) {
            changes.errors = [error];
        }
        else {
            changes.dataType = this.state.dataType;
        }

        this.props.onDeclarationChange(location, changes);

        let func = this.getFunction();

        let parameterIndex = func?.parameters.findIndex(p => p.number === this.state.number) as number;

        userDefinedFunctionsParameterHelper(func.number).changeReferences(ActionType.Clear, func, this.props.reportConfig, parameterIndex, this.state.number);
    }

    getContent(paramerer: UserDefinedFunctionParameter): JSX.Element {
        return (
            <>
                <ErrorList errors={paramerer.errors} className="mt-2" />
            </>);
    }

    render(): JSX.Element {

        return (
            <>
                {
                    typeHelper.isNumber(this.props.declarationBatchNumber) &&
                    <>
                        <div className="section-heading">
                            <InfoIcon info={guidance.userDefinedFunctionParameters} />
                            <h4>Parameters</h4>
                        </div>

                        <ReportLogicList
                            isWaiting={this.props.isWaiting}
                            items={this.getParameters()}
                            columns={getColumns(
                                this.onNameChange,
                                this.onDataTypeChange,
                                this.onBusinessDescriptionChange,
                                this.onRemoveFieldClick,
                                this.props.isReadOnly
                            )}
                            getContent={this.getContent}
                            getKey={x => x.number}
                            createHtmlId={x => reportConfigNavigation.buildFunctionParameterId(x.number)} />

                        <div className="row no-gutters mb-3">
                            <div className="col-auto">
                                <button
                                    onClick={this.onAddFieldClick}
                                    className="btn btn-light">+ Parameter</button>
                            </div>
                        </div>
                    </>
                }
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.state.callbackFunction}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title={this.state.title}
                        message={this.state.message} />
                }
            </>
        );
    }
}

export default UserDefinedFunctionParameterBuilder;
