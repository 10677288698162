import './FilePicker.scss';
import { onEnter, onOver, onLeave, onDrop, getDropAreaStyles, getDropAreaContentStyles } from './FilePicker.Helpers';
import FileInput from './FileInput';
import React from 'react';
import validate from './FilePicker.Validation';

interface FilePickerProps {
    uniqueName: string;
    maxFileCount: number;
    maxFileSizeInMb: number;
    allowedExtensions: string[];
    autoReset: boolean;
    height: number;
    onPicked: (files: FileList, error: string) => void;
}

interface FilePickerState {
    pickedFiles: string | null;
}

class FilePicker extends React.Component<FilePickerProps, FilePickerState> {

    constructor(props: FilePickerProps) {
        super(props);

        this.state = { pickedFiles: null };

        this.handlePickedFiles = this.handlePickedFiles.bind(this);
        this.onFileDropped = this.onFileDropped.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    handlePickedFiles(fileList: FileList): void {

        let error = validate(fileList, this.props.maxFileCount, this.props.allowedExtensions, this.props.maxFileSizeInMb);

        let files = error ? null : fileList;

        let pickedFiles = error ? null : Array.from(files as FileList).map(f => f.name).join(', ');

        this.setState({ pickedFiles });

        this.props.onPicked(files as FileList, error as string);
    }

    onFileDropped(e: React.DragEvent<HTMLDivElement>): void {

        onDrop(e);

        this.handlePickedFiles(e.dataTransfer.files);
    }

    onInputChange(e: React.ChangeEvent<HTMLInputElement>): void {

        this.handlePickedFiles(e.target.files as FileList);

        if (this.props.autoReset) {

            this.setState({ pickedFiles: null });

            e.target.value = '';
        }
    }

    render(): JSX.Element {

        return (
            <div
                className="drop-area"
                style={getDropAreaStyles(this.props.height)}
                onDragEnter={onEnter}
                onDragOver={onOver}
                onDragLeave={onLeave}
                onDrop={this.onFileDropped}>

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <div className="drop-area-content" style={getDropAreaContentStyles(this.props.height)}>
                            <div>Drag and drop or</div>
                            <FileInput
                                uniqueName={this.props.uniqueName}
                                maxFileCount={this.props.maxFileCount}
                                allowedExtensions={this.props.allowedExtensions}
                                onChange={this.onInputChange} />
                        </div>
                    </div>
                    <div className="col"></div>
                </div>
                <div className="mt-2">
                    <span>{this.state.pickedFiles}</span>
                </div>
            </div>
        );
    }
}

export default FilePicker;
