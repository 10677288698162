import bespokeValidator from './bespokeValidator';
import clear from '../../common/array/clear';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import declarationValidator from './declarationValidator';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import ReportCase from '../../../../types/report/ReportCase';
import ReportCaseField from '../../../../types/report/ReportCaseField';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

interface CaseFieldError {
    caseNumber: number;
    fieldId: number;
}

const validateField = (field: ReportCaseField, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): void => {

    clear(field.errors);

    if (field.isBespoke) {

        let error = bespokeValidator.validateAssignment(field.bespokeAssignment);
        if (error) {
            field.errors.push(error);
        }

        return;
    }

    let definition = reportFields.find(x => x.id === field.fieldId) as ReportFieldDefinition;

    if (definition) {

        declarationValidator.validate(field, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, definition.dataType, field.fieldId, true);
    }
};

const validate = (functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], cases: ReportCase[], dataSources: IngestionConfig[], reportFields: ReportFieldDefinition[], customDataSourceFields: CustomDataSourceFields): CaseFieldError[] => {

    let errors: CaseFieldError[] = [];

    cases.forEach(c => {

        c.fields.forEach(f => {

            validateField(f, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields);

            if (f.errors.length > 0) {
                errors.push({ caseNumber: c.number, fieldId: f.fieldId });
            }
        });
    });

    return errors;
};

const enrichmentValidator = {
    validate,
    validateField
};

export default enrichmentValidator;