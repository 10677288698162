import React from 'react';
import copyArray from '../../../../infrastructure/helpers/common/copyArray';
import operandDisplayHelper from '../../../../infrastructure/helpers/functions/operand/operandDisplayHelper';
import userDefinedFunctionHelper from '../../../../infrastructure/helpers/functions/userDefinedFunctions/userDefinedFunctionHelper';
import Lookup from '../../../../types/functions/Lookup';
import Operand from '../../../../types/functions/Operand/Operand';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportVariable from '../../../../types/report/ReportVariable';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import getArgument from './getArgument';
import UserDefinedFunctionParameter from '../../../../types/report/UserDefinedFunctionParameter';
import Dictionary from '../../../../types/report/Dictionary';

const addComma = (i: number, args: Operand[]): boolean => {
    return (
        (i >= 0) &&
        (i < args.length - 1)
    );
};

const buildArgumentDescription = (field: UserDefinedFunctionParameter): string => {

    return `${field.name}: ${field.dataType}`;
};

const getUserDefinedFunctionOperand = (
    operand: Operand,
    operandNumber: number,
    argumentIndexes: number[],
    functions: UserDefinedFunction[],
    lookups: Lookup[],
    dictionaries: Dictionary[],
    variables: ReportVariable[],
    dataSources: IngestionConfig[],
    reportFields: ReportFieldDefinition[],
    customDataSourceFields: CustomDataSourceFields,
    selectorNumber: number | null,
    onClick: (operandNumber: number, argumentIndexes: number[], event: React.MouseEvent<HTMLElement>) => void,
    disabled: boolean
): JSX.Element => {

    let func = userDefinedFunctionHelper.getByNumber(functions, operand.functionNumber);

    let tooltips = func.parameters.map(buildArgumentDescription);

    let details = operandDisplayHelper.getOperandDetails(operand, functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, null);

    let args: JSX.Element[] = (operand.arguments as Operand[])
        .map((x, i, arr) => getArgument(x, operandNumber, copyArray(argumentIndexes).concat([i]), tooltips[i], functions, lookups, dictionaries, variables, dataSources, reportFields, customDataSourceFields, selectorNumber, onClick, i, addComma(i, arr), disabled));

    return (
        <>
            <button className="transparent-button code function" onClick={(event) => onClick(operandNumber, argumentIndexes, event)} disabled={disabled} >
                {details.displayText}
            </button>

            <span className="code">(</span>

            {args}

            <span className="code">)</span>
        </>
    );
};

export default getUserDefinedFunctionOperand;