import { reportType } from '../../../constants/reportType';
import bespokeValidator from './bespokeValidator';
import clear from '../../common/array/clear';
import conditionValidator from './conditionValidator';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import ReportCase from '../../../../types/report/ReportCase';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportVariable from '../../../../types/report/ReportVariable';
import stringHelper from '../../common/stringHelper';
import typeHelper from '../../common/typeHelper';
import uniquenessValidator from './uniquenessValidator';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

const maxNameCharacters = 50;

const isRegularCase = ($case: ReportCase): boolean => !$case.isSystemGenerated;
const isAggregatedCase = ($case: ReportCase): boolean => typeHelper.isNumber($case.aggregationNumber) && $case.isSystemGenerated;

const validateCase = ($case: ReportCase, functions: UserDefinedFunction[], lookups: Lookup[], dictionaries: Dictionary[], variables: ReportVariable[], dataSources: IngestionConfig[], customDataSourceFields: CustomDataSourceFields, atLeastOneConditionRequired: boolean): void => {

    clear($case.errors);

    let metadataError = validateMetadata($case);
    if (metadataError) {
        $case.errors.push(metadataError);
    }

    if (typeHelper.isNumber($case.aggregationNumber) && !$case.isSystemGenerated) {

        $case.errors.push('Aggregated case should be system generated');
    }

    if (!$case.isBespoke) {

        Array.prototype.push.apply($case.errors, conditionValidator.validate($case.condition, functions, lookups, dictionaries, variables, dataSources, [], customDataSourceFields, atLeastOneConditionRequired, null));
        return;
    }

    let bespokeError = bespokeValidator.validateCondition($case.bespokeCondition);
    if (bespokeError) {
        $case.errors.push(bespokeError);
    }
};

const validateMetadata = ($case: ReportCase): string | null => {

    if (stringHelper.isEmpty($case.name)) {
        return 'Reporting case name is required';
    }

    if ($case.name.length > maxNameCharacters) {
        return `Reporting case name max character count is ${maxNameCharacters}`;
    }

    return null;
};

const validate = (config: ReportConfig, dataSources: IngestionConfig[], customDataSourceFields: CustomDataSourceFields): string | null => {

    if (config.type === reportType.accuracy) {

        return null;
    }

    if (config.cases.length === 0) {

        return 'At least one case is required';
    }

    let regularCases = config.cases.filter(isRegularCase);
    let aggregatedCases = config.cases.filter(isAggregatedCase);

    if (regularCases.length === 0 && aggregatedCases.length === 0) {

        return 'At least one regular (SystemGenerated=No) report case is required';
    }

    config.cases.forEach(x => validateCase(x, config.userDefinedFunctions, config.lookups, config.dictionaries, config.variables, dataSources, customDataSourceFields, isRegularCase(x) && regularCases.length > 1));

    uniquenessValidator.validate(config.cases, c => c.name, 'Reporting case', 'name');

    let valid = config.cases.every(x => x.errors.length === 0);

    return valid ? null : 'Invalid case(s)';
};

const caseValidator = {
    validate
};

export default caseValidator;
