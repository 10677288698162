import FieldDefinition from '../../../types/common/FieldDefinition';
import groupBy from '../../../infrastructure/helpers/common/array/groupBy';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import dataType from '../../../infrastructure/constants/dataType';

const nameMaxCharCount = 100;
const commentMaxCharCount = 2000;

const validateOne = (definition: FieldDefinition): string | null => {

    if (stringHelper.isEmpty(definition.name)) {
        return 'Field definiton name is required';
    }

    if (definition.name.length > nameMaxCharCount) {
        return `Field definiton name max character count is ${nameMaxCharCount}`;
    }

    if (typeHelper.isString(definition.comment) && definition.comment.length > commentMaxCharCount) {
        return `Field definiton comment max character count is ${commentMaxCharCount}`;
    }

    return null;
};

const validate = (definitions: FieldDefinition[], isTransposed: boolean | null): string | null => {

    if (definitions.length === 0) {
        return 'At least one field is required';
    }

    let errors = definitions.map(validateOne).filter(x => x);
    if (errors.length > 0) {
        return errors[0];
    }

    let definitionsAlternativeNames = definitions.filter(field => field.alternativeNames)
        .flatMap(field => field.alternativeNames!);

    let definitionsMainNames = definitions.flatMap(field => field.name);

    let alldefinitionsNames = definitionsMainNames.concat(definitionsAlternativeNames);

    let duplicateName = groupBy(alldefinitionsNames, x => x, x => x).filter(x => x.group.length > 1).map(g => g.key);

    if (duplicateName.length > 0) {
        return `Duplicate field definition names: ${duplicateName.join(', ')}`;
    }

    if (isTransposed) {
        if (definitions.length !== 2) {
            return 'Exactly two fields are required for transposed configs';
        }

        if (!definitions[0].name.toLowerCase().endsWith('key')) {
            return 'First field must end with `key` for transposed configs';
        }

        if (definitions[0].type !== dataType.string) {
            return 'First field type must be `String` for transposed configs';
        }

        if (!definitions[1].name.toLowerCase().endsWith('value')) {
            return 'Second field must end with `value` for transposed configs';
        }
    }

    return null;
};

export default validate;
