import Assignment from '../../../../types/functions/Assignment/Assignment';
import Condition from '../../../../types/functions/Condition/Condition';
import conditionHelper from './../condition/conditionHelper';
import copyObject from '../../common/copyObject';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from './../operand/operandHelper';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import typeHelper from '../../common/typeHelper';

const getInner = (assignment: Assignment): Assignment | null => {

    if (typeHelper.isObject(assignment.elseIf)) {
        return assignment.elseIf;
    }

    if (typeHelper.isObject(assignment.else)) {
        return assignment.else;
    }

    return null;
};

const find = (assignment: Assignment, predicate: (x: Assignment) => boolean): Assignment | null => {
    do {
        if (predicate(assignment)) {
            return assignment;
        }

        assignment = getInner(assignment) as Assignment;
    }
    while (assignment != null);

    return null;
};

const getPrevious = (assignment: Assignment, number: number): Assignment | null => {

    return find(assignment, x => x.elseIf?.number === number || x.else?.number === number);
};

const getNext = (assignment: Assignment, number: number): Assignment | null => {

    let target = getByNumber(assignment, number);

    if (!typeHelper.isObject(target)) {
        return null;
    }

    if (typeHelper.isObject(target.elseIf)) {
        return target.elseIf;
    }

    if (typeHelper.isObject(target.else)) {
        return target.else;
    }

    return null;
};

const getLast = (assignment: Assignment): Assignment | null => {

    return find(assignment, x => !typeHelper.isObject(x.elseIf) && !typeHelper.isObject(x.else));
};

const asCollection = (assignment: Assignment): Assignment[] => {

    let collection: Assignment[] = [];

    if (!typeHelper.isObject(assignment)) {
        return collection;
    }

    do {
        collection.push(assignment);
        assignment = getInner(assignment) as Assignment;
    }
    while (assignment != null);

    return collection;
};

const getByNumber = (assignment: Assignment, number: number): Assignment => {

    return find(assignment, x => x.number === number) as Assignment;
};

const add = (assignment: Assignment, number: number, doNotPopulate: boolean): void => {

    let empty = getEmpty(doNotPopulate);

    let last = getLast(assignment) as Assignment;

    let target = getByNumber(assignment, number) as Assignment;

    let previous = getPrevious(assignment, number) as Assignment;

    let isFirst = !typeHelper.isObject(previous);
    let isLast = target.number === last.number;

    if (isFirst) {

        if (isLast) {
            target.condition = conditionHelper.add(assignment.condition as Condition, null);
            target.else = empty;
        }
        else {
            assignment.elseIf = copyObject(assignment);
            assignment.else = null;
            assignment.doNotPopulate = doNotPopulate;
            assignment.condition = conditionHelper.add(empty.condition as Condition, null);
            assignment.value = operandHelper.getEmpty();
        }
    }
    else {

        empty.condition = conditionHelper.add(empty.condition as Condition, null);

        previous.elseIf = empty;
        previous.else = null;

        if (isLast) {
            empty.else = last;
        }
        else {
            empty.elseIf = target;
        }
    }

    asCollection(assignment).forEach((a, i) => a.number = i + 1);
};

const remove = (assignment: Assignment, number: number): void => {

    let previous = getPrevious(assignment, number) as Assignment;

    if (!typeHelper.isObject(previous)) {

        let target = getByNumber(assignment, number) as Assignment;

        target.condition = null;
        target.else = null;

        return;
    }

    let removed = getByNumber(assignment, number);

    let next = getNext(assignment, number) as Assignment;

    previous.elseIf = null;
    previous.else = null;

    if (typeHelper.isObject(removed.elseIf)) {
        previous.elseIf = next;
    } else if (typeHelper.isObject(removed.else)) {
        previous.else = next;
    }
};

const getValue = (assignment: Assignment, location: ReportLogicLocation, recursive: boolean): Operand => {

    let target = getByNumber(assignment, location.assignmentNumber);

    if (!recursive) {
        return target.value;
    }

    return operandHelper.getOperand(target.value, location);
};

const getEmpty = (doNotPopulate: boolean): Assignment => {
    return {
        number: 0,
        condition: null,
        value: operandHelper.getEmpty(),
        doNotPopulate: doNotPopulate,
        elseIf: null,
        else: null
    };
};

const removeOnlyValues = (doNotPopulate: boolean, assignment: Assignment): Assignment => {

    assignment.value = operandHelper.getEmpty();

    if (assignment.else) {
        assignment.else.value = operandHelper.getEmpty();
    }
    else if (assignment.elseIf) {
        assignment.elseIf = removeOnlyValues(doNotPopulate, assignment.elseIf);
    }

    return assignment;
};

const assignmentHelper = {
    asCollection,
    getByNumber,
    add,
    remove,
    getValue,
    getEmpty,
    removeOnlyValues,
};

export default assignmentHelper;
