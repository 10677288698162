import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Dictionary from '../../../../types/report/Dictionary';
import DictionaryField from '../../../../types/report/DictionaryField';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import clear from '../../common/array/clear';
import stringHelper from '../../common/stringHelper';
import declarationValidator from './declarationValidator';
import uniquenessValidator from './uniquenessValidator';

const maxNameCharacters = 50;

const validateField = (field: DictionaryField, functions: UserDefinedFunction[], dictionaries: Dictionary[], dataSources: IngestionConfig[]): void => {

    clear(field.errors);

    if (stringHelper.isEmpty(field.name)) {
        field.errors.push('Field name is required');
    }

    if (field.name.length > maxNameCharacters) {
        field.errors.push(`Parameter name max character count is ${maxNameCharacters}`);
    }

    declarationValidator.validate(field, functions, [], dictionaries, [], dataSources, [], null as any, field.dataType, null, true);
};

const validateDictionary = (dictionary: Dictionary, functions: UserDefinedFunction[], dictionaries: Dictionary[], dataSources: IngestionConfig[]): void => {

    clear(dictionary.errors);

    let metadataError = validateMetadata(dictionary);
    if (metadataError) {
        dictionary.errors.push(metadataError);
    }

    dictionary.fields.forEach(x => validateField(x, functions, dictionaries, dataSources));
};

const validate = (dictionaries: Dictionary[], functions: UserDefinedFunction[], dataSources: IngestionConfig[]): string | null => {

    dictionaries.forEach(x => validateDictionary(x, functions, dictionaries, dataSources));

    uniquenessValidator.validate(dictionaries, a => a.name, 'Dictionary', 'name');

    let valid = dictionaries.every(x => x.errors.length === 0 && x.fields.every(f => f.errors.length === 0));

    return valid ? null : 'Invalid dictionaries(s)';
};

const validateMetadata = (dictionary: Dictionary): string | null => {

    if (stringHelper.isEmpty(dictionary.name)) {
        return 'Dictionary name is required';
    }

    if (dictionary.name.length > maxNameCharacters) {
        return `Dictionary name max character count is ${maxNameCharacters}`;
    }

    if (dictionary.fields.length === 0) {
        return 'At least one field is required';
    }

    return null;
};

const dictionaryValidator = {
    validate,
    validateField
};

export default dictionaryValidator;
