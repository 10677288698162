import httpClient from '../helpers/common/httpClient';
import identityStorage from '../authorization/identityStorage';
import ReportConfigLockResponse from '../../types/report/ReportConfigLockResponse';
import reportConfigLockResult from '../constants/reportConfigLockResult';
import typeHelper from '../helpers/common/typeHelper';
import urlHelper from '../helpers/common/urlHelper';
import writeUserRoles from '../constants/identity/writeUserRoles';
import ReportConfigLock from '../types/Functions/ReportConfigLock';
import ReportConfig from '../../types/report/ReportConfig';
import ReportConfigComponentLock from '../../types/report/ReportConfigComponentLock';
import ComponentType from '../../types/report/ComponentType';

const key = 'LOCKED_REPORT_CONFIG_ID';

const clear = (): void => {
    localStorage.removeItem(key);
};

const isAnyComponentLocked = async (reportConfigComponentLock: ReportConfigComponentLock): Promise<ReportConfigLockResponse> => {

    let identity = identityStorage.get();

    if (!writeUserRoles.includes(identity.userRole)) {

        return { result: reportConfigLockResult.lockingNotAllowed, lockedBy: '' };
    }

    let url = urlHelper.buildRoute(['report', 'is-any-locked']);

    const response = await httpClient.post(url, reportConfigComponentLock);
    return response.data;
};

const isLocked = async (id: number, componentType: ComponentType, reportConfigId: number) => {

    return isAnyComponentLocked({ componentType, reportConfigId, componentsIds: [id] });
}

const isReportConfigUsedByOtherUser = async (reportConfigId: number): Promise<string> => {

    let identity = identityStorage.get();

    if (!writeUserRoles.includes(identity.userRole)) {

        return  reportConfigLockResult.lockingNotAllowed;
    }

    let url = urlHelper.buildRoute(['report', reportConfigId, 'is-used-by-users']);

    const response = await httpClient.post(url, {});
    return response.data;
};

const lock = (id: number): Promise<ReportConfigLockResponse> => {

    let identity = identityStorage.get();

    if (!writeUserRoles.includes(identity.userRole)) {

        return Promise.resolve({ result: reportConfigLockResult.lockingNotAllowed, lockedBy: null as any });
    }

    let url = urlHelper.buildRoute(['report', id, 'lock']);

    return new Promise<ReportConfigLockResponse>(resolve => {
        return httpClient.post(url).then(response => {
            localStorage.setItem(key, id.toString());
            resolve(response.data);
        });
    });
};

const getMinutesWhenLockWillExpire = async (reportConfigComponentLock: ReportConfigComponentLock) => {

    let url = urlHelper.buildRoute(['report', 'lock-expire']);

    let identity = identityStorage.get();

    if (!writeUserRoles.includes(identity.userRole)) {

        return { result: reportConfigLockResult.lockingNotAllowed, lockedBy: '' };
    }
    const response = await httpClient.post(url, reportConfigComponentLock);
    return response.data;
}

const lockValidationConfigComponent = async (reportConfig: ReportConfig, reportConfigLock: ReportConfigLock): Promise<ReportConfigLockResponse> => {

    reportConfigLock.lockedById = 'noone';
    let identity = identityStorage.get();

    if (!writeUserRoles.includes(identity.userRole)) {

        return { result: reportConfigLockResult.lockingNotAllowed, lockedBy: '' };
    }

    let url = urlHelper.buildRoute(['report', 'lock']);

    const response = await httpClient.post(url, { reportConfig, reportConfigLock });
    return response.data;
};

const unlockComponents = (id: number): void => {

    let identity = identityStorage.get();

    if (writeUserRoles.includes(identity.userRole)) {

        let url = urlHelper.buildRoute(['report/unlock-components', id]);

        httpClient.put(url, {});
    }
}

const unlock = (): Promise<any> => {

    let identity = identityStorage.get();

    if (!writeUserRoles.includes(identity.userRole)) {

        return Promise.resolve(false);
    }

    let id = localStorage.getItem(key);

    if (!typeHelper.isString(id)) {

        return Promise.resolve(false);
    }

    let url = urlHelper.buildRoute(['report', id, 'unlock']);

    clear();

    return httpClient.post(url);
};

const lockManager = {
    clear,
    lock,
    isLocked,
    lockValidationConfigComponent,
    unlock,
    getMinutesWhenLockWillExpire,
    isAnyComponentLocked,
    isReportConfigUsedByOtherUser,
    unlockComponents
};
export default lockManager;
