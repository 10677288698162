import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';

const validate = (comment: string): string | null => {

    if (stringHelper.isEmpty(comment)) {

        return 'Comment is required';
    }

    return null;
};

export { validate };
