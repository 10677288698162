import { comparisonTypeOptions, logicalOperatorOptions } from './ConditionItem.Helpers';
import { getOperandContent } from './ConditionItem.Rendering';
import { ReactComponent as RemoveIcon } from '../../../Common/BootstrapIcons/trash.svg';
import Condition from '../../../../types/functions/Condition/Condition';
import conditionHelper from '../../../../infrastructure/helpers/functions/condition/conditionHelper';
import copyObject from '../../../../infrastructure/helpers/common/copyObject';
import CustomDataSourceFields from '../../../../types/report/CustomDataSourceFields';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import locationType from '../../../../types/functions/Location/LocationType';
import logicalOperator from '../../../../infrastructure/constants/functions/logicalOperator';
import Lookup from '../../../../types/functions/Lookup';
import ParenthesesDisplay from '../ParenthesesDisplay/ParenthesesDisplay';
import ParenthesesEditor from '../ParenthesesEditor/ParenthesesEditor';
import parenthesesType from '../../../../infrastructure/constants/functions/parenthesesType';
import React from 'react';
import ReportFieldDefinition from '../../../../types/report/ReportFieldDefinition';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import ReportVariable from '../../../../types/report/ReportVariable';
import SelectList from '../../../Common/SelectList/SelectList';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import operandHelper from '../../../../infrastructure/helpers/functions/operand/operandHelper';
import DraggableItem from '../../../Common/DraggableItem/DraggableItem';
import Dictionary from '../../../../types/report/Dictionary';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';

interface ConditionItemProps {
    isReadOnly: boolean;
    isFirst: boolean;
    isLast: boolean;
    allowCopy: boolean;
    allwaysAllowDelete: boolean;
    keyword: string;
    functions: UserDefinedFunction[],
    lookups: Lookup[];
    dictionaries: Dictionary[];
    variables: ReportVariable[];
    dataSources: IngestionConfig[];
    reportFields: ReportFieldDefinition[];
    customDataSourceFields: CustomDataSourceFields;
    location: ReportLogicLocation;
    condition: Condition;
    clauseNumber?: number;
    onOperandClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onComparisonTypeChange: (location: ReportLogicLocation, value: string, event: React.ChangeEvent<HTMLElement>) => void;
    onRemoveClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onLogicalOperatorChange: (location: ReportLogicLocation, operator: string, event: React.ChangeEvent<HTMLElement>) => void;
    onParenthesesChange: (location: ReportLogicLocation, count: number, type: string, event?: React.MouseEvent<HTMLElement>) => void;
    onAddConditionClick: (location: ReportLogicLocation, operator: string | null, event?: React.MouseEvent<HTMLElement>) => void;
    onCopyClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    onPasteClick: (location: ReportLogicLocation, event?: React.MouseEvent<HTMLElement>) => void;
    allowPaste: (location: ReportLogicLocation) => boolean;
    onClearClick?: (event: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLElement>) => Promise<boolean>,
    onReorderConditions?: (dragIdentifier: number, dropIdentifier: number, error?: string) => void;
}

const openParentheses = '(';
const closeParentheses = ')';

class ConditionItem extends React.Component<ConditionItemProps> {

    constructor(props: ConditionItemProps) {
        super(props);

        this.onOperandClick = this.onOperandClick.bind(this);
        this.onComparisonTypeChange = this.onComparisonTypeChange.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onAddOrConditionClick = this.onAddOrConditionClick.bind(this);
        this.onAddAndConditionClick = this.onAddAndConditionClick.bind(this);
        this.onLogicalOperatorChange = this.onLogicalOperatorChange.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.onPasteClick = this.onPasteClick.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
        this.onReorderConditions = this.onReorderConditions.bind(this);
    }

    onOperandClick(operandNumber: number, argumentIndexes: number[], event: React.MouseEvent<HTMLElement>): void {

        let location = copyObject(this.props.location);

        location.pieceOfCode = locationType.pieceOfCode.conditionOperand;
        location.operandNumber = operandNumber;
        location.argumentIndexes = argumentIndexes;

        this.props.onOperandClick(location, event);
    }

    onComparisonTypeChange(value: string, event: React.ChangeEvent<HTMLElement>): void {

        this.props.onComparisonTypeChange(this.props.location, value, event);
    }

    onRemoveClick(event: React.MouseEvent<HTMLElement>): void {
        this.props.onRemoveClick(this.props.location, event);
    }

    onAddOrConditionClick(event: React.MouseEvent<HTMLElement>): void {
        this.props.onAddConditionClick(this.props.location, logicalOperator.or, event);
    }

    onAddAndConditionClick(event: React.MouseEvent<HTMLElement>): void {
        this.props.onAddConditionClick(this.props.location, logicalOperator.and, event);
    }

    onLogicalOperatorChange(operator: string, event: React.ChangeEvent<HTMLElement>): void {
        this.props.onLogicalOperatorChange(this.props.location, operator, event);
    }

    onCopyClick(event: React.MouseEvent<HTMLElement>): void {

        let location = copyObject(this.props.location);

        location.conditionNumber = 1;

        this.props.onCopyClick(location, event);
    }

    onPasteClick(event: React.MouseEvent<HTMLElement>): void {

        let location = copyObject(this.props.location);

        location.conditionNumber = 1;

        this.props.onPasteClick(location, event);
    }

    async onClearClick(e: React.MouseEvent<HTMLElement>): Promise<void> {
        const isLocked = this.props.onClearClick && await this.props.onClearClick(e);
        if (isLocked) {
            e.stopPropagation();
        }
        else {
            this.props.condition.operand1 = operandHelper.getEmpty();
            this.props.condition.operand2 = operandHelper.getEmpty();

            this.forceUpdate();
        }
    }

    onReorderConditions(dragNumber: number, dropNumber: number, event: React.DragEvent<HTMLDivElement>, dragSpecialData?: string, dropSpecialData?: string) {
        let parsedDragData = dragSpecialData && JSON.parse(dragSpecialData);
        let parsedDropData = dropSpecialData && JSON.parse(dropSpecialData);

        if (parsedDragData.keyword !== parsedDropData.keyword || parsedDragData.clauseNumber !== parsedDropData.clauseNumber) {
            event.preventDefault();
            if (this.props.onReorderConditions) {
                this.props.onReorderConditions(dragNumber, dropNumber, 'Cannot reorder between different clauses');
            }
        }
        else if (this.props.onReorderConditions) {
            this.props.onReorderConditions(dragNumber, dropNumber);
        }
        else {
            event.preventDefault();
        }
    }

    getCondition() {
        return <div>
            <div>
                <ParenthesesEditor
                    count={this.props.condition.openParentheses}
                    character={openParentheses}
                    onChange={(count, event) => { this.props.onParenthesesChange(this.props.location, count, parenthesesType.open, event) }}
                    isReadOnly={this.props.isReadOnly} />
            </div>
            <div>
                <ParenthesesDisplay
                    count={this.props.condition.openParentheses}
                    character={openParentheses} />
            </div>
            <div>
                {getOperandContent(this.props.condition.operand1, 1, [], null, this.props.functions, this.props.lookups, this.props.dictionaries, this.props.variables, this.props.dataSources, this.props.reportFields, this.props.customDataSourceFields, this.props.condition.operand1.selectorNumber, this.onOperandClick, this.props.isReadOnly)}
            </div>
            <div>
                <SelectList
                    options={comparisonTypeOptions}
                    value={this.props.condition.comparisonType}
                    className="code comparison"
                    onChange={this.onComparisonTypeChange}
                    disabled={this.props.isReadOnly} />
            </div>
            <div>
                {getOperandContent(this.props.condition.operand2, 2, [], null, this.props.functions, this.props.lookups, this.props.dictionaries, this.props.variables, this.props.dataSources, this.props.reportFields, this.props.customDataSourceFields, this.props.condition.operand2.selectorNumber, this.onOperandClick, this.props.isReadOnly)}
            </div>
            <div>
                <ParenthesesDisplay
                    count={this.props.condition.closeParentheses}
                    character={closeParentheses} />
            </div>
            <div>
                <ParenthesesEditor
                    count={this.props.condition.closeParentheses}
                    character={closeParentheses}
                    onChange={(count, event) => this.props.onParenthesesChange(this.props.location, count, parenthesesType.close, event)}
                    isReadOnly={this.props.isReadOnly} />
            </div>
        </div>
    }

    render() {
        let logicalOperator = conditionHelper.getLogicalOperator(this.props.condition);

        return (
            <div>
                {
                    this.props.isFirst &&
                    <div className='line-height-1 padding-top-2px'>{this.props.keyword}</div>
                }
                {this.props.keyword === 'IF' || this.props.keyword === 'ELSE IF' ?
                    <DraggableItem identifier={this.props.condition.number} onDrop={this.onReorderConditions} specialData={JSON.stringify({ keyword: this.props.keyword, clauseNumber: this.props.clauseNumber })}>
                        {this.getCondition()}
                    </DraggableItem>
                    : this.getCondition()
                }
                <div>
                    {
                        typeHelper.isString(logicalOperator) &&
                        <SelectList
                            options={logicalOperatorOptions}
                            value={logicalOperator as string}
                            className="code comparison"
                            onChange={this.onLogicalOperatorChange}
                            disabled={this.props.isReadOnly} />
                    }
                    {
                        this.props.isLast &&
                        !this.props.isReadOnly &&
                        <>
                            <button className="helper-btn min-width-40px padding-top-2px" onClick={this.onAddOrConditionClick}>&#43; Or</button>
                            <button className="helper-btn min-width-40px padding-top-2px" onClick={this.onAddAndConditionClick}>&#43; And</button>
                            {
                                this.props.allowCopy &&
                                <>
                                    <button className="helper-btn" onClick={this.onCopyClick}>Copy</button>
                                    {
                                        this.props.allowPaste(this.props.location) &&
                                        <button className="helper-btn" onClick={this.onPasteClick}>Paste</button>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
                <div>
                    {
                        (this.props.condition.operand1.operandType ||
                            this.props.condition.operand2.operandType) &&
                        <button disabled={this.props.isReadOnly} className="helper-btn line-height-1" onClick={this.onClearClick}>CLEAR</button>
                    }
                    {
                        (this.props.allwaysAllowDelete || (!this.props.isReadOnly && !(this.props.isFirst && this.props.isLast))) &&
                        <button onClick={this.onRemoveClick} className="transparent-button line-height-1" title="remove condition"><RemoveIcon /></button>
                    }
                </div>
            </div>
        );
    }
}

export default ConditionItem;
