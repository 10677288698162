import { ReactComponent as CommentIcon } from '../../Common/BootstrapIcons/comment.svg';
import { ReactComponent as EditIcon } from '../../Common/BootstrapIcons/edit.svg';
import ActionItemGridItem from '../../../types/common/ActionItem/ActionItemGridItem';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import EditableText from '../../Common/EditableText/EditableText';
import history from '../../../infrastructure/helpers/react/history';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import PromiseButton from '../../Common/PromiseButton';
import React from 'react';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';

const onEditClick = (actionItemId: number, reportConfigId: number): void => {

    let redirect = urlHelper.buildUrl(appRoutes.reporting.actionLog, { id: reportConfigId });

    let route = urlHelper.buildUrl(appRoutes.reporting.actionItem, { id: actionItemId, redirect: encodeURIComponent(redirect) });

    history.push(route);
};

const getColumns = (reportConfigId: number, onCommentClick: (id: number) => Promise<any>, getCommentCount: (id: number) => number): ColumnDefinition<ActionItemGridItem>[] => {
    return [
        {
            header: 'Id',
            sortKey: 'Id',
            getContent: x => x.id.toString(),
            width: '38px'
        },
        {
            header: 'Name',
            sortKey: 'Name',
            getContent: x => x.name
        },
        {
            header: 'Description',
            getContent: x => stringHelper.isNonEmpty(x.description) ? <EditableText value={x.description} multiline={true} disabled={true} onChange={() => { }} /> : <></>
        },
        {
            header: 'Status',
            getContent: x => x.status
        },
        {
            header: 'Assignee',
            getContent: x => x.assignee
        },
        {
            header: 'Raised Date',
            getContent: x => x.createdTimestamp
        },
        {
            header: 'Closed Date',
            getContent: x => x.closedTimestamp
        },
        {
            header: 'Client Action Id',
            getContent: x => x.clientActionId.toString()
        },
        {
            header: '',
            getContent: x =>
                <>
                    <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onCommentClick(x.id)}>
                        <CustomIcon icon={CommentIcon} count={getCommentCount(x.id)} title={iconTooltip.comments} />
                    </PromiseButton>
                    <button className="custom-icon-button" onClick={() => onEditClick(x.id, reportConfigId)}>
                        <CustomIcon icon={EditIcon} title={iconTooltip.edit} />
                    </button>
                </>,
            width: '72px'
        }
    ];
};

export default getColumns;