import { ReactComponent as RemoveIcon } from '../../../components/Common/BootstrapIcons/close.svg';
import BespokeToggleButton from '../../Functions/Bespoke/BespokeToggleButton';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import DraggableItem from '../../Common/DraggableItem/DraggableItem';
import EditableText from '../../Common/EditableText/EditableText';
import EndpointFilter from '../../../types/report/EndpointFilter';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import React from 'react';

const getNumberContent = (number: number, reorder: (dragNumber: number, dropNumber: number) => void, isReadOnly: boolean): string | JSX.Element => {

    if (isReadOnly) {
        return number.toString();
    }

    return (
        <DraggableItem identifier={number} onDrop={reorder}>
            {number}
        </DraggableItem>
    );
};

const getColumns = (
    onNameChange: (name: string, number: number) => void,
    onBusinessDescriptionChange: (businessDescription: string, number: number) => void,
    onBespokeToggleClick: (isBespoke: boolean, filter: EndpointFilter) => void,
    onRemoveClick: (number: number) => void,
    reorder: (dragNumber: number, dropNumber: number) => void,
    isReadOnly: boolean
): ColumnDefinition<EndpointFilter>[] => {

    let columns: ColumnDefinition<EndpointFilter>[] = [
        {
            header: 'Reference',
            getContent: x => getNumberContent(x.number, reorder, isReadOnly),
            width: '110px'
        },
        {
            header: 'Filter Name',
            getContent: x => <EditableText disabled={isReadOnly} value={x.name} onChange={value => onNameChange(value, x.number)} multiline={false} />,
            width: '200px'
        },
        {
            header: 'Business Description',
            getContent: x => <EditableText disabled={isReadOnly} value={x.businessDescription} onChange={value => onBusinessDescriptionChange(value, x.number)} multiline={true} style={{ width: '100%', height: '60px' }} />
        },
        {
            header: '',
            getContent: x =>
                <div className="float-right">
                    {
                        !isReadOnly &&
                        <BespokeToggleButton
                            isBespoke={x.isBespoke}
                            onClick={isBespoke => onBespokeToggleClick(isBespoke, x)}
                            style={{ verticalAlign: 'top', width: '150px' }} />
                    }
                    <button
                        className="custom-icon-button"
                        onClick={() => onRemoveClick(x.number)}
                        disabled={isReadOnly}>
                        <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                    </button>
                </div>,
            width: '200px'
        }
    ];

    return columns;
};

export {
    getColumns
};