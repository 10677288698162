import { createOption } from './MultiResultLookupBuilder.Helpers';
import BaseOperandProps from '../Props/BaseOperandProps';
import FieldOperandProps from '../Props/FieldOperandProps';
import Lookup from '../../../../types/functions/Lookup';
import Operand from '../../../../types/functions/Operand/Operand';
import React from 'react';
import SelectList from '../../../Common/SelectList/SelectList';
import SelectOption from '../../../../infrastructure/types/SelectOption';
import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import locationType from '../../../../types/functions/Location/LocationType';

class MultiResultLookupBuilder extends React.Component<BaseOperandProps & FieldOperandProps> {

    constructor(props: BaseOperandProps & FieldOperandProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.qualifies = this.qualifies.bind(this);
        this.getValue = this.getValue.bind(this);
    }

    onChange(value: string): void {

        if (stringHelper.isEmpty(value)) {
            return;
        }

        let operand = { lookupNumber: parseInt(value) } as Operand;

        this.props.onOperandChange(operand);
    }

    getOptions(): SelectOption[] {

        return this.props.lookups.filter(this.qualifies).map(x => createOption(x, this.props.collections.dataSources));
    }

    qualifies(lookup: Lookup): boolean {

        if (!lookup.isMultiResult) {

            return false;
        }

        if (!typeHelper.isNumber(lookup.dataSource2Id)) {

            return false;
        }

        if (lookup.dataSource1Id !== this.props.dataSource1Id) {

            return false;
        }

        if (this.props.location.statement === locationType.statement.lookup &&
            this.props.location.statementNumber <= lookup.number)
        {
            return false;
        }

        return true;
    }

    getValue(): string {

        return this.props.operand.lookupNumber?.toString() || '';
    }

    render(): JSX.Element {
        return (
            <SelectList
                options={this.getOptions()}
                value={this.getValue()}
                onChange={this.onChange}
                includeEmptyOption={true}
                className="form-control" />
        );
    }
}
export default MultiResultLookupBuilder;
