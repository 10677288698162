import { getArgumentDefinitionByIndex, getFunctionDefinition } from './functionHelper.Helpers';
import ArgumentDefinition from '../../../../types/functions/ArgumentDefinition';
import assignCompatibleArguments from './functionHelper.assignCompatibleArguments';
import FunctionDefinition from '../../../../types/functions/FunctionDefinition';
import Operand from '../../../../types/functions/Operand/Operand';
import operandHelper from '../operand/operandHelper';
import operandType from '../../../constants/functions/operandType';
import repeat from '../../common/array/repeat';
import typeHelper from '../../common/typeHelper';

const getTotalArgumentCount = (definition: FunctionDefinition, arrayArgumentCount: number | null): number => {

    if (!definition.arguments.some(a => a.isArray)) {

        return definition.arguments.length;
    }

    let arrayCount = typeHelper.isNumber(arrayArgumentCount) ?
        (arrayArgumentCount as number) :
        (definition.arguments.find(a => a.isArray) as ArgumentDefinition).minArrayArgumentCount as number;

    return definition.arguments.filter(a => !a.isArray).length + arrayCount; 
};

const setup = (operand: Operand, argumentDefinition: ArgumentDefinition): void => {

    if (!typeHelper.isString(argumentDefinition.defaultValue)) {
        return;
    }

    operand.operandType = operandType.constant;
    operand.dataType = argumentDefinition.allowedTypes[0];
    operand.value = (argumentDefinition.defaultValue as string);
};

const getArgumentDescriptions = (functionName: string, argumentCount: number, describe: (definition: ArgumentDefinition) => string): string[] => {

    let result: string[] = [];

    let definition = getFunctionDefinition(functionName);

    for (let i = 0; i < argumentCount; i++) {

        result.push(describe(getArgumentDefinitionByIndex(definition, i)));
    }

    return result;
};

const getMaxArrayArgumentsCount = (functionName: string): number => {

    const maximumArgumentsAllowedForAllFunctions = 50;

    let definition = getFunctionDefinition(functionName);

    let arrayDefinition = definition.arguments.find(a => a.isArray) as ArgumentDefinition;

    return typeHelper.isObject(arrayDefinition) && arrayDefinition.maxArrayArgumentCount ? (arrayDefinition.maxArrayArgumentCount as number) : maximumArgumentsAllowedForAllFunctions;
};

const getMinArrayArgumentCount = (functionName: string): number => {

    let definition = getFunctionDefinition(functionName);

    let arrayDefinition = definition.arguments.find(a => a.isArray) as ArgumentDefinition;

    return typeHelper.isObject(arrayDefinition) ? (arrayDefinition.minArrayArgumentCount as number) : 0;
};

const getNonArrayArgumentCount = (functionName: string): number => {

    let definition = getFunctionDefinition(functionName);

    return definition.arguments.filter(x => !x.isArray).length;
};

const isArrayFunction = (functionName: string): boolean => {

    let definition = getFunctionDefinition(functionName);

    return definition.arguments.some(x => x.isArray);
};

const getEmptyArguments = (functionName: string, arrayArgumentCount: number | null): Operand[] => {

    let definition = getFunctionDefinition(functionName);

    let count = getTotalArgumentCount(definition, arrayArgumentCount);

    let result = repeat(count, operandHelper.getEmpty);

    result.forEach((x, i) => setup(x, getArgumentDefinitionByIndex(definition, i)));

    return result;
};

const functionHelper = {
    getFunctionDefinition,
    getArgumentDescriptions,
    getMinArrayArgumentCount,
    getMaxArrayArgumentsCount,
    getNonArrayArgumentCount,
    isArrayFunction,
    getEmptyArguments,
    assignCompatibleArguments
};

export default functionHelper;
