import './ChangeLogModal.scss';
import { Unsubscribe } from 'redux';
import actions from '../../../../store/actions';
import ChangeLogModalState from './ChangeLogModalState';
import Modal from '../../Modal/Modal';
import React from 'react';
import store from '../../../../store/store';
import UiDataGrid from '../../DataGrid/UiDataGrid/UiDataGrid';

class ChangeLogModal extends React.Component<any, ChangeLogModalState> {
    private unsubscribe: Unsubscribe | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            isOpen: false,
            columns: [],
            data: [],
            error: null
        };

        this.onStateChange = this.onStateChange.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onStateChange);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
    }

    onStateChange(): void {

        let state = store.getState();
        if (state.action !== actions.changeLogModal.open) {
            return;
        }

        this.setState(state.changeLogModalState);
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    render(): JSX.Element {
        return (
            <Modal state={this.state} close={this.close} size="extra-large">

                <div className="change-log-modal-content">
                    {
                        this.state.data.length > 0 ?
                            <UiDataGrid columns={this.state.columns} data={this.state.data} getKey={(x, i) => i} /> :
                            <>No Changes</>
                    }
                </div>

                <button className="btn btn-light" onClick={this.close}>Close</button>

                {this.props.children}
            </Modal>
        );
    }

}

export default ChangeLogModal;
