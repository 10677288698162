const headerName = {
    contentType: 'Content-Type'
};

const headerValue = {
    contentType: {
        multipartFormData: 'multipart/form-data'
    }
};

export { headerName, headerValue };
