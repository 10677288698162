enum ComponentType {
    None = '',
    AccuracyValidation = 'Accuracy Validation',
    AccuracyValidationStandard = 'Standard',
    AccuracyValidationCore = 'Core',
    Lookup = 'Lookup',
    Variable = 'Variable',
    IsinSelector = 'Isin Selector',
    LeiSelector = 'Lei Selector',
    LseSelector = 'Lse Selector',
    ExchangeRatesSelector = 'Exchange Rates Selector',
    AnnaDsbUpiEnrichmentSelector = 'Anna Dsb Upi Enrichment Selector',
    LSESelector = 'LSE',
    AnnaDsbUpiSelector = 'Anna Dsb Upi Selector',
    Filter = 'Filter',
    EndpointFilter = 'Endpoint Filter',
    UnderReportFilter = 'Under Report Filter',
    OverReportFilter = 'Over Report Filter',
    Enrichment = 'Enrichment',
    Case = 'Case',
    CaseField = 'Case Field',
    FirdsFcaInstrumentSelectors = 'Firds Fca Instrument Selector',
    FirdsEsmaInstrumentSelectors = 'Firds Esma Instrument Selector',
    FCARegulatedEntitiesSelector = 'FCA Regulated Entities Selector',
    Aggregation = 'Aggregation',
    AggregationGroup = 'Aggregation Group',
    AggregationField = 'Aggregation Field',
    MatchingKeyClient = 'Matching Key Client',
    MatchingKeyEndpoint = 'Matching Key Endpoint',
    UserDefinedFunction = 'User Defined Function',
    UserDefinedFunctionParameter = 'User Defined Function Parameter',
    Dictionary = 'Dictionary',
    DictionaryField = 'Dictionary Field'
}

export default ComponentType