import { Unsubscribe } from 'redux';
import { getColumns } from './VariableBuilder.Grid';
import { resolveSystemVariableNameSuggestion, prepareOptionsFromVariables, dataTypeChangeMessage } from './VariableBuilder.Helpers';
import AssignmentBuilder from '../../Functions/Assignment/AssignmentBuilder';
import assignmentHelper from '../../../infrastructure/helpers/functions/assignment/assignmentHelper';
import DeclarationBuilderProps from '../../Functions/Declaration/DeclarationBuilderProps';
import ErrorList from '../../Common/ErrorList/ErrorList';
import locationType from '../../../types/functions/Location/LocationType';
import pseudocodeHelper from '../../../infrastructure/helpers/functions/common/pseudocodeHelper';
import React from 'react';
import store from '../../../store/store';
import actions from '../../../store/actions';
import reportConfigNavigation from '../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportLogicList from '../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import reportLogicReferenceHelper from '../../../infrastructure/helpers/functions/report/reportLogicReferenceHelper';
import ReportVariable from '../../../types/report/ReportVariable';
import SelectOption from '../../../infrastructure/types/SelectOption';
import systemVariables from '../../../infrastructure/constants/functions/systemVariables';
import CopyVariableModalState from './CopyVariableModal/CopyVariableModalState';
import copyObject from '../../../infrastructure/helpers/common/copyObject';
import InsertAtPositionModal from '../../Common/Modals/InsertAtPositionModal/InsertAtPositionModal';
import dataType from '../../../infrastructure/constants/dataType';
import { mapToBasicOption } from '../../../infrastructure/helpers/html/selectOptionHelper';
import reorderHelper from '../../../infrastructure/helpers/common/reorderHelper';
import Assignment from '../../../types/functions/Assignment/Assignment';
import Operand from '../../../types/functions/Operand/Operand';
import ReportConfigComponentLock from '../../../types/report/ReportConfigComponentLock';
import { getConfirmRemoveMessage } from '../../Functions/Assignment/AssignmentItem/AssignmentItem.Helpers';
import IdNameAndNumber from '../../../types/report/IdNameAndNumber';
import reportComponentBuildersHelper from '../Common/ReportComponentsHelper';
import ComponentType from '../../../types/report/ComponentType';
import ActionType from '../../../types/report/ActionType';
import { reportType } from '../../../infrastructure/constants/reportType';
import WarningModal from '../../Common/Modals/WarningModal/WarningModal';
import operandHelper from '../../../infrastructure/helpers/functions/operand/operandHelper';
import { onReferenceSelected } from '../../../infrastructure/helpers/report/reference/helper';

const dataTypeOptions = Object.values(dataType).map(mapToBasicOption);

interface State {
    showInsertAtPositionModal: boolean,
    error: string,
    dataType: string,
    number: number,
    assignment: Assignment,
    showWarningModal: boolean,
    location: ReportLogicLocation,
    variable: ReportVariable,
    isSingle?: boolean,
    event?: React.MouseEvent<HTMLElement>,
    reportConfigComponentLock?: ReportConfigComponentLock,
    message: string,
    title: string,
    onWarningOkClickFunction: () => void
}

class VariableBuilder extends React.Component<DeclarationBuilderProps<ReportVariable>, State> {
    private unsubscribeCopyVariable: Unsubscribe | undefined;
    constructor(props: DeclarationBuilderProps<ReportVariable>) {
        super(props);
        this.state = {
            showInsertAtPositionModal: false,
            error: '',
            dataType: '',
            number: 0,
            assignment: {} as Assignment,
            showWarningModal: false,
            location: {} as ReportLogicLocation,
            variable: {} as ReportVariable,
            isSingle: false,
            event: undefined,
            reportConfigComponentLock: {} as ReportConfigComponentLock,
            message: '',
            title: '',
            onWarningOkClickFunction: () => { }
        }

        this.onNameChange = this.onNameChange.bind(this);
        this.onDataTypeChange = this.onDataTypeChange.bind(this);
        this.onBusinessDescriptionChange = this.onBusinessDescriptionChange.bind(this);
        this.onIsSystemChange = this.onIsSystemChange.bind(this);
        this.onAddVariableClick = this.onAddVariableClick.bind(this);
        this.onRemoveVariableClick = this.onRemoveVariableClick.bind(this);
        this.onResetAssignmentClick = this.onResetAssignmentClick.bind(this);
        this.getVariableReferences = this.getVariableReferences.bind(this);
        this.getAssignmentContent = this.getAssignmentContent.bind(this);
        this.onCopyVariableClick = this.onCopyVariableClick.bind(this);
        this.onCopyVariableSave = this.onCopyVariableSave.bind(this);
        this.reorder = this.reorder.bind(this);
        this.onVariableConfiguredInPopup = this.onVariableConfiguredInPopup.bind(this);
        this.tryLock = this.tryLock.bind(this);
        this.validateInsertBeforeAfter = this.validateInsertBeforeAfter.bind(this);
        this.onDataTypeChangeClick = this.onDataTypeChangeClick.bind(this);
        this.onRemoveAssignmentConfirmed = this.onRemoveAssignmentConfirmed.bind(this);
    }

    componentDidMount(): void {

        this.unsubscribeCopyVariable = store.subscribe(this.onCopyVariableSave);
        this.setInternalIds();
    }

    setInternalIds() {
        let index = 1;
        this.props.reportConfig.variables.forEach((variable) => {
            variable._internalId = index;
            index += 1;
        })
    }

    componentWillUnmount(): void {

        (this.unsubscribeCopyVariable as Unsubscribe)();
    }

    async onNameChange(name: string, number: number) {

        const isLocked = await this.tryLock(number);

        if (!isLocked) {
            let location = this.getLocation(number);
            this.props.onDeclarationChange(location, { name: name || '' } as ReportVariable);
        }
    }

    onDataTypeChangeClick(dataType: string, number: number, assignment: Assignment) {
        this.setState({ dataType, number, assignment, showWarningModal: true, message: dataTypeChangeMessage, title: 'Data type change', onWarningOkClickFunction: this.onDataTypeChange });
    }

    onDataTypeChange(): void {

        this.setState({ showWarningModal: false });

        let location = this.getLocation(this.state.number);
        let changes = {} as ReportVariable;

        let error = this.props.validateRemoveDeclaration(location);
        if (error) {
            changes.errors = [error];
        }
        else {
            changes.dataType = this.state.dataType;
            changes.assignment = assignmentHelper.removeOnlyValues(false, this.state.assignment);
            changes.assignment.number = this.state.assignment.number;
        }

        this.props.onDeclarationChange(location, changes);
    }

    async onBusinessDescriptionChange(businessDescription: string, number: number): Promise<void> {
        const isLocked = await this.tryLock(number);
        if (!isLocked) {
            let location = this.getLocation(number);
            this.props.onDeclarationChange(location, { businessDescription: businessDescription } as ReportVariable);
        }
    }

    async onIsSystemChange(isSystem: boolean, dataType: string, number: number, event: React.ChangeEvent<HTMLElement>): Promise<void> {

        const isLocked = await this.tryLock(number);

        if (!isLocked) {
            let error: string | null = null;
            let location = this.getLocation(number);
            let overrideDataType = isSystem && dataType !== systemVariables.dataType;
            if (overrideDataType) {
                error = this.props.validateRemoveDeclaration(location);
            }

            let changes = {} as ReportVariable;

            if (error) {
                changes.errors = [error];
            }
            else {
                changes.isSystem = isSystem;
                if (isSystem) {
                    changes.name = resolveSystemVariableNameSuggestion(this.props.reportConfig.variables);
                }
                if (overrideDataType) {
                    changes.dataType = systemVariables.dataType;
                    changes.assignment = assignmentHelper.getEmpty(false);
                    changes.assignment.number = 1;
                }
            }

            this.props.onDeclarationChange(location, changes);
        }
    }

    onAddVariableClick(): void {

        if (this.props.reportConfig.variables.length > 0) {
            this.setState({ showInsertAtPositionModal: true });
        }
        else {
            this.props.onAddDeclarationClick(this.setVariableName);
        }
    }

    async onVariableConfiguredInPopup(name: string, type: string, isInsertAfter: boolean, selectedVariableNumber: string): Promise<void> {

        this.setState({ showInsertAtPositionModal: false });
        const insertionNumber: number = parseInt(selectedVariableNumber);

        this.props.onAddDeclarationClick((variable: ReportVariable, variables: ReportVariable[]) => {
            variables = reorderHelper.rearangeElementsWhenAddNewOne(variables, variable, isInsertAfter, insertionNumber) as ReportVariable[];

            if (!name) {
                let number = variables.map(v => v.number).sort((x, y) => { return x > y ? 1 : x < y ? -1 : 0; }).pop() || 0;
                variable.name = `var-${number}`;
                variable._internalId = variable.number;
            }
            else {
                variable.name = name
            }

            variable.dataType = type || dataType.boolean;
            reorderHelper.changeReferences(this.props.reportConfig,
                variables, (operand: Operand, oldAndNewNumbers: Map<number, number>, componentType: ComponentType | undefined) => {
                    if (componentType !== ComponentType.AccuracyValidation) {
                        if (operand.variableNumber && oldAndNewNumbers.has(operand.variableNumber)) {
                            operand.variableNumber = oldAndNewNumbers.get(operand.variableNumber) as number;
                        }
                    }
                }
            );
        });
    }

    createVariableIdAndNumber(): Map<number, number> {
        const variablesIdAndNumber = new Map<number, number>();

        this.props.reportConfig.variables.forEach((variable) => {
            const id = variable._internalId;
            variablesIdAndNumber.set(id, variable.number);
        });

        return variablesIdAndNumber;
    }

    async onRemoveVariableClick(number: number, event: React.MouseEvent<HTMLElement>): Promise<void> {

        let location = this.getLocation(number);

        const isLocked = await this.tryLock(number);

        if (!isLocked) {
            const validationError = await reportComponentBuildersHelper.validateAction(
                number,
                false,
                this.props.reportConfig.variables as IdNameAndNumber[],
                ComponentType.Variable,
                this.props.reportConfig.id || 0,
                ActionType.Remove);
            if (!validationError) {
                this.props.onRemoveDeclarationClick(location, (variables) => {

                    let updated = reorderHelper.rearangeElementsWhenRemoveOne(variables) as ReportVariable[];

                    reorderHelper.changeReferences(
                        this.props.reportConfig,
                        updated,
                        (operand: Operand, oldAndNewNumbers: Map<number, number>) => {
                            if (operand.variableNumber && oldAndNewNumbers.has(operand.variableNumber)) {
                                operand.variableNumber = oldAndNewNumbers.get(operand.variableNumber) as number;
                            }
                        }
                    );
                });
            }
            else {
                this.props.onRemoveDeclarationClick(location, undefined, undefined, validationError);
            }
        }
    }

    async onRemoveAssignmentClick(location: ReportLogicLocation, variable: ReportVariable, isSingle?: boolean, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {
        const isLocked = await this.tryLock(undefined, variable);

        if (!isLocked) {
            this.setState({ message: getConfirmRemoveMessage(isSingle || false), isSingle, variable, location, reportConfigComponentLock, showWarningModal: true, title: 'Remove assignment', onWarningOkClickFunction: this.onRemoveAssignmentConfirmed });
        }
    }

    onRemoveAssignmentConfirmed() {

        this.setState({ showWarningModal: false });

        this.props.onRemoveAssignmentClick(this.state.location, this.state.isSingle, this.state.event, this.state.reportConfigComponentLock);
    }

    async onResetAssignmentClick(location: ReportLogicLocation, variable: ReportVariable, isSingle?: boolean, event?: React.MouseEvent<HTMLElement>, reportConfigComponentLock?: ReportConfigComponentLock): Promise<void> {

        const isLocked = await this.tryLock(undefined, variable);

        if (!isLocked) {
            this.props.onResetAssignmentClick(location, false, event, reportConfigComponentLock);
        }
    }

    getLocation(number: number): ReportLogicLocation {

        return { statement: locationType.statement.variable, statementNumber: number } as ReportLogicLocation;
    }

    getVariableReferences(number: number): SelectOption[] {

        return reportLogicReferenceHelper.getVariableReferences(number, this.props.reportConfig, this.props.collections);
    }

    setVariableName(variable: ReportVariable): void {

        variable.name = `var-${variable.number}`;
    }

    async onCopyVariableClick(number: number, event: React.MouseEvent<HTMLElement>): Promise<void> {

        const isLocked = await this.tryLock(number);

        if (!isLocked) {
            let state: CopyVariableModalState = {
                title: 'Copy Variable',
                isOpen: true,
                error: null,
                sourceVariableNumber: number.toString(),
                targetVariableNumber: '',
                toVariables: []
            };

            store.dispatch({ type: actions.copyVariableModal.change, payload: state });
        }
    }

    onCopyVariableSave(): void {

        let appState = store.getState();

        if (appState.action !== actions.copyVariableModal.save) {
            return;
        }

        let sourceNumber = parseInt(appState.copyVariableModalSave.sourceVariableNumber);
        let sourceVariable = this.props.reportConfig.variables.find(x => x.number === sourceNumber) as ReportVariable;

        let targetNumber = parseInt(appState.copyVariableModalSave.targetVariableNumber);
        let targetVariable = this.props.reportConfig.variables.find(x => x.number === targetNumber) as ReportVariable;

        targetVariable.assignment = copyObject(sourceVariable.assignment);
        targetVariable.businessDescription = sourceVariable.businessDescription;

        let location = this.getLocation(targetVariable.number);
        this.props.onDeclarationChange(location, targetVariable);
    }

    async reorder(dragNumber: number, dropNumber: number, event: React.DragEvent<HTMLDivElement>): Promise<void> {

        const isLockedDragged = await this.tryLock(dragNumber);
        let isLockedDropped = false;
        if (!isLockedDragged) {
            isLockedDropped = await this.tryLock(dropNumber);
        }

        if (!isLockedDragged && !isLockedDropped) {
            let dragIndex = this.props.reportConfig.variables.findIndex(x => x.number === dragNumber);
            let dropIndex = this.props.reportConfig.variables.findIndex(x => x.number === dropNumber);

            let draggedVariable = this.props.reportConfig.variables.find(v => v.number === dragNumber) as ReportVariable;
            let dropVariable = this.props.reportConfig.variables.find(v => v.number === dropNumber) as ReportVariable;

            this.clearAllErrors();

            let result = reportComponentBuildersHelper.validateDragAndDrop<ReportVariable>(dragIndex,
                dropIndex,
                draggedVariable as IdNameAndNumber,
                dropVariable as IdNameAndNumber,
                this.props.reportConfig.variables,
                this.getVariablesReferences
            );

            let changes = {} as ReportVariable;

            if (result.error) {
                const variableReferenced: ReportVariable = this.props.reportConfig.variables.find(s => s.number === result.number) || {} as ReportVariable;

                result.error = `${result.error} The referenced variable is "${variableReferenced.name}".`;

                changes.errors = [result.error];

                this.props.onDeclarationChange(this.getLocation(result.isDrag ? dragNumber : dropNumber), changes);

                return;
            }
            else {

                reorderHelper.reorderWithNumbersChange(this.props.reportConfig.variables, dragIndex, dropIndex);

                reorderHelper.changeReferences(this.props.reportConfig,
                    this.props.reportConfig.variables, (operand: Operand, oldAndNewNumbers: Map<number, number>, componentType?: ComponentType | undefined) => {
                        if (componentType !== ComponentType.AccuracyValidation) {
                            if (operand.variableNumber && operand.variableNumber === draggedVariable.number) {
                                operand.variableNumber = dropVariable.number;
                            }
                            else if (operand.variableNumber && operand.variableNumber === dropVariable.number) {
                                operand.variableNumber = draggedVariable.number;
                            }
                        }
                    }
                );

                this.props.onDragAndDrop && this.props.onDragAndDrop(this.props.reportConfig.variables);
            }
        }
    }

    clearAllErrors() {
        this.props.reportConfig.variables.forEach((variable) => {
            variable.errors = [];
        });
    }

    async tryLock(number?: number, variable?: ReportVariable): Promise<boolean> {

        let isLocked = false;
        if (this.props.reportConfig.type === reportType.accuracy && this.props.onComponentContainerClick) {

            let currentvariable = variable || this.props.reportConfig.variables.find(v => v.number === number);
            if (!currentvariable?.isUsedByCurrentUser) {
                isLocked = await this.props.onComponentContainerClick({ componentId: currentvariable?.id || 0, componentType: ComponentType.Variable, number: currentvariable?.number || 0 });
            }
        }
        return isLocked;
    }

    getVariablesReferences(startIndex: number, endIndex: number, collection: ReportVariable[]) {

        let referencedVariablesNumbers = new Set<number>();

        for (let i = startIndex; i < endIndex; i++) {
            if (collection[i].assignment) {
                operandHelper.proceedWhitAssignment(collection[i].assignment as Assignment, (operand: Operand) => {
                    if (operand.variableNumber) {
                        referencedVariablesNumbers.add(operand.variableNumber);
                    }
                });
            }
            referencedVariablesNumbers.add(collection[i].number);
        }

        return referencedVariablesNumbers;
    }

    async validateInsertBeforeAfter(number: number, isInsertAfter: boolean) {
        return await reportComponentBuildersHelper.validateAction(
            number,
            isInsertAfter,
            this.props.reportConfig.variables as IdNameAndNumber[],
            ComponentType.Variable,
            this.props.reportConfig.id || 0,
            ActionType.Add);
    }

    async onReorderConditions(variable: ReportVariable, error?: string) {
        let isLocked = await this.tryLock(variable.number);

        if (error && !variable.errors.includes(error)) {
            variable.errors.push(error);
        }
        if (!isLocked) {
            let location = this.getLocation(variable.number);
            this.props.onDeclarationChange(location, variable);
        }
    }

    getAssignmentContent(variable: ReportVariable): JSX.Element {
        const reportConfigComponentLock: ReportConfigComponentLock = { componentId: variable.id || 0, componentType: ComponentType.Variable, number: variable.number };

        return (
            <>
                <AssignmentBuilder
                    isReadOnly={variable.isReadOnly || this.props.isReadOnly}
                    location={this.getLocation(variable.number)}
                    assignment={variable.assignment}
                    fieldName={pseudocodeHelper.wrapInBrackets(variable.name)}
                    functions={this.props.reportConfig.userDefinedFunctions}
                    lookups={this.props.reportConfig.lookups}
                    dictionaries={this.props.reportConfig.dictionaries}
                    variables={this.props.reportConfig.variables}
                    dataSources={this.props.collections.dataSources}
                    reportFields={this.props.collections.reportFields}
                    customDataSourceFields={this.props.collections.customDataSourceFields}
                    onAddAssignmentClick={(location, event) => this.props.onAddAssignmentClick(location, event, reportConfigComponentLock)}
                    onRemoveAssignmentClick={(location, isSingle, event) => this.onRemoveAssignmentClick(location, variable, isSingle, event, reportConfigComponentLock)}
                    onResetAssignmentClick={(location, isSingle, event) => { this.onResetAssignmentClick(location, variable, isSingle, event, reportConfigComponentLock) }}
                    onLogicalOperatorChange={(location, operator, event) => this.props.onLogicalOperatorChange(location, operator, event, reportConfigComponentLock)}
                    onParenthesesChange={(location, count, type, event) => this.props.onParenthesesChange(location, count, type, event, reportConfigComponentLock)}
                    onAddConditionClick={(location, operator, event) => this.props.onAddConditionClick(location, operator, event, reportConfigComponentLock)}
                    onRemoveConditionClick={(location, event) => this.props.onRemoveConditionClick(location, event, reportConfigComponentLock)}
                    onComparisonTypeChange={(location, value, event) => this.props.onComparisonTypeChange(location, value, event, reportConfigComponentLock)}
                    onOperandClick={(location, event) => this.props.onOperandClick(location, event, reportConfigComponentLock)}
                    onCopyClick={(location) => this.props.onCopyClick(location, reportConfigComponentLock)}
                    onPasteClick={(location, event) => this.props.onPasteClick(location, event, reportConfigComponentLock)}
                    onValidateFieldClick={(location, event) => this.props.onValidateDeclarationClick(location, event, reportConfigComponentLock)}
                    onClearClick={(event) => this.tryLock(undefined, variable)}
                    allowPaste={this.props.allowPaste}
                    key={variable.number}
                    onReorderAssignmentConditions={(error) => { this.onReorderConditions(variable, error); }}
                />
                <ErrorList errors={variable.errors} className="mt-2" />
            </>
        );
    }

    render(): JSX.Element {

        const dropdownOptions = prepareOptionsFromVariables(this.props.reportConfig.variables);

        return (
            <>
                <ReportLogicList
                    isWaiting={this.props.isWaiting}
                    items={this.props.reportConfig.variables}
                    columns={getColumns(
                        this.tryLock,
                        this.onNameChange,
                        this.onDataTypeChangeClick,
                        this.onBusinessDescriptionChange,
                        this.onIsSystemChange,
                        this.onRemoveVariableClick,
                        this.onCopyVariableClick,
                        this.getVariableReferences,
                        this.reorder,
                        this.props.reportConfig.type,
                        this.props.isReadOnly,
                        onReferenceSelected)}
                    getContent={this.getAssignmentContent}
                    getKey={v => v.number}
                    createHtmlId={v => reportConfigNavigation.buildVariableId(v.number)}
                    onClick={(e: React.MouseEvent<HTMLElement>, item: ReportVariable) => {
                        if (item.isLocked && item.isReadOnly) {
                            this.tryLock(item.number);
                        }
                    }} />
                {
                    !this.props.isReadOnly &&
                    !this.props.isWaiting &&
                    <div className="mt-4 mb-4">
                        <button onClick={this.onAddVariableClick} className="btn btn-light">+ Variable</button>
                    </div>
                }
                {this.state.showInsertAtPositionModal ?
                    <InsertAtPositionModal
                        title='New Variable'
                        onSaveClick={this.onVariableConfiguredInPopup}
                        dataTypeOptions={dataTypeOptions}
                        dropdownForTypeName='Data Type'
                        defaultDataType={dataType.boolean}
                        name='Variable'
                        dropdownOptions={dropdownOptions}
                        onClose={() => { this.setState({ showInsertAtPositionModal: false }) }}
                        defaultDropdownOption={dropdownOptions[0]}
                        defaultDropdownOptionAlwaysLastOne={true}
                        validateFunction={this.validateInsertBeforeAfter}
                    />
                    : null
                }
                {this.state.showWarningModal &&
                    <WarningModal
                        onOkClick={this.state.onWarningOkClickFunction}
                        onCancelClick={() => this.setState({ showWarningModal: false })}
                        title={this.state.title}
                        message={this.state.message} />
                }
            </>
        );
    }
}

export default VariableBuilder;