import { AxiosResponse } from 'axios';
import { getEmptyIngestionConfig } from './IngestionPage/IngestionPage.Helpers';
import { getFilenameDetails } from '../../../infrastructure/helpers/common/pathUtils';
import { requiresCsvArguments } from '../IngestionConfigForm/IngestionConfigForm.Helpers';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import CsvArgumentsPicker from '../CsvArgumentsPicker/CsvArgumentsPicker';
import CsvIngestionArguments from '../../../types/ingestion/csv/CsvIngestionArguments';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import FieldDefinition from '../../../types/common/FieldDefinition';
import fileExtension from '../../../infrastructure/constants/fileExtension';
import FilePicker from '../../Common/FilePicker/FilePicker';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import IngestionPageState from './IngestionPage/IngestionPageState';
import IngestionStepIndicator from '../IngestionStepIndicator';
import PromiseButton from '../../Common/PromiseButton';
import React from 'react';
import store from '../../../store/store';
import validate from './IngestionUploadPage.Validation';
import validateCsvArguments from '../CsvArgumentsPicker/CsvAgrumentsPicker.Validation';

interface UploadFilePageState extends IngestionPageState {
    file: File | null;
}

class IngestionUploadPage extends React.Component<any, UploadFilePageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            file: null,
            config: getEmptyIngestionConfig(),
            error: null
        };

        this.onFilePicked = this.onFilePicked.bind(this);
        this.onCsvArgumentsChange = this.onCsvArgumentsChange.bind(this);
        this.onUploadClick = this.onUploadClick.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.getUploadParams = this.getUploadParams.bind(this);
        this.getUploadData = this.getUploadData.bind(this);
    }

    onFilePicked(files: FileList, error: string): void {

        store.dispatch({ type: actions.navigation.setUnsafe, payload: null as any });

        let file = error ? null : files.item(0);

        let details = getFilenameDetails((file ? file.name : null) as string);

        this.setState({
            file: file,
            config: Object.assign({}, this.state.config, { filenames: details.name, fileType: details.extension } as IngestionConfig),
            error: error
        });
    }

    onCsvArgumentsChange(args: CsvIngestionArguments): void {

        let csvSpecification = Object.assign({}, this.state.config.csvSpecification, args);

        let config = Object.assign({}, this.state.config, { csvSpecification: csvSpecification } as IngestionConfig);

        this.setState({ config: config });
    }

    onUploadClick(): Promise<any> {

        let fileError = validate(this.state.file as File);
        if (fileError) {

            this.setState({ error: fileError });

            return Promise.reject(fileError);
        }

        let csvSpecificationError = validateCsvArguments(this.state.config);
        if (csvSpecificationError) {

            this.setState({ error: csvSpecificationError });

            return Promise.reject(csvSpecificationError);
        }

        let params = this.getUploadParams();
        let data = this.getUploadData();

        return httpClient.postFile('ingestion/describe-fields', data, params).then(this.onUploadSuccess);
    }

    onUploadSuccess(response: AxiosResponse<FieldDefinition[]>): void {

        let changes = { fieldDefinitions: response.data } as IngestionConfig;

        if (!requiresCsvArguments(this.state.config.fileType)) {
            changes.csvSpecification = null as any;
        }

        store.dispatch({ type: actions.navigation.execute, payload: () => history.push(appRoutes.ingestion.details, Object.assign({}, this.state.config, changes)) });
    }

    getUploadParams(): Object {

        let params = requiresCsvArguments(this.state.config.fileType) ? this.state.config.csvSpecification : {};

        return params as Object;
    }

    getUploadData(): FormData {
        let data = new FormData();

        data.append('file', this.state.file as File);

        return data;
    }

    render(): JSX.Element {
        const gridClassName = 'col-12 col-sm-11 col-md-8 col-lg-6 col-xl-5';

        return (
            <>
                <IngestionStepIndicator
                    currentStepNumber={1}
                    config={this.state.config} />

                <div className="row">
                    <div className={gridClassName}>
                        <FilePicker
                            uniqueName="ingestion"
                            allowedExtensions={[fileExtension.csv, fileExtension.txt, fileExtension.json]}
                            maxFileCount={1}
                            maxFileSizeInMb={10}
                            autoReset={false}
                            height={200}
                            onPicked={this.onFilePicked} />
                    </div>
                </div>

                {
                    requiresCsvArguments(this.state.config.fileType) &&
                    <div className="mt-3">
                        <div className="row">
                            <div className={gridClassName}>
                                <CsvArgumentsPicker
                                    isReadOnly={false}
                                    arguments={this.state.config.csvSpecification}
                                    onChange={this.onCsvArgumentsChange} />
                            </div>
                        </div>
                    </div>
                }

                <ErrorMessage className="mt-3" error={this.state.error} />

                <div className="mt-3 mb-3">
                    <PromiseButton
                        text="Upload"
                        className="btn cb-btn"
                        enableOnErrorOnly={true} 
                        task={this.onUploadClick}/>
                </div>
            </>
        );
    }
}

export default IngestionUploadPage;
