import Lookup from '../../../../types/functions/Lookup';

const specialValue = 'enpoint-lookup';

const isEndpoint = (value: string): boolean => {

    return value === specialValue;
};

const getDataSourceId = (value: string): number | null => {

    if (isEndpoint(value)) {

        return null;
    }

    return parseInt(value);
};

const getValue = (lookup: Lookup): string => {

    if (lookup.isEndpoint) {

        return specialValue;
    }

    return (lookup.dataSource1Id as number).toString();
};

const enpointLookupAdapter = {
    specialValue,
    isEndpoint,
    getDataSourceId,
    getValue
};

export default enpointLookupAdapter;
