import ComplianceUrl from '../../../../types/common/ComplianceUrl';
import groupBy from '../../../../infrastructure/helpers/common/array/groupBy';
import stringHelper from '../../../../infrastructure/helpers/common/stringHelper';
import urlValidator from '../../../../infrastructure/helpers/common/validator/urlValidator';

const maxNameCharacters = 300;

const validate = (urls: ComplianceUrl[]): string | null => {

    for (let i = 0; i < urls.length; i++) {

        let error = validateOne(urls[i]);
        if (error) {
            return error;
        }
    }

    if (hasDuplicateNames(urls)) {

        return 'Duplicate URL names';
    }

    return null;
};

const validateOne = (url: ComplianceUrl): string | null => {

    if (stringHelper.isEmpty(url.name)) {

        return 'URL name is required';
    }

    if (url.name.length > maxNameCharacters) {

        return `URL name max character count is ${maxNameCharacters}`;
    }

    let urlError = urlValidator.validate(url.url, 'Compliance');
    if (urlError) {
        return urlError;
    }

    return null;
};

const hasDuplicateNames = (urls: ComplianceUrl[]): boolean => {

    let duplicates = groupBy(urls, x => x.name.toLowerCase(), x => x).filter(x => x.group.length > 1);

    return duplicates.length > 0;
};

export { validate };
