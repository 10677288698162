import ObjectIndexer from "../types/ObjectIndexer";
import reportRegime from "./reportRegime";

const refitEuSubRegimes = {
    'Trades/Positions EU' : 'Derivatives',
    'Margins EU': 'Margins',
    'Trades/Positions UK': 'DerivativesUk',
    'Margins UK': 'MarginsUk'
};

const getSubRegimes = (regime: string): ObjectIndexer<string> => {

    if (regime === reportRegime.REFIT) {

        return refitEuSubRegimes;
    }

    return {};
}

export default getSubRegimes;
