import { AxiosResponse } from 'axios';
import { ReactComponent as DownloadIcon } from '../../Common/BootstrapIcons/download.svg';
import { ReactComponent as EditIcon } from '../../Common/BootstrapIcons/edit.svg';
import { ReactComponent as HistoryIcon } from '../../Common/BootstrapIcons/history.svg';
import { ReactComponent as OffIcon } from '../../Common/BootstrapIcons/toggle-off.svg';
import { ReactComponent as OnIcon } from '../../Common/BootstrapIcons/toggle-on.svg';
import { ReactComponent as RemoveIcon } from '../../Common/BootstrapIcons/close.svg';
import actions from '../../../store/actions';
import appRoutes from '../../../infrastructure/constants/appRoutes';
import ColumnDefinition from '../../../infrastructure/types/DataGrid/ColumnDefinition';
import CustomIcon from '../../Common/BootstrapIcons/Custom/CustomIcon';
import dataSourceKind from '../../../infrastructure/constants/dataSourceKind';
import EditableText from '../../Common/EditableText/EditableText';
import GridRefresh from '../../../store/state/events/GridRefresh';
import history from '../../../infrastructure/helpers/react/history';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import iconTooltip from '../../Common/BootstrapIcons/Tooltip/IconTooltip';
import identityStorage from '../../../infrastructure/authorization/identityStorage';
import IngestionConfigGridItem from '../../../types/ingestion/IngestionConfigGridItem';
import PromiseButton from '../../Common/PromiseButton';
import React from 'react';
import store from '../../../store/store';
import stringHelper from '../../../infrastructure/helpers/common/stringHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import writeUserRoles from '../../../infrastructure/constants/identity/writeUserRoles';
import { ReactComponent as CopyIcon } from '../../Common/BootstrapIcons/copy.svg';

const uniqueName = 'ingestion-config-grid';

const resolveAction = (enabled: boolean): string => enabled ? 'archive' : 'unarchive';
const resolveIcon = (enabled: boolean): React.FunctionComponent<React.SVGProps<SVGSVGElement>> => enabled ? OnIcon : OffIcon;

const goToEditPage = (config: IngestionConfigGridItem): void => {
    let params = { id: config.id };

    let route = urlHelper.buildUrl(appRoutes.ingestion.edit, params);

    history.push(route);
};

const onChangeStatusClick = (config: IngestionConfigGridItem): Promise<any> => {

    let action = resolveAction(config.enabled);

    let route = urlHelper.buildRoute(['ingestion', config.id, action]);

    return httpClient.post(route).then(onSuccess);
};

const onDownloadClick = (config: IngestionConfigGridItem): Promise<void> => {

    let route = urlHelper.buildRoute(['ingestion', config.id, 'export']);

    return httpClient.download(route);
};

const onSuccess = (response: AxiosResponse): void => {

    let payload: GridRefresh = {
        uniqueName: uniqueName,
        useCurrentPage: true
    };

    store.dispatch({ type: actions.grid.refresh, payload: payload });
};


const getColumns = (enabled: boolean,
    onCopyClick: (config: IngestionConfigGridItem) => void,
    onDeleteClick: (config: IngestionConfigGridItem) => void
): ColumnDefinition<IngestionConfigGridItem>[] => {

    let identity = identityStorage.get();

    let hasWriteRole = writeUserRoles.includes(identity.userRole);

    return [
        {
            header: 'Id',
            sortKey: 'Id',
            getContent: x => x.id.toString(),
        },
        {
            header: 'Name',
            sortKey: 'Name',
            getContent: x => <button className="transparent-button" onClick={() => goToEditPage(x)}>{x.name}</button>,
            className: 'break-all'
        },
        {
            header: 'Created By',
            getContent: x => x.createdBy,
            className: 'break-all'
        },
        {
            header: 'Date Created',
            sortKey: 'CreatedTimestamp',
            getContent: x => x.createdTimestamp,
        },
        {
            header: 'File',
            sortKey: 'FileType',
            getContent: x => x.fileType,
        },
        {
            header: 'Data',
            sortKey: 'DataSourceKind',
            getContent: x => x.dataSourceKind === dataSourceKind.rawAndRef ? 'Both' : x.dataSourceKind,
        },
        {
            header: 'Description',
            getContent: x => stringHelper.isNonEmpty(x.description) ? <EditableText value={x.description} multiline={true} disabled={true} onChange={() => { }} /> : <></>
        },
        {
            header: 'Actions',
            getContent: x =>
                <>
                    <button className="custom-icon-button" onClick={() => history.push(urlHelper.buildUrl(appRoutes.ingestion.changeLog, { id: x.id }))}>
                        <CustomIcon icon={HistoryIcon} title={iconTooltip.changeLog} />
                    </button>
                    <button className="custom-icon-button" onClick={() => goToEditPage(x)}>
                        <CustomIcon icon={EditIcon} title={iconTooltip.edit} />
                    </button>

                    {
                        hasWriteRole &&
                        <PromiseButton className="custom-icon-button" enableOnErrorOnly={true} task={() => onChangeStatusClick(x)}>
                            <CustomIcon icon={resolveIcon(x.enabled)} title={resolveAction(x.enabled)} />
                        </PromiseButton>
                    }

                    <PromiseButton className="custom-icon-button" enableOnErrorOnly={false} task={() => onDownloadClick(x)}>
                        <CustomIcon icon={DownloadIcon} title={iconTooltip.download} />
                    </PromiseButton>

                    <button className="custom-icon-button" onClick={() => onCopyClick(x)}>
                        <CustomIcon icon={CopyIcon} title={iconTooltip.copy} />
                    </button>

                    {
                        hasWriteRole &&
                        !enabled &&
                        <button className="custom-icon-button" onClick={() => onDeleteClick(x)}>
                            <CustomIcon icon={RemoveIcon} title={iconTooltip.remove} />
                        </button>
                    }

                </>,
            width: '170px'
        }
    ];
};

export { uniqueName, getColumns };
