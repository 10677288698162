const publicHolidayCurrencies = [
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "AOA",
    "ARS",
    "AUD",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BRL",
    "BSD",
    "BWP",
    "BYN",
    "BZD",
    "CAD",
    "CHF",
    "CLP",
    "CNH",
    "CNY",
    "COP",
    "CRC",
    "CZK",
    "DKK",
    "DZD",
    "EGP",
    "ETB",
    "EUR",
    "FJD",
    "FRF",
    "GBP",
    "GEL",
    "GHS",
    "GNF",
    "GYD",
    "HKD",
    "HRK",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LSL",
    "LTL",
    "LVL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MMK",
    "MNT",
    "MUR",
    "MWK",
    "MXN",
    "MYR",
    "MZN",
    "NAD",
    "NDF",
    "NGN",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PEN",
    "PHP",
    "PKR",
    "PLN",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "RWF",
    "SAR",
    "SCR",
    "SEK",
    "SGD",
    "SIT",
    "SLL",
    "SSP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "UYU",
    "UZS",
    "VND",
    "XAF",
    "XOF",
    "ZAR",
    "ZMW",
    "ZWD"
];

export default publicHolidayCurrencies;
