import { Route, RouteProps, Redirect } from 'react-router-dom';
import appRoutes from '../../infrastructure/constants/appRoutes';
import identityProvider from '../../infrastructure/authorization/identityProvider';
import React from 'react';

interface ProtectedRouteProps extends RouteProps {
    roles: string[];
}

class ProtectedRoute extends React.Component<ProtectedRouteProps> {
    render(): JSX.Element {

        let result = identityProvider.authorize();

        if (!result.authorized) {
            return (
                <Redirect to={appRoutes.unauthorized} />
            );
        }

        if (this.props.roles.length > 0 && !this.props.roles.includes(result.role)) {
            return (
                <Redirect to={appRoutes.forbidden} />
            );
        }

        return (
            <Route {...this.props} />
        );
    }
}

export default ProtectedRoute;
