import React from 'react';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';
import history from '../../../infrastructure/helpers/react/history';
import SingleValueResponse from '../../../infrastructure/types/common/SingleValueResponse';
import actions from '../../../store/actions';
import GridRefresh from '../../../store/state/events/GridRefresh';
import store from '../../../store/store';
import IncrementVersionRequest from '../../../types/common/IncrementVersionRequest';
import ChangeLog from '../../Common/ChangeLog/ChangeLog';
import { commonColumns, moduleColumn, moduleRefColumn } from '../../Common/Modals/ChangeLogModal/ChangeLogModal.Grid';
import getColumns from './VersionManagement.Grid';
import CreateVersionModalState from '../../Common/Modals/CreateVersionModal/CreateVersionModalState';
import CreateVersionModal from '../../Common/Modals/CreateVersionModal/CreateVersionModal';
import RollBackVersionModal from '../../Common/Modals/RollbackVersionModal/RollBackVersionModal';
import RollbackVersionModalState from '../../Common/Modals/RollbackVersionModal/RollbackVersionModalState';

interface VersionManagementState {
    isWaiting: boolean;
    version: string,
    regime: string;
}

const gridName = 'field-definitions-versions';

class VersionManagement extends React.Component<any, VersionManagementState> {

    constructor(props: any) {
        super(props);

        this.state = {
            regime: this.getRegimeFromQuery(),
            version: '',
            isWaiting: true
        };

        this.getNextVersion = this.getNextVersion.bind(this);
        this.onGetVersionSuccess = this.onGetVersionSuccess.bind(this);
        this.openIncrementVersionModal = this.openIncrementVersionModal.bind(this);
        this.onIncrementVersionSuccess = this.onIncrementVersionSuccess.bind(this);
        this.openRollBackModal = this.openRollBackModal.bind(this);
    }

    componentDidMount(): void {

        this.getNextVersion();
    }

    componentDidUpdate(): void {

        let regime = this.getRegimeFromQuery();
        if (regime === this.state.regime) {
            return;
        }

        this.setState({ regime: regime, isWaiting: true }, this.getNextVersion);
    }

    getRegimeFromQuery(): string {

        return urlHelper.deserializeQuery(history.location.search)['regime'];
    }

    getNextVersion(): void {

        let route = urlHelper.buildRoute(['report-field-version', 'next', this.state.regime]);

        httpClient.get<SingleValueResponse<string>>(route).then(response => this.onGetVersionSuccess(response.data.value));
    }

    onGetVersionSuccess(version: string): void {

        let changes = { isWaiting: false, version: version } as VersionManagementState;

        this.setState(changes);
    }

    openIncrementVersionModal(): void {

        let route = urlHelper.buildRoute(['report-field-version', this.state.regime]);

        let payload: CreateVersionModalState = {
            isOpen: true,
            title: 'Increment Version',
            name: '',
            comment: '',
            onSaveClick: (name: string, comment: string) => httpClient.put(route, { name: name, comment: comment } as IncrementVersionRequest).then(this.onIncrementVersionSuccess),
            error: null
        };

        store.dispatch({ type: actions.createVersionModal.open, payload: payload });
    }

    onIncrementVersionSuccess() {

        this.getNextVersion();

        let payload: GridRefresh = {
            uniqueName: gridName,
            useCurrentPage: false
        };

        store.dispatch({ type: actions.grid.refresh, payload: payload });
    }

    openRollBackModal(): void {

        var getVersionsRoute = urlHelper.buildRoute(['report-field-version', 'list', this.state.regime]);

        httpClient.get<string[]>(getVersionsRoute).then((response) => {

            let payload: RollbackVersionModalState = {
                isOpen: true,
                title: 'Roll Back',
                name: '',
                comment: '',
                versions: response.data,
                selectedVersion: '',
                onSaveClick: (identifier: string, name: string, comment: string) => {
                    let route = urlHelper.buildRoute(['report-field-version', 'rollback', this.state.regime, identifier]);
                    return httpClient.post(route, { name: name, comment: comment } as IncrementVersionRequest).then(this.onIncrementVersionSuccess)
                },
                error: null
            };

            if (response.data.length > 0) {

                payload.selectedVersion = response.data[0];
                payload.name = `Roll Back to V${payload.selectedVersion}`;
            }

            store.dispatch({ type: actions.rollBackVersionModal.open, payload: payload });
        });
    }

    render(): JSX.Element {

        return (
            <>
                <h4 className="mb-3">Version Management</h4>
                {
                    !this.state.isWaiting &&
                    <div className="mb-4">
                        <button className="btn cb-btn" onClick={this.openIncrementVersionModal}>
                            <span>Create Version V{this.state.version}</span>
                        </button>
                        <button className="btn cb-btn" onClick={this.openRollBackModal}>
                            <span>Roll Back</span>
                        </button>
                    </div>
                }

                <h4 className="mb-3">Change Log</h4>

                <ChangeLog
                    getColumns={getColumns}
                    modalColumns={[moduleColumn, moduleRefColumn].concat(commonColumns)}
                    backendRoute={urlHelper.buildRoute(['report-field-version', this.state.regime])}
                    uniqueName={gridName} />

                <CreateVersionModal />
                <RollBackVersionModal />
            </>
        );
    }
}

export default VersionManagement;
