import { leiResultSetOptions } from './LeiResultSetBuilder.Helpers';
import RadioButtonGroup from '../../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';

interface Props {
    value: string;
}

class LeiResultSetBuilder extends React.Component<Props> {

    render(): JSX.Element {

        return (
            <div className="mt-3">
                <RadioButtonGroup
                    options={leiResultSetOptions}
                    name="lei-types"
                    value={this.props.value}
                    onChange={() => { }} />
            </div>
        );
    }
}

export default LeiResultSetBuilder;
