import React from 'react';
import { Unsubscribe } from 'redux';
import copyObject from '../../../../infrastructure/helpers/common/copyObject';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import assignmentHelper from '../../../../infrastructure/helpers/functions/assignment/assignmentHelper';
import outsideContextHelper from '../../../../infrastructure/helpers/functions/common/outsideContextHelper';
import autoCompleteHelper from '../../../../infrastructure/helpers/functions/condition/autoCompleteHelper';
import conditionHelper from '../../../../infrastructure/helpers/functions/condition/conditionHelper';
import conditionModifyHelper from '../../../../infrastructure/helpers/functions/condition/conditionModifyHelper';
import customDataSourceHelper from '../../../../infrastructure/helpers/functions/customDataSource/customDataSourceHelper';
import lookupHelper from '../../../../infrastructure/helpers/functions/lookup/lookupHelper';
import operand2ExpectedTypeHelper from '../../../../infrastructure/helpers/functions/operand/operand2ExpectedTypeHelper';
import operandHelper from '../../../../infrastructure/helpers/functions/operand/operandHelper';
import operandRuleHelper from '../../../../infrastructure/helpers/functions/operand/operandRuleHelper';
import overrideOperandHelper from '../../../../infrastructure/helpers/functions/operand/overrideOperandHelper/overrideOperandHelper';
import CustomDataSourceLegitimacy from '../../../../infrastructure/types/Functions/CustomDataSourceLegitimacy';
import Declaration from '../../../../infrastructure/types/Functions/Declaration';
import OutsideContextDetails from '../../../../infrastructure/types/Functions/OutsideContextDetails';
import actions from '../../../../store/actions';
import store from '../../../../store/store';
import Assignment from '../../../../types/functions/Assignment/Assignment';
import Condition from '../../../../types/functions/Condition/Condition';
import locationType from '../../../../types/functions/Location/LocationType';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import Operand from '../../../../types/functions/Operand/Operand';
import OperandRules from '../../../../types/functions/Operand/OperandRules';
import OperandModalState from '../../../Functions/Operand/Modal/OperandModalState';
import validateOperand from '../../../Functions/Operand/OperandBuilder.Validation';
import MatchingKeyOperandSetBlockBuilderProps from './MatchingKeyOperandSetBlockBuilderProps';
import MatchingKeyOperandSetBuilderProps from './MatchingKeyOperandSetBuilderProps';
import arrayItemHelper from '../../../../infrastructure/helpers/functions/common/arrayItemHelper';
import getArgumentDefinition from '../../../../infrastructure/helpers/functions/common/argumentHelper';

class MatchingKeyOperandSetBlockBuilder<T extends Declaration> extends React.Component<MatchingKeyOperandSetBlockBuilderProps<T>> {
    private unsubscribe: Unsubscribe | undefined;
    private unsubscribeOperandSave: Unsubscribe | undefined;

    constructor(props: MatchingKeyOperandSetBlockBuilderProps<T>) {
        super(props);

        this.onOperandClick = this.onOperandClick.bind(this);
        this.onOperandSave = this.onOperandSave.bind(this);
        this.updateOperand = this.updateOperand.bind(this);

        this.onAddAssignmentClick = this.onAddAssignmentClick.bind(this);
        this.onRemoveAssignmentClick = this.onRemoveAssignmentClick.bind(this);

        this.onLogicalOperatorChange = this.onLogicalOperatorChange.bind(this);
        this.onParenthesesChange = this.onParenthesesChange.bind(this);
        this.onAddConditionClick = this.onAddConditionClick.bind(this);
        this.onRemoveConditionClick = this.onRemoveConditionClick.bind(this);
        this.onComparisonTypeChange = this.onComparisonTypeChange.bind(this);
        this.onValidateDeclarationClick = this.onValidateDeclarationClick.bind(this);

        this.onPastePieceOfCodeClick = this.onPastePieceOfCodeClick.bind(this);
        this.allowPaste = this.allowPaste.bind(this);

        this.onConditionOperandClick = this.onConditionOperandClick.bind(this);
        this.onConditionOperandSave = this.onConditionOperandSave.bind(this);

        this.resetAssignment = this.resetAssignment.bind(this);
        this.updateAssignment = this.updateAssignment.bind(this);
        this.updateCondition = this.updateCondition.bind(this);
        this.updateConditionOperand = this.updateConditionOperand.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.getDeclaration = this.getDeclaration.bind(this);
        this.getCondition = this.getCondition.bind(this);
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(this.onOperandSave);
        this.unsubscribeOperandSave = store.subscribe(this.onConditionOperandSave);
    }

    componentWillUnmount(): void {
        (this.unsubscribe as Unsubscribe)();
        (this.unsubscribeOperandSave as Unsubscribe)();
    }

    onOperandClick(location: ReportLogicLocation): void {

        let set = this.props.helper.getSet(this.props.reportConfig, location);

        let copy = copyObject(location);

        let i = copy.argumentIndexes.shift() as number;

        let operand = operandHelper.getOperand(set[i], copy);

        let argumentDefinition = getArgumentDefinition(null, set[i], copy, this.props.reportConfig.dictionaries, this.props.reportConfig.userDefinedFunctions);

        let legitimacy: CustomDataSourceLegitimacy = { isin: false, lei: false, exchangeRates: false, annaDsbUpiEnrichment: false, annaDsbUpi: false, lse: false, firdsFcaInstrument: false, firdsEsmaInstrument: false, fcaRegulatedEntities: false };

        let operandRules = operandRuleHelper.get(argumentDefinition, this.props.details.expectedType, this.props.details.statement, legitimacy);

        let callStack = operandHelper.getOperandCallStack(set[i], location.argumentIndexes.slice(1));
        let arrayItemLegitimacy = arrayItemHelper.getLegitimacy(callStack, operand);

        let state: OperandModalState = {
            title: this.props.details.name,
            isOpen: true,
            operand: operandHelper.prepareForEdit(operand, operandRules, outsideContextHelper.getDefaults()),
            location: location,
            dataSource1Id: this.props.helper.getDataSourceId(this.props.reportConfig, location),
            dataSource2Id: null,
            customDataSourceLegitimacy: legitimacy,
            operandRules: operandRules,
            specialScenario: { doNotPopulate: false, pseudoFunction: null },
            customDataSourceContext: null,
            error: null,
            arrayItemLegitimacy: arrayItemLegitimacy,
            parameters: null
        };

        this.dispatchStateChange(state);
    }

    onOperandSave(): void {

        let appState = store.getState();
        if (appState.action !== actions.operandModal.save) {
            return;
        }

        let state = appState.operandModalSave;
        if (state.location.statement !== this.props.details.statement) {
            return;
        }

        if (state.location.pieceOfCode !== locationType.pieceOfCode.operandsList) {
            return;
        }

        if (state.location.argumentIndexes.length === 0) {
            return;
        }

        let error = validateOperand(state.operand, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, null as any, state.operandRules);

        let changes = { error: error } as OperandModalState;

        if (error) {
            this.dispatchStateChange(changes);
            return;
        }

        this.updateOperand(state.operand, state.location);

        changes.isOpen = false;

        this.dispatchStateChange(changes);
    }

    updateOperand(operand: Operand, location: ReportLogicLocation): void {

        let set = this.props.helper.getSet(this.props.reportConfig, location);

        let i = location.argumentIndexes.shift() as number;

        let target = operandHelper.getOperand(set[i], location);

        overrideOperandHelper.override(target, operand, this.props.collections.dataSources, this.props.collections.reportFields, null as any, []);

        this.props.onChange(this.props.helper.resolveTarget(this.props.reportConfig));
    }

    dispatchStateChange(changes: OperandModalState): void {

        store.dispatch({ type: actions.operandModal.change, payload: changes });
    }

    onAddAssignmentClick(location: ReportLogicLocation): void {

        const update = (assignment: Assignment): void => assignmentHelper.add(assignment, location.assignmentNumber, false);

        this.updateAssignment(location, update);
    }

    onRemoveAssignmentClick(location: ReportLogicLocation): void {

        const update = (assignment: Assignment): void => assignmentHelper.remove(assignment, location.assignmentNumber);

        this.updateAssignment(location, update);
    }

    resetAssignment(location: ReportLogicLocation): void {

        const update = (assignment: Assignment): void => {

            let item = assignmentHelper.getByNumber(assignment, location.assignmentNumber);

            item.value = operandHelper.getEmpty();
        };

        this.updateAssignment(location, update);
    }

    onLogicalOperatorChange(location: ReportLogicLocation, operator: string): void {

        const update = (condition: Condition): Condition => {

            let target = conditionHelper.getByNumber(condition, location.conditionNumber);

            conditionModifyHelper.changeLogicalOperator(target, operator);

            return condition;
        };

        this.updateCondition(location, update);
    }

    onParenthesesChange(location: ReportLogicLocation, count: number, type: string): void {

        const update = (condition: Condition): Condition => {

            let target = conditionHelper.getByNumber(condition, location.conditionNumber);

            conditionModifyHelper.changeParenthesesCount(target, count, type);

            return condition;
        };

        this.updateCondition(location, update);
    }

    onAddConditionClick(location: ReportLogicLocation, operator: string | null): void {

        const update = (condition: Condition): Condition => {

            let added = conditionHelper.add(condition, operator);

            return added;
        };

        this.updateCondition(location, update);
    }

    onRemoveConditionClick(location: ReportLogicLocation): void {

        const update = (condition: Condition): Condition => conditionHelper.remove(condition, location.conditionNumber);

        this.updateCondition(location, update);
    }

    onComparisonTypeChange(location: ReportLogicLocation, value: string): void {

        const update = (condition: Condition): Condition => {

            let target = conditionHelper.getByNumber(condition, location.conditionNumber);

            Object.assign(target, { comparisonType: value });

            return condition;
        };

        this.updateCondition(location, update);
    }

    onConditionOperandClick(location: ReportLogicLocation): void {

        let operand: Operand;
        let outsideContextDetails: OutsideContextDetails;
        let operandRules = {} as OperandRules;
        let title: string;
        let doNotPopulate: boolean;
        let dataSource1Id: number | null;
        let dataSource2Id: number | null;

        let legitimacy = customDataSourceHelper.getLegitimacy(this.props.reportConfig, location.statement, this.props.declarationHelper.getDataSourceId(this.props.reportConfig, location));

        let condition = this.getCondition(location);

        operand = conditionHelper.getOperand(condition, location);

        outsideContextDetails = outsideContextHelper.get(condition, null, location, this.props.reportConfig.lookups, this.props.type);

        let previousLocationFildId = location.fieldId;

        if (location.pieceOfCode === locationType.pieceOfCode.conditionOperand) {

            let exactCondition = conditionHelper.getExactConditionByNumber(condition, location.conditionNumber);

            if (exactCondition !== null && this.props.getOperandRuleBundle) {

                operandRules = operandRuleHelper.getOperandRules(
                    location,
                    exactCondition,
                    this.props.getOperandRuleBundle,
                    operand,
                    this.props.reportConfig,
                    legitimacy,
                    this.props.collections
                )
            }
            else {

                let argumentDefinition = getArgumentDefinition(condition, null, location, this.props.reportConfig.dictionaries, this.props.reportConfig.userDefinedFunctions);

                let expectedType = operand2ExpectedTypeHelper.resolve(condition, location, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields);

                operandRules = operandRuleHelper.get(argumentDefinition, expectedType, this.props.type, legitimacy);
            }
        }

        location.fieldId = previousLocationFildId;

        doNotPopulate = false;

        title = `Operand ${location.operandNumber}`;

        let conditionOperand = conditionHelper.getExactConditionOperand(condition, location);
        let callStack = operandHelper.getOperandCallStack(conditionOperand, location.argumentIndexes);
        let arrayItemLegitimacy = arrayItemHelper.getLegitimacy(callStack, operand);

        if (location.statement === locationType.statement.lookup) {

            let lookup = lookupHelper.getByNumber(this.props.reportConfig.lookups, location.statementNumber);

            dataSource1Id = lookup.dataSource1Id;
            dataSource2Id = lookup.dataSource2Id;
        }
        else {

            dataSource1Id = outsideContextDetails.isOutsideContext ? outsideContextDetails.dataSourceId : this.props.declarationHelper.getDataSourceId(this.props.reportConfig, location);
            dataSource2Id = null;
        }

        let state: OperandModalState = {
            title: title,
            isOpen: true,
            operand: operandHelper.prepareForEdit(operand, operandRules, outsideContextDetails),
            location: location,
            dataSource1Id: dataSource1Id,
            dataSource2Id: dataSource2Id,
            operandRules: operandRules,
            customDataSourceLegitimacy: legitimacy,
            specialScenario: { doNotPopulate: doNotPopulate, pseudoFunction: null },
            customDataSourceContext: outsideContextDetails.customDataSource,
            error: null,
            arrayItemLegitimacy: arrayItemLegitimacy,
            parameters: null
        };

        this.dispatchStateChange(state);
    }

    onConditionOperandSave(): void {

        let appState = store.getState();
        if (appState.action !== actions.operandModal.save) {
            return;
        }

        let state = appState.operandModalSave;
        if (state.location.statement !== this.props.type) {
            return;
        }

        if (state.location.pieceOfCode !== locationType.pieceOfCode.conditionOperand) {
            return;
        }

        let error = state.specialScenario.doNotPopulate ? null : validateOperand(state.operand, this.props.reportConfig.userDefinedFunctions, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields, state.operandRules);

        let changes = { error: error } as OperandModalState;

        if (error) {
            this.dispatchStateChange(changes);
            return;
        }

        this.updateCondition(state.location, condition => this.updateConditionOperand(state, condition));

        changes.isOpen = false;

        this.dispatchStateChange(changes);
    }

    onValidateDeclarationClick(location: ReportLogicLocation): void {

        let declarations = this.props.declarationHelper.getDeclarations(this.props.reportConfig, location);

        let declaration = this.props.declarationHelper.getDeclaration(declarations, location);

        this.props.validate(declaration);

        this.props.onDeclarationChange(declarations);
    }

    onPastePieceOfCodeClick(location: ReportLogicLocation): void {

        if (!typeHelper.isObject(this.props.clipboard)) {
            return;
        }

        let clipboard = this.props.clipboard as ReportLogicLocation;

        if (clipboard.pieceOfCode === locationType.pieceOfCode.condition) {

            let update = (_condition: Condition): Condition => {

                let source = this.getCondition(clipboard);

                return copyObject(source);
            };

            this.updateCondition(location, update);

            return;
        }

        if (clipboard.pieceOfCode === locationType.pieceOfCode.assignmentValue) {

            let declaration = this.getDeclaration(clipboard);

            let source = assignmentHelper.getByNumber(declaration.assignment, clipboard.assignmentNumber);

            let update = (assignment: Assignment): void => {

                let target = assignmentHelper.getByNumber(assignment, location.assignmentNumber) as Assignment;

                target.doNotPopulate = false;
                target.value = copyObject(source.value);
            };

            this.updateAssignment(location, update);
        }
    }

    allowPaste(location: ReportLogicLocation): boolean {

        if (!typeHelper.isObject(this.props.clipboard)) {

            return false;
        }

        let clipboardLocation = this.props.clipboard as ReportLogicLocation;

        // Allow copy/paste between Client Matching Key and Endpoint Matching Key locations
        let allowForMatchingKey =
            (clipboardLocation.statement === locationType.statement.matchingKey && location.statement === locationType.statement.matchingKeyEndPoint) ||
            (clipboardLocation.statement === locationType.statement.matchingKeyEndPoint && location.statement === locationType.statement.matchingKey);

        let proceed =
            (clipboardLocation.statement === location.statement || allowForMatchingKey) &&
            clipboardLocation.pieceOfCode === location.pieceOfCode;

        if (!proceed) {

            return false;
        }

        let isSameItem =
            clipboardLocation.statementNumber === location.statementNumber &&
            clipboardLocation.assignmentNumber === location.assignmentNumber;

        return !isSameItem;
    }

    updateAssignment(location: ReportLogicLocation, update: (assignment: Assignment) => void): void {

        let declarations = this.props.declarationHelper.getDeclarations(this.props.reportConfig, location);

        let declaration = this.props.declarationHelper.getDeclaration(declarations, location);

        update(declaration.assignment);

        this.props.onDeclarationChange(declarations);
    }

    getCondition(location: ReportLogicLocation): Condition {

        let declaration = this.getDeclaration(location);

        let item = assignmentHelper.getByNumber(declaration.assignment, location.assignmentNumber);

        return item.condition as Condition;
    }

    updateCondition(location: ReportLogicLocation, update: (condition: Condition) => Condition): void {

        const updateAssignement = (assignment: Assignment): void => {

            let target = assignmentHelper.getByNumber(assignment, location.assignmentNumber);

            target.condition = update(target.condition as Condition);
        };

        this.updateAssignment(location, updateAssignement);
    }

    updateConditionOperand(state: OperandModalState, condition: Condition): Condition {

        let operand = conditionHelper.getOperand(condition, state.location);

        overrideOperandHelper.override(operand, state.operand, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields, this.props.reportConfig.variables);

        autoCompleteHelper.execute(condition);

        return condition;
    }

    updateValue(location: ReportLogicLocation, operand: Operand, assignment: Assignment): void {

        let item = assignmentHelper.getByNumber(assignment, location.assignmentNumber);

        item.doNotPopulate = false;

        let target = assignmentHelper.getValue(assignment, location, true);

        overrideOperandHelper.override(target, operand, this.props.collections.dataSources, this.props.collections.reportFields, this.props.collections.customDataSourceFields, this.props.reportConfig.variables);
    }

    getDeclaration(location: ReportLogicLocation): T {

        let declarations = this.props.declarationHelper.getDeclarations(this.props.reportConfig, location);

        return this.props.declarationHelper.getDeclaration(declarations, location);
    }

    render(): JSX.Element {

        let props: MatchingKeyOperandSetBuilderProps = {
            isWaiting: this.props.isWaiting,
            isReadOnly: this.props.isReadOnly,
            selectedSetNumber: this.props.selectedSetNumber,
            reportConfig: this.props.reportConfig,
            collections: this.props.collections,
            onRemoveClick: this.props.onRemoveClick,
            onOperandClick: this.onOperandClick,
            onChange: this.props.onItemChange,
            configurationData: this.props.configurationData,

            onAddAssignmentClick: this.onAddAssignmentClick,
            onRemoveAssignmentClick: this.onRemoveAssignmentClick,
            onResetAssignmentClick: this.resetAssignment,
            onLogicalOperatorChange: this.onLogicalOperatorChange,
            onParenthesesChange: this.onParenthesesChange,
            onAddConditionClick: this.onAddConditionClick,
            onRemoveConditionClick: this.onRemoveConditionClick,
            onComparisonTypeChange: this.onComparisonTypeChange,
            onConditionOperandClick: this.onConditionOperandClick,
            onCopyClick: this.props.onClipboardChange,
            onPasteClick: this.onPastePieceOfCodeClick,
            onValidateDeclarationClick: this.onValidateDeclarationClick,
            allowPaste: this.allowPaste,

        };

        return (
            <>
                {React.createElement(this.props.childComponentType, props)}
            </>
        );
    }
}

export default MatchingKeyOperandSetBlockBuilder;
