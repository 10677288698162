import dataType from '../../../infrastructure/constants/dataType';

const guidance = {
    name: 'This is the name of the field. If header row is present, then solution automatically detects and populated the field name. If header row is not present or any field is added manually by user then this field needs to be populated manually.',
    type: `Data types identified automically by Control Box: ${Object.values(dataType).join('/')}. User has the ability to change it.`,
    format: 'Control box automatically identifies different formats for Date/Time/DateTime. User has the ability to change it.',
    mandatory: 'Mandatory or Optional field.',
    trim: 'Value will be trimmed when parsing data.',
    example: 'CB auto populates this field from the first data line of the file. This indicates example values for each field.',
    replacements: 'User can perform conversion of characters by uploading a csv file with "Before" and "After" values. This feature is applicable only for data type: Strings.',
    comment: 'Free text field to be used to add notes by user.',
    systemFieldFileName: 'This field stores date from the file name. Allowed Date formats: yyyy-MM-dd/ yyyyMMdd / yyyy_MM_dd. Example: "abc_20240206.csv".',
    fileNameFromFile: 'This is the name of the file which contained this record.',
    alternativeNames: 'These are the alternate field names.',
    ingestionFunctions: 'Functions for the fields.',
    references: 'Show which config ids have used the given field in which modules.'
};

export default guidance;
