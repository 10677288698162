import dataType from '../../../constants/dataType';
import FieldDefinition from '../../../../types/common/FieldDefinition';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import selectMany from '../../common/array/selectMany';

const getById = (dataSources: IngestionConfig[], dataSourceId: number): IngestionConfig => {

    return dataSources.find(x => x.id === dataSourceId) as IngestionConfig;
};

const getByFieldId = (dataSources: IngestionConfig[], fieldId: number): IngestionConfig => {

    return dataSources.find(x => x.fieldDefinitions.some(f => f.id === fieldId)) as IngestionConfig;
};

const getFieldDefinitionById = (dataSources: IngestionConfig[], fieldId: number): FieldDefinition => {

    return selectMany(dataSources, x => x.fieldDefinitions).find(x => x.id === fieldId) as FieldDefinition;
};

const getEmptyFieldDefinition = (): FieldDefinition => {

    return {
        id: null,
        name: '',
        type: dataType.string,
        formats: null as any,
        replacements: null as any,
        exampleValue: '',
        isRequired: false,
        trim: false,
        comment: ''
    };
};

const dataSourceHelper = {
    getById,
    getByFieldId,
    getFieldDefinitionById,
    getEmptyFieldDefinition
};

export default dataSourceHelper;
