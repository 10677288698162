import './InfoIcon.scss';
import { ReactComponent as Icon } from '../BootstrapIcons/info-small.svg';
import React from 'react';

interface Props {
    info: string;
}

class InfoIcon extends React.Component<Props> {

    render(): JSX.Element {

        return (
            <span className="info-icon" onClick={async() => await navigator.clipboard.writeText(this.props.info)} title={this.props.info}>
                <Icon />
            </span>
        );
    }
}

export default InfoIcon;
