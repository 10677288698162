import EnvironmentConfig from './environmentConfig';

const resolveConfig = (): EnvironmentConfig => {

    const config: EnvironmentConfig = {
        env: {
            name: process.env.REACT_APP_ENV || 'dev'
        },
        server: {
            apiUrl: process.env.REACT_APP_APIURL || 'http://localhost:5102'
        },
        identity: {
            authority: process.env.REACT_APP_AUTHURL || 'http://localhost:5100'
        }
    };

    return config;
};

export default resolveConfig;

