import './ReportFieldInstructionsModal.scss';
import { getVisibility } from '../../../../infrastructure/helpers/html/htmlUtils';
import { ReactComponent as LinkIcon } from '../../../Common/BootstrapIcons/link.svg';
import Modal from '../../../Common/Modal/Modal';
import ModalState from '../../../Common/Modal/ModalState';
import React from 'react';
import ReportFieldInstructions from '../../../../types/report/ReportFieldInstructions';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import { createValidationEsmaTable, getClassOfSpecifiedDerivativesContractsString, getDetailsToBeReportedString, getExplanatoryNotesString, getFormatToBeReportedString, getValidationsString, shouldShowRegimeSpecificColumn } from '../../../Common/Modals/ReportFieldInstructionEditModal/ReportFieldInstructionHelper';
import reportRegime from '../../../../infrastructure/constants/reportRegime';

interface ReportFieldInstructionsModalProps {
    state: ModalState<ReportFieldInstructions>;
    close: () => void;
    regime: string;
}

class ReportFieldInstructionsModal extends React.Component<ReportFieldInstructionsModalProps> {

    getSection(title: string, html: string, url: string, additionalContent?: JSX.Element | null): JSX.Element {
        return (
            <>
                <div className="title-container">

                    <h5>{title}</h5>
                    {
                        typeHelper.isString(url) &&
                        <div>
                            <a href={url} target="_blank" rel="noopener noreferrer" title="Compliance Guidance"><LinkIcon /></a>
                        </div>
                    }
                </div>

                <div className="section">
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                </div>
                {additionalContent}
            </>
        );
    }

    render(): JSX.Element {

        const validationEsmaTableRows = (this.props.regime === reportRegime.REFIT || 
            this.props.regime === reportRegime._UK)  && 
            this.props.state.context.validationEsmaTableRows ?
            createValidationEsmaTable(this.props.state.context.validationEsmaTableRows)
            : null

        return (
            <Modal state={this.props.state} close={this.props.close} size="extra-large">

                <div className="report-field-instructions">
                    {this.getSection(getDetailsToBeReportedString(this.props.regime), this.props.state.context.detailsToBeReported, this.props.state.context.detailsToBeReportedUrl)}

                    {this.getSection(getFormatToBeReportedString(this.props.regime), this.props.state.context.formatToBeReported, this.props.state.context.formatToBeReportedUrl)}

                    {shouldShowRegimeSpecificColumn(this.props.regime) &&
                        this.getSection(getClassOfSpecifiedDerivativesContractsString(this.props.regime), this.props.state.context.classOfSpecifiedDerivativesContracts, this.props.state.context.classOfSpecifiedDerivativesContractsUrl)}

                    {shouldShowRegimeSpecificColumn(this.props.regime) &&
                        this.getSection(getExplanatoryNotesString(this.props.regime), this.props.state.context.explanatoryNotes, this.props.state.context.explanatoryNotesUrl)}

                    {this.getSection(getValidationsString(this.props.regime), this.props.state.context.validations, this.props.state.context.validationsUrl, validationEsmaTableRows)}

                    {this.getSection('CN Population Guidelines', this.props.state.context.cnPopulationGuidelines, this.props.state.context.cnPopulationGuidelinesUrl)}

                    <div style={getVisibility(typeHelper.isString(this.props.state.context.t) || typeHelper.isString(this.props.state.context.p))}>
                        <table className="grid">
                            <thead>
                                <tr>
                                    <th>T</th>
                                    <th>P</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.props.state.context.t}</td>
                                    <td>{this.props.state.context.p}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ReportFieldInstructionsModal;
