import React from 'react';
import pseudocodeHelper from '../../../infrastructure/helpers/functions/common/pseudocodeHelper';
import locationType from '../../../types/functions/Location/LocationType';
import ReportLogicLocation from '../../../types/functions/Location/ReportLogicLocation';
import UserDefinedFunction from '../../../types/report/UserDefinedFunction';
import AssignmentBuilder from '../../Functions/Assignment/AssignmentBuilder';
import DeclarationBuilderProps from '../../Functions/Declaration/DeclarationBuilderProps';

class UserDefinedFunctionBodyBuilder extends React.Component<DeclarationBuilderProps<UserDefinedFunction>> {

    constructor(props: DeclarationBuilderProps<UserDefinedFunction>) {
        super(props);

        this.onResetAssignmentClick = this.onResetAssignmentClick.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.getFunction = this.getFunction.bind(this);
        this.onReorderConditions = this.onReorderConditions.bind(this);
    }

    onResetAssignmentClick(location: ReportLogicLocation): void {

        this.props.onResetAssignmentClick(location, true);
    }

    getLocation(): ReportLogicLocation {

        let location = {} as ReportLogicLocation;

        location.statement = locationType.statement.userDefinedFunction;
        location.statementNumber = this.props.declarationBatchNumber as number;

        return location;
    }

    getFunction(): UserDefinedFunction {

        return this.props.reportConfig.userDefinedFunctions.find(d => d.number === this.props.declarationBatchNumber) as UserDefinedFunction;
    }

    onReorderConditions(func: UserDefinedFunction, error?: string) {

        if (error && !func.errors.includes(error)) {
            func.errors.push(error);
        }

        let location = this.getLocation();
        this.props.onDeclarationChange(location, func);
    }

    render(): JSX.Element {

        let location = this.getLocation();
        let func = this.getFunction();

        return (
            <>
                <AssignmentBuilder
                    isReadOnly={this.props.isReadOnly}
                    location={location}
                    assignment={func.assignment}
                    fieldName={pseudocodeHelper.wrapInBrackets(func.name)}
                    functions={this.props.reportConfig.userDefinedFunctions}
                    lookups={this.props.reportConfig.lookups}
                    dictionaries={this.props.reportConfig.dictionaries}
                    variables={this.props.reportConfig.variables}
                    dataSources={this.props.collections.dataSources}
                    reportFields={[]}
                    customDataSourceFields={null as any}
                    onAddAssignmentClick={this.props.onAddAssignmentClick}
                    onRemoveAssignmentClick={this.props.onRemoveAssignmentClick}
                    onResetAssignmentClick={this.onResetAssignmentClick}
                    onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                    onParenthesesChange={this.props.onParenthesesChange}
                    onAddConditionClick={this.props.onAddConditionClick}
                    onRemoveConditionClick={this.props.onRemoveConditionClick}
                    onComparisonTypeChange={this.props.onComparisonTypeChange}
                    onOperandClick={this.props.onOperandClick}
                    onCopyClick={this.props.onCopyClick}
                    onPasteClick={this.props.onPasteClick}
                    onValidateFieldClick={this.props.onValidateDeclarationClick}
                    allowPaste={this.props.allowPaste}
                    onReorderAssignmentConditions={(error) => { this.onReorderConditions(func, error); }} />
            </>
        );
    }
}

export default UserDefinedFunctionBodyBuilder;
