import dataSourceHelper from '../../../../infrastructure/helpers/functions/common/dataSourceHelper';
import IngestionConfig from '../../../../types/ingestion/IngestionConfig';
import Lookup from '../../../../types/functions/Lookup';
import lookupHelper from '../../../../infrastructure/helpers/functions/lookup/lookupHelper';
import pseudocodeHelper from '../../../../infrastructure/helpers/functions/common/pseudocodeHelper';
import SelectOption from '../../../../infrastructure/types/SelectOption';

const createOption = (lookup: Lookup, dataSources: IngestionConfig[]): SelectOption => {

    let dataSource = dataSourceHelper.getById(dataSources, lookup.dataSource2Id as number);

    return {
        value: lookup.number.toString(),
        text: pseudocodeHelper.wrapInBrackets(lookupHelper.getName(lookup, dataSource.name))
    };
};

export { createOption };