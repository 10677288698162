import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import ReportConfig from '../../../../types/report/ReportConfig';
import UserDefinedFunction from '../../../../types/report/UserDefinedFunction';
import NumberDeclarationHelper from '../../../types/Functions/NumberDeclarationHelper';

class UserDefinedFunctionBodyHelper extends NumberDeclarationHelper<UserDefinedFunction> {

    getDeclarations(config: ReportConfig, location: ReportLogicLocation): UserDefinedFunction[] {

        return config.userDefinedFunctions;
    }

    getEmpty(rawDataSourceId: number | null): UserDefinedFunction {

        //let assignment = assignmentHelper.getEmpty(false);
        //assignment.number = 1;

        //return {
        //    id: null,
        //    name: '',
        //    number: 0,
        //    dataSourceId: rawDataSourceId,
        //    returnType: dataType.string,
        //    assignment: assignment,
        //    businessDescription: '',
        //    errors: [],
        //};

        return {} as UserDefinedFunction;
    };
}

const userDefinedFunctionBodyHelper = new UserDefinedFunctionBodyHelper();

export default userDefinedFunctionBodyHelper;
