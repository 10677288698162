import ReportFieldDefinition from '../../../types/report/ReportFieldDefinition';
import SelectOption from '../../../infrastructure/types/SelectOption';

let empty: SelectOption = { value: '', text: '- Choose Field -', disabled: true };

const buildOption = (field: ReportFieldDefinition): SelectOption => {

    return { value: field.id.toString(), text: `${field.number}. ${field.name}` };
};

const buildOptions = (fields: ReportFieldDefinition[]): SelectOption[] => {

    return [empty].concat(fields.map(buildOption));
};

export {
    buildOptions
};