import Grouping from '../../../types/Grouping'
import distinct from './distinct';

const groupBy = <TSource, TKey, TSelect>(
    source: TSource[],
    key: (x: TSource) => TKey,
    select: (x: TSource) => TSelect): Grouping<TKey, TSelect>[] => {

    let keys = distinct(source, key);

    let groupings: Grouping<TKey, TSelect>[] = keys.map(k => {
        return {
            key: k,
            group: source.filter(x => key(x) === k).map(select)
        };
    });

    return groupings;
};

export default groupBy;