import { SelectSearchOption } from "react-select-search";
import SelectOption from "../../../../infrastructure/types/SelectOption";
import ReportConfig from "../../../../types/report/ReportConfig";
import operandHelper from "../../../../infrastructure/helpers/functions/operand/operandHelper";
import Operand from "../../../../types/functions/Operand/Operand";
import IdAndName from "../../../../types/report/IdAndName";
import ComponentType from "../../../../types/report/ComponentType";
import reportConfigNavigation from "../../../../infrastructure/helpers/report/navigation/reportConfigNavigation";
import operandType from "../../../../infrastructure/constants/functions/operandType";
import ReportCollections from "../../../../infrastructure/types/Functions/ReportCollections";

const getOptions = (fields: string[], defaultOptioName?: string): SelectOption[] => {

    let options: SelectOption[] = [{ value: defaultOptioName || '', text: defaultOptioName || '' }];

    fields.forEach((field) => {
        options.push({ value: field, text: field });
    });

    return options;
}

const getFieldsOptions = (fields: IdAndName[], defaultOptionName?: string): SelectSearchOption[] => {

    let options: SelectSearchOption[] = [{ value: defaultOptionName || '', name: defaultOptionName || '' }];

    fields.forEach((field) => {
        options.push({ value: field.id.toString(), name: field.name });
    });

    return options;
}

const getSelectOptions = (collections: ReportCollections, reportConfig: ReportConfig, typeOfOperand: string, fieldsIds?: number[], defaultOption?: string): SelectOption[] => {

    let references = new Set<string>();

    let result: SelectOption[] = defaultOption ? [{ text: defaultOption, value: defaultOption }] : [];

    operandHelper.traverseReportConfigSectionsForOperands(reportConfig,
        (operand: Operand,
            itemName: string | undefined,
            componentType: ComponentType | undefined,
            numberOrId?: number,
            mainComponentType?: ComponentType | undefined,
            mainComponentNumber?: number,
            mainComponentName?: string) => {

            if (operand.operandType === typeOfOperand) {

                numberOrId = numberOrId || 0;

                if (fieldsIds) {
                    let matchFound = false;

                    switch (typeOfOperand) {
                        case operandType.field: matchFound = fieldsIds.includes(operand.fieldId || 0); break;
                        case operandType.reportField: matchFound = fieldsIds.includes(operand.reportFieldId || 0); break;
                        default: break;
                    }

                    if (matchFound) {
                        let mainName = itemName;

                        switch (componentType) {
                            case ComponentType.AggregationField: mainName = collections?.dataSources?.find(x => x.id === reportConfig.rawDataSourceId)?.fieldDefinitions?.find(x => x.id === numberOrId)?.name; break;
                            case ComponentType.CaseField: mainName = collections?.reportFields?.find(x => x.id === numberOrId)?.name; break;
                        }

                        let text = buildOptionText(componentType as ComponentType, mainName as string, mainComponentName);

                        if (!references.has(text)) {
                            references.add(text);

                            let optionValue = '';

                            switch (componentType) {
                                case ComponentType.DictionaryField:
                                case ComponentType.CaseField:
                                case ComponentType.AggregationField:
                                case ComponentType.AnnaDsbUpiEnrichmentSelector:
                                case ComponentType.FirdsEsmaInstrumentSelectors:
                                case ComponentType.FirdsFcaInstrumentSelectors: optionValue = buildOptionValue(componentType as ComponentType, numberOrId, mainComponentNumber); break;
                                default: optionValue = buildOptionValue(componentType as ComponentType, numberOrId); break;
                            }

                            result.push({ text, value: optionValue });
                        }
                    }
                }
            }
        });

    return result.sort((prev, next) => { return prev.text === defaultOption || next.text === defaultOption || prev.text > next.text ? 1 : prev.text < next.text ? -1 : 0 });
}

const buildOptionValue = (componentType: ComponentType, number: number, otherNumber?: number): string => {

    switch (componentType) {
        case ComponentType.Lookup: return reportConfigNavigation.buildLookupId(number);
        case ComponentType.AnnaDsbUpiEnrichmentSelector: return reportConfigNavigation.buildAnnaDsbUpiEnrichmentSelectorId(number, otherNumber || 0);
        case ComponentType.AnnaDsbUpiSelector: return reportConfigNavigation.buildAnnaDsbUpiSelectorId(number);
        case ComponentType.IsinSelector: return reportConfigNavigation.buildIsinSelectorId(number);
        case ComponentType.LeiSelector: return reportConfigNavigation.buildLeiSelectorId(number);
        case ComponentType.LseSelector: return reportConfigNavigation.buildLSESelectorId(number);
        case ComponentType.FirdsEsmaInstrumentSelectors: return reportConfigNavigation.buildFirdsEsmaInstrumentSelectorId(number, otherNumber || 0);
        case ComponentType.FirdsFcaInstrumentSelectors: return reportConfigNavigation.buildFirdsFcaInstrumentSelectorId(number, otherNumber || 0);
        case ComponentType.ExchangeRatesSelector: return reportConfigNavigation.buildExchangeRatesSelectorId(number);
        case ComponentType.FCARegulatedEntitiesSelector: return reportConfigNavigation.buildFCARegulatedEntitiesSelectorId(number);
        case ComponentType.Variable: return reportConfigNavigation.buildVariableId(number);
        case ComponentType.Case: return reportConfigNavigation.buildCaseId(number)
        case ComponentType.CaseField: return reportConfigNavigation.buildReportFieldId(number, otherNumber);
        case ComponentType.Aggregation: return reportConfigNavigation.buildAggregationId(number);
        case ComponentType.AggregationField: return reportConfigNavigation.buildAggregatedRecordFieldId(number, otherNumber);
        case ComponentType.Filter: return reportConfigNavigation.buildFilterId(number);
        case ComponentType.AccuracyValidationCore: return reportConfigNavigation.buildValidationId(number, 'core');
        case ComponentType.AccuracyValidationStandard: return reportConfigNavigation.buildValidationId(number, 'standard');
        case ComponentType.OverReportFilter: return reportConfigNavigation.buildOverReportFilter(number);
        case ComponentType.UnderReportFilter: return reportConfigNavigation.buildUnderReportFilter(number);
        case ComponentType.Enrichment: return reportConfigNavigation.buildReportFieldId(number);
        case ComponentType.DictionaryField: return reportConfigNavigation.buildDictionaryFieldId(number, otherNumber);

        default: return '';
    }
}

const buildOptionText = (componentType: ComponentType, name: string, secondName?: string | undefined) => {
    switch (componentType) {
        case ComponentType.Lookup: return `Lookup: "${name}"`;
        case ComponentType.AnnaDsbUpiEnrichmentSelector: return `Cache Key Anna Dsb Upi Enrichment ${name}: "${secondName}"`;
        case ComponentType.AnnaDsbUpiSelector: return `Cache Key Anna Dsb Upi: "${name}"`;
        case ComponentType.IsinSelector: return `Cache Key Isin: "${name}"`;
        case ComponentType.LeiSelector: return `Cache Key Lei: "${name}"`;
        case ComponentType.LseSelector: return `Cache Key Lse: "${name}"`;
        case ComponentType.FirdsEsmaInstrumentSelectors: return `Cache Key Firds Esma Instrument ${name}: "${secondName}"`;
        case ComponentType.FirdsFcaInstrumentSelectors: return `Cache Key Firds Fca Instrument ${name}: "${secondName}"`;
        case ComponentType.ExchangeRatesSelector: return `Cache Key Exchange Rates: "${name}"`;
        case ComponentType.FCARegulatedEntitiesSelector: return `Cache Key Fca Regulated Entities: "${name}"`;
        case ComponentType.Variable: return `Variable: "${name}"`;
        case ComponentType.Case: return `Reporting Case: "${name}"`;
        case ComponentType.CaseField: return `Reporting Case: "${secondName}", Field: "${name}"`;
        case ComponentType.Aggregation: return `Aggregation: "${name}"`;
        case ComponentType.AggregationField: return `Aggregation: "${secondName}", Field: "${name}"`;
        case ComponentType.Filter: return `Filter: "${name}"`;
        case ComponentType.AccuracyValidationCore: return `Core Validation: "${name}"`;
        case ComponentType.AccuracyValidationStandard: return `Cn Validation: "${name}"`;
        case ComponentType.OverReportFilter: return `Over Report Filter: "${name}"`;
        case ComponentType.UnderReportFilter: return `Under Report Filter: "${name}"`;
        case ComponentType.DictionaryField: return `Dictionary: "${secondName}", Field: "${name}"`;

        default: return '';
    }
}

const referenceHelper = {
    getOptions,
    getFieldsOptions,
    getSelectOptions
}

export default referenceHelper;