import React from 'react';
import ReportCollections from '../../../infrastructure/types/Functions/ReportCollections';
import Operand from '../../../types/functions/Operand/Operand';
import ReportConfig from '../../../types/report/ReportConfig';
import NumberRange from '../../Common/NumberRange/NumberRange';
import getArgument from '../Condition/ContitionItem/getArgument';

interface OperandSetProps {
    isReadOnly: boolean;
    assignment: string;
    function: string;
    set: Operand[];
    assignmentNumber: number,
    reportConfig: ReportConfig;
    collections: ReportCollections;
    onCountChange: (operands: Operand[], count: number) => void;
    onOperandClick: (asignmentNumber: number, argumentIndexes: number[], event: React.MouseEvent<HTMLElement>) => void;
    maxArgumentsCount: number;
}

class OperandSet extends React.Component<OperandSetProps> {

    constructor(props: OperandSetProps) {
        super(props);

        this.onCountChange = this.onCountChange.bind(this);
    }

    onCountChange(count: number): void {
        this.props.onCountChange(this.props.set, count);
    }

    render(): JSX.Element {
        return (
            <span className="code-block">
                {
                    this.props.assignment.length > 0 &&
                    <span className="code">{this.props.assignment}</span>
                }

                <span className="code function">{this.props.function}</span>

                <span className="code">(</span>

                {this.props.set.map((operand, i, arr) =>
                    getArgument(
                        operand,
                        this.props.assignmentNumber,
                        [i],
                        null as any,
                        this.props.reportConfig.userDefinedFunctions,
                        this.props.reportConfig.lookups,
                        this.props.reportConfig.dictionaries,
                        this.props.reportConfig.variables,
                        this.props.collections.dataSources,
                        this.props.collections.reportFields,
                        null as any,
                        operand.selectorNumber,
                        (_number, argumentIndexes, event) => this.props.onOperandClick(_number, argumentIndexes, event),
                        i,
                        i !== arr.length - 1,
                        this.props.isReadOnly
                    )
                )}

                <span className="code">)</span>

                <NumberRange min={1} max={this.props.maxArgumentsCount} value={this.props.set.length} onChange={this.onCountChange} />
            </span>
        );
    }
}

export default OperandSet;
