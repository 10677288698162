import appRoutes from '../../infrastructure/constants/appRoutes';
import history from '../../infrastructure/helpers/react/history';
import identityProvider from '../../infrastructure/authorization/identityProvider';
import React from 'react';
import typeHelper from '../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../infrastructure/helpers/common/urlHelper';

class LoginComplete extends React.Component {

    constructor(props: any) {
        super(props);

        let params = urlHelper.deserializeQuery(history.location.hash);

        if (!typeHelper.isString(params['access_token'])) {
            return;
        }

        identityProvider.loginComplete().then(() => history.replace(appRoutes.home));
    }

    render(): string {
        return 'Authenticating...';
    }
}

export default LoginComplete;
