import './UiDataGrid.scss';
import { createDataRow, createHeaderCell } from '../DataGrid.Helpers';
import ColumnDefinition from '../../../../infrastructure/types/DataGrid/ColumnDefinition';
import React from 'react';

interface UiDataGridProps<T> {
    columns: ColumnDefinition<T>[];
    data: T[];
    getKey: (record: T, i: number) => string | number;
    tableClassName?: string;
}

class UiDataGrid<T> extends React.Component<UiDataGridProps<T>> {

    constructor(props: UiDataGridProps<T>) {
        super(props);

        this.getNoItemsContent = this.getNoItemsContent.bind(this);
    }

    getNoItemsContent(): JSX.Element {

        if (this.props.data.length > 0) {
            return <></>;
        }

        return (
            <tr>
                <td colSpan={this.props.columns.length} className="no-items-text">No items</td>
            </tr>
        );
    }

    render(): JSX.Element {
        return (
            <div className="grid-container">
                <table className={`${this.props.tableClassName || 'ui-data-grid grid'}`}>
                    <thead>
                        <tr>
                            {this.props.columns.map((c, i) => createHeaderCell(c, i, null as any))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.getNoItemsContent()}
                        {this.props.data.map((item, i) => createDataRow(this.props.columns, item, i, this.props.getKey))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default UiDataGrid;
