import locationType from '../../../../types/functions/Location/LocationType';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import MatchingKey from '../../../../types/report/MatchingKey';
import ReportConfig from '../../../../types/report/ReportConfig';
import DeclarationHelper from '../../../types/Functions/DeclarationHelper';
import assignmentHelper from '../assignment/assignmentHelper';

class MatchingKeyDeclarationHelper implements DeclarationHelper<MatchingKey> {

    add(_declarations: MatchingKey[], _declaration: MatchingKey): void {

        throw new Error('Not relevant');
    }

    remove(_declarations: MatchingKey[], _location: ReportLogicLocation): MatchingKey[] {

        throw new Error('Not relevant');
    }

    getDeclarations(config: ReportConfig, location: ReportLogicLocation): MatchingKey[] {

        if (location.statement === locationType.statement.matchingKey) {
            return [config.matchingKey]
        }

        if (location.statement === locationType.statement.matchingKeyEndPoint) {
            return [config.matchingKeyEndPoint]
        } 

        throw new Error('Unexpected statement');
    }

    getDeclaration(declarations: MatchingKey[], _location: ReportLogicLocation): MatchingKey {

        return declarations[0];
    }

    getDataSourceId(_config: ReportConfig, _location: ReportLogicLocation): number | null {

        return null;
    }

    getEmpty(_rawDataSourceId: number | null): MatchingKey {

        let assignment = assignmentHelper.getEmpty(true);
        assignment.number = 1;

        return {
            assignment: assignment,
            errors: [],
            operands: []
        };
    }
}

const matchingKeyDeclarationHelper = new MatchingKeyDeclarationHelper();

export default matchingKeyDeclarationHelper;
