import Aggregation from '../../../../types/report/Aggregation';
import Operand from '../../../../types/functions/Operand/Operand';
import OperandSetHelper from '../../../types/Functions/OperandSetHelper';
import ReportConfig from '../../../../types/report/ReportConfig';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';

class AggregationGroupByHelper extends OperandSetHelper<Aggregation[]> {

    getSet(config: ReportConfig, location: ReportLogicLocation): Operand[] {

        let aggregation = this.get(config, location);

        return aggregation.groupBy;
    }

    getDataSourceId(config: ReportConfig, location: ReportLogicLocation): number | null {

        let aggregation = this.get(config, location);

        return aggregation.dataSourceId;
    }

    resolveTarget(config: ReportConfig): Aggregation[] {

        return config.aggregations;
    }

    private get(config: ReportConfig, location: ReportLogicLocation): Aggregation {

        return config.aggregations.find(x => x.number === location.statementNumber) as Aggregation;
    }
}

const aggregationGroupByHelper = new AggregationGroupByHelper();

export default aggregationGroupByHelper;
