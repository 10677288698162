import CollectionBundle from './Types/CollectionBundle';
import DataGridPageRequest from '../../../infrastructure/types/DataGrid/DataGridPageRequest';
import DataGridPageResponse from '../../../infrastructure/types/DataGrid/DataGridPageResponse';
import httpClient from '../../../infrastructure/helpers/common/httpClient';
import IngestionConfig from '../../../types/ingestion/IngestionConfig';
import IngestionConfigGridItem from '../../../types/ingestion/IngestionConfigGridItem';
import ObjectIndexer from '../../../infrastructure/types/ObjectIndexer';
import ReportConfig from '../../../types/report/ReportConfig';
import ReportFieldDefinition from '../../../types/report/ReportFieldDefinition';
import typeHelper from '../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../infrastructure/helpers/common/urlHelper';

const getConfigDataSources = (clientDataSourceIds: number[], globalRefDataSourceIds: number[]): Promise<IngestionConfig[]> => {

    let client = clientDataSourceIds.map(id => urlHelper.buildRoute(['ingestion', id]));
    let globalRef = globalRefDataSourceIds.map(id => urlHelper.buildRoute(['global-ref-data-source', id]));

    let calls = client.concat(globalRef).map(route => httpClient.get<IngestionConfig>(route));

    return new Promise<IngestionConfig[]>(resolve => {

        Promise.all(calls).then(responses => resolve(responses.map(x => x.data)))
    });
};

const getCollections = (regime: string): Promise<CollectionBundle> => {

    let request: DataGridPageRequest = {
        page: 1,
        size: 1000,
        sortBy: 'Id',
        ascending: true
    };

    let call1 = httpClient.get<DataGridPageResponse<IngestionConfigGridItem>>('ingestion/page', Object.assign({}, request, { enabled: true }));
    let call2 = httpClient.get<DataGridPageResponse<IngestionConfigGridItem>>('ingestion/page', Object.assign({}, request, { enabled: false }));
    let call3 = httpClient.get<ObjectIndexer<number>>('global-ref-data-source');
    let call4 = httpClient.get<ReportFieldDefinition[]>('report-field', { regime: regime });

    return new Promise<CollectionBundle>(resolve => {

        Promise
            .all([call1, call2, call3, call4])
            .then(responses => resolve({
                enabled: responses[0].data.page,
                disabled: responses[1].data.page,
                globalRef: responses[2].data,
                reportFields: responses[3].data
            }));
    });
};

const importDataSources = async (dataSources: IngestionConfig[]): Promise<void> => {

    for (let i = 0; i < dataSources.length; i++) {

        await httpClient.post('ingestion', dataSources[i]);
    }
};

const importReportConfig = (config: ReportConfig): Promise<any> => {

    if (!typeHelper.isNumber(config.id)) {

        return httpClient.post('report', config);
    }

    let url = urlHelper.buildRoute(['report', config.id]);

    return httpClient.put(url, config);
};

export {
    getConfigDataSources,
    getCollections,
    importDataSources,
    importReportConfig
};
