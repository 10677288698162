import { AxiosResponse } from 'axios';
import { getColumns } from './CaseBuilder.Grid';
import actionItemHelper from '../../../../infrastructure/helpers/actionItem/actionItemHelper';
import ActionItemSaveRequest from '../../../../types/common/ActionItem/ActionItemSaveRequest';
import actions from '../../../../store/actions';
import Aggregation from '../../../../types/report/Aggregation';
import BespokeConditionBuilder from '../../../Functions/Bespoke/BespokeConditionBuilder';
import caseHelper from '../../../../infrastructure/helpers/functions/caseHelper';
import ComplianceComment from '../../../../types/common/ComplianceComment';
import complianceCommentHelper from '../../../../infrastructure/helpers/complianceComment/complianceCommentHelper';
import ConditionBuilder from '../../../Functions/Condition/ConditionBuilder';
import conditionDisplayHelper from '../../../../infrastructure/helpers/functions/condition/conditionDisplayHelper';
import conditionHelper from '../../../../infrastructure/helpers/functions/condition/conditionHelper';
import enrichmentHelper from '../../../../infrastructure/helpers/functions/enrichment/enrichmentHelper';
import ErrorList from '../../../Common/ErrorList/ErrorList';
import httpClient from '../../../../infrastructure/helpers/common/httpClient';
import locationType from '../../../../types/functions/Location/LocationType';
import React from 'react';
import ReportCase from '../../../../types/report/ReportCase';
import reportConfigNavigation from '../../../../infrastructure/helpers/report/navigation/reportConfigNavigation';
import ReportLogicList from '../../Common/ReportLogicList/ReportLogicList';
import ReportLogicLocation from '../../../../types/functions/Location/ReportLogicLocation';
import StatementBuilderProps from '../../../Functions/Statement/StatementBuilderProps';
import store from '../../../../store/store';
import typeHelper from '../../../../infrastructure/helpers/common/typeHelper';
import urlHelper from '../../../../infrastructure/helpers/common/urlHelper';
import Condition from '../../../../types/functions/Condition/Condition';
import identityStorage from '../../../../infrastructure/authorization/identityStorage';
import ActionitemDto from '../../../../types/common/ActionItem/ActionItemDto';
import ActionItemDto from '../../../../types/common/ActionItem/ActionItemDto';

class CaseBuilder extends React.Component<StatementBuilderProps<ReportCase>> {

    constructor(props: StatementBuilderProps<ReportCase>) {
        super(props);

        this.onDataSourceChange = this.onDataSourceChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onIsSystemGeneratedChange = this.onIsSystemGeneratedChange.bind(this);
        this.onIsSeparateOutputChange = this.onIsSeparateOutputChange.bind(this);
        this.onAggregatedChange = this.onAggregatedChange.bind(this);
        this.onAggregationChange = this.onAggregationChange.bind(this);
        this.onBusinessDescriptionChange = this.onBusinessDescriptionChange.bind(this);
        this.onBespokeToggleClick = this.onBespokeToggleClick.bind(this);
        this.onCommentClick = this.onCommentClick.bind(this);
        this.onActionItemClick = this.onActionItemClick.bind(this);
        this.onGetCommentSuccess = this.onGetCommentSuccess.bind(this);
        this.onGetActionItemDataSuccess = this.onGetActionItemDataSuccess.bind(this);
        this.getCommentCount = this.getCommentCount.bind(this);
        this.getActionItemCount = this.getActionItemCount.bind(this);
        this.getConditionContent = this.getConditionContent.bind(this);
    }

    onDataSourceChange(dataSourceId: string, number: number): void {

        let changes = {} as ReportCase;

        changes.number = number;
        changes.dataSourceId = parseInt(dataSourceId);
        changes.condition = conditionHelper.getEmpty();
        changes.fields = [];
        changes.aggregationNumber = null;

        enrichmentHelper.handleCaseChanges([changes], this.props.collections.reportFields.map(x => x.id));

        this.props.onStatementChange(changes);
    }

    onNameChange(name: string, number: number): void {

        this.props.onStatementChange({ number: number, name: name } as ReportCase);
    }

    onIsSystemGeneratedChange(value: boolean, number: number, dataSourceId: number): void {

        let changes = {} as ReportCase;

        changes.number = number;
        changes.isSystemGenerated = value;

        if (!value && dataSourceId !== this.props.reportConfig.rawDataSourceId) {

            changes.dataSourceId = this.props.reportConfig.rawDataSourceId as number;
            changes.condition = conditionHelper.getEmpty();
            changes.fields = [];

            enrichmentHelper.handleCaseChanges([changes], this.props.collections.reportFields.map(x => x.id));
        }

        this.props.onStatementChange(changes);
    }

    onIsSeparateOutputChange(value: boolean, number: number): void {

        let changes = {} as ReportCase;

        changes.number = number;
        changes.isSeparateOutput = value;

        this.props.onStatementChange(changes);
    }

    onAggregatedChange(aggregated: boolean, number: number): void {

        let changes = { number: number } as ReportCase;

        if (!aggregated) {

            changes.aggregationNumber = null;

            this.props.onStatementChange(changes);

            return;
        }

        let $case = caseHelper.getByNumber(this.props.reportConfig.cases, number);

        let aggregation = this.props.reportConfig.aggregations.find(a => a.dataSourceId === $case.dataSourceId) as Aggregation;

        changes.aggregationNumber = aggregation.number;
        changes.isSystemGenerated = true;

        this.props.onStatementChange(changes);
    }

    onAggregationChange(aggregationNumber: number, caseNumber: number): void {

        this.props.onStatementChange({ number: caseNumber, aggregationNumber: aggregationNumber } as ReportCase);
    }

    onBusinessDescriptionChange(businessDescription: string, number: number): void {

        this.props.onStatementChange({ number: number, businessDescription: businessDescription } as ReportCase);
    }

    onBespokeToggleClick(isBespoke: boolean, $case: ReportCase): void {

        let changes = { number: $case.number, isBespoke: isBespoke } as ReportCase;

        if (isBespoke) {
            changes.bespokeCondition = conditionDisplayHelper.buildConditions($case.condition, this.props.reportConfig.lookups, this.props.reportConfig.dictionaries, this.props.reportConfig.variables, this.props.collections.dataSources, [], this.props.collections.customDataSourceFields);
        }

        this.props.onStatementChange(changes);
    }

    onCommentClick(id: number): Promise<any> {

        let url = urlHelper.buildRoute(['case-comment', id]);

        return httpClient.get<ComplianceComment[]>(url).then(response => this.onGetCommentSuccess(response.data, url));
    }

    onActionItemClick(id: number): Promise<any> {

        let identity = identityStorage.get();

        let allItemsForClient = urlHelper.buildRoute(['action-item', 'by-client-id']);

        let caseActionitemsExternalIds = urlHelper.buildRoute(['action-item', 'case', id]);

        let buildRelationUrl = (actionItemId: string) => urlHelper.buildRoute(['action-item', actionItemId, 'case', id]);

        let calls: [Promise<AxiosResponse<ActionitemDto[]>>, Promise<AxiosResponse<ActionitemDto[]>>] = [httpClient.get<ActionitemDto[]>(allItemsForClient, { reportConfigId: this.props.reportConfig.id, clientId: identity.clientId || null }), httpClient.get<ActionitemDto[]>(caseActionitemsExternalIds)];

        let saveRequest = { reportConfigId: this.props.reportConfig.id, caseId: id } as ActionItemSaveRequest;

        return Promise.all(calls).then(responses => this.onGetActionItemDataSuccess(responses[0].data, responses[1].data, saveRequest, buildRelationUrl));
    }

    onGetCommentSuccess(comments: ComplianceComment[], url: string): void {

        let state = complianceCommentHelper.createModalState(comments, url);

        store.dispatch({ type: actions.complianceCommentModal.open, payload: state });
    }

    onGetActionItemDataSuccess(allActionItems: ActionItemDto[], caseActionItems: ActionitemDto[], saveRequest: ActionItemSaveRequest, buildRelationUrl: (actionItemId: string) => string): void {

        let originalActionItems: ActionItemDto[] = [];

        allActionItems.forEach((iten) => {
            originalActionItems.push(Object.assign({}, iten));
        });

        const caseItems = actionItemHelper.prepareModuleActionitems(allActionItems, caseActionItems);

        let state = actionItemHelper.createModalState(originalActionItems, allActionItems, caseItems, saveRequest, buildRelationUrl, 'Case');

        store.dispatch({ type: actions.actionItemModal.open, payload: state });
    }

    getCommentCount(id: number): number {

        return this.props.counts.caseCommentCounts[id.toString()] || 0;
    }

    getActionItemCount(id: number): number {

        return this.props.counts.caseActionItemCounts[id.toString()] || 0;
    }

    onReorderConditions(condition: Condition, $case: ReportCase) {

        $case.condition = condition;
        this.props.onStatementChange($case);
    }

    getConditionContent($case: ReportCase): JSX.Element {

        if ($case.isBespoke) {
            return (
                <BespokeConditionBuilder
                    keyword={this.props.details.keyword}
                    keyword2={this.props.details.keyword2}
                    statement={$case}
                    onChange={this.props.onStatementChange}
                    isReadOnly={this.props.isReadOnly} />
            );
        }

        let location = { statement: locationType.statement.case, statementNumber: $case.number } as ReportLogicLocation;

        let hasCondition = typeHelper.isObject($case.condition);

        return (
            <>
                {
                    hasCondition &&
                    <>
                        <ConditionBuilder
                            isReadOnly={this.props.isReadOnly}
                            allowCopy={true}
                            allwaysAllowDelete={true}
                            keyword={this.props.details.keyword}
                            functions={this.props.reportConfig.userDefinedFunctions}
                            lookups={this.props.reportConfig.lookups}
                            dictionaries={this.props.reportConfig.dictionaries}
                            variables={this.props.reportConfig.variables}
                            dataSources={this.props.collections.dataSources}
                            reportFields={[]}
                            customDataSourceFields={this.props.collections.customDataSourceFields}
                            location={location}
                            condition={$case.condition}
                            onLogicalOperatorChange={this.props.onLogicalOperatorChange}
                            onParenthesesChange={this.props.onParenthesesChange}
                            onAddConditionClick={this.props.onAddConditionClick}
                            onRemoveConditionClick={this.props.onRemoveConditionClick}
                            onComparisonTypeChange={this.props.onComparisonTypeChange}
                            onOperandClick={this.props.onOperandClick}
                            onCopyClick={this.props.onCopyClick}
                            onPasteClick={this.props.onPasteClick}
                            allowPaste={this.props.allowPaste}
                            onReorderConditions={(condition) => { this.onReorderConditions(condition, $case); }} />


                        <div className="code-block">
                            <span className="code">{this.props.details.keyword2}</span>
                        </div>
                    </>
                }
                {
                    !hasCondition &&
                    <div className="code-block">
                        <span className="code">REPORT ALL RECORDS </span>
                        {
                            !this.props.isReadOnly &&
                            <button className="helper-btn" onClick={() => this.props.onAddConditionClick(location, null)}>+ Condition</button>
                        }
                    </div>
                }

                <ErrorList errors={$case.errors} className="mt-2" />
            </>
        );
    }

    render(): JSX.Element {
        return (
            <ReportLogicList
                isWaiting={this.props.isWaiting}
                items={this.props.statements}
                columns={getColumns(
                    this.onDataSourceChange,
                    this.onNameChange,
                    this.onIsSystemGeneratedChange,
                    this.onIsSeparateOutputChange,
                    this.onAggregatedChange,
                    this.onAggregationChange,
                    this.onBusinessDescriptionChange,
                    this.onBespokeToggleClick,
                    this.props.onRemoveStatementClick,
                    this.onCommentClick,
                    this.onActionItemClick,
                    this.props.reorder,
                    this.getCommentCount,
                    this.getActionItemCount,
                    this.props.collections.dataSources,
                    this.props.reportConfig.aggregations,
                    this.props.isReadOnly)}
                getContent={this.getConditionContent}
                getKey={c => c.number}
                createHtmlId={c => reportConfigNavigation.buildCaseId(c.number)} />
        );
    }
}

export default CaseBuilder;
