const repeat = <T>(count: number, get: () => T): T[] => {
    let result: T[] = [];

    for (let i = 0; i < count; i++) {
        result.push(get());
    }

    return result;
};

export default repeat;