import ComplianceComment from '../../../types/common/ComplianceComment';
import ComplianceCommentModalState from '../../../components/Common/Modals/ComplianceCommentModal/ComplianceCommentModalState';

const createModalState = (comments: ComplianceComment[], url: string): ComplianceCommentModalState => {

    let state = {} as ComplianceCommentModalState;

    state.isOpen = true;
    state.error = null;
    state.comment = '';
    state.comments = comments;
    state.url = url;

    return state;
};

const complianceCommentHelper = {
    createModalState
};

export default complianceCommentHelper;
