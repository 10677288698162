import { createSelectorOption } from './Selector.Helpers';
import RadioButtonGroup from '../../Common/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import Selector from '../../../types/report/Selector';

interface Props {
    selectorNumber: number | null;
    defaultName: string;
    selectors: Selector[];
    onSelectorSelected: (number: number) => void;
}

class SelectorComponent extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.onSelectorSelected = this.onSelectorSelected.bind(this);
    }

    onSelectorSelected(value: string): void {

        this.props.onSelectorSelected(parseInt(value));
    }

    render(): JSX.Element {

        if (this.props.selectors.length === 0) {

            return (<></>);
        }

        return (
            <RadioButtonGroup
                options={this.props.selectors.map((selector) => { return createSelectorOption(selector, this.props.defaultName) })}
                value={(this.props.selectorNumber as number)?.toString() || ''}
                onChange={this.onSelectorSelected}
                name={this.props.defaultName} />
        );
    }
}

export default SelectorComponent;
